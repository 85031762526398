<template>
    <div id="users-list">
        <div class="card mb-4 shadow-sm">
            <h2
                ref="header-form"
                class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary"
            >
                <i class="fa fa-pencil-alt"></i> Registro de usuario 
            </h2>
            <div class="card-body">
                <form-errors :errors="errors"></form-errors>
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-6">
                        <div class="mb-3">
                            <label for="name" class="form-label">
                                Nombres <span class="text-danger">*</span>
                            </label>
                            <input
                                id="name"
                                class="form-control"
                                type="text"
                                placeholder="Nombres"
                                tabindex="1"
                                v-model="record.names"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="surnames" class="form-label">
                                Apellidos <span class="text-danger">*</span>
                            </label>
                            <input
                                id="surnames"
                                class="form-control"
                                type="text"
                                placeholder="Apellidos"
                                tabindex="2"
                                v-model="record.surnames"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="prefix" class="form-label">
                                Correo electrónico
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                id="prefix"
                                class="form-control"
                                type="email"
                                placeholder="Correo electrónico"
                                tabindex="3"
                                v-model="record.email"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="marital_status" class="form-label">
                                Rol <span class="text-danger">*</span>
                            </label>
                            <select
                                id="marital_status"
                                class="form-select"
                                tabindex="4"
                                v-model="record.role"
                                :disabled="getDisabledByRoleUSR"
                            >
                                <option value="">-- Seleccione --</option>
                                <option value="ADM">Administrador</option>
                                <option value="SUP">Supervisor</option>
                                <option value="OPD">Operador de donantes</option>
                                <option value="OPS">Operador de solicitudes</option>
                                <option value="OPSR">Operador de solicitudes (Renal)</option>
                                <option value="OPSH">Operador de solicitudes (Hepático)</option>
                                <option value="OPSC">Operador de solicitudes (Córnea)</option>
                                <option value="OPSM">Operador de solicitudes (Médula ósea)</option>
                                <option value="OPSV">Operador de solicitudes (Válvulas cardíacas)</option>
                                <option value="OPSCOR">Operador de solicitudes (Corazón)</option>
                                <option value="OPSP">Operador de solicitudes (Páncreas)</option>
                                <option value="OPSPU">Operador de solicitudes (Pulmón)</option>
                                <option value="PIN">Personal de inmunología</option>
                                <option value="CHO">Coordinador hospitalario</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer text-center">
                <button
                    type="reset"
                    class="btn btn-outline-primary-dark"
                    @click="reset()"
                >
                    Limpiar
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    @click="onclickSave()"
                >
                    <i class="fa fa-save"></i>
                    Guardar
                </button>
            </div>
        </div>
        <div class="card mb-4 shadow-sm">
            <h2
                class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary"
            >
                <i class="fa fa-book"></i> Lista de usuarios
            </h2>
            <div class="card-body">
                <!-- Filtros de la tabla -->
                <div class="row">
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label">Rol</label>
                        <select
                            id="rol"
                            class="form-select"
                            tabindex="4"
                            v-model="filterBy.role"
                        >
                            <option value="">-- Seleccione --</option>
                            <option value="ADM">Administrador</option>
                            <option value="SUP">Supervisor</option>
                            <option value="OPD">Operador de donantes</option>
                            <option value="OPS">Operador de solicitudes</option>
                            <option value="OPSR">Operador de solicitudes (Renal)</option>
                            <option value="OPSH">Operador de solicitudes (Hepático)</option>
                            <option value="OPSC">Operador de solicitudes (Córnea)</option>
                            <option value="OPSM">Operador de solicitudes (Médula ósea)</option>
                            <option value="OPSV">Operador de solicitudes (Válvulas cardíacas)</option>
                            <option value="OPSCOR">Operador de solicitudes (Corazón)</option>
                            <option value="OPSP">Operador de solicitudes (Páncreas)</option>
                            <option value="OPSPU">Operador de solicitudes (Pulmón)</option>
                            <option value="PIN">Personal de inmunología</option>
                            <option value="CHO">Coordinador hospitalario</option>
                        </select>
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label">Correo electrónico</label>
                        <input
                            id="prefix"
                            class="form-control"
                            type="email"
                            placeholder="Correo electrónico"
                            tabindex="3"
                            v-model="filterBy.email"
                        />
                    </div>
                    <div class="row pb-4">
                        <div class="col-12 col-lg-3">
                            <button class="btn btn-outline-primary-dark"
                                type="reset" @click="resetFilters()"
                            >
                                Limpiar
                            </button>
                            <button type="button" class="btn btn-outline-primary-dark"
                                aria-label="Search" @click="filterUsers"
                            >
                                Buscar
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Final de filtros de la tabla -->
                <v-client-table
                    :columns="columns"
                    :data="records"
                    :options="table_options"
                >
                    <div slot="role" slot-scope="props">
                        {{ showRole(props.row.role) }}
                    </div>
                    <div slot="created_at" slot-scope="props">
                        {{ convertDate(props.row.created_at) }}
                    </div>
                    <div slot="id" slot-scope="props" class="main-flex-buttons">
                        <button
                            class="btn btn-outline-primary-dark"
                            type="button"
                            title="Ver registro"
                            @click="show_info('users', props.row.id)"
                        >
                            <i class="fa fa-eye"></i>
                        </button>
                        <button
                            type="button"
                            class="btn btn-outline-primary"
                            title="Editar registro"
                            @click="
                                getRecord('users-list', props.row.id);
                                getProfile(props.row.id);
                                scrollMeTo('header-form');
                            "
                        >
                            <i class="fa fa-edit"></i>
                        </button>
                        <!--button
                            class="btn btn-outline-danger"
                            type="button"
                            title="Eliminar registro"
                            @click="deleteRecord(url, props.row.id)"
                        >
                            <i class="fas fa-trash"></i>
                        </button-->
                    </div>
                </v-client-table>

                <!-- Modal -->
                <div
                    id="show_user"
                    class="modal fade"
                    tabindex="-1"
                    role="dialog"
                >
                    <div
                        class="modal-dialog modal-dialog-centered modal-xl"
                        role="document"
                    >
                        <div class="modal-content">
                            <div class="modal-header">
                                <h6>Información detallada del usuario</h6>
                                <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    @click="reset()"
                                ></button>
                            </div>
                            <div class="modal-body">
                                <!-- Datos de la cuenta -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Datos de la cuenta
                                </h3>
                                <hr />
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="email" class="form-label">
                                            Correo electrónico
                                        </label>
                                        <input
                                            id="email"
                                            class="form-control"
                                            type="email"
                                            disabled
                                            :value="recordQuery.email"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="role" class="form-label">
                                            Rol
                                        </label>
                                        <input
                                            id="role"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="showRole(recordQuery.role)"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="created_at"
                                            class="form-label"
                                        >
                                            Creado
                                        </label>
                                        <input
                                            id="created_at"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="
                                                convertDate(
                                                    modalQuery.created_at
                                                )
                                            "
                                        />
                                    </div>
                                </div>

                                <!-- Datos Personales -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Datos personales
                                </h3>
                                <hr />
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="names" class="form-label">
                                            Nombres
                                        </label>
                                        <input
                                            id="names"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.names"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="surnames" class="form-label">
                                            Apellidos
                                        </label>
                                        <input
                                            id="surnames"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.surnames"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="dni" class="form-label">
                                            Cédula de identidad
                                        </label>
                                        <input
                                            id="dni"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.dni"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="nationality"
                                            class="form-label"
                                        >
                                            Nacionalidad
                                        </label>
                                        <input
                                            id="nationality"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="
                                                showNationality(
                                                    modalQuery.nationality
                                                )
                                            "
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="gender" class="form-label">
                                            Género
                                        </label>
                                        <input
                                            id="gender"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="
                                                showGender(modalQuery.gender)
                                            "
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="ocupation"
                                            class="form-label"
                                        >
                                            Ocupación
                                        </label>
                                        <input
                                            id="ocupation"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.ocupation_name"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="marital_status"
                                            class="form-label"
                                        >
                                            Estado civil
                                        </label>
                                        <input
                                            id="marital_status"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="
                                                showMaritalStatus(
                                                    modalQuery.marital_status
                                                )
                                            "
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="academic_level"
                                            class="form-label"
                                        >
                                            Nivel académico</label
                                        >
                                        <input
                                            id="academic_level"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.academic_level"
                                        />
                                    </div>
                                </div>

                                <!-- Edad -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Edad
                                </h3>
                                <hr />
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="birthdate"
                                            class="form-label"
                                        >
                                            Fecha de nacimiento
                                        </label
                                        >
                                        <input
                                            id="birthdate"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="
                                                (modalQuery.birthdate != '')
                                                ? convertDate(modalQuery.birthdate)
                                                : ''
                                            "
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="birthplace"
                                            class="form-label"
                                        >
                                            Lugar de nacimiento
                                        </label>
                                        <input
                                            id="birthplace"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.birthplace"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="age" class="form-label">
                                            Edad
                                        </label>
                                        <input
                                            id="age"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.age"
                                        />
                                    </div>
                                </div>

                                <!-- Dirección -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Dirección
                                </h3>
                                <hr />
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="estate_id"
                                            class="form-label"
                                        >
                                            Estado
                                        </label>
                                        <input
                                            id="estate_id"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="location.estate"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="municipality_id"
                                            class="form-label"
                                        >
                                            Municipio
                                        </label>
                                        <input
                                            id="municipality_id"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="location.municipality"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="parish_id"
                                            class="form-label"
                                        >
                                            Parroquia
                                        </label>
                                        <input
                                            id="parish_id"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="location.parish"
                                        />
                                    </div>
                                </div>

                                <!-- Contacto -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Contacto
                                </h3>
                                <hr />
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="phone" class="form-label">
                                            Teléfono
                                        </label>
                                        <input
                                            id="phone"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.phone"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="movil" class="form-label">
                                            Celular
                                        </label>
                                        <input
                                            id="movil"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.movil"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="modal-footer justify-content-center"
                            >
                                <button
                                    type="button"
                                    class="btn btn-outline-primary-dark"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    Cerrar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            errors: [],
            records: [],
            tempRecords: [],
            filterBy: {
                role: '',
                email:'',
            },
            record: {
                id: "",
                names: "",
                surnames: "",
                email: "",
                password: "",
                role: ""
            },
            recordQuery: {
                id: "",
                names: "",
                surnames: "",
                email: "",
                password: "",
                role: ""
            },
            url: "users-list",
            columns: ["name", "email", "role", "created_at", "id"],
            modalQuery: {},
            location: {
                parish: "",
                municipality: "",
                estate: ""
            }
        };
    },
    methods: {
        /**
         * Obtiene el registo solicitado.
         *
         * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <danielking.321@gmail.com>
         *
         * @param     {String}      url     URL para el registro a editar
         * @param     {String}      id      ID del registro a editar
         */
        async getRecord(url, id) {
            const vm = this;
            const query = `${window.url}/${url}/${id}`;
            await axios
            .get(query)
            .then(response => {
                vm.record.id = response.data.result.id;
                vm.record.name = response.data.result.name;
                vm.record.email = response.data.result.email;
                vm.record.role = response.data.result.role;
            })
            .catch(error => {
                console.error(error);
            })
        },

        /**
         * Obtiene el registo solicitado.
         *
         * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <danielking.321@gmail.com>
         *
         * @param     {String}      url     URL para el registro a editar
         * @param     {String}      id      ID del registro a editar
         */
        async getProfile(id) {
            const vm = this;
            axios
            .get(`${window.url}` + "/profiles/" + id)
            .then(response => {
                vm.record.names = response.data.profile.names;
                vm.record.surnames = response.data.profile.surnames;
            })
            .catch(error => {
                console.log(error);
            })
        },

        /**
         * Obtiene todos los datos del perfil según el id del usuario.
         *
         * @method getProfileComplet
         *
         */
        async getProfileComplet(id) {
            const vm = this;
            axios
                .get(`${window.url}` + "/profiles/" + id)
                .then(response => {
                    vm.modalQuery = response.data.profile;
                    if (vm.modalQuery.parish_id) {
                        this.getLocation("parish", vm.modalQuery.parish_id);
                    } else {
                        vm.location.parish = "";
                        vm.location.municipality = "";
                        vm.location.estate = "";
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },

        /**
         * Método que asigna un valor a la variable roleType dependiendo del
         * valor de la variable role para así mostrar el valor asignado en
         * la tabla de registros.
         *
         * @method showRole
         *
         * @author Argenis Osorio <aosorio@cenditel.gob.ve>
         */
        showRole(role) {
            let roleType = "";
            if (role === "ADM") {
                roleType = "Administrador";
            }
            else if (role === "SUP") {
                roleType = "Supervisor";
            }
            else if (role === "OPD") {
                roleType = "Operador de donantes";
            }
            else if (role === "OPS") {
                roleType = "Operador de solicitudes";
            }
            else if (role === "OPSR") {
                roleType = "Operador de solicitudes (Renal)";
            }
            else if (role === "OPSH") {
                roleType = "Operador de solicitudes (Hepático)";
            }
            else if (role === "OPSC") {
                roleType = "Operador de solicitudes (Córnea)";
            }
            else if (role === "OPSM") {
                roleType = "Operador de solicitudes (Médula ósea)";
            }
            else if (role === "OPSV") {
                roleType = "Operador de solicitudes (Válvulas cardíacas)";
            }
            else if (role === "OPSCOR") {
                roleType = "Operador de solicitudes (Corazón)";
            }
            else if (role === "OPSP") {
                roleType = "Operador de solicitudes (Páncreas)";
            }
            else if (role === "OPSPU") {
                roleType = "Operador de solicitudes (Pulmón)";
            }
            else if (role === "PIN") {
                roleType = "Personal de inmunología";
            }
            else if (role === "CHO") {
                roleType = "Coordinador hospitalario";
            }
            else if (role === "USR") {
                roleType = "Usuario";
            }
            return roleType;
        },

        /**
         * Método que cambia el formato de visualización de la fecha en la
         * tabla de registros.
         *
         * @method convertDate
         *
         * @author Argenis Osorio <aosorio@cenditel.gob.ve>
         */
        convertDate(date) {
            let convertedDate = "";
            var date = new Date(date);
            convertedDate = date.toLocaleDateString("en-GB");
            return convertedDate;
        },

        /**
         * Método para reestablecer valores iniciales del formulario
         *
         * @method reset
         *
         * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <kingangelo_123@gmail.com>
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        reset() {
            const vm = this;
            vm.errors = [];
            vm.record = {
                name: "",
                names: "",
                surnames: "",
                email: "",
                role: "",
                password: ""
            };
            vm.modalQuery = {};
        },

        /**
         * Método para mostrar la informacion de un usuario en un modal
         *
         * @method show_info
         *
         * @author José Briceño <josejorgebriceno9@gmail.com>
         * @author Angelo Osorio <danielking.321@gmail.com>
         */
        async show_info(url, id) {
            const vm = this;
            vm.getProfileComplet(id);

            const user = `${window.url}/${url}/${id}`;
            axios.get(user).then(response => {
                vm.recordQuery = response.data.result;
            });

            $("#show_user").modal("show");
        },

        /**
         * Método para obtener la representación textual de
         * la dirección (estado, municipio, parroquia)
         * dado el id de una parroquia.
         *
         * @method getLocation
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         */
        async getLocation(url, id) {
            const vm = this;
            const query = `${window.url}/${url}/${id}`;

            axios
                .get(query)
                .then(response => {
                    vm.location.parish = response.data.parish.name;
                    vm.location.municipality =
                        response.data.parish.municipality.name;
                    vm.location.estate =
                        response.data.parish.municipality.estate.name;
                })
                .catch(error => {
                    console.error(error);
                });
        },

        /**
         * Método para mostrar la nacionalidad
         *
         * @method showNationality
         *
         * @author Francisco J. P. Ruiz  <javierrupe19@gmail.com>
         */
        showNationality(nationality) {
            let nationalityType = "";
            if (nationality === "V") {
                nationalityType = "Venezolano(a)";
            } else if (nationality === "E") {
                nationalityType = "Extranjero(a)";
            }
            return nationalityType;
        },

        /**
         * Método para mostrar el género
         *
         * @method showGender
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         */
        showGender(gender) {
            let genderType = "";
            if (gender === "F") {
                genderType = "Femenino";
            } else if (gender === "M") {
                genderType = "Masculino";
            }
            return genderType;
        },

        /**
         * Método para mostrar el estado civil
         *
         * @method showGender
         *
         * @author Francisco J. P. Ruiz  <javierrupe19@gmail.com>
         */
        showMaritalStatus(maritalStatus) {
            let maritalStatusType = "";
            if (maritalStatus === "S") {
                maritalStatusType = "Soltero(a)";
            } else if (maritalStatus === "C") {
                maritalStatusType = "Casado(a)";
            } else if (maritalStatus === "D") {
                maritalStatusType = "Divorciado(a)";
            } else if (maritalStatus === "V") {
                maritalStatusType = "Viudo(a)";
            }
            return maritalStatusType;
        },
        /**
         * Método para reestablecer valores iniciales del formulario de filtros.
         *
         * @method resetFilters
         *
         * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <kingangelo_123@gmail.com>
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        resetFilters() {
            const vm = this;
            vm.filterBy = {
                role: '',
            };
            vm.records = vm.tempRecords;
        },
        /**
         * Método que permite filtrar los datos de la tabla Users.
         *
         * @method filterUsers
         *
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        filterUsers() {
            const vm = this;
            vm.records = vm.tempRecords.filter((rec) => {
                return (vm.filterBy.role) ? (rec.role === vm.filterBy.role) : true;
            }).filter((rec) => {
                return (vm.filterBy.email) ? (rec.email === vm.filterBy.email) : true;
            })
        },
        async onclickSave(){
            const vm = this;
            await vm.setRecord('users-list')
            await vm.getRecords(vm.url, "records")
            vm.tempRecords = vm.records
            vm.filterUsers()
        }
    },
    created() {
        this.table_options.headings = {
            name: "Nombres",
            email: "Correo electrónico",
            role: "Rol",
            created_at: "Creado",
            id: "Acción"
        };
        this.table_options.sortable = ["name","role","created_at"];
        this.table_options.filterable = ["name","role","created_at"];
        this.table_options.columnsClasses = {
            id: "col-2 text-center"
        };
    },
    computed:{
        /**
         * Método que devuelve disabled si el rol del usuario seleccionado
         * tiene rol USR.
         *
         * @method getDisabledByRoleUSR
         *
         * @author Ing. Argenis Osorio <aosorio@cenditel.gob.ve>
         */
        getDisabledByRoleUSR: function() {
            const vm = this;
            if (vm.record.role == 'USR') {
                return 'disabled';
            }
            else {
                return null;
            }
        },
    },
    async mounted() {
        const vm = this;
        /**
         * Si el usuario autenticado intenta acceder al componente sin tener el
         * rol requerido este no carta los diferentes datos a consultar y
         * redirecciona a la pantalla inicial.
        */
        vm.user_role = vm.$parent.user.role;
        if ((vm.user_role == 'ADM')) {
            vm.loadingState(true); // Inicio de spinner de carga.
            await vm.getRecords(vm.url, "records");
            vm.tempRecords = vm.records
            vm.loadingState(); // Finaliza spinner de carga.
        }
        else {
            location.href = '/';
        }
    }
};
</script>
