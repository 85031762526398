<template>
    <div id="transplant-request-form-component">
        <go-back title="Regresar"/>
        <div class="card mb-4 shadow-sm">
            <h2 class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary">
                Cambiar estatus de la solicitud
            </h2>
            <div class="card-body">
                <form-errors :errors="errors"></form-errors>
                <div class="row pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="estateId" class="form-label">
                            Estatus de la solicitud
                        </label>
                        <v-select
                            id="estateId"
                            label="text"
                            placeholder="-- Seleccione --"
                            :options="StatusOptions"
                            :reduce="estate => estate.id"
                            :selectable="option => option.id !== ''"
                            :tabindex="1"
                            v-model="record.estatus_id">
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                </div>
            </div>
            <div class="card-footer text-center">
                <button class="btn btn-primary" type="button" @click="setRecord('transplant-request-update-status')">
                    <i class="fa fa-save"></i>
                    Actualizar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            errors:[],
            record:{},
            StatusOptions: [
                { id: 1, text: "Recibida" },
                { id: 2, text: "Procesada" },
                { id: 3, text: "En espera de suero" },
                { id: 4, text: "Lista de espera (activos)" },
                { id: 5, text: "Lista de espera (inactivos)" },
                { id: 6, text: "Rechazada" },
                { id: 7, text: "Trasplante realizado" },
            ],
        }
    },
    methods: {
        /**
         * Ejecuta la acción para guardar o actualizar datos
         *
         * @method setRecord
         * @author Francisco Escala <fjescala@gmail.com>
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         *
         * @param  {String}  url URL de la petición a realizar
         */
        async setRecord(url, type_request = "", field = "") {
            const vm = this;
            const recordId = typeof vm.record.id !== "undefined" && vm.record.id ? vm.record.id : ""
            const formData = new FormData()
            vm.loadingState(true) // Inicio de spinner de carga.

            Object.keys(vm.record).forEach(key => {
                if (vm.record[key] == null) {
                    vm.record[key] = ""
                }
                formData.append(key, vm.record[key]);
            })
            await axios({
                method: "post",
                url: `${window.url}/${url}`,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" }
            })
            .then(response => {
                vm.loadingState() // Finaliza spinner de carga.
                vm.showMessage("updated")
                // Redireccionar al componente anterior luego del guardado.
                setTimeout(() => vm.$router.go(-1), 3000);
            })
            .catch(error => {
                vm.loadingState() // Finaliza spinner de carga.
                vm.setErrors(error)
            })
        },
    },
    async mounted() {
        let vm = this
        /**
         * Si el usuario autenticado intenta acceder al componente sin tener el
         * rol requerido este no carta los diferentes datos a consultar y
         * redirecciona a la pantalla inicial.
        */
        vm.user_role = vm.$parent.user.role;
        if ((vm.user_role == 'ADM') || (vm.user_role == 'OPS') ||
            (vm.user_role == 'OPSR') || (vm.user_role == 'OPSH') ||
            (vm.user_role == 'OPSC') || (vm.user_role == 'OPSM') ||
            (vm.user_role == 'OPSV') || (vm.user_role == 'OPSCOR') ||
            (vm.user_role == 'OPSP') || (vm.user_role == 'OPSPU')
        ) {
            vm.loadingState(true) // Inicio de spinner de carga.
            if (vm.$route.params.id) {
                vm.record.id=vm.$route.params.id
                await axios({
                    method: "get",
                    url: `${window.url}/manage-aplicant/`+vm.record.id,
                    headers: { "Content-Type": "multipart/form-data" }
                })
                .then(response => {
                    if (response.data?.result?.status == 1){
                        vm.StatusOptions=vm.StatusOptions.filter(x=> { return x.id==2})
                    }else if(response.data?.result?.status == 2){
                        vm.StatusOptions=vm.StatusOptions.filter(x=> { return x.id!=2})
                    }
                    if (response.data?.result?.transplant_id== 30 || response.data?.result?.transplant_id== 31){
                        vm.StatusOptions=vm.StatusOptions.filter(x=> { return x.id!=3})
                    }
                })
                .catch(error => {
                    vm.loadingState() // Finaliza spinner de carga.
                    vm.setErrors(error)
                })
            }
            else {
                this.$router.push({ name: 'transplant-request-list' })
            }
            vm.loadingState() // Finaliza spinner de carga.
        }
        else {
            location.href = '/';
        }
    }
}
</script>