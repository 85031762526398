<template>
    <div id="wrapper" class="container">
        <router-view></router-view>
    </div>
</template>

<script>
	export default {
		data() {
			return {
				//
			}
		},
		props: {
            domain: {
                type: String,
                required: false,
                default: process.env.APP_URL
            },
            user: {
                type: Object,
                required: true
            },
            logoutUrl: {
                type: String,
                required: true
            }
        },
        methods: {
            /**
             * Establece la ruta de un elemento
             *
             * @author    Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
             *
             * @param     {String}       route    Ubicación del elemento
             */
            setPathRoute(route) {
                return `${window.url}/${route}`;
            },
            /**
             * Realiza la acciń para desautenticar al usuario
             *
             * @method logout
             *
             * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
             *
             * @param  {Object} event Datos del evento generado
             */
            logout(event) {
                event.preventDefault();
                document.getElementById('logout-form').submit();
            },
        },
        mounted() {

        }
	};
</script>