<template>
    <div id="medical-specialist">
        <div class="card mb-4 shadow-sm">
            <h2
                ref="header-form"
                class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary" :key="key"
            >
                <i class="fa fa-pencil-alt"></i> Registro de médico especialista en trasplante 
            </h2>
            <div class="card-body">
                <form-errors :errors="errors"></form-errors>
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-6">
                        <div class="mb-3">
                            <label for="names" class="form-label">
                                Nombres <span class="text-danger">*</span>
                            </label>
                            <input
                                id="names"
                                class="form-control"
                                type="text"
                                placeholder="Nombres"
                                tabindex="1"
                                v-model="record.names"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="lastName" class="form-label">
                                Apellidos <span class="text-danger">*</span>
                            </label>
                            <input
                                id="lastName"
                                class="form-control"
                                type="text"
                                placeholder="Apellidos"
                                tabindex="2"
                                v-model="record.last_names"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="dni" class="form-label">
                                Cédula <span class="text-danger">*</span>
                            </label>
                            <input
                                id="dni"
                                class="form-control"
                                type="text"
                                minlength="7"
                                maxlength="9"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                placeholder="Cédula de identidad"
                                tabindex="3"
                                v-model="record.dni"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="nationality" class="form-label">
                                Nacionalidad <span class="text-danger">*</span>
                            </label>
                            <select
                                id="nationality"
                                class="form-select"
                                tabindex="4"
                                v-model="record.nationality"
                            >
                                <option value="">-- Seleccione --</option>
                                <option value="V">Venezolano(a)</option>
                                <option value="E">Extranjero(a)</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="phone" class="form-label">
                                Teléfono <span class="text-danger">*</span>
                            </label>
                            <input
                                id="phone"
                                class="form-control"
                                type="text"
                                placeholder="Teléfono"
                                maxlength="11"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                tabindex="5"
                                aria-describedby="descriptionHelp"
                                v-model="record.phone"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="sacs_register_number" class="form-label">
                                Número de registro SACS
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                id="sacs_register_number"
                                class="form-control"
                                type="text"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                placeholder="Número de registro SACS"
                                tabindex="6"
                                v-model="record.sacs_register_number"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="nationality" class="form-label">
                                Centro de trasplante
                                <span class="text-danger">*</span>
                            </label>
                            <select
                                id="nationality"
                                class="form-select"
                                tabindex="7"
                                v-model="record.health_center_id"
                            >
                                <option value="">-- Seleccione --</option>
                                <option
                                    v-for="center in transplantCenters"
                                    :key="center.id"
                                    :value="center.id"
                                    >{{ center.name }}</option
                                >
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="description" class="form-label">
                                Descripción
                            </label>
                            <input
                                id="description"
                                class="form-control"
                                type="text"
                                placeholder="Descripción"
                                tabindex="8"
                                maxlength="50"
                                aria-describedby="descriptionHelp"
                                v-model="record.description"
                            />
                            <div id="descriptionHelp" class="form-text">
                                Opcional
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer text-center">
                <button
                    class="btn btn-outline-primary-dark"
                    type="reset"
                    @click="reset()"
                >
                    Limpiar
                </button>
                <button
                    class="btn btn-primary"
                    type="button"
                    @click="setRecord('medical-specialist')"
                >
                    <i class="fa fa-save"></i>
                    Guardar
                </button>
            </div>
        </div>
        <div class="card mb-4 shadow-sm">
            <h2
                class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary"
            >
                <i class="fa fa-book"></i> Lista de médicos especialistas en trasplantes
            </h2>
            <div class="card-body">
                <v-client-table
                    :columns="columns"
                    :data="records"
                    :options="table_options"
                >
                    <div slot="id" slot-scope="props" class="main-flex-buttons">
                        <button
                            class="btn btn-outline-primary-dark"
                            type="button"
                            title="Ver registro"
                            @click="show_info('medical-specialist', props.row.id)"
                        >
                            <i class="fa fa-eye"></i>
                        </button>
                        <button
                            class="btn btn-outline-primary"
                            type="button"
                            title="Editar registro"
                            @click="
                                getRecord('medical-specialist', props.row.id);
                                scrollMeTo('header-form');
                            "
                        >
                            <i class="fa fa-edit"></i>
                        </button>
                        <button
                            class="btn btn-outline-danger"
                            type="button"
                            title="Eliminar registro"
                            @click="
                                deleteRecordFiltered(
                                    'medical-specialist',
                                    url,
                                    props.row.id
                                )
                            "
                        >
                            <i class="fas fa-trash"></i>
                        </button>
                    </div>
                </v-client-table>
                <!-- Modal -->
                <div
                    id="show_medicalSpecialist"
                    class="modal fade"
                    tabindex="-1"
                    role="dialog"
                >
                    <div
                        class="modal-dialog modal-dialog-centered modal-xl"
                        role="document"
                    >
                        <div class="modal-content">
                            <div class="modal-header">
                                <h6>
                                    Información detallada del médico
                                    especialista en trasplante
                                </h6>
                                <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div class="modal-body" :key="key">
                                <!-- Datos Personales -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Datos
                                </h3>
                                <hr />
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="names" class="form-label"
                                            >Nombres</label
                                        >
                                        <input
                                            id="names"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.names"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="lastName" class="form-label"
                                            >Apellidos</label
                                        >
                                        <input
                                            id="lastName"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.last_names"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="dni" class="form-label"
                                            >Cédula</label
                                        >
                                        <input
                                            id="dni"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.dni"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="nationality"
                                            class="form-label"
                                            >Nacionalidad</label
                                        >
                                        <input
                                            id="nationality"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="
                                                showNationality(
                                                    modalQuery.nationality
                                                )
                                            "
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="phone" class="form-label">
                                            Teléfono
                                        </label>
                                        <input
                                            id="phone"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.phone"
                                        />
                                    </div>

                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="sacs_register_number"
                                            class="form-label"
                                            >Número de registro SACS</label
                                        >
                                        <input
                                            id="sacs_register_number"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.sacs_register_number"
                                        />
                                    </div>

                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="health_center"
                                            class="form-label"
                                            >Centro de trasplante</label
                                        >
                                        <input
                                            id="health_center"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.health_center"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="description"
                                            class="form-label"
                                        >
                                            Descripción</label
                                        >
                                        <input
                                            id="description"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.description"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="modal-footer justify-content-center"
                            >
                                <button
                                    type="button"
                                    class="btn btn-outline-primary-dark"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    Cerrar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            record: {
                names: "",
                last_names: "",
                dni: "",
                nationality: "",
                health_center_id: "",
                description: "",
                phone: "",
                sacs_register_number: ""
            },
            modalQuery: {
                names: "",
                last_names: "",
                dni: "",
                nationality: "",
                health_center_id: "",
                description: "",
                phone: "",
                sacs_register_number: ""
            },
            url: "medical-specialist",
            transplantCenters: [],
            errors: [],
            records: [],
            columns: [
                "names",
                "last_names",
                "health_center.name",
                "description",
                "id"
            ],
            key:0,
        };
    },
    created() {
        this.table_options.headings = {
            names: "Nombres",
            last_names: "Apellidos",
            "health_center.name": "Centro de trasplante",
            description: "Descripción",
            id: "Acción"
        };
        this.table_options.sortable = ["names", "last_names"];
        this.table_options.filterable = ["names", "last_names"];
        this.table_options.columnsClasses = {
            id: "col-2 text-center"
        };
    },
    methods: {
        reset() {
            const vm = this;
            vm.record = {
                names: "",
                last_names: "",
                dni: "",
                nationality: "",
                health_center_id: "",
                description: "",
                phone: "",
                sacs_register_number: ""
            },
            vm.modalQuery = {
                names: "",
                last_names: "",
                dni: "",
                nationality: "",
                health_center_id: "",
                description: "",
                phone: "",
                sacs_register_number: ""
            },
            vm.errors = [];
        },
        /**
         * Método para mostrar la informacion de un medical specialist en un modal
         *
         * @method show_info
         *
         * @author José Briceño <josejorgebriceno9@gmail.com>
         * @author Angelo Osorio <danielking.321@gmail.com>
         */
        async show_info(url, id) {
            const vm = this;

            const user = `${window.url}/${url}/${id}`;
            
            axios.get(user).then(response => {
                
                vm.modalQuery = response.data.result;
                vm.modalQuery.health_center=vm.modalQuery.health_center.name
                vm.key++
                $("#show_medicalSpecialist").modal("show");
            });

            
        },
/**
         * Obtiene todos los datos del perfil según el id del usuario.
         *
         * @method getProfileComplet
         *
         */
        async getProfileComplet(id) {
            const vm = this;
            axios
                .get(`${window.url}` + "/profiles/" + id)
                .then(response => {
                    vm.modalQuery = response.data.profile;
                    if (vm.modalQuery.parish_id) {
                        this.getLocation("parish", vm.modalQuery.parish_id);
                    } else {
                        vm.location.parish = "";
                        vm.location.municipality = "";
                        vm.location.estate = "";
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        /**
         * Método para mostrar la nacionalidad
         *
         * @method showNationality
         *
         * @author Francisco J. P. Ruiz  <javierrupe19@gmail.com>
         */
        showNationality(nationality) {
            let nationalityType = "";
            if (nationality === "V") {
                nationalityType = "Venezolano(a)";
            } else if (nationality === "E") {
                nationalityType = "Extranjero(a)";
            }
            return nationalityType;
        },

        async getTransplantCenters() {
            try {
                const vm = this;
                const response = await axios.get(
                    `${window.url}/health-center-type/transplant`
                );
                vm.transplantCenters = response.data.result;
            } catch (error) {
                vm.errors.push(error);
            }
        }
    },

    async mounted() {
        const vm = this;
        /**
         * Si el usuario autenticado intenta acceder al componente sin tener el
         * rol requerido este no carta los diferentes datos a consultar y
         * redirecciona a la pantalla inicial.
        */
        vm.user_role = vm.$parent.user.role;
        if ((vm.user_role == 'ADM')) {
            vm.loadingState(true); // Inicio de spinner de carga.
            vm.getTransplantCenters();
            await vm.getRecords(vm.url);
            vm.loadingState(); // Finaliza spinner de carga.
        }
        else {
            location.href = '/';
        }
    }
};
</script>
