<template>
    <div>
        <div v-if="donor">
            <div class="alert alert-success alert-dismissible fade show" role="alert">
                <i class="fa fa-address-card"></i>
                    Se ha registrado en el Registro nacional de voluntad de donación
                    (RENAVDO), puede generar su carnet
                    <b>
                        <a href="#" class="text-primary" @click="generatePDF"
                            style="text-decoration: none;"
                        >
                            aquí.
                        </a>
                    </b>
                <button id="profile-message" class="btn-close" type="button"
                    aria-label="Close"data-bs-dismiss="alert"></button>
            </div>
        </div>
        <!-- Card -->
        <div class="card shadow-sm">
            <h2 class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary">
                <i class="fa fa-pencil-alt"></i> Registro nacional de voluntad de donación (RENAVDO)
            </h2>
            <!-- Card body -->
            <div class="card-body">
                <form-errors :errors="errors"></form-errors>
                <div class="row justify-content-center pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <h4 class="h6">
                            Tipo de Donante <span class="text-danger">*</span>
                        </h4>
                        <div class="form-check form-check-inline pt-2">
                            <input
                                id="donorOption"
                                class="form-check-input donor_type"
                                type="radio"
                                name="type"
                                value="DN"
                                v-model="record.donorType"
                                @change="setReason"
                            />
                            <label class="form-check-label" for="donorOption">
                                Donante
                            </label>
                        </div>
                        <div class="form-check form-check-inline pt-2">
                            <input
                                id="notDonorOption"
                                class="form-check-input donor_type"
                                type="radio"
                                name="type"
                                value="ND"
                                v-model="record.donorType"
                                @change="setReason"/>
                            <label class="form-check-label" for="notDonorOption">
                                No Donante
                            </label>
                        </div>
                    </div>
                    <div v-if="created_at" class="col-12 col-lg-2 mb-3">
                        <label for="date" class="form-label">
                            Fecha de registro
                        </label>
                        <input
                            id="date"
                            class="form-control"
                            type="text"
                            name="date"
                            placeholder="Fecha de registro"
                            readonly
                            tabindex="-1"
                            :value="new Date(created_at).toLocaleDateString('en-GB')"
                        />
                    </div>
                    <div v-else class="col-12 col-lg-2 mb-3">
                        <label for="date" class="form-label">
                            Fecha de registro
                        </label>
                        <input
                            id="date"
                            class="form-control"
                            type="text"
                            name="date"
                            placeholder="Fecha de registro"
                            readonly
                            tabindex="-1"
                            v-model="new Date(record.registered_at).toLocaleDateString('en-GB', {timeZone: 'UTC'})"
                        />
                    </div>
                    <div class="col-12 col-lg-2 mb-3" v-if="record.number">
                        <label for="number" class="form-label">
                            Serial N°
                        </label>
                        <input
                            id="number"
                            class="form-control"
                            type="text"
                            name="number"
                            placeholder="Serial N°"
                            readonly
                            disabled
                            tabindex="-1"
                            v-model="record.number"
                        />
                    </div>
                </div>

                <!-- Datos Personales -->
                <h3 class="h5 text-secondary text-uppercase">
                    Datos Personales
                </h3>
                <hr>
                <div class="row justify-content-center pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="names" class="form-label">
                            Nombres <span class="text-danger">*</span>
                        </label>
                        <input
                            id="names"
                            class="form-control"
                            type="text"
                            name="names"
                            placeholder="Nombres"
                            required
                            tabindex="1"
                            v-model="record.names"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="surnames" class="form-label">
                            Apellidos <span class="text-danger">*</span>
                        </label>
                        <input
                            id="surnames"
                            class="form-control"
                            type="text"
                            name="surnames"
                            placeholder="Apellidos"
                            required
                            tabindex="2"
                            v-model="record.surnames"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="dni" class="form-label">
                            Cédula de identidad <span class="text-danger">*</span>
                        </label>
                        <input
                            id="dni"
                            class="form-control"
                            type="text"
                            name="dni"
                            minlength="7"
                            maxlength="9"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Cédula de identidad"
                            required
                            tabindex="3"
                            v-model="record.dni"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="nationality" class="form-label">
                            Nacionalidad <span class="text-danger">*</span>
                        </label>
                        <v-select
                            id="nationality"
                            label="text"
                            placeholder="-- Seleccione --"
                            :options="nationalityOptions"
                            :reduce="nationality => nationality.id"
                            :selectable="option => option.id !== ''"
                            :tabindex="4"
                            v-model="selectedOptions.nationality"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="gender" class="form-label">
                            Género <span class="text-danger">*</span>
                        </label>
                        <v-select
                            id="gender"
                            label="text"
                            placeholder="-- Seleccione --"
                            :options="genderOptions"
                            :tabindex="5"
                            :reduce="gender => gender.id"
                            :selectable="option => option.id !== ''"
                            v-model="selectedOptions.gender"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="marital_status" class="form-label">
                            Estado civil <span class="text-danger">*</span>
                        </label>
                        <v-select
                            id="marital_status"
                            label="text"
                            placeholder="-- Seleccione --"
                            :options="maritalStatusOptions"
                            :reduce="ms => ms.id"
                            :selectable="option => option.id !== ''"
                            :tabindex="6"
                            v-model="selectedOptions.marital_status"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="ocupation" class="form-label">
                            Ocupación <span class="text-danger">*</span>
                        </label>
                        <v-select
                            id="ocupation"
                            label="name"
                            placeholder="-- Seleccione --"
                            :options="occupations"
                            :reduce="ocupation => ocupation.id"
                            :selectable="option => option.id !== ''"
                            :tabindex="7"
                            v-model="selectedOptions.ocupation"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="academic_levels" class="form-label">
                            Nivel académico <span class="text-danger">*</span>
                        </label>
                        <v-select
                            id="academic_level"
                            label="name"
                            placeholder="-- Seleccione --"
                            :options="academic_levels"
                            :reduce="ms => ms.name"
                            :selectable="option => option.id !== ''"
                            :tabindex="8"
                            v-model="selectedOptions.academic_level"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="blood_group" class="form-label">
                            Grupo sangíneo
                        </label>
                        <v-select
                            v-model="selectedOptions.blood_group"
                            :options="blood_groups"
                            :selectable="option => option.id !== ''"
                            :reduce="blood_group => blood_group.id"
                            label="name"
                            id="blood_group"
                            :tabindex="9"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="rhFactor" class="form-label">
                            Factor RH
                        </label>
                        <v-select
                            v-model="selectedOptions.rh_factor"
                            :options="rh_factorOptions"
                            :reduce="rh_factor => rh_factor.id"
                            label="text"
                            id="rh_factor"
                            :tabindex="10"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-8 mb-3"></div>
                </div>
                <!-- ./Datos Personales -->

                <!-- Edad -->
                <h3 class="h5 text-secondary text-uppercase">
                    Edad
                </h3>
                <hr>
                <div class="row justify-content-center pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="birthdate" class="form-label">
                            Fecha de nacimiento <span class="text-danger">*</span>
                        </label>
                        <input
                            id="birthdate"
                            class="form-control"
                            name="birthdate"
                            type="date"
                            placeholder="Fecha de nacimiento"
                            required
                            tabindex="9"
                            :max="today"
                            v-model="record.birthdate"
                            @change="setAge"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="age" class="form-label">Edad</label>
                        <input
                            id="age"
                            class="form-control"
                            name="age"
                            type="number"
                            aria-describedby="ageHelp"
                            min="1"
                            max="3"
                            placeholder="0"
                            readonly
                            tabindex="-1"
                            v-model="record.age"
                        />
                        <div id="ageHelp" class="form-text">
                            Este campo se autorrellena al ingresar la
                            <label class="fw-bold" for="birthdate">
                                Fecha de nacimiento
                            </label>.
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="birthplace" class="form-label">
                            Lugar de nacimiento <span class="text-danger">*</span>
                        </label>
                        <input
                            id="birthplace"
                            class="form-control"
                            name="birthplace"
                            type="text"
                            placeholder="Lugar de nacimiento"
                            required
                            tabindex="10"
                            v-model="record.birthplace"
                        />
                    </div>
                </div>
                <!-- Final Edad -->

                <!-- Dirección -->
                <h3 class="h5 text-secondary text-uppercase">
                    Dirección
                </h3>
                <hr>
                <div class="row justify-content-center pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="estate_id" class="form-label">
                            Estado <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="selectedOptions.estate_id"
                            :options="estates"
                            :selectable="option => option.id !== ''"
                            :reduce="estate => estate.id"
                            label="text"
                            id="estate_id"
                            :tabindex="11"
                            @input="
                                selectedOptions.municipality_id='';
                                selectedOptions.parish_id='';
                                getMunicipalities(selectedOptions.estate_id)
                            "
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="municipality_id" class="form-label">
                            Municipio <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="selectedOptions.municipality_id"
                            :options="municipalities"
                            :selectable="option => option.id !== ''"
                            :reduce="mun => mun.id"
                            label="text"
                            id="municipality_id"
                            :tabindex="12"
                            @input="
                                selectedOptions.parish_id='';
                                getParishes(selectedOptions.municipality_id)
                            "
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="parish_id" class="form-label">
                            Parroquia <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="selectedOptions.parish_id"
                            :options="parishes"
                            :selectable="option => option.id !== ''"
                            :reduce="par => par.id"
                            label="text"
                            id="parish_id"
                            :tabindex="13"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                </div>
                <!-- Final Dirección -->

                <!-- Contacto -->
                <h3 class="h5 text-secondary text-uppercase">
                    Contacto
                </h3>
                <hr>
                <div class="row justify-content-center pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="phone" class="form-label">
                            Teléfono local <span class="text-danger">*</span>
                        </label>
                        <input
                            id="phone"
                            class="form-control"
                            name="phone"
                            type="text"
                            placeholder="Teléfono local"
                            required
                            tabindex="14"
                            maxlength="11"
                            v-model="record.phone"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="movil" class="form-label">
                            Teléfono móvil <span class="text-danger">*</span>
                        </label>
                        <input
                            id="movil"
                            class="form-control"
                            name="movil"
                            type="text"
                            placeholder="Teléfono móvil"
                            tabindex="15"
                            v-model="record.movil"
                            maxlength="11"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="dni" class="form-label">
                            Correo electrónico <span class="text-danger">*</span>
                        </label>
                        <input
                            id="email"
                            class="form-control"
                            name="email"
                            type="email"
                            placeholder="Correo electrónico"
                            tabindex="16"
                            v-model="record.email"
                        />
                    </div>
                </div>
                <!-- Final Contacto -->

                <!-- Datos de la donación -->
                <div v-if="record.donorType==='DN'" class="w-100">
                    <h3 class="h5 text-secondary text-uppercase">
                        Datos de la donación <span class="text-danger" style="font-size: 14px;">*</span>
                    </h3>
                    <hr>
                    <div class="row">
                        <div class="col-12 mb-3 pb-4">
                            <div class="form-check form-check-inline ps-0">
                                <input
                                    id="totalDonor"
                                    class="custom-control-input donor_schema"
                                    type="radio"
                                    value="T"
                                    v-model="record.donorSchema"
                                    @input="restorePartialDonors()"
                                />
                                <label class="custom-control-label" for="totalDonor">
                                    Total
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    id="partialDonor"
                                    class="custom-control-input donor_schema"
                                    type="radio"
                                    value="P"
                                    v-model="record.donorSchema"
                                    @input="restorePartialDonors()"
                                />
                                <label class="custom-control-label" for="partialDonor">
                                    Parcial
                                </label>
                            </div>
                        </div>
                        <div v-if="record.donorSchema === 'P'" class="col-12 mb-3 pb-4">
                            <h3 class="h5 text-secondary text-uppercase">
                                Órganos y Tejidos
                            </h3>
                            <hr>
                            <table class="table table-inverse">
                                <thead>
                                    <tr>
                                        <th class="text-center"></th>
                                        <th class="text-center">Terapeútico (trasplante)</th>
                                        <th class="text-center">Investigación o Docencia</th>
                                    </tr>
                                </thead>
                                <tbody v-for="(organ, index) in organs" :key="index">
                                    <tr>
                                        <td class="font-weight-bold text-center">
                                            {{ organ.type }}
                                        </td>
                                        <td colspan="2"></td>
                                    </tr>
                                    <tr v-for="(list, index) in organ.list" :key="index">
                                        <td>{{ list.name }}</td>
                                        <td class="text-center">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                :id="list.therapeutic"
                                                :value="list.therapeutic"
                                                @change="asRadio(list.research)"
                                                v-model="record.partialDonors"
                                            />
                                        </td>
                                        <td class="text-center">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                :id="list.research"
                                                :value="list.research"
                                                @change="asRadio(list.therapeutic)"
                                                v-model="record.partialDonors"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- Final datos de la donación -->

                <div v-if="record.donorType==='ND'" class="w-100">
                    <h3 class="h5 text-secondary text-uppercase">
                        Razones por la cual no quiero ser donante
                    </h3>
                    <hr>
                    <div class="row pb-4">
                        <div class="col-12">
                            <div class=" mb-3">
                                <label class="form-label">Especifique</label>
                                <input
                                    id="totalDonor"
                                    class="custom-control-input donor_schema"
                                    type="radio"
                                    v-model="record.donorSchema"
                                    value="T"
                                />
                                <textarea
                                    class="form-control"
                                    rows="6"
                                    v-model="record.not_donor_reason"
                                >
                                </textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Familiares con quién se comparte la decisión -->
                <h3 class="h5 text-secondary text-uppercase">
                    Familiares con quién se comparte la decisión
                    <i
                        class="fas fa-plus-circle ml-3"
                        title="Agregar más familiares"
                        style="cursor:pointer"
                        @click="addFamily"
                    >
                    </i>
                </h3>
                <div class="row pb-4" v-for="(family, index) in record.families" :key="index">
                    <div v-if="index > 0" class="col-12"><hr></div>
                    <div class="col-12 col-md-5">
                        <div class="mb-3">
                            <label class="form-label" :for="'names_' + index">Nombres</label>
                            <input
                                :for="'names_' + index"
                                class="form-control"
                                type="text"
                                v-model="family.names"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-md-5">
                        <div class="mb-3">
                            <label class="form-label" :for="'surnames_' + index">Apellidos</label>
                            <input
                                :id="'surnames_' + index"
                                class="form-control"
                                type="text"
                                v-model="family.surnames"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-md-2">
                        <div style="margin-top: 30px;">
                            <button
                                class="btn btn-outline-danger"
                                type="button"
                                title="Eliminar familiar"
                                @click="deleteFamily(family)"
                            >
                                <i class="fas fa-trash"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-md-5">
                        <div class="mb-3">
                            <label class="form-label" :for="'phone_' + index">Teléfono</label>
                            <input
                                :id="'phone_' + index"
                                class="form-control"
                                type="text"
                                v-model="family.phone"
                                maxlength="11"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-md-5">
                        <div class="mb-3">
                            <label class="form-label" :for="'relationship_' + index">Parentesco</label>
                            <select
                                :id="'relationship_' + index"
                                class="form-select"
                                v-model="family.relationship"
                            >
                                <option
                                    :value="relationship.id"
                                    :key="relationship.id"
                                    v-for="relationship in relationships"
                                >
                                    {{ relationship.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-md-2">
                    </div>
                </div>
                <!-- Final familiares con quién se comparte la decisión -->
            </div>
            <!-- Final Card body -->

            <!-- Card footer -->
            <div class="card-footer text-center">
                <span v-if="get_status >= 2">
                </span>
                <span v-else>
                    <button
                        class="btn btn-outline-primary-dark"
                        type="reset"
                        @click="reset()"
                    >
                        Limpiar
                    </button>
                    <button
                        v-if="get_status"
                        class="btn btn-primary"
                        type="button"
                        @click="setRecord('manage-will-donor')"
                    >
                        <i class="fa fa-save"></i>
                        Actualizar
                    </button>
                    <button
                        v-else
                        class="btn btn-primary"
                        type="button"
                        @click="setRecord('manage-will-donor')"
                    >
                        <i class="fa fa-save"></i>
                        Guardar
                    </button>
                </span>
                <span v-if="donor">
                    <button
                        class="btn btn-secondary-dark"
                        type="button"
                        @click="generatePDF"
                    >
                        <i class="fa fa-address-card"></i>
                        Generar carnet
                    </button>
                </span>
            </div>
            <!-- Final Card footer -->
        </div>
        <!-- Final Card -->
    </div>
</template>

<script>
    import base64images from '../../base64images'
    export default {
        data() {
            return {
                errors: [],
                blood_groups: [],
                estates: [],
                municipalities: [],
                parishes: [],
                academic_levels: [],
                relationships: [],
                relationships_family: [],
                donor: [],
                rh_factorOptions: [
                    { id: "P", text: "Positivo" },
                    { id: "N", text: "Negativo" }
                ],
                created_at: '',
                record: {
                    user_id: '',
                    donorType: '',
                    registered_at: '',
                    number: '',
                    dni: '',
                    birthdate: '',
                    birthplace: '',
                    age: '',
                    nationality: '',
                    names: '',
                    surnames: '',
                    ocupation: '',
                    gender: '',
                    blood_group: '',
                    rh_factor: '',
                    marital_status: '',
                    academic_level: '',
                    phone: '',
                    movil: '',
                    email: '',
                    estate_id: '',
                    municipality_id: '',
                    parish_id: '',
                    donorSchema: '',
                    not_donor_reason: '',
                    partialDonors: [],
                    families: [],
                    status: '',
                },
                selectedOptions: {
                    nationality: "",
                    gender: "",
                    ocupation: "",
                    blood_group: '',
                    rh_factor: '',
                    marital_status: "",
                    academic_level: "",
                    estate_id: "",
                    municipality_id: "",
                    parish_id: ""
                },
                nationalityOptions: [
                    { id: "V", text: "Venezolano(a)" },
                    { id: "E", text: "Extranjero(a)" }
                ],
                genderOptions: [
                    { id: "F", text: "Femenino" },
                    { id: "M", text: "Masculino" }
                ],
                maritalStatusOptions: [
                    { id: "S", text: "Soltero(a)" },
                    { id: "C", text: "Casado(a)" },
                    { id: "D", text: "Divorciado(a)" },
                    { id: "V", text: "Viudo(a)" }
                ],
                occupations_url: "global-data-type/occupation",
                occupations: [],
                today: '',
                get_status: '',
                url_donor: 'get-donor',
                organs: [
                    {
                        type: 'Órganos',
                        list: [
                            {
                                name: 'Riñon',
                                therapeutic: 'therapeutic_kidney',
                                research: 'reasearch_kidney'
                            },
                            {
                                name: 'Páncreas',
                                therapeutic: 'therapeutic_pancreas',
                                research: 'reasearch_pancreas'
                            },
                            {
                                name: 'Hígado',
                                therapeutic: 'therapeutic_liver',
                                research: 'reasearch_liver'
                            },
                            {
                                name: 'Corazón',
                                therapeutic: 'therapeutic_heart',
                                research: 'reasearch_heart'
                            },
                            {
                                name: 'Intestino',
                                therapeutic: 'therapeutic_intestine',
                                research: 'reasearch_intestine'
                            },
                            {
                                name: 'Pulmón',
                                therapeutic: 'therapeutic_lung',
                                research: 'reasearch_lung'
                            },
                        ]
                    },
                    {
                        type: 'Tejidos',
                        list: [
                            {
                                name: 'Sangre',
                                therapeutic: 'therapeutic_blood',
                                research: 'reasearch_blood'
                            },
                            {
                                name: 'Tejido Ocular',
                                therapeutic: 'therapeutic_eye_tissue',
                                research: 'reasearch_eye_tissue'
                            },
                            {
                                name: 'Tejido Osteotendinoso',
                                therapeutic: 'therapeutic_osteotendinous_tissue',
                                research: 'reasearch_osteotendinous_tissue'
                            },
                            {
                                name: 'Tejido Cardiovascular',
                                therapeutic: 'therapeutic_cardiovascular_tissue',
                                research: 'reasearch_cardiovascular_tissue'
                            },
                            {
                                name: 'Hueso',
                                therapeutic: 'therapeutic_bone',
                                research: 'reasearch_bone'
                            },
                            {
                                name: 'Válvulas Cardíacas',
                                therapeutic: 'therapeutic_heart_valves',
                                research: 'reasearch_heart_valves'
                            },
                            {
                                name: 'Piel',
                                therapeutic: 'therapeutic_skin',
                                research: 'reasearch_skin'
                            },
                            {
                                name: 'Cartílago',
                                therapeutic: 'therapeutic_cartilage',
                                research: 'reasearch_cartilage'
                            },
                            {
                                name: 'Vasos',
                                therapeutic: 'therapeutic_vessels',
                                research: 'reasearch_vessels'
                            },
                        ]
                    },
                    {
                        type: 'Células',
                        list: [
                            {
                                name: 'Células Progenítoras Hematopoyéticas',
                                therapeutic: 'therapeutic_hematopoietic_stem_cells',
                                research: 'reasearch_hematopoietic_stem_cells'
                            },
                            {
                                name: 'Cordón umbilical',
                                therapeutic: 'therapeutic_umbilical_cord',
                                research: 'reasearch_umbilical_cord'
                            },
                        ]
                    }
                ],
            }
        },
        methods: {
            /**
             * Método para reestablecer valores iniciales del formulario
             *
             * @method reset
             *
             * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <kingangelo_123@gmail.com>
             */
            reset() {
                const vm = this;
                vm.errors = [];

                // Datos personales
                vm.record.donorType = null;
                vm.record.names = '';
                vm.record.surnames = '';
                vm.record.dni = '';
                vm.record.nationality = '';
                vm.record.gender = '';
                vm.record.marital_status = '';
                vm.record.academic_level = '';
                vm.record.ocupation = '';
                vm.record.blood_group = '';

                // Edad
                vm.record.birthdate = '';
                vm.record.birthplace = '';
                vm.record.age = 0;

                // Dirección
                vm.record.estate_id = '';
                vm.record.municipality_id = '';
                vm.record.parish_id = '';

                // Contacto
                vm.record.phone = '';
                vm.record.movil = '';
                vm.record.email = '';

                // Familiares con quién se comparte la decisión
                vm.record.families = [
                    {
                        names: '',
                        surnames: '',
                        relationship: '',
                        phone: ''
                    }
                ]
            },

            /**
             * Método que resetea los órganos y tejidos si se selecciona el
             * esquema de donación como parcial.
             *
             * @method restorePartialDonors
             *
             * @author Francisco Escala <fescala@cenditel.gob.ve>
            */
            restorePartialDonors() {
                const vm = this;
                if (vm.record.donorSchema == "P") {
                    vm.record.partialDonors = [];
                }
            },

            /**
             * Método que elimina un elemento del arreglo de familiares con
             * quién se comparte la decisión.
             *
             * @method deleteFamily
             *
             * @author Ing. Argenis Osorio <aosorio@cenditel.gob.ve>
            */
            deleteFamily: function(family) {
                this.record.families.splice(this.record.families.indexOf(family), 1);
            },

            /**
             * Método que rellena el campo edad según le fecha de nacimiento
             * seleccionada.
             *
             * @method setAge
             *
             * @author José Briceño <josejorgebriceno9@gmail.com>
             */
            setAge() {
                const vm = this;
                let age = moment().diff(
                    vm.record.birthdate,
                    "years",
                    false);
                vm.record.age = age > -1 ? age : "";
            },

            /**
             * Método que selecciona automaticamente el boton "Especifique"
             * cuando el usuario es No donate.
             *
             *
             * @method setReason
             *
             * @author José Briceño <josejorgebriceno9@gmail.gob.ve>
            */
            setReason() {
                const vm = this;
                if (vm.record.donorType == 'ND'){
                    vm.record.donorSchema = "T";
                }
                else {
                    vm.record.not_donor_reason = '';
                    vm.record.donorSchema = '';
                }
            },

            /**
             * Método que permite generar el carnet en formato PDF.
             *
             * @method generatePDF
             *
             * @author Argenis Osorio <aosorio@cenditel.gob.ve>
             */
            generatePDF() {
                var certi = base64images.carnetDonante();
                var donorType = this.record.donorType==='DN' ? 'Donante' : 'No Donante';
                var names = this.record.names;
                var surnames = this.record.surnames;
                var dni = this.record.dni;
                var birthdate = new Date(this.record.birthdate).toLocaleDateString('en-GB', {timeZone: 'UTC'});
                var filename = this.record.dni;
                var number = this.record.number;
                var doc = new jsPDF('landscape','mm', 'a4');
                doc.addImage(certi, 'JPEG', 0, 0, 297, 210);
                doc.setFont('times');
                if (donorType === 'Donante') {
                    doc.setTextColor(0,176,0);
                    doc.setFontSize(22);
                    doc.text(156, 155, donorType);
                    doc.setTextColor(0,0,0);
                    doc.setFontSize(16)
                    doc.text(147, 165, names);
                    doc.text(147, 172, surnames);
                    doc.text(147, 178, dni);
                    doc.text(147, 185, birthdate);
                    doc.text(147, 192, number);
                    doc.save(donorType+'-'+filename+'.pdf');
                }
                else {
                    doc.setTextColor(255,0,0);
                    doc.setFontSize(22);
                    doc.text(156, 155, donorType);
                    doc.setTextColor(0,0,0);
                    doc.setFontSize(16)
                    doc.text(147, 165, names);
                    doc.text(147, 172, surnames);
                    doc.text(147, 178, dni);
                    doc.text(147, 185, birthdate);
                    doc.text(147, 192, number);
                    doc.save(donorType+'-'+filename+'.pdf');
                }
            },

            /**
             * Método que agrega un elemento del arreglo de familiares con
             * quién se comparte la decisión.
             *
             * @method deleteFamily
             *
             * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
            */
            addFamily() {
                const vm = this;
                vm.record.families.push({
                    names: '',
                    surnames: '',
                    relationship: '',
                    phone: ''
                });
            },

            /**
             * Método que filtra los números telefonicos de los familiares para recibir solo digitos
             *
             * @method filterPhone
             *
             * @author José Briceño <jbriceno@cenditel.gob.ve>
            */
            filterPhone : function (event) {
                try {
                    let phone = event.currentTarget.value.replace(/[^0-9]/g, '');

                    this.record.families[event.currentTarget.id].phone = phone;
                } catch (error) {
                    console.log(error);
                }
            },

            /**
             * Ejecuta la acción para guardar o actualizar datos
             *
             * @method setRecord
             *
             * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
             *
             * @param  {String}  url URL de la petición a realizar
             */
            async setRecord(url) {

                const vm = this;
                this.errors = [];
                vm.loadingState(true); // Inicio de spinner de carga.

                if (vm.record.not_donor_reason == '' && vm.record.donorType == 'ND'){
                    this.errors.push('Especifique el motivo por el cual no desea ser Donante');
                    vm.loadingState(); // Finaliza spinner de carga.
                }

                if (vm.record.donorSchema == ''){
                    this.errors.push('Complete la sección datos de la donación');
                    vm.loadingState(); // Finaliza spinner de carga.
                }

                if (this.errors.length === 0){
                    const recordId = (typeof(vm.record.id) !== "undefined" && vm.record.id) ? vm.record.id : '';
                    vm.record.nationality = vm.selectedOptions.nationality;
                    vm.record.gender = vm.selectedOptions.gender;
                    vm.record.ocupation = vm.selectedOptions.ocupation;
                    vm.record.marital_status = vm.selectedOptions.marital_status;
                    vm.record.blood_group = vm.selectedOptions.blood_group;
                    vm.record.rh_factor = vm.selectedOptions.rh_factor;
                    vm.record.academic_level = vm.selectedOptions.academic_level;
                    vm.record.estate_id = vm.selectedOptions.estate_id;
                    vm.record.municipality_id = vm.selectedOptions.municipality_id;
                    vm.record.parish_id = vm.selectedOptions.parish_id;

                    await axios({
                        method: (recordId) ? 'put' : 'post',
                        url: `${window.url}/${url}/${recordId}`,
                        data: vm.record
                    })
                    .then(response => {
                        vm.showMessage('updated');
                        // Elimina el aviso de alerta de "Por favor realice el Registro nacional de voluntad de donación (RENAVDO)."
                        (() => {
                            if (document.querySelector("#renavdo-message")) {
                                document.querySelector("#renavdo-message").click();
                            }
                        })();
                        vm.loadingState(); // Finaliza spinner de carga.
                        vm.getRecord(vm.url_donor, vm.record.user_id);
                    })
                    .catch(error => {
                            vm.loadingState(); // Finaliza spinner de carga.
                            vm.setErrors(error)
                        }
                    );
                }
            },

            /**
             * Obtiene el registo solicitado (Datos guardados del Donante).
             *
             * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <danielking.321@gmail.com>
             *
             * @param     {String}      url     URL para el registro a editar
             * @param     {String}      id      ID del registro a editar
             */
            async getRecord(url, id) {
                const vm = this;
                const query = `${window.url}/${url}/${id}`;

                await axios.get(query)
                .then(response => {
                    if (response.data.result !== null) {
                        // Si es true muestra el botón de generar el carnet.
                        vm.donor = response.data.result;
                        vm.get_status = response.data.result.status;
                        vm.record.number = (vm.donor !== null) ? vm.donor.number : '';
                        vm.created_at = (vm.donor.created_at) ? vm.donor.created_at : '';
                        vm.selectedOptions.rh_factor = (vm.donor.rh_factor) ? vm.donor.rh_factor : '';
                        vm.selectedOptions.blood_group = (vm.donor.blood_group)?parseInt(vm.donor.blood_group):'';
                        vm.record.donorSchema = (vm.donor !== null) ? vm.donor.donorSchema : '';
                        if (vm.record.donorSchema == "P" && vm.donor.partial_organs !== null && vm.donor !== null) {
                            vm.record.partialDonors = [];
                            vm.donor.partial_organs.forEach(element => vm.record.partialDonors.push(element));
                        }
                        vm.record.donorType = (vm.donor !== null) ? vm.donor.donorType : '';
                        vm.record.not_donor_reason = (vm.donor !== null) ? vm.donor.not_donor_reason : '';
                        vm.record.families = (vm.donor !== null) ? JSON.parse(vm.donor.families) : [];
                    }
                    else {
                        // Si es null no muestra el botón de generar el carnet.
                        vm.donor = null;
                    }
                })
                .catch(error => {
                    console.error(error);
                });
            },

            /**
             * Método que obtiene los datos de los parentescos.
             *
             * @author Angelo Osorio <adosorio@cenditel.gob.ve>
             */
            getKinship(url) {
                const vm = this;
                const query = `${window.url}/${url}`;
                axios.get(query).then(response => {
                    vm.relationships = response.data.result;
                    vm.relationships_family = response.data.result;
                    vm.relationships.unshift({
                        id: '',
                        name: 'Seleccione...'
                    });
                }).catch(error => {
                    console.error(error);
                });
            },

            /**
             * Método que desmarca el checkbox hermano del que lo dispara si está marcado.
             *
             * @author Angelo Osorio <adosorio@cenditel.gob.ve>
             */
            asRadio(adjacent) {
                const vm = this;
                let sibling = vm.record.partialDonors.indexOf(adjacent);

                if (sibling != -1) {
                    vm.record.partialDonors.splice(sibling, 1);
                }
            }
        },

        async mounted() {
            const vm = this;
            vm.loadingState(true); // Inicio de spinner de carga.

            // Obtener el listado de ocupaciones.
            await vm.getRecords(vm.occupations_url, "occupations", true);

            // Obtener el listado de los grupos sanguíneos.
            await vm.getRecords('global-data-type/blood_group', 'blood_groups');

            $('#phone, #movil').on('input', function(event) {
                if(event.target.id == 'phone'){
                    vm.record.phone = event.target.value.replace(/[^0-9]/g, '');
                }
                if(event.target.id == 'movil'){
                    vm.record.movil = event.target.value.replace(/[^0-9]/g, '');
                }
            });

            $('#estate_id').on('change', () => {
                vm.parishes = [];
                vm.record.municipality_id = '';
                vm.record.parish_id = '';
            });

            let today = new Date();
            vm.record.registered_at = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`;
            vm.record.donorType = (vm.$parent.user.profile.donor) ? 'DN' : ((vm.$parent.user.profile.not_donor) ? 'ND' : '');
            vm.record.email = vm.$parent.user.email;
            vm.record.user_id = vm.$parent.user.profile.user_id;

            axios.get(`${window.url}` + "/profiles/" + vm.$parent.user.id).then(async response => {
                let profile = response.data.profile;
                vm.record.names = profile.names;
                vm.record.surnames = profile.surnames;
                vm.record.dni = profile.dni;
                vm.selectedOptions.nationality = profile.nationality;
                vm.selectedOptions.gender = profile.gender;
                vm.selectedOptions.ocupation = (profile.ocupation)?parseInt(profile.ocupation):'';
                vm.selectedOptions.marital_status = profile.marital_status;
                vm.selectedOptions.academic_level = profile.academic_level;
                vm.record.birthdate = profile.birthdate;
                vm.record.birthplace = profile.birthplace;
                vm.record.age = profile.age;
                vm.selectedOptions.estate_id = (profile.estate_id)?parseInt(profile.estate_id):'';
                await vm.getMunicipalities(parseInt(profile.estate_id));
                vm.selectedOptions.municipality_id = (profile.municipality_id)?parseInt(profile.municipality_id):'';
                await vm.getParishes(parseInt(profile.municipality_id));
                vm.selectedOptions.parish_id = (profile.parish_id)?parseInt(profile.parish_id):'';
                vm.record.phone = profile.phone;
                vm.record.movil = profile.movil;
                vm.record.status = 1;
                vm.getEstates();
                vm.getMunicipalities();
                vm.getParishes();
            })
            .catch((error) => {
                console.log(error)
            });
            await vm.getRecord(vm.url_donor, vm.record.user_id);
            await vm.getKinship('global-data-type/kinship');

            // Obtener los registros de los niveles académicos.
            await vm.getRecords("global-data-type/academic_level", "academic_levels");

            if (vm.record.donorType == 'ND') {
                vm.record.donorSchema = "T";
            }

            vm.today = vm.getCurrentDate();

            $("#estate_id").on("change", () => {
                vm.parishes = [];
                vm.record.municipality_id = "";
                vm.record.parish_id = "";
            });
            vm.loadingState(); // Finaliza spinner de carga.
            if (vm.record.age < 18) {
                // Redireccionar al componente anterior si es menor de edad.
                setTimeout(() => vm.$router.go(-1), 800);
            }
        },

        watch: {
            /**
             * Método que reinicia el campo fecha de nacimiento cuando el año supera los 4 digitos
             * 
             * @param {*} newValue 
             */
                "record.birthdate": function (newValue) {
                const vm = this;
                let date = newValue.split("-");
                let current_year = new Date().getFullYear();
                vm.record.birthdate = date ? parseInt(date[0]) > current_year ? `${current_year}-${date[1]}-${date[2]}` : newValue : "";
            },
        }
    };
</script>
