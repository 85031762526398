<template>
    <div class="row justify-content-center">
        <div class="col-12">
            <h2 class="font-open mb-3">Bienvenido al SINIDOT</h2>
        </div>
        <div class="col-10 col-lg px-lg-0 mb-3">
            <div class="card rounded-0 text-light bg-primary h-100">
                <div class="card-header landing-card-header">
                    <p class="mb-0 text-center">
                        <img class="img-icon" src="images/persona.png"
                            alt="Personas necesitan un trasplante en este momento" />
                    </p>
                    <h3 class="landing-titles">
                        Personas que necesitan un trasplante en este momento
                    </h3>
                </div>
                <div class="card-body">
                    <h4 class="card-title text-center text-light">
                        {{ transplant_request_list }}
                    </h4>
                    <p class="card-text">
                        <ul class="fa-ul">
                            <li>
                                <img class="img-list" src="images/renal.png" alt="Renal" />
                                Renal
                            </li>
                            <li>
                                <img class="img-list" src="images/hepatico.png" alt="Hepático" />
                                Hepático
                            </li>
                            <li>
                                <img class="img-list" src="images/medula.png" alt="Médula Osea" />
                                Médula Osea
                            </li>
                            <li>
                                <img class="img-list" src="images/cornea.png" alt="Córnea" />
                                Córnea
                            </li>
                            <li>
                                <img class="img-list" src="images/corazon.png" alt="Válvulas cardíacas" />
                                Válvulas cardíacas
                            </li>
                        </ul>
                    </p>
                    <h5 class="text-center">
                        <router-link class="text-light" v-if="role"
                            :to="{name: 'panel-transplant-request-list'}"
                        >
                            Ver índice
                        </router-link>
                    </h5>
                </div>
            </div>
        </div>
        <div class="col-10 col-lg px-lg-0 mb-3">
            <div class="card rounded-0 text-light bg-secondary h-100">
                <div class="card-header landing-card-header">
                    <p class="mb-0 text-center">
                        <img class="img-icon" src="images/cantidad.png"
                            alt="Personas necesitan un trasplante en este momento" />
                    </p>
                    <h3 class="landing-titles">
                        Trasplantes realizados durante este año ({{ current_year }})
                    </h3>
                </div>
                <div class="card-body">
                    <h4 class="card-title text-center text-light">
                        {{ current_year_transplant_performed_count }}
                    </h4>
                    <p class="card-text">
                        <ul class="fa-ul">
                            <li>
                                <img class="img-list" src="images/renal.png" alt="Renal" />
                                Renal
                            </li>
                            <li>
                                <img class="img-list" src="images/hepatico.png" alt="Hepático" />
                                Hepático
                            </li>
                            <li>
                                <img class="img-list" src="images/medula.png" alt="Médula Osea" />
                                Médula Osea
                            </li>
                            <li>
                                <img class="img-list" src="images/cornea.png" alt="Córnea" />
                                Córnea
                            </li>
                            <li>
                                <img class="img-list" src="images/corazon.png" alt="Válvulas cardíacas" />
                                Válvulas cardíacas
                            </li>
                        </ul>
                    </p>
                    <h5 class="text-center">
                        <router-link class="text-light" v-if="role"
                            :to="{name: 'panel-transplants-performed-list'}"
                        >
                            Ver índice
                        </router-link>
                    </h5>
                </div>
            </div>
        </div>
        <div class="col-10 col-lg px-lg-0 mb-3">
            <div class="card rounded-0 text-light bg-primary h-100">
                <div class="card-header landing-card-header">
                    <p class="mb-0 text-center">
                        <img class="img-icon" src="images/donante.png"
                            alt="Personas necesitan un trasplante en este momento" />
                    </p>
                    <h3 class="landing-titles">
                        Personas que donaron sus órganos durante este año ({{ current_year }})
                    </h3>
                </div>
                <div class="card-body">
                    <h4 class="card-title text-center text-light">
                        {{ current_year_donors_count }}
                    </h4>
                    <p class="card-text">
                        <ul class="fa-ul">
                            <li>
                                <img class="img-list" src="images/renal.png" alt="Renal" />
                                Renal
                            </li>
                            <li>
                                <img class="img-list" src="images/hepatico.png" alt="Hepático" />
                                Hepático
                            </li>
                            <li>
                                <img class="img-list" src="images/medula.png" alt="Médula Osea" />
                                Médula Osea
                            </li>
                            <li>
                                <img class="img-list" src="images/cornea.png" alt="Córnea" />
                                Córnea
                            </li>
                            <li>
                                <img class="img-list" src="images/corazon.png" alt="Válvulas cardíacas" />
                                Válvulas cardíacas
                            </li>
                        </ul>
                    </p>
                    <h5 class="text-center">
                        <router-link class="text-light" v-if="role"
                            :to="{name: 'panel-effective-donor-list'}"
                        >
                            Ver índice
                        </router-link>
                    </h5>
                </div>
            </div>
        </div>
        <div class="col-10 col-lg px-lg-0 mb-3">
            <div class="card rounded-0 text-light bg-secondary h-100">
                <div class="card-header landing-card-header">
                    <p class="mb-0 text-center">
                        <img class="img-icon" src="images/paciente.png"
                            alt="Personas necesitan un trasplante en este momento" />
                    </p>
                    <h3 class="landing-titles">
                        Donantes por cada millón de habitantes durante este año ({{ current_year }})
                    </h3>
                </div>
                <div class="card-body">
                    <h4 class="card-title text-center text-light">
                        {{ donors_per_million_inhabitants }}
                    </h4>
                    <p class="card-text">
                        <ul class="fa-ul">
                            <li>
                                <img class="img-list" src="images/renal.png" alt="Renal" />
                                Renal
                            </li>
                            <li>
                                <img class="img-list" src="images/hepatico.png" alt="Hepático" />
                                Hepático
                            </li>
                            <li>
                                <img class="img-list" src="images/medula.png" alt="Médula Osea" />
                                Médula Osea
                            </li>
                            <li>
                                <img class="img-list" src="images/cornea.png" alt="Córnea" />
                                Córnea
                            </li>
                            <li>
                                <img class="img-list" src="images/corazon.png" alt="Válvulas cardíacas" />
                                Válvulas cardíacas
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-10 col-lg px-lg-0 mb-3">
            <div class="card rounded-0 text-light bg-primary h-100">
                <div class="card-header landing-card-header">
                    <p class="mb-0 text-center">
                        <img class="img-icon" src="images/cantidad.png"
                            alt="Cantidad de personas que expresaron su voluntad de donar" />
                    </p>
                    <h3 class="landing-titles">
                        Personas que expresaron su voluntad de donar
                    </h3>
                </div>
                <div class="card-body">
                    <h4 class="card-title text-center text-light">
                        {{ voluntary_donors }}
                    </h4>
                    <p class="card-text">
                        <ul class="fa-ul">
                            <li>
                                <img class="img-list" src="images/renal.png" alt="Renal" />
                                Renal
                            </li>
                            <li>
                                <img class="img-list" src="images/hepatico.png" alt="Hepático" />
                                Hepático
                            </li>
                            <li>
                                <img class="img-list" src="images/medula.png" alt="Médula Osea" />
                                Médula Osea
                            </li>
                            <li>
                                <img class="img-list" src="images/cornea.png" alt="Córnea" />
                                Córnea
                            </li>
                            <li>
                                <img class="img-list" src="images/corazon.png" alt="Válvulas cardíacas" />
                                Válvulas cardíacas
                            </li>
                        </ul>
                    </p>
                    <h5 class="text-center">
                        <router-link class="text-light" v-if="role"
                            :to="{name: 'panel-voluntary-donors-list'}"
                        >
                            Ver índice
                        </router-link>
                    </h5>
                </div>
            </div>
        </div>
        <div class="col-10 col-lg px-lg-0 mb-3">
            <div class="card rounded-0 text-primary h-100 bg-light justify-content-center align-items-center">
                <img class="img-fluid landing-img-voluntad p-4" src="images/voluntad.png"
                    alt="Personas necesitan un trasplante en este momento" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
        data() {
            return {
                url_transplant_request_list: "api/countaplicant",
                transplant_request_list: '',
                url_current_year_transplant_performed: 'api/countransplantperformed',
                current_year_transplant_performed_count: '',
                url_current_year_donors: 'api/counteffectivedonor',
                current_year_donors_count: '',
                url_donors_per_million_inhabitants: 'api/countdonorspermillioninhabitants',
                donors_per_million_inhabitants: '',
                url_voluntary_donors: 'api/countdonor',
                voluntary_donors: '',
                effective_donors: [],
                role: "",
            }
        },
        methods: {
            /**
             * Método que determina si el usuario es de FUNDAVENE o no.
             *
             * @method getUserRole
             *
             * @author Angelo Osorio <adosorio@@cenditel.gob.ve> | <danielking.321@gmail.com>
             */
            getUserRole() {
                const vm = this;
                const role = vm.$parent.user.role;
                if (role == "ADM" || role == "SUP") {
                    return true;
                }
                return false;
            },

            /**
             * Método que calcula los donantes por millon de habitantes.
             *
             * @method calculateDonorsPerMillionInhabitants
             *
             * @author José Briceño <jbriceno@cenditel.gob.ve>
             */
            calculateDonorsPerMillionInhabitants() {
                const vm = this;
                vm.donors_per_million_inhabitants = Math.round((vm.current_year_donors_count / 30000000) * 1000000); 
            }
        },
        created() {
            /**
             * Muestra el año actual en el tablero.
             *
             * @author Argenis Osorio <aosorio@cenditel.gob.ve>
             */
            const vm = this;
            var currentTime = new Date();
            vm.current_year = currentTime.getFullYear()
        },
        async mounted() {
            const vm = this;
            vm.loadingState(true); // Inicio de spinner de carga.
            /**
             * Consulta la lista de solicitudes de trasplantes realizados y la
             * lista de personas que expresaron su voluntad de donar.
             *
             * @author Argenis Osorio <aosorio@cenditel.gob.ve>
             * @author Angelo Osorio <adosorio@cenditel.gob.ve>
             */
            await vm.getRecords(vm.url_transplant_request_list, "transplant_request_list");
            await vm.getRecords(vm.url_current_year_transplant_performed, "current_year_transplant_performed_count");
            await vm.getRecords(vm.url_current_year_donors, "current_year_donors_count");
            await vm.getRecords(vm.url_donors_per_million_inhabitants, "donors_per_million_inhabitants");
            await vm.getRecords(vm.url_voluntary_donors, "voluntary_donors");

            // Obtiene el rol de usuario autenticado y se lo pasa a la variable role.
            vm.role = vm.getUserRole();
            vm.loadingState(); // Finaliza spinner de carga.
        },
    };
</script>
