<template>
    <!-- transplant-request-decline-component -->
    <div id="transplant-request-decline-component">
        <go-back title="Regresar"/>
        <!-- Inicio de formulario -->
        <div class="card mb-4 shadow-sm">
            <h2 class="h3 text-center text-primary text-uppercase py-3
                border-bottom border-primary"
            >
                <i class="fa fa-pencil-alt"></i> Rechazar solicitud de trasplante
            </h2>
            <!-- Inicio de card-body -->
            <div class="card-body">
                <form-errors :errors="errors"></form-errors>
                <h3 class="h5 text-secondary text-uppercase">
                    Datos del usuario que rechaza la solicitud
                </h3>
                <hr />
                <div class="row pb-4">
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="agent_name" class="form-label">
                                Nombres
                            </label>
                            <input id="agent_name" type="text"
                                class="form-control" disabled
                                v-model="record.agent_names">
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="agent_surnames" class="form-label">
                                Apellidos
                            </label>
                            <input id="agent_surnames" type="text"
                                class="form-control" disabled
                                v-model="record.agent_surnames">
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="agent_mail" class="form-label">
                                Correo electrónico
                            </label>
                            <input id="agent_mail" type="text"
                                class="form-control" disabled
                                v-model="record.agent_mail">
                        </div>
                    </div>
                </div>
                <!-- Final de datos del usuario que realiza la gestión de rechazar la solicitud -->

                <!-- Datos del solicitante -->
                <h3 class="h5 text-secondary text-uppercase">
                    Datos del solicitante
                </h3>
                <hr />
                <div class="row pb-4">
                    <!-- Fecha de registro -->
                    <div class="col-12 col-lg-2 mb-3">
                        <label for="created_at" class="form-label">
                            Fecha de registro
                        </label>
                        <input
                            id="created_at"
                            class="form-control"
                            type="text"
                            disabled
                            :value="
                                new Date(
                                    aplicant.created_at
                                ).toLocaleDateString('en-GB')
                            "
                        />
                    </div>

                    <!-- Serial N° -->
                    <div class="col-12 col-lg-2 mb-3">
                        <label for="number" class="form-label">
                            Serial N°
                        </label>
                        <input
                            id="number"
                            class="form-control"
                            type="text"
                            disabled
                            :value="aplicant.number"
                        />
                    </div>
                </div>

                <div class="row pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="names" class="form-label">
                            Nombres</label>
                        <input
                            id="names"
                            class="form-control"
                            type="text"
                            disabled
                            :value="profile.names"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="surnames" class="form-label">
                            Apellidos
                        </label>
                        <input
                            id="surnames"
                            class="form-control"
                            type="text"
                            disabled
                            :value="profile.surnames"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="dni" class="form-label">
                            Cédula de identidad
                        </label>
                        <input
                            id="dni"
                            class="form-control"
                            type="text"
                            disabled
                            :value="profile.dni"
                        />
                    </div>
                </div>
                <!-- Final de datos del solicitante -->

                <div class="row">
                    <div class="col-12 col-lg-12">
                        <label for="rejection_reason" class="form-label">
                            Razones por las que se rechaza la solicitud <span class="text-danger">*</span>
                        </label>
                        <textarea rows="5" class="form-control"
                            v-model="record.rejection_reason"
                        >
                        </textarea>
                    </div>
                </div>
            </div>
            <!-- Final de card-body -->

            <!-- Inicio de card-footer -->
            <div class="card-footer text-center">
                <button type="button" class="btn btn-outline-primary-dark"
                    @click="reset()"
                >
                    Limpiar
                </button>
                <button type="button" class="btn btn-primary"
                    @click="setRecord('transplant-request-rejected')"
                >
                    <i class="fa fa-save"></i>
                    Guardar
                </button>
            </div>
            <!-- Final de card-footer -->
        </div>
        <!-- Final de formulario -->
    </div>
    <!-- Final de transplant-request-decline-component -->
</template>

<script>
export default {
    data() {
        return {
            errors: [],
            records: [],
            aplicant: [],
            profile: [],
            record: {
                aplicant_id:"",
                methods:"decline",
                status: '6',
                rejection_reason: '',
            },
            url: "manage-aplicant",
        }
    },
    methods: {
        /**
         * Método para reestablecer valores iniciales del formulario
         *
         * @method reset
         *
         * @author Francisco Escala <fjescala@gmail.com>
         * 
         *
         */
        reset() {
            const vm = this;
            vm.errors = [];
            vm.record.rejection_reason = '';
        },
        /**
         * Ejecuta la acción para guardar o actualizar datos
         *
         * @method setRecord
         * @author Francisco Escala <fjescala@gmail.com>
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         *
         * @param  {String}  url URL de la petición a realizar
         */
        async setRecord(url) {
            const vm = this;
            const recordId = (typeof (vm.record.id) !== "undefined" && vm.record.id) ? vm.record.id : '';
            vm.loadingState(true); // Inicio de spinner de carga.
            await axios({
                method: (recordId) ? 'put' : 'post',
                url: `${window.url}/${url}/${recordId}`,
                data: vm.record
            })
            .then(response => {
                vm.loadingState(); // Finaliza spinner de carga.
                vm.getRecords(vm.url);
                vm.showMessage('updated');
                // Redireccionar al componente anterior luego del guardado.
                setTimeout(() => vm.$router.go(-1), 3000);
            })
            .catch(error => {
                vm.loadingState(); // Finaliza spinner de carga.
                vm.setErrors(error);
            });
        },
    },
    async mounted() {
        const vm = this;
        vm.loadingState(true); // Inicio de spinner de carga.
        /** Datos del usuario gestor de fundavene */
        vm.record.agent_names = vm.$parent.user.profile.names;
        vm.record.agent_surnames = vm.$parent.user.profile.surnames;
        vm.record.agent_mail = vm.$parent.user.email;
        vm.record.agent_user_id = vm.$parent.user.profile.user_id;
        vm.record.aplicant_id = vm.$route.params.id;
        await vm.getRecords(`${vm.url}/${vm.record.aplicant_id}`, "aplicant");
        vm.profile = vm.aplicant.profile;
        vm.record.rejection_reason = vm.aplicant.rejection_reason;
        vm.loadingState(); // Finaliza spinner de carga.
    },
}
</script>
