<template>
    <div id="potential-donor-record-list-component">
        <div class="card mb-4 shadow-sm">
            <h2 class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary">
                <i class="fa fa-th-list"></i> Donantes potenciales
            </h2>
            <div class="card-body">
                <!-- Filtros de la tabla -->
                <div class="row">
                    <div class="col-12 col-lg-3 mb-3">
                        <label class="form-label">Cédula de identidad</label>
                        <input
                            class="form-control"
                            type="text"
                            minlength="7"
                            maxlength="9"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Cédula de identidad"
                            v-model="filterBy.dni"
                        />
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label class="form-label">Donación</label>
                        <v-select
                            id="donationOption"
                            label="text"
                            placeholder="-- Seleccione --"
                            :options="donationOptions"
                            :reduce="donationOption => donationOption.id"
                            :selectable="option => option.id !== ''"
                            v-model="filterSelectedOptions.donationOption"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label class="form-label">Género</label>
                        <v-select
                            v-model="filterSelectedOptions.genderOption"
                            :options="genderOptions"
                            :reduce="genderOption => genderOption.id"
                            label="text"
                            id="genderOption"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label class="form-label">Edad (Mayor que)</label>
                        <input
                            class="form-control"
                            type="text"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Edad (Mayor que)"
                            v-model="filterBy.fromAge"
                        />
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label class="form-label">Edad (Menor que)</label>
                        <input 
                            class="form-control"
                            type="text"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Edad (Menor que)"
                            v-model="filterBy.toAge"
                        />
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label class="form-label">Grupo sanguíneo</label>
                        <v-select
                            id="blodGroupOption"
                            label="name"
                            placeholder="-- Seleccione --"
                            :options="blood_groups"
                            :reduce="blodGroupOption => blodGroupOption.id"
                            :selectable="option => option.id !== ''"
                            v-model="filterSelectedOptions.blodGroupOption"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label class="form-label">Factor RH</label>
                        <v-select
                            id="rhFactorOption"
                            label="text"
                            placeholder="-- Seleccione --"
                            :options="rhFactorOptions"
                            :reduce="rhFactorOption => rhFactorOption.id"
                            :selectable="option => option.id !== ''"
                            v-model="filterSelectedOptions.rhFactorOption"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label class="form-label" for="estate_id">
                            Estado
                        </label>
                        <v-select
                            id="estate_id"
                            label="text"
                            placeholder="-- Seleccione --"
                            :options="estates"
                            :reduce="estate => estate.id"
                            :selectable="option => option.id !== ''"
                            :tabindex="13"
                            v-model="filterSelectedOptions.estate_id"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="row pb-4">
                        <div class="col-12 col-lg-3">
                            <button class="btn btn-outline-primary-dark"
                                type="reset" @click="resetFilters()">
                                Limpiar
                            </button>
                            <button class="btn btn-outline-primary-dark"
                                type="button" aria-label="Search"
                                @click="filterDonors">
                                Buscar
                            </button>
                        </div>
                    </div>
                </div>
                <!--/ Filtros de la tabla -->

                <!-- Tabla -->
                <v-client-table :data="records" :columns="columns" :options="table_options">
                    <div slot="profile" slot-scope="props">
                        {{props.row.profile.names}} {{props.row.profile.surnames}}
                    </div>
                    <div slot="donorSchema" slot-scope="props">
                        {{ (props.row.donorSchema === 'T') ? 'Total' : 'Parcial' }}
                    </div>
                    <div slot="id" slot-scope="props" class="main-flex-buttons">
                        <button class="btn btn-outline-primary-dark" type="button"
                            title="Ver registro"
                            @click="showInfo(props.row.id)">
                            <i class="fa fa-eye"></i>
                        </button>
                        <router-link
                            v-if="(user_role == 'ADM') || (user_role == 'CHO') || (user_role == 'PIN')"
                            type="button" class="btn btn-outline-primary"
                            title="Actualizar Donante potencial"
                            :to="{
                                name: 'potential-donor-registry-management',
                                params:{
                                    id: props.row.id,
                                }
                            }"
                        >
                            <i class="fa fa-edit"></i>
                        </router-link>
                        <router-link
                            v-if="(user_role == 'ADM') || (user_role == 'CHO')"
                            type="button" class="btn btn-outline-primary"
                            title="Gestionar Donante efectivo"
                            :to="{
                                name: 'effective-donor-registry-management',
                                params:{
                                    id: props.row.id,
                                }
                            }"
                        >
                            <i class="fa fa-arrow-right"></i>
                        </router-link>
                        <router-link
                            v-if="(user_role == 'ADM') || (user_role == 'CHO')"
                            type="button" class="btn btn-outline-primary"
                            title="Gestionar Donante No apto"
                            :to="{
                                name: 'not-apt-donor-registry-management',
                                params:{
                                    id: props.row.id,
                                }
                            }"
                        >
                            <i class="fa fa-user-times"></i>
                        </router-link>
                    </div>
                </v-client-table>
                <!--/ Tabla -->

                <!-- Modal -->
                <div id="show_potential_donor" class="modal fade" role="dialog"
                    tabindex="-1">
                    <div class="modal-dialog modal-dialog-centered modal-xl"
                        role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4>
                                    Información detallada del Donante potencial
                                </h4>
                                <button
                                    class="btn-close"
                                    type="button"
                                    aria-label="Close"
                                    data-bs-dismiss="modal"
                                ></button>
                            </div>
                            <div class="modal-body">
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-2 mb-3">
                                        <label for="created_at" class="form-label">
                                            Fecha de registro
                                        </label>
                                        <input
                                            id="created_at"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="new Date(modalQuery.created_at).toLocaleDateString('en-GB')"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-2 mb-3">
                                        <label for="number" class="form-label">
                                            Serial N°
                                        </label>
                                        <input id="number" class="form-control"
                                            type="text" disabled
                                            :value="modalQuery.number"
                                        />
                                    </div>
                                </div>

                                <!-- Datos del usuario que realiza el registro -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Datos del usuario que realizó el registro
                                </h3>
                                <hr>
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="agent_names" class="form-label">
                                            Nombres
                                        </label>
                                        <input
                                            id="agent_names"
                                            class="form-control"
                                            type="text" disabled
                                            :value="modalQuery && modalQuery.agent_info ? modalQuery.agent_info.names : ''"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="agent_surnames" class="form-label">
                                            Apellidos
                                        </label>
                                        <input
                                            id="agent_surnames"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery && modalQuery.agent_info ? modalQuery.agent_info.surnames : ''"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="agent_mail" class="form-label">
                                            Correo electrónico
                                        </label>
                                        <input
                                            id="agent_mail"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery && modalQuery.agent_info ? modalQuery.agent_info.email : ''"
                                        />
                                    </div>
                                </div>

                                <!-- Datos del donante  -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Datos del donante potencial
                                </h3>
                                <hr>
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="names" class="form-label">
                                            Nombres
                                        </label>
                                        <input
                                            id="names"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="profile.names"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="surnames" class="form-label">
                                            Apellidos
                                        </label>
                                        <input
                                            id="surnames"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="profile.surnames"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="dni" class="form-label">
                                            Cédula de identidad
                                        </label>
                                        <input
                                            id="dni"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="profile.dni"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="nationality" class="form-label">
                                            Nacionalidad
                                        </label>
                                        <input
                                            id="nationality"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="profile.nationality == 'V' ? 'Venezolano(a)' : 'Extranjero(a)'"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="gender" class="form-label">
                                            Género
                                        </label>
                                        <input
                                            id="gender"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="showGender(profile.gender)"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="marital_status" class="form-label">
                                            Estado civil
                                        </label>
                                        <input
                                            id="marital_status"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="showMaritalStatus(profile.marital_status)"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="ocupation" class="form-label">
                                            Ocupación
                                        </label>
                                        <input
                                            id="ocupation"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="profile.ocupation ? profile.ocupation.name : ''"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="academic_level" class="form-label">
                                            Nivel académico
                                        </label>
                                        <input
                                            id="academic_level"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="profile.academic_level"
                                        />
                                    </div>
                                </div>

                                <!-- Edad -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Edad
                                </h3>
                                <hr>
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="birthdate" class="form-label">
                                            Fecha de nacimiento
                                        </label>
                                        <input
                                            id="birthdate"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="new Date(profile.birthdate).toLocaleDateString('en-GB', {timeZone: 'UTC'})"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="age" class="form-label">
                                            Edad
                                        </label>
                                        <input
                                            id="age"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="profile.age"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="birthplace" class="form-label">
                                            Lugar de nacimiento
                                        </label>
                                        <input
                                            id="birthplace"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="profile.birthplace"
                                        />
                                    </div>
                                </div>

                                <!-- Dirección -->
                                <h3 class="h5 text-secondary text-uppercase">Dirección</h3>
                                <hr>
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="estate" class="form-label">
                                            Estado
                                        </label>
                                        <input
                                            id="estate"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="location.estate"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="municipality" class="form-label">
                                            Municipio
                                        </label>
                                        <input
                                            id="municipality"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="location.municipality"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="parish" class="form-label">
                                            Parroquía
                                        </label>
                                        <input
                                            id="parish"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="location.parish"
                                        />
                                    </div>
                                </div>

                                <!-- Contacto -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Contacto
                                </h3>
                                <hr>
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="phone" class="form-label">
                                            Teléfono local
                                        </label>
                                        <input
                                            id="phone"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="profile.phone"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="movil" class="form-label">
                                            Teléfono móvil
                                        </label>
                                        <input
                                            id="movil"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="profile.movil"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="email" class="form-label">
                                            Correo electrónico
                                        </label>
                                        <input
                                            id="email"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="profile_email"
                                        />
                                    </div>
                                </div>

                                <!-- Datos de la Donación -->
                                <h3 class="h5 text-secondary text-uppercase">Datos de la Donación</h3>
                                <hr>
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="donor" class="form-label">Donación</label>
                                        <input id="donor" class="form-control" type="text" disabled
                                            :value="(modalQuery.donorSchema === 'T')? 'Total' : 'Parcial'" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-4" v-if="modalQuery.partial_organs">
                                        <div v-if="getPartial(modalQuery.partial_organs,'therapeutic').length > 0">
                                            <h6>Terapeútico (trasplante):</h6>
                                            <ul>
                                                <li v-for="organ in getPartial(modalQuery.partial_organs,'therapeutic')">
                                                    <span class="small">{{ getNameOrgan(organ) + '.' }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3" v-if="modalQuery.partial_organs">
                                        <div v-if="getPartial(modalQuery.partial_organs,'reasearch').length > 0">
                                            <h6>Investigación o Docencia:</h6>
                                            <ul>
                                                <li v-for="organ in getPartial(modalQuery.partial_organs,'reasearch')">
                                                    <span class="small">{{ getNameOrgan(organ) + '.'}}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="health_center"
                                            class="form-label"
                                        >
                                            Centro generador de órganos y tejidos
                                        </label>
                                        <input id="health_center"
                                            class="form-control" type="text"
                                            disabled
                                            :value="modalQuery.health_center ? modalQuery.health_center.name : ''"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="blood_group"
                                            class="form-label"
                                        >
                                            Grupo sanguíneo
                                        </label>
                                        <input id="blood_group"
                                            class="form-control" type="text"
                                            disabled
                                            :value="getBloodType(modalQuery.blood_group)"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="size" class="form-label">Factor RH</label>
                                        <input id="size" class="form-control" type="text" disabled
                                            :value="modalQuery.rh_factor == 'P' ? 'Positivo' : 'Negativo'" />
                                    </div>
                                </div>
                                <!-- Exámenes médicos  -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Exámenes médicos
                                </h3>
                                <hr>
                                <h4 class="h5 text-secondary">
                                    Resultados de serología
                                </h4>
                                <div class="row pb-4">
                                    <div class="col-12 mb-3">
                                        <div class="row">
                                            <div class="col-12 col-lg-10">
                                                <label for="refusal_file" class="form-label">
                                                    Examen de serología
                                                </label>
                                            </div>
                                            <div class="col-12 col-lg-2 d-flex justify-content-center justify-content-lg-end">
                                                <button 
                                                    class="btn btn-outline-primary-dark"
                                                    @click="downloadFile(modalQuery.serology_test_file,'Examen de serología')"
                                                    :disabled="modalQuery.serology_test_file === ''"
                                                >
                                                    <i class="fa fa-download"></i>
                                                    Descargar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <label for="family_refusal_reason" class="form-label">
                                            Observación
                                        </label>
                                        <textarea 
                                            class="form-control"
                                            disabled
                                            rows="3"
                                            tabindex="1"
                                            :value="modalQuery.serology_test_observation"
                                        >
                                        </textarea>
                                    </div>
                                </div>
                                <!-- Final Resultados de serología -->

                                <!-- Recepción de muestras -->
                                <h4 class="h5 text-secondary">
                                    Recepción de muestras
                                </h4>
                                <div class="card p-3">
                                    <!-- Ganglios -->
                                    <div class="row pt-2 pb-4">
                                        <div class="col-12 col-lg-2 d-flex justify-content-center align-items-center">
                                            <h6>Ganglios</h6>
                                        </div>
                                        <div class="col-12 col-lg-4 col-xl-3 pb-2">
                                            <label class="fw-bold">Cantidad</label>
                                            <input
                                                id="ganglios_quantity"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="modalQuery.ganglios_quantity"
                                            />
                                        </div>
                                        <div class="col-12 col-lg-6 col-xl-4">
                                            <label class="fw-bold">Observación</label>
                                            <textarea
                                                class="form-control"
                                                disabled
                                                rows="3"
                                                :value="modalQuery.ganglios_observation"
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                    <!-- Final Ganglios -->
    
                                    <!-- Tubo con EDTA -->
                                    <div class="row pb-4">
                                        <div class="col-12 col-lg-2 d-flex justify-content-center align-items-center">
                                            <h6>Tubo con EDTA</h6>
                                        </div>
                                        <div class="col-12 col-lg-4 col-xl-3 pb-2">
                                            <label class="fw-bold">Cantidad</label>
                                            <input
                                                id="tube_edta_quantity"
                                                type="text"
                                                class="form-control"
                                                :value="modalQuery.tube_edta_quantity"
                                                disabled
                                            />
                                        </div>
                                        <div class="col-12 col-lg-6 col-xl-4">
                                            <label class="fw-bold">Observación</label>
                                            <textarea
                                                class="form-control"
                                                disabled
                                                rows="3"
                                                :value="modalQuery.tube_edta_observation"
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                    <!-- Final Tubo con EDTA -->

                                    <!-- Tubo sin aditivos -->
                                    <div class="row pb-4">
                                        <div class="col-12 col-lg-2 d-flex justify-content-center align-items-center">
                                            <h6>Tubo sin aditivos</h6>
                                        </div>
                                        <div class="col-12 col-lg-4 col-xl-3 pb-2">
                                            <label class="fw-bold">Cantidad</label>
                                            <input
                                                id="tube_w_additives_quantity"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="modalQuery.tube_w_additives_quantity"
                                            />
                                        </div>
                                        <div class="col-12 col-lg-6 col-xl-4">
                                            <label class="fw-bold">Observación</label>
                                            <textarea
                                                class="form-control"
                                                disabled
                                                rows="3"
                                                :value="modalQuery.tube_w_additives_observation"
                                            >
                                            </textarea>
                                        </div>
                                        <!-- Final Tubo sin aditivos -->
                                    </div>
                                </div>
                                <!-- Final Recepción de muestras -->

                                <!-- Resultados de inmunología -->
                                <h4 class="h5 text-secondary mt-4">
                                    Resultados de inmunología
                                </h4>

                                <div class="card p-3">
                                    <div class="row pb-4">
                                        <!-- Títulos -->
                                        <div class="col-12 col-lg-3 offset-lg-1 order-0">
                                            <h5 class="h6 text-center mb-0 mt-3">Clase 1</h5>
                                            <hr>
                                        </div>
                                        <div class="col-12 col-lg-8 order-2 order-lg-1">
                                            <h5 class="h6 text-center mb-0 mt-3">Clase 2</h5>
                                            <hr>
                                        </div>

                                        <!-- Alelos 1 y 2 - A, B, C -->
                                        <div class="col-12 col-lg-4 order-1 order-lg-2">
                                            <!-- HLA, A, B, C -->
                                            <div class="row mb-3">
                                                <div class="col-3 text-center">
                                                    <span class="form-label">HLA</span>
                                                </div>
                                                <div class="col-3 text-center">
                                                    <span class="form-label">A</span>
                                                </div>
                                                <div class="col-3 text-center">
                                                    <span class="form-label">B</span>
                                                </div>
                                                <div class="col-3 text-center">
                                                    <span class="form-label">C</span>
                                                </div>
                                            </div>

                                            <!-- Alelo 1, 1A, 1B, 1C -->
                                            <div class="row mb-3 align-items-center">
                                                <div class="col-3 text-center">
                                                    <span class="form-label mb-1">Alelo 1</span>
                                                </div>
                                                <div class="col-3 text-center">
                                                    <input
                                                        id="allele_1_a"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="allele_1_a"
                                                    />
                                                </div>
                                                <div class="col-3 text-center">
                                                    <input
                                                        id="allele_1_b"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="allele_1_b"
                                                    />
                                                </div>
                                                <div class="col-3 text-center">
                                                    <input
                                                        id="allele_1_c"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="allele_1_c"
                                                    />
                                                </div>
                                            </div>

                                            <!-- Alelo 2, 2A, 2B, 2C -->
                                            <div class="row align-items-center">
                                                <div class="col-3 text-center">
                                                    <span class="form-label mb-1">Alelo 2</span>
                                                </div>
                                                <div class="col-3 text-center">
                                                    <input
                                                        id="allele_2_a"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="allele_2_a"
                                                    />
                                                </div>
                                                <div class="col-3 text-center">
                                                    <input
                                                        id="allele_2_b"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="allele_2_b"
                                                    />
                                                </div>
                                                <div class="col-3 text-center">
                                                    <input
                                                        id="allele_2_c"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="allele_2_c"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Alelos 1 y 2 - DRB1, DBQ1, DPB1 -->
                                        <div class="col-12 col-lg-3 order-3">
                                            <!-- HLA, DRB1, DBQ1, DPB1 -->
                                            <div class="row mb-3">
                                                <div class="col-3 d-lg-none text-center">
                                                    <span class="form-label">HLA</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <span class="form-label">DRB1</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <span class="form-label">DBQ1</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <span class="form-label">DPB1</span>
                                                </div>
                                            </div>

                                            <!-- Alelo 1, DRB1, DBQ1, DPB1 -->
                                            <div class="row mb-3 align-items-center">
                                                <div class="col-3 d-lg-none text-center">
                                                    <span class="form-label mb-1">Alelo 1</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_1_DRB1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="allele_1_DRB1"
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_1_DBQ1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="allele_1_DBQ1"
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_1_DPB1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="allele_1_DPB1"
                                                    />
                                                </div>
                                            </div>

                                            <!-- Alelo 2, DRB1, DBQ1, DPB1 -->
                                            <div class="row mb-5 mb-lg-0 align-items-center">
                                                <div class="col-3 d-lg-none text-center">
                                                    <span class="form-label mb-1">Alelo 2</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_2_DRB1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="allele_2_DRB1"
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_2_DBQ1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="allele_2_DBQ1"
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_2_DPB1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="allele_2_DPB1"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Alelos 1 y 2 - DQA1, DPA1, DRB3 -->
                                        <div class="col-12 col-lg-3 order-4">
                                            <!-- HLA, DQA1, DPA1, DRB3 -->
                                            <div class="row mb-3">
                                                <div class="col-3 d-lg-none text-center">
                                                    <span class="form-label">HLA</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <span class="form-label">DQA1</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <span class="form-label">DPA1</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <span class="form-label">DRB3</span>
                                                </div>
                                            </div>
                                            <!-- Alelo 1, DQA1, DPA1, DRB3 -->
                                            <div class="row mb-3 align-items-center">
                                                <div class="col-3 d-lg-none text-center">
                                                    <span class="form-label mb-1">Alelo 1</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_1_DQA1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="allele_1_DQA1"
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_1_DPA1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="allele_1_DPA1"
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_1_DRB3"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="allele_1_DRB3"
                                                    />
                                                </div>
                                            </div>

                                            <!-- Alelo 2, DQA1, DPA1, DRB3 -->
                                            <div class="row mb-5 mb-lg-0 align-items-center">
                                                <div class="col-3 d-lg-none text-center">
                                                    <span class="form-label mb-1">Alelo 2</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_2_DQA1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="allele_2_DQA1"
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_2_DPA1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="allele_2_DPA1"
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_2_DRB3"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="allele_2_DRB3"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Alelos 1 y 2 - DRB4, DRB5 -->
                                        <div class="col-12 col-lg-2 order-5">

                                            <!-- HLA, DRB4, DRB5 -->
                                            <div class="row mb-3">
                                                <div class="col-3 d-lg-none text-center">
                                                    <span class="form-label">HLA</span>
                                                </div>
                                                <div class="col-3 col-lg-6 text-center">
                                                    <span class="form-label">DRB4</span>
                                                </div>
                                                <div class="col-3 col-lg-6 text-center">
                                                    <span class="form-label">DRB5</span>
                                                </div>
                                            </div>
                                            <!-- Alelo 1, DRB4, DRB5 -->
                                            <div class="row mb-3 align-items-center">
                                                <div class="col-3 d-lg-none text-center">
                                                    <span class="form-label mb-1">Alelo 1</span>
                                                </div>
                                                <div class="col-3 col-lg-6 text-center">
                                                    <input
                                                        id="allele_1_DRB4"
                                                        class="form-control"
                                                        type="text"
                                                        :value="allele_1_DRB4"
                                                        disabled
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-6 text-center">
                                                    <input
                                                        id="allele_1_DRB5"
                                                        class="form-control"
                                                        type="text"
                                                        :value="allele_1_DRB5"
                                                        disabled
                                                    />
                                                </div>
                                            </div>

                                            <!-- Alelo 2, DRB4, DRB5 -->
                                            <div class="row align-items-center">
                                                <div class="col-3 d-lg-none text-center">
                                                    <span class="form-label mb-1">Alelo 2</span>
                                                </div>
                                                <div class="col-3 col-lg-6 text-center">
                                                    <input
                                                        id="allele_2_DRB4"
                                                        class="form-control"
                                                        type="text"
                                                        :value="allele_2_DRB4"
                                                        disabled
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-6 text-center">
                                                    <input
                                                        id="allele_2_DRB5"
                                                        class="form-control"
                                                        type="text"
                                                        :value="allele_2_DRB5"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Final Resultados de inmunología -->

                                <!-- Documentos requeridos -->
                                <h3 class="h5 text-secondary mt-4 text-uppercase">
                                    Documentos requeridos
                                </h3>
                                <hr>
                                <div class="row pb-2">
                                    <div class="col-12 col-lg-10">
                                        <label for="family_acceptance_document" class="form-label">
                                            Planilla de registro posterior a la información familiar
                                        </label>
                                    </div>
                                    <div class="col-12 col-lg-2 d-flex justify-content-center justify-content-lg-end">
                                        <button  class="btn btn-outline-primary-dark"
                                            @click="downloadFile(modalQuery.family_acceptance_document,'Planilla de registro posterior a la información familiar')"
                                            :disabled="modalQuery.family_acceptance_document === ''"
                                        >
                                            <i class="fa fa-download"></i>
                                            Descargar
                                        </button>
                                    </div>
                                </div>
                                <hr>
                                <h3 v-if="families.length" class="h5 text-secondary text-uppercase">
                                    Familiares con quienes se compartió la decisión
                                </h3>
                                <div v-if="families != null">
                                    <div class="row pb-4" v-for="(family, index) in families" :key="index">
                                        <div class="col-12 col-lg-6 mb-3">
                                            <label for="family_names" class="form-label">Nombres</label>
                                            <input id="family_names" class="form-control" type="text" disabled
                                                :value="family.names" />
                                        </div>
                                        <div class="col-12 col-lg-6 mb-3">
                                            <label for="family_surnames" class="form-label">Apellidos</label>
                                            <input id="family_surnames" class="form-control" type="text" disabled
                                                :value="family.surnames" />
                                        </div>
                                        <div class="col-12 col-lg-6 mb-3">
                                            <label for="family_phone" class="form-label">Teléfono</label>
                                            <input id="pfamily_hone" class="form-control" type="text" disabled
                                                :value="family.phone" />
                                        </div>
                                        <div class="col-12 col-lg-6 mb-3">
                                            <label for="family_relationship" class="form-label">Parentesco</label>
                                            <input id="family_relationship" class="form-control" type="text" disabled
                                                :value="getKinship(family.relationship)" />
                                        </div>
                                    </div>
                                </div>

                                <!-- Datos del personal de inmunología asociado
                                al registro de este donante potencial -->
                                <h4 class="h6 text-secondary">
                                    Datos del personal de inmunología asociado
                                    al registro de este Donante potencial
                                </h4>
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="doctor" class="form-label">
                                            Personal de inmunología <br> asociado
                                        </label>
                                        <input id="doctor" class="form-control"
                                            type="text" disabled
                                            :value="modalQuery.pin ? modalQuery.pin.name : ''"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="doctor" class="form-label">
                                            Correo electrónico de la unidad <br> de inmunología
                                        </label>
                                        <input
                                            id="tube_w_additives_quantity"
                                            name="email_uin"
                                            type="email"
                                            class="form-control"
                                            :value="modalQuery.email_uin"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer justify-content-center">
                                <button type="button" class="btn btn-outline-primary-dark"
                                    data-bs-dismiss="modal" aria-label="Close">
                                    Cerrar
                                </button>
                                <button type="button" class="btn btn-secondary-dark" @click="exportPDF()">
                                    <i class="fa fa-address-card"></i>
                                    Generar planilla
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!--/ Modal -->
            </div>
        </div>
    </div>
</template>

<script>
import base64images from '../../../base64images'
export default {
    name : 'PotentialDonorsListComponent',
    data() {
        return {
            records: [],
            tmpRecords: [],
            filterBy: {
                dni: '',
                fromAge: '',
                toAge: ''
            },
            families: [],
            kinship: [],
            filterSelectedOptions: {
                donationOption: "",
                genderOption: "",
                blodGroupOption: "",
                rhFactorOption: "",
                estate_id:"",
            },
            estates:[],
            rhFactorOptions: [
                { id: "P", text: "Positivo" },
                { id: "N", text: "Negativo" }
            ],
            donationOptions: [
                { id: "T", text: "Total" },
                { id: "P", text: "Parcial" }
            ],
            genderOptions: [
                { id: "M", text: "Masculino" },
                { id: "F", text: "Femenino" }
            ],
            modalQuery: {},
            user_role: '',
            profile: {},
            profile_email: '',
            organs: [],
            blood_groups: [],
            doctor: [],
            columns: [ 'number', 'profile', 'profile.dni', 'donorSchema', 'id' ],
            location: {
                parish:'',
                municipality:'',
                estate:'',
            },
            allele_1_a: '',
            allele_1_b: '',
            allele_1_c: '',
            allele_1_DRB1: '',
            allele_1_DBQ1: '',
            allele_1_DPB1: '',
            allele_1_DQA1: '',
            allele_1_DPA1: '',
            allele_1_DRB3: '',
            allele_1_DRB4: '',
            allele_1_DRB5: '',
            allele_2_a: '',
            allele_2_b: '',
            allele_2_c: '',
            allele_2_DRB1: '',
            allele_2_DBQ1: '',
            allele_2_DPB1: '',
            allele_2_DQA1: '',
            allele_2_DPA1: '',
            allele_2_DRB3: '',
            allele_2_DRB4: '',
            allele_2_DRB5: '',
            organs_list:{
                list: [
                    {
                        name: 'Riñon',
                        therapeutic: 'therapeutic_kidney',
                        research: 'reasearch_kidney'
                    },
                    {
                        name: 'Páncreas',
                        therapeutic: 'therapeutic_pancreas',
                        research: 'reasearch_pancreas'
                    },
                    {
                        name: 'Hígado',
                        therapeutic: 'therapeutic_liver',
                        research: 'reasearch_liver'
                    },
                    {
                        name: 'Corazón',
                        therapeutic: 'therapeutic_heart',
                        research: 'reasearch_heart'
                    },
                    {
                        name: 'Intestino',
                        therapeutic: 'therapeutic_intestine',
                        research: 'reasearch_intestine'
                    },
                    {
                        name: 'Pulmón',
                        therapeutic: 'therapeutic_lung',
                        research: 'reasearch_lung'
                    },{
                        name: 'Sangre',
                        therapeutic: 'therapeutic_blood',
                        research: 'reasearch_blood'
                    },
                    {
                        name: 'Tejido Ocular',
                        therapeutic: 'therapeutic_eye_tissue',
                        research: 'reasearch_eye_tissue'
                    },
                    {
                        name: 'Tejido Osteotendinoso',
                        therapeutic: 'therapeutic_osteotendinous_tissue',
                        research: 'reasearch_osteotendinous_tissue'
                    },
                    {
                        name: 'Tejido Cardiovascular',
                        therapeutic: 'therapeutic_cardiovascular_tissue',
                        research: 'reasearch_cardiovascular_tissue'
                    },
                    {
                        name: 'Hueso',
                        therapeutic: 'therapeutic_bone',
                        research: 'reasearch_bone'
                    },
                    {
                        name: 'Válvulas Cardíacas',
                        therapeutic: 'therapeutic_heart_valves',
                        research: 'reasearch_heart_valves'
                    },
                    {
                        name: 'Piel',
                        therapeutic: 'therapeutic_skin',
                        research: 'reasearch_skin'
                    },
                    {
                        name: 'Cartílago',
                        therapeutic: 'therapeutic_cartilage',
                        research: 'reasearch_cartilage'
                    },
                    {
                        name: 'Vasos',
                        therapeutic: 'therapeutic_vessels',
                        research: 'reasearch_vessels'
                    },
                    {
                        name: 'Células Progenítoras Hematopoyéticas',
                        therapeutic: 'therapeutic_hematopoietic_stem_cells',
                        research: 'reasearch_hematopoietic_stem_cells'
                    },
                    {
                        name: 'Cordón umbilical',
                        therapeutic: 'therapeutic_umbilical_cord',
                        research: 'reasearch_umbilical_cord'
                    },
                ]
            },
        }
    },
    async created() {
        this.table_options.headings = {
            'number':             'Serial N°',
            'profile':            'Nombres y Apellidos',
            'profile.dni':        'Cédula',
            'donorSchema':        'Donación',
            'id':                 'Acción'
        };
        this.table_options.sortable = ['number', 'profile', 'profile.dni'];
        this.table_options.filterable = ['number', 'profile', 'profile.dni'];
        this.table_options.columnsClasses = {
            'id': 'text-center'
        };
    },
    methods:{
        /**
         * Método que devuelve la lista de familiares con quien se comparte
         * la decisión para ser mostrados en el reporte pdf.
         *
         * @method getFamilyNames
         *
         * @author Daniel Contreras <dcontreras@cenditel.gob.ve>
        */
        getFamilyNames() {
            const vm = this;
            let familyInfo = '';

            for (let [i, family] of vm.families.entries()) {
                if (i == vm.families.length - 1) {
                    familyInfo = familyInfo + 'Nombre: ' + family.names + ' ' + family.surnames + ' | Teléfono: ' + family.phone + ' | Parentesco: ' + vm.getKinship(family.relationship) + '.';
                } else {
                    familyInfo = familyInfo + 'Nombre: ' + family.names + ' ' + family.surnames + ' | Teléfono: ' + family.phone + ' | Parentesco: ' + vm.getKinship(family.relationship) + ',\n';
                }
            }
            return familyInfo;
        },

        getKinship(id){
            const vm= this;
            let text = '';

            vm.kinship.forEach(element => {
                if (element.id  == id) {
                    text = element.name;
                }
            });
            return text;
        },

        /**
         * Método para reestablecer valores iniciales del formulario de filtros.
         *
         * @method resetFilters
         *
         * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <kingangelo_123@gmail.com>
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        resetFilters() {
            const vm = this;
            vm.filterBy = {
                dni: '',
                fromAge: '',
                toAge: ''
            };
            vm.filterSelectedOptions.donationOption = '';
            vm.filterSelectedOptions.genderOption = '';
            vm.filterSelectedOptions.blodGroupOption = '';
            vm.filterSelectedOptions.rhFactorOption = '';
            vm.filterSelectedOptions.estate_id = '';
            vm.records = vm.tmpRecords;
        },

        /**
         * Método que permite filtrar los datos de la tabla Donantes potenciales.
         *
         * @method filterDonors
         *
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        filterDonors() {
            const vm = this;

            vm.records = vm.tmpRecords.filter((rec) => {
                return (vm.filterBy.dni) ? (rec.profile.dni === vm.filterBy.dni) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.donationOption) ? (rec.donorSchema === vm.filterSelectedOptions.donationOption) : true;
            }).filter((rec) => {
                return (vm.filterBy.fromAge) ? (rec.profile.age >= vm.filterBy.fromAge) : true;
            }).filter((rec) => {
                return (vm.filterBy.toAge) ? (rec.profile.age <= vm.filterBy.toAge) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.blodGroupOption) ? (parseInt(rec.blood_group) === parseInt(vm.filterSelectedOptions.blodGroupOption)) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.rhFactorOption) ? (rec.rh_factor === vm.filterSelectedOptions.rhFactorOption) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.genderOption) ? (rec.profile.gender === vm.filterSelectedOptions.genderOption) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.estate_id) ? (parseInt(rec.profile.estate_id) === parseInt(vm.filterSelectedOptions.estate_id)) : true;
            });
        },

        /**
         * Método que permite obtener el nombre del órgano.
         *
         * @method getNameOrgan
         *
         */
        getNameOrgan(organ) {
            const vm= this;
            let text = '';
            vm.organs_list.list.forEach(element => {
                if (element.therapeutic  == organ || element.research == organ ) {
                    text = element.name;
                }
            });
            return text;
        },

        /**
         * Método que permite obtener los órganos si es Donador parcial.
         *
         * @method getPartial
         *
         */
        getPartial(organs, type) {
            let result = organs.filter(organ => organ.split('_')[0] == type );
            return result
        },

        /**
         * Método que devuelve la lista de órganos de tipo terapeuticos
         * para ser mostrados en el reporte pdf.
         *
         * @method getArrayTherapeuticOrgans
         *
         * @author Ing. Argenis Osorio <aosorio@cenditel.gob.ve>
        */
        getArrayTherapeuticOrgans() {
            const vm= this;
            if (vm.modalQuery.partial_organs) {
                const a = vm.getPartial(vm.modalQuery.partial_organs,'therapeutic');
                let arrayTherapeuticOrgans = '';
                for (let organ of a) {
                    // vm.getNameOrgan(organ)
                    // arrayTherapeuticOrgans.push(
                    //     vm.getNameOrgan(organ)
                    // );
                    arrayTherapeuticOrgans += vm.getNameOrgan(organ) + ', ';
                }
                return arrayTherapeuticOrgans;
            }
            return [];
        },

        /**
         * Método que devuelve la lista de órganos para investigación a ser
         * mostrados en el reporte pdf.
         *
         * @method getArrayReasearchOrgans
         *
         * @author Ing. Argenis Osorio <aosorio@cenditel.gob.ve>
        */
        getArrayReasearchOrgans() {
            const vm= this;
            if (vm.modalQuery.partial_organs) {
                const a = vm.getPartial(vm.modalQuery.partial_organs,'reasearch');
                let arrayRasearchOrgans = '';
                for (let organ of a) {
                    // vm.getNameOrgan(organ)
                    // arrayRasearchOrgans.push(
                    //     vm.getNameOrgan(organ)
                    // );
                    arrayRasearchOrgans += vm.getNameOrgan(organ) + ', ';
                }
                return arrayRasearchOrgans;
            }
            return [];
        },

        /**
         * Método para mostrar la informacion de un registro
         * dado su ID en un modal
         *
         * @method showInfo
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {int}      id    ID del registro
         */
        async showInfo(id) {
            const vm = this;

            let recordEdit = JSON.parse(JSON.stringify(vm.records.filter((rec) => {
                return rec.id === id;
            })[0]));

            vm.modalQuery = recordEdit;
            vm.profile = recordEdit.profile;
            vm.families = JSON.parse(recordEdit.families).slice(0, 10);

            await axios.get(`${window.url}` + '/users-list/' + vm.profile.user_id)
            .then((response) => {
                vm.profile_email = response.data.result.email;
            }).catch(error => {
                console.log(error.response);
            });

            await vm.getLocation('parish', vm.profile.parish_id);

            let oJson = JSON.parse(vm.modalQuery.hla_test_results);
            vm.allele_1_a = oJson.allele_1_a;
            vm.allele_1_b = oJson.allele_1_b;
            vm.allele_1_c = oJson.allele_1_c;
            vm.allele_1_DRB1 = oJson.allele_1_DRB1;
            vm.allele_1_DBQ1 = oJson.allele_1_DBQ1;
            vm.allele_1_DPB1 = oJson.allele_1_DPB1;
            vm.allele_1_DQA1 = oJson.allele_1_DQA1;
            vm.allele_1_DPA1 = oJson.allele_1_DPA1;
            vm.allele_1_DRB3 = oJson.allele_1_DRB3;
            vm.allele_1_DRB4 = oJson.allele_1_DRB4;
            vm.allele_1_DRB5 = oJson.allele_1_DRB5;
            vm.allele_2_a = oJson.allele_2_a;
            vm.allele_2_b = oJson.allele_2_b;
            vm.allele_2_c = oJson.allele_2_c;
            vm.allele_2_DRB1 = oJson.allele_2_DRB1;
            vm.allele_2_DBQ1 = oJson.allele_2_DBQ1;
            vm.allele_2_DPB1 = oJson.allele_2_DPB1;
            vm.allele_2_DQA1 = oJson.allele_2_DQA1;
            vm.allele_2_DPA1 = oJson.allele_2_DPA1;
            vm.allele_2_DRB3 = oJson.allele_2_DRB3;
            vm.allele_2_DRB4 = oJson.allele_2_DRB4;
            vm.allele_2_DRB5 = oJson.allele_2_DRB5;

            await vm.getRecords('global-data-type/blood_group', 'blood_groups');

            $('#show_potential_donor').modal('show');
        },

        async getLocation(url, id) {
            const vm = this;
            const query = `${window.url}/${url}/${id}`;

            await axios.get(query).then(response => {
                vm.location.parish = response.data.parish.name;
                vm.location.municipality = response.data.parish.municipality.name;
                vm.location.estate = response.data.parish.municipality.estate.name;
            }).catch(error => {
                    console.error(error)
            });
        },

        /**
         * Método para mostrar el género
         *
         * @method showGender
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         */
        showGender(gender) {
            let genderType= ''
            if (gender === 'F') {
                genderType = 'Femenino'
            } else if (gender === 'M') {
                genderType = 'Masculino'
            }
            return genderType
        },

        /**
         * Método para mostrar el género
         *
         * @method showMaritalStatus
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         */
        showMaritalStatus(maritalStatus) {
            let maritalStatusType= ''
            if (maritalStatus === 'S') {
                maritalStatusType = 'Soltero(a)'
            } else if (maritalStatus === 'C') {
                maritalStatusType = 'Casado(a)'
            } else if (maritalStatus === 'D') {
                maritalStatusType = 'Divorciado(a)'
            } else if (maritalStatus === 'V') {
                maritalStatusType = 'Viudo(a)'
            }
            return maritalStatusType
        },

        /**
         * Obtiene el nombre del tipo de sangre dato un id.
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @method getTransplantType
         * @param     {int}      blood_type_id      ID del tipo de sangre
         */
        getBloodType(blood_type_id) {
            const vm= this;
            let name = '';

            vm.blood_groups.forEach(element => {
                if (element.id  == blood_type_id) {
                    name = element.name;
                }
            });

            return name;
        },

        /**
         * Método para Descargar Archivos
         *
         * @method downloadFile
         *
         */
        downloadFile(file,nombre) {
            const vm = this;
            const url = file;
            const formData = new FormData();
            formData.append("url", url);
            axios({
                url: `${window.url}` + "/potential-donors-download",
                method: "post",
                data: formData,
                responseType: "blob"
            }).then(response => {
                let fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                let fileLink = document.createElement("a");
                let ext =file.substring(file.lastIndexOf('.')+1, file.length) || file                
                fileLink.href = fileURL;
                fileLink.setAttribute("download",nombre+"."+ext);
                document.body.appendChild(fileLink);
                fileLink.click();
                URL.revokeObjectURL(fileLink.href);
            });
        },

        /**
         * Método que permite exportar la planilla del Donante potencial en PDF.
         *
         * @method exportPDF
         *
         * @author Daniel Contreras <dcontreras@cenditel.gob.ve>
        */
        exportPDF() {
            var docDefinition = {
                info: {
                    title: 'Planilla N° '+this.modalQuery.number,
                },
                pageOrientation: 'portrait',
                //Cintillo
                header: {
                    image: base64images.cintilloFundavene(),
                    width: 530,
                    style:'text_center'
                },
                pageMargins: [ 40, 60, 40, 60 ],
                content: [
                    // Cabecera del reporte
                    { text: '\n\nInformación del Donante potencial: ' + this.modalQuery.profile.names + ' ' + this.modalQuery.profile.surnames + '\n\n',
                        style: 'header'
                    },
                    {
                        columns: [
                            {
                                // Fecha de registro
                                style: 'subheader',
                                text: 'Fecha de registro',
                            },
                            {
                                // Serial N°
                                style: 'subheader',
                                text: 'Serial N°',
                            },
                            {
                                // Vacío
                                text: '',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Fecha de registro
                                text: new Date(this.modalQuery.created_at).toLocaleDateString('en-GB')+'\n\n', style: 'text_query',
                            },
                            {
                                // Serial N°
                                text: this.modalQuery.number+'\n\n', style: 'text_query',
                            },
                            {
                                // Vacío
                                text: '',
                            },
                        ]
                    },
                    // Datos del usuario que realizó el registro
                    { text: 'Datos del usuario que realizó el registro\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Nombres
                                style: 'subheader',
                                text: 'Nombres',
                            },
                            {
                                // Apellidos
                                style: 'subheader',
                                text: 'Apellidos',
                            },
                            {
                                // Correo electrónico
                                style: 'subheader',
                                text: 'Correo electrónico',
                            }
                        ],
                    },
                    {
                        columns: [
                            {
                                // Nombres
                                text: this.modalQuery.agent_info.names+'\n\n', style: 'text_query',
                            },
                            {
                                // Apellidos
                                text: this.modalQuery.agent_info.surnames+'\n\n', style: 'text_query',
                            },
                            {
                                // Correo electrónico
                                text: this.modalQuery.agent_info.email+'\n\n', style: 'text_query',
                            }
                        ]
                    },
                    // Datos personales
                    { text: 'Datos personales\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Nombres
                                style: 'subheader',
                                text: 'Nombres',
                            },
                            {
                                // Apellidos
                                style: 'subheader',
                                text: 'Apellidos',
                            },
                            {
                                // Cédula de identidad
                                style: 'subheader',
                                text: 'Cédula de identidad',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Nombres
                                text: this.profile.names+'\n\n', style: 'text_query',
                            },
                            {
                                // Apellidos
                                text: this.profile.surnames+'\n\n', style: 'text_query',
                            },
                            {
                                // Cédula de identidad
                                text: this.profile.dni+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                // Nacionalidad
                                style: 'subheader',
                                text: 'Nacionalidad',
                            },
                            {
                                // Género
                                style: 'subheader',
                                text: 'Género',
                            },
                            {
                                // Estado civil
                                style: 'subheader',
                                text: 'Estado civil',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Nacionalidad
                                text: this.profile.nationality == 'V' ? 'Venezolano(a)' : 'Extranjero(a)'+'\n\n', style: 'text_query',
                            },
                            {
                                // Género
                                text: this.showGender(this.profile.gender)+'\n\n', style: 'text_query',
                            },
                            {
                                // Estado civil
                                text: this.showMaritalStatus(this.profile.marital_status)+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                // Ocupación
                                style: 'subheader',
                                text: 'Ocupación',
                            },
                            {
                                // Nivel académico
                                style: 'subheader',
                                text: 'Nivel académico',
                            },
                            {
                                //
                                style: 'subheader',
                                text: '',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Ocupación
                                text: this.profile.ocupation ? this.profile.ocupation.name : ''+'\n\n', style: 'text_query',
                            },
                            {
                                // Nivel académico
                                text: this.profile.academic_level+'\n\n', style: 'text_query',
                            },
                            {
                                //
                                text: '\n\n', style: 'text_query',
                            },
                        ]
                    },
                    //  Edad
                    { text: 'Edad\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Fecha de nacimiento
                                style: 'subheader',
                                text: 'Fecha de nacimiento',
                            },
                            {
                                // Edad
                                style: 'subheader',
                                text: 'Edad',
                            },
                            {
                                // Lugar de nacimiento
                                style: 'subheader',
                                text: 'Lugar de nacimiento',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Fecha de nacimiento
                                text: new Date(this.profile.birthdate).toLocaleDateString('en-GB', {timeZone: 'UTC'})+'\n\n', style: 'text_query',
                            },
                            {
                                // Edad
                                text: this.profile.age+'\n\n', style: 'text_query',
                            },
                            {
                                // Lugar de nacimiento
                                text: this.profile.birthplace+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    //  Dirección
                    { text: 'Dirección\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Estado
                                style: 'subheader',
                                text: 'Estado',
                            },
                            {
                                // Municipio
                                style: 'subheader',
                                text: 'Municipio',
                            },
                            {
                                // Parroquía
                                style: 'subheader',
                                text: 'Parroquía',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Estado
                                text: this.location.estate+'\n\n', style: 'text_query',
                            },
                            {
                                // Municipio
                                text: this.location.municipality+'\n\n', style: 'text_query',
                            },
                            {
                                // Parroquía
                                text: this.location.parish+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    //  Contacto
                    { text: 'Contacto\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Teléfono local
                                style: 'subheader',
                                text: 'Teléfono local',
                            },
                            {
                                // Teléfono móvil
                                style: 'subheader',
                                text: 'Teléfono móvil',
                            },
                            {
                                // Correo electrónico
                                style: 'subheader',
                                text: 'Correo electrónico',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Teléfono local
                                text: this.profile.phone+'\n\n', style: 'text_query',
                            },
                            {
                                // Teléfono móvil
                                text: this.profile.movil+'\n\n', style: 'text_query',
                            },
                            {
                                // Correo electrónico
                                text: this.profile_email+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    // Datos de la Donación
                    { text: 'Datos de la Donación\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Donación
                                style: 'subheader',
                                text: 'Donación',
                            },
                            {
                                // Terapeútico (trasplante)
                                style: 'subheader',
                                text: this.getArrayTherapeuticOrgans().length > 0 ? 'Terapeútico (trasplante)' : '',
                            },
                            {
                                // Investigación o Docencia
                                style: 'subheader',
                                text: this.getArrayReasearchOrgans().length > 0 ? 'Investigación o Docencia' : '',
                            }
                        ],
                    },
                    {
                        columns: [
                            {
                                // Donación
                                text: (this.modalQuery.donorSchema === 'T') ? 'Total' : 'Parcial'+'\n\n',
                                style: 'text_query',
                            },
                            {
                                // Terapeútico (trasplante)
                                text: (this.modalQuery.donorSchema === 'T') ? '' : this.getArrayTherapeuticOrgans()+'\n\n',
                                style: 'text_query',
                                margin: [0, 0, 20, 20]
                            },
                            {
                                // Investigación o Docencia
                                text: (this.modalQuery.donorSchema === 'T') ? '\n' : this.getArrayReasearchOrgans()+'\n\n',
                                style: 'text_query',
                                margin: [0, 0, 20, 20]
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                // Grupo sanguíneo
                                style: 'subheader',
                                text: '\nGrupo sanguíneo',
                            },
                            {
                                // Factor RH
                                style: 'subheader',
                                text: '\nFactor RH',
                            },
                            {
                                // Centro generador de órganos y tejidos
                                style: 'subheader',
                                text: '\nCentro generador de órganos y tejidos',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Grupo sanguíneo
                                text: this.getBloodType(this.modalQuery.blood_group)+'\n\n', style: 'text_query',
                            },
                            {
                                // Factor RH
                                text: this.modalQuery.rh_factor == 'P' ? 'Positivo' : 'Negativo'+'\n\n', style: 'text_query',
                            },
                            {
                                // Centro generador de órganos y tejidos
                                text: this.modalQuery.health_center ? this.modalQuery.health_center.name : ''+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    //  Exámenes médicos
                    { text: 'Exámenes médicos\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Observaciones de serología
                                style: 'subheader',
                                text: 'Observaciones de serología',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Observaciones de serología
                                text: this.modalQuery.serology_test_observation+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    //  Recepción de muestras
                    { text: 'Recepción de muestras\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Ganglios
                                style: 'subheader',
                                text: 'Ganglios',
                            },
                            {
                                // Cantidad
                                style: 'subheader',
                                text: 'Cantidad',
                            },
                            {
                                // Observación
                                style: 'subheader',
                                text: 'Observación',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                //
                                text: '\n\n', style: 'text_query',
                            },
                            {
                                // Observaciones de serología
                                text: this.modalQuery.ganglios_quantity ? this.modalQuery.ganglios_quantity+'\n\n' : 'Sin datos',
                                style: 'text_query',
                            },
                            {
                                // Observaciones de serología
                                text: this.modalQuery.ganglios_observation ? this.modalQuery.ganglios_observation+'\n\n' : 'Sin datos',
                                style: 'text_query',
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                // Tubo con EDTA
                                style: 'subheader',
                                text: 'Tubo con EDTA',
                            },
                            {
                                // Cantidad
                                style: 'subheader',
                                text: 'Cantidad',
                            },
                            {
                                // Observación
                                style: 'subheader',
                                text: 'Observación',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                //
                                text: '\n\n', style: 'text_query',
                            },
                            {
                                // Observaciones de serología
                                text: this.modalQuery.tube_edta_quantity ? this.modalQuery.tube_edta_quantity+'\n\n' : 'Sin datos',
                                style: 'text_query',
                            },
                            {
                                // Observaciones de serología
                                text: this.modalQuery.tube_edta_observation ? this.modalQuery.tube_edta_observation+'\n\n' : 'Sin datos',
                                style: 'text_query',
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                // Tubo sin aditivos
                                style: 'subheader',
                                text: 'Tubo sin aditivos',
                            },
                            {
                                // Cantidad
                                style: 'subheader',
                                text: 'Cantidad',
                            },
                            {
                                // Observación
                                style: 'subheader',
                                text: 'Observación',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                //
                                text: '\n\n', style: 'text_query',
                            },
                            {
                                // Observaciones de serología
                                text: this.modalQuery.tube_w_additives_quantity ? this.modalQuery.tube_w_additives_quantity+'\n\n' : 'Sin datos',
                                style: 'text_query',
                            },
                            {
                                // Observaciones de serología
                                text: this.modalQuery.tube_w_additives_observation ?
                                this.modalQuery.tube_w_additives_observation+'\n\n' : 'Sin datos',
                                style: 'text_query',
                            },
                        ]
                    },
                    //  Resultados de inmunología
                    { text: '\n\nResultados de inmunología\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Clase 1
                                style: 'subheader',
                                text: 'Clase 1',
                                width: '30%',
                                alignment: 'center'
                            },
                            {
                                // Clase 2
                                style: 'subheader',
                                text: 'Clase 2',
                                width: '70%',
                                alignment: 'center'
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                // HLA
                                style: 'subheader',
                                text: '\nHLA',
                            },
                            {
                                // A
                                style: 'subheader',
                                text: '\nA',
                            },
                            {
                                // B
                                style: 'subheader',
                                text: '\nB',
                            },
                            {
                                // C
                                style: 'subheader',
                                text: '\nC',
                            },
                            {
                                // DRB1
                                style: 'subheader',
                                text: '\nDRB1',
                            },
                            {
                                // DBQ1
                                style: 'subheader',
                                text: '\nDBQ1',
                            },
                            {
                                // DPB1
                                style: 'subheader',
                                text: '\nDPB1',
                            },
                            {
                                // DQA1
                                style: 'subheader',
                                text: '\nDQA1',
                            },
                            {
                                // DPA1
                                style: 'subheader',
                                text: '\nDPA1',
                            },
                            {
                                // DRB3
                                style: 'subheader',
                                text: '\nDRB3',
                            },
                            {
                                // DRB4
                                style: 'subheader',
                                text: '\nDRB4',
                            },
                            {
                                // DRB5
                                style: 'subheader',
                                text: '\nDRB5',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Alelo 1
                                style: 'subheader',
                                text: 'Alelo 1',
                            },
                            {
                                // A
                                style: 'subheader',
                                text: this.allele_1_a, style: 'text_query',
                            },
                            {
                                // B
                                style: 'subheader',
                                text: this.allele_1_b, style: 'text_query',
                            },
                            {
                                // C
                                style: 'subheader',
                                text: this.allele_1_c, style: 'text_query',
                            },
                            {
                                // DRB1
                                style: 'subheader',
                                text: this.allele_1_DRB1, style: 'text_query',
                            },
                            {
                                // DBQ1
                                style: 'subheader',
                                text: this.allele_1_DBQ1, style: 'text_query',
                            },
                            {
                                // DPB1
                                style: 'subheader',
                                text: this.allele_1_DPB1, style: 'text_query',
                            },
                            {
                                // DQA1
                                style: 'subheader',
                                text: this.allele_1_DQA1, style: 'text_query',
                            },
                            {
                                // DPA1
                                style: 'subheader',
                                text: this.allele_1_DPA1, style: 'text_query',
                            },
                            {
                                // DRB3
                                style: 'subheader',
                                text: this.allele_1_DRB3, style: 'text_query',
                            },
                            {
                                // DRB4
                                style: 'subheader',
                                text: this.allele_1_DRB4, style: 'text_query',
                            },
                            {
                                // DRB5
                                style: 'subheader',
                                text: this.allele_1_DRB5, style: 'text_query',
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                // Alelo 2
                                style: 'subheader',
                                text: 'Alelo 2',
                            },
                            {
                                // A
                                style: 'subheader',
                                text: this.allele_2_a, style: 'text_query',
                            },
                            {
                                // B
                                style: 'subheader',
                                text: this.allele_2_b, style: 'text_query',
                            },
                            {
                                // C
                                style: 'subheader',
                                text: this.allele_2_c, style: 'text_query',
                            },
                            {
                                // DRB1
                                style: 'subheader',
                                text: this.allele_2_DRB1, style: 'text_query',
                            },
                            {
                                // DBQ1
                                style: 'subheader',
                                text: this.allele_2_DBQ1, style: 'text_query',
                            },
                            {
                                // DPB1
                                style: 'subheader',
                                text: this.allele_2_DPB1, style: 'text_query',
                            },
                            {
                                // DQA1
                                style: 'subheader',
                                text: this.allele_2_DQA1, style: 'text_query',
                            },
                            {
                                // DPA1
                                style: 'subheader',
                                text: this.allele_2_DPA1, style: 'text_query',
                            },
                            {
                                // DRB3
                                style: 'subheader',
                                text: this.allele_2_DRB3, style: 'text_query',
                            },
                            {
                                // DRB4
                                style: 'subheader',
                                text: this.allele_2_DRB4, style: 'text_query',
                            },
                            {
                                // DRB5
                                style: 'subheader',
                                text: this.allele_2_DRB5, style: 'text_query',
                            },
                        ]
                    },
                    // Familiares con quienes se compartió la decisión
                    {
                        text: this.families.length > 0 ? '\n\nFamiliares con quienes se compartió la decisión\n\n' : '',
                        style: 'header'},
                    {
                        columns: [
                            {
                                // Familiares
                                style: 'subheader',
                                text: this.families.length > 0 ? 'Familiares' : '',
                            }
                        ],
                    },
                    {
                        columns: [
                            {
                                // Familiares
                                text: this.getFamilyNames()+'\n\n', style: 'text_query'
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                // Datos del personal de inmunología asociado al registro de este donante potencial
                                style: 'subheader',
                                text: '\nPersonal de inmunología asociado',
                            },
                            {
                                // Correo electrónico de la unidad de inmunología.
                                style: 'subheader',
                                text: '\nCorreo electrónico de la unidad de inmunología',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Personal de inmunología asociado
                                text: this.modalQuery.pin ? this.modalQuery.pin.name + '\n\n' : '', style: 'text_query',
                            },
                            {
                                // Personal de inmunología asociado
                                text: this.modalQuery.email_uin ? this.modalQuery.email_uin + '\n\n' : '', style: 'text_query',
                            }
                        ]
                    },
                    // Footer del reporte
                    {
                        image: base64images.footerPlanillasPDF(),
                        width: 450,
                        style: 'text_center',
                        absolutePosition: {x: 12, y: 730}
                    },
                ],
                styles: {
                    header: {
                        fontSize: 13,
                        bold: true,
                        alignment: 'justify'
                    },
                    subheader: {
                        fontSize: 10,
                        bold: true,
                        alignment: 'justify'
                    },
                    text_query: {
                        fontSize: 8,
                        alignment: 'justify'
                    },
                    text_bold: {
                        fontSize: 8,
                        alignment: 'center',
                        bold: true
                    },
                    text_center: {
                        fontSize: 8,
                        alignment: 'center',
                    },
                }
            }
            pdfMake.createPdf(docDefinition).download('Planilla Donante potencial-Serial N°'+this.modalQuery.number+'-CI-'+this.profile.dni);
        },
    },
    async mounted() {
        const vm = this;
        /**
         * Si el usuario autenticado intenta acceder al componente sin tener el
         * rol requerido este no carta los diferentes datos a consultar y
         * redirecciona a la pantalla inicial.
        */
        vm.user_role = vm.$parent.user.role;
        if ((vm.user_role == 'ADM') || (vm.user_role == 'SUP') || (vm.user_role == 'CHO') || (vm.user_role == 'PIN')) {
            vm.loadingState(true); // Inicio de spinner de carga.
            // Obtener el rol del usuario autenticado.
            vm.user_role = vm.$parent.user.role;
            // Obtener el listado de órganos.
            await vm.getRecords('global-data-type/kinship', 'kinship')
            // Obtener el listado de los grupos sanguíneos.
            await vm.getRecords('global-data-type/blood_group', 'blood_groups');
            // Obtener listado de tipos de sangre.
            await vm.getRecords('global-data-type/blood_type', 'blood_type');
            if (vm.user_role == 'PIN') {
                // Obtener listado de Donantes potenciales según el rol PIN.
                await vm.getRecords('potential-donors-by-pin', 'records');
            } else {
                // Obtener listado de Donantes potenciales.
                await vm.getRecords('potential-donors', 'records');
            }
            // Obtener los estados.
            await vm.getEstates()
            // Variable temporal a ser usada en el formulario de filtros.
            vm.tmpRecords = vm.records;
            vm.loadingState(); // Finaliza spinner de carga.
        }
        else {
            location.href = '/';
        }
    }
}
</script>
<style>
</style>
