<template>
    <div id="effective-donor-record-list-component">
        <!-- Card -->
        <div class="card mb-4 shadow-sm">
            <h2 class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary">
                <i class="fa fa-th-list"></i> Personas que donaron sus órganos durante este año (Donantes efectivos)
            </h2>
            <div class="card-body">
                <!-- Filtros de la tabla -->
                <div class="row">
                    <div class="col-12 col-lg-3 mb-3">
                        <label class="form-label">Cédula de identidad</label>
                        <input
                            class="form-control"
                            type="text"
                            minlength="7"
                            maxlength="9"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Cédula de identidad"
                            v-model="filterBy.dni"
                        />
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label class="form-label">Donación</label>
                        <v-select
                            v-model="filterSelectedOptions.donationOption"
                            :options="donationOptions"
                            :reduce="donationOption => donationOption.id"
                            label="text"
                            id="donationOption"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label class="form-label">Género</label>
                        <v-select
                            v-model="filterSelectedOptions.genderOption"
                            :options="genderOptions"
                            :reduce="genderOption => genderOption.id"
                            label="text"
                            id="genderOption"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label class="form-label">Edad (Mayor que)</label>
                        <input
                            class="form-control"
                            type="text"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Edad (Mayor que)"
                            v-model="filterBy.fromAge"
                        />
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label class="form-label">Edad (Menor que)</label>
                        <input
                            class="form-control"
                            type="text"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Edad (Menor que)"
                            v-model="filterBy.toAge"
                        />
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label class="form-label">Grupo sanguíneo</label>
                        <v-select
                            v-model="filterSelectedOptions.blodGroupOption"
                            :options="blood_groups"
                            :reduce="blodGroupOption => blodGroupOption.id"
                            label="name"
                            id="blodGroupOption"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label class="form-label">Factor RH</label>
                        <v-select
                            v-model="filterSelectedOptions.rhFactorOption"
                            :options="rhFactorOptions"
                            :reduce="rhFactorOption => rhFactorOption.id"
                            label="text"
                            id="rhFactorOption"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="estate_id" class="form-label">
                            Estado
                        </label>
                        <v-select
                            v-model="filterSelectedOptions.estate_id"
                            :options="estates"
                            :selectable="option => option.id !== ''"
                            :reduce="estate => estate.id"
                            label="text"
                            id="estate_id"
                            :tabindex="13"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="row pb-4">
                        <div class="col-12 col-lg-3">
                            <button class="btn btn-outline-primary-dark"
                                type="reset" @click="resetFilters()"
                            >
                                Limpiar
                            </button>
                            <button type="button" class="btn btn-outline-primary-dark"
                                aria-label="Search" @click="filterDonors"
                            >
                                Buscar
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Final de filtros de la tabla -->
                <b>Registros encontrados: {{ records.length }}</b>
                <v-client-table class="mt-3" :columns="columns" :data="records" :options="table_options">
                    <div slot="profile" slot-scope="props">
                        {{props.row.profile.names}} {{props.row.profile.surnames}}
                    </div>
                    <div slot="donorSchema" slot-scope="props">
                        {{ (props.row.donorSchema === 'T') ? 'Total' : 'Parcial' }}
                    </div>
                    <div slot="id" slot-scope="props" class="main-flex-buttons">
                        <button class="btn btn-outline-primary-dark" type="button" title="Ver registro"
                            @click="showInfo(props.row.id)">
                            <i class="fa fa-eye"></i>
                        </button>
                    </div>
                </v-client-table>
                <!-- Modal -->
                <div id="show_efective_donor" class="modal fade" tabindex="-1" role="dialog">
                    <!-- Modal-dialog -->
                    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                        <!-- Modal-content -->
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4>Información detallada del Donante efectivo</h4>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <!-- modal-body -->
                            <div class="modal-body">
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-2 mb-3">
                                        <label for="created_at" class="form-label">Fecha de registro</label>
                                        <input id="created_at" class="form-control" type="text" disabled
                                            :value="new Date(modalQuery.created_at).toLocaleDateString('en-GB')" />
                                    </div>
                                    <div class="col-12 col-lg-2 mb-3">
                                        <label for="number" class="form-label">
                                            Serial N°
                                        </label>
                                        <input id="number" class="form-control" type="text" disabled
                                            :value="modalQuery.number" />
                                    </div>
                                </div>

                                <!-- Datos del usuario que realiza el registro -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Datos del usuario que realizó el registro
                                </h3>
                                <hr>
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="agent_names" class="form-label">
                                            Nombres
                                        </label>
                                        <input id="agent_names" class="form-control"
                                            type="text" disabled
                                            :value="modalQuery && modalQuery.agent_info ? modalQuery.agent_info.names : ''"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="agent_surnames" class="form-label">
                                            Apellidos
                                        </label>
                                        <input id="agent_surnames" class="form-control"
                                            type="text" disabled
                                            :value="modalQuery && modalQuery.agent_info ? modalQuery.agent_info.surnames : ''"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="agent_mail" class="form-label">
                                            Correo electrónico
                                        </label>
                                        <input id="agent_mail" class="form-control"
                                            type="text" disabled
                                            :value="modalQuery && modalQuery.agent_info ? modalQuery.agent_info.email : ''"
                                        />
                                    </div>
                                </div>

                                <!-- Datos del donante  -->
                                <h3 class="h5 text-secondary text-uppercase">Datos del donante efectivo</h3>
                                <hr>
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="names" class="form-label">Nombres del difunto</label>
                                        <input id="names" class="form-control" type="text" disabled
                                            :value="profile.names" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="surnames" class="form-label">Apellidos del difunto</label>
                                        <input id="surnames" class="form-control" type="text" disabled
                                            :value="profile.surnames" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="dni" class="form-label">Cédula de identidad</label>
                                        <input id="dni" class="form-control" type="text" disabled
                                            :value="profile.dni" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="nationality" class="form-label">
                                            Nacionalidad
                                        </label>
                                        <input id="nationality" class="form-control"
                                            type="text" disabled
                                            :value="profile.nationality == 'V' ? 'Venezolano(a)' : 'Extranjero(a)'"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="gender" class="form-label">Genéro</label>
                                        <input id="gender" class="form-control" type="text" disabled
                                            :value="showGender(profile.gender)" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="marital_status" class="form-label">
                                            Estado civil
                                        </label>
                                        <input id="marital_status" class="form-control"
                                            type="text" disabled
                                            :value="showMaritalStatus(profile.marital_status)"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="ocupation" class="form-label">
                                            Ocupación
                                        </label>
                                        <input id="ocupation" class="form-control" type="text" disabled
                                            :value="profile.ocupation.name" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="academic_levels" class="form-label">
                                            Nivel académico
                                        </label>
                                        <input id="academic_level" class="form-control" type="text" disabled
                                            :value="profile.academic_level" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="birthdate" class="form-label">Fecha de nacimiento</label>
                                        <input id="birthdate" class="form-control" type="text" disabled
                                            :value="new Date(profile.birthdate).toLocaleDateString('en-GB', {timeZone: 'UTC'})" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="age" class="form-label">Edad</label>
                                        <input id="age" class="form-control" type="text" disabled
                                            :value="profile.age" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="birthplace" class="form-label">Lugar de nacimiento</label>
                                        <input id="birthplace" class="form-control" type="text" disabled
                                            :value="profile.birthplace" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="estate_id" class="form-label">
                                            Estado
                                        </label>
                                        <input id="estate_id" class="form-control" type="text" disabled
                                            :value="location.estate" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="municipality_id" class="form-label">
                                            Municipio
                                        </label>
                                        <input id="municipality_id" class="form-control" type="text" disabled
                                            :value="location.municipality" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="parish_id" class="form-label">
                                            Parroquia
                                        </label>
                                        <input id="parish_id" class="form-control" type="text" disabled
                                            :value="location.parish" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="phone" class="form-label">
                                            Teléfono local
                                        </label>
                                        <input id="phone" class="form-control"
                                            type="text" disabled
                                            :value="profile.phone"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="movil" class="form-label">
                                            Teléfono móvil
                                        </label>
                                        <input id="movil" class="form-control"
                                            type="text" disabled
                                            :value="profile.movil"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="email" class="form-label">
                                            Correo electrónico
                                        </label>
                                        <input id="email" class="form-control"
                                            type="text" disabled
                                            :value="profile_email"
                                        />
                                    </div>

                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="donorSchema" class="form-label">
                                            Donación
                                        </label>
                                        <input id="donorSchema" class="form-control"
                                            type="text"
                                            disabled
                                            :value="(profile.donorSchema === 'T')? 'Total' : 'Parcial'"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-4" v-if="modalQuery.partial_organs">
                                        <div v-if="getPartial(modalQuery.partial_organs,'therapeutic').length > 0">
                                            <h6>Terapeútico (trasplante):</h6>
                                            <ul>
                                                <li v-for="organ in getPartial(modalQuery.partial_organs,'therapeutic')">
                                                    <span class="small">{{ getNameOrgan(organ) + '.' }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3" v-if="modalQuery.partial_organs">
                                        <div v-if="getPartial(modalQuery.partial_organs,'reasearch').length > 0">
                                            <h6>Investigación o Docencia:</h6>
                                            <ul>
                                                <li v-for="organ in getPartial(modalQuery.partial_organs,'reasearch')">
                                                    <span class="small">{{ getNameOrgan(organ) + '.'}}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="healthcare_center_where_died" class="form-label">Centro asistencial</label>
                                        <input id="healthcare_center_where_died" class="form-control" type="text" disabled
                                            :value="modalQuery.health_center.name" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="blood_group" class="form-label">
                                            Grupo sangíneo
                                        </label>
                                        <input id="healthcare_center_where_died" class="form-control" type="text" disabled :value="modalQuery.blood_group" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="rhFactor" class="form-label">
                                            Factor RH
                                        </label>
                                        <input id="healthcare_center_where_died" class="form-control" type="text" disabled :value="getRhFactor(modalQuery.rh_factor)" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="size" class="form-label">Talla</label>
                                        <input id="size" class="form-control" type="text" disabled
                                            :value="modalQuery.size" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="weight" class="form-label">Peso (Kg)</label>
                                        <input id="weight" class="form-control" type="text" disabled
                                            :value="modalQuery.weight" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="death_date" class="form-label">Fecha de muerte</label>
                                        <input id="death_date" class="form-control" type="text" disabled
                                            :value="new Date(modalQuery.death_date).toLocaleDateString('en-GB', {timeZone: 'UTC'})" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="death_time" class="form-label">Hora de muerte</label>
                                        <input id="death_time" class="form-control" disabled
                                            type="time" tabindex="10"
                                            :value="modalQuery.death_time" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="diagnosis" class="form-label">Diagnóstico</label>
                                        <textarea rows="3"  class="form-control" tabindex="11" disabled
                                            :value="modalQuery.diagnosis">
                                        </textarea>
                                    </div>
                                </div>
                                <!-- Datos de órganos y tejidos que han sido extraídos -->
                                <h4 class="h6 text-secondary">
                                    Órganos, células y tejidos que han sido extraídos
                                </h4>
                                <div class="my-3">
                                    <div v-if="typeof(modalQuery.organs_selected)!=='undefined' && modalQuery.organs_selected.length" class="card mt-3 p-3">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr class="text-center">
                                                        <th scope="col">Cantidad</th>
                                                        <th scope="col">Órgano</th>
                                                        <th scope="col">Observación</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="text-center" v-for="(item, index) in modalQuery.organs_selected"
                                                        :key="index">
                                                        <td>{{item.number}}</td>
                                                        <td>{{item.organ}}</td>
                                                        <td>
                                                            {{modalQuery.organs_selected[index].observation}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <!-- Final datos de órganos y tejidos que han sido extraídos -->
                                <!-- Exámenes Médicos -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Exámenes Médicos
                                </h3>
                                <hr>
                                <!-- Resultados de serología -->
                                <h4 class="h5 text-secondary">
                                    Resultados de serología
                                </h4>
                                <div class="row pb-4">
                                    <div class="col-12 mb-3">
                                        <div class="row">
                                            <div class="col-12 col-lg-10">
                                                <label
                                                    for="refusal_file"
                                                    class="form-label"
                                                >
                                                    Examen de serología
                                                </label>
                                            </div>
                                            <div
                                                class="col-12 col-lg-2 d-flex justify-content-center justify-content-lg-end"
                                            >
                                                <button
                                                    class="btn btn-outline-primary-dark"
                                                    @click="
                                                        downloadFile(
                                                            modalQuery.serology_test_file,
                                                            'Examen de serología'
                                                        )
                                                    "
                                                    :disabled="
                                                        modalQuery.serology_test_file ===
                                                            ''
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-download"
                                                    ></i>
                                                    Descargar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <label
                                            for="family_refusal_reason"
                                            class="form-label"
                                        >
                                            Observación
                                        </label>
                                        <textarea
                                            class="form-control"
                                            disabled
                                            rows="3"
                                            tabindex="1"
                                            :value="
                                                modalQuery.serology_test_observation
                                            "
                                        >
                                        </textarea>
                                    </div>
                                </div>
                                <!-- Final Resultados de serología -->

                                <!-- Recepción de muestras -->
                                <h4 class="h5 text-secondary">
                                    Recepción de muestras
                                </h4>
                                <div class="card p-3">
                                    <!-- Ganglios -->
                                    <div class="row pt-2 pb-4">
                                        <div class="col-12 col-lg-2 d-flex justify-content-center align-items-center">
                                            <h6>Ganglios</h6>
                                        </div>
                                        <div class="col-12 col-lg-4 col-xl-3 pb-2">
                                            <label class="fw-bold">Cantidad</label>
                                            <input
                                                id="ganglios_quantity"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                tabindex="17"
                                                v-model="modalQuery.ganglios_quantity"
                                            />
                                        </div>
                                        <div class="col-12 col-lg-6 col-xl-4">
                                            <label class="fw-bold">Observación</label>
                                            <textarea
                                                class="form-control"
                                                disabled
                                                rows="3"
                                                tabindex="13"
                                                v-model="modalQuery.ganglios_observation"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <!-- Final Ganglios -->


                                    <!-- Tubo con EDTA -->
                                    <div class="row pb-4">
                                        <div class="col-12 col-lg-2 d-flex justify-content-center align-items-center">
                                            <h6>Tubo con EDTA</h6>
                                        </div>
                                        <div class="col-12 col-lg-4 col-xl-3 pb-2">
                                            <label class="fw-bold">Cantidad</label>
                                            <input
                                                id="tube_edta_quantity"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                tabindex="14"
                                                v-model="modalQuery.tube_edta_quantity"
                                            />
                                        </div>
                                        <div class="col-12 col-lg-6 col-xl-4">
                                            <label class="fw-bold">Observación</label>
                                            <textarea
                                                class="form-control"
                                                disabled
                                                rows="3"
                                                tabindex="15"
                                                v-model="modalQuery.tube_edta_observation"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <!-- Final Tubo con EDTA -->


                                    <!-- Tubo sin aditivos -->
                                    <div class="row pb-4">
                                        <div class="col-12 col-lg-2 d-flex justify-content-center align-items-center">
                                            <h6>Tubo sin aditivos</h6>
                                        </div>
                                        <div class="col-12 col-lg-4 col-xl-3 pb-2">
                                            <label class="fw-bold">Cantidad</label>
                                            <input
                                                id="tube_w_additives_quantity"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                tabindex="16"
                                                v-model="modalQuery.tube_w_additives_quantity"
                                            />
                                        </div>
                                        <div class="col-12 col-lg-6 col-xl-4">
                                            <label class="fw-bold">Observación</label>
                                            <textarea
                                                class="form-control"
                                                disabled
                                                rows="3"
                                                tabindex="17"
                                                v-model="modalQuery.tube_w_additives_observation"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <!-- Final Tubo sin aditivos -->
                                </div>
                                <!-- Final Recepción de muestras -->

                                <!-- Resultados de inmunología -->
                                <h4 class="h5 text-secondary mt-4">
                                    Resultados de inmunología
                                </h4>

                                <div class="card p-3">
                                    <div class="row pb-4">
                                        <!-- Títulos -->
                                        <div class="col-12 col-lg-3 offset-lg-1 order-0">
                                            <h5 class="h6 text-center mb-0 mt-3">Clase 1</h5>
                                            <hr>
                                        </div>
                                        <div class="col-12 col-lg-8 order-2 order-lg-1">
                                            <h5 class="h6 text-center mb-0 mt-3">Clase 2</h5>
                                            <hr>
                                        </div>

                                        <!-- Alelos 1 y 2 - A, B, C -->
                                        <div class="col-12 col-lg-4 order-1 order-lg-2">
                                            <!-- HLA, A, B, C -->
                                            <div class="row mb-3">
                                                <div class="col-3 text-center">
                                                    <span class="form-label">HLA</span>
                                                </div>
                                                <div class="col-3 text-center">
                                                    <span class="form-label">A</span>
                                                </div>
                                                <div class="col-3 text-center">
                                                    <span class="form-label">B</span>
                                                </div>
                                                <div class="col-3 text-center">
                                                    <span class="form-label">C</span>
                                                </div>
                                            </div>

                                            <!-- Alelo 1, 1A, 1B, 1C -->
                                            <div class="row mb-3 align-items-center">
                                                <div class="col-3 text-center">
                                                    <span class="form-label mb-1">Alelo 1</span>
                                                </div>
                                                <div class="col-3 text-center">
                                                    <input
                                                        id="allele_1_a"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="modalQuery.hla_test_results.allele_1_a"
                                                    />
                                                </div>
                                                <div class="col-3 text-center">
                                                    <input
                                                        id="allele_1_b"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="modalQuery.hla_test_results.allele_1_b"
                                                    />
                                                </div>
                                                <div class="col-3 text-center">
                                                    <input
                                                        id="allele_1_c"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="modalQuery.hla_test_results.allele_1_c"
                                                    />
                                                </div>
                                            </div>

                                            <!-- Alelo 2, 2A, 2B, 2C -->
                                            <div class="row align-items-center">
                                                <div class="col-3 text-center">
                                                    <span class="form-label mb-1">Alelo 2</span>
                                                </div>
                                                <div class="col-3 text-center">
                                                    <input
                                                        id="allele_2_a"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="modalQuery.hla_test_results.allele_2_a"
                                                    />
                                                </div>
                                                <div class="col-3 text-center">
                                                    <input
                                                        id="allele_2_b"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="modalQuery.hla_test_results.allele_2_b"
                                                    />
                                                </div>
                                                <div class="col-3 text-center">
                                                    <input
                                                        id="allele_2_c"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="modalQuery.hla_test_results.allele_2_c"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Alelos 1 y 2 - DRB1, DBQ1, DPB1 -->
                                        <div class="col-12 col-lg-3 order-3">
                                            <!-- HLA, DRB1, DBQ1, DPB1 -->
                                            <div class="row mb-3">
                                                <div class="col-3 d-lg-none text-center">
                                                    <span class="form-label">HLA</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <span class="form-label">DRB1</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <span class="form-label">DBQ1</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <span class="form-label">DPB1</span>
                                                </div>
                                            </div>

                                            <!-- Alelo 1, DRB1, DBQ1, DPB1 -->
                                            <div class="row mb-3 align-items-center">
                                                <div class="col-3 d-lg-none text-center">
                                                    <span class="form-label mb-1">Alelo 1</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_1_DRB1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="modalQuery.hla_test_results.allele_1_DRB1"
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_1_DBQ1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="modalQuery.hla_test_results.allele_1_DBQ1"
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_1_DPB1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="modalQuery.hla_test_results.allele_1_DPB1"
                                                    />
                                                </div>
                                            </div>

                                            <!-- Alelo 2, DRB1, DBQ1, DPB1 -->
                                            <div class="row mb-5 mb-lg-0 align-items-center">
                                                <div class="col-3 d-lg-none text-center">
                                                    <span class="form-label mb-1">Alelo 2</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_2_DRB1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="modalQuery.hla_test_results.allele_2_DRB1"
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_2_DBQ1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="modalQuery.hla_test_results.allele_2_DBQ1"
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_2_DPB1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="modalQuery.hla_test_results.allele_2_DPB1"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Alelos 1 y 2 - DQA1, DPA1, DRB3 -->
                                        <div class="col-12 col-lg-3 order-4">
                                            <!-- HLA, DQA1, DPA1, DRB3 -->
                                            <div class="row mb-3">
                                                <div class="col-3 d-lg-none text-center">
                                                    <span class="form-label">HLA</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <span class="form-label">DQA1</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <span class="form-label">DPA1</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <span class="form-label">DRB3</span>
                                                </div>
                                            </div>
                                            <!-- Alelo 1, DQA1, DPA1, DRB3 -->
                                            <div class="row mb-3 align-items-center">
                                                <div class="col-3 d-lg-none text-center">
                                                    <span class="form-label mb-1">Alelo 1</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_1_DQA1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="modalQuery.hla_test_results.allele_1_DQA1"
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_1_DPA1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="modalQuery.hla_test_results.allele_1_DPA1"
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_1_DRB3"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="modalQuery.hla_test_results.allele_1_DRB3"
                                                    />
                                                </div>
                                            </div>

                                            <!-- Alelo 2, DQA1, DPA1, DRB3 -->
                                            <div class="row mb-5 mb-lg-0 align-items-center">
                                                <div class="col-3 d-lg-none text-center">
                                                    <span class="form-label mb-1">Alelo 2</span>
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_2_DQA1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="modalQuery.hla_test_results.allele_2_DQA1"
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_2_DPA1"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="modalQuery.hla_test_results.allele_2_DPA1"
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-4 text-center">
                                                    <input
                                                        id="allele_2_DRB3"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="modalQuery.hla_test_results.allele_2_DRB3"
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <!-- Alelos 1 y 2 - DRB4, DRB5 -->
                                        <div class="col-12 col-lg-2 order-5">

                                            <!-- HLA, DRB4, DRB5 -->
                                            <div class="row mb-3">
                                                <div class="col-3 d-lg-none text-center">
                                                    <span class="form-label">HLA</span>
                                                </div>
                                                <div class="col-3 col-lg-6 text-center">
                                                    <span class="form-label">DRB4</span>
                                                </div>
                                                <div class="col-3 col-lg-6 text-center">
                                                    <span class="form-label">DRB5</span>
                                                </div>
                                            </div>
                                            <!-- Alelo 1, DRB4, DRB5 -->
                                            <div class="row mb-3 align-items-center">
                                                <div class="col-3 d-lg-none text-center">
                                                    <span class="form-label mb-1">Alelo 1</span>
                                                </div>
                                                <div class="col-3 col-lg-6 text-center">
                                                    <input
                                                        id="allele_1_DRB4"
                                                        class="form-control"
                                                        type="text"
                                                        :value="modalQuery.hla_test_results.allele_1_DRB4"
                                                        disabled
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-6 text-center">
                                                    <input
                                                        id="allele_1_DRB5"
                                                        class="form-control"
                                                        type="text"
                                                        :value="modalQuery.hla_test_results.allele_1_DRB5"
                                                        disabled
                                                    />
                                                </div>
                                            </div>

                                            <!-- Alelo 2, DRB4, DRB5 -->
                                            <div class="row align-items-center">
                                                <div class="col-3 d-lg-none text-center">
                                                    <span class="form-label mb-1">Alelo 2</span>
                                                </div>
                                                <div class="col-3 col-lg-6 text-center">
                                                    <input
                                                        id="allele_2_DRB4"
                                                        class="form-control"
                                                        type="text"
                                                        :value="modalQuery.hla_test_results.allele_2_DRB4"
                                                        disabled
                                                    />
                                                </div>
                                                <div class="col-3 col-lg-6 text-center">
                                                    <input
                                                        id="allele_2_DRB5"
                                                        class="form-control"
                                                        type="text"
                                                        :value="modalQuery.hla_test_results.allele_2_DRB5"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Final Resultados de inmunología -->

                                <!-- Documentos requeridos -->
                                <h3
                                    class="h5 text-secondary text-uppercase mt-4"
                                >
                                    Documentos requeridos
                                </h3>
                                <hr />
                                <div class="row pb-2">
                                    <div class="col-12 col-lg-10">
                                        <label
                                            for="family_acceptance_document"
                                            class="form-label"
                                        >
                                            Planilla de registro de muerte según
                                            criterios neurológicos
                                        </label>
                                    </div>
                                    <div
                                        class="col-12 col-lg-2 d-flex justify-content-center justify-content-lg-end"
                                    >
                                        <button
                                            class="btn btn-outline-primary-dark"
                                            @click="
                                                downloadFile(
                                                    modalQuery.document_neurological_death,
                                                    'Planilla de registro de muerte según criterios neurológicos'
                                                )
                                            "
                                            :disabled="
                                                !modalQuery.document_neurological_death ||
                                                    modalQuery.document_neurological_death ===
                                                        ''
                                            "
                                        >
                                            <i class="fa fa-download"></i>
                                            Descargar
                                        </button>
                                    </div>
                                </div>
                                <hr />
                                <div class="row pb-2">
                                    <div class="col-12 col-lg-10">
                                        <label
                                            for="family_acceptance_document"
                                            class="form-label"
                                        >
                                            Planilla de registro posterior a la
                                            información familiar
                                        </label>
                                    </div>
                                    <div
                                        class="col-12 col-lg-2 d-flex justify-content-center justify-content-lg-end"
                                    >
                                        <button
                                            class="btn btn-outline-primary-dark"
                                            @click="
                                                downloadFile(
                                                    modalQuery.family_acceptance_document,
                                                    'Planilla de registro posterior a la información familiar'
                                                )
                                            "
                                            :disabled="
                                                !modalQuery.family_acceptance_document ||
                                                    modalQuery.family_acceptance_document ===
                                                        ''
                                            "
                                        >
                                            <i class="fa fa-download"></i>
                                            Descargar
                                        </button>
                                    </div>
                                </div>
                                <hr />
                                <div class="row pb-2">
                                    <div class="col-12 col-lg-10">
                                        <label
                                            for="family_acceptance_document"
                                            class="form-label"
                                        >
                                            Acta de protocolo en caso de muerte
                                            violenta, servicio médico forense
                                        </label>
                                    </div>
                                    <div
                                        class="col-12 col-lg-2 d-flex justify-content-center justify-content-lg-end"
                                    >
                                        <button
                                            class="btn btn-outline-primary-dark"
                                            @click="
                                                downloadFile(
                                                    modalQuery.document_protocol_violent_death,
                                                    'Acta de protocolo en caso de muerte violenta, servicio médico forense'
                                                )
                                            "
                                            :disabled="
                                                !modalQuery.document_protocol_violent_death ||
                                                    modalQuery.document_protocol_violent_death ===
                                                        ''
                                            "
                                        >
                                            <i class="fa fa-download"></i>
                                            Descargar
                                        </button>
                                    </div>
                                </div>
                                <hr />
                                <div class="row pb-2">
                                    <div class="col-12 col-lg-10">
                                        <label
                                            for="family_acceptance_document"
                                            class="form-label"
                                        >
                                            Planilla de registro hepatectomía
                                            programa de coordinación
                                            hospitalaria de trasplante
                                        </label>
                                    </div>
                                    <div
                                        class="col-12 col-lg-2 d-flex justify-content-center justify-content-lg-end"
                                    >
                                        <button
                                            class="btn btn-outline-primary-dark"
                                            @click="
                                                downloadFile(
                                                    modalQuery.document_hepatectomy_form,
                                                    'Planilla de registro hepatectomía programa de coordinación hospitalaria de trasplante'
                                                )
                                            "
                                            :disabled="
                                                !modalQuery.document_hepatectomy_form ||
                                                    modalQuery.document_hepatectomy_form ===
                                                        ''
                                            "
                                        >
                                            <i class="fa fa-download"></i>
                                            Descargar
                                        </button>
                                    </div>
                                </div>
                                <!-- Final de Documentos requeridos -->
                                <hr>
                                <h3 v-if="families.length" class="h5 text-secondary text-uppercase">
                                    Familiares con quienes se compartió la decisión
                                </h3>
                                <div v-if="families != null">
                                    <div class="row pb-4" v-for="(family, index) in families" :key="index">
                                        <div class="col-12 col-lg-6 mb-3">
                                            <label for="family_names" class="form-label">Nombres</label>
                                            <input id="family_names" class="form-control" type="text" disabled
                                                :value="family.names" />
                                        </div>
                                        <div class="col-12 col-lg-6 mb-3">
                                            <label for="family_surnames" class="form-label">Apellidos</label>
                                            <input id="family_surnames" class="form-control" type="text" disabled
                                                :value="family.surnames" />
                                        </div>
                                        <div class="col-12 col-lg-6 mb-3">
                                            <label for="family_phone" class="form-label">Teléfono</label>
                                            <input id="pfamily_hone" class="form-control" type="text" disabled
                                                :value="family.phone" />
                                        </div>
                                        <div class="col-12 col-lg-6 mb-3">
                                            <label for="family_relationship" class="form-label">Parentesco</label>
                                            <input id="family_relationship" class="form-control" type="text" disabled
                                                :value="getKinship(family.relationship)" />
                                        </div>
                                    </div>
                                </div>

                                <!-- Datos del personal de inmunología asociado
                                al registro de este donante efectivo -->
                                <h4 class="h6 text-secondary">
                                    Datos del personal de inmunología asociado
                                    al registro de este Donante efectivo
                                </h4>
                                <br>
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="doctor" class="form-label">Personal de inmunología asociado</label>
                                        <input id="doctor" class="form-control" type="text" disabled
                                            :value="modalQuery.pin.name" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="email_uin" class="form-label">
                                            Correo electrónico de la unidad <br> de inmunología
                                        </label>
                                        <input
                                            id="email_uin"
                                            name="email_uin"
                                            type="email"
                                            class="form-control"
                                            :value="modalQuery.email_uin"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <!-- Final modal-body -->

                            <!-- Modal-footer -->
                            <div class="modal-footer justify-content-center">
                                <button type="button" class="btn btn-outline-primary-dark"
                                    data-bs-dismiss="modal" aria-label="Close">
                                    Cerrar
                                </button>
                                <button type="button" class="btn btn-secondary-dark" @click="exportPDF()">
                                    <i class="fa fa-address-card"></i>
                                    Generar planilla
                                </button>
                            </div>
                            <!-- Final Modal-footer -->
                        </div>
                        <!-- Final modal-content -->
                    </div>
                    <!-- Fianl modal-dialog -->
                </div>
                <!-- Modal -->
            </div>
            <!-- Card -->
        </div>
    </div>
</template>

<script>
import base64images from '../../../base64images'
export default {
    name : 'EffectiveDonorRecordList',
    data() {
        return {
            records: [],
            tmpRecords: [],
            families: [],
            kinship: [],
            modalQuery: {
                hla_test_results:{},
                pin:{},
                agent_info:{},
                health_center:{},
            },
            profile: {
                ocupation: '',
            },
            organs: [],
            blood_type: [],
            blood_groups: [],
            doctor: [],
            columns: [ 'number', 'profile', 'profile.dni', 'donorSchema', 'id' ],
            organs_list:{
                list: [
                    {
                        name: 'Riñon',
                        therapeutic: 'therapeutic_kidney',
                        research: 'reasearch_kidney'
                    },
                    {
                        name: 'Páncreas',
                        therapeutic: 'therapeutic_pancreas',
                        research: 'reasearch_pancreas'
                    },
                    {
                        name: 'Hígado',
                        therapeutic: 'therapeutic_liver',
                        research: 'reasearch_liver'
                    },
                    {
                        name: 'Corazón',
                        therapeutic: 'therapeutic_heart',
                        research: 'reasearch_heart'
                    },
                    {
                        name: 'Intestino',
                        therapeutic: 'therapeutic_intestine',
                        research: 'reasearch_intestine'
                    },
                    {
                        name: 'Pulmón',
                        therapeutic: 'therapeutic_lung',
                        research: 'reasearch_lung'
                    },{
                        name: 'Sangre',
                        therapeutic: 'therapeutic_blood',
                        research: 'reasearch_blood'
                    },
                    {
                        name: 'Tejido Ocular',
                        therapeutic: 'therapeutic_eye_tissue',
                        research: 'reasearch_eye_tissue'
                    },
                    {
                        name: 'Tejido Osteotendinoso',
                        therapeutic: 'therapeutic_osteotendinous_tissue',
                        research: 'reasearch_osteotendinous_tissue'
                    },
                    {
                        name: 'Tejido Cardiovascular',
                        therapeutic: 'therapeutic_cardiovascular_tissue',
                        research: 'reasearch_cardiovascular_tissue'
                    },
                    {
                        name: 'Hueso',
                        therapeutic: 'therapeutic_bone',
                        research: 'reasearch_bone'
                    },
                    {
                        name: 'Válvulas Cardíacas',
                        therapeutic: 'therapeutic_heart_valves',
                        research: 'reasearch_heart_valves'
                    },
                    {
                        name: 'Piel',
                        therapeutic: 'therapeutic_skin',
                        research: 'reasearch_skin'
                    },
                    {
                        name: 'Cartílago',
                        therapeutic: 'therapeutic_cartilage',
                        research: 'reasearch_cartilage'
                    },
                    {
                        name: 'Vasos',
                        therapeutic: 'therapeutic_vessels',
                        research: 'reasearch_vessels'
                    },
                    {
                        name: 'Células Progenítoras Hematopoyéticas',
                        therapeutic: 'therapeutic_hematopoietic_stem_cells',
                        research: 'reasearch_hematopoietic_stem_cells'
                    },
                    {
                        name: 'Cordón umbilical',
                        therapeutic: 'therapeutic_umbilical_cord',
                        research: 'reasearch_umbilical_cord'
                    },
                ]
            },
            filterBy: {
                dni: '',
                fromAge: '',
                toAge: ''
            },
            filterSelectedOptions: {
                donationOption: "",
                genderOption: "",
                blodGroupOption: "",
                rhFactorOption: "",
                estate_id:"",
            },
            rhFactorOptions: [
                { id: "P", text: "Positivo" },
                { id: "N", text: "Negativo" }
            ],
            donationOptions: [
                { id: "T", text: "Total" },
                { id: "P", text: "Parcial" }
            ],
            genderOptions: [
                { id: "M", text: "Masculino" },
                { id: "F", text: "Femenino" }
            ],
            estates:[],
            location: {
                parish:'',
                municipality:'',
                estate:'',
            },
            profile_email:'',
        }
    },
    async created() {
        this.table_options.headings = {
            'number':             'Serial N°',
            'profile':            'Nombres y Apellidos',
            'profile.dni':        'Cédula',
            'donorSchema':        'Donación',
            'id':                 'Acción'
        };
        this.table_options.sortable = ['number', 'profile', 'profile.dni'];
        this.table_options.filterable = ['number', 'profile', 'profile.dni'];
        this.table_options.columnsClasses = {
            'id': 'text-center'
        };
    },
    methods:{
        /**
         * Método que devuelve la lista de familiares con quien se comparte
         * la decisión para ser mostrados en el reporte pdf.
         *
         * @method getFamilyNames
         *
         * @author Daniel Contreras <dcontreras@cenditel.gob.ve>
        */
        getFamilyNames() {
            const vm = this;
            let familyInfo = '';

            for (let [i, family] of vm.families.entries()) {
                if (i == vm.families.length - 1) {
                    familyInfo = familyInfo + 'Nombre: ' + family.names + ' ' + family.surnames + ' | Teléfono: ' + family.phone + ' | Parentesco: ' + vm.getKinship(family.relationship) + '.';
                } else {
                    familyInfo = familyInfo + 'Nombre: ' + family.names + ' ' + family.surnames + ' | Teléfono: ' + family.phone + ' | Parentesco: ' + vm.getKinship(family.relationship) + ',\n';
                }
            }
            return familyInfo;
        },

        getKinship(id){
            const vm= this;
            let text = '';

            vm.kinship.forEach(element => {
                if (element.id  == id) {
                    text = element.name;
                }
            });
            return text;
        },

        getNameOrgan(organ){
            const vm= this;
            let text = '';
            vm.organs_list.list.forEach(element => {
                if (element.therapeutic  == organ || element.research == organ ) {
                    text = element.name;
                }
            });
            return text;
        },

        getPartial(organs, type){
            let result = organs.filter(organ => organ.split('_')[0] == type );
            return result
        },


        /**
         * Método para mostrar la informacion de un registro
         * dado su ID en un modal
         *
         * @method showInfo
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {int}      id    ID del registro
         */
        async showInfo(id) {
            const vm = this;

            let recordEdit = JSON.parse(JSON.stringify(vm.records.filter((rec) => {
                return rec.id === id;
            })[0]));

            vm.modalQuery = recordEdit;
            vm.modalQuery.organs_selected=vm.modalQuery.organs_selected?JSON.parse(vm.modalQuery.organs_selected):''
            vm.modalQuery.hla_test_results=vm.modalQuery.hla_test_results?JSON.parse(vm.modalQuery.hla_test_results):{}
            vm.modalQuery.pin=vm.modalQuery.pin?vm.modalQuery.pin:{}
            vm.modalQuery.blood_group=vm.modalQuery.blood_group?vm.blood_groups.filter(elem => elem.id==parseInt(vm.modalQuery.blood_group))[0].name:""
            vm.profile = recordEdit.profile;

            if (recordEdit.families != null) {
                vm.families = JSON.parse(recordEdit.families).slice(0, 10);
            }
            else {
                vm.families = [];
            }

            if (vm.profile.user_id != null) {
                vm.user_role = vm.$parent.user.role;
                if ((vm.user_role == 'ADM') || (vm.user_role == 'SUP')) {
                    await axios.get(`${window.url}` + '/users-list/' + vm.profile.user_id)
                    .then((response) => {
                        vm.profile_email = response.data.result.email;
                    }).catch(error => {
                        console.log(error.response);
                    });
                }
                else {
                    location.href = '/';
                }
            }
            await vm.getLocation('parish', vm.profile.parish_id);
            $('#show_efective_donor').modal('show');
        },

        /**
        * Método para mostrar el género
        *
        * @method showGender
        *
        * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
        */
        showGender(gender){
            let genderType= ''
            if (gender === 'F') {
                genderType = 'Femenino'
            } else if (gender === 'M') {
                genderType = 'Masculino'
            }
            return genderType
        },

        /**
        * Obtiene el nombre del tipo de sangre dato un id.
        *
        * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
        * @method getTransplantType
        * @param     {int}      blood_type_id      ID del tipo de sangre
        */
        getBloodType(blood_type_id){
            const vm= this;
            let name = '';

            vm.blood_type.forEach(element => {
                if (element.id  == blood_type_id) {
                    name = element.name;
                }
            });
            return name;
        },
        /**
         * Método para reestablecer valores iniciales del formulario de filtros.
         *
         * @method resetFilters
         *
         * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <kingangelo_123@gmail.com>
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        resetFilters() {
            const vm = this;
            vm.filterBy = {
                dni: '',
                fromAge: '',
                toAge: ''
            };
            vm.filterSelectedOptions.donationOption = '';
            vm.filterSelectedOptions.genderOption = '';
            vm.filterSelectedOptions.blodGroupOption = '';
            vm.filterSelectedOptions.rhFactorOption = '';
            vm.filterSelectedOptions.estate_id = '';
            vm.records = vm.tmpRecords;
        },
        /**
         * Método que permite filtrar los datos de la tabla Donantes voluntarios.
         *
         * @method filterDonors
         *
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        filterDonors() {
            const vm = this
            vm.records = vm.tmpRecords.filter((rec) => {
                return (vm.filterBy.dni) ? (rec.profile.dni === vm.filterBy.dni) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.donationOption) ? (rec.donorSchema === vm.filterSelectedOptions.donationOption) : true;
            }).filter((rec) => {
                return (vm.filterBy.fromAge) ? (rec.profile.age >= vm.filterBy.fromAge) : true;
            }).filter((rec) => {
                return (vm.filterBy.toAge) ? (rec.profile.age <= vm.filterBy.toAge) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.genderOption) ? (rec.profile.gender === vm.filterSelectedOptions.genderOption) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.blodGroupOption) ? (parseInt(rec.blood_group) === parseInt(vm.filterSelectedOptions.blodGroupOption)) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.rhFactorOption) ? (rec.rh_factor === vm.filterSelectedOptions.rhFactorOption) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.estate_id && vm.filterSelectedOptions.estate_id!="") ? (parseInt(rec.profile.estate_id) === parseInt(vm.filterSelectedOptions.estate_id)) : true;
            })
        },
        /**
         * Método para mostrar el género
         *
         * @method showMaritalStatus
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         */
        showMaritalStatus(maritalStatus) {
            let maritalStatusType= ''
            if (maritalStatus === 'S') {
                maritalStatusType = 'Soltero(a)'
            } else if (maritalStatus === 'C') {
                maritalStatusType = 'Casado(a)'
            } else if (maritalStatus === 'D') {
                maritalStatusType = 'Divorciado(a)'
            } else if (maritalStatus === 'V') {
                maritalStatusType = 'Viudo(a)'
            }
            return maritalStatusType
        },
        /**
         * Obtiene el nombre del rh Factor dato un id.
         *
         * @author Luis Hernández <lhernandez@mppct.gob.ve>
         * @method getRhFactor
         * @param     {string}      rhFactor_id      ID del rh factor
         */
        getRhFactor(rhFactor_id){
            let result = this.rhFactorOptions.filter(element => element.id == rhFactor_id )[0]?this.rhFactorOptions.filter(element => element.id == rhFactor_id )[0].text:"";
            return result
        },
        async getLocation(url, id) {
            const vm = this;
            const query = `${window.url}/${url}/${id}`;

            await axios.get(query).then(response => {
                vm.location.parish = response.data.parish.name;
                vm.location.municipality = response.data.parish.municipality.name;
                vm.location.estate = response.data.parish.municipality.estate.name;
            }).catch(error => {
                    console.error(error)
            });
        },

        /**
         * Método para Descargar Archivos
         *
         * @method downloadFile
         *
         */
        async downloadFile(file, name) {
            const vm = this;
            const url = file;
            vm.errors = [];
            const formData = new FormData();
            formData.append("url", url);
            try {
                let response = await axios({
                    url: `${window.url}` + "/effective-donors-download",
                    method: "post",
                    data: formData,
                    responseType: "arraybuffer"
                });
                let fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                let fileLink = document.createElement("a");
                fileLink.href = fileURL;
                let ext =
                    file.substring(file.lastIndexOf(".") + 1, file.length) ||
                    file;
                fileLink.setAttribute("download", name + "." + ext);
                document.body.appendChild(fileLink);
                fileLink.click();
                URL.revokeObjectURL(fileLink.href);
            } catch (error) {
                let { errors } = JSON.parse(
                    String.fromCharCode.apply(
                        null,
                        new Uint8Array(error.response.data)
                    )
                );
                vm.errors = [];
                for (var index in errors) {
                    if (errors[index]) {
                        vm.errors.push(errors[index][0]);
                    }
                }
            }
        },

        /**
         * Método que permite exportar la planilla del Donante efectivo en PDF.
         *
         * @method exportPDF
         *
         * @author Luis Hernandez <lhernandez@mincyt.gob.ve>
        */
        exportPDF() {
            let contenido = [
                    // Cabecera del reporte
                    { text: '\n\nInformación del Donante efectivo\n\n', style: 'header' },
                    {
                        columns: [
                            {
                                // Fecha de registro
                                style: 'subheader',
                                text: 'Fecha de registro',
                            },
                            {
                                // Serial N°
                                style: 'subheader',
                                text: 'Serial N°',
                            },
                            {
                                // Vacío
                                text: '',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Fecha de registro
                                text: new Date(this.modalQuery.created_at).toLocaleDateString('en-GB')+'\n\n', style: 'text_query',
                            },
                            {
                                // Serial N°
                                text: this.modalQuery.number+'\n\n', style: 'text_query',
                            },
                            {
                                // Vacío
                                text: '',
                            },
                        ]
                    },
                    // Datos del usuario que realizó el registro
                    { text: 'Datos del usuario que realizó el registro\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Nombres
                                style: 'subheader',
                                text: 'Nombres',
                            },
                            {
                                // Apellidos
                                style: 'subheader',
                                text: 'Apellidos',
                            },
                            {
                                // Correo electrónico
                                style: 'subheader',
                                text: 'Correo electrónico',
                            }
                        ],
                    },
                    {
                        columns: [
                            {
                                // Nombres
                                text: this.$parent.user.profile.names+'\n\n', style: 'text_query',
                            },
                            {
                                // Apellidos
                                text: this.$parent.user.profile.surnames+'\n\n', style: 'text_query',
                            },
                            {
                                // Correo electrónico
                                text: this.$parent.user.email+'\n\n', style: 'text_query',
                            }
                        ]
                    },
                    // Datos personales
                    { text: 'Datos personales\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Nombres
                                style: 'subheader',
                                text: 'Nombres',
                            },
                            {
                                // Apellidos
                                style: 'subheader',
                                text: 'Apellidos',
                            },
                            {
                                // Cédula de identidad
                                style: 'subheader',
                                text: 'Cédula de identidad',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Nombres
                                text: this.profile.names+'\n\n', style: 'text_query',
                            },
                            {
                                // Apellidos
                                text: this.profile.surnames+'\n\n', style: 'text_query',
                            },
                            {
                                // Cédula de identidad
                                text: this.profile.dni+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                // Nacionalidad
                                style: 'subheader',
                                text: 'Nacionalidad',
                            },
                            {
                                // Género
                                style: 'subheader',
                                text: 'Género',
                            },
                            {
                                // Estado civil
                                style: 'subheader',
                                text: 'Estado civil',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Nacionalidad
                                text: this.profile.nationality == 'V' ? 'Venezolano(a)' : 'Extranjero(a)'+'\n\n', style: 'text_query',
                            },
                            {
                                // Género
                                text: this.showGender(this.profile.gender)+'\n\n', style: 'text_query',
                            },
                            {
                                // Estado civil
                                text: this.showMaritalStatus(this.profile.marital_status)+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                // Ocupación
                                style: 'subheader',
                                text: 'Ocupación',
                            },
                            {
                                // Nivel académico
                                style: 'subheader',
                                text: 'Nivel académico',
                            },
                            {
                                //
                                style: 'subheader',
                                text: '',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Ocupación
                                text: this.profile.ocupation ? this.profile.ocupation.name : 'Sin datos'+'\n\n', style: 'text_query',
                            },
                            {
                                // Nivel académico
                                text: this.profile.academic_level? this.profile.academic_level: 'Sin datos'+'\n\n', style: 'text_query',
                            },
                            {
                                //
                                text: '\n\n', style: 'text_query',
                            },
                        ]
                    },
                    //  Edad
                    { text: 'Edad\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Fecha de nacimiento
                                style: 'subheader',
                                text: 'Fecha de nacimiento',
                            },
                            {
                                // Edad
                                style: 'subheader',
                                text: 'Edad',
                            },
                            {
                                // Lugar de nacimiento
                                style: 'subheader',
                                text: 'Lugar de nacimiento',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Fecha de nacimiento
                                text: new Date(this.profile.birthdate).toLocaleDateString('en-GB', {timeZone: 'UTC'})+'\n\n', style: 'text_query',
                            },
                            {
                                // Edad
                                text: (this.profile.age?this.profile.age:'Sin datos')+'\n\n', style: 'text_query',
                            },
                            {
                                // Lugar de nacimiento
                                text: (this.profile.birthplace?this.profile.birthplace:'Sin datos')+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    //  Dirección
                    { text: 'Dirección\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Estado
                                style: 'subheader',
                                text: 'Estado',
                            },
                            {
                                // Municipio
                                style: 'subheader',
                                text: 'Municipio',
                            },
                            {
                                // Parroquía
                                style: 'subheader',
                                text: 'Parroquía',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Estado
                                text: (this.location.estate?this.location.estate:'Sin datos')+'\n\n', style: 'text_query',
                            },
                            {
                                // Municipio
                                text: (this.location.municipality?this.location.municipality:'Sin datos')+'\n\n', style: 'text_query',
                            },
                            {
                                // Parroquía
                                text: (this.location.parish?this.location.parish:'Sin datos')+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    //  Contacto
                    { text: 'Contacto\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Teléfono local
                                style: 'subheader',
                                text: 'Teléfono local',
                            },
                            {
                                // Teléfono móvil
                                style: 'subheader',
                                text: 'Teléfono móvil',
                            },
                            {
                                // Correo electrónico
                                style: 'subheader',
                                text: 'Correo electrónico',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Teléfono local
                                text: (this.profile.phone?this.profile.phone:'Sin datos')+'\n\n', style: 'text_query',
                            },
                            {
                                // Teléfono móvil
                                text: (this.profile.movil?this.profile.movil:'Sin datos')+'\n\n', style: 'text_query',
                            },
                            {
                                // Correo electrónico
                                text: (this.profile_email?this.profile_email:'Sin datos')+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    // Datos de la Donación
                    { text: 'Datos de la Donación\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Donación
                                style: 'subheader',
                                text: 'Donación',
                            },
                            {
                                // Terapeútico (trasplante)
                                style: 'subheader',
                                text: this.getArrayTherapeuticOrgans().length > 0 ? 'Terapeútico (trasplante)' : '',
                            },
                            {
                                // Investigación o Docencia
                                style: 'subheader',
                                text: this.getArrayReasearchOrgans().length > 0 ? 'Investigación o Docencia' : '',
                            }
                        ],
                    },
                    {
                        columns: [
                            {
                                // Donación
                                text: (this.modalQuery.donorSchema === 'T') ? 'Total' : 'Parcial'+'\n\n',
                                style: 'text_query',
                            },
                            {
                                // Terapeútico (trasplante)
                                text: (this.modalQuery.donorSchema === 'T') ? '' : this.getArrayTherapeuticOrgans()+'\n\n',
                                style: 'text_query',
                                margin: [0, 0, 20, 20]
                            },
                            {
                                // Investigación o Docencia
                                text: (this.modalQuery.donorSchema === 'T') ? '\n' : this.getArrayReasearchOrgans()+'\n\n',
                                style: 'text_query',
                                margin: [0, 0, 20, 20]
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                // Grupo sanguíneo
                                style: 'subheader',
                                text: '\nGrupo sanguíneo',
                            },
                            {
                                // Factor RH
                                style: 'subheader',
                                text: '\nFactor RH',
                            },
                            {
                                // Centro generador de órganos y tejidos
                                style: 'subheader',
                                text: '\nCentro generador de órganos y tejidos',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Grupo sanguíneo
                                text: this.modalQuery.blood_group+'\n\n', style: 'text_query',
                            },
                            {
                                // Factor RH
                                text: this.modalQuery.rh_factor == 'P' ? 'Positivo' : 'Negativo'+'\n\n', style: 'text_query',
                            },
                            {
                                // Centro generador de órganos y tejidos
                                text: this.modalQuery.health_center ? this.modalQuery.health_center.name : 'Sin datos'+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                // Talla
                                style: 'subheader',
                                text: '\nTalla',
                            },
                            {
                                // Peso (Kg)
                                style: 'subheader',
                                text: '\nPeso (Kg)',
                            },
                            {
                                // Fecha de muerte
                                style: 'subheader',
                                text: '\nFecha de muerte',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Talla
                                text: (this.modalQuery.size?this.modalQuery.size:'Sin datos')+'\n\n', style: 'text_query',
                            },
                            {
                                // Peso (Kg)
                                text: (this.modalQuery.weight?this.modalQuery.weight:'Sin datos')+'\n\n', style: 'text_query',
                            },
                            {
                                // Fecha de muerte
                                text: new Date(this.modalQuery.death_date).toLocaleDateString('en-GB', {timeZone: 'UTC'})+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                // Hora de muerte
                                style: 'subheader',
                                text: '\nHora de muerte',
                            },
                            {
                                // Diagnóstico
                                style: 'subheader',
                                text: '\nDiagnóstico',
                            },
                            {
                                //
                                style: 'subheader',
                                text: '\n',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Hora de muerte
                                text: (this.modalQuery.death_time?this.modalQuery.death_time:'Sin datos')+'\n\n', style: 'text_query',
                            },
                            {
                                // Diagnóstico
                                text: (this.modalQuery.diagnosis?this.modalQuery.diagnosis:'Sin datos')+'\n\n', style: 'text_query',
                            },
                            {
                                //
                                text: '\n\n', style: 'text_query',
                            },
                        ]
                    },
                    //  Órganos, células y tejidos que han sido extraídos
                    { text: 'Órganos, células y tejidos que han sido extraídos\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Cantidad
                                style: 'subheader',
                                text: 'Cantidad',
                            },
                            {
                                // Órgano
                                style: 'subheader',
                                text: 'Órgano',
                            },
                            {
                                // Observación
                                style: 'subheader',
                                text: 'Observación',
                            },
                        ],
                    }]
            for(let orgn in this.modalQuery.organs_selected){
                contenido.push({
                    columns: [
                        {
                            // cantidad
                            text: (this.modalQuery.organs_selected[orgn].number?this.modalQuery.organs_selected[orgn].number:'Sin datos')+'\n\n', style: 'text_query',
                        },
                        {
                            // organo
                            text: (this.modalQuery.organs_selected[orgn].organ?this.modalQuery.organs_selected[orgn].organ:'Sin datos')+'\n\n', style: 'text_query',
                        },
                        {
                            // observacion
                            text: (this.modalQuery.organs_selected[orgn].observation?this.modalQuery.organs_selected[orgn].observation:'Sin datos')+'\n\n', style: 'text_query',
                        },
                    ]
                })
            }
            contenido.push(...[//  Exámenes médicos
                    { text: '\n\nExámenes médicos\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Observaciones de serología
                                style: 'subheader',
                                text: 'Observaciones de serología',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Observaciones de serología
                                text: (this.modalQuery.serology_test_observation?this.modalQuery.serology_test_observation:'Sin datos')+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    //  Recepción de muestras
                    { text: 'Recepción de muestras\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Ganglios
                                style: 'subheader',
                                text: 'Ganglios',
                            },
                            {
                                // Cantidad
                                style: 'subheader',
                                text: 'Cantidad',
                            },
                            {
                                // Observación
                                style: 'subheader',
                                text: 'Observación',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                //
                                text: '\n\n', style: 'text_query',
                            },
                            {
                                // Observaciones de serología
                                text: this.modalQuery.ganglios_quantity ? this.modalQuery.ganglios_quantity+'\n\n' : 'Sin datos',
                                style: 'text_query',
                            },
                            {
                                // Observaciones de serología
                                text: this.modalQuery.ganglios_observation ? this.modalQuery.ganglios_observation+'\n\n' : 'Sin datos',
                                style: 'text_query',
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                // Tubo con EDTA
                                style: 'subheader',
                                text: 'Tubo con EDTA',
                            },
                            {
                                // Cantidad
                                style: 'subheader',
                                text: 'Cantidad',
                            },
                            {
                                // Observación
                                style: 'subheader',
                                text: 'Observación',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                //
                                text: '\n\n', style: 'text_query',
                            },
                            {
                                // Observaciones de serología
                                text: this.modalQuery.tube_edta_quantity ? this.modalQuery.tube_edta_quantity+'\n\n' : 'Sin datos',
                                style: 'text_query',
                            },
                            {
                                // Observaciones de serología
                                text: this.modalQuery.tube_edta_observation ? this.modalQuery.tube_edta_observation+'\n\n' : 'Sin datos',
                                style: 'text_query',
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                // Tubo sin aditivos
                                style: 'subheader',
                                text: 'Tubo sin aditivos',
                            },
                            {
                                // Cantidad
                                style: 'subheader',
                                text: 'Cantidad',
                            },
                            {
                                // Observación
                                style: 'subheader',
                                text: 'Observación',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                //
                                text: '\n\n', style: 'text_query',
                            },
                            {
                                // Observaciones de serología
                                text: this.modalQuery.tube_w_additives_quantity ? this.modalQuery.tube_w_additives_quantity+'\n\n' : 'Sin datos',
                                style: 'text_query',
                            },
                            {
                                // Observaciones de serología
                                text: this.modalQuery.tube_w_additives_observation ?
                                this.modalQuery.tube_w_additives_observation+'\n\n' : 'Sin datos',
                                style: 'text_query',
                            },
                        ]
                    },
                    //  Resultados de inmunología
                    { text: '\n\nResultados de inmunología\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Clase 1
                                style: 'subheader',
                                text: 'Clase 1',
                                width: '30%',
                                alignment: 'center'
                            },
                            {
                                // Clase 2
                                style: 'subheader',
                                text: 'Clase 2',
                                width: '70%',
                                alignment: 'center'
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                // HLA
                                style: 'subheader',
                                text: '\nHLA',
                            },
                            {
                                // A
                                style: 'subheader',
                                text: '\nA',
                            },
                            {
                                // B
                                style: 'subheader',
                                text: '\nB',
                            },
                            {
                                // C
                                style: 'subheader',
                                text: '\nC',
                            },
                            {
                                // DRB1
                                style: 'subheader',
                                text: '\nDRB1',
                            },
                            {
                                // DBQ1
                                style: 'subheader',
                                text: '\nDBQ1',
                            },
                            {
                                // DPB1
                                style: 'subheader',
                                text: '\nDPB1',
                            },
                            {
                                // DQA1
                                style: 'subheader',
                                text: '\nDQA1',
                            },
                            {
                                // DPA1
                                style: 'subheader',
                                text: '\nDPA1',
                            },
                            {
                                // DRB3
                                style: 'subheader',
                                text: '\nDRB3',
                            },
                            {
                                // DRB4
                                style: 'subheader',
                                text: '\nDRB4',
                            },
                            {
                                // DRB5
                                style: 'subheader',
                                text: '\nDRB5',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Alelo 1
                                style: 'subheader',
                                text: 'Alelo 1',
                            },
                            {
                                // A
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_1_a, style: 'text_query',
                            },
                            {
                                // B
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_1_b, style: 'text_query',
                            },
                            {
                                // C
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_1_c, style: 'text_query',
                            },
                            {
                                // DRB1
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_1_DRB1, style: 'text_query',
                            },
                            {
                                // DBQ1
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_1_DBQ1, style: 'text_query',
                            },
                            {
                                // DPB1
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_1_DPB1, style: 'text_query',
                            },
                            {
                                // DQA1
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_1_DQA1, style: 'text_query',
                            },
                            {
                                // DPA1
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_1_DPA1, style: 'text_query',
                            },
                            {
                                // DRB3
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_1_DRB3, style: 'text_query',
                            },
                            {
                                // DRB4
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_1_DRB4, style: 'text_query',
                            },
                            {
                                // DRB5
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_1_DRB5, style: 'text_query',
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                // Alelo 2
                                style: 'subheader',
                                text: 'Alelo 2',
                            },
                            {
                                // A
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_2_a, style: 'text_query',
                            },
                            {
                                // B
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_2_b, style: 'text_query',
                            },
                            {
                                // C
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_2_c, style: 'text_query',
                            },
                            {
                                // DRB1
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_2_DRB1, style: 'text_query',
                            },
                            {
                                // DBQ1
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_2_DBQ1, style: 'text_query',
                            },
                            {
                                // DPB1
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_2_DPB1, style: 'text_query',
                            },
                            {
                                // DQA1
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_2_DQA1, style: 'text_query',
                            },
                            {
                                // DPA1
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_2_DPA1, style: 'text_query',
                            },
                            {
                                // DRB3
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_2_DRB3, style: 'text_query',
                            },
                            {
                                // DRB4
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_2_DRB4, style: 'text_query',
                            },
                            {
                                // DRB5
                                style: 'subheader',
                                text: this.modalQuery.hla_test_results.allele_2_DRB5, style: 'text_query',
                            },
                        ]
                    },
                    // Familiares con quienes se compartió la decisión
                    {
                        text: this.families.length > 0 ? '\n\nFamiliares con quienes se compartió la decisión\n\n' : '',
                        style: 'header'},
                    {
                        columns: [
                            {
                                // Familiares
                                style: 'subheader',
                                text: this.families.length > 0 ? 'Familiares' : '',
                            }
                        ],
                    },
                    {
                        columns: [
                            {
                                // Familiares
                                text: this.getFamilyNames()+'\n\n', style: 'text_query'
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                // Datos del personal de inmunología asociado al registro de este donante efectivo
                                style: 'subheader',
                                text: '\nPersonal de inmunología asociado',
                            },
                            {
                                // Correo electrónico de la unidad de inmunología.
                                style: 'subheader',
                                text: '\nCorreo electrónico de la unidad de inmunología',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Personal de inmunología asociado
                                text: this.modalQuery.pin ? this.modalQuery.pin.name+'\n\n' : '', style: 'text_query',
                            },
                            {
                                // Correo electrónico de la unidad de inmunología
                                text: this.modalQuery.email_uin ? this.modalQuery.email_uin + '\n\n' : '', style: 'text_query',
                            }
                        ]
                    },
                    // Footer del reporte
                    {
                        image: base64images.footerPlanillasPDF(),
                        width: 450,
                        style: 'text_center',
                        absolutePosition: {x: 12, y: 730}
                    }])
            var docDefinition = {
                info: {
                    title: 'Planilla N° '+this.modalQuery.number,
                },
                pageOrientation: 'portrait',
                //Cintillo
                header: {
                    image: base64images.cintilloFundavene(),
                    width: 530,
                    style:'text_center'
                },
                pageMargins: [ 40, 60, 40, 60 ],
                content: contenido,
                styles: {
                    header: {
                        fontSize: 13,
                        bold: true,
                        alignment: 'justify',
                    },
                    subheader: {
                        fontSize: 10,
                        bold: true,
                        alignment: 'justify',
                    },
                    text_query: {
                        fontSize: 8,
                        alignment: 'justify',
                    },
                    text_bold: {
                        fontSize: 8,
                        alignment: 'center',
                        bold: true,
                    },
                    text_center: {
                        fontSize: 8,
                        alignment: 'center',
                    },
                }
            }
            pdfMake.createPdf(docDefinition).download('Planilla Donante efectivo-Serial N°'+this.modalQuery.number+'-CI-'+this.profile.dni);
        },
        /**
         * Método que devuelve la lista de órganos de tipo terapeuticos
         * para ser mostrados en el reporte pdf.
         *
         * @method getArrayTherapeuticOrgans
         *
         * @author Ing. Argenis Osorio <aosorio@cenditel.gob.ve>
        */
        getArrayTherapeuticOrgans() {
            const vm= this;
            if (vm.modalQuery.partial_organs) {
                const a = vm.getPartial(vm.modalQuery.partial_organs,'therapeutic');
                let arrayTherapeuticOrgans = '';
                for (let organ of a) {
                    // vm.getNameOrgan(organ)
                    // arrayTherapeuticOrgans.push(
                    //     vm.getNameOrgan(organ)
                    // );
                    arrayTherapeuticOrgans += vm.getNameOrgan(organ) + ', ' ;
                }
                return arrayTherapeuticOrgans;
            }
            else {
                return [];
            }
        },
        /**
         * Método que devuelve la lista de órganos para investigación a ser
         * mostrados en el reporte pdf.
         *
         * @method getArrayReasearchOrgans
         *
         * @author Ing. Argenis Osorio <aosorio@cenditel.gob.ve>
        */
        getArrayReasearchOrgans() {
            const vm= this;
            if (vm.modalQuery.partial_organs) {
                const a = vm.getPartial(vm.modalQuery.partial_organs,'reasearch');
                let arrayRasearchOrgans = [];
                for (let organ of a) {
                    arrayRasearchOrgans += vm.getNameOrgan(organ) + ', ';
                }
                return arrayRasearchOrgans;
            }
            else {
                return [];
            }
        },
    },
    async mounted() {
        const vm = this;
        /**
         * Si el usuario autenticado intenta acceder al componente sin tener el
         * rol requerido este no carta los diferentes datos a consultar y
         * redirecciona a la pantalla inicial.
        */
        vm.user_role = vm.$parent.user.role;
        if ((vm.user_role == 'ADM') || (vm.user_role == 'SUP')) {
            vm.loadingState(true); // Inicio de spinner de carga.
            // Obtener los estados.
            await vm.getEstates()
            await vm.getRecords('effective-donors/current-year-donors', 'records');
            await vm.getRecords('global-data-type/blood_type', 'blood_type');
            // Variable temporal a ser usada en el formulario de filtros.
            vm.tmpRecords = vm.records;
            // Obtener el listado de órganos.
            await vm.getRecords('global-data-type/kinship', 'kinship');
            // Obtener el listado de los grupos sanguíneos.
            await vm.getRecords('global-data-type/blood_group', 'blood_groups')
            vm.loadingState(); // Finaliza spinner de carga.
        }
        else {
            location.href = '/';
        }
    }
}
</script>
