<template>
    <div id="living-donor-not-suitable-patient-list-component">
        <go-back title="Regresar"/>
        <div class="card mb-4 shadow-sm">
            <h2 class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary">
                <i class="fa fa-book"></i> Pacientes no aptos con donante vivo
            </h2>
            <div class="card-body">
                <v-client-table :columns="columns" :data="records" :options="table_options">
                    <div slot="id" slot-scope="props" class="main-flex-buttons">
                    </div>
                </v-client-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            records: [],
            columns: ['names', 'id_number', 'status', 'transplant_type', 'date' , 'blood_type','id'],
        }
    },
    created() {
        this.table_options.headings = {
            'names': 'Nombres y apellidos',
            'id_number': 'Cédula',
            'status': 'Estatus',
            'transplant_type': 'Tipo de trasplante',
            'date': 'Fecha de la solicitud',
            'blood_type': 'Tipo de sangre',
            'id': 'Acción'
        };
        this.table_options.sortable = ['names', 'id_number', 'status', 'transplant_type', 'date' , 'blood_type',];
        this.table_options.filterable = ['names', 'id_number', 'status', 'transplant_type', 'date' , 'blood_type',];
        this.table_options.columnsClasses = {
            'id': 'text-center'
        };
    },
}
</script>
