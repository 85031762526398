<template>
    <div id="profile-component">
        <div class="card mb-4 shadow-sm">
            <h2 class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary">
                <i class="fa fa-user"></i> Perfil
            </h2>
            <div class="card-body">
                <form-errors :errors="errors"></form-errors>
                <!-- Solicitud de trasplante -->
                <h3 class="h5 text-secondary text-uppercase" v-bind:class="role ? '' : 'd-none'">
                    Solicita trasplante
                </h3>
                <hr v-bind:class="role ? '' : 'd-none'" />

                <div class="row pb-4" v-bind:class="role ? '' : 'd-none'">
                    <div class="col-12 col-lg-4 mb-3">
                        <div class="form-check form-switch">
                            <div v-if="aplicants.length > 0">
                                <div v-for="(val, key, index) in aplicants" :key="index">
                                    <div v-if="index != Object.keys(aplicants).length - 1">
                                        <input id="applicantOption" class="form-check-input donor_type" type="checkbox"
                                            name="applicant" role="switch" tabindex="5" value="AP"
                                            :disabled="val.status != 99" v-model="record.applicant" />
                                        <label class="form-check-label" for="applicantOption">
                                            Solicitante
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <input id="applicantOption" class="form-check-input donor_type" type="checkbox"
                                    name="applicant" role="switch" value="AP" tabindex="5" v-model="record.applicant" />
                                <label class="form-check-label" for="applicantOption">
                                    Solicitante
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Datos Personales -->
                <h3 class="h5 text-secondary text-uppercase">
                    Datos personales
                </h3>
                <hr />
                <div class="row pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="names" class="form-label">
                            Nombres <span class="text-danger">*</span>
                        </label>
                        <input id="names" class="form-control" type="text"
                            placeholder="Nombres" tabindex="2"
                            v-model="record.names" />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="surnames" class="form-label">
                            Apellidos <span class="text-danger">*</span>
                        </label>
                        <input id="surnames" class="form-control" type="text"
                            placeholder="Apellidos" tabindex="3"
                            v-model="record.surnames" />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="dni" class="form-label">
                            Cédula de identidad
                            <span class="text-danger" v-if="record.age > 17">*</span>
                        </label>
                        <input id="dni" class="form-control" type="text"
                            minlength="7" maxlength="9"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Cédula de identidad"
                            tabindex="4" v-model="record.dni" />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="dni" class="form-label">
                            Correo electrónico
                            <span class="text-danger">*</span>
                        </label>
                        <input
                            id="email"
                            class="form-control"
                            type="email"
                            placeholder="Correo electrónico"
                            tabindex="5"
                            v-model="record.email"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="nationality" class="form-label">
                            Nacionalidad <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="selectedOptions.nationality"
                            :options="nationalityOptions"
                            :reduce="nationality => nationality.id"
                            label="text"
                            id="nationality"
                            :tabindex="6"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="gender" class="form-label">
                            Género <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="selectedOptions.gender"
                            :options="genderOptions"
                            :selectable="option => option.id !== ''"
                            :reduce="gender => gender.id"
                            label="text"
                            id="gender"
                            :tabindex="7"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="ocupation" class="form-label">
                            Ocupación <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="selectedOptions.ocupation"
                            :options="occupations"
                            :selectable="option => option.id !== ''"
                            :reduce="ocupation => ocupation.id"
                            label="name"
                            id="ocupation"
                            :tabindex="8"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="marital_status" class="form-label">
                            Estado civil <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="selectedOptions.marital_status"
                            :options="maritalStatusOptions"
                            :selectable="option => option.id !== ''"
                            :reduce="ms => ms.id"
                            label="text"
                            id="marital_status"
                            :tabindex="9"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="academic_levels" class="form-label">
                            Nivel académico <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="selectedOptions.academic_level"
                            :options="academic_levels"
                            :selectable="option => option.id !== ''"
                            :reduce="ms => ms.name"
                            label="name"
                            id="academic_level"
                            :tabindex="10"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                </div>

                <!-- Edad -->
                <h3 class="h5 text-secondary text-uppercase">Edad</h3>
                <hr />
                <div class="row pb-4">
                    <!-- Fecha de nacimiento -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="birthdate" class="form-label">
                            Fecha de nacimiento
                            <span class="text-danger">*</span>
                        </label>
                        <input
                            id="birthdate"
                            class="form-control"
                            type="date"
                            placeholder="Fecha de nacimiento"
                            tabindex="-1"
                            :max="today"
                            v-model="record.birthdate"
                            @change="setAge"
                        />
                    </div>
                    <!-- Edad -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="age" class="form-label">
                            Edad <span class="text-danger">*</span>
                        </label>
                        <input
                            id="age"
                            class="form-control"
                            type="text"
                            placeholder="Edad"
                            v-model="record.age"
                            disabled
                        />
                        <div id="ageHelp" class="form-text">
                            Este campo se autorrellena al ingresar la
                            <label class="fw-bold" for="birthdate"
                                >Fecha de nacimiento</label
                            >.
                        </div>
                    </div>
                    <!-- Lugar de nacimiento -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="birthplace" class="form-label">
                            Lugar de nacimiento
                            <span class="text-danger">*</span>
                        </label>
                        <input
                            id="birthplace"
                            class="form-control"
                            type="text"
                            placeholder="Lugar de nacimiento"
                            tabindex="12"
                            v-model="record.birthplace"
                        />
                    </div>
                </div>

                <div v-if="record.age > 17">
                    <h3 class="h5 text-secondary text-uppercase"
                        v-bind:class="role ? '' : 'd-none'"
                    >
                        Voluntad de donación <span class="text-danger" style="font-size: 14px;">*</span>
                    </h3>
                    <hr v-bind:class="role ? '' : 'd-none'" />
                    <div class="row pb-4" v-bind:class="role ? '' : 'd-none'">
                        <div class="col-12 col-lg-4 mb-3">
                            <div class="form-check form-check-inline pt-2">
                                <input
                                    id="donorOption"
                                    class="form-check-input donor_type"
                                    type="radio"
                                    name="type"
                                    value="DN"
                                    tabindex="1"
                                    v-model="record.donorType"
                                />
                                <label class="form-check-label" for="donorOption">
                                    Donante
                                </label>
                            </div>
                            <div class="form-check form-check-inline pt-2">
                                <input
                                    id="notDonorOption"
                                    class="form-check-input donor_type"
                                    type="radio"
                                    name="type"
                                    value="ND"
                                    v-model="record.donorType"
                                />
                                <label
                                    class="form-check-label"
                                    for="notDonorOption"
                                >
                                    No Donante
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Dirección -->
                <h3 class="h5 text-secondary text-uppercase">Dirección</h3>
                <hr />
                <div class="row pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="estate_id" class="form-label">
                            Estado <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="selectedOptions.estate_id"
                            :options="estates"
                            :selectable="option => option.id !== ''"
                            :reduce="estate => estate.id"
                            label="text"
                            id="estate_id"
                            :tabindex="13"
                            @input="
                                selectedOptions.municipality_id='';selectedOptions.parish_id='';getMunicipalities(selectedOptions.estate_id)
                            "
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="municipality_id" class="form-label">
                            Municipio <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="selectedOptions.municipality_id"
                            :options="municipalities"
                            :selectable="option => option.id !== ''"
                            :reduce="mun => mun.id"
                            label="text"
                            id="municipality_id"
                            :tabindex="14"
                            @input="
                                selectedOptions.parish_id='';getParishes(selectedOptions.municipality_id)
                            "
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="parish_id" class="form-label">
                            Parroquia <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="selectedOptions.parish_id"
                            :options="parishes"
                            :selectable="option => option.id !== ''"
                            :reduce="par => par.id"
                            label="text"
                            id="parish_id"
                            :tabindex="15"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                </div>

                <!-- Contacto -->
                <h3 class="h5 text-secondary text-uppercase">Contacto</h3>
                <hr />
                <div class="row pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="phone" class="form-label">
                            Teléfono local <span class="text-danger">*</span>
                        </label>
                        <input
                            id="phone"
                            class="form-control"
                            type="text"
                            placeholder="Teléfono"
                            tabindex="16"
                            v-model="record.phone"
                            maxlength="11"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="movil" class="form-label">
                            Teléfono móvil <span class="text-danger">*</span>
                        </label>
                        <input
                            id="movil"
                            class="form-control"
                            type="text"
                            placeholder="Teléfono móvil"
                            tabindex="17"
                            v-model="record.movil"
                            maxlength="11"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        />
                    </div>
                </div>
            </div>
            <div class="card-footer text-center">
                <button
                    type="button"
                    class="btn btn-primary text-light"
                    @click="setRecord('profile-update')"
                >
                    <i class="fa fa-save"></i>
                    Guardar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            errors: [],
            estates: [],
            municipalities: [],
            parishes: [],
            aplicants: [],
            record: {
                dni: "",
                names: "",
                surnames: "",
                email: "",
                birthdate: "",
                birthplace: "",
                age: "",
                nationality: "",
                gender: "",
                ocupation: "",
                marital_status: "",
                phone: "",
                movil: "",
                estate_id: "",
                municipality_id: "",
                parish_id: "",
                academic_level: "",
                donorType: "",
                applicant: false
            },
            selectedOptions: {
                nationality: "",
                gender: "",
                ocupation: "",
                marital_status: "",
                academic_level: "",
                estate_id: "",
                municipality_id: "",
                parish_id: ""
            },
            occupations_url: "global-data-type/occupation",
            occupations: [],
            academic_levels: [],
            nationalityOptions: [
                { id: "V", text: "Venezolano(a)" },
                { id: "E", text: "Extranjero(a)" }
            ],
            genderOptions: [
                { id: "F", text: "Femenino" },
                { id: "M", text: "Masculino" }
            ],
            maritalStatusOptions: [
                { id: "S", text: "Soltero(a)" },
                { id: "C", text: "Casado(a)" },
                { id: "D", text: "Divorciado(a)" },
                { id: "V", text: "Viudo(a)" }
            ],
            profile: "",
            role: "",
            today: "",
            wasUnderage: false,
        };
    },
    methods: {
        /**
         * Ejecuta la acción para guardar o actualizar datos
         *
         * @method setRecord
         *
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         *
         * @param  {String}  url URL de la petición a realizar
         */
        async setRecord(url) {
            const vm = this;
            vm.errors = [];
            vm.loadingState(true); // Inicio de spinner de carga.
            let recordId = typeof vm.record.id !== "undefined" && vm.record.id ? vm.record.id : "";
            vm.record.nationality = vm.selectedOptions.nationality;
            vm.record.gender = vm.selectedOptions.gender;
            vm.record.ocupation = vm.selectedOptions.ocupation;
            vm.record.marital_status = vm.selectedOptions.marital_status;
            vm.record.academic_level = vm.selectedOptions.academic_level;
            vm.record.estate_id = vm.selectedOptions.estate_id;
            vm.record.municipality_id = vm.selectedOptions.municipality_id;
            vm.record.parish_id = vm.selectedOptions.parish_id;

            await axios({
                method: recordId ? "put" : "post",
                url: `${window.url}/${url}/${recordId}`,
                data: vm.record
            })
            .then(response => {
                vm.showMessage("updated");
                vm.loadingState(); // Finaliza spinner de carga.

                // Elimina el aviso de alerta de "Por favor complete su perfil de usuario."
                // Y muestra el aviso de "Por favor realice el Registro nacional de voluntad de donación (RENAVDO)."
                if (document.querySelector("#profile-message")) {
                    setTimeout(() => {
                        location.reload();
                    }, 2000);
                }

                // Muestra el aviso de rellenar el RENAVDO en caso de que fuera menor de edad y ahora es mayor.
                if (vm.wasUnderage && vm.record.age > 17) {
                    vm.wasUnderage = false;
                    setTimeout(() => {
                        location.reload();
                    }, 2000);
                }

                // Si el usuario llena el RENAVDO y luego vuelve al formulario de perfil y aplica ahora como
                // solicitante.
                if (vm.profile.applicant) {
                    setTimeout(() => {
                        location.reload();
                    }, 2000);
                }
            })
            .catch(error => {
                vm.loadingState(); // Finaliza spinner de carga.
                vm.setErrors(error);
            });
        },

        /**
         * Método que rellena el campo edad según le fecha de nacimiento
         * seleccionada.
         *
         * @method setAge
         *
         * @author José Briceño <josejorgebriceno9@gmail.com>
         */
        setAge() {
            const vm = this;
            let age = moment().diff(
                vm.record.birthdate,
                "years",
                false);
            vm.record.age = age > -1 ? age : "";
        },

        /**
         * Método que asigna el estado de los radios de donante y
         * el checkbox de solicitante en el formulario.
         *
         * @method setMultiples
         *
         * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <danielking.321@gmail.com>
         * @author Argenis Osorio <aosorio@cenditel.gob.ve>
         */
        setMultiples(donor, applicant) {
            const vm = this;
            if (donor == true) {
                vm.record.donorType = "DN";
            }
            else {
                if (donor === null) {
                    vm.record.donorType = "";
                }
                else {
                    vm.record.donorType = "ND"
                }
            }
            applicant
                ? (vm.record.applicant = "AP")
                : (vm.record.applicant = false);
        },

        /**
         * Método que determina si el usuario es de FUNDAVENE o no.
         *
         * @method getUserRole
         *
         * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <danielking.321@gmail.com>
         */
        getUserRole() {
            const vm = this;
            const role = vm.$parent.user.role;
            if (role == "USR" || role == "MED") {
                return true;
            }
            return false;
        },
        onSelect(items, lastSelectItem) {
            this.items = items;
            this.lastSelectItem = lastSelectItem;
        }
    },
    async created() {
        const vm = this;
        await vm.getEstates();
        /**
         * Obtiene los datos del perfil del usuario.
         *
         * @author Argenis Osorio <aosorio@cenditel.gob.ve>
         */
        await axios.get(`${window.url}` + "/profiles/" + vm.$parent.user.id)
        .then(async response => {
            vm.profile = response.data.profile || "";
            vm.record = vm.profile;
            if (vm.profile.age < 18) {
                vm.wasUnderage = true;
            }
            vm.record.email = vm.$parent.user.email;
            vm.selectedOptions.nationality = vm.profile.nationality;
            vm.selectedOptions.gender = vm.profile.gender;
            vm.selectedOptions.ocupation = (vm.profile.ocupation) ? parseInt(vm.profile.ocupation) : '';
            vm.selectedOptions.academic_level = vm.profile.academic_level;
            vm.selectedOptions.marital_status = vm.profile.marital_status;
            vm.selectedOptions.estate_id = (vm.profile.estate_id) ? parseInt(vm.profile.estate_id) : '';
            await vm.getMunicipalities(parseInt(vm.profile.estate_id));
            vm.selectedOptions.municipality_id = (vm.profile.municipality_id) ? parseInt(vm.profile.municipality_id) : '';
            await vm.getParishes(parseInt(vm.profile.municipality_id));
            vm.selectedOptions.parish_id = (vm.profile.parish_id) ? parseInt(vm.profile.parish_id) : '';
            await vm.setMultiples(vm.profile.donor, vm.profile.applicant);
        })
        .catch(error => {
            console.log(error);
        });
    },

    watch: {
        /**
         * Método que reinicia el campo fecha de nacimiento cuando el año supera los 4 digitos
         * 
         * @param {*} newValue 
         */
         "record.birthdate": function (newValue) {
            const vm = this;
            let date = newValue.split("-");
            let current_year = new Date().getFullYear();
            vm.record.birthdate = date ? parseInt(date[0]) > current_year ? `${current_year}-${date[1]}-${date[2]}` : newValue : "";
        },
    },


    async mounted() {
        const vm = this;
        vm.loadingState(true); // Inicio de  spinner de carga.
        // await vm.getRecords("get-aplicant/" + vm.record.id, "aplicants");
        vm.today = vm.getCurrentDate();
        vm.role = vm.getUserRole();
        // Obtener los registros de las ocupaciones.
        await vm.getRecords(vm.occupations_url, "occupations", true);
        // Obtener los registros de los niveles académicos.
        await vm.getRecords("global-data-type/academic_level", "academic_levels", true);
        /**
         *  Código que restringe los campos telefonicos a digitos unicamente
         */
        $("#phone, #movil").on("input", function(event) {
            event.currentTarget.value = event.currentTarget.value.replace(
                /[^0-9]/g,
                ""
            );
        });

        $("#estate_id").on("change", () => {
            vm.parishes = [];
            vm.record.municipality_id = "";
            vm.record.parish_id = "";
        });
        vm.loadingState(); // Finaliza spinner de carga.
    }
};
</script>
