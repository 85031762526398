<template>
    <!-- transplant-request-form-component -->
    <div id="transplant-request-form-component">
        <go-back title="Regresar" />
        <!-- Formulario de solicitud de trasplante -->
        <div class="card mb-4 shadow-sm">
            <h2
                class="h3 text-center text-primary text-uppercase py-3
                border-bottom border-primary"
            >
                <i class="fa fa-pencil-alt"></i> Actualizar solicitud de
                trasplante procesada
            </h2>
            <!-- Inicio de card-body -->
            <div class="card-body">
                <form-errors :errors="errors"></form-errors>
                <div class="row pb-4">
                    <!-- Fecha de registro -->
                    <div class="col-12 col-lg-2">
                        <label for="date" class="form-label">
                            Fecha de registro
                        </label>
                        <input
                            id="date"
                            class="form-control"
                            type="text"
                            name="date"
                            readonly
                            disabled
                            v-model="record.created_at"
                        />
                    </div>

                    <!-- Serial N° -->
                    <div class="col-12 col-lg-2 mb-3" v-if="record.number">
                        <label for="number" class="form-label">
                            Serial N°
                        </label>
                        <input
                            id="number"
                            class="form-control"
                            type="text"
                            name="number"
                            placeholder="Serial N°"
                            readonly
                            disabled
                            tabindex="-1"
                            v-model="record.number"
                        />
                    </div>
                </div>

                <!-- Datos del usuario que realiza la gestión -->
                <h3 class="h5 text-secondary text-uppercase">
                    Usuario que realiza la gestión de la solicitud
                </h3>
                <hr />
                <div class="row pb-4">
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="agent_name" class="form-label">
                                Nombres
                            </label>
                            <input
                                id="agent_name"
                                type="text"
                                class="form-control"
                                disabled
                                v-model="record.agent_names"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="agent_surnames" class="form-label">
                                Apellidos
                            </label>
                            <input
                                id="agent_surnames"
                                type="text"
                                class="form-control"
                                disabled
                                v-model="record.agent_surnames"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="agent_mail" class="form-label">
                                Correo electrónico
                            </label>
                            <input
                                id="agent_mail"
                                type="email"
                                class="form-control"
                                disabled
                                v-model="record.agent_mail"
                            />
                        </div>
                    </div>
                </div>
                <!-- Final de datos del usuario que realiza la gestión -->

                <!-- Datos personales -->
                <h3 class="h5 text-secondary text-uppercase">
                    Datos personales
                </h3>
                <hr />
                <div class="row pb-4">
                    <!-- Nombres -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="names" class="form-label">
                            Nombres <span class="text-danger">*</span>
                        </label>
                        <input
                            id="names"
                            class="form-control"
                            type="text"
                            name="names"
                            v-model="record.names"
                        />
                    </div>

                    <!-- Apellidos -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="lastname" class="form-label">
                            Apellidos <span class="text-danger">*</span>
                        </label>
                        <input
                            id="lastname"
                            class="form-control"
                            type="text"
                            name="lastname"
                            v-model="record.surnames"
                        />
                    </div>

                    <!-- Número de cédula de identidad -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="idNumber" class="form-label">
                            Número de cédula de identidad
                            <span class="text-danger" v-if="record.age > 17"
                                >*</span
                            >
                        </label>
                        <input
                            id="idNumber"
                            class="form-control"
                            type="text"
                            name="id_number"
                            minlength="7"
                            maxlength="9"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Cédula de identidad"
                            v-model="record.dni"
                        />
                    </div>

                    <!-- Documento de la cédula de identidad -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="idFile" class="form-label">
                            Documento de la cédula de identidad
                            <span
                                class="text-danger"
                                v-if="record.age > 17 || record.dni"
                                >*</span
                            >
                        </label>
                        <input
                            id="idFile"
                            class="form-control"
                            type="file"
                            ref="id_file"
                            name="id_file"
                            accept=".pdf, .docx, .doc, .odt"
                            @change="onFileUpload"
                        />
                        <div class="form-text mb-3">
                            Formato permitido:
                            <label class="fw-bold">.pdf .docx .doc .odt</label>
                            Tamaño máximo:
                            <label class="fw-bold">5MB</label>
                        </div>
                        <button
                            v-if="url_dni"
                            class="btn btn-outline-primary-dark"
                            title="Descarga el archivo previamente subido"
                            @click="
                                downloadFile(
                                    url_dni,
                                    'Documento de la cédula de identidad'
                                )
                            "
                        >
                            <i class="fa fa-download"></i>
                            Descargar
                        </button>
                    </div>

                    <!-- Documento de la foto actualizada -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="updated_photo_file" class="form-label">
                            Documento de la foto actualizada
                            <span class="text-danger">*</span>
                        </label>
                        <input
                            id="updated_photo_file"
                            class="form-control"
                            type="file"
                            ref="updated_photo_file"
                            name="updated_photo_file"
                            accept=".pdf, .docx, .doc, .odt"
                            @change="onFileUpload"
                        />
                        <div class="form-text mb-3">
                            Formato permitido:
                            <label class="fw-bold">.pdf .docx .doc .odt</label>
                            Tamaño máximo:
                            <label class="fw-bold">5MB</label>
                        </div>
                        <button
                            v-if="url_updated_photo"
                            class="btn btn-outline-primary-dark"
                            title="Descarga el archivo previamente subido"
                            @click="
                                downloadFile(
                                    url_updated_photo,
                                    'Documento de la foto actualizada'
                                )
                            "
                        >
                            <i class="fa fa-download"></i>
                            Descargar
                        </button>
                    </div>

                    <!-- Nacionalidad -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="nationality" class="form-label">
                            Nacionalidad <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="record.nationality"
                            :options="nationalityOptions"
                            :reduce="nationality => nationality.id"
                            label="text"
                            id="nationality"
                            :tabindex="6"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>

                    <!-- Género -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="gender" class="form-label">
                            Género <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="record.gender"
                            :options="genderOptions"
                            :selectable="option => option.id !== ''"
                            :reduce="gender => gender.id"
                            label="text"
                            id="gender"
                            :tabindex="7"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>

                    <!-- Ocupación -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="ocupation" class="form-label">
                            Ocupación <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="record.ocupation"
                            :options="occupations"
                            :selectable="option => option.id !== ''"
                            :reduce="ocupation => ocupation.id"
                            label="name"
                            id="ocupation"
                            :tabindex="8"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>

                    <!-- Estado civil -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="marital_status" class="form-label">
                            Estado civil <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="record.marital_status"
                            :options="maritalStatusOptions"
                            :selectable="option => option.id !== ''"
                            :reduce="ms => ms.id"
                            label="text"
                            id="marital_status"
                            :tabindex="9"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>

                    <!-- Nivel académico -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="academic_levels" class="form-label">
                            Nivel académico <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="record.academic_level"
                            :options="academic_levels"
                            :selectable="option => option.id !== ''"
                            :reduce="ms => ms.name"
                            label="name"
                            id="academic_level"
                            :tabindex="10"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                </div>
                <!-- Final de datos personales -->

                <!-- Edad -->
                <h3 class="h5 text-secondary text-uppercase">Edad</h3>
                <hr />
                <div class="row pb-4">
                    <!-- Fecha de nacimiento -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="birthdate" class="form-label">
                            Fecha de nacimiento
                            <span class="text-danger">*</span>
                        </label>
                        <input
                            id="birthdate"
                            tabindex="-1"
                            class="form-control"
                            type="date"
                            name="birthdate"
                            v-model="record.birthdate"
                            :max="today"
                            @change="setAge"
                        />
                    </div>

                    <!-- Edad -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="age" class="form-label">Edad</label>
                        <input
                            id="age"
                            class="form-control"
                            type="number"
                            name="age"
                            min="1"
                            max="3"
                            placeholder="0"
                            readonly
                            tabindex="-1"
                            v-model="record.age"
                        />
                    </div>

                    <!-- Lugar de nacimiento -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="placeOfBirth" class="form-label">
                            Lugar de nacimiento
                            <span class="text-danger">*</span>
                        </label>
                        <input
                            id="placeOfBirth"
                            class="form-control"
                            type="text"
                            name="place_of_birth"
                            placeholder="Lugar de nacimiento"
                            v-model="record.place_of_birth"
                        />
                    </div>
                </div>
                <!-- Final de edad -->

                <!-- Dirección -->
                <h3 class="h5 text-secondary text-uppercase">Dirección</h3>
                <hr />
                <div class="row pb-4">
                    <!-- Estado -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="estateId" class="form-label">
                            Estado <span class="text-danger">*</span>
                        </label>
                        <v-select
                            id="estateId"
                            label="text"
                            placeholder="-- Seleccione --"
                            :options="estates"
                            :reduce="estate => estate.id"
                            :selectable="option => option.id !== ''"
                            :tabindex="6"
                            v-model="record.estate_id"
                            @input="
                                record.municipality_id = '';
                                record.parish_id = '';
                                getMunicipalities(record.estate_id);
                            "
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>

                    <!-- Municipio -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="municipalityId" class="form-label">
                            Municipio <span class="text-danger">*</span>
                        </label>
                        <v-select
                            id="municipality_id"
                            label="text"
                            placeholder="-- Seleccione --"
                            :options="municipalities"
                            :reduce="mun => mun.id"
                            :selectable="option => option.id !== ''"
                            :tabindex="14"
                            @input="
                                record.parish_id = '';
                                getParishes(record.municipality_id);
                            "
                            v-model="record.municipality_id"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>

                    <!-- Parroquia -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="parishId" class="form-label">
                            Parroquia <span class="text-danger">*</span>
                        </label>
                        <v-select
                            id="parish_id"
                            label="text"
                            placeholder="-- Seleccione --"
                            :options="parishes"
                            :reduce="par => par.id"
                            :selectable="option => option.id !== ''"
                            :tabindex="15"
                            v-model="record.parish_id"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>

                    <!-- Dirección -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="adress" class="form-label">
                            Dirección <span class="text-danger">*</span>
                        </label>
                        <textarea
                            id="adress"
                            class="form-control"
                            name="adress"
                            cols="20"
                            placeholder="Dirección"
                            rows="4"
                            v-model="record.adress"
                        ></textarea>
                    </div>
                </div>
                <!-- Final de dirección -->

                <!-- Contacto -->
                <h3 class="h5 text-secondary text-uppercase">Contacto</h3>
                <hr />
                <div class="row pb-4">
                    <!-- Teléfono móvil -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="patientCellPhone" class="form-label">
                            Teléfono móvil <span class="text-danger">*</span>
                        </label>
                        <input
                            id="patientCellPhone"
                            class="form-control"
                            type="text"
                            name="patient_cellphone"
                            maxlength="11"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Teléfono móvil"
                            required
                            v-model="record.patient_cellphone"
                        />
                    </div>

                    <!-- Teléfono local -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="phone" class="form-label">
                            Teléfono local <span class="text-danger">*</span>
                        </label>
                        <input
                            id="phone"
                            class="form-control"
                            type="text"
                            name="phone"
                            maxlength="11"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Teléfono local"
                            required
                            v-model="record.phone"
                        />
                    </div>

                    <!-- Correo electrónico -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="mail" class="form-label">
                            Correo electrónico
                            <span class="text-danger">*</span>
                        </label>
                        <input
                            id="mail"
                            class="form-control"
                            type="email"
                            name="mail"
                            v-model="record.email"
                        />
                    </div>
                </div>
                <!-- Final de contacto -->

                <!-- Datos del trasplante -->
                <h3 class="h5 text-secondary text-uppercase">
                    Datos del trasplante
                </h3>
                <hr />
                <div class="row pb-4">
                    <!-- Tipo de trasplante -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="transplantId" class="form-label">
                            Tipo de trasplante
                            <span class="text-danger">*</span>
                        </label>
                        <v-select
                            id="transplantId"
                            label="name"
                            placeholder="-- Seleccione --"
                            :options="transplants"
                            :reduce="transplant => transplant.id"
                            :selectable="option => option.id !== ''"
                            :tabindex="6"
                            v-model="record.transplant_id"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>

                    <!-- ¿Posee Centro de trasplante? -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="donor" class="form-label">
                            ¿Posee centro de trasplante?
                        </label>
                        <select
                            id="donar"
                            class="form-select"
                            tabindex="9"
                            v-model="record.have_healthcare_center"
                        >
                            <option value="false">No</option>
                            <option value="true">Si</option>
                        </select>
                    </div>

                    <!-- Grupo sanguíneo -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="patient_blood_type" class="form-label">
                            Grupo sanguíneo <span class="text-danger">*</span>
                        </label>
                        <v-select
                            id="patient_blood_type"
                            label="name"
                            placeholder="-- Seleccione --"
                            :options="blood_types"
                            :reduce="blood_type => blood_type.id"
                            :selectable="option => option.id !== ''"
                            :tabindex="6"
                            v-model="record.patient_blood_type"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>

                    <!-- Factor RH -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="rhFactor" class="form-label">
                            Factor RH <span class="text-danger">*</span>
                        </label>
                        <select
                            id="nationality"
                            class="form-select"
                            tabindex="5"
                            v-model="record.rh_factor"
                        >
                            <option value="">-- Seleccione --</option>
                            <option value="P">Positivo</option>
                            <option value="N">Negativo</option>
                        </select>
                    </div>

                    <!-- Centro de trasplante -->
                    <div
                        class="col-12 col-lg-4 mb-3"
                        v-if="record.have_healthcare_center == 'true'"
                    >
                        <label for="healthcareCenter" class="form-label">
                            Centro de trasplante
                            <span class="text-danger">*</span>
                        </label>
                        <v-select
                            id="healthcareCenter"
                            v-model="record.healthcare_center"
                            label="name"
                            placeholder="-- Seleccione --"
                            :options="healthcare_centers"
                            :reduce="healthcare_center => healthcare_center.id"
                            :selectable="option => option.id !== ''"
                            :tabindex="6"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                </div>

                <div class="row">
                    <!-- Acta de nacimiento -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="born_file" class="form-label">
                            Acta de nacimiento
                            <span class="text-danger"  v-if="record.transplant_id != 30 || (record.transplant_id == 30 && record.age < 18)" >*</span>
                        </label>
                        <input
                            id="born_file"
                            class="form-control"
                            type="file"
                            name="born_file"
                            ref="born_file"
                            accept=".pdf, .docx, .doc, .odt"
                            required
                            @change="onFileUpload"
                        />
                        <div class="form-text mb-3">
                            Formato permitido:
                            <label class="fw-bold">.pdf .docx .doc .odt</label>
                            Tamaño máximo:
                            <label class="fw-bold">5MB</label>
                        </div>
                        <button
                            v-if="url_born_file"
                            class="btn btn-outline-primary-dark"
                            title="Descarga el archivo previamente subido"
                            @click="
                                downloadFile(
                                    url_born_file,
                                    'Acta de nacimiento'
                                )
                            "
                        >
                            <i class="fa fa-download"></i>
                            Descargar
                        </button>
                    </div>

                    <!-- Informe médico actualizado -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="report_file" class="form-label">
                            Informe médico actualizado
                            <span class="text-danger">*</span>
                        </label>
                        <input
                            id="report_file"
                            class="form-control"
                            type="file"
                            name="report_file"
                            ref="report_file"
                            accept=".pdf, .docx, .doc, .odt"
                            required
                            @change="onFileUpload"
                        />
                        <div class="form-text mb-3">
                            Formato permitido:
                            <label class="fw-bold">.pdf .docx .doc .odt</label>
                            Tamaño máximo:
                            <label class="fw-bold">5MB</label>
                        </div>
                        <button
                            v-if="url_medic_report_file"
                            class="btn btn-outline-primary-dark"
                            title="Descarga el archivo previamente subido"
                            @click="
                                downloadFile(
                                    url_medic_report_file,
                                    'Informe médico actualizado'
                                )
                            "
                        >
                            <i class="fa fa-download"></i>
                            Descargar
                        </button>
                    </div>

                    <!-- Documento del tipo de sangre -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="bloodType" class="form-label">
                            Documento del tipo de sangre
                            <span class="text-danger">*</span>
                        </label>
                        <input
                            id="bloodType"
                            class="form-control"
                            type="file"
                            name="blood_type"
                            ref="blood_type"
                            accept=".pdf, .docx, .doc, .odt"
                            @change="onFileUpload"
                        />
                        <div class="form-text mb-3">
                            Formato permitido:
                            <label class="fw-bold">.pdf .docx .doc .odt</label>
                            Tamaño máximo:
                            <label class="fw-bold">5MB</label>
                        </div>
                        <button
                            v-if="url_blood_type_file"
                            class="btn btn-outline-primary-dark"
                            title="Descarga el archivo previamente subido"
                            @click="
                                downloadFile(
                                    url_blood_type_file,
                                    'Documento del tipo de sangre'
                                )
                            "
                        >
                            <i class="fa fa-download"></i>
                            Descargar
                        </button>
                    </div>
                </div>

                <div class="row pb-4">
                    <!-- Diagnóstico -->
                    <div class="row pb-2">
                        <div class="col-12 col-lg-12 mb-3">
                            <label for="diagnosis" class="form-label">
                                Diagnóstico <span class="text-danger">*</span>
                            </label>
                            <textarea
                                id="diagnosis"
                                class="form-control"
                                name="diagnosis"
                                cols="20"
                                rows="5"
                                v-model="record.diagnosis"
                            ></textarea>
                        </div>
                    </div>

                    <!-- ¿Posee posible donante vivo? -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="donor" class="form-label">
                            ¿Posee posible donante vivo?
                        </label>
                        <select
                            id="donar"
                            class="form-select"
                            tabindex="9"
                            v-model="record.donor"
                            @input="clearLivingDonorData"
                        >
                            <option value="false">No</option>
                            <option value="true">Si</option>
                        </select>
                    </div>

                    <!-- ¿Posee Padres? -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="dads" class="form-label">
                            ¿Posee Padres?
                        </label>
                        <select
                            id="dads"
                            class="form-select"
                            tabindex="10"
                            v-model="record.dads"
                        >
                            <option value="false">No</option>
                            <option value="true">Si</option>
                        </select>
                    </div>
                </div>

                <!-- Datos del donante vivo -->
                <div v-if="record.donor == 'true'">
                    <h3 class="h5 text-secondary text-uppercase">
                        Datos del donante vivo
                        <i
                            class="fa fa-plus-circle cursor-pointer"
                            @click="addDonor" v-if="record.donor_arrays.length != 2"
                        ></i>
                    </h3>
                    <hr />
                </div>
                <div v-if="record.donor == 'true'">
                    <div
                        v-for="(donor_array, index) in record.donor_arrays"
                        :key="index"
                    >
                        <div v-if="index > '0'" :key="index + keys">
                            <h3 class="h5 text-secondary text-uppercase">
                                Datos del donante vivo {{ index + 1 }}
                                <i
                                    class="fa fa-plus-circle cursor-pointer"
                                    @click="addDonor" v-if="record.donor_arrays.length != 2"
                                ></i>
                                <i
                                    v-if="index != '0'"
                                    class="fa fa-minus-circle cursor-pointer"
                                    style="color: red"
                                    @click="
                                        removeRow(index, record.donor_arrays)
                                    "
                                ></i>
                                <i
                                    class="fas fa-arrow-down"
                                    v-if="!record.donor_arrays[index].isExpand"
                                    @click="mostrar(index, record.donor_arrays)"
                                ></i>
                                <i
                                    class="fas fa-arrow-up"
                                    v-if="record.donor_arrays[index].isExpand"
                                    @click="ocultar(index, record.donor_arrays)"
                                ></i>
                            </h3>
                            <div class="icon"></div>
                            <hr />
                        </div>
                        <div
                            class="row justify-content-left pb-4"
                            v-if="
                                record.donor_arrays[index].isExpand ||
                                    index == '0'
                            "
                        >
                            <!-- Nombres del donante vivo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="donarNames" class="form-label">
                                    Nombres <span class="text-danger">*</span>
                                </label>
                                <input
                                    id="donorNames"
                                    class="form-control"
                                    name="donor_names"
                                    v-model="donor_array.donor_names"
                                    type="text"
                                    placeholder="Nombres"
                                />
                            </div>
                            <!-- Apellidos del donante vivo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="donorLastname" class="form-label">
                                    Apellidos <span class="text-danger">*</span>
                                </label>
                                <input
                                    id="donorLastname"
                                    class="form-control"
                                    name="donor_lastname"
                                    v-model="donor_array.donor_lastname"
                                    type="text"
                                    placeholder="Apellidos"
                                />
                            </div>
                            <!-- Fecha de nacimiento del donante vivo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="donorBirthday" class="form-label">
                                    Fecha de nacimiento
                                    <span class="text-danger">*</span>
                                </label>
                                <input
                                    tabindex="-1"
                                    :id="'donorBirthday_' + index"
                                    class="form-control"
                                    name="donor_birthday"
                                    v-model="donor_array.donor_birthday"
                                    type="date"
                                    :max="today"
                                    @change="setBirthdate(index); setAgeDonor(index)"
                                />
                            </div>
                            <!-- Edad del donante vivo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="age" class="form-label">
                                    Edad
                                </label>
                                <input
                                    :id="'donor_age_' + index"
                                    class="form-control"
                                    type="number"
                                    name="donor_age"
                                    min="1"
                                    max="3"
                                    placeholder="0"
                                    readonly
                                    tabindex="-1"
                                    v-model="donor_array.donor_age"
                                />
                                <div id="ageHelp" class="form-text">
                                    Este campo se autorrellena al ingresar la
                                    <label class="fw-bold" for="birthdate">
                                        Fecha de nacimiento </label
                                    >.
                                </div>
                            </div>
                            <!-- Lugar de nacimiento del donante vivo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="placeOfBirth" class="form-label">
                                    Lugar de nacimiento
                                    <span class="text-danger">*</span>
                                </label>
                                <input
                                    id="placeOfBirth"
                                    class="form-control"
                                    type="text"
                                    name="place_of_birth"
                                    placeholder="Lugar de nacimiento"
                                    v-model="donor_array.donor_place_of_birth"
                                />
                            </div>
                            <!-- Nacionalidad del donante vivo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="nationality" class="form-label">
                                    Nacionalidad
                                    <span class="text-danger">*</span>
                                </label>
                                <v-select
                                    v-model="donor_array.donor_nationality"
                                    :options="nationalityOptions"
                                    :reduce="nationality => nationality.id"
                                    label="text"
                                    id="nationality"
                                    :tabindex="6"
                                    :selectable="option => option.id !== ''"
                                    placeholder="-- Seleccione --"
                                >
                                    <slot name="no-options"
                                        >Sin registros.</slot
                                    >
                                </v-select>
                            </div>
                            <!-- Género del donante vivo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="gender" class="form-label">
                                    Género <span class="text-danger">*</span>
                                </label>
                                <v-select
                                    v-model="donor_array.donor_gender"
                                    :options="genderOptions"
                                    :selectable="option => option.id !== ''"
                                    :reduce="gender => gender.id"
                                    label="text"
                                    id="gender"
                                    :tabindex="7"
                                    placeholder="-- Seleccione --"
                                >
                                    <slot name="no-options"
                                        >Sin registros.</slot
                                    >
                                </v-select>
                            </div>
                            <!-- Estado civil del donante vivo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="marital_status" class="form-label">
                                    Estado civil
                                    <span class="text-danger">*</span>
                                </label>
                                <v-select
                                    v-model="donor_array.donor_marital_status"
                                    :options="maritalStatusOptions"
                                    :selectable="option => option.id !== ''"
                                    :reduce="ms => ms.id"
                                    label="text"
                                    id="marital_status"
                                    :tabindex="9"
                                    placeholder="-- Seleccione --"
                                >
                                    <slot name="no-options"
                                        >Sin registros.</slot
                                    >
                                </v-select>
                            </div>
                            <!-- Ocupación del donante vivo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="ocupation" class="form-label">
                                    Ocupación <span class="text-danger">*</span>
                                </label>
                                <v-select
                                    v-model="donor_array.donor_ocupation"
                                    :options="occupations"
                                    :selectable="option => option.id !== ''"
                                    :reduce="ocupation => ocupation.id"
                                    label="name"
                                    id="ocupation"
                                    :tabindex="8"
                                    placeholder="-- Seleccione --"
                                >
                                    <slot name="no-options"
                                        >Sin registros.</slot
                                    >
                                </v-select>
                            </div>
                            <!-- Nivel académico del donante vivo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="academic_levels" class="form-label">
                                    Nivel académico
                                    <span class="text-danger">*</span>
                                </label>
                                <v-select
                                    v-model="donor_array.donor_academic_level"
                                    :options="academic_levels"
                                    :selectable="option => option.id !== ''"
                                    :reduce="ms => ms.name"
                                    label="name"
                                    id="academic_level"
                                    :tabindex="10"
                                    placeholder="-- Seleccione --"
                                >
                                    <slot name="no-options"
                                        >Sin registros.</slot
                                    >
                                </v-select>
                            </div>
                            <!-- Grupo sanguíneo del donante vivo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="donor_blood" class="form-label">
                                    Grupo sanguíneo
                                    <span class="text-danger">*</span>
                                </label>
                                <v-select
                                    v-model="donor_array.donor_blood"
                                    :options="blood_types"
                                    :reduce="blood_type => blood_type.id"
                                    label="name"
                                    id="donor_blood"
                                    :tabindex="6"
                                    :selectable="option => option.id !== ''"
                                    placeholder="-- Seleccione --"
                                >
                                    <slot name="no-options"
                                        >Sin registros.</slot
                                    >
                                </v-select>
                            </div>
                            <!-- Factor RH del donante vivo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="rhFactor" class="form-label">
                                    Factor RH <span class="text-danger">*</span>
                                </label>
                                <select
                                    id="nationality"
                                    class="form-select"
                                    tabindex="5"
                                    v-model="donor_array.donor_rh_factor"
                                >
                                    <option value="">-- Seleccione --</option>
                                    <option value="P">Positivo</option>
                                    <option value="N">Negativo</option>
                                </select>
                            </div>
                            <!-- Número de cédula de identidad del donante vivo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="idNumber" class="form-label">
                                    Número de cédula de identidad
                                    <span class="text-danger">*</span>
                                </label>
                                <input
                                    id="donor_idNumber"
                                    class="form-control"
                                    type="text"
                                    name="donor_id_number"
                                    minlength="7"
                                    maxlength="9"
                                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                    placeholder="Cédula de identidad"
                                    v-model="donor_array.donor_dni"
                                />
                            </div>
                            <!-- Documento de la cédula de identidad del donante vivo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="donorIdFile" class="form-label">
                                    Documento de la cédula de identidad del donante
                                    <span class="text-danger">*</span>
                                </label>
                                <input
                                    id="donor_id"
                                    class="form-control"
                                    v-model="donor_array.donor_id"
                                    name="donor_id"
                                    type="hidden"
                                />
                                <input
                                    id="donorIdFile"
                                    class="form-control"
                                    type="file"
                                    name="donor_id_file"
                                    ref="donor_id_file"
                                    accept=".pdf, .docx, .doc, .odt"
                                    @change="
                                        onFileUploadDonor(
                                            $event,
                                            index,
                                            donor_array.donor_id,
                                            'donor_id_file'
                                        )
                                    "
                                />
                                <div class="form-text mb-3">
                                    Formato permitido:
                                    <label class="fw-bold"
                                        >.pdf .docx .doc .odt</label
                                    >
                                    Tamaño máximo:
                                    <label class="fw-bold">5MB</label>
                                </div>
                                <div v-if="donor_array.url_donor_dni_file">
                                    <button
                                        id="donorDniFile"
                                        class="btn btn-outline-primary-dark"
                                        title="Descarga el archivo previamente subido"
                                        @click="
                                            downloadFile(
                                                donor_array.url_donor_dni_file,
                                                'Documento de la cédula de identidad del donante'
                                            )
                                        "
                                    >
                                        <i class="fa fa-download"></i>
                                        Descargar
                                    </button>
                                </div>
                            </div>
                            <!-- Documento del tipo de sangre del donante vivo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="donorBloodType" class="form-label">
                                    Documento del tipo de sangre
                                    <span class="text-danger">*</span>
                                </label>
                                <input
                                    id="donorBloodType"
                                    class="form-control"
                                    type="file"
                                    name="donor_blood_type_file"
                                    ref="donor_blood_type_file"
                                    accept=".pdf, .docx, .doc, .odt"
                                    @change="
                                        onFileUploadDonor(
                                            $event,
                                            index,
                                            donor_array.donor_id,
                                            'donor_blood_type_file'
                                        )
                                    "
                                />
                                <div class="form-text mb-3">
                                    Formato permitido:
                                    <label class="fw-bold"
                                        >.pdf .docx .doc .odt</label
                                    >
                                    Tamaño máximo:
                                    <label class="fw-bold">5MB</label>
                                </div>
                                <div
                                    v-if="donor_array.url_donor_blood_type_file"
                                >
                                    <button
                                        id="DonorBloodTypeFile"
                                        class="btn btn-outline-primary-dark"
                                        title="Descarga el archivo previamente subido"
                                        @click="
                                            downloadFile(
                                                donor_array.url_donor_blood_type_file,
                                                'Documento del tipo de sangre del donante'
                                            )
                                        "
                                    >
                                        <i class="fa fa-download"></i>
                                        Descargar
                                    </button>
                                </div>
                            </div>
                            <!-- Teléfono móvil del donante vivo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="doctorCellPhone" class="form-label">
                                    Teléfono móvil
                                    <span class="text-danger">*</span>
                                </label>
                                <input
                                    id="doctorCellPhone"
                                    class="form-control"
                                    name="doctor_cellphone"
                                    type="text"
                                    v-model="donor_array.donor_cellphone"
                                    placeholder="Teléfono móvil"
                                    maxlength="11"
                                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                />
                            </div>
                            <!-- Teléfono local del donante vivo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="doctorPhone" class="form-label">
                                    Teléfono local
                                    <span class="text-danger">*</span>
                                </label>
                                <input
                                    id="doctorPhone"
                                    class="form-control"
                                    name="doctor_phone"
                                    type="text"
                                    v-model="donor_array.donor_phone"
                                    placeholder="Teléfono local"
                                    maxlength="11"
                                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                />
                            </div>
                            <!-- Correo electrónico del donante vivo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="dni" class="form-label">
                                    Correo electrónico
                                    <span class="text-danger">*</span>
                                </label>
                                <input
                                    id="email"
                                    class="form-control"
                                    type="email"
                                    placeholder="Correo electrónico"
                                    tabindex="5"
                                    v-model="donor_array.donor_email"
                                />
                            </div>
                            <!-- Parentesco del donante vivo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="relationship" class="form-label">
                                    Parentesco
                                    <span class="text-danger">*</span>
                                </label>
                                <v-select
                                    v-model="donor_array.donor_relationship"
                                    :options="relationships"
                                    :reduce="relationship => relationship.id"
                                    label="name"
                                    id="relationship"
                                    :tabindex="6"
                                    :selectable="option => option.id !== ''"
                                    placeholder="-- Seleccione --"
                                >
                                    <slot name="no-options"
                                        >Sin registros.</slot
                                    >
                                </v-select>
                                <input
                                    id="relationship_name"
                                    class="form-control"
                                    v-model="donor_array.relationship_name"
                                    name="relationship_name"
                                    type="hidden"
                                />
                            </div>
                            <div
                                v-if="
                                    getRelationship(index) === 'family' &&
                                        donor_array.url_born_file
                                "
                                class="col-12 col-sm-2 mb-3 mt-lg-4 pt-lg-2"
                            ></div>
                            <!-- Acta de nacimiento del donante vivo -->
                            <div
                                class="col-12 col-lg-4 mb-3"
                                v-if="getRelationship(index) === 'family'"
                            >
                                <label for="donorFile" class="form-label">
                                    Acta de nacimiento del donante
                                </label>
                                <input
                                    class="form-control"
                                    type="file"
                                    accept=".pdf, .docx, .doc, .odt"
                                    :id="'donor_file' + index"
                                    :name="'donor_file' + index"
                                    @change="
                                        onFileUploadDonor(
                                            $event,
                                            index,
                                            donor_array.donor_id,
                                            'donor_file'
                                        )
                                    "
                                />
                                <div class="form-text mb-3">
                                    Formato permitido:
                                    <label class="fw-bold"
                                        >.pdf .docx .doc .odt</label
                                    >
                                    Tamaño máximo:
                                    <label class="fw-bold">5MB</label>
                                </div>
                            </div>
                            <div
                                v-if="
                                    getRelationship(index) === 'family' &&
                                    donor_array.url_born_file
                                "
                                class="col-12 col-sm-2 mb-3 mt-lg-4 pt-lg-2"
                            >
                                <button
                                    class="btn btn-outline-primary-dark"
                                    title="Descarga el archivo previamente subido"
                                    @click="
                                        downloadFile(
                                            donor_array.url_born_file,
                                            'Acta de nacimiento del donante'
                                        )
                                    "
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>
                            <!-- Acta de concubinato del donante vivo -->
                            <div
                                class="col-12 col-lg-4 mb-3"
                                v-if="getRelationship(index) === 'concubinage'"
                            >
                                <label for="concubinageFile" class="form-label">
                                    Acta de concubinato
                                    <span class="text-danger">*</span>
                                </label>
                                <input
                                    class="form-control"
                                    type="file"
                                    accept=".pdf, .docx, .doc, .odt"
                                    :id="'concubinage_file' + index"
                                    :name="'concubinage_file' + index"
                                    @change="
                                        onFileUploadDonor(
                                            $event,
                                            index,
                                            donor_array.donor_id,
                                            'concubinage_file'
                                        )
                                    "
                                />
                                <div class="form-text mb-3">
                                    Formato permitido:
                                    <label class="fw-bold"
                                        >.pdf .docx .doc .odt</label
                                    >
                                    Tamaño máximo:
                                    <label class="fw-bold">5MB</label>
                                </div>
                            </div>
                            <div
                                v-if="
                                    getRelationship(index) === 'concubinage' &&
                                        donor_array.url_concubinage_file
                                "
                                class="col-12 col-sm-2 mb-3 mt-lg-4 pt-lg-2"
                            >
                                <button
                                    class="btn btn-outline-primary-dark"
                                    title="Descarga el archivo previamente subido"
                                    @click="
                                        downloadFile(
                                            donor_array.url_concubinage_file,
                                            'Acta de concubinato del donante'
                                        )
                                    "
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>
                            <!-- Acta de matrimonio del donante vivo -->
                            <div
                                class="col-12 col-lg-4 mb-3"
                                v-if="getRelationship(index) === 'marriage'"
                            >
                                <label for="marriageFile" class="form-label">
                                    Acta de matrimonio
                                    <span class="text-danger">*</span>
                                </label>
                                <input
                                    id="marriageFile"
                                    class="form-control"
                                    type="file"
                                    name="marriage_file"
                                    accept=".pdf, .docx, .doc, .odt"
                                    required
                                    @change="
                                        onFileUploadDonor(
                                            $event,
                                            index,
                                            donor_array.donor_id,
                                            'marriage_file'
                                        )
                                    "
                                />
                                <div class="form-text mb-3">
                                    Formato permitido:
                                    <label class="fw-bold"
                                        >.pdf .docx .doc .odt</label
                                    >
                                    Tamaño máximo:
                                    <label class="fw-bold">5MB</label>
                                </div>
                            </div>
                            <div
                                v-if="
                                    getRelationship(index) === 'marriage' &&
                                        donor_array.url_marriage_file
                                "
                                class="col-12 col-sm-2 mb-3 mt-lg-4 pt-lg-2"
                            >
                                <button
                                    class="btn btn-outline-primary-dark"
                                    title="Descarga el archivo previamente subido"
                                    @click="
                                        downloadFile(
                                            donor_array.url_marriage_file,
                                            'Acta de matrimonio del donante'
                                        )
                                    "
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>
                            <!-- Tiempo en unión estable (años) del donante vivo -->
                            <div
                                class="col-12 col-lg-4 mb-3"
                                v-if="getRelationship(index) === 'concubinage'"
                            >
                                <label for="age" class="form-label">
                                    Tiempo en unión estable (años)
                                    <span class="text-danger">*</span>
                                </label>
                                <input
                                    class="form-control"
                                    name="time"
                                    v-model="donor_array.time"
                                    type="number"
                                    aria-describedby=""
                                    min="1"
                                    max="100"
                                    placeholder="0"
                                    tabindex="-1"
                                />
                            </div>
                            <!-- Certificado de adopción del donante vivo -->
                            <div
                                class="col-12 col-lg-4 mb-3"
                                v-if="getRelationship(index) === 'adoption'"
                            >
                                <label for="adoptionFile" class="form-label">
                                    Certificado de adopción
                                    <span class="text-danger">*</span>
                                </label>
                                <input
                                    id="adoptionFile"
                                    class="form-control"
                                    type="file"
                                    name="adoption_file"
                                    accept=".pdf, .docx, .doc, .odt"
                                    @change="
                                        onFileUploadDonor(
                                            $event,
                                            index,
                                            donor_array.donor_id,
                                            'adoption_file'
                                        )
                                    "
                                />
                                <div class="form-text mb-3">
                                    Formato permitido:
                                    <label class="fw-bold"
                                        >.pdf .docx .doc .odt</label
                                    >
                                    Tamaño máximo:
                                    <label class="fw-bold">5MB</label>
                                </div>
                            </div>
                            <div
                                v-if="
                                    getRelationship(index) === 'adoption' &&
                                        donor_array.url_adoption_file
                                "
                                class="col-12 col-sm-2 mb-3 mt-lg-4 pt-lg-2"
                            >
                                <button
                                    class="btn btn-outline-primary-dark"
                                    title="Descarga el archivo previamente subido"
                                    @click="
                                        downloadFile(
                                            donor_array.url_adoption_file,
                                            'Certificado de adopción del donante'
                                        )
                                    "
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Final de datos del donante vivo -->

                <!-- Datos de los padres -->
                <div v-if="record.dads == 'true'">
                    <!-- Datos del Padre -->
                    <h3 class="h5 text-secondary text-uppercase">
                        Datos del Padre
                    </h3>
                    <hr />
                    <div class="row pb-4">
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="dadNames" class="form-label">
                                Nombres
                            </label>
                            <input
                                id="dadNames"
                                class="form-control"
                                name="dad_names"
                                v-model="record.dad_names"
                                type="text"
                                placeholder="Nombres"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="dadLastname" class="form-label">
                                Apellidos
                            </label>
                            <input
                                id="dadLastname"
                                class="form-control"
                                name="dad_lastname"
                                v-model="record.dad_lastname"
                                type="text"
                                placeholder="Apellidos"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="dadIdNumber" class="form-label">
                                Número de cédula de identidad
                            </label>
                            <input
                                id="dadIdNumber"
                                class="form-control"
                                name="dad_dni_number"
                                type="text"
                                minlength="7"
                                maxlength="9"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                v-model="record.dad_dni_number"
                                placeholder="Cédula de identidad"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="dadIdFile" class="form-label">
                                Documento de la cédula de identidad
                            </label>
                            <input
                                id="dadIdFile"
                                class="form-control"
                                type="file"
                                name="dad_id_file"
                                ref="dad_id_file"
                                accept=".pdf, .docx, .doc, .odt"
                                @change="onFileUpload"
                            />
                            <div class="form-text mb-3">
                                Formato permitido:
                                <label class="fw-bold"
                                    >.pdf .docx .doc .odt</label
                                >
                                Tamaño máximo:
                                <label class="fw-bold">5MB</label>
                            </div>
                            <button
                                v-if="url_dad_dni_file"
                                class="btn btn-outline-primary-dark"
                                title="Descarga el archivo previamente subido"
                                @click="
                                    downloadFile(
                                        url_dad_dni_file,
                                        'Documento de la cédula de identidad del padre'
                                    )
                                "
                            >
                                <i class="fa fa-download"></i>
                                Descargar
                            </button>
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="dadCellPhone" class="form-label">
                                Teléfono móvil
                            </label>
                            <input
                                id="dadCellPhone"
                                class="form-control"
                                name="dad_cellphone"
                                type="text"
                                maxlength="11"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                placeholder="Teléfono móvil"
                                v-model="record.dad_cellphone"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="dadPhone" class="form-label"
                                >Teléfono local</label
                            >
                            <input
                                id="dadPhone"
                                class="form-control"
                                name="dad_phone"
                                type="text"
                                maxlength="11"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                placeholder="Teléfono"
                                v-model="record.dad_phone"
                            />
                        </div>
                    </div>
                    <!-- Final de datos del Padre -->

                    <!-- Datos de la Madre -->
                    <h3 class="h5 text-secondary text-uppercase">
                        Datos de la Madre
                    </h3>
                    <hr />
                    <div class="row pb-4">
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="monNames" class="form-label">
                                Nombres
                            </label>
                            <input
                                id="monNames"
                                class="form-control"
                                name="mon_names"
                                type="text"
                                placeholder="Nombres"
                                v-model="record.mon_names"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="monLastname" class="form-label">
                                Apellidos
                            </label>
                            <input
                                id="monLastname"
                                class="form-control"
                                name="mon_lastname"
                                type="text"
                                placeholder="Apellidos"
                                v-model="record.mon_lastname"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="monIdNumber" class="form-label">
                                Número de cédula de identidad
                            </label>
                            <input
                                id="monIdNumber"
                                class="form-control"
                                name="mon_dni_number"
                                type="text"
                                minlength="7"
                                maxlength="9"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                v-model="record.mon_dni_number"
                                placeholder="Cédula de identidad"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="monIdFile" class="form-label">
                                Documento de la cédula de identidad
                            </label>
                            <input
                                id="monIdFile"
                                class="form-control"
                                type="file"
                                name="mon_id_file"
                                ref="mon_id_file"
                                accept=".pdf, .docx, .doc, .odt"
                                @change="onFileUpload"
                            />
                            <div class="form-text mb-3">
                                Formato permitido:
                                <label class="fw-bold"
                                    >.pdf .docx .doc .odt</label
                                >
                                Tamaño máximo:
                                <label class="fw-bold">5MB</label>
                            </div>
                            <button
                                v-if="url_mon_dni_file"
                                class="btn btn-outline-primary-dark"
                                title="Descarga el archivo previamente subido"
                                @click="
                                    downloadFile(
                                        url_mon_dni_file,
                                        'Documento de la cédula de identidad de la madre'
                                    )
                                "
                            >
                                <i class="fa fa-download"></i>
                                Descargar
                            </button>
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="monCellPhone" class="form-label">
                                Teléfono móvil
                            </label>
                            <input
                                id="monCellPhone"
                                class="form-control"
                                name="mon_cellphone"
                                type="text"
                                maxlength="11"
                                v-model="record.mon_cellphone"
                                placeholder="Teléfono móvil"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="monPhone" class="form-label"
                                >Teléfono local</label
                            >
                            <input
                                id="monPhone"
                                class="form-control"
                                name="mon_phone"
                                type="text"
                                maxlength="11"
                                v-model="record.mon_phone"
                                placeholder="Teléfono"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            />
                        </div>
                    </div>
                    <!-- Final de datos de la Madre -->
                </div>
                <!-- Final de datos de los padres -->

                <!-- Datos adicionales -->
                <div v-if="record.transplant_id == 28">
                    <h3 class="h5 text-secondary text-uppercase">
                        Datos adicionales
                    </h3>
                    <hr />
                    <div class="row pb-4">
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="dialisis_id" class="form-label">
                                Tipo de diálisis
                                <span class="text-danger">*</span>
                            </label>
                            <select
                                id="dialisis_id"
                                class="form-select"
                                v-model="record.dialisis_id"
                                required
                            >
                                <option value="">-- Seleccione --</option>
                                <option value="1">Peritoneal</option>
                                <option value="2">Hemodiálisis</option>
                            </select>
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="healthcareCenter" class="form-label">
                                Centro de diálisis
                                <span class="text-danger">*</span>
                            </label>
                            <v-select
                                v-model="record.healthcare_center_dialisis"
                                :options="healthcare_centers_dialisis"
                                :reduce="
                                    healthcare_centers_dialisis =>
                                        healthcare_centers_dialisis.id
                                "
                                label="name"
                                id="healthcareCenter"
                                :tabindex="6"
                                :selectable="option => option.id !== ''"
                                placeholder="-- Seleccione --"
                            >
                                <slot name="no-options">Sin registros.</slot>
                            </v-select>
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="time_dialisis" class="form-label">
                                Fecha de inicio de diálisis
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                id="time_dialisis"
                                class="form-control"
                                name="time_dialisis"
                                v-model="record.time_dialisis"
                                type="date"
                                :max="today"
                            />
                        </div>
                    </div>
                </div>
                <!-- Final de datos adicionales -->

                <!-- Datos del médico de la unidad de diálisis -->
                <div v-if="record.transplant_id == 28">
                    <h3 class="h5 text-secondary text-uppercase">
                        Datos del médico de la unidad de diálisis
                    </h3>
                    <hr />
                    <div class="row pb-4">
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctorNames" class="form-label">
                                Nombres <span class="text-danger">*</span>
                            </label>
                            <input
                                id="doctorNames"
                                class="form-control"
                                name="doctor_dialisis_names"
                                v-model="record.doctor_dialisis_names"
                                type="text"
                                placeholder="Nombres"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctorLastname" class="form-label">
                                Apellidos <span class="text-danger">*</span>
                            </label>
                            <input
                                id="doctorLastname"
                                class="form-control"
                                name="doctor_dialisis_lastname"
                                type="text"
                                v-model="record.doctor_dialisis_lastname"
                                placeholder="Apellidos"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctormail" class="form-label">
                                Correo electrónico
                            </label>
                            <input
                                id="doctormail"
                                class="form-control"
                                name="doctor_dialisis_mail"
                                type="email"
                                v-model="record.doctor_dialisis_mail"
                                placeholder="Correo electrónico"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctorCellPhone" class="form-label">
                                Teléfono móvil
                            </label>
                            <input
                                id="doctorCellPhone"
                                class="form-control"
                                name="doctor_dialisis_cellphone"
                                type="text"
                                v-model="record.doctor_dialisis_cellphone"
                                maxlength="11"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                placeholder="Teléfono móvil"
                                required
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctorPhone" class="form-label"
                                >Teléfono de la unidad de diálisis</label
                            >
                            <input
                                id="doctorPhone"
                                class="form-control"
                                name="doctor_dialisis_phone"
                                type="text"
                                v-model="record.doctor_dialisis_phone"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                placeholder="Teléfono local"
                                maxlength="11"
                            />
                        </div>
                    </div>
                </div>
                <!-- Final de datos del médico de la unidad de diálisis -->

                <!-- Asignación de cita médica -->
                <div
                    v-if="
                        record.have_healthcare_center == 'false' &&
                            record.created_at
                    "
                >
                    <h3 class="h5 text-secondary text-uppercase">
                        Asignación de cita médica
                    </h3>
                    <hr />
                    <div>
                        <div class="row pb-4">
                            <!-- Fecha de cita -->
                            <div class="col-12 col-lg-3 mb-3">
                                <label for="date" class="form-label">
                                    Fecha de cita
                                </label>
                                <input
                                    id="date"
                                    class="form-control"
                                    type="date"
                                    name="date"
                                    :min="today"
                                    v-model="
                                        record.citation_information
                                            .medical_citation_date
                                    "
                                />
                            </div>
                            <!-- fin de Fecha de cita -->
                            <div class="col-12 col-lg-3 mb-3">
                                <label
                                    for="healthcareCenter"
                                    class="form-label"
                                >
                                    Centro de trasplante
                                </label>
                                <v-select
                                    id="healthcareCenter"
                                    v-model="
                                        record.citation_information
                                            .medical_citation_healthcare_center
                                    "
                                    label="name"
                                    placeholder="-- Seleccione --"
                                    :options="healthcare_centers"
                                    :reduce="
                                        healthcare_center =>
                                            healthcare_center.id
                                    "
                                    :selectable="option => option.id !== ''"
                                    :tabindex="6"
                                >
                                    <slot name="no-options"
                                        >Sin registros.</slot
                                    >
                                </v-select>
                            </div>
                            <!--  Asistencia -->
                            <div class="col-12 col-lg-3 mb-3">
                                <label for="donor" class="form-label">
                                    ¿Asistió a la cita?
                                </label>
                                <select
                                    id="donar"
                                    class="form-select"
                                    tabindex="9"
                                    v-model="
                                        record.citation_information
                                            .medical_citation_assistance
                                    "
                                >
                                    <option value="false">No</option>
                                    <option value="true">Si</option>
                                </select>
                            </div>
                            <!-- fin Asistencia  -->
                            <!-- Observación -->
                            <div class="row pb-2">
                                <div class="col-12 col-lg-12 mb-3">
                                    <label for="Observación" class="form-label">
                                        Observación
                                    </label>
                                    <textarea
                                        id="Observación"
                                        class="form-control"
                                        name="Observación"
                                        cols="20"
                                        rows="5"
                                        disabled
                                        v-model="
                                            record.citation_information
                                                .medical_citation_observation
                                        "
                                    ></textarea>
                                </div>
                            </div>
                            <!-- fin de Observación -->
                        </div>
                    </div>
                </div>
                <!-- Fin Asignación de cita médica  -->

                <!-- Datos del representante legal -->
                <div v-if="record.age <= 18 && record.dads == 'false'">
                    <h3 class="h5 text-secondary text-uppercase">
                        Datos del representante legal
                    </h3>
                    <hr />
                    <div class="row pb-4">
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="representativeNames" class="form-label">
                                Nombres <span class="text-danger">*</span>
                            </label>
                            <input
                                id="representativeNames"
                                class="form-control"
                                name="representative_names"
                                v-model="record.representative_names"
                                type="text"
                                placeholder="Nombres"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label
                                for="representativeLastname"
                                class="form-label"
                            >
                                Apellidos <span class="text-danger">*</span>
                            </label>
                            <input
                                id="representativeLastname"
                                class="form-control"
                                name="representative_lastname"
                                v-model="record.representative_lastname"
                                type="text"
                                placeholder="Apellidos"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label
                                for="representativeIdNumber"
                                class="form-label"
                            >
                                Cédula de identidad
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                id="representativeIdNumber"
                                class="form-control"
                                name="representative_dni_number"
                                type="text"
                                minlength="7"
                                maxlength="9"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                v-model="record.representative_dni_number"
                                placeholder="Cédula de identidad"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label
                                for="representativeIdFile"
                                class="form-label"
                            >
                                Cédula de identidad
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                id="representativeIdFile"
                                class="form-control"
                                type="file"
                                name="representative_id_file"
                                ref="representative_id_file"
                                accept=".pdf, .docx, .doc, .odt"
                                @change="onFileUpload"
                            />
                            <div class="form-text mb-3">
                                Formato permitido:
                                <label class="fw-bold"
                                    >.pdf .docx .doc .odt</label
                                >
                                Tamaño máximo:
                                <label class="fw-bold">5MB</label>
                            </div>
                            <button
                                v-if="url_dni"
                                class="btn btn-outline-primary-dark"
                                title="Descarga el archivo previamente subido"
                                @click="
                                    downloadFile(
                                        url_representative_dni_file,
                                        'Cédula de identidad del representante legal'
                                    )
                                "
                            >
                                <i class="fa fa-download"></i>
                                Descargar
                            </button>
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label
                                for="representativeCellPhone"
                                class="form-label"
                            >
                                Teléfono móvil
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                id="representativeCellPhone"
                                class="form-control"
                                name="representative_cellphone"
                                type="text"
                                v-model="record.representative_cellphone"
                                placeholder="Teléfono móvil"
                                maxlength="11"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="representativePhone" class="form-label">
                                Teléfono local
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                id="representativePhone"
                                class="form-control"
                                name="representative_phone"
                                type="text"
                                v-model="record.representative_phone"
                                placeholder="Teléfono"
                                maxlength="11"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="legal_certifi_file" class="form-label">
                                Certificado de representante legal
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                id="legal_certifi_file"
                                class="form-control"
                                type="file"
                                name="legal_certifi_file"
                                ref="legal_certifi_file"
                                accept=".pdf, .docx, .doc, .odt"
                                required
                                @change="onFileUpload"
                            />
                            <div class="form-text mb-3">
                                Formato permitido:
                                <label class="fw-bold"
                                    >.pdf .docx .doc .odt</label
                                >
                                Tamaño máximo:
                                <label class="fw-bold">5MB</label>
                            </div>
                            <button
                                v-if="url_dni"
                                class="btn btn-outline-primary-dark"
                                title="Descarga el archivo previamente subido"
                                @click="
                                    downloadFile(
                                        url_legal_certifi_file,
                                        'Certificado de representante legal'
                                    )
                                "
                            >
                                <i class="fa fa-download"></i>
                                Descargar
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Final de datos del representante legal -->

                <!-- Datos del médico especialista en trasplante -->
                <div v-if="record.have_healthcare_center == 'true'">
                    <h3 class="h5 text-secondary text-uppercase">
                        Datos del médico especialista en trasplante
                    </h3>
                    <hr />
                </div>
                <div
                    class="row pb-4"
                    v-if="record.have_healthcare_center == 'true'"
                >
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="doctorNames" class="form-label">
                            Nombres
                            <span class="text-danger">*</span></label
                        >
                        <input
                            id="doctorNames"
                            class="form-control"
                            name="doctor_transplant_names"
                            v-model="record.doctor_transplant_names"
                            type="text"
                            placeholder="Nombres"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="doctorLastname" class="form-label">
                            Apellidos
                            <span class="text-danger">*</span></label
                        >
                        <input
                            id="doctorLastname"
                            class="form-control"
                            name="doctor_transplant_lastname"
                            type="text"
                            v-model="record.doctor_transplant_lastname"
                            placeholder="Apellidos"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="doctormail" class="form-label">
                            Correo electrónico
                        </label>
                        <input
                            id="doctormail"
                            class="form-control"
                            name="doctor_transplant_mail"
                            type="email"
                            v-model="record.doctor_transplant_mail"
                            placeholder="Correo electrónico"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="doctorCellPhone" class="form-label">
                            Teléfono móvil
                        </label>
                        <input
                            id="doctorTransplantCellPhone"
                            class="form-control"
                            name="doctor_transplant_cellphone"
                            type="text"
                            v-model="record.doctor_transplant_cellphone"
                            placeholder="Teléfono móvil"
                            maxlength="11"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="doctorPhone" class="form-label"
                            >Teléfono local</label
                        >
                        <input
                            id="doctorTransplantPhone"
                            class="form-control"
                            name="doctor_transplant_phone"
                            type="text"
                            v-model="record.doctor_transplant_phone"
                            placeholder="Teléfono"
                            maxlength="11"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        />
                    </div>
                </div>
                <!-- Final de datos del médico especialista en trasplante -->

                <!-- Datos del médico tratante -->
                <div v-if="record.transplant_id != 28">
                    <h3 class="h5 text-secondary text-uppercase">
                        Datos del médico tratante
                    </h3>
                    <hr />
                    <div class="row pb-4">
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctorNames" class="form-label">
                                Nombres <span class="text-danger">*</span>
                            </label>
                            <input
                                id="doctorNames"
                                class="form-control"
                                name="doctor_names"
                                v-model="record.doctor_names"
                                type="text"
                                placeholder="Nombres"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctorLastname" class="form-label">
                                Apellidos <span class="text-danger">*</span>
                            </label>
                            <input
                                id="doctorLastname"
                                class="form-control"
                                name="doctor_lastname"
                                type="text"
                                v-model="record.doctor_lastname"
                                placeholder="Apellidos"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctormail" class="form-label">
                                Correo electrónico
                            </label>
                            <input
                                id="doctormail"
                                class="form-control"
                                name="doctor_mail"
                                type="email"
                                v-model="record.doctor_mail"
                                placeholder="Correo electrónico"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctorCellPhone" class="form-label">
                                Teléfono móvil
                            </label>
                            <input
                                id="doctorCellPhone"
                                class="form-control"
                                name="doctor_cellphone"
                                type="text"
                                v-model="record.doctor_cellphone"
                                placeholder="Teléfono móvil"
                                maxlength="11"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctorPhone" class="form-label"
                                >Teléfono local</label
                            >
                            <input
                                id="doctorPhone"
                                class="form-control"
                                name="doctor_phone"
                                type="text"
                                v-model="record.doctor_phone"
                                placeholder="Teléfono local"
                                maxlength="11"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            />
                        </div>
                    </div>
                </div>
                <!-- Final de datos del médico tratante -->

                <!-- Observación de la solicitud de trasplante -->
                <div class="row pb-4">
                    <div class="col-12 col-lg-12 mb-3">
                        <label for="diagnosis" class="form-label">
                            Observación de la solicitud de trasplante
                        </label>
                        <textarea
                            id="observation_request"
                            class="form-control"
                            name="observation_request"
                            cols="20"
                            rows="3"
                            v-model="record.observation_request"
                        ></textarea>
                    </div>
                </div>
                <!-- Final de observación de la solicitud de trasplante -->
            </div>
            <!-- Final de card-body -->

            <!-- Inicio de card-footer -->
            <div class="card-footer text-center">
                <button
                    class="btn btn-outline-primary-dark"
                    type="reset"
                    @click="reset()"
                >
                    Limpiar
                </button>
                <button
                    class="btn btn-primary"
                    type="button"
                    @click="setRecord('transplant-req-processed-update')"
                >
                    <i class="fa fa-save"></i>
                    Actualizar
                </button>
            </div>
            <!-- Final de card-footer -->
        </div>
        <!-- Final de formulario de solicitud de trasplante -->
    </div>
    <!-- Final de transplant-request-form-component -->
</template>

<script>
export default {
    data() {
        return {
            today: "",
            id_file: null,
            updated_photo_file: null,
            blood_type_file: null,
            born_file: null,
            legal_certifi_file: null,
            report_file: null,
            donor_id_file: [],
            donor_blood_type_file: [],
            donor_born_file: null,
            patient_file: null,
            concubinage_file: [],
            marriage_file: [],
            adoption_file: [],
            exposition_healthcare_center_change_file: null,
            letter_healthcare_center_change_file: null,
            mon_id_file: null,
            dad_id_file: null,
            url_updated_photo: "",
            representative_id_file: null,
            requirement_file: {},
            modalQuery: {},
            errors: [],
            records: [],
            blood_types: [],
            estates: [],
            basico: [],
            municipalities: [],
            parishes: [],
            transplants: [],
            relationships: [],
            healthcare_centers: [],
            healthcare_centers_dialisis: [],
            requirements: [],
            // rl de los archivos previamente subidos.
            url_medic_report_file: "",
            url_blood_type_file: "",
            url_born_file: "",
            url_dni: "",
            url_dad_dni_file: "",
            url_mon_dni_file: "",
            // Representante legal.
            url_representative_dni_file: "",
            url_legal_certifi_file: "",
            aplicants: [],
            healthcare_center_assignment: false,
            update_request: false,
            nationalityOptions: [
                { id: "V", text: "Venezolano(a)" },
                { id: "E", text: "Extranjero(a)" }
            ],
            genderOptions: [
                { id: "F", text: "Femenino" },
                { id: "M", text: "Masculino" }
            ],
            maritalStatusOptions: [
                { id: "S", text: "Soltero(a)" },
                { id: "C", text: "Casado(a)" },
                { id: "D", text: "Divorciado(a)" },
                { id: "V", text: "Viudo(a)" }
            ],
            occupations_url: "global-data-type/occupation",
            occupations: [],
            academic_levels: [],
            record: {
                // Asignación médica
                citation_information : {
                    medical_citation_date: "",
                    medical_citation_healthcare_center: "",
                    medical_citation_assistance: "",
                    medical_citation_observation: ""
                },
                dads: "false",
                names: "",
                number: "",
                status: "",
                surnames: "",
                birthdate: "",
                place_of_birth: "",
                dni: "",
                gender: "",
                academic_level: "",
                adress: "",
                email: "",
                patient_blood_type: "",
                patient_cellphone: "",
                age: "0",
                phone: "",
                time_dialisis: "",
                transplant_id: "",
                dialisis_id: "",
                healthcare_center_diálisis: "",
                healthcare_center: "",
                have_healthcare_center: "false",
                healthcare_center_for_change: "",
                diagnosis: "",
                donor: "false",
                donor_arrays: [],
                donor_names: "",
                donor_lastname: "",
                donor_birthday: "",
                ///nuevos
                donor_dni: "",
                donor_nationality: "",
                donor_gender: "",
                donor_marital_status: "",
                donor_ocupation: "",
                donor_academic_level: "",
                donor_age: "",
                donor_cellphone: "",
                donor_rh_factor: "",
                donor_phone: "",
                donor_email: "",
                donor_relationship: "",
                donor_place_of_birth: "",
                //nuevos
                rh_factor: "",
                donor_blood: "",
                time: "",
                dad_names: "",
                dad_lastname: "",
                dad_dni_number: "",
                dad_cellphone: "",
                dad_phone: "",
                mon_names: "",
                mon_lastname: "",
                mon_dni_number: "",
                mon_cellphone: "",
                mon_phone: "",
                representative_names: "",
                representative_lastname: "",
                representative_dni_number: "",
                representative_cellphone: "",
                representative_phone: "",
                process_description: "",
                doctor_dialisis_names: "",
                doctor_dialisis_lastname: "",
                doctor_dialisis_dni_number: "",
                doctor_dialisis_cellphone: "",
                doctor_dialisis_phone: "",
                doctor_transplant_names: "",
                doctor_transplant_lastname: "",
                doctor_transplant_dni_number: "",
                // doctor_transplant_sacs_register_number: "",
                doctor_transplant_mail: "",
                doctor_sacs_register_number: "",
                doctor_dialisis_sacs_register_number: "",
                doctor_transplant_cellphone: "",
                doctor_transplant_phone: "",
                doctor_names: "",
                doctor_lastname: "",
                doctor_dni_number: "",
                doctor_cellphone: "",
                doctor_phone: "",
                doctor_mail: "",
                user_id: "",
                profile_id: "",
                estate_id: "",
                municipality_id: "",
                parish_id: "",
                transplant_id: "",
                relationship: "",
                healthcare_center: "",
                observation_request: "",
                agent_user_id: "",
                agent_names: "",
                agent_surnames: "",
                agent_mail: ""
            },
            keys: 1
        };
    },
    methods: {

        /**
         * Método que evita que el año de la fecha de nacimiento de un donante vivo tenga mas de 4 digitos
         * 
         * @param {*} index
         * 
         * @author José Briceño <josejorgebriceno9@gmail.com>
         */
         setBirthdate(index) {
            const vm = this;
            let date = vm.record.donor_arrays[index].donor_birthday ? vm.record.donor_arrays[index].donor_birthday.split("-") : "";
            let current_year = new Date().getFullYear();
            vm.record.donor_arrays[index].donor_birthday = date ? (parseInt(date[0]) > current_year ? `${current_year}-${date[1]}-${date[2]}` : vm.record.donor_arrays[index].donor_birthday) : "";
        },

        /**
         * Método que oculta un donante vivo.
         *
         * @method removeRow
         *
         * @author
         */
        removeRow: function(index, el) {
            $(".tooltip:last").remove();
            el.splice(index, 1);
        },

        /**
         * Método que inicializa los campos para un donante vivo nuevo
         *
         * @method addDonor
         *
         * @author Francisco Escala <fjescala@gmail.com>
         */
        addDonor() {
            this.record.donor_arrays.push({
                donor_names: "",
                donor_lastname: "",
                donor_birthday: "",
                donor_id: new Date().getTime(),
                ///nuevos
                donor_dni: "",
                donor_nationality: "",
                donor_gender: "",
                donor_marital_status: "",
                donor_ocupation: "",
                donor_academic_level: "",
                donor_age: "",
                donor_cellphone: "",
                donor_rh_factor: "",
                donor_phone: "",
                donor_email: "",
                donor_relationship: "",
                donor_place_of_birth: "",
                //nuevos
                rh_factor: "",
                donor_blood: ""
            });
        },

        /**
         * Método que obtiene el archivo subido al campo respectivo.
         *
         * @method onFileUpload
         *
         * @author Francisco Escala <fjescala@gmail.com>
         */
        onFileUpload(e) {
            const vm = this;
            const max_allowed_size = vm.maxWeightAllow();
            if (e.target.files[0].size) {
                if (e.target.files[0].size < max_allowed_size) {
                    switch (e.target.name) {
                        case "id_file":
                            vm.id_file = e.target.files[0];
                            break;

                        case "updated_photo_file":
                            vm.updated_photo_file = e.target.files[0];
                            break;

                        case "blood_type":
                            vm.blood_type_file = e.target.files[0];

                            break;
                        case "born_file":
                            vm.born_file = e.target.files[0];

                            break;
                        case "legal_certifi_file":
                            vm.legal_certifi_file = e.target.files[0];

                            break;
                        case "report_file":
                            vm.report_file = e.target.files[0];

                            break;

                        case "donor_file":
                            vm.donor_born_file = e.target.files[0];

                            break;
                        case "patient_file":
                            vm.patient_file = e.target.files[0];

                            break;

                        case "marriage_file":
                            vm.marriage_file = e.target.files[0];

                            break;
                        case "mon_id_file":
                            vm.mon_id_file = e.target.files[0];

                            break;
                        case "dad_id_file":
                            vm.dad_id_file = e.target.files[0];

                            break;
                        case "adoption_file":
                            vm.adoption_file = e.target.files[0];

                            break;
                        case "exposition_healthcare_center_change_file":
                            vm.exposition_healthcare_center_change_file =
                                e.target.files[0];

                            break;
                        case "letter_healthcare_center_change_file":
                            vm.letter_healthcare_center_change_file =
                                e.target.files[0];

                            break;
                        case "representative_id_file":
                            vm.representative_id_file = e.target.files[0];

                            break;
                        default:
                            vm.requirement_file[e.target.name] =
                                e.target.files[0];
                    }
                } else {
                    e.target.value = "";
                    vm.maxWeightExceededMessage();
                }
            }
        },

        /**
         * Método que obtiene los archivos del donante subidos  a los campos
         * respectivos.
         *
         * @method onFileUploadDonor
         *
         * @author Francisco Escala <fjescala@gmail.com>
         */
        onFileUploadDonor(e, index, id, name) {
            const vm = this;
            const max_allowed_size = vm.maxWeightAllow();
            if (e.target.files[0].size) {
                if (e.target.files[0].size < max_allowed_size) {
                    switch (name) {
                        case "donor_id_file":
                            this.donor_id_file[index] = [id, e.target.files[0]];
                            break;

                        case "donor_blood_type_file":
                            this.donor_blood_type_file[index] = [
                                id,
                                e.target.files[0]
                            ];
                            break;

                        case "concubinage_file":
                            this.concubinage_file[index] = [
                                id,
                                e.target.files[0]
                            ];
                            break;

                        case "marriage_file":
                            this.marriage_file[index] = [id, e.target.files[0]];
                            break;

                        case "adoption_file":
                            this.adoption_file[index] = [id, e.target.files[0]];
                            break;

                        default:
                    }
                } else {
                    e.target.value = "";
                    vm.maxWeightExceededMessage();
                }
            }
        },

        /**
         * Método para reestablecer valores iniciales del formulario
         *
         * @method reset
         *
         * @author Francisco Escala <fjescala@gmail.com>
         * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <kingangelo_123@gmail.com>
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        reset() {
            const vm = this;
            vm.update_request = false;
            vm.errors = [];
            vm.record.nationality = "";
            vm.record.gender = "";
            vm.record.ocupation = "";
            vm.record.marital_status = "";
            vm.record.academic_level = "";
            vm.record.dads = "false";
            vm.record.surnames = "";
            vm.record.birthdate = "";
            vm.record.place_of_birth = "";
            vm.record.dni = "";
            vm.record.adress = "";
            vm.record.email = "";
            vm.record.patient_blood_type = "";
            vm.record.patient_cellphone = "";
            vm.record.age = "0";
            vm.record.phone = "";
            vm.record.time_dialisis = "";
            vm.record.transplant_id = "";
            vm.record.dialisis_id = "";
            vm.record.healthcare_center_diálisis = "";
            vm.record.healthcare_center = "";
            vm.record.have_healthcare_center = "false";
            vm.record.healthcare_center_for_change = "";
            vm.record.diagnosis = "";
            vm.record.donor = "false";
            vm.record.donor_arrays = [];
            vm.record.donor_names = "";
            vm.record.donor_lastname = "";
            vm.record.donor_birthday = "";
            ///nuevos
            vm.record.donor_dni = "";
            vm.record.donor_nationality = "";
            vm.record.donor_gender = "";
            vm.record.donor_marital_status = "";
            vm.record.donor_ocupation = "";
            vm.record.donor_academic_level = "";
            vm.record.donor_age = "";
            vm.record.donor_cellphone = "";
            vm.record.donor_rh_factor = "";
            vm.record.donor_phone = "";
            vm.record.donor_email = "";
            vm.record.donor_relationship = "";
            vm.record.donor_place_of_birth = "";
            //nuevos
            vm.record.rh_factor = "";
            vm.record.donor_blood = "";
            vm.record.time = "";
            vm.record.dad_names = "";
            vm.record.dad_lastname = "";
            vm.record.dad_dni_number = "";
            vm.record.dad_cellphone = "";
            vm.record.dad_phone = "";
            vm.record.mon_names = "";
            vm.record.mon_lastname = "";
            vm.record.mon_dni_number = "";
            vm.record.mon_cellphone = "";
            vm.record.mon_phone = "";
            vm.record.representative_names = "";
            vm.record.representative_lastname = "";
            vm.record.representative_dni_number = "";
            vm.record.representative_cellphone = "";
            vm.record.representative_phone = "";
            vm.record.process_description = "";
            vm.record.doctor_dialisis_names = "";
            vm.record.doctor_dialisis_lastname = "";
            vm.record.doctor_dialisis_dni_number = "";
            vm.record.doctor_dialisis_cellphone = "";
            vm.record.doctor_dialisis_phone = "";
            vm.record.doctor_transplant_names = "";
            vm.record.doctor_transplant_lastname = "";
            vm.record.doctor_transplant_dni_number = "";
            // vm.record.doctor_transplant_sacs_register_number = "";
            vm.record.doctor_sacs_register_number = "";
            vm.record.doctor_dialisis_sacs_register_number = "";
            vm.record.doctor_transplant_cellphone = "";
            vm.record.doctor_transplant_phone = "";
            vm.record.doctor_names = "";
            vm.record.doctor_lastname = "";
            vm.record.doctor_dni_number = "";
            vm.record.doctor_cellphone = "";
            vm.record.doctor_phone = "";
            vm.record.estate_id = "";
            vm.record.municipality_id = "";
            vm.record.parish_id = "";
            vm.record.transplant_id = "";
            vm.record.relationship = "";
            vm.record.healthcare_center = "";
            vm.record.names = "";
            vm.record.observation_request = "";
            // Asignación de cita médica
            vm.record.citation_information.medical_citation_date && (vm.record.citation_information.medical_citation_date = "");
            vm.record.citation_information.medical_citation_healthcare_center && (vm.record.citation_information.medical_citation_healthcare_center = "");
            vm.record.citation_information.medical_citation_assistance && (vm.record.citation_information.medical_citation_assistance = "");
            vm.record.citation_information.medical_citation_observation && (vm.record.citation_information.medical_citation_observation = "");
            //files
            vm.$refs.id_file && (vm.$refs.id_file.value = null);
            vm.$refs.born_file && (vm.$refs.born_file.value = null);
            vm.$refs.report_file && (vm.$refs.report_file.value = null);
            vm.$refs.blood_type && (vm.$refs.blood_type.value = null);
            vm.$refs.representative_id_file &&
                (vm.$refs.representative_id_file.value = null);
            vm.$refs.legal_certifi_file &&
                (vm.$refs.legal_certifi_file.value = null);
            vm.$refs.donor_id_file && (vm.$refs.donor_id_file.value = null);
            vm.$refs.donor_blood_type_file &&
                (vm.$refs.donor_blood_type_file.value = null);
            vm.$refs.dad_id_file && (vm.$refs.dad_id_file.value = null);
            vm.$refs.mon_id_file && (vm.$refs.mon_id_file.value = null);
            vm.addDonor();
        },

        /**
         * Método para Descargar Archivos
         *
         * @method downloadFile
         *
         */
        async downloadFile(file, name) {
            const vm = this;
            const url = file;
            const formData = new FormData();
            formData.append("url", url);
            try {
                let response = await axios({
                    url: `${window.url}` + "/download-processed-update-aplicant-file",
                    method: "post",
                    data: formData,
                    responseType: "arraybuffer"
                });
                let fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                let fileLink = document.createElement("a");
                fileLink.href = fileURL;
                let ext =
                    file.substring(file.lastIndexOf(".") + 1, file.length) ||
                    file;
                fileLink.setAttribute("download", name + "." + ext);
                document.body.appendChild(fileLink);
                fileLink.click();
                URL.revokeObjectURL(fileLink.href);
            } catch (error) {
                let { errors } = JSON.parse(
                    String.fromCharCode.apply(
                        null,
                        new Uint8Array(error.response.data)
                    )
                );
                vm.errors = [];

                for (var index in errors) {
                    if (errors[index]) {
                        vm.errors.push(errors[index][0]);
                    }
                }
            }
        },

        /**
         * Ejecuta la acción para guardar o actualizar datos
         *
         * @method setRecord
         * @author Francisco Escala <fjescala@gmail.com>
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         *
         * @param  {String}  url URL de la petición a realizar
         */
        async setRecord(url) {
            const vm = this;
            vm.errors = [];
            const recordId =
                typeof vm.record.id !== "undefined" && vm.record.id
                    ? vm.record.id
                    : "";
            const formData = new FormData();
            vm.loadingState(true); // Inicia spinner de carga.

            if (vm.adoption_file) {
                vm.adoption_file.forEach(function(element) {
                    formData.append(`adoption_file[${element[0]}]`, element[1]);
                });
            }

            if (vm.marriage_file) {
                vm.marriage_file.forEach(function(element) {
                    formData.append(`marriage_file[${element[0]}]`, element[1]);
                });
            }

            if (vm.concubinage_file) {
                vm.concubinage_file.forEach(function(element) {
                    formData.append(
                        `concubinage_file[${element[0]}]`,
                        element[1]
                    );
                });
            }

            if (vm.donor_blood_type_file) {
                vm.donor_blood_type_file.forEach(function(element) {
                    formData.append(
                        `donor_blood_type_file[${element[0]}]`,
                        element[1]
                    );
                });
            }

            if (vm.donor_id_file) {
                vm.donor_id_file.forEach(function(element) {
                    formData.append(`donor_id_file[${element[0]}]`, element[1]);
                });
            }

            if (vm.id_file) {
                formData.append("id_file", vm.id_file);
            }
            if (vm.updated_photo_file) {
                formData.append(
                    "updated_photo_file",
                    vm.updated_photo_file,
                    vm.updated_photo_file.name
                );
            }
            if (vm.blood_type_file) {
                formData.append(
                    "blood_type_file",
                    vm.blood_type_file,
                    vm.blood_type_file.name
                );
            }

            if (vm.born_file) {
                formData.append("born_file", vm.born_file, vm.born_file.name);
            }

            if (vm.legal_certifi_file) {
                formData.append(
                    "legal_certifi_file",
                    vm.legal_certifi_file,
                    vm.legal_certifi_file.name
                );
            }

            if (vm.report_file) {
                formData.append(
                    "report_file",
                    vm.report_file,
                    vm.report_file.name
                );
            }

            if (vm.patient_file) {
                formData.append(
                    "patient_file",
                    vm.patient_file,
                    vm.patient_file.name
                );
            }

            if (vm.mon_id_file) {
                formData.append(
                    "mon_id_file",
                    vm.mon_id_file,
                    vm.mon_id_file.name
                );
            }

            if (vm.dad_id_file) {
                formData.append(
                    "dad_id_file",
                    vm.dad_id_file,
                    vm.dad_id_file.name
                );
            }

            if (vm.representative_id_file) {
                formData.append(
                    "representative_id_file",
                    vm.representative_id_file,
                    vm.representative_id_file.name
                );
            }

            if (vm.requirement_file) {
                for (const name_file in vm.requirement_file) {
                    formData.append(
                        `requirement_file[${name_file}]`,
                        vm.requirement_file[name_file]
                    );
                }
            }

            Object.keys(vm.record).forEach(key => {
                if (vm.record[key] == null) {
                    vm.record[key] = "";
                }
                if (key == "donor_arrays" || key == "citation_information") {
                    if (vm.record[key].length < 1) {
                        /// dont send the key to de server then
                    } else {
                        formData.append(key, JSON.stringify(vm.record[key]));
                    }
                } else {
                    formData.append(key, vm.record[key]);
                }
            });
            await axios({
                method: recordId ? "put" : "post",
                url: `${window.url}/${url}`,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" }
            })
                .then(response => {
                    vm.loadingState(); // Finaliza spinner de carga.
                    vm.showMessage("updated");
                })
                .catch(error => {
                    vm.loadingState(); // Finaliza spinner de carga.
                    vm.setErrors(error);
                });
        },

        /**
         * Método para mostrar la informacion de un registro
         * dado su ID en un modal
         *
         * @method showInfo
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {int}      id    ID del registro
         */
        async showInfo(id) {
            const vm = this;
            vm.modalQuery = vm.record;
            $("#show_transplant_request").modal("show");
        },

        /**
         * Método que consume el api de una url particular enviada.
         *
         * @author Francisco Escala <fjescala@gmail.com>
         */
        getRecord(url, id) {
            const vm = this;
            const query = `${window.url}/${url}/${id}`;
            axios
                .get(query)
                .then(response => {
                    vm.record = response.data.result;
                    vm.getMunicipalities();
                    vm.getParishes();
                })
                .catch(error => {
                    console.error(error);
                });
        },

        /**
         * Método que convierte la fecha de registro del perfil.
         *
         * @author Argenis Osorio <aosorio@cenditel.gob.ve>
         */
        convertDate(date) {
            const vm = this;
            var date = new Date(date);
            return date.toLocaleDateString("en-GB");
        },

        /**
         * Método que obtiene los registros de parentesco
         *
         * @method getKinship
         *
         * @author  Luis Ramírez <lgramirez@cenditel.gob.ve>
         *
         * @param  {String}  url URL de la petición a realizar
         *
         */
        getKinship(url) {
            const vm = this;
            const query = `${window.url}/${url}`;
            axios
                .get(query)
                .then(response => {
                    vm.relationships = response.data.result;
                    vm.relationships.unshift({
                        id: "",
                        name: "Seleccione..."
                    });
                })
                .catch(error => {
                    console.error(error);
                });
        },

        /**
         * Método que rellena el campo edad según le fecha de nacimiento
         * seleccionada.
         *
         * @method setAgeDonor
         *
         * @author Francisco Escala
         * 
         * @author José Briceño <josejorgebriceno9@gmail.com>
         */
         setAgeDonor(index) {
            const vm = this;
            let age = moment().diff(vm.record.donor_arrays[index].donor_birthday, "years", false);
            vm.record.donor_arrays[index].donor_age = age > -1 ? age : "";
        },

        /**
         * Método que rellena el campo edad según le fecha de nacimiento
         * seleccionada.
         *
         * @method setAge
         *
         * @author José Briceño <josejorgebriceno9@gmail.com>
         */
         setAge() {
            const vm = this;
            let age = moment().diff(vm.record.birthdate, "years", false);
            vm.record.age = age > -1 ? age : "";
        },

        /**
         * Método que obtiene la fecha del registro y la agrega sobre el campo correspondiente
         *
         * @method  getDate
         *
         * @author  Luis Ramírez <lgramirez@cenditel.gob.ve>
         *
         */
        getDate() {
            const vm = this;
            const newDate = new Date();
            vm.record.date = newDate.toLocaleDateString("en-GB");
            vm.record.created_at = newDate.toLocaleDateString("en-GB");
        },

        /**
         * Método que mueve el puntero del formulario una vez actualizado.
         *
         * @method updateRequest
         *
         * @author
         */
        updateRequest() {
            const vm = this;
            vm.update_request = true;
            window.scrollTo(0, 200);
        },

        /**
         * Define los documentos requeridos de un donante vivo para los parentescos: concubinato, matrimonio y adopción
         *
         * @method  getRelationship
         *
         * @author  Luis Ramírez <lgramirez@cenditel.gob.ve>
         * @author  Francisco Escala <fjescala@gmail.com>
         */
        getRelationship(index) {
            String.prototype.allReplace = function(obj) {
                var retStr = this;
                for (var x in obj) {
                    retStr = retStr.replace(new RegExp(x, "g"), obj[x]);
                }
                return retStr;
            };
            var relati = this.relationships.filter(
                e => e.id == this.record.donor_arrays[index].donor_relationship
            );
            var relationship = relati[0].name;
            let expresion = /concubi/i;
            let found = relationship.match(expresion);
            if (found !== null) {
                this.record.donor_arrays[index].relationship_name =
                    "concubinage";
                return "concubinage";
            }
            expresion = /espos/i;
            found = relationship.match(expresion);
            if (found !== null) {
                this.record.donor_arrays[index].relationship_name = "marriage";
                return "marriage";
            }
            expresion = /adop/i;
            found = relationship.match(expresion);
            if (found !== null) {
                this.record.donor_arrays[index].relationship_name = "adoption";
                return "adoption";
            } else {
                this.record.donor_arrays[index].relationship_name = "family";
                return "family";
            }
        },

        /**
         * Colapse los donantes
         *
         * @method  getRelationship
         *
         * @author  Luis Hernández <lhernandez@mincyt.gob.ve>
         */
        mostrar(index, donor_arrays) {
            this.record.donor_arrays[index].isExpand = true;
            this.keys++;
        },
        ocultar(index, donor_arrays) {
            this.record.donor_arrays[index].isExpand = false;
            this.keys++;
        },

        /**
         * Método que obtiene los datos del agente que actualiza una solicitud de trasplante
         *
         * @method getAgentData
         *
         * @author José Briceño <josejorgebriceno9@gmail.com>
         */
        getAgentData() {
            const vm = this;
            vm.record.agent_user_id = vm.$parent.user.profile.user_id;
            vm.record.agent_names = vm.$parent.user.profile.names;
            vm.record.agent_surnames = vm.$parent.user.profile.surnames;
            vm.record.agent_mail = vm.$parent.user.email;
        },

        /**
         * Método que reinicia los datos del donante vivo
         *
         * @method clearLivingDonorData
         *
         * @author José Briceño <josejorgebriceno9@gmail.com>
         *
         * @author Francisco Escala <fjescala@gmail.com>
         */
        clearLivingDonorData() {
            const vm = this;
            vm.record.donor_arrays = [];
            vm.record.donor && vm.addDonor();
            vm.$refs.donor_id_file && (vm.$refs.donor_id_file.value = null);
            vm.$refs.donor_blood_type_file &&
                (vm.$refs.donor_blood_type_file.value = null);
            vm.$refs.donor_born_file && (vm.$refs.donor_born_file.value = null);
            vm.$refs.donor_concubine_file &&
                (vm.$refs.donor_concubine_file.value = null);
            vm.$refs.donor_marriage_file &&
                (vm.$refs.donor_marriage_file.value = null);
            vm.$refs.donor_adoption_file &&
                (vm.$refs.donor_adoption_file.value = null);
        }
    },

    async mounted() {
        const vm = this;
        /**
         * Si el usuario autenticado intenta acceder al componente sin tener el
         * rol requerido este no carta los diferentes datos a consultar y
         * redirecciona a la pantalla inicial.
        */
        vm.user_role = vm.$parent.user.role;
        if ((vm.user_role == 'ADM') || (vm.user_role == 'OPS')) {
            vm.loadingState(true); // Inicio de spinner de carga.
            if (vm.$route.params.id) {
                try {
                    let {
                        data: { result: result }
                    } = await axios.get(
                        `${window.url}` +
                            "/transplant-request-update/" +
                            `${vm.$route.params.id}`
                    );
                    // Datos personales
                    vm.record.profile_id = result.profile.id;
                    vm.record.number = result.number;
                    vm.record.created_at = vm.convertDate(result.created_at);
                    vm.record.date = vm.record.created_at;
                    vm.record.names = result.profile.names;
                    vm.record.surnames = result.profile.surnames;
                    vm.record.dni = result.profile.dni;
                    vm.url_updated_photo = result.updated_photo;
                    vm.record.nationality = result.profile.nationality;
                    vm.record.gender = result.profile.gender;
                    vm.record.ocupation = parseInt(result.profile.ocupation);
                    vm.record.marital_status = result.profile.marital_status;
                    vm.record.academic_level = result.profile.academic_level;
                    vm.url_dni = result.url_dni;
                    vm.record.birthdate = result.profile.birthdate;
                    vm.record.age = result.profile.age;
                    vm.record.place_of_birth = result.profile.birthplace;
                    vm.record.estate_id = result.profile.estate_id;
                    vm.getMunicipalities(vm.record.estate_id);
                    vm.record.municipality_id = result.profile.municipality_id;
                    vm.getParishes(vm.record.municipality_id);
                    vm.record.parish_id = result.profile.parish_id;
                    vm.record.adress = result.adress;
                    vm.record.patient_cellphone = result.profile.phone;
                    vm.record.phone = result.profile.movil;
                    let user = await axios.get(
                        `${window.url}` + "/users/" + `${result.profile.user_id}`
                    );
                    vm.record.user_id = user.data.result.id;
                    vm.record.email = user.data.result.email;
                    vm.record.transplant_id = parseInt(result.transplant_id);

                    vm.record.healthcare_center = result.healthcare_center
                        ? parseInt(result.healthcare_center)
                        : "";
                    vm.record.have_healthcare_center = result.healthcare_center
                        ? "true"
                        : "false";
                    vm.record.patient_blood_type = parseInt(
                        result.patient_blood_type
                    );
                    vm.record.rh_factor = result.rh_factor == "P" ? "P" : "N";
                    vm.url_born_file = result.url_born_file;
                    vm.url_medic_report_file = result.url_medic_report_file;
                    vm.url_blood_type_file = result.url_blood_type_file;
                    vm.record.diagnosis = result.diagnosis;
                    //Datos de los donantes vivos
                    let living_donor = "";
                    result.living_donor &&
                        (living_donor = JSON.parse(result.living_donor)) &&
                        (vm.record.donor =
                            living_donor.length > 0 ? "true" : "false") &&
                        (vm.record.donor_arrays = living_donor);
                    // formulario de padres
                    vm.record.dads =
                        result.dad_names != null || result.mon_names != null
                            ? "true"
                            : "false";
                    // Datos del padre
                    vm.record.dad_names = result.dad_names;
                    vm.record.dad_lastname = result.dad_lastname;
                    vm.record.dad_dni_number = result.dad_dni_number;
                    vm.url_dad_dni_file = result.url_dad_dni_file;
                    vm.record.dad_cellphone = result.dad_cellphone;
                    vm.record.dad_phone = result.dad_phone;
                    // Datos de la madre
                    vm.record.mon_names = result.mon_names;
                    vm.record.mon_lastname = result.mon_lastname;
                    vm.record.mon_dni_number = result.mon_dni_number;
                    vm.url_mon_dni_file = result.url_mon_dni_file;
                    vm.record.mon_cellphone = result.mon_cellphone;
                    vm.record.mon_phone = result.mon_phone;
                    // Centro de dialisis
                    vm.record.dialisis_id = result.dialisis_id;
                    vm.record.healthcare_center_dialisis = parseInt(
                        result.healthcare_center_dialisis
                    );
                    vm.record.time_dialisis = result.time_dialisis;
                    // Doctor dialisis
                    vm.record.doctor_dialisis_names = result.doctor_dialisis_names;
                    vm.record.doctor_dialisis_lastname =
                        result.doctor_dialisis_lastname;
                    vm.record.doctor_dialisis_dni_number =
                        result.doctor_dialisis_dni_number;
                    vm.record.doctor_dialisis_cellphone =
                        result.doctor_dialisis_cellphone;
                    vm.record.doctor_dialisis_phone = result.doctor_dialisis_phone;
                    vm.record.doctor_dialisis_sacs_register_number =
                        result.doctor_dialisis_sacs_register_number;
                    vm.record.doctor_dialisis_mail = result.doctor_dialisis_mail;
                    // Doctor trasplante
                    vm.record.doctor_transplant_names =
                        result.doctor_transplant_names;
                    vm.record.doctor_transplant_lastname =
                        result.doctor_transplant_lastname;
                    vm.record.doctor_transplant_dni_number =
                        result.doctor_transplant_dni_number;
                    vm.record.doctor_transplant_cellphone =
                        result.doctor_transplant_cellphone;
                    vm.record.doctor_transplant_phone =
                        result.doctor_transplant_phone;
                    vm.record.doctor_transplant_mail =
                        result.doctor_transplant_mail;
                    // Médico tratante
                    vm.record.doctor_lastname = result.doctor_lastname;
                    vm.record.doctor_mail = result.doctor_mail;
                    vm.record.doctor_names = result.doctor_names;
                    vm.record.doctor_phone = result.doctor_phone;
                    vm.record.doctor_cellphone = result.doctor_cellphone;
                    // Representante legal
                    vm.record.representative_cellphone =
                        result.representative_cellphone;
                    vm.record.representative_dni_number =
                        result.representative_dni_number;
                    vm.record.representative_lastname =
                        result.representative_lastname;
                    vm.record.representative_names = result.representative_names;
                    vm.record.representative_phone = result.representative_phone;
                    vm.url_representative_dni_file =
                        result.url_representative_dni_file;
                    vm.url_legal_certifi_file = result.url_legal_certifi_file;
                    vm.record.observation_request = result.observation_request;

                    // Asignación de cita médica
                    let medical_citation_information = null;
                    medical_citation_information = result.medical_citation && JSON.parse(
                        result.medical_citation
                    );
                    if (medical_citation_information) {
                        vm.record.citation_information.medical_citation_date = medical_citation_information.medical_citation_date;
                        vm.record.citation_information.medical_citation_healthcare_center = medical_citation_information.medical_citation_healthcare_center;
                        vm.record.citation_information.medical_citation_assistance = medical_citation_information.medical_citation_assistance;
                        vm.record.citation_information.medical_citation_observation = medical_citation_information.medical_citation_observation;
                    }
                }
                catch (error) {
                    console.log(error);
                }
            }
            vm.loadingState(); // Finaliza spinner de carga.
        }
        else {
            location.href = '/';
        }
    },

    async created() {
        const vm = this;
        /**
         * Si el usuario autenticado intenta acceder al componente sin tener el
         * rol requerido este no carta los diferentes datos a consultar y
         * redirecciona a la pantalla inicial.
        */
        vm.user_role = vm.$parent.user.role;
        if ((vm.user_role == 'ADM') || (vm.user_role == 'OPS')) {
            await vm.getAgentData();
            await vm.getEstates();
            vm.today = vm.getCurrentDate();
            // Obtener los registros de las ocupaciones.
            await vm.getRecords(vm.occupations_url, "occupations", true);
            // Obtener los registros de los niveles académicos.
            await vm.getRecords(
                "global-data-type/academic_level",
                "academic_levels",
                true
            );
            await vm.getKinship("global-data-type/kinship");
            await vm.getRecords(
                "global-data-type/transplantation-type",
                "transplants"
            );
            await vm.getRecords(
                "health-center-type/healthcare",
                "healthcare_centers_dialisis"
            );
            // Obtener el listado de los grupos sanguíneos.
            await vm.getRecords("global-data-type/blood_group", "blood_types");
            await vm.getRecords(
                "health-center-type/transplant",
                "healthcare_centers"
            );
        }
        else {
            location.href = '/';
        }
    },
    watch: {
        /**
         * Método que evita que el año de la fecha de la cita médica sea superior a 4 digitos
         * 
         * @param {*} newValue
         */
         "record.citation_information.medical_citation_date": function (newValue) {
            const vm = this;
            let date = newValue ? newValue.split("-") : "";
            let max_date = new Date();
            let year = max_date.getFullYear() + 2;
            vm.record.citation_information.medical_citation_date = date ? parseInt(date[0]) > year ? `${max_date.getFullYear()}-${date[1]}-${date[2]}` : newValue : "";
        },
        /**
         * Método que evita que el año de la fecha de inicio de diálisis sea superior al año actual
         *
         * @param {*} newValue
         */
         "record.time_dialisis": function (newValue) {
            const vm = this;
            let date = newValue ? newValue.split("-") : "";
            let current_year = new Date().getFullYear();
            vm.record.time_dialisis = date ? parseInt(date[0]) > current_year ? `${current_year}-${date[1]}-${date[2]}`: newValue : "";
        },
        
        /**
         * Método que reinicia el campo fecha de nacimiento cuando el año supera los 4 digitos
         *
         * @param {*} newValue
         */
         "record.birthdate": function (newValue) {
            const vm = this;
            let date = newValue.split("-");
            let current_year = new Date().getFullYear();
            vm.record.birthdate = date ? parseInt(date[0]) > current_year ? `${current_year}-${date[1]}-${date[2]}` : newValue : "";
        },

        /**
         * Método que reinicia los datos del representante legal
         * @author José Briceño <josejorgebriceno9@gmail.com>
         * @param {*} newValue
         */
        "record.age": function(newValue) {
            const vm = this;
            newValue > 17 && (vm.record.representative_names = "");
            newValue > 17 && (vm.record.representative_lastname = "");
            newValue > 17 && (vm.record.representative_dni_number = "");
            newValue > 17 && (vm.record.representative_cellphone = "");
            newValue > 17 && (vm.record.representative_phone = "");
            newValue > 17 &&
                vm.$refs.representative_id_file &&
                (vm.$refs.representative_id_file.value = null);
        },

        /**
         * Método que reinicia los datos del centro de trasplante seleccionado
         * @author José Briceño <josejorgebriceno9@gmail.com>
         * @param {*} newValue
         */
        "record.have_healthcare_center": function(newValue) {
            const vm = this;
            newValue == "false" && (vm.record.healthcare_center = "");

            newValue == "false" &&
                (vm.record.citation_information = {
                    medical_citation_date: "",
                    medical_citation_healthcare_center: "",
                    medical_citation_assistance: "",
                    medical_citation_observation: ""
                });
        },

        /**
         * Método que reinicia los datos del médico de la unidad de dialisis
         * @author José Briceño <josejorgebriceno9@gmail.com>
         * @param {*} newValue
         */
        "record.transplant_id": function(newValue) {
            const vm = this;
            newValue != 28 && (vm.record.doctor_dialisis_names = "");
            newValue != 28 && (vm.record.doctor_dialisis_lastname = "");
            newValue != 28 && (vm.record.doctor_dialisis_mail = "");
            newValue != 28 && (vm.record.doctor_dialisis_cellphone = "");
            newValue != 28 && (vm.record.doctor_dialisis_phone = "");
        },

        /**
         * Método que reinicia los datos de los padres
         * @author José Briceño <josejorgebriceno9@gmail.com>
         * @param {*} newValue
         */
        "record.dads": function(newValue) {
            const vm = this;
            // Padre
            newValue == "false" && (vm.record.dad_names = "");
            newValue == "false" && (vm.record.dad_lastname = "");
            newValue == "false" && (vm.record.dad_dni_number = "");
            newValue == "false" && (vm.record.dad_cellphone = "");
            newValue == "false" && (vm.record.dad_phone = "");
            newValue == "false" &&
                vm.url_dad_dni_file &&
                (vm.url_dad_dni_file.value = "");

            // Madre
            newValue == "false" && (vm.record.mon_names = "");
            newValue == "false" && (vm.record.mon_lastname = "");
            newValue == "false" && (vm.record.mon_dni_number = "");
            newValue == "false" && (vm.record.mon_cellphone = "");
            newValue == "false" && (vm.record.mon_phone = "");
            newValue == "false" &&
                vm.url_mon_dni_file &&
                (vm.url_mon_dni_file.value = "");

            //Represntante legal
            newValue == "true" && (vm.record.representative_names = "");
            newValue == "true" && (vm.record.representative_lastname = "");
            newValue == "true" && (vm.record.representative_dni_number = "");
            newValue == "true" && (vm.record.representative_cellphone = "");
            newValue == "true" && (vm.record.representative_phone = "");
            newValue == "true" &&
                vm.$refs.representative_id_file &&
                (vm.$refs.representative_id_file.value = null);
        },

        /**
         * Método que reinicia los datos relacionados a los tipos de trasplantes
         * @author José Briceño <josejorgebriceno9@gmail.com>
         * @param {*} newValue
         */
        "record.transplant_id": function(newValue) {
            const vm = this;
            // Datos adicionales
            newValue != 28 && (vm.record.dialisis_id = "");
            newValue != 28 && (vm.record.healthcare_center_dialisis = "");
            newValue != 28 && (vm.record.time_dialisis = "");
            // Datos del médico de la unidad de diálisis
            newValue != 28 && (vm.record.doctor_dialisis_names = "");
            newValue != 28 && (vm.record.doctor_dialisis_lastname = "");
            newValue != 28 && (vm.record.doctor_dialisis_mail = "");
            newValue != 28 && (vm.record.doctor_dialisis_cellphone = "");
            newValue != 28 && (vm.record.doctor_dialisis_phone = "");
            // Datos del médico tratante
            newValue == 28 && (vm.record.doctor_names = "");
            newValue == 28 && (vm.record.doctor_lastname = "");
            newValue == 28 && (vm.record.doctor_mail = "");
            newValue == 28 && (vm.record.doctor_cellphone = "");
            newValue == 28 && (vm.record.doctor_phone = "");
        }
    }
};
</script>
