<template>
    <div id="transplant-request-performed-list-component">
        <div class="card mb-4 shadow-sm">
            <h2 class="h3 text-center text-primary text-uppercase py-3
                border-bottom border-primary"
            >
                <i class="fa fa-book"></i> Trasplantes realizados
            </h2>
            <div class="card-body">
                <!-- Filtros de la tabla -->
                <div class="row">
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label">
                            Cédula de identidad
                        </label>
                        <input
                            id=""
                            class="form-control"
                            type="text"
                            minlength="7"
                            maxlength="9"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Cédula de identidad"
                            v-model="filterBy.dni"
                        />
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label">Género</label>
                        <v-select
                            v-model="filterSelectedOptions.genderOption"
                            :options="genderOptions"
                            :reduce="genderOption => genderOption.id"
                            label="text"
                            id="genderOption"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label">
                            Edad (Mayor que)
                        </label>
                        <input
                            id=""
                            class="form-control"
                            type="text"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Edad (Mayor que)"
                            v-model="filterBy.fromAge"
                        />
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label">
                            Edad (Menor que)
                            </label>
                        <input
                            id=""
                            class="form-control"
                            type="text"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Edad (Menor que)"
                            v-model="filterBy.toAge"
                        />
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label">
                            Grupo sanguíneo
                        </label>
                        <v-select
                            v-model="filterSelectedOptions.blodGroupOption"
                            :options="blood_groups"
                            :reduce="blodGroupOption => blodGroupOption.id"
                            label="name"
                            id="blodGroupOption"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label">
                            Factor RH
                        </label>
                        <v-select
                            v-model="filterSelectedOptions.rhFactorOption"
                            :options="rhFactorOptions"
                            :reduce="rhFactorOption => rhFactorOption.id"
                            label="text"
                            id="rhFactorOption"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="estate_id" class="form-label">
                            Estado
                        </label>
                        <v-select
                            v-model="filterSelectedOptions.estate_id"
                            :options="estates"
                            :selectable="option => option.id !== ''"
                            :reduce="estate => estate.id"
                            label="text"
                            id="estate_id"
                            :tabindex="13"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div
                        class="col-12 col-lg-3 mb-3"
                        v-if="user_role == 'ADM' || user_role == 'SUP' || user_role == 'OPS'"
                    >
                        <label for="transplante_id" class="form-label">
                            Tipo de trasplante
                        </label>
                        <v-select
                            v-model="filterSelectedOptions.transplante_id"
                            :options="transplants"
                            :reduce="transplant => transplant.id"
                            label="name"
                            id="transplantId"
                            :tabindex="6"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="row pb-4">
                        <div class="col-12 col-lg-3">
                            <button
                                class="btn btn-outline-primary-dark"
                                type="reset"
                                @click="resetFilters()"
                            >
                                Limpiar
                            </button>
                            <button
                                type="button"
                                class="btn btn-outline-primary-dark"
                                aria-label="Search"
                                @click="filterTransplanteRequest"
                            >
                                Buscar
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Final de filtros de la tabla -->

                <!-- Tabla -->
                <v-client-table
                    :columns="columns"
                    :data="records"
                    :options="table_options"
                >
                    <div slot="number" slot-scope="props">
                        {{ props.row.number }}
                    </div>
                    <div slot="created_at" slot-scope="props">
                        {{ convertDate(props.row.created_at) }}
                    </div>
                    <div slot="names" slot-scope="props">
                        {{ props.row.profile.names }}
                        {{ props.row.profile.surnames }}
                    </div>
                    <div
                        slot="id_number"
                        slot-scope="props"
                    >
                        {{ props.row.profile.dni }}
                    </div>
                    <div slot="transplant_type" slot-scope="props">
                        {{ getTransplantType(props.row.transplant_id) }}
                    </div>
                    <div slot="id" slot-scope="props">
                        <button
                            class="btn btn-outline-primary-dark"
                            type="button"
                            title="Ver registro"
                            @click="showInfo(props.row.id)"
                        >
                            <i class="fa fa-eye"></i>
                        </button>
                        <router-link
                            type="button" class="btn btn-outline-primary"
                            title="Procesar solicitud"
                            :to="{
                                name: 'transplant-request-update-status',
                                params:{
                                    id: props.row.id,
                                }
                            }">
                            <i class="fa fa-arrow-right"></i>
                        </router-link>
                        <router-link
                            :to="{
                                name: 'transplant-request-decline',
                                params: {
                                    id: props.row.id
                                }
                            }"
                            type="button"
                            class="btn btn-outline-primary"
                            title="Rechazar la solicitud"
                        >
                            <i class="fa fa-times-circle"></i>
                        </router-link>
                    </div>
                </v-client-table>
                <!-- Final de tabla -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            records: [],
            transplant_type: [],
            healthcare_center: [],
            blood_type: [],
            healthcare_centers_dialisis: [],
            user_role: "",
            columns: [
                "number",
                "created_at",
                "names",
                "id_number",
                "transplant_type",
                "observation_request",
                "id"
            ],
            url: "transplant-request-performed",
            url_transplantation_type: "global-data-type/transplantation-type",
            url_healthcare_center: "health-center-type/transplant",
            url_blood_type: "global-data-type/blood_type",
            url_healthcare_centers_dialisis: "health-center-type/healthcare",
            modalQuery: {},
            profile: {},
            user: {},
            location: {
                parish: "",
                municipality: "",
                estate: ""
            },
            filterBy: {
                dni: "",
                fromAge: "",
                toAge: ""
            },
            filterSelectedOptions: {
                donationOption: "",
                genderOption: "",
                blodGroupOption: "",
                rhFactorOption: "",
                estate_id: "",
                transplante_id: "",
                estatus_id: ""
            },
            rhFactorOptions: [
                { id: "P", text: "Positivo" },
                { id: "N", text: "Negativo" }
            ],
            donationOptions: [
                { id: "T", text: "Total" },
                { id: "P", text: "Parcial" }
            ],
            genderOptions: [
                { id: "M", text: "Masculino" },
                { id: "F", text: "Femenino" }
            ],
            estates: [],
            blood_groups: [],
            transplants: [],
            estatusOptions: [
                { id: 1, text: "Recibida" },
                { id: 2, text: "Procesada" },
                { id: 99, text: "Negada" }
            ],
            tmpRecords: [],
            nationalityOptions: [
                { id: "V", text: "Venezolano(a)" },
                { id: "E", text: "Extranjero(a)" }
            ],
            maritalStatusOptions: [
                { id: "S", text: "Soltero(a)" },
                { id: "C", text: "Casado(a)" },
                { id: "D", text: "Divorciado(a)" },
                { id: "V", text: "Viudo(a)" }
            ],
            occupations_url: "global-data-type/occupation",
            occupations: [],
            relationships: []
        };
    },
    methods: {
        /**
         * Obtiene el nombre del tipo de trasplante dato un id.
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @method getTransplantType
         * @param     {int}      transplant_id      ID del tipo de trasplante
         */
        getTransplantType(transplant_id) {
            const vm = this;
            let name = "";
            vm.transplant_type.forEach(element => {
                if (element.id == transplant_id) {
                    name = element.name;
                }
            });
            return name;
        },

        /**
         * Obtiene el nombre del centro de trasplante dato un id.
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @method getTransplantType
         * @param     {int}      healthcare_center_id      ID del centro de trasplante
         */
        getHealthcareCenter(healthcare_center_id) {
            const vm = this;
            let name = "";
            vm.healthcare_center.forEach(element => {
                if (element.id == healthcare_center_id) {
                    name = element.name;
                }
            });
            return name;
        },

        /**
         * Obtiene el nombre del centro de dialisis dato un id.
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @method getTransplantType
         * @param     {int}      healthcare_centers_dialisis_id      ID del centro de dialisis
         */
        getHealthcareCentersDialisis(healthcare_centers_dialisis_id) {
            const vm = this;
            let name = "";
            vm.healthcare_centers_dialisis.forEach(element => {
                if (element.id == healthcare_centers_dialisis_id) {
                    name = element.name;
                }
            });
            return name;
        },

        /**
         * Obtiene el nombre del tipo de sangre dato un id.
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @method getTransplantType
         * @param     {int}      blood_type_id      ID del tipo de sangre
         */
        getBloodType(blood_type_id) {
            const vm = this;
            let name = "";
            vm.blood_type.forEach(element => {
                if (element.id == blood_type_id) {
                    name = element.name;
                }
            });
            return name;
        },

        /**
         * Método que cambia el formato de visualización de la fecha en la
         * tabla de registros.
         *
         * @method convertDate
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {date}      date     fecha ha ser fornateada
         */
        convertDate(date) {
            return new Date(date).toLocaleDateString("en-GB");
        },

        /**
         * Método para mostrar la informacion de un registro
         * dado su ID en un modal
         *
         * @method showInfo
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {int}      id    ID del registro
         */
        async showInfo(id) {
            const vm = this;

            let recordEdit = JSON.parse(
                JSON.stringify(
                    vm.records.filter(rec => {
                        return rec.id === id;
                    })[0]
                )
            );

            vm.modalQuery = recordEdit;

            if (vm.modalQuery.url_medical_extra_requirements == null) {
                vm.modalQuery.url_medical_extra_requirements = "";
            }
            else {
                vm.modalQuery.url_medical_extra_requirements =
                vm.modalQuery.url_medical_extra_requirements.length > 0
                ? JSON.parse(
                        vm.modalQuery.url_medical_extra_requirements
                    )
                : "";
            }

            if (vm.modalQuery.url_requirements_file == null) {
                vm.modalQuery.url_requirements_file = "";
            } else {
                vm.modalQuery.url_requirements_file =
                vm.modalQuery.url_requirements_file.length > 0
                ? JSON.parse(vm.modalQuery.url_requirements_file)
                : "";
            }

            vm.profile = recordEdit.profile;
            vm.getUser("users", vm.profile.user_id);
            vm.getLocation("parish", vm.profile.parish_id);

            $("#show_transplant_request").modal("show");
        },
        /**
         * Método para obtener los datos del registro de un usuario
         *
         *
         * @method getLocation
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {int}      id    ID del usuario
         */
        async getUser(url, id) {
            const vm = this;
            const query = `${window.url}/${url}/${id}`;
            axios.get(query).then(response => {
                vm.user = response.data.result;
            })
            .catch(error => {
                console.error(error);
            });
        },

        /**
         * Método para obtener la representación textual de
         * la dirección (estado, municipio, parroquia)
         * dado el id de una parroquia.
         *
         * @method getLocation
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {int}      id    ID de la parroquia
         */
        async getLocation(url, id) {
            const vm = this;
            const query = `${window.url}/${url}/${id}`;
            axios.get(query)
            .then(response => {
                vm.location.parish = response.data.parish.name;
                vm.location.municipality =
                    response.data.parish.municipality.name;
                vm.location.estate =
                    response.data.parish.municipality.estate.name;
            })
            .catch(error => {
                console.error(error);
            });
        },

        /**
         * Método que permite filtrar los datos de la tabla.
         *
         * @method filterTransplanteRequest
         *
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        filterTransplanteRequest() {
            const vm = this;
            vm.records = vm.tmpRecords
                .filter(rec => {
                    return vm.filterBy.dni
                        ? rec.profile.dni === vm.filterBy.dni
                        : true;
                })
                .filter(rec => {
                    return vm.filterBy.fromAge
                        ? rec.profile.age >= vm.filterBy.fromAge
                        : true;
                })
                .filter(rec => {
                    return vm.filterBy.toAge
                        ? rec.profile.age <= vm.filterBy.toAge
                        : true;
                })
                .filter(rec => {
                    return vm.filterSelectedOptions.genderOption
                    ? rec.profile.gender ===
                    vm.filterSelectedOptions.genderOption
                    : true;
                })
                .filter(rec => {
                    return vm.filterSelectedOptions.blodGroupOption
                    ? parseInt(rec.patient_blood_type) ===
                    parseInt(vm.filterSelectedOptions.blodGroupOption)
                    : true;
                })
                .filter(rec => {
                    return vm.filterSelectedOptions.rhFactorOption
                    ? rec.rh_factor ===
                    vm.filterSelectedOptions.rhFactorOption
                    : true;
                })
                .filter(rec => {
                    return vm.filterSelectedOptions.estate_id &&
                    vm.filterSelectedOptions.estate_id != ""
                    ? parseInt(rec.profile.estate_id) ===
                    parseInt(vm.filterSelectedOptions.estate_id)
                    : true;
                })
                .filter(rec => {
                    return vm.filterSelectedOptions.transplante_id &&
                    vm.filterSelectedOptions.transplante_id != ""
                    ? parseInt(rec.transplant_id) ===
                    parseInt(vm.filterSelectedOptions.transplante_id)
                    : true;
                });
        },

        /**
         * Método para reestablecer valores iniciales del formulario de filtros.
         *
         * @method resetFilters
         *
         * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <kingangelo_123@gmail.com>
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        resetFilters() {
            const vm = this;
            vm.filterBy = {
                dni: "",
                fromAge: "",
                toAge: ""
            };
            vm.filterSelectedOptions.genderOption = "";
            vm.filterSelectedOptions.blodGroupOption = "";
            vm.filterSelectedOptions.rhFactorOption = "";
            vm.filterSelectedOptions.estate_id = "";
            vm.filterSelectedOptions.transplante_id = "";
            vm.filterSelectedOptions.estatus_id = "";
            vm.records = vm.tmpRecords;
        },

        /**
         * Método que obtiene los registros de parentesco
         *
         * @method getKinship
         *
         * @author  Luis Ramírez <lgramirez@cenditel.gob.ve>
         *
         * @param  {String}  url URL de la petición a realizar
         *
         */
        getKinship(url) {
            const vm = this;
            const query = `${window.url}/${url}`;
            axios.get(query)
            .then(response => {
                vm.relationships = response.data.result;
                vm.relationships.unshift({
                    id: "",
                    name: "Seleccione..."
                });
            })
            .catch(error => {
                console.error(error);
            });
        },

        /**
         * Método para mostrar el género
         *
         * @method showGender
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         */
        showGender(gender) {
            let genderType = "";
            if (gender === "F") {
                genderType = "Femenino";
            } else if (gender === "M") {
                genderType = "Masculino";
            }
            return genderType;
        },

        /**
         * Método para mostrar el estado civil.
         *
         * @method showMaritalStatus
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         */
        showMaritalStatus(maritalStatus) {
            let maritalStatusType = "";
            if (maritalStatus === "S") {
                maritalStatusType = "Soltero(a)";
            } else if (maritalStatus === "C") {
                maritalStatusType = "Casado(a)";
            } else if (maritalStatus === "D") {
                maritalStatusType = "Divorciado(a)";
            } else if (maritalStatus === "V") {
                maritalStatusType = "Viudo(a)";
            }
            return maritalStatusType;
        }
    },
    async created() {
        this.table_options.headings = {
            number: 'Serial N°',
            created_at: 'Fecha de registro',
            names: "Nombres y Apellidos",
            id_number: "Cédula",
            transplant_type: "Tipo de trasplante",
            observation_request: "Observaciones",
            id: "Acción"
        };
        this.table_options.sortable = [
            "number",
            "created_at",
            "names",
            "id_number",
            "transplant_type",
            "observation_request"
        ];
        this.table_options.filterable = [
            "number",
            "created_at",
            "names",
            "id_number",
            "transplant_type",
            "observation_request"
        ];
        this.table_options.columnsClasses = {
            id: "text-center"
        };
        this.table_options.orderBy = { column: "number" };
    },
    async mounted() {
        const vm = this;
        /**
         * Si el usuario autenticado intenta acceder al componente sin tener el
         * rol requerido este no carta los diferentes datos a consultar y
         * redirecciona a la pantalla inicial.
        */
        // Obtener el rol del usuario autenticado
        vm.user_role = vm.$parent.user.role;
        if (
                (vm.user_role == 'ADM') ||
                (vm.user_role == 'SUP') ||
                (vm.user_role == 'OPS') ||
                (vm.user_role == 'OPSR') ||
                (vm.user_role == 'OPSH') ||
                (vm.user_role == 'OPSC') ||
                (vm.user_role == 'OPSM') ||
                (vm.user_role == 'OPSV') ||
                (vm.user_role == 'OPSCOR') ||
                (vm.user_role == 'OPSP') ||
                (vm.user_role == 'OPSPU')
            ) {
            vm.loadingState(true); // Inicio de spinner de carga.
            await vm.getRecords(vm.url, "records");
            await vm.getRecords(vm.url_transplantation_type, "transplant_type");
            await vm.getRecords(vm.url_healthcare_center, "healthcare_center");
            await vm.getRecords(vm.url_blood_type, "blood_type");
            // Obtener los estados
            await vm.getEstates();
            await vm.getRecords(
                vm.url_healthcare_centers_dialisis,
                "healthcare_centers_dialisis"
            );
            await vm.getRecords("global-data-type/blood_group", "blood_groups");
            await vm.getRecords(
                "global-data-type/transplantation-type",
                "transplants"
            );
            await vm.getRecords(vm.occupations_url, "occupations", true);
            await vm.getRecords(
                "global-data-type/academic_level",
                "academic_levels",
                true
            );
            await vm.getRecords("global-data-type/blood_group", "blood_types");
            await vm.getKinship("global-data-type/kinship");
            vm.tmpRecords = vm.records;
            vm.loadingState(); // Finaliza spinner de carga.
        }
        else {
            location.href = '/';
        }
    }
};
</script>
