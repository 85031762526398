<template>
    <!-- transplant-request-form-component -->
    <div id="transplant-request-form-component">
        <!-- Notificación de solicitud enviada -->
        <div v-if="aplicants.length > 0">
            <div
                v-if="aplicants[0].status == 1"
                class="alert alert-success alert-dismissible fade show"
                role="alert"
            >
                <i class="far fa-check-circle"></i> Su
                <b>Solicitud de trasplante</b> ha sido enviada y está en
                revisión, se le notificará vía correo electrónico y a través del
                sistema los siguientes pasos a seguir.
                <button
                    id="success-register"
                    class="btn-close"
                    type="button"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                ></button>
            </div>
        </div>
        <!-- Final de notificación de solicitud enviada -->

        <!-- Observaciones de la solicitud -->
        <div
            v-if="record.observation_request"
            class="alert alert-warning alert-dismissible fade show"
            role="alert"
        >
            <i class="fa fa-exclamation-triangle"></i>
            <b>Observaciones de la solicitud: </b>
            {{ record.observation_request }}
            <button
                id="success-register"
                class="btn-close"
                type="button"
                data-bs-dismiss="alert"
                aria-label="Close"
            ></button>
        </div>
        <!-- Final de observaciones de la solicitud -->

        <!-- Formulario de solicitud de trasplante -->
        <div class="card mb-4 shadow-sm">
            <h2
                class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary"
            >
                <i class="fa fa-pencil-alt"></i> Formulario de solicitud de
                trasplante
            </h2>
            <!-- card-body -->
            <div class="card-body">
                <form-errors :errors="errors"></form-errors>
                <div class="row pb-4">
                    <!-- Fecha de registro -->
                    <div class="col-12 col-lg-2">
                        <label for="date" class="form-label">
                            Fecha de registro
                        </label>
                        <input
                            id="date"
                            class="form-control"
                            type="text"
                            name="date"
                            readonly
                            disabled
                            v-model="record.created_at"
                        />
                    </div>
                    <!-- Serial N° -->
                    <div class="col-12 col-lg-2 mb-3" v-if="record.number">
                        <label for="number" class="form-label">
                            Serial N°
                        </label>
                        <input
                            id="number"
                            class="form-control"
                            type="text"
                            name="number"
                            placeholder="Serial N°"
                            readonly
                            disabled
                            tabindex="-1"
                            v-model="record.number"
                        />
                    </div>
                </div>

                <!-- Datos personales -->
                <h3 class="h5 text-secondary text-uppercase">
                    Datos personales
                </h3>
                <hr />
                <div class="row pb-4">
                    <!-- Nombres -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="names" class="form-label">
                            Nombres <span class="text-danger">*</span>
                        </label>
                        <input
                            id="names"
                            class="form-control"
                            type="text"
                            name="names"
                            v-model="record.names"
                        />
                    </div>

                    <!-- Apellidos -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="lastname" class="form-label">
                            Apellidos <span class="text-danger">*</span>
                        </label>
                        <input
                            id="lastname"
                            class="form-control"
                            type="text"
                            name="lastname"
                            v-model="record.surnames"
                        />
                    </div>

                    <!-- Número de cédula de identidad -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="idNumber" class="form-label">
                            Número de cédula de identidad
                            <span class="text-danger" v-if="record.age > 17"
                                >*</span
                            >
                        </label>
                        <input
                            id="idNumber"
                            class="form-control"
                            type="text"
                            name="id_number"
                            minlength="7"
                            maxlength="9"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Cédula de identidad"
                            v-model="record.dni"
                        />
                    </div>

                    <!-- Documento de la cédula de identidad -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="idFile" class="form-label">
                            Documento de la cédula de identidad
                            <span
                                class="text-danger"
                                v-if="record.age > 17 || record.dni"
                                >*</span
                            >
                        </label>
                        <div :class="[url_dni ? 'row' : '']">
                            <div :class="[url_dni ? 'col-12 col-lg-7' : '']">
                                <input
                                    id="idFile"
                                    class="form-control"
                                    type="file"
                                    ref="id_file"
                                    name="id_file"
                                    accept=".pdf, .docx, .doc, .odt"
                                    @change="onFileUpload"
                                />
                            </div>
                            <div :class="[url_dni ? 'col-12 col-lg-5' : '']">
                                <button
                                    v-if="url_dni"
                                    class="btn btn-outline-primary-dark"
                                    title="Descarga el archivo previamente subido"
                                    @click="
                                        downloadFile(
                                            url_dni,
                                            'Documento de la cédula de identidad'
                                        )
                                    "
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>
                        </div>
                        <div class="form-text mb-3">
                            Formato permitido:
                            <label class="fw-bold">.pdf .docx .doc .odt</label>
                            Tamaño máximo:
                            <label class="fw-bold">5MB</label>
                        </div>
                    </div>

                    <!-- Documento de la foto actualizada -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="updated_photo_file" class="form-label">
                            Documento de la foto actualizada
                            <span class="text-danger">*</span>
                        </label>
                        <div :class="[url_updated_photo ? 'row' : '']">
                            <div
                                :class="[
                                    url_updated_photo ? 'col-12 col-lg-7' : ''
                                ]"
                            >
                                <input
                                    id="updated_photo_file"
                                    class="form-control"
                                    type="file"
                                    ref="updated_photo_file"
                                    name="updated_photo_file"
                                    accept=".pdf, .docx, .doc, .odt"
                                    @change="onFileUpload"
                                />
                            </div>
                            <div
                                :class="[
                                    url_updated_photo ? 'col-12 col-lg-5' : ''
                                ]"
                            >
                                <button
                                    v-if="url_updated_photo"
                                    class="btn btn-outline-primary-dark"
                                    title="Descarga el archivo previamente subido"
                                    @click="
                                        downloadFile(
                                            url_updated_photo,
                                            'Documento de la foto actualizada'
                                        )
                                    "
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>
                        </div>
                        <div class="form-text mb-3">
                            Formato permitido:
                            <label class="fw-bold">.pdf .docx .doc .odt</label>
                            Tamaño máximo:
                            <label class="fw-bold">5MB</label>
                        </div>
                    </div>

                    <!-- Nacionalidad -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="nationality" class="form-label">
                            Nacionalidad <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="selectedOptions.nationality"
                            :options="nationalityOptions"
                            :reduce="nationality => nationality.id"
                            label="text"
                            id="nationality"
                            :tabindex="6"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>

                    <!-- Fecha de nacimiento -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="birthdate" class="form-label">
                            Fecha de nacimiento
                            <span class="text-danger">*</span>
                        </label>
                        <input
                            id="birthdate"
                            tabindex="-1"
                            class="form-control"
                            type="date"
                            name="birthdate"
                            v-model="record.birthdate"
                            :max="today"
                            @change="setAge"
                        />
                    </div>

                    <!-- Edad -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="age" class="form-label">Edad</label>
                        <input
                            id="age"
                            class="form-control"
                            type="text"
                            name="age"
                            min="1"
                            max="3"
                            placeholder="0"
                            readonly
                            tabindex="-1"
                            v-model="record.age"
                        />
                        <div id="ageHelp" class="form-text">
                            Este campo se autorrellena al ingresar la
                            <label class="fw-bold" for="birthdate">
                                Fecha de nacimiento </label
                            >.
                        </div>
                    </div>

                    <!-- Lugar de nacimiento -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="placeOfBirth" class="form-label">
                            Lugar de nacimiento
                            <span class="text-danger">*</span>
                        </label>
                        <input
                            id="placeOfBirth"
                            class="form-control"
                            type="text"
                            name="place_of_birth"
                            placeholder="Lugar de nacimiento"
                            v-model="record.place_of_birth"
                        />
                    </div>

                    <!-- Género -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="gender" class="form-label">
                            Género <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="selectedOptions.gender"
                            :options="genderOptions"
                            :selectable="option => option.id !== ''"
                            :reduce="gender => gender.id"
                            label="text"
                            id="gender"
                            :tabindex="7"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>

                    <!-- Ocupación -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="ocupation" class="form-label">
                            Ocupación <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="selectedOptions.ocupation"
                            :options="occupations"
                            :selectable="option => option.id !== ''"
                            :reduce="ocupation => ocupation.id"
                            label="name"
                            id="ocupation"
                            :tabindex="8"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>

                    <!-- Estado civil -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="marital_status" class="form-label">
                            Estado civil <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="selectedOptions.marital_status"
                            :options="maritalStatusOptions"
                            :selectable="option => option.id !== ''"
                            :reduce="ms => ms.id"
                            label="text"
                            id="marital_status"
                            :tabindex="9"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>

                    <!-- Nivel académico -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="academic_levels" class="form-label">
                            Nivel académico <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="selectedOptions.academic_level"
                            :options="academic_levels"
                            :selectable="option => option.id !== ''"
                            :reduce="ms => ms.name"
                            label="name"
                            id="academic_level"
                            :tabindex="10"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                </div>
                <!-- Final de datos personales -->

                <!-- Dirección -->
                <h3 class="h5 text-secondary text-uppercase">Dirección</h3>
                <hr />
                <div class="row pb-4">
                    <!-- Estado -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="estateId" class="form-label">
                            Estado <span class="text-danger">*</span>
                        </label>
                        <v-select
                            id="estateId"
                            label="text"
                            placeholder="-- Seleccione --"
                            :options="estates"
                            :reduce="estate => estate.id"
                            :selectable="option => option.id !== ''"
                            :tabindex="6"
                            v-model="record.estate_id"
                            @input="
                                record.municipality_id = '';
                                record.parish_id = '';
                                getMunicipalities(record.estate_id);
                            "
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>

                    <!-- Municipio -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="municipalityId" class="form-label">
                            Municipio <span class="text-danger">*</span>
                        </label>
                        <v-select
                            id="municipality_id"
                            label="text"
                            placeholder="-- Seleccione --"
                            :options="municipalities"
                            :reduce="mun => mun.id"
                            :selectable="option => option.id !== ''"
                            :tabindex="14"
                            @input="
                                record.parish_id = '';
                                getParishes(record.municipality_id);
                            "
                            v-model="record.municipality_id"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>

                    <!-- Parroquia -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="parishId" class="form-label">
                            Parroquia <span class="text-danger">*</span>
                        </label>
                        <v-select
                            id="parish_id"
                            label="text"
                            placeholder="-- Seleccione --"
                            :options="parishes"
                            :reduce="par => par.id"
                            :selectable="option => option.id !== ''"
                            :tabindex="15"
                            v-model="record.parish_id"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>

                    <!-- Dirección -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="adress" class="form-label">
                            Dirección <span class="text-danger">*</span>
                        </label>
                        <textarea
                            id="adress"
                            class="form-control"
                            name="adress"
                            cols="20"
                            placeholder="Dirección"
                            rows="4"
                            v-model="record.adress"
                        ></textarea>
                    </div>
                </div>
                <!-- Final de dirección -->

                <!-- Contacto -->
                <h3 class="h5 text-secondary text-uppercase">Contacto</h3>
                <hr />
                <div class="row pb-4">
                    <!-- Teléfono local -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="phone" class="form-label">
                            Teléfono local <span class="text-danger">*</span>
                        </label>
                        <input
                            id="phone"
                            class="form-control"
                            type="text"
                            name="phone"
                            maxlength="11"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Teléfono local"
                            required
                            v-model="record.phone"
                        />
                    </div>

                    <!-- Teléfono móvil -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="patientCellPhone" class="form-label">
                            Teléfono móvil <span class="text-danger">*</span>
                        </label>
                        <input
                            id="patientCellPhone"
                            class="form-control"
                            type="text"
                            name="movil"
                            maxlength="11"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Teléfono móvil"
                            required
                            v-model="record.movil"
                        />
                    </div>

                    <!-- Correo electrónico -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="mail" class="form-label">
                            Correo electrónico
                            <span class="text-danger">*</span>
                        </label>
                        <input
                            id="mail"
                            class="form-control"
                            type="email"
                            name="mail"
                            v-model="record.email"
                        />
                    </div>
                </div>
                <!-- Final de contacto -->

                <!-- Datos del trasplante -->
                <h3 class="h5 text-secondary text-uppercase">
                    Datos del trasplante
                </h3>
                <hr />
                <div class="row pb-4">
                    <!-- Tipo de trasplante -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="transplantId" class="form-label">
                            Tipo de trasplante
                            <span class="text-danger">*</span>
                        </label>
                        <v-select
                            id="transplantId"
                            label="name"
                            placeholder="-- Seleccione --"
                            :options="transplants"
                            :reduce="transplant => transplant.id"
                            :selectable="option => option.id !== ''"
                            :tabindex="6"
                            v-model="record.transplant_id"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>

                    <!-- Grupo sanguíneo -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="patient_blood_type" class="form-label">
                            Grupo sanguíneo <span class="text-danger">*</span>
                        </label>
                        <v-select
                            id="patient_blood_type"
                            label="name"
                            placeholder="-- Seleccione --"
                            :options="blood_types"
                            :reduce="blood_type => blood_type.id"
                            :selectable="option => option.id !== ''"
                            :tabindex="6"
                            v-model="record.patient_blood_type"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>

                    <!-- Factor RH -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="rhFactor" class="form-label">
                            Factor RH <span class="text-danger">*</span>
                        </label>
                        <select
                            id="nationality"
                            class="form-select"
                            tabindex="5"
                            v-model="record.rh_factor"
                        >
                            <option value="">-- Seleccione --</option>
                            <option value="P">Positivo</option>
                            <option value="N">Negativo</option>
                        </select>
                    </div>

                    <!-- ¿Posee Centro de trasplante? -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="have_healthcare_center" class="form-label">
                            ¿Posee Centro de trasplante?
                        </label>
                        <select
                            id="donar"
                            class="form-select"
                            tabindex="9"
                            v-model="record.have_healthcare_center"
                        >
                            <option :value="false">No</option>
                            <option :value="true">Sí</option>
                        </select>
                    </div>

                    <!-- Centro de trasplante -->
                    <div
                        class="col-12 col-lg-4 mb-3"
                        v-if="record.have_healthcare_center"
                    >
                        <label for="healthcareCenter" class="form-label">
                            Centro de trasplante
                            <span class="text-danger">*</span>
                        </label>
                        <v-select
                            id="healthcareCenter"
                            v-model="record.healthcare_center"
                            label="name"
                            placeholder="-- Seleccione --"
                            :options="healthcare_centers"
                            :reduce="healthcare_center => healthcare_center.id"
                            :selectable="option => option.id !== ''"
                            :tabindex="6"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                </div>

                <div class="row">
                    <!-- Acta de nacimiento -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="born_file" class="form-label">
                            Acta de nacimiento
                            <span
                                class="text-danger"
                                v-if="isRequiredBornFile(record.transplant_id)"
                                >*</span
                            >
                        </label>
                        <div :class="[url_born_file ? 'row' : '']">
                            <div
                                :class="[
                                    url_born_file ? 'col-12 col-lg-7' : ''
                                ]"
                            >
                                <input
                                    id="born_file"
                                    class="form-control"
                                    type="file"
                                    name="born_file"
                                    ref="born_file"
                                    accept=".pdf, .docx, .doc, .odt"
                                    required
                                    @change="onFileUpload"
                                />
                            </div>
                            <div
                                :class="[
                                    url_born_file ? 'col-12 col-lg-5' : ''
                                ]"
                            >
                                <button
                                    v-if="url_born_file"
                                    class="btn btn-outline-primary-dark"
                                    title="Descarga el archivo previamente subido"
                                    @click="
                                        downloadFile(
                                            url_born_file,
                                            'Acta de nacimiento'
                                        )
                                    "
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>
                        </div>
                        <div class="form-text mb-3">
                            Formato permitido:
                            <label class="fw-bold">.pdf .docx .doc .odt</label>
                            Tamaño máximo:
                            <label class="fw-bold">5MB</label>
                        </div>
                    </div>

                    <!-- Informe médico actualizado -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="report_file" class="form-label">
                            Informe médico actualizado
                            <span class="text-danger">*</span>
                        </label>
                        <div :class="[url_medic_report_file ? 'row' : '']">
                            <div
                                :class="[
                                    url_medic_report_file
                                        ? 'col-12 col-lg-7'
                                        : ''
                                ]"
                            >
                                <input
                                    id="report_file"
                                    class="form-control"
                                    type="file"
                                    name="report_file"
                                    ref="report_file"
                                    accept=".pdf, .docx, .doc, .odt"
                                    required
                                    @change="onFileUpload"
                                />
                            </div>
                            <div
                                :class="[
                                    url_medic_report_file
                                        ? 'col-12 col-lg-5'
                                        : ''
                                ]"
                            >
                                <button
                                    v-if="url_medic_report_file"
                                    class="btn btn-outline-primary-dark"
                                    title="Descarga el archivo previamente subido"
                                    @click="
                                        downloadFile(
                                            url_medic_report_file,
                                            'Informe médico actualizado'
                                        )
                                    "
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>
                        </div>
                        <div class="form-text mb-3">
                            Formato permitido:
                            <label class="fw-bold">.pdf .docx .doc .odt</label>
                            Tamaño máximo:
                            <label class="fw-bold">5MB</label>
                        </div>
                    </div>

                    <!-- Documento del tipo de sangre -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="bloodType" class="form-label">
                            Documento del tipo de sangre
                            <span class="text-danger">*</span>
                        </label>
                        <div :class="[url_blood_type_file ? 'row' : '']">
                            <div
                                :class="[
                                    url_blood_type_file ? 'col-12 col-lg-7' : ''
                                ]"
                            >
                                <input
                                    id="bloodType"
                                    class="form-control"
                                    type="file"
                                    name="blood_type"
                                    ref="blood_type"
                                    accept=".pdf, .docx, .doc, .odt"
                                    @change="onFileUpload"
                                />
                            </div>
                            <div
                                :class="[
                                    url_blood_type_file ? 'col-12 col-lg-5' : ''
                                ]"
                            >
                                <button
                                    v-if="url_blood_type_file"
                                    class="btn btn-outline-primary-dark"
                                    title="Descarga el archivo previamente subido"
                                    @click="
                                        downloadFile(
                                            url_blood_type_file,
                                            'Documento del tipo de sangre'
                                        )
                                    "
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>
                        </div>
                        <div class="form-text mb-3">
                            Formato permitido:
                            <label class="fw-bold">.pdf .docx .doc .odt</label>
                            Tamaño máximo:
                            <label class="fw-bold">5MB</label>
                        </div>
                    </div>

                    <!-- Documento del resultado de prueba HLA -->
                    <div
                        v-if="record.transplant_id == 31"
                        class="col-12 col-lg-4 mb-3"
                    >
                        <label for="hla_results_file" class="form-label">
                            Resultado de prueba HLA
                        </label>
                        <div :class="[url_hla_results_file ? 'row' : '']">
                            <div
                                :class="[
                                    url_hla_results_file
                                        ? 'col-12 col-lg-7'
                                        : ''
                                ]"
                            >
                                <input
                                    id="hla_results_file"
                                    class="form-control"
                                    type="file"
                                    name="hla_results_file"
                                    ref="hla_results_file"
                                    accept=".pdf, .docx, .doc, .odt"
                                    @change="onFileUpload"
                                />
                            </div>
                            <div
                                :class="[
                                    url_hla_results_file
                                        ? 'col-12 col-lg-5'
                                        : ''
                                ]"
                            >
                                <button
                                    v-if="url_hla_results_file"
                                    class="btn btn-outline-primary-dark"
                                    title="Descarga el archivo previamente subido"
                                    @click="
                                        downloadFile(
                                            url_hla_results_file,
                                            'Documento del resultado de la prueba HLA'
                                        )
                                    "
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>
                        </div>
                        <div class="form-text mb-3">
                            Formato permitido:
                            <label class="fw-bold">
                                .pdf .docx .doc .odt
                            </label>
                            Tamaño máximo:
                            <label class="fw-bold">5MB</label>
                        </div>
                    </div>
                </div>

                <div class="row pb-4">
                    <!-- Diagnóstico -->
                    <div class="row pb-2">
                        <div class="col-12 col-lg-12 mb-3">
                            <label for="diagnosis" class="form-label">
                                Diagnóstico <span class="text-danger">*</span>
                            </label>
                            <textarea
                                id="diagnosis"
                                class="form-control"
                                name="diagnosis"
                                cols="20"
                                rows="5"
                                v-model="record.diagnosis"
                            ></textarea>
                        </div>
                    </div>

                    <!-- ¿Posee posible donante vivo? -->
                    <div
                        class="col-12 col-lg-4 mb-3"
                        v-if="
                            ![30, 32, 33, 34, 35].includes(record.transplant_id)
                        "
                    >
                        <label for="donor" class="form-label">
                            ¿Posee posible donante vivo?
                        </label>
                        <select
                            id="donar"
                            class="form-select"
                            tabindex="9"
                            v-model="record.donor"
                            @change="clearLivingDonorData"
                        >
                            <option :value="false">No</option>
                            <option :value="true">Si</option>
                        </select>
                    </div>

                    <!-- ¿Posee Padres? -->
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="dads" class="form-label">
                            ¿Posee Padres?
                        </label>
                        <select
                            id="dads"
                            class="form-select"
                            tabindex="10"
                            v-model="record.dads"
                        >
                            <option :value="false">No</option>
                            <option :value="true">Si</option>
                        </select>
                    </div>
                </div>
                <!-- Datos del donante vivo -->
                <div v-if="record.donor" class="living-donor-section mb-3">
                    <div v-if="record.donor">
                        <h3 class="h5 text-secondary text-uppercase">
                            Datos del donante vivo
                            <!--
                            <i
                                class="fa fa-plus-circle cursor-pointer"
                                @click="addDonor"
                                v-if="record.donor_arrays.length != 2"
                            ></i>
                             -->
                        </h3>
                        <hr />
                    </div>
                    <div v-if="record.donor">
                        <div
                            v-for="(donor_array, index) in record.donor_arrays"
                            :key="index"
                        >
                            <div v-if="index > '0'" :key="index + keys">
                                <h3 class="h5 text-secondary text-uppercase">
                                    Datos del donante vivo {{ index + 1 }}
                                    <!-- Botón de agregar otro Donante vivo -->
                                    <i
                                        class="fa fa-plus-circle cursor-pointer"
                                        @click="addDonor"
                                        v-if="record.donor_arrays.length != 2"
                                    ></i>
                                    <!-- Botón de eliminar el Donante vivo -->
                                    <i
                                        v-if="index != '0'"
                                        class="fa fa-minus-circle cursor-pointer"
                                        style="color: red"
                                        @click="
                                            removeRow(
                                                index,
                                                record.donor_arrays
                                            )
                                        "
                                    ></i>
                                    <!-- Botón de ocultar el Donante vivo 2 -->
                                    <i
                                        class="fas fa-arrow-down"
                                        v-if="
                                            !record.donor_arrays[index].isExpand
                                        "
                                        @click="
                                            mostrarDonante(
                                                index,
                                                record.donor_arrays
                                            )
                                        "
                                    ></i>
                                    <!-- Botón de mostrar el Donante vivo 2 -->
                                    <i
                                        class="fas fa-arrow-up"
                                        v-if="
                                            record.donor_arrays[index].isExpand
                                        "
                                        @click="
                                            ocultarDonante(
                                                index,
                                                record.donor_arrays
                                            )
                                        "
                                    ></i>
                                </h3>
                                <div class="icon"></div>
                                <hr />
                            </div>
                            <div
                                class="row justify-content-left pb-4"
                                v-if="
                                    record.donor_arrays[index].isExpand ||
                                        index == '0'
                                "
                            >
                                <!-- Nombres del donante vivo -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label for="donarNames" class="form-label">
                                        Nombres
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        id="donorNames"
                                        class="form-control"
                                        name="donor_names"
                                        v-model="donor_array.donor_names"
                                        type="text"
                                        placeholder="Nombres"
                                    />
                                </div>
                                <!-- Apellidos del donante vivo -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="donorLastname"
                                        class="form-label"
                                    >
                                        Apellidos
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        id="donorLastname"
                                        class="form-control"
                                        name="donor_lastname"
                                        v-model="donor_array.donor_lastname"
                                        type="text"
                                        placeholder="Apellidos"
                                    />
                                </div>
                                <!-- Fecha de nacimiento del donante vivo -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="donorBirthday"
                                        class="form-label"
                                    >
                                        Fecha de nacimiento
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        tabindex="-1"
                                        :id="'donorBirthday_' + index"
                                        class="form-control"
                                        name="donor_birthday"
                                        v-model="donor_array.donor_birthday"
                                        type="date"
                                        :max="today"
                                        @change="
                                            setBirthdate(index);
                                            setAgeDonor(index);
                                        "
                                    />
                                </div>
                                <!-- Edad del donante vivo -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label for="age" class="form-label">
                                        Edad
                                    </label>
                                    <input
                                        :id="'donor_age_' + index"
                                        class="form-control"
                                        type="text"
                                        name="donor_age"
                                        min="1"
                                        max="3"
                                        placeholder="0"
                                        readonly
                                        disabled
                                        tabindex="-1"
                                        v-model="donor_array.donor_age"
                                    />
                                    <div id="ageHelp" class="form-text">
                                        Este campo se autorrellena al ingresar
                                        la
                                        <label class="fw-bold" for="birthdate">
                                            Fecha de nacimiento </label
                                        >.
                                    </div>
                                </div>
                                <!-- Lugar de nacimiento del donante vivo -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="placeOfBirth"
                                        class="form-label"
                                    >
                                        Lugar de nacimiento
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        id="placeOfBirth"
                                        class="form-control"
                                        type="text"
                                        name="place_of_birth"
                                        placeholder="Lugar de nacimiento"
                                        v-model="
                                            donor_array.donor_place_of_birth
                                        "
                                    />
                                </div>
                                <!-- Nacionalidad del donante vivo -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label for="nationality" class="form-label">
                                        Nacionalidad
                                        <span class="text-danger">*</span>
                                    </label>
                                    <v-select
                                        v-model="donor_array.donor_nationality"
                                        :options="nationalityOptions"
                                        :reduce="nationality => nationality.id"
                                        label="text"
                                        id="nationality"
                                        :tabindex="6"
                                        :selectable="option => option.id !== ''"
                                        placeholder="-- Seleccione --"
                                    >
                                        <slot name="no-options">
                                            Sin registros.
                                        </slot>
                                    </v-select>
                                </div>
                                <!-- Género del donante vivo -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label for="gender" class="form-label">
                                        Género
                                        <span class="text-danger">*</span>
                                    </label>
                                    <v-select
                                        v-model="donor_array.donor_gender"
                                        :options="genderOptions"
                                        :selectable="option => option.id !== ''"
                                        :reduce="gender => gender.id"
                                        label="text"
                                        id="gender"
                                        :tabindex="7"
                                        placeholder="-- Seleccione --"
                                    >
                                        <slot name="no-options">
                                            Sin registros.
                                        </slot>
                                    </v-select>
                                </div>
                                <!-- Estado civil del donante vivo -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="marital_status"
                                        class="form-label"
                                    >
                                        Estado civil
                                        <span class="text-danger">*</span>
                                    </label>
                                    <v-select
                                        v-model="
                                            donor_array.donor_marital_status
                                        "
                                        :options="maritalStatusOptions"
                                        :selectable="option => option.id !== ''"
                                        :reduce="ms => ms.id"
                                        label="text"
                                        id="marital_status"
                                        :tabindex="9"
                                        placeholder="-- Seleccione --"
                                    >
                                        <slot name="no-options">
                                            Sin registros.
                                        </slot>
                                    </v-select>
                                </div>
                                <!-- Ocupación del donante vivo -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label for="ocupation" class="form-label">
                                        Ocupación
                                        <span class="text-danger">*</span>
                                    </label>
                                    <v-select
                                        v-model="donor_array.donor_ocupation"
                                        :options="occupations"
                                        :selectable="option => option.id !== ''"
                                        :reduce="ocupation => ocupation.id"
                                        label="name"
                                        id="ocupation"
                                        :tabindex="8"
                                        placeholder="-- Seleccione --"
                                    >
                                        <slot name="no-options">
                                            Sin registros.
                                        </slot>
                                    </v-select>
                                </div>
                                <!-- Nivel académico del donante vivo -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="academic_levels"
                                        class="form-label"
                                    >
                                        Nivel académico
                                        <span class="text-danger">*</span>
                                    </label>
                                    <v-select
                                        v-model="
                                            donor_array.donor_academic_level
                                        "
                                        :options="academic_levels"
                                        :selectable="option => option.id !== ''"
                                        :reduce="ms => ms.name"
                                        label="name"
                                        id="academic_level"
                                        :tabindex="10"
                                        placeholder="-- Seleccione --"
                                    >
                                        <slot name="no-options">
                                            Sin registros.
                                        </slot>
                                    </v-select>
                                </div>
                                <!-- Grupo sanguíneo del donante vivo -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label for="donor_blood" class="form-label">
                                        Grupo sanguíneo
                                        <span class="text-danger">*</span>
                                    </label>
                                    <v-select
                                        v-model="donor_array.donor_blood"
                                        :options="blood_types"
                                        :reduce="blood_type => blood_type.id"
                                        label="name"
                                        id="donor_blood"
                                        :tabindex="6"
                                        :selectable="option => option.id !== ''"
                                        placeholder="-- Seleccione --"
                                    >
                                        <slot name="no-options">
                                            Sin registros.
                                        </slot>
                                    </v-select>
                                </div>
                                <!-- Factor RH del donante vivo -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label for="rhFactor" class="form-label">
                                        Factor RH
                                        <span class="text-danger">*</span>
                                    </label>
                                    <select
                                        id="nationality"
                                        class="form-select"
                                        tabindex="5"
                                        v-model="donor_array.donor_rh_factor"
                                    >
                                        <option value=""
                                            >-- Seleccione --</option
                                        >
                                        <option value="P">Positivo</option>
                                        <option value="N">Negativo</option>
                                    </select>
                                </div>
                                <!-- Número de cédula de identidad del donante vivo -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label for="idNumber" class="form-label">
                                        Número de cédula de identidad
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        id="donor_idNumber"
                                        class="form-control"
                                        type="text"
                                        name="donor_id_number"
                                        minlength="7"
                                        maxlength="9"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        placeholder="Cédula de identidad"
                                        v-model="donor_array.donor_dni"
                                    />
                                </div>
                                <!-- Documento de la cédula de identidad del donante vivo -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label for="donorIdFile" class="form-label">
                                        Documento de la cédula de identidad
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div
                                        :class="[
                                            donor_array.url_donor_dni_file
                                                ? 'row'
                                                : ''
                                        ]"
                                    >
                                        <div
                                            :class="[
                                                donor_array.url_donor_dni_file
                                                    ? 'col-12 col-lg-7'
                                                    : ''
                                            ]"
                                        >
                                            <input
                                                id="donor_id"
                                                class="form-control"
                                                v-model="donor_array.donor_id"
                                                name="donor_id"
                                                type="hidden"
                                            />
                                            <input
                                                id="donorIdFile"
                                                class="form-control"
                                                type="file"
                                                name="donor_id_file"
                                                ref="donor_id_file"
                                                accept=".pdf, .docx, .doc, .odt"
                                                @change="
                                                    onFileUploadDonor(
                                                        $event,
                                                        index,
                                                        donor_array.donor_id,
                                                        'donor_id_file'
                                                    )
                                                "
                                            />
                                        </div>
                                        <div
                                            :class="[
                                                donor_array.url_donor_dni_file
                                                    ? 'col-12 col-lg-5'
                                                    : ''
                                            ]"
                                        >
                                            <button
                                                v-if="
                                                    donor_array.url_donor_dni_file
                                                "
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_donor_dni_file,
                                                        'Documento de la cédula de identidad del donante'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                    </div>
                                    <div class="form-text mb-3">
                                        Formato permitido:
                                        <label class="fw-bold">
                                            .pdf .docx .doc .odt
                                        </label>
                                        Tamaño máximo:
                                        <label class="fw-bold">5MB</label>
                                    </div>
                                </div>
                                <!-- Documento del tipo de sangre del donante vivo -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="donorBloodType"
                                        class="form-label"
                                    >
                                        Documento del tipo de sangre
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div
                                        :class="[
                                            donor_array.url_donor_blood_type_file
                                                ? 'row'
                                                : ''
                                        ]"
                                    >
                                        <div
                                            :class="[
                                                donor_array.url_donor_blood_type_file
                                                    ? 'col-12 col-lg-7'
                                                    : ''
                                            ]"
                                        >
                                            <input
                                                id="donorBloodType"
                                                class="form-control"
                                                type="file"
                                                name="donor_blood_type_file"
                                                ref="donor_blood_type_file"
                                                accept=".pdf, .docx, .doc, .odt"
                                                @change="
                                                    onFileUploadDonor(
                                                        $event,
                                                        index,
                                                        donor_array.donor_id,
                                                        'donor_blood_type_file'
                                                    )
                                                "
                                            />
                                        </div>
                                        <div
                                            :class="[
                                                donor_array.url_donor_blood_type_file
                                                    ? 'col-12 col-lg-5'
                                                    : ''
                                            ]"
                                        >
                                            <button
                                                v-if="
                                                    donor_array.url_donor_blood_type_file
                                                "
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_donor_blood_type_file,
                                                        'Documento del tipo de sangre del donante'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                    </div>
                                    <div class="form-text mb-3">
                                        Formato permitido:
                                        <label class="fw-bold">
                                            .pdf .docx .doc .odt
                                        </label>
                                        Tamaño máximo:
                                        <label class="fw-bold">5MB</label>
                                    </div>
                                </div>
                                <!-- Teléfono móvil del donante vivo -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="doctorCellPhone"
                                        class="form-label"
                                    >
                                        Teléfono móvil
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        id="doctorCellPhone"
                                        class="form-control"
                                        name="doctor_cellphone"
                                        type="text"
                                        v-model="donor_array.donor_cellphone"
                                        placeholder="Teléfono móvil"
                                        maxlength="11"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                    />
                                </div>
                                <!-- Teléfono local del donante vivo -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label for="doctorPhone" class="form-label">
                                        Teléfono local
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        id="doctorPhone"
                                        class="form-control"
                                        name="doctor_phone"
                                        type="text"
                                        v-model="donor_array.donor_phone"
                                        placeholder="Teléfono local"
                                        maxlength="11"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                    />
                                </div>
                                <!-- Correo electrónico del donante vivo -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label for="dni" class="form-label">
                                        Correo electrónico
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        id="email"
                                        class="form-control"
                                        type="email"
                                        placeholder="Correo electrónico"
                                        tabindex="5"
                                        v-model="donor_array.donor_email"
                                    />
                                </div>
                                <!-- Parentesco del donante vivo -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="relationship"
                                        class="form-label"
                                    >
                                        Parentesco
                                        <span class="text-danger">*</span>
                                    </label>
                                    <v-select
                                        v-model="donor_array.donor_relationship"
                                        :options="
                                            getKinshipForTansplantType(
                                                record.transplant_id
                                            )
                                        "
                                        :reduce="
                                            relationship => relationship.id
                                        "
                                        label="name"
                                        id="relationship"
                                        :tabindex="6"
                                        :selectable="option => option.id !== ''"
                                        placeholder="-- Seleccione --"
                                    >
                                        <slot name="no-options">
                                            Sin registros.
                                        </slot>
                                    </v-select>
                                    <input
                                        id="relationship_name"
                                        class="form-control"
                                        v-model="donor_array.relationship_name"
                                        name="relationship_name"
                                        type="hidden"
                                    />
                                </div>
                                <!-- Acta de nacimiento del donante vivo -->
                                <div
                                    class="col-12 col-lg-4 mb-3"
                                    v-if="
                                        getRelationship(index) === 'family' &&
                                            donor_array.donor_relationship !=
                                                1 &&
                                            donor_array.donor_relationship != 2
                                            &&
                                            donor_array.donor_relationship != 5
                                    "
                                >
                                    <label for="donorFile" class="form-label">
                                        Acta de nacimiento
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div
                                        :class="[
                                            donor_array.url_donor_born_file
                                                ? 'row'
                                                : ''
                                        ]"
                                    >
                                        <div
                                            :class="[
                                                donor_array.url_donor_born_file
                                                    ? 'col-12 col-lg-7'
                                                    : ''
                                            ]"
                                        >
                                            <input
                                                class="form-control"
                                                type="file"
                                                accept=".pdf, .docx, .doc, .odt"
                                                :id="'donor_file' + index"
                                                :name="'donor_file' + index"
                                                ref="donor_born_file"
                                                @change="
                                                    onFileUploadDonor(
                                                        $event,
                                                        index,
                                                        donor_array.donor_id,
                                                        'donor_file'
                                                    )
                                                "
                                            />
                                        </div>
                                        <div
                                            :class="[
                                                donor_array.url_donor_born_file
                                                    ? 'col-12 col-lg-5'
                                                    : ''
                                            ]"
                                        >
                                            <button
                                                v-if="
                                                    getRelationship(index) ===
                                                        'family' &&
                                                        donor_array.url_donor_born_file
                                                "
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_donor_born_file,
                                                        'Acta de nacimiento del donante'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                    </div>
                                    <div class="form-text mb-3">
                                        Formato permitido:
                                        <label class="fw-bold">
                                            .pdf .docx .doc .odt
                                        </label>
                                        Tamaño máximo:
                                        <label class="fw-bold">5MB</label>
                                    </div>
                                </div>
                                <!-- Certificado de adopción del donante vivo -->
                                <div
                                    class="col-12 col-lg-4 mb-3"
                                    v-if="getRelationship(index) === 'adoption'"
                                >
                                    <label
                                        for="adoptionFile"
                                        class="form-label"
                                    >
                                        Certificado de adopción
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div
                                        :class="[
                                            donor_array.url_adoption_file
                                                ? 'row'
                                                : ''
                                        ]"
                                    >
                                        <div
                                            :class="[
                                                donor_array.url_adoption_file
                                                    ? 'col-12 col-lg-7'
                                                    : ''
                                            ]"
                                        >
                                            <input
                                                id="adoptionFile"
                                                class="form-control"
                                                type="file"
                                                name="adoption_file"
                                                ref="donor_adoption_file"
                                                accept=".pdf, .docx, .doc, .odt"
                                                @change="
                                                    onFileUploadDonor(
                                                        $event,
                                                        index,
                                                        donor_array.donor_id,
                                                        'adoption_file'
                                                    )
                                                "
                                            />
                                        </div>
                                        <div
                                            :class="[
                                                donor_array.url_adoption_file
                                                    ? 'col-12 col-lg-5'
                                                    : ''
                                            ]"
                                        >
                                            <button
                                                v-if="
                                                    getRelationship(index) ===
                                                        'adoption' &&
                                                        donor_array.url_adoption_file
                                                "
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_adoption_file,
                                                        'Certificado de adopción del donante'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                    </div>
                                    <div class="form-text mb-3">
                                        Formato permitido:
                                        <label class="fw-bold">
                                            .pdf .docx .doc .odt
                                        </label>
                                        Tamaño máximo:
                                        <label class="fw-bold">5MB</label>
                                    </div>
                                </div>
                                <!-- Acta de matrimonio del donante vivo -->
                                <div
                                    class="col-12 col-lg-4 mb-3"
                                    v-if="getRelationship(index) === 'marriage'"
                                >
                                    <label
                                        for="marriageFile"
                                        class="form-label"
                                    >
                                        Acta de matrimonio
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div
                                        :class="[
                                            donor_array.url_marriage_file
                                                ? 'row'
                                                : ''
                                        ]"
                                    >
                                        <div
                                            :class="[
                                                donor_array.url_marriage_file
                                                    ? 'col-12 col-lg-7'
                                                    : ''
                                            ]"
                                        >
                                            <input
                                                id="marriageFile"
                                                class="form-control"
                                                type="file"
                                                name="marriage_file"
                                                ref="donor_marriage_file"
                                                accept=".pdf, .docx, .doc, .odt"
                                                required
                                                @change="
                                                    onFileUploadDonor(
                                                        $event,
                                                        index,
                                                        donor_array.donor_id,
                                                        'marriage_file'
                                                    )
                                                "
                                            />
                                        </div>
                                        <div
                                            :class="[
                                                donor_array.url_marriage_file
                                                    ? 'col-12 col-lg-5'
                                                    : ''
                                            ]"
                                        >
                                            <button
                                                v-if="
                                                    getRelationship(index) ===
                                                        'marriage' &&
                                                        donor_array.url_marriage_file
                                                "
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_marriage_file,
                                                        'Acta de matrimonio del donante'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                    </div>
                                    <div class="form-text mb-3">
                                        Formato permitido:
                                        <label class="fw-bold">
                                            .pdf .docx .doc .odt
                                        </label>
                                        Tamaño máximo:
                                        <label class="fw-bold">5MB</label>
                                    </div>
                                </div>
                                <!-- Acta de concubinato del donante vivo -->
                                <div
                                    class="col-12 col-lg-4 mb-3"
                                    v-if="
                                        getRelationship(index) === 'concubinage'
                                    "
                                >
                                    <label
                                        for="concubinageFile"
                                        class="form-label"
                                    >
                                        Acta de concubinato
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div
                                        :class="[
                                            donor_array.url_concubinage_file
                                                ? 'row'
                                                : ''
                                        ]"
                                    >
                                        <div
                                            :class="[
                                                donor_array.url_concubinage_file
                                                    ? 'col-12 col-lg-7'
                                                    : ''
                                            ]"
                                        >
                                            <input
                                                class="form-control"
                                                type="file"
                                                accept=".pdf, .docx, .doc, .odt"
                                                :id="'concubinage_file' + index"
                                                :name="
                                                    'concubinage_file' + index
                                                "
                                                ref="donor_concubine_file"
                                                @change="
                                                    onFileUploadDonor(
                                                        $event,
                                                        index,
                                                        donor_array.donor_id,
                                                        'concubinage_file'
                                                    )
                                                "
                                            />
                                        </div>
                                        <div
                                            :class="[
                                                donor_array.url_concubinage_file
                                                    ? 'col-12 col-lg-5'
                                                    : ''
                                            ]"
                                        >
                                            <button
                                                v-if="
                                                    getRelationship(index) ===
                                                        'concubinage' &&
                                                        donor_array.url_concubinage_file
                                                "
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_concubinage_file,
                                                        'Acta de concubinato del donante'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                    </div>
                                    <div class="form-text mb-3">
                                        Formato permitido:
                                        <label class="fw-bold">
                                            .pdf .docx .doc .odt
                                        </label>
                                        Tamaño máximo:
                                        <label class="fw-bold">5MB</label>
                                    </div>
                                </div>
                                <!--documento Cedula del Padre -->
                                <div
                                    :class="
                                        donor_array.donor_relationship == 6 ||
                                        donor_array.donor_relationship == 8 ||
                                        donor_array.donor_relationship == 10 ||
                                        donor_array.donor_relationship == 11
                                            ? 'col-12 col-lg-4 mb-3'
                                            : ''
                                    "
                                    v-if="
                                        donor_array.donor_relationship ==
                                        6 ||
                                        donor_array.donor_relationship ==
                                        8 ||
                                        donor_array.donor_relationship ==
                                        10 ||
                                        donor_array.donor_relationship ==
                                        11
                                    "
                                >
                                    <div>
                                        <label
                                            for="donorIdFile"
                                            class="form-label"
                                        >
                                            Documento de la cédula de identidad
                                            del Padre
                                        </label>
                                        <div
                                            :class="[
                                                donor_array.url_donor_dni_dad_file
                                                    ? 'row'
                                                    : ''
                                            ]"
                                        >
                                            <div
                                                :class="[
                                                    donor_array.url_donor_dni_dad_file
                                                        ? 'col-12 col-lg-7'
                                                        : ''
                                                ]"
                                            >
                                                <input
                                                    :id="
                                                        'donor_dni_dad_file' +
                                                            index
                                                    "
                                                    class="form-control"
                                                    type="file"
                                                    :name="
                                                        'donor_dni_dad_file' +
                                                            index
                                                    "
                                                    ref="donor_dni_dad_file"
                                                    accept=".pdf, .docx, .doc, .odt"
                                                    @change="
                                                        onFileUploadDonor(
                                                            $event,
                                                            index,
                                                            donor_array.donor_id,
                                                            'donor_dni_dad_file'
                                                        )
                                                    "
                                                />
                                            </div>
                                            <div
                                                :class="[
                                                    donor_array.url_donor_dni_dad_file
                                                        ? 'col-12 col-lg-5'
                                                        : ''
                                                ]"
                                            >
                                                <button
                                                    v-if="
                                                        donor_array.url_donor_dni_dad_file
                                                    "
                                                    class="btn btn-outline-primary-dark"
                                                    title="Descarga el archivo previamente subido"
                                                    @click="
                                                        downloadFile(
                                                            donor_array.url_donor_dni_dad_file,
                                                            'Documento de la cédula de identidad del padre del donante'
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-download"
                                                    ></i>
                                                    Descargar
                                                </button>
                                            </div>
                                        </div>
                                        <div class="form-text mb-3">
                                            Formato permitido:
                                            <label class="fw-bold">
                                                .pdf .docx .doc .odt
                                            </label>
                                            Tamaño máximo:
                                            <label class="fw-bold">5MB</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- fin documento Cedula del Padre -->
                                <!-- inicio de acta de nacimiento del padre -->
                                <div
                                    :class="
                                        donor_array.donor_relationship == 6 ||
                                        donor_array.donor_relationship == 8 ||
                                        donor_array.donor_relationship == 10 ||
                                        donor_array.donor_relationship == 11
                                            ? 'col-12 col-lg-4 mb-3'
                                            : ''
                                    "
                                    v-if="
                                        donor_array.donor_relationship ==
                                        6 ||
                                        donor_array.donor_relationship ==
                                        8 ||
                                        donor_array.donor_relationship ==
                                        10 ||
                                        donor_array.donor_relationship ==
                                        11
                                    "
                                >
                                    <div>
                                        <label
                                            for="donorIdFile"
                                            class="form-label"
                                        >
                                            Acta de nacimiento del Padre
                                            <span
                                                v-if="
                                                    donor_array.donor_relationship ==
                                                        2
                                                "
                                                class="text-danger"
                                                >*</span
                                            >
                                        </label>

                                        <div
                                            :class="[
                                                donor_array.url_donor_birth_certificate_dad_file
                                                    ? 'row'
                                                    : ''
                                            ]"
                                        >
                                            <div
                                                :class="[
                                                    donor_array.url_donor_birth_certificate_dad_file
                                                        ? 'col-12 col-lg-7'
                                                        : ''
                                                ]"
                                            >
                                                <input
                                                    :id="
                                                        'donor_birth_certificate_dad_file' +
                                                            index
                                                    "
                                                    class="form-control"
                                                    type="file"
                                                    :name="
                                                        'donor_birth_certificate_dad_file' +
                                                            index
                                                    "
                                                    ref="donor_birth_certificate_dad_file"
                                                    accept=".pdf, .docx, .doc, .odt"
                                                    @change="
                                                        onFileUploadDonor(
                                                            $event,
                                                            index,
                                                            donor_array.donor_id,
                                                            'donor_birth_certificate_dad_file'
                                                        )
                                                    "
                                                />
                                            </div>
                                            <div
                                                :class="[
                                                    donor_array.url_donor_birth_certificate_dad_file
                                                        ? 'col-12 col-lg-5'
                                                        : ''
                                                ]"
                                            >
                                                <button
                                                    v-if="
                                                        donor_array.url_donor_birth_certificate_dad_file
                                                    "
                                                    class="btn btn-outline-primary-dark"
                                                    title="Descarga el archivo previamente subido"
                                                    @click="
                                                        downloadFile(
                                                            donor_array.url_donor_birth_certificate_dad_file,
                                                            'Acta de nacimiento del padre del donante'
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-download"
                                                    ></i>
                                                    Descargar
                                                </button>
                                            </div>
                                        </div>
                                        <div class="form-text mb-3">
                                            Formato permitido:
                                            <label class="fw-bold">
                                                .pdf .docx .doc .odt
                                            </label>
                                            Tamaño máximo:
                                            <label class="fw-bold">5MB</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- fin de acta de nacimiento del padre -->
                                <!--documento Cedula del madre -->
                                <div
                                    :class="
                                        donor_array.donor_relationship == 6 ||
                                        donor_array.donor_relationship == 8 ||
                                        donor_array.donor_relationship == 10 ||
                                        donor_array.donor_relationship == 11
                                            ? 'col-12 col-lg-4 mb-3'
                                            : ''
                                    "
                                    v-if="
                                        donor_array.donor_relationship ==
                                        6 ||
                                        donor_array.donor_relationship ==
                                        8 ||
                                        donor_array.donor_relationship ==
                                        10 ||
                                        donor_array.donor_relationship ==
                                        11
                                    "
                                >
                                    <div>
                                        <label
                                            for="donorIdFile"
                                            class="form-label"
                                        >
                                            Documento de la cédula de identidad
                                            de la Madre
                                        </label>
                                        <div
                                            :class="[
                                                donor_array.url_donor_dni_mother_file
                                                    ? 'row'
                                                    : ''
                                            ]"
                                        >
                                            <div
                                                :class="[
                                                    donor_array.url_donor_dni_mother_file
                                                        ? 'col-12 col-lg-7'
                                                        : ''
                                                ]"
                                            >
                                                <input
                                                    :id="
                                                        'donor_dni_mother_file' +
                                                            index
                                                    "
                                                    class="form-control"
                                                    type="file"
                                                    :name="
                                                        'donor_dni_mother_file' +
                                                            index
                                                    "
                                                    ref="donor_dni_mother_file"
                                                    accept=".pdf, .docx, .doc, .odt"
                                                    @change="
                                                        onFileUploadDonor(
                                                            $event,
                                                            index,
                                                            donor_array.donor_id,
                                                            'donor_dni_mother_file'
                                                        )
                                                    "
                                                />
                                            </div>
                                            <div
                                                :class="[
                                                    donor_array.url_donor_dni_mother_file
                                                        ? 'col-12 col-lg-5'
                                                        : ''
                                                ]"
                                            >
                                                <button
                                                    v-if="
                                                        donor_array.url_donor_dni_mother_file
                                                    "
                                                    class="btn btn-outline-primary-dark"
                                                    title="Descarga el archivo previamente subido"
                                                    @click="
                                                        downloadFile(
                                                            donor_array.url_donor_dni_mother_file,
                                                            'Documento de la cédula de identidad de la madre del donante vivo'
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-download"
                                                    ></i>
                                                    Descargar
                                                </button>
                                            </div>
                                        </div>
                                        <div class="form-text mb-3">
                                            Formato permitido:
                                            <label class="fw-bold">
                                                .pdf .docx .doc .odt
                                            </label>
                                            Tamaño máximo:
                                            <label class="fw-bold">5MB</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- fin documento Cedula del madre -->
                                <!-- inicio de acta de nacimiento de la madre-->
                                <div
                                    :class="
                                        donor_array.donor_relationship == 6 ||
                                        donor_array.donor_relationship == 8 ||
                                        donor_array.donor_relationship == 10 ||
                                        donor_array.donor_relationship == 11
                                            ? 'col-12 col-lg-4 mb-3'
                                            : ''
                                    "
                                    v-if="
                                        donor_array.donor_relationship ==
                                        6 ||
                                        donor_array.donor_relationship ==
                                        8 ||
                                        donor_array.donor_relationship ==
                                        10 ||
                                        donor_array.donor_relationship ==
                                        11
                                    "
                                >
                                    <div>
                                        <label
                                            for="donorIdFile"
                                            class="form-label"
                                        >
                                            Acta de nacimiento de la Madre
                                            <span
                                                v-if="
                                                    donor_array.donor_relationship ==
                                                        1
                                                "
                                                class="text-danger"
                                                >*</span
                                            >
                                        </label>
                                        <div
                                            :class="[
                                                donor_array.url_donor_birth_certificate_mother_file
                                                    ? 'row'
                                                    : ''
                                            ]"
                                        >
                                            <div
                                                :class="[
                                                    donor_array.url_donor_birth_certificate_mother_file
                                                        ? 'col-12 col-lg-7'
                                                        : ''
                                                ]"
                                            >
                                                <input
                                                    :id="
                                                        'donor_birth_certificate_mother_file' +
                                                            index
                                                    "
                                                    class="form-control"
                                                    type="file"
                                                    :name="
                                                        'donor_birth_certificate_mother_file' +
                                                            index
                                                    "
                                                    ref="donor_birth_certificate_mother_file"
                                                    accept=".pdf, .docx, .doc, .odt"
                                                    @change="
                                                        onFileUploadDonor(
                                                            $event,
                                                            index,
                                                            donor_array.donor_id,
                                                            'donor_birth_certificate_mother_file'
                                                        )
                                                    "
                                                />
                                            </div>
                                            <div
                                                :class="[
                                                    donor_array.url_donor_birth_certificate_mother_file
                                                        ? 'col-12 col-lg-5'
                                                        : ''
                                                ]"
                                            >
                                                <button
                                                    v-if="
                                                        donor_array.url_donor_birth_certificate_mother_file
                                                    "
                                                    class="btn btn-outline-primary-dark"
                                                    title="Descarga el archivo previamente subido"
                                                    @click="
                                                        downloadFile(
                                                            donor_array.url_donor_birth_certificate_mother_file,
                                                            'Acta de nacimiento del padre del donante'
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-download"
                                                    ></i>
                                                    Descargar
                                                </button>
                                            </div>
                                        </div>
                                        <div class="form-text mb-3">
                                            Formato permitido:
                                            <label class="fw-bold">
                                                .pdf .docx .doc .odt
                                            </label>
                                            Tamaño máximo:
                                            <label class="fw-bold">5MB</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- fin de acta de nacimiento del madre -->
                                <!--documento cedula del abuelo en común -->
                                <div
                                    :class="
                                        donor_array.donor_relationship == 8 ||
                                        donor_array.donor_relationship == 9 ||
                                        donor_array.donor_relationship == 11 ||
                                        donor_array.donor_relationship == 10
                                            ? 'col-12 col-lg-4 mb-3'
                                            : ''
                                    "
                                >
                                    <div
                                        v-if="
                                            donor_array.donor_relationship ==
                                            8 ||
                                            donor_array.donor_relationship ==
                                            9 ||
                                            donor_array.donor_relationship ==
                                            11 ||
                                            donor_array.donor_relationship ==
                                            10
                                        "
                                    >
                                        <label
                                            for="donorIdFile"
                                            class="form-label"
                                        >
                                            Documento de la cédula de identidad
                                            del Abuelo en común
                                        </label>
                                        <div
                                            :class="[
                                                donor_array.url_donor_dni_grandfather_file
                                                    ? 'row'
                                                    : ''
                                            ]"
                                        >
                                            <div
                                                :class="[
                                                    donor_array.url_donor_dni_grandfather_file
                                                        ? 'col-12 col-lg-7'
                                                        : ''
                                                ]"
                                            >
                                                <input
                                                    :id="
                                                        'donor_dni_grandfather_file' +
                                                            index
                                                    "
                                                    class="form-control"
                                                    type="file"
                                                    :name="
                                                        'donor_dni_grandfather_file' +
                                                            index
                                                    "
                                                    ref="donor_dni_grandfather_file"
                                                    accept=".pdf, .docx, .doc, .odt"
                                                    @change="
                                                        onFileUploadDonor(
                                                            $event,
                                                            index,
                                                            donor_array.donor_id,
                                                            'donor_dni_grandfather_file'
                                                        )
                                                    "
                                                />
                                            </div>
                                            <div
                                                :class="[
                                                    donor_array.url_donor_dni_grandfather_file
                                                        ? 'col-12 col-lg-5'
                                                        : ''
                                                ]"
                                            >
                                                <button
                                                    v-if="
                                                        donor_array.url_donor_dni_grandfather_file
                                                    "
                                                    class="btn btn-outline-primary-dark"
                                                    title="Descarga el archivo previamente subido"
                                                    @click="
                                                        downloadFile(
                                                            donor_array.url_donor_dni_grandfather_file,
                                                            'Documento de la cédula de identidad de la madre del donante vivo'
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-download"
                                                    ></i>
                                                    Descargar
                                                </button>
                                            </div>
                                        </div>
                                        <div class="form-text mb-3">
                                            Formato permitido:
                                            <label class="fw-bold">
                                                .pdf .docx .doc .odt
                                            </label>
                                            Tamaño máximo:
                                            <label class="fw-bold">5MB</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- fin documento cedula del abuelo en común -->
                                <!-- inicio de acta de nacimiento del abuelo -->
                                <div
                                    :class="
                                        donor_array.donor_relationship == 9 ||
                                        donor_array.donor_relationship == 10
                                            ? 'col-12 col-lg-4 mb-3'
                                            : ''
                                    "
                                    v-if="
                                        donor_array.donor_relationship != 8 &&
                                            donor_array.donor_relationship != 11
                                    "
                                >
                                    <div
                                        v-if="
                                            donor_array.donor_relationship ==
                                                9 ||
                                                donor_array.donor_relationship ==
                                                    10
                                        "
                                    >
                                        <label
                                            for="donorIdFile"
                                            class="form-label"
                                        >
                                            Acta de nacimiento del Abuelo en
                                            común
                                        </label>
                                        <div
                                            :class="[
                                                donor_array.url_donor_birth_certificate_grandfather_file
                                                    ? 'row'
                                                    : ''
                                            ]"
                                        >
                                            <div
                                                :class="[
                                                    donor_array.url_donor_birth_certificate_grandfather_file
                                                        ? 'col-12 col-lg-7'
                                                        : ''
                                                ]"
                                            >
                                                <input
                                                    :id="
                                                        'donor_birth_certificate_grandfather_file' +
                                                            index
                                                    "
                                                    class="form-control"
                                                    type="file"
                                                    :name="
                                                        'donor_birth_certificate_grandfather_file' +
                                                            index
                                                    "
                                                    ref="donor_birth_certificate_grandfather_file"
                                                    accept=".pdf, .docx, .doc, .odt"
                                                    @change="
                                                        onFileUploadDonor(
                                                            $event,
                                                            index,
                                                            donor_array.donor_id,
                                                            'donor_birth_certificate_grandfather_file'
                                                        )
                                                    "
                                                />
                                            </div>
                                            <div
                                                :class="[
                                                    donor_array.url_donor_birth_certificate_grandfather_file
                                                        ? 'col-12 col-lg-5'
                                                        : ''
                                                ]"
                                            >
                                                <button
                                                    v-if="
                                                        donor_array.url_donor_birth_certificate_grandfather_file
                                                    "
                                                    class="btn btn-outline-primary-dark"
                                                    title="Descarga el archivo previamente subido"
                                                    @click="
                                                        downloadFile(
                                                            donor_array.url_donor_birth_certificate_grandfather_file,
                                                            'Acta de nacimiento del padre del donante'
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-download"
                                                    ></i>
                                                    Descargar
                                                </button>
                                            </div>
                                        </div>
                                        <div class="form-text mb-3">
                                            Formato permitido:
                                            <label class="fw-bold">
                                                .pdf .docx .doc .odt
                                            </label>
                                            Tamaño máximo:
                                            <label class="fw-bold">5MB</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- fin de acta de nacimiento del abuelo -->
                                <!--documento cedula de la abuela en común -->
                                <div
                                    :class="
                                        donor_array.donor_relationship == 8 ||
                                        donor_array.donor_relationship == 9 ||
                                        donor_array.donor_relationship == 11 ||
                                        donor_array.donor_relationship == 10
                                            ? 'col-12 col-lg-4 mb-3'
                                            : ''
                                    "
                                >
                                    <div
                                        v-if="
                                            donor_array.donor_relationship ==
                                            8 ||
                                            donor_array.donor_relationship ==
                                            9 ||
                                            donor_array.donor_relationship ==
                                            11 ||
                                            donor_array.donor_relationship ==
                                            10
                                        "
                                    >
                                        <label
                                            for="donorIdFile"
                                            class="form-label"
                                        >
                                            Documento de la cédula de identidad
                                            de la Abuela en común
                                        </label>
                                        <div
                                            :class="[
                                                donor_array.url_donor_dni_grandmother_file
                                                    ? 'row'
                                                    : ''
                                            ]"
                                        >
                                            <div
                                                :class="[
                                                    donor_array.url_donor_dni_grandmother_file
                                                        ? 'col-12 col-lg-7'
                                                        : ''
                                                ]"
                                            >
                                                <input
                                                    :id="
                                                        'donor_dni_grandmother_file' +
                                                            index
                                                    "
                                                    class="form-control"
                                                    type="file"
                                                    :name="
                                                        'donor_dni_grandmother_file' +
                                                            index
                                                    "
                                                    ref="donor_dni_grandmother_file"
                                                    accept=".pdf, .docx, .doc, .odt"
                                                    @change="
                                                        onFileUploadDonor(
                                                            $event,
                                                            index,
                                                            donor_array.donor_id,
                                                            'donor_dni_grandmother_file'
                                                        )
                                                    "
                                                />
                                            </div>
                                            <div
                                                :class="[
                                                    donor_array.url_donor_dni_grandmother_file
                                                        ? 'col-12 col-lg-5'
                                                        : ''
                                                ]"
                                            >
                                                <button
                                                    v-if="
                                                        donor_array.url_donor_dni_grandmother_file
                                                    "
                                                    class="btn btn-outline-primary-dark"
                                                    title="Descarga el archivo previamente subido"
                                                    @click="
                                                        downloadFile(
                                                            donor_array.url_donor_dni_grandmother_file,
                                                            'Documento de la cédula de identidad de la madre del donante vivo'
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-download"
                                                    ></i>
                                                    Descargar
                                                </button>
                                            </div>
                                        </div>
                                        <div class="form-text mb-3">
                                            Formato permitido:
                                            <label class="fw-bold">
                                                .pdf .docx .doc .odt
                                            </label>
                                            Tamaño máximo:
                                            <label class="fw-bold">5MB</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- fin documento cedula de la abuela en común -->
                                <!-- inicio de acta de nacimiento de la abuela -->
                                <div
                                    :class="
                                        donor_array.donor_relationship == 9 ||
                                        donor_array.donor_relationship == 10
                                            ? 'col-12 col-lg-4 mb-3'
                                            : ''
                                    "
                                >
                                    <div
                                        v-if="
                                            donor_array.donor_relationship ==
                                            9 ||
                                            donor_array.donor_relationship ==
                                            10
                                        "
                                    >
                                        <label
                                            for="donorIdFile"
                                            class="form-label"
                                        >
                                            Acta de nacimiento de la Abuela en
                                            común
                                        </label>
                                        <div
                                            :class="[
                                                donor_array.url_donor_birth_certificate_grandmother_file
                                                    ? 'row'
                                                    : ''
                                            ]"
                                        >
                                            <div
                                                :class="[
                                                    donor_array.url_donor_birth_certificate_grandmother_file
                                                        ? 'col-12 col-lg-7'
                                                        : ''
                                                ]"
                                            >
                                                <input
                                                    :id="
                                                        'donor_birth_certificate_grandmother_file' +
                                                            index
                                                    "
                                                    class="form-control"
                                                    type="file"
                                                    :name="
                                                        'donor_birth_certificate_grandmother_file' +
                                                            index
                                                    "
                                                    ref="donor_birth_certificate_grandmother_file"
                                                    accept=".pdf, .docx, .doc, .odt"
                                                    @change="
                                                        onFileUploadDonor(
                                                            $event,
                                                            index,
                                                            donor_array.donor_id,
                                                            'donor_birth_certificate_grandmother_file'
                                                        )
                                                    "
                                                />
                                            </div>
                                            <div
                                                :class="[
                                                    donor_array.url_donor_birth_certificate_grandmother_file
                                                        ? 'col-12 col-lg-5'
                                                        : ''
                                                ]"
                                            >
                                                <button
                                                    v-if="
                                                        donor_array.url_donor_birth_certificate_grandmother_file
                                                    "
                                                    class="btn btn-outline-primary-dark"
                                                    title="Descarga el archivo previamente subido"
                                                    @click="
                                                        downloadFile(
                                                            donor_array.url_donor_birth_certificate_grandmother_file,
                                                            'Acta de nacimiento del padre del donante'
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-download"
                                                    ></i>
                                                    Descargar
                                                </button>
                                            </div>
                                        </div>
                                        <div class="form-text mb-3">
                                            Formato permitido:
                                            <label class="fw-bold">
                                                .pdf .docx .doc .odt
                                            </label>
                                            Tamaño máximo:
                                            <label class="fw-bold">5MB</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- fin de acta de nacimiento del Abuela -->
                            </div>
                        </div>
                    </div>
                    <!-- Final de datos del donante vivo -->
                </div>

                <!-- Datos de los padres -->
                <div v-if="record.dads == true">
                    <!-- Datos del Padre -->
                    <h3 class="h5 text-secondary text-uppercase">
                        Datos del Padre
                    </h3>
                    <hr />
                    <div class="row pb-4">
                        <!-- Nombres del Padre -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="dadNames" class="form-label">
                                Nombres
                            </label>
                            <input
                                id="dadNames"
                                class="form-control"
                                name="dad_names"
                                v-model="record.dad_names"
                                type="text"
                                placeholder="Nombres"
                            />
                        </div>
                        <!-- Apellidos del Padre -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="dadLastname" class="form-label">
                                Apellidos
                            </label>
                            <input
                                id="dadLastname"
                                class="form-control"
                                name="dad_lastname"
                                v-model="record.dad_lastname"
                                type="text"
                                placeholder="Apellidos"
                            />
                        </div>
                        <!-- Número de cédula de identidad del Padre -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="dadIdNumber" class="form-label">
                                Número de cédula de identidad
                            </label>
                            <input
                                id="dadIdNumber"
                                class="form-control"
                                name="dad_dni_number"
                                type="text"
                                minlength="7"
                                maxlength="9"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                v-model="record.dad_dni_number"
                                placeholder="Cédula de identidad"
                            />
                        </div>
                        <!-- Documento de la cédula de identidad del Padre -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="dadIdFile" class="form-label">
                                Documento de la cédula de identidad
                            </label>
                            <div :class="[url_dad_dni_file ? 'row' : '']">
                                <div
                                    :class="[
                                        url_dad_dni_file
                                            ? 'col-12 col-lg-7'
                                            : ''
                                    ]"
                                >
                                    <input
                                        id="dadIdFile"
                                        class="form-control"
                                        type="file"
                                        name="dad_id_file"
                                        ref="dad_id_file"
                                        accept=".pdf, .docx, .doc, .odt"
                                        @change="onFileUpload"
                                    />
                                </div>
                                <div
                                    :class="[
                                        url_dad_dni_file
                                            ? 'col-12 col-lg-5'
                                            : ''
                                    ]"
                                >
                                    <button
                                        v-if="url_dad_dni_file"
                                        class="btn btn-outline-primary-dark"
                                        title="Descarga el archivo previamente subido"
                                        @click="
                                            downloadFile(
                                                url_dad_dni_file,
                                                'Documento de la cédula de identidad del padre'
                                            )
                                        "
                                    >
                                        <i class="fa fa-download"></i>
                                        Descargar
                                    </button>
                                </div>
                            </div>
                            <div class="form-text mb-3">
                                Formato permitido:
                                <label class="fw-bold">
                                    .pdf .docx .doc .odt
                                </label>
                                Tamaño máximo:
                                <label class="fw-bold">5MB</label>
                            </div>
                        </div>
                        <!-- Acta de nacimiento del Padre -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="dad_born_file" class="form-label">
                                Acta de nacimiento
                            </label>
                            <div :class="[url_dad_born_file ? 'row' : '']">
                                <div :class="[url_dad_born_file ? 'col-12 col-lg-7' : '']">
                                    <input id="dad_born_file" class="form-control" type="file" name="dad_born_file"
                                        ref="dad_born_file" accept=".pdf, .docx, .doc, .odt" @change="onFileUpload" />
                                </div>
                                <div :class="[url_dad_born_file ? 'col-12 col-lg-5' : '']">
                                    <button v-if="url_dad_born_file" class="btn btn-outline-primary-dark"
                                        title="Descarga el archivo previamente subido" @click="
                                            downloadFile(
                                                url_dad_born_file,
                                                'Acta de nacimiento del padre'
                                            )
                                        ">
                                        <i class="fa fa-download"></i>
                                        Descargar
                                    </button>
                                </div>
                            </div>
                            <div class="form-text mb-3">
                                Formato permitido:
                                <label class="fw-bold"> .pdf .docx .doc .odt </label>
                                Tamaño máximo:
                                <label class="fw-bold">5MB</label>
                            </div>
                        </div>
                        <!-- Teléfono móvil del Padre -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="dadCellPhone" class="form-label">
                                Teléfono móvil
                            </label>
                            <input
                                id="dadCellPhone"
                                class="form-control"
                                name="dad_cellphone"
                                type="text"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                placeholder="Teléfono móvil"
                                v-model="record.dad_cellphone"
                                maxlength="11"
                            />
                        </div>
                        <!-- Teléfono local del Padre -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="dadPhone" class="form-label">
                                Teléfono local
                            </label>
                            <input
                                id="dadPhone"
                                class="form-control"
                                name="dad_phone"
                                type="text"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                placeholder="Teléfono"
                                v-model="record.dad_phone"
                                maxlength="11"
                            />
                        </div>
                    </div>
                    <!-- Final de datos del Padre -->

                    <!-- Datos de la Madre -->
                    <h3 class="h5 text-secondary text-uppercase">
                        Datos de la Madre
                    </h3>
                    <hr />
                    <div class="row pb-4">
                        <!-- Nombres de la Madre -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="monNames" class="form-label">
                                Nombres
                            </label>
                            <input
                                id="monNames"
                                class="form-control"
                                name="mon_names"
                                type="text"
                                placeholder="Nombres"
                                v-model="record.mon_names"
                            />
                        </div>
                        <!-- Apellidos de la Madre -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="monLastname" class="form-label">
                                Apellidos
                            </label>
                            <input
                                id="monLastname"
                                class="form-control"
                                name="mon_lastname"
                                type="text"
                                placeholder="Apellidos"
                                v-model="record.mon_lastname"
                            />
                        </div>
                        <!-- Número de cédula de identidad de la Madre -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="monIdNumber" class="form-label">
                                Número de cédula de identidad
                            </label>
                            <input
                                id="monIdNumber"
                                class="form-control"
                                name="mon_dni_number"
                                type="text"
                                minlength="7"
                                maxlength="9"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                v-model="record.mon_dni_number"
                                placeholder="Cédula de identidad"
                            />
                        </div>
                        <!-- Documento de la cédula de identidad de la Madre -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="monIdFile" class="form-label">
                                Documento de la cédula de identidad
                            </label>
                            <div :class="[url_mon_dni_file ? 'row' : '']">
                                <div
                                    :class="[
                                        url_mon_dni_file
                                            ? 'col-12 col-lg-7'
                                            : ''
                                    ]"
                                >
                                    <input
                                        id="monIdFile"
                                        class="form-control"
                                        type="file"
                                        name="mon_id_file"
                                        ref="mon_id_file"
                                        accept=".pdf, .docx, .doc, .odt"
                                        @change="onFileUpload"
                                    />
                                </div>
                                <div
                                    :class="[
                                        url_mon_dni_file
                                            ? 'col-12 col-lg-5'
                                            : ''
                                    ]"
                                >
                                    <button
                                        v-if="url_mon_dni_file"
                                        class="btn btn-outline-primary-dark"
                                        title="Descarga el archivo previamente subido"
                                        @click="
                                            downloadFile(
                                                url_mon_dni_file,
                                                'Documento de la cédula de identidad de la madre'
                                            )
                                        "
                                    >
                                        <i class="fa fa-download"></i>
                                        Descargar
                                    </button>
                                </div>
                            </div>
                            <div class="form-text mb-3">
                                Formato permitido:
                                <label class="fw-bold">
                                    .pdf .docx .doc .odt
                                </label>
                                Tamaño máximo:
                                <label class="fw-bold">5MB</label>
                            </div>
                        </div>
                        <!-- Acta de nacimiento de la Madre-->
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="mom_born_file" class="form-label">
                                Acta de nacimiento
                            </label>
                            <div :class="[url_mom_born_file ? 'row' : '']">
                                <div :class="[url_mom_born_file ? 'col-12 col-lg-7' : '']">
                                    <input id="mom_born_file" class="form-control" type="file" name="mom_born_file"
                                        ref="mom_born_file" accept=".pdf, .docx, .doc, .odt" @change="onFileUpload" />
                                </div>
                                <div :class="[url_mom_born_file ? 'col-12 col-lg-5' : '']">
                                    <button v-if="url_mom_born_file" class="btn btn-outline-primary-dark"
                                        title="Descarga el archivo previamente subido" @click="
                                            downloadFile(
                                                url_mom_born_file,
                                                'Acta de nacimiento de la madre'
                                            )
                                        ">
                                        <i class="fa fa-download"></i>
                                        Descargar
                                    </button>
                                </div>
                            </div>
                            <div class="form-text mb-3">
                                Formato permitido:
                                <label class="fw-bold"> .pdf .docx .doc .odt </label>
                                Tamaño máximo:
                                <label class="fw-bold">5MB</label>
                            </div>
                        </div>
                        <!-- Teléfono móvil de la Madre -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="monCellPhone" class="form-label">
                                Teléfono móvil
                            </label>
                            <input
                                id="monCellPhone"
                                class="form-control"
                                name="mon_cellphone"
                                type="text"
                                v-model="record.mon_cellphone"
                                placeholder="Teléfono móvil"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                maxlength="11"
                            />
                        </div>
                        <!-- Teléfono local de la Madre -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="monPhone" class="form-label">
                                Teléfono local
                            </label>
                            <input
                                id="monPhone"
                                class="form-control"
                                name="mon_phone"
                                type="text"
                                v-model="record.mon_phone"
                                placeholder="Teléfono"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                maxlength="11"
                            />
                        </div>
                    </div>
                    <!-- Final de datos de la Madre -->
                </div>
                <!-- Final de datos de los padres -->
                <!-- Final de datos del trasplante -->

                <!-- Datos adicionales -->
                <div v-if="record.transplant_id == 28">
                    <h3 class="h5 text-secondary text-uppercase">
                        Datos adicionales
                    </h3>
                    <hr />
                    <div class="row pb-4">
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="dialisis_id" class="form-label">
                                Tipo de diálisis
                                <span class="text-danger">*</span>
                            </label>
                            <select
                                id="dialisis_id"
                                class="form-select"
                                v-model="record.dialisis_id"
                                required
                            >
                                <option value="">-- Seleccione --</option>
                                <option value="1">Peritoneal</option>
                                <option value="2">Hemodiálisis</option>
                            </select>
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="healthcareCenter" class="form-label">
                                Centro de diálisis
                                <span class="text-danger">*</span>
                            </label>
                            <v-select
                                v-model="record.healthcare_center_dialisis"
                                :options="healthcare_centers_dialisis"
                                :reduce="
                                    healthcare_centers_dialisis =>
                                        healthcare_centers_dialisis.id
                                "
                                label="name"
                                id="healthcareCenter"
                                :tabindex="6"
                                :selectable="option => option.id !== ''"
                                placeholder="-- Seleccione --"
                            >
                                <slot name="no-options">Sin registros.</slot>
                            </v-select>
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="time_dialisis" class="form-label">
                                Fecha de inicio de diálisis
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                id="time_dialisis"
                                class="form-control"
                                name="time_dialisis"
                                v-model="record.time_dialisis"
                                type="date"
                                :max="today"
                            />
                        </div>
                    </div>
                </div>
                <!-- Final de datos adicionales -->

                <!-- Datos del representante legal -->
                <div v-if="record.age < 18 && !record.dads">
                    <h3 class="h5 text-secondary text-uppercase">
                        Datos del representante legal
                    </h3>
                    <hr />
                    <div class="row pb-4">
                        <!-- Nombres del representante legal -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="representativeNames" class="form-label">
                                Nombres <span class="text-danger">*</span>
                            </label>
                            <input
                                id="representativeNames"
                                class="form-control"
                                name="representative_names"
                                v-model="record.representative_names"
                                type="text"
                                placeholder="Nombres"
                            />
                        </div>
                        <!-- Apellidos del representante legal -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label
                                for="representativeLastname"
                                class="form-label"
                            >
                                Apellidos <span class="text-danger">*</span>
                            </label>
                            <input
                                id="representativeLastname"
                                class="form-control"
                                name="representative_lastname"
                                v-model="record.representative_lastname"
                                type="text"
                                placeholder="Apellidos"
                            />
                        </div>
                        <!-- Número de cédula de identidad del representante legal -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label
                                for="representativeIdNumber"
                                class="form-label"
                            >
                                Número de cédula de identidad
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                id="representativeIdNumber"
                                class="form-control"
                                name="representative_dni_number"
                                type="text"
                                minlength="7"
                                maxlength="9"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                v-model="record.representative_dni_number"
                                placeholder="Cédula de identidad"
                            />
                        </div>
                        <!-- Documento de la cédula de identidad del representante legal -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label
                                for="representativeIdFile"
                                class="form-label"
                            >
                                Documento de la cédula de identidad
                                <span class="text-danger">*</span>
                            </label>
                            <div
                                :class="[
                                    url_representative_dni_file ? 'row' : ''
                                ]"
                            >
                                <div
                                    :class="[
                                        url_representative_dni_file
                                            ? 'col-12 col-lg-7'
                                            : ''
                                    ]"
                                >
                                    <input
                                        id="representativeIdFile"
                                        class="form-control"
                                        type="file"
                                        name="representative_dni_file"
                                        ref="representative_dni_file"
                                        accept=".pdf, .docx, .doc, .odt"
                                        @change="onFileUpload"
                                    />
                                </div>
                                <div
                                    :class="[
                                        url_representative_dni_file
                                            ? 'col-12 col-lg-5'
                                            : ''
                                    ]"
                                >
                                    <button
                                        v-if="url_representative_dni_file"
                                        class="btn btn-outline-primary-dark"
                                        title="Descarga el archivo previamente subido"
                                        @click="
                                            downloadFile(
                                                url_representative_dni_file,
                                                'Documento de la cédula de identidad representante legal'
                                            )
                                        "
                                    >
                                        <i class="fa fa-download"></i>
                                        Descargar
                                    </button>
                                </div>
                            </div>
                            <div class="form-text mb-3">
                                Formato permitido:
                                <label class="fw-bold">
                                    .pdf .docx .doc .odt
                                </label>
                                Tamaño máximo:
                                <label class="fw-bold">5MB</label>
                            </div>
                        </div>
                        <!-- Teléfono móvil del representante legal -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label
                                for="representativeCellPhone"
                                class="form-label"
                            >
                                Teléfono móvil
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                id="representativeCellPhone"
                                class="form-control"
                                name="representative_cellphone"
                                type="text"
                                v-model="record.representative_cellphone"
                                placeholder="Teléfono móvil"
                                maxlength="11"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            />
                        </div>
                        <!-- Teléfono local del representante legal -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="representativePhone" class="form-label">
                                Teléfono local
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                id="representativePhone"
                                class="form-control"
                                name="representative_phone"
                                type="text"
                                v-model="record.representative_phone"
                                placeholder="Teléfono"
                                maxlength="11"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            />
                        </div>
                        <!-- Certificado de representante legal -->
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="legal_certifi_file" class="form-label">
                                Certificado de representante legal
                                <span class="text-danger">*</span>
                            </label>
                            <div :class="[url_legal_certifi_file ? 'row' : '']">
                                <div
                                    :class="[
                                        url_legal_certifi_file
                                            ? 'col-12 col-lg-7'
                                            : ''
                                    ]"
                                >
                                    <input
                                        id="legal_certifi_file"
                                        class="form-control"
                                        type="file"
                                        name="legal_certifi_file"
                                        ref="legal_certifi_file"
                                        accept=".pdf, .docx, .doc, .odt"
                                        required
                                        @change="onFileUpload"
                                    />
                                </div>
                                <div
                                    :class="[
                                        url_legal_certifi_file
                                            ? 'col-12 col-lg-5'
                                            : ''
                                    ]"
                                >
                                    <button
                                        v-if="url_legal_certifi_file"
                                        class="btn btn-outline-primary-dark"
                                        title="Descarga el archivo previamente subido"
                                        @click="
                                            downloadFile(
                                                url_legal_certifi_file,
                                                'Certificado de representante legal'
                                            )
                                        "
                                    >
                                        <i class="fa fa-download"></i>
                                        Descargar
                                    </button>
                                </div>
                            </div>
                            <div class="form-text mb-3">
                                Formato permitido:
                                <label class="fw-bold">
                                    .pdf .docx .doc .odt
                                </label>
                                Tamaño máximo:
                                <label class="fw-bold">5MB</label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Final de datos del representante legal -->

                <!-- Datos del médico tratante -->
                <div v-if="record.transplant_id != 28">
                    <h3 class="h5 text-secondary text-uppercase">
                        Datos del médico tratante
                    </h3>
                    <hr />
                    <div class="row pb-4">
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctorNames" class="form-label">
                                Nombres <span class="text-danger">*</span>
                            </label>
                            <input
                                id="doctorNames"
                                class="form-control"
                                name="doctor_names"
                                v-model="record.doctor_names"
                                type="text"
                                placeholder="Nombres"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctorLastname" class="form-label">
                                Apellidos <span class="text-danger">*</span>
                            </label>
                            <input
                                id="doctorLastname"
                                class="form-control"
                                name="doctor_lastname"
                                type="text"
                                v-model="record.doctor_lastname"
                                placeholder="Apellidos"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctormail" class="form-label">
                                Correo electrónico
                            </label>
                            <input
                                id="doctormail"
                                class="form-control"
                                name="doctor_mail"
                                type="email"
                                v-model="record.doctor_mail"
                                placeholder="Correo electrónico"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctorCellPhone" class="form-label">
                                Teléfono móvil
                            </label>
                            <input
                                id="doctorCellPhone"
                                class="form-control"
                                name="doctor_cellphone"
                                type="text"
                                v-model="record.doctor_cellphone"
                                placeholder="Teléfono móvil"
                                maxlength="11"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctorPhone" class="form-label">
                                Teléfono local
                            </label>
                            <input
                                id="doctorPhone"
                                class="form-control"
                                name="doctor_phone"
                                type="text"
                                v-model="record.doctor_phone"
                                placeholder="Teléfono local"
                                maxlength="11"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            />
                        </div>
                    </div>
                </div>
                <!-- Final de datos del médico tratante -->

                <!-- Datos del médico de la unidad de diálisis -->
                <div v-if="record.transplant_id == 28">
                    <h3 class="h5 text-secondary text-uppercase">
                        Datos del médico de la unidad de diálisis
                    </h3>
                    <hr />
                    <div class="row pb-4">
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctorNames" class="form-label">
                                Nombres <span class="text-danger">*</span>
                            </label>
                            <input
                                id="doctorNames"
                                class="form-control"
                                name="doctor_dialisis_names"
                                v-model="record.doctor_dialisis_names"
                                type="text"
                                placeholder="Nombres"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctorLastname" class="form-label">
                                Apellidos <span class="text-danger">*</span>
                            </label>
                            <input
                                id="doctorLastname"
                                class="form-control"
                                name="doctor_dialisis_lastname"
                                type="text"
                                v-model="record.doctor_dialisis_lastname"
                                placeholder="Apellidos"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctormail" class="form-label">
                                Correo electrónico
                            </label>
                            <input
                                id="doctormail"
                                class="form-control"
                                name="doctor_dialisis_mail"
                                type="email"
                                v-model="record.doctor_dialisis_mail"
                                placeholder="Correo electrónico"
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctorCellPhone" class="form-label">
                                Teléfono móvil
                            </label>
                            <input
                                id="doctorCellPhone"
                                class="form-control"
                                name="doctor_dialisis_cellphone"
                                type="text"
                                v-model="record.doctor_dialisis_cellphone"
                                maxlength="11"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                placeholder="Teléfono móvil"
                                required
                            />
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="doctorPhone" class="form-label">
                                Teléfono de la unidad de diálisis
                            </label>
                            <input
                                id="doctorPhone"
                                class="form-control"
                                name="doctor_dialisis_phone"
                                type="text"
                                v-model="record.doctor_dialisis_phone"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                placeholder="Teléfono local"
                                maxlength="11"
                            />
                        </div>
                    </div>
                </div>
                <!-- Final de datos del médico de la unidad de diálisis -->

                <!-- Datos del médico especialista en trasplante -->
                <div v-if="record.have_healthcare_center">
                    <h3 class="h5 text-secondary text-uppercase">
                        Datos del médico especialista en trasplante
                    </h3>
                    <hr />
                </div>
                <div class="row pb-4" v-if="record.have_healthcare_center">
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="doctorNames" class="form-label">
                            Nombres
                            <span class="text-danger">*</span>
                        </label>
                        <input
                            id="doctorNames"
                            class="form-control"
                            name="doctor_transplant_names"
                            v-model="record.doctor_transplant_names"
                            type="text"
                            placeholder="Nombres"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="doctorLastname" class="form-label">
                            Apellidos
                            <span class="text-danger">*</span>
                        </label>
                        <input
                            id="doctorLastname"
                            class="form-control"
                            name="doctor_transplant_lastname"
                            type="text"
                            v-model="record.doctor_transplant_lastname"
                            placeholder="Apellidos"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="doctormail" class="form-label">
                            Correo electrónico
                        </label>
                        <input
                            id="doctormail"
                            class="form-control"
                            name="doctor_transplant_mail"
                            type="email"
                            v-model="record.doctor_transplant_mail"
                            placeholder="Correo electrónico"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="doctorCellPhone" class="form-label">
                            Teléfono móvil
                        </label>
                        <input
                            id="doctorTransplantCellPhone"
                            class="form-control"
                            name="doctor_transplant_cellphone"
                            type="text"
                            v-model="record.doctor_transplant_cellphone"
                            placeholder="Teléfono móvil"
                            maxlength="11"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="doctorPhone" class="form-label">
                            Teléfono local
                        </label>
                        <input
                            id="doctorTransplantPhone"
                            class="form-control"
                            name="doctor_transplant_phone"
                            type="text"
                            v-model="record.doctor_transplant_phone"
                            placeholder="Teléfono"
                            maxlength="11"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        />
                    </div>
                </div>
                <!-- Final de datos del médico especialista en trasplante -->

                <!-- Datos del médico cirujano de trasplante -->
                <div v-if="record.have_healthcare_center">
                    <h3 class="h5 text-secondary text-uppercase">
                        Datos del médico cirujano de trasplante
                    </h3>
                    <hr />
                </div>
                <div class="row pb-4" v-if="record.have_healthcare_center">
                    <div class="col-12 col-lg-4 mb-3">
                        <label
                            for="doctor_transplant_surgeon_names"
                            class="form-label"
                        >
                            Nombres
                        </label>
                        <input
                            id="doctorNames"
                            class="form-control"
                            name="doctor_transplant_surgeon_names"
                            v-model="record.doctor_transplant_surgeon_names"
                            type="text"
                            placeholder="Nombres"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="doctorLastname" class="form-label">
                            Apellidos
                        </label>
                        <input
                            id="doctorLastname"
                            class="form-control"
                            name="doctor_transplant_surgeon_lastname"
                            type="text"
                            v-model="record.doctor_transplant_surgeon_lastname"
                            placeholder="Apellidos"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="doctormail" class="form-label">
                            Correo electrónico
                        </label>
                        <input
                            id="doctormail"
                            class="form-control"
                            name="doctor_transplant_surgeon_mail"
                            type="email"
                            v-model="record.doctor_transplant_surgeon_mail"
                            placeholder="Correo electrónico"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="doctorCellPhone" class="form-label">
                            Teléfono móvil
                        </label>
                        <input
                            id="doctorTransplantCellPhone"
                            class="form-control"
                            name="doctor_transplant_surgeon_cellphone"
                            type="text"
                            v-model="record.doctor_transplant_surgeon_cellphone"
                            placeholder="Teléfono móvil"
                            maxlength="11"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="doctorPhone" class="form-label">
                            Teléfono local
                        </label>
                        <input
                            id="doctorTransplantPhone"
                            class="form-control"
                            name="doctor_transplant_surgeon_phone"
                            type="text"
                            v-model="record.doctor_transplant_surgeon_phone"
                            placeholder="Teléfono"
                            maxlength="11"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        />
                    </div>
                </div>
                <!-- Final de datos del médico cirujano de trasplante -->

                <!-- Cita medica asignada -->
                <div v-if="record.citation_information.medical_citation_date">
                    <h3 class="h5 text-secondary text-uppercase">
                        Cita médica asignada
                    </h3>
                    <hr />
                    <div class="row pb-4">
                        <!-- Fecha de la cita -->
                        <div class="col-12 col-lg-2">
                            <label for="date" class="form-label">
                                Fecha de la cita
                            </label>
                            <input
                                id="date"
                                class="form-control"
                                type="date"
                                name="date"
                                readonly
                                disabled
                                v-model="
                                    record.citation_information
                                        .medical_citation_date
                                "
                            />
                        </div>
                        <!-- Fin de fecha de la cita -->
                        <!-- Hora de la cita -->
                        <div class="col-12 col-lg-2">
                            <label for="citation_time" class="form-label">
                                Hora de la cita
                            </label>
                            <input
                                id="citation_time"
                                class="form-control"
                                name="citation_time"
                                type="time"
                                readonly
                                disabled
                                tabindex="13"
                                v-model="
                                    record.citation_information
                                        .medical_citation_time
                                "
                            />
                        </div>
                        <!-- Fin de hora de la cita -->
                        <div class="col-12 col-lg-4">
                            <label for="healthcareCenter" class="form-label">
                                Centro de trasplante
                            </label>
                            <v-select
                                id="healthcareCenter"
                                disabled
                                v-model="
                                    record.citation_information
                                        .medical_citation_healthcare_center
                                "
                                label="name"
                                placeholder="-- Seleccione --"
                                class="v-selectDisabled"
                                :options="healthcare_centers"
                                :reduce="
                                    healthcare_center => healthcare_center.id
                                "
                                :selectable="option => option.id !== ''"
                                :tabindex="6"
                            >
                                <slot name="no-options">Sin registros.</slot>
                            </v-select>
                        </div>
                        <!--  Asistencia -->
                        <div class="col-12 col-lg-2">
                            <label for="donor" class="form-label">
                                ¿Asistió a la cita?
                            </label>
                            <select
                                id="donar"
                                class="form-select"
                                tabindex="9"
                                v-model="
                                    record.citation_information
                                        .medical_citation_assistance
                                "
                            >
                                <option value="false">No</option>
                                <option value="true">Sí</option>
                            </select>
                        </div>
                        <!-- Fin Asistencia -->
                    </div>
                    <!-- Observación -->
                    <div class="row pb-2">
                        <div class="col-12 col-lg-12 mb-3">
                            <label for="Observación" class="form-label">
                                Observación del solicitante
                            </label>
                            <textarea
                                id="Observación"
                                class="form-control"
                                name="Observación"
                                cols="20"
                                rows="5"
                                v-model="
                                    record.citation_information
                                        .medical_citation_observation
                                "
                            ></textarea>
                        </div>
                    </div>
                    <!-- fin de Observación -->
                </div>
                <!-- Fin de cita medica asignada  -->
            </div>
            <!-- Final de card-body -->

            <!-- Inicio de card-footer -->
            <div class="card-footer text-center">
                <div v-if="aplicants.length > 0">
                    <button
                        v-if="record.status == 1"
                        class="btn btn-outline-primary-dark"
                        type="reset"
                        @click="reset()"
                    >
                        Limpiar
                    </button>
                    <button
                        v-if="record.status == 1"
                        class="btn btn-primary"
                        type="button"
                        @click="setRecord('manage-aplicant')"
                    >
                        <i class="fa fa-save"></i>
                        Actualizar
                    </button>
                </div>
                <div v-else>
                    <button
                        class="btn btn-outline-primary-dark"
                        type="reset"
                        @click="reset()"
                    >
                        Limpiar
                    </button>
                    <button
                        class="btn btn-primary"
                        type="button"
                        @click="setRecord('manage-aplicant')"
                    >
                        <i class="fa fa-save"></i>
                        Guardar
                    </button>
                </div>
            </div>
            <!-- Final de card-footer -->
        </div>
        <!-- Final de formulario de solicitud de trasplante -->

        <!-- Tabla con los datos de la solicitud de trasplante -->
        <div
            v-if="record.number"
            id="request"
            class="card mb-4 shadow-sm table-responsive-md"
        >
            <h2
                class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary"
            >
                <i class="fa fa-clipboard"></i> Solicitud de trasplante
            </h2>
            <div class="card-body">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Serial N°</th>
                            <th scope="col">Fecha de registro</th>
                            <th scope="col">Nombres y Apellidos</th>
                            <th scope="col">Cédula</th>
                            <th scope="col">Estatus</th>
                            <th scope="col">Observaciones</th>
                            <th class="text-center" scope="col">Acción</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="aplicant in aplicants">
                            <td>{{ aplicant.number }}</td>
                            <td>{{ convertDate(aplicant.created_at) }}</td>
                            <td>
                                {{
                                    aplicant.profile.names +
                                        " " +
                                        aplicant.profile.surnames
                                }}
                            </td>
                            <td>{{ aplicant.profile.dni }}</td>
                            <td v-if="aplicant.status == 1">Enviada</td>
                            <td v-if="aplicant.status == 2">Procesada</td>
                            <td v-if="aplicant.status == 3">
                                En espera de suero
                            </td>
                            <td v-if="aplicant.status == 4">Lista de espera</td>
                            <td v-if="aplicant.status == 5">Lista de espera</td>
                            <td v-if="aplicant.status == 6">Rechazada</td>
                            <td v-if="aplicant.status == 7">
                                Trasplante realizado
                            </td>
                            <td class="text-justify">
                                {{
                                    aplicant.observation_request
                                        ? aplicant.observation_request
                                        : ""
                                }}
                            </td>
                            <td class="text-center">
                                <button
                                    class="btn btn-outline-primary-dark"
                                    type="button"
                                    title="Ver registro"
                                    @click="showInfo(aplicant.id)"
                                >
                                    <i class="fa fa-eye"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- Final de tabla con los datos de la solicitud de trasplante -->

        <!-- Modal -->
        <div
            id="show_transplant_request"
            class="modal fade"
            tabindex="-1"
            role="dialog"
        >
            <!-- modal-dialog -->
            <div
                class="modal-dialog modal-dialog-centered modal-xl"
                role="document"
            >
                <!-- modal-content -->
                <div class="modal-content">
                    <!-- modal-header -->
                    <div class="modal-header">
                        <h4>
                            Información detallada de la Solicitud de Trasplante
                        </h4>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <!-- Final de modal-header -->

                    <!-- modal-body -->
                    <div class="modal-body">
                        <div class="row pb-4">
                            <!-- Fecha de registro -->
                            <div class="col-12 col-lg-2 mb-3">
                                <label for="created_at" class="form-label">
                                    Fecha de registro
                                </label>
                                <input
                                    id="created_at"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="
                                        new Date(
                                            modalQuery.created_at
                                        ).toLocaleDateString('en-GB')
                                    "
                                />
                            </div>

                            <!-- Serial N° -->
                            <div class="col-12 col-lg-2 mb-3">
                                <label for="number" class="form-label">
                                    Serial N°
                                </label>
                                <input
                                    id="number"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="modalQuery.number"
                                />
                            </div>

                            <!-- Estatus -->
                            <div class="col-12 col-lg-2 mb-3">
                                <label for="status" class="form-label">
                                    Estatus
                                </label>
                                <input
                                    id="status"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="
                                        modalQuery.status == 1
                                        ? 'Enviada'
                                        : modalQuery.status == 2
                                        ? 'Procesada'
                                        : modalQuery.status == 3
                                        ? 'En espera de suero'
                                        : modalQuery.status == 4
                                        ? 'Lista de espera'
                                        : modalQuery.status == 5
                                        ? 'Lista de espera'
                                        : modalQuery.status == 6
                                        ? 'Rechazada'
                                        : modalQuery.status == 7
                                        ? 'Trasplante realizado'
                                        : 'Sin estatus'
                                    "
                                />
                            </div>
                        </div>

                        <!-- Datos personales -->
                        <h3 class="h5 text-secondary text-uppercase">
                            Datos personales
                        </h3>
                        <hr />
                        <div
                            class="row pb-4"
                            v-if="modalQuery && modalQuery.profile"
                        >
                            <!-- Nombres -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="names" class="form-label">
                                    Nombres
                                </label>
                                <input
                                    id="names"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="modalQuery.profile.names"
                                />
                            </div>
                            <!-- Apellidos -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="surnames" class="form-label">
                                    Apellidos
                                </label>
                                <input
                                    id="surnames"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="modalQuery.profile.surnames"
                                />
                            </div>
                            <!-- Número de cédula de identidad -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="dni" class="form-label">
                                    Número de cédula de identidad
                                </label>
                                <input
                                    id="dni"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="modalQuery.profile.dni"
                                />
                            </div>
                            <!-- Documento de la cédula de identidad -->
                            <div
                                class="col-12 col-lg-4 mb-3"
                                v-if="modalQuery.url_dni"
                            >
                                <label for="idFile" class="form-label">
                                    Documento de la cédula de identidad
                                </label>
                                <br />
                                <button v-if="modalQuery.url_dni" class="btn btn-outline-primary-dark"
                                    title="Descarga el archivo previamente subido" @click="
                                        downloadFile(
                                            modalQuery.url_dni,
                                            'Documento de la cédula de identidad'
                                        )
                                    "
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>
                            <!-- Documento de la foto actualizada -->
                            <div
                                class="col-12 col-lg-4 mb-3"
                                v-if="modalQuery.updated_photo"
                            >
                                <label
                                    for="updated_photo_file"
                                    class="form-label"
                                >
                                    Documento de la foto actualizada
                                </label>
                                <br />
                                <button class="btn btn-outline-primary-dark" title="Descarga el archivo previamente subido"
                                    @click="
                                        downloadFile(
                                            modalQuery.updated_photo,
                                            'Documento de la foto actualizada'
                                        )
                                    "
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>
                            <!-- Nacionalidad -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="nationality" class="form-label">
                                    Nacionalidad
                                </label>
                                <input
                                    id="nationality"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="
                                        modalQuery.profile.nationality &&
                                        nationalityOptions.filter(x => {
                                            return (
                                                x.id ==
                                                modalQuery.profile.nationality
                                            );
                                        })[0]
                                        ? nationalityOptions.filter(x => {
                                        return (
                                        x.id ==
                                        modalQuery.profile.nationality
                                        );
                                        })[0].text
                                        : ''
                                    "
                                />
                            </div>
                            <!-- Fecha de nacimiento -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="birthdate" class="form-label">
                                    Fecha de nacimiento
                                </label>
                                <input
                                    id="birthdate"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="
                                        new Date(
                                            modalQuery.profile.birthdate
                                        ).toLocaleDateString('en-GB', {
                                            timeZone: 'UTC'
                                        })
                                    "
                                />
                            </div>
                            <!-- Edad -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="age" class="form-label">
                                    Edad
                                </label>
                                <input
                                    id="age"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="profile.age"
                                />
                            </div>
                            <!-- Lugar de nacimiento -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="birthplace" class="form-label">
                                    Lugar de nacimiento
                                </label>
                                <input
                                    id="birthplace"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="modalQuery.profile.birthplace"
                                />
                            </div>
                            <!-- Género -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="gender" class="form-label">
                                    Género
                                </label>
                                <input
                                    id="gender"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="
                                        modalQuery.profile.gender &&
                                        genderOptions.filter(x => {
                                            return (
                                                x.id ==
                                                modalQuery.profile.gender
                                            );
                                        })[0]
                                        ? genderOptions.filter(x => {
                                        return (
                                            x.id ==
                                            modalQuery.profile.gender
                                        );
                                        })[0].text
                                        : ''
                                    "
                                />
                            </div>
                            <!-- Ocupación -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="ocupation" class="form-label">
                                    Ocupación
                                </label>
                                <input
                                    id="gender"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="
                                        modalQuery.profile.ocupation &&
                                        occupations.filter(x => {
                                            return (
                                                x.id ==
                                                parseInt(
                                                    modalQuery.profile.ocupation
                                                )
                                            );
                                        })[0]
                                        ? occupations.filter(x => {
                                        return (
                                        x.id ==
                                        parseInt(modalQuery.profile.ocupation));
                                        })[0].name
                                        : ''
                                    "
                                />
                            </div>
                            <!-- Estado civil -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="marital_status" class="form-label">
                                    Estado civil
                                </label>
                                <input
                                    id="gender"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="
                                        modalQuery.profile.marital_status &&
                                        maritalStatusOptions.filter(x => {
                                            return (
                                                x.id ==
                                                modalQuery.profile
                                                    .marital_status
                                            );
                                        })[0]
                                        ? maritalStatusOptions.filter(x => {
                                        return (
                                        x.id ==
                                        modalQuery.profile.marital_status);
                                        })[0].text
                                        : ''
                                    "
                                />
                            </div>
                            <!-- Nivel académico -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="academic_levels" class="form-label">
                                    Nivel académico
                                </label>
                                <input
                                    id="gender"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="modalQuery.profile.academic_level"
                                />
                            </div>
                        </div>
                        <!-- Final de datos personales -->

                        <!-- Dirección -->
                        <h3 class="h5 text-secondary text-uppercase">
                            Dirección
                        </h3>
                        <hr />
                        <div class="row pb-4">
                            <!-- Estado -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="estate" class="form-label">
                                    Estado
                                </label>
                                <input
                                    id="estate"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="location.estate"
                                />
                            </div>
                            <!-- Municipio -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="municipality" class="form-label">
                                    Municipio
                                </label>
                                <input
                                    id="municipality"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="location.municipality"
                                />
                            </div>
                            <!-- Parroquia -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="parish" class="form-label">
                                    Parroquia
                                </label>
                                <input
                                    id="parish"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="location.parish"
                                />
                            </div>
                            <!-- Dirección -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="adress" class="form-label">
                                    Dirección
                                </label>
                                <textarea
                                    id="adress"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    rows="4"
                                    >{{ modalQuery.adress }}</textarea
                                >
                            </div>
                        </div>
                        <!-- Final de dirección -->

                        <!-- Contacto -->
                        <h3 class="h5 text-secondary text-uppercase">
                            Contacto
                        </h3>
                        <hr />
                        <div class="row pb-4">
                            <!-- Teléfono local -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="phone" class="form-label">
                                    Teléfono local
                                </label>
                                <input
                                    id="phone"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="profile.phone"
                                />
                            </div>
                            <!-- Teléfono móvil -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="movil" class="form-label">
                                    Teléfono móvil
                                </label>
                                <input
                                    id="movil"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="profile.movil"
                                />
                            </div>
                            <!-- Correo electrónico -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="email" class="form-label">
                                    Correo electrónico
                                </label>
                                <input
                                    id="email"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="user.email"
                                />
                            </div>
                        </div>
                        <!-- Final de Contacto -->

                        <!-- Datos del Trasplante -->
                        <h3 class="h5 text-secondary text-uppercase">
                            Datos del Trasplante
                        </h3>
                        <hr />
                        <div class="row pb-4">
                            <!-- Tipo de trasplante -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="transplant_id" class="form-label">
                                    Tipo de trasplante
                                </label>
                                <input
                                    id="transplant_id"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="
                                        getTransplantType(
                                            modalQuery.transplant_id
                                        )
                                    "
                                />
                            </div>
                            <!-- Grupo sanguíneo -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label
                                    for="patient_blood_type"
                                    class="form-label"
                                >
                                    Grupo sanguíneo
                                </label>
                                <input
                                    id="patient_blood_type"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="
                                        getBloodType(
                                            parseInt(
                                                modalQuery.patient_blood_type
                                            )
                                        )
                                    "
                                />
                            </div>
                            <!-- Factor RH -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="rhFactor" class="form-label">
                                    Factor RH
                                </label>
                                <input
                                    id="rhFactor"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="
                                        rhFactorOptions.filter(
                                            x => x.id == modalQuery.rh_factor
                                        )[0]
                                        ? rhFactorOptions.filter(
                                        x =>
                                        x.id ==
                                        modalQuery.rh_factor
                                        )[0].text
                                        : ''
                                    "
                                />
                            </div>
                            <!-- ¿Posee Centro de trasplante? -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="donor" class="form-label">
                                    ¿Posee Centro de trasplante?
                                </label>
                                <input
                                    id="transplant_id"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="
                                        modalQuery.healthcare_center
                                            ? 'Si'
                                            : 'No'
                                    "
                                />
                            </div>
                            <!-- Centro de trasplante -->
                            <div
                                class="col-12 col-lg-4 mb-3"
                                v-if="modalQuery.healthcare_center"
                            >
                                <label
                                    for="healthcare_center"
                                    class="form-label"
                                >
                                    Centro de trasplante
                                </label>
                                <input
                                    id="healthcare_center"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="
                                        getHealthcareCenter(
                                            modalQuery.healthcare_center
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <div class="row">
                            <!-- Acta de nacimiento -->
                            <div
                                class="col-12 col-lg-4 mb-3"
                                v-if="modalQuery.url_born_file"
                            >
                                <label for="born_file" class="form-label">
                                    Acta de nacimiento
                                </label>
                                <br />
                                <button
                                    v-if="modalQuery.url_born_file"
                                    class="btn btn-outline-primary-dark"
                                    title="Descarga el archivo previamente subido"
                                    @click="
                                        downloadFile(
                                            modalQuery.url_born_file,
                                            'Acta de nacimiento'
                                        )
                                    "
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>

                            <!-- Informe médico actualizado -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="report_file" class="form-label">
                                    Informe médico actualizado
                                </label>
                                <br />
                                <button
                                    v-if="modalQuery.url_medic_report_file"
                                    class="btn btn-outline-primary-dark"
                                    title="Descarga el archivo previamente subido"
                                    @click="
                                        downloadFile(
                                            modalQuery.url_medic_report_file,
                                            'Informe médico actualizado'
                                        )
                                    "
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>

                            <!-- Documento del tipo de sangre -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="bloodType" class="form-label">
                                    Documento del tipo de sangre
                                </label>
                                <br />
                                <button
                                    v-if="modalQuery.url_blood_type_file"
                                    class="btn btn-outline-primary-dark"
                                    title="Descarga el archivo previamente subido"
                                    @click="
                                        downloadFile(
                                            modalQuery.url_blood_type_file,
                                            'Documento del tipo de sangre'
                                        )
                                    "
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>

                            <!-- Resultado de prueba HLA -->
                            <div
                                class="col-12 col-lg-4 mb-3"
                                v-if="
                                    modalQuery.transplant_id == 31 &&
                                        modalQuery.url_hla_results_file
                                "
                            >
                                <label for="hlaResultsFile" class="form-label">
                                    Resultado de prueba HLA
                                </label>
                                <br />
                                <button
                                    v-if="modalQuery.url_hla_results_file"
                                    class="btn btn-outline-primary-dark"
                                    title="Descarga el archivo previamente subido"
                                    @click="
                                        downloadFile(
                                            modalQuery.url_hla_results_file,
                                            'Resultado de prueba HLA'
                                        )
                                    "
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>
                        </div>
                        <div class="row pb-4">
                            <!-- Diagnóstico -->
                            <div class="row pb-2">
                                <div class="col-12 col-lg-12 mb-3">
                                    <label for="diagnosis" class="form-label">
                                        Diagnóstico
                                    </label>
                                    <textarea
                                        id="diagnosis"
                                        class="form-control"
                                        name="diagnosis"
                                        cols="20"
                                        rows="5"
                                        disabled
                                        v-model="modalQuery.diagnosis"
                                    ></textarea>
                                </div>
                            </div>
                            <!-- ¿Posee posible donante vivo? -->
                            <div
                                class="col-12 col-lg-4 mb-3"
                                v-if="
                                    modalQuery.transplant_id != 30 &&
                                        modalQuery.transplant_id != 32 &&
                                        modalQuery.transplant_id != 33 &&
                                        modalQuery.transplant_id != 34 &&
                                        modalQuery.transplant_id != 35
                                "
                            >
                                <label for="donor" class="form-label">
                                    ¿Posee posible donante vivo?
                                </label>
                                <input
                                    id="healthcare_center"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="
                                        modalQuery.living_donor &&
                                        modalQuery.living_donor != []
                                            ? 'Si'
                                            : 'No'
                                    "
                                />
                            </div>
                            <!-- ¿Posee Padres? -->
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="dads" class="form-label">
                                    ¿Posee Padres?
                                </label>
                                <input
                                    id="healthcare_center"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="
                                        modalQuery.mon_names ||
                                        modalQuery.dad_names
                                            ? 'Si'
                                            : 'No'
                                    "
                                />
                            </div>
                        </div>
                        <!-- Final de datos del trasplante -->

                        <!-- Datos del donante vivo -->
                        <div
                            v-if="
                                modalQuery.living_donor &&
                                    modalQuery.living_donor != []
                            "
                        >
                            <h3 class="h5 text-secondary text-uppercase">
                                Datos del donante vivo
                            </h3>
                            <hr />
                        </div>
                        <div
                            v-if="modalQuery.living_donor != []"
                            class="accordion mb-3"
                            id="accordionPanelsStayOpenExample"
                        >
                            <div
                                class="accordion-item"
                                v-for="(donor_array,
                                index) in modalQuery.living_donor"
                            >
                                <h2
                                    class="accordion-header"
                                    :id="'panelsStayOpen-heading' + index"
                                >
                                    <button
                                        class="accordion-button h5 text-secondary text-uppercase"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        :data-bs-target="
                                            '#panelsStayOpen-collapse' + index
                                        "
                                        aria-expanded="true"
                                        :aria-controls="
                                            'panelsStayOpen-collapse' + index
                                        "
                                    >
                                        Datos del donante vivo {{ index + 1 }}
                                    </button>
                                </h2>
                                <div
                                    :id="'panelsStayOpen-collapse' + index"
                                    class="accordion-collapse collapse show"
                                    :aria-labelledby="
                                        'panelsStayOpen-heading' + index
                                    "
                                >
                                    <div class="accordion-body row pb-4">
                                        <!-- Nombres del donante vivo -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="donarNames"
                                                class="form-label"
                                            >
                                                Nombres
                                            </label>
                                            <input
                                                id="donorNames"
                                                class="form-control"
                                                name="donor_names"
                                                disabled
                                                v-model="
                                                    donor_array.donor_names
                                                "
                                                type="text"
                                                placeholder="Nombres"
                                            />
                                        </div>
                                        <!-- Apellidos del donante vivo -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="donorLastname"
                                                class="form-label"
                                            >
                                                Apellidos
                                            </label>
                                            <input
                                                id="donorLastname"
                                                class="form-control"
                                                name="donor_lastname"
                                                disabled
                                                v-model="
                                                    donor_array.donor_lastname
                                                "
                                                type="text"
                                                placeholder="Apellidos"
                                            />
                                        </div>
                                        <!-- Fecha de nacimiento del donante vivo -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="donorBirthday"
                                                class="form-label"
                                            >
                                                Fecha de nacimiento
                                            </label>
                                            <input
                                                tabindex="-1"
                                                :id="'donorBirthday_' + index"
                                                class="form-control"
                                                name="donor_birthday"
                                                disabled
                                                v-model="
                                                    donor_array.donor_birthday
                                                "
                                                type="date"
                                            />
                                        </div>
                                        <!-- Edad del donante vivo -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label for="age" class="form-label">
                                                Edad
                                            </label>
                                            <input
                                                :id="'donor_age_' + index"
                                                class="form-control"
                                                type="text"
                                                name="donor_age"
                                                min="1"
                                                max="3"
                                                placeholder="0"
                                                disabled
                                                tabindex="-1"
                                                v-model="donor_array.donor_age"
                                            />
                                        </div>
                                        <!-- Lugar de nacimiento del donante vivo -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="placeOfBirth"
                                                class="form-label"
                                            >
                                                Lugar de nacimiento
                                            </label>
                                            <input
                                                id="placeOfBirth"
                                                class="form-control"
                                                type="text"
                                                name="place_of_birth"
                                                disabled
                                                placeholder="Lugar de nacimiento"
                                                v-model="
                                                    donor_array.donor_place_of_birth
                                                "
                                            />
                                        </div>
                                        <!-- Nacionalidad del donante vivo -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="nationality"
                                                class="form-label"
                                            >
                                                Nacionalidad
                                            </label>
                                            <input
                                                id="nationality"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    donor_array.donor_nationality ==
                                                    'V'
                                                        ? 'Venezolano(a)'
                                                        : 'Extranjero(a)'
                                                "
                                            />
                                        </div>
                                        <!-- Género del donante vivo -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="gender"
                                                class="form-label"
                                            >
                                                Género
                                            </label>
                                            <input
                                                id="gender"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    showGender(
                                                        donor_array.donor_gender
                                                    )
                                                "
                                            />
                                        </div>
                                        <!-- Estado civil del donante vivo -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="marital_status"
                                                class="form-label"
                                            >
                                                Estado civil
                                            </label>
                                            <input
                                                id="marital_status"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    showMaritalStatus(
                                                        donor_array.donor_marital_status
                                                    )
                                                "
                                            />
                                        </div>
                                        <!-- Ocupación del donante vivo -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="ocupation"
                                                class="form-label"
                                            >
                                                Ocupación
                                            </label>
                                            <input
                                                id="ocupation"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    occupations.filter(
                                                        x =>
                                                            x.id ==
                                                            donor_array.donor_ocupation
                                                    )[0].name
                                                "
                                            />
                                        </div>
                                        <!-- Nivel académico del donante vivo -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="academic_levels"
                                                class="form-label"
                                            >
                                                Nivel académico
                                            </label>
                                            <input
                                                id="academic_level"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    donor_array.donor_academic_level
                                                "
                                            />
                                        </div>
                                        <!-- Grupo sanguíneo -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="donor_blood"
                                                class="form-label"
                                            >
                                                Grupo sanguíneo
                                            </label>
                                            <input
                                                id="donor_blood"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    blood_types.filter(
                                                        x =>
                                                            x.id ==
                                                            donor_array.donor_blood
                                                    )[0].name
                                                "
                                            />
                                        </div>
                                        <!-- Factor RH del donante vivo -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="rhFactor"
                                                class="form-label"
                                            >
                                                Factor RH
                                            </label>
                                            <input
                                                id="donor_rh_factor"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    rhFactorOptions.filter(
                                                        x =>
                                                            x.id ==
                                                            donor_array.donor_rh_factor
                                                    )[0].text
                                                "
                                            />
                                        </div>
                                        <!-- Número de cédula de identidad del donante vivo -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="idNumber"
                                                class="form-label"
                                            >
                                                Número cédula de identidad
                                            </label>
                                            <input
                                                id="donor_idNumber"
                                                class="form-control"
                                                type="text"
                                                name="donor_id_number"
                                                minlength="7"
                                                maxlength="9"
                                                disabled
                                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                placeholder="Número de cédula de identidad"
                                                v-model="donor_array.donor_dni"
                                            />
                                        </div>
                                        <!-- Documento de la cédula de identidad del donante -->
                                        <div
                                            v-if="
                                                donor_array.url_donor_dni_file
                                            "
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="donorIdFile"
                                                class="form-label"
                                            >
                                                Documento de la cédula de
                                                identidad
                                            </label>
                                            <button
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_donor_dni_file,
                                                        'Documento de la cédula de identidad del donante'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!-- Documento del tipo de sangre del donante vivo -->
                                        <div
                                            v-if="
                                                donor_array.url_donor_blood_type_file
                                            "
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="donorBloodType"
                                                class="form-label"
                                            >
                                                Documento del tipo de sangre
                                            </label>
                                            <br />
                                            <button
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_donor_blood_type_file,
                                                        'Documento del tipo de sangre del donante vivo'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!-- Teléfono móvil del donante vivo -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="donor_cellphone"
                                                class="form-label"
                                            >
                                                Teléfono móvil
                                            </label>
                                            <input
                                                id="donor_cellphone"
                                                class="form-control"
                                                name="doctor_cellphone"
                                                type="text"
                                                v-model="
                                                    donor_array.donor_cellphone
                                                "
                                                placeholder="Teléfono móvil"
                                                maxlength="11"
                                                disabled
                                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                            />
                                        </div>
                                        <!-- Teléfono local del donante vivo -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="donor_phone"
                                                class="form-label"
                                            >
                                                Teléfono local
                                            </label>
                                            <input
                                                id="donor_phone"
                                                class="form-control"
                                                name="doctor_phone"
                                                type="text"
                                                v-model="
                                                    donor_array.donor_phone
                                                "
                                                placeholder="Teléfono local"
                                                maxlength="11"
                                                disabled
                                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                            />
                                        </div>
                                        <!-- Correo electrónico del donante vivo -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="donor_email"
                                                class="form-label"
                                            >
                                                Correo electrónico
                                            </label>
                                            <input
                                                id="donor_email"
                                                class="form-control"
                                                type="email"
                                                placeholder="Correo electrónico"
                                                tabindex="5"
                                                disabled
                                                v-model="
                                                    donor_array.donor_email
                                                "
                                            />
                                        </div>
                                        <!-- Parentesco del donante vivo -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="relationship"
                                                class="form-label"
                                            >
                                                Parentesco
                                            </label>
                                            <input
                                                id="donor_blood"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    relationships.filter(
                                                        x =>
                                                            x.id ==
                                                            donor_array.donor_relationship
                                                    )[0]
                                                    ? relationships.filter(
                                                    x =>
                                                    x.id ==
                                                    donor_array.donor_relationship
                                                    )[0].name
                                                    : ''
                                                "
                                            />
                                        </div>
                                        <!-- Acta de nacimiento del donante vivo -->
                                        <div
                                            v-if="
                                                donor_array.donor_relationship != 1 &&
                                                donor_array.donor_relationship != 2 &&
                                                donor_array.donor_relationship != 5 &&
                                                donor_array.url_donor_born_file
                                            "
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="donorFile"
                                                class="form-label"
                                            >
                                                Acta de nacimiento
                                            </label>
                                            <br />
                                            <button
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_donor_born_file,
                                                        'Acta de nacimiento del donante vivo'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!-- Documento de la cédula de identidad del padre -->
                                        <div
                                            v-if="
                                                (donor_array.donor_relationship == 6 ||
                                                donor_array.donor_relationship == 8 ||
                                                donor_array.donor_relationship == 10 ||
                                                donor_array.donor_relationship == 11) &&
                                                donor_array.url_donor_dni_dad_file
                                            "
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="donorFile"
                                                class="form-label"
                                            >
                                                Documento de la cédula de identidad del padre
                                            </label>
                                            <br />
                                            <button
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_donor_dni_dad_file,
                                                        'Documento de la cédula de identidad del padre'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!-- Acta de nacimiento del padre -->
                                        <div
                                            v-if="
                                                (donor_array.donor_relationship == 6 ||
                                                donor_array.donor_relationship == 8 ||
                                                donor_array.donor_relationship == 10 ||
                                                donor_array.donor_relationship == 11) &&
                                                donor_array.url_donor_birth_certificate_dad_file
                                            "
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="donorFile"
                                                class="form-label"
                                            >
                                                Acta de nacimiento del padre
                                            </label>
                                            <br />
                                            <button
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_donor_birth_certificate_dad_file,
                                                        'Acta de nacimiento del padre'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!-- Documento de la cédula de identidad de la madre -->
                                        <div
                                            v-if="
                                                (donor_array.donor_relationship == 6 ||
                                                donor_array.donor_relationship == 8 ||
                                                donor_array.donor_relationship == 10 ||
                                                donor_array.donor_relationship == 11) &&
                                                donor_array.url_donor_dni_mother_file
                                            "
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="donorFile"
                                                class="form-label"
                                            >
                                                Documento de la cédula de identidad de la madre
                                            </label>
                                            <br />
                                            <button
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_donor_dni_mother_file,
                                                        'Documento de la cédula de identidad de la madre'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!-- Acta de nacimiento de la madre -->
                                        <div
                                            v-if="
                                                (donor_array.donor_relationship == 6 ||
                                                donor_array.donor_relationship == 8 ||
                                                donor_array.donor_relationship == 9 ||
                                                donor_array.donor_relationship == 10 ||
                                                donor_array.donor_relationship == 11) &&
                                                donor_array.url_donor_birth_certificate_mother_file
                                            "
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="donorFile"
                                                class="form-label"
                                            >
                                                Acta de nacimiento de la madre
                                            </label>
                                            <br />
                                            <button
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_donor_birth_certificate_mother_file,
                                                        'Acta de nacimiento de la madre'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!-- Documento de la cédula de identidad del abuelo en común -->
                                        <div
                                            v-if="
                                                (donor_array.donor_relationship == 8 ||
                                                donor_array.donor_relationship == 9 ||
                                                donor_array.donor_relationship == 10 ||
                                                donor_array.donor_relationship == 11) &&
                                                donor_array.url_donor_dni_grandfather_file
                                            "
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="donorFile"
                                                class="form-label"
                                            >
                                                Documento de la cédula de identidad del abuelo en común
                                            </label>
                                            <br />
                                            <button
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_donor_dni_grandfather_file,
                                                        'Documento de la cédula de identidad del abuelo en común'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!-- Acta de nacimiento del abuelo en común -->
                                        <div
                                            v-if="
                                                (donor_array.donor_relationship == 9 ||
                                                donor_array.donor_relationship == 10) &&
                                                donor_array.url_donor_birth_certificate_grandfather_file
                                            "
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="donorFile"
                                                class="form-label"
                                            >
                                            Acta de nacimiento del abuelo en común
                                            </label>
                                            <br />
                                            <button
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_donor_birth_certificate_grandfather_file,
                                                        'Acta de nacimiento del abuelo en común'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!-- Documento de la cédula de identidad de la abuela en común -->
                                        <div
                                            v-if="
                                                (donor_array.donor_relationship == 8 ||
                                                donor_array.donor_relationship == 9 ||
                                                donor_array.donor_relationship == 10 ||
                                                donor_array.donor_relationship == 11) &&
                                                donor_array.url_donor_dni_grandmother_file
                                            "
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="donorFile"
                                                class="form-label"
                                            >
                                                Documento de la cédula de identidad de la abuela en común
                                            </label>
                                            <br />
                                            <button
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_donor_dni_grandmother_file,
                                                        'Documento de la cédula de identidad de la abuela en común'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!-- Acta de nacimiento de la abuela en común -->
                                        <div
                                            v-if="
                                                (donor_array.donor_relationship == 9 ||
                                                donor_array.donor_relationship == 10) &&
                                                donor_array.url_donor_birth_certificate_grandmother_file
                                            "
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="donorFile"
                                                class="form-label"
                                            >
                                            Acta de nacimiento de la abuela en común
                                            </label>
                                            <br />
                                            <button
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_donor_birth_certificate_grandmother_file,
                                                        'Acta de nacimiento de la abuela en común'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!-- Acta de concubinato del donante vivo -->
                                        <div
                                            v-if="
                                                getRelationship(index) ===
                                                    'concubinage' &&
                                                    donor_array.url_concubinage_file
                                            "
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="donorFile"
                                                class="form-label"
                                            >
                                                Acta de concubinato
                                            </label>
                                            <br />
                                            <button
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_concubinage_file,
                                                        'Acta de concubinato del donante vivo'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!-- Acta de matrimonio del donante vivo -->
                                        <div
                                            v-if="
                                                getRelationship(index) ===
                                                    'marriage' &&
                                                    donor_array.url_marriage_file
                                            "
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="donorFile"
                                                class="form-label"
                                            >
                                                Acta de matrimonio
                                            </label>
                                            <br />
                                            <button
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_marriage_file,
                                                        'Acta de matrimonio del donante vivo'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!-- Certificado de adopción del donante vivo -->
                                        <div
                                            v-if="
                                                getRelationship(index) ===
                                                    'adoption' &&
                                                    donor_array.url_adoption_file
                                            "
                                            class="col-12 col-sm-2 mb-3 mt-lg-4 pt-lg-2"
                                        >
                                            <label
                                                for="adoptionFile"
                                                class="form-label"
                                            >
                                                Certificado de adopción
                                            </label>
                                            <br />
                                            <button
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_adoption_file,
                                                        'Certificado de adopción del donante vivo'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!--documento Cedula del Padre -->
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                            v-if="
                                                donor_array.donor_relationship ==
                                                    4 &&
                                                    donor_array.url_donor_dni_dad_file
                                            "
                                        >
                                            <label
                                                for="donorIdFile"
                                                class="form-label"
                                            >
                                                Documento de la cédula de
                                                identidad del Padre
                                            </label>
                                            <br />
                                            <button
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_donor_dni_dad_file,
                                                        'Documento de la cédula de identidad del padre del donante'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!-- fin documento Cedula del Padre -->
                                        <!--documento Cedula del madre -->
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                            v-if="
                                                donor_array.donor_relationship ==
                                                    4 &&
                                                    donor_array.url_donor_dni_mother_file
                                            "
                                        >
                                            <label
                                                for="donorIdFile"
                                                class="form-label"
                                            >
                                                Documento de la cédula de
                                                identidad de la Madre
                                            </label>
                                            <br />
                                            <button
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        donor_array.url_donor_dni_mother_file,
                                                        'Documento de la cédula de identidad de la madre del donante vivo'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!-- fin documento Cedula del madre -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Final de datos del donante vivo -->

                        <!-- Datos de los padres -->
                        <!-- Datos del Padre  -->
                        <div
                            v-if="
                                modalQuery.dad_names || modalQuery.dad_lastname
                            "
                        >
                            <h3 class="h5 text-secondary text-uppercase">
                                Datos del Padre
                            </h3>
                            <hr />
                            <div class="row pb-4">
                                <!-- Nombres del Padre -->
                                <div
                                    class="col-12 col-lg-4 mb-3"
                                    v-if="modalQuery.dad_names"
                                >
                                    <label for="dad_names" class="form-label">
                                        Nombres
                                    </label>
                                    <input
                                        id="dad_names"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="modalQuery.dad_names"
                                    />
                                </div>
                                <!-- Apellidos del Padre -->
                                <div
                                    class="col-12 col-lg-4 mb-3"
                                    v-if="modalQuery.dad_lastname"
                                >
                                    <label
                                        for="dad_lastname"
                                        class="form-label"
                                    >
                                        Apellidos
                                    </label>
                                    <input
                                        id="dad_lastname"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="modalQuery.dad_lastname"
                                    />
                                </div>
                                <!-- Número de cédula de identidad del Padre -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="dad_dni_number"
                                        class="form-label"
                                    >
                                        Número de cédula de identidad
                                    </label>
                                    <input
                                        id="dad_dni_number"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="modalQuery.dad_dni_number"
                                    />
                                </div>
                                <!-- Documento de la cédula de identidad del padre -->
                                <div
                                    class="col-12 col-lg-4"
                                    v-if="modalQuery.url_dad_dni_file"
                                >
                                    <label
                                        for="url_dad_dni_file"
                                        class="form-label"
                                    >
                                        Documento de la cédula de identidad
                                    </label>
                                    <br />
                                    <button v-if="modalQuery.url_dad_dni_file" class="btn btn-outline-primary-dark"
                                        title="Descarga el archivo previamente subido" @click="
                                            downloadFile(
                                                modalQuery.url_dad_dni_file,
                                                'Documento de la cédula de identidad del padre'
                                            )
                                        ">
                                        <i class="fa fa-download"></i>
                                        Descargar
                                    </button>
                                </div>
                                <!-- Acta de nacimiento del Padre -->
                                <div class="col-12 col-lg-4" v-if="modalQuery.url_dad_born_file">
                                    <label for="url_dad_born_file" class="form-label">
                                        Acta de nacimiento
                                    </label>
                                    <br />
                                    <button v-if="modalQuery.url_dad_born_file" class="btn btn-outline-primary-dark"
                                        title="Descarga el archivo previamente subido" @click="
                                            downloadFile(
                                                modalQuery.url_dad_born_file,
                                                'Acta de nacimiento del padre'
                                            )
                                        "
                                    >
                                        <i class="fa fa-download"></i>
                                        Descargar
                                    </button>
                                </div>
                                <!-- Teléfono móvil del Padre -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="dad_cellphone"
                                        class="form-label"
                                    >
                                        Teléfono móvil
                                    </label>
                                    <input
                                        id="dad_cellphone"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="modalQuery.dad_cellphone"
                                    />
                                </div>
                                <!-- Teléfono local del Padre -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label for="dad_phone" class="form-label">
                                        Teléfono local
                                    </label>
                                    <input
                                        id="dad_phone"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="modalQuery.dad_phone"
                                    />
                                </div>
                            </div>
                        </div>
                        <!-- Final de datos del Padre -->

                        <!-- Datos de la Madre -->
                        <div
                            v-if="
                                modalQuery.mon_names || modalQuery.mon_lastname
                            "
                        >
                            <h3 class="h5 text-secondary text-uppercase">
                                Datos de la Madre
                            </h3>
                            <hr />
                            <div class="row pb-4">
                                <!-- Nombres de la madre -->
                                <div
                                    class="col-12 col-lg-4 mb-3"
                                    v-if="modalQuery.mon_names"
                                >
                                    <label for="mon_names" class="form-label">
                                        Nombres
                                    </label>
                                    <input
                                        id="mon_names"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="modalQuery.mon_names"
                                    />
                                </div>
                                <!-- Apellidos de la madre -->
                                <div
                                    class="col-12 col-lg-4 mb-3"
                                    v-if="modalQuery.mon_lastname"
                                >
                                    <label
                                        for="mon_lastname"
                                        class="form-label"
                                    >
                                        Apellidos
                                    </label>
                                    <input
                                        id="mon_lastname"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="modalQuery.mon_lastname"
                                    />
                                </div>
                                <!-- Número de cédula de identidad de la madre -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="mon_dni_number"
                                        class="form-label"
                                    >
                                        Número de cédula de identidad
                                    </label>
                                    <input
                                        id="mon_dni_number"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="modalQuery.mon_dni_number"
                                    />
                                </div>
                                <!-- Documento de la cédula de identidad de la madre -->
                                <div
                                    class="col-12 col-lg-4"
                                    v-if="modalQuery.url_mon_dni_file"
                                >
                                    <label
                                        for="url_mon_dni_file"
                                        class="form-label"
                                    >
                                        Documento de la cédula de identidad
                                    </label>
                                    <br />
                                    <button v-if="modalQuery.url_mon_dni_file" class="btn btn-outline-primary-dark"
                                        title="Descarga el archivo previamente subido" @click="
                                            downloadFile(
                                                modalQuery.url_mon_dni_file,
                                                'Documento de la cédula de identidad de la madre'
                                            )
                                        "
                                    >
                                        <i class="fa fa-download"></i>
                                        Descargar
                                    </button>
                                </div>
                                <!-- Acta de nacimiento de la Madre-->
                                <div class="col-12 col-lg-4" v-if="modalQuery.url_mom_born_file">
                                    <label for="url_mom_born_file" class="form-label">
                                        Acta de nacimiento
                                    </label>
                                    <br />
                                    <button v-if="modalQuery.url_mom_born_file" class="btn btn-outline-primary-dark"
                                        title="Descarga el archivo previamente subido" @click="
                                            downloadFile(
                                                modalQuery.url_mom_born_file,
                                                'Acta de nacimiento de la madre'
                                            )
                                        ">
                                        <i class="fa fa-download"></i>
                                        Descargar
                                    </button>
                                </div>
                                <!-- Teléfono móvil de la madre -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="mon_cellphone"
                                        class="form-label"
                                    >
                                        Teléfono móvil
                                    </label>
                                    <input
                                        id="mon_cellphone"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="modalQuery.mon_cellphone"
                                    />
                                </div>
                                <!-- Teléfono local de la madre -->
                                <div class="col-12 col-lg-4 mb-3">
                                    <label for="mon_phone" class="form-label">
                                        Teléfono local
                                    </label>
                                    <input
                                        id="mon_phone"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="modalQuery.mon_phone"
                                    />
                                </div>
                            </div>
                        </div>
                        <!-- Final de datos de la Madre -->
                        <!-- Datos de los padres -->

                        <!-- Datos adicionales -->
                        <div v-if="modalQuery.transplant_id == 28" class="mt-3">
                            <h3 class="h5 text-secondary text-uppercase">
                                Datos adicionales
                            </h3>
                            <hr />
                            <div class="row pb-4">
                                <div class="col-12 col-lg-4 mb-3">
                                    <label for="dialisis_id" class="form-label">
                                        Tipo de diálisis
                                    </label>
                                    <input
                                        id="dialisis_id"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            modalQuery.dialisis_id == 1
                                                ? 'Peritoneal'
                                                : 'Hemodiálisis'
                                        "
                                    />
                                </div>
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="healthcare_center_diálisis"
                                        class="form-label"
                                    >
                                        Centro de diálisis
                                    </label>
                                    <input
                                        id="healthcare_center_diálisis"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            getHealthcareCentersDialisis(
                                                modalQuery.healthcare_center_dialisis
                                            )
                                        "
                                    />
                                </div>
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="time_dialisis"
                                        class="form-label"
                                    >
                                        Fecha de inicio de diálisis
                                    </label>
                                    <input
                                        id="time_dialisis"
                                        class="form-control"
                                        type="date"
                                        disabled
                                        :value="modalQuery.time_dialisis"
                                    />
                                </div>
                            </div>

                            <!-- Datos del médico de la unidad de diálisis -->
                            <h3 class="h5 text-secondary text-uppercase">
                                Datos del médico de la unidad de diálisis
                            </h3>
                            <hr />
                            <div class="row pb-4">
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="doctor_dialisis_names"
                                        class="form-label"
                                    >
                                        Nombres
                                    </label>
                                    <input
                                        id="doctor_dialisis_names"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            modalQuery.doctor_dialisis_names
                                        "
                                    />
                                </div>
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="doctor_dialisis_lastname"
                                        class="form-label"
                                    >
                                        Apellidos
                                    </label>
                                    <input
                                        id="doctor_dialisis_lastname"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            modalQuery.doctor_dialisis_lastname
                                        "
                                    />
                                </div>
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="doctor_dialisis_dni_number"
                                        class="form-label"
                                    >
                                        Correo electrónico
                                    </label>
                                    <input
                                        id="doctor_dialisis_dni_number"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="modalQuery.doctor_dialisis_mail"
                                    />
                                </div>
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="doctor_dialisis_cellphone"
                                        class="form-label"
                                    >
                                        Teléfono móvil
                                    </label>
                                    <input
                                        id="doctor_dialisis_cellphone"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            modalQuery.doctor_dialisis_cellphone
                                        "
                                    />
                                </div>
                                <div
                                    class="col-12 col-lg-4 mb-3"
                                    v-if="modalQuery.doctor_dialisis_phone"
                                >
                                    <label
                                        for="doctor_dialisis_phone"
                                        class="form-label"
                                    >
                                        Teléfono de la unidad de diálisis
                                    </label>
                                    <input
                                        id="doctor_dialisis_phone"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            modalQuery.doctor_dialisis_phone
                                        "
                                    />
                                </div>
                            </div>
                            <!-- Final de datos del médico de la unidad de diálisis -->
                        </div>
                        <!-- Final de datos adicionales -->

                        <!-- Datos del representante legal -->
                        <div
                            v-if="
                                modalQuery &&
                                    modalQuery.profile &&
                                    modalQuery.profile.age <= 18 &&
                                    modalQuery.representative_names
                            "
                        >
                            <h3 class="h5 text-secondary text-uppercase">
                                Datos del representante legal
                            </h3>
                            <hr />
                            <div class="row pb-4">
                                <div
                                    class="col-12 col-lg-4 mb-3"
                                    v-if="modalQuery.representative_names"
                                >
                                    <label
                                        for="representative_names"
                                        class="form-label"
                                    >
                                        Nombres
                                    </label>
                                    <input
                                        id="representative_names"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="modalQuery.representative_names"
                                    />
                                </div>
                                <div
                                    class="col-12 col-lg-4 mb-3"
                                    v-if="modalQuery.representative_lastname"
                                >
                                    <label
                                        for="representative_lastname"
                                        class="form-label"
                                    >
                                        Apellidos
                                    </label>
                                    <input
                                        id="representative_lastname"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            modalQuery.representative_lastname
                                        "
                                    />
                                </div>
                                <div
                                    class="col-12 col-lg-4 mb-3"
                                    v-if="modalQuery.representative_dni_number"
                                >
                                    <label
                                        for="representative_dni_number"
                                        class="form-label"
                                    >
                                        Número de cédula de identidad
                                    </label>
                                    <input
                                        id="representative_dni_number"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            modalQuery.representative_dni_number
                                        "
                                    />
                                </div>
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="representativeIdFile"
                                        class="form-label"
                                    >
                                        Documento de la cédula de identidad
                                    </label>
                                    <br />
                                    <button v-if="modalQuery.url_representative_dni_file"
                                        class="btn btn-outline-primary-dark" title="Descarga el archivo previamente subido"
                                        @click="
                                            downloadFile(
                                                modalQuery.url_representative_dni_file,
                                                'Documento de la cédula de identidad representante legal'
                                            )
                                        "
                                    >
                                        <i class="fa fa-download"></i>
                                        Descargar
                                    </button>
                                </div>
                                <div
                                    class="col-12 col-lg-4 mb-3"
                                    v-if="modalQuery.representative_cellphone"
                                >
                                    <label
                                        for="representative_cellphone"
                                        class="form-label"
                                    >
                                        Teléfono móvil
                                    </label>
                                    <input
                                        id="representative_cellphone"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            modalQuery.representative_cellphone
                                        "
                                    />
                                </div>
                                <div
                                    class="col-12 col-lg-4 mb-3"
                                    v-if="modalQuery.representative_phone"
                                >
                                    <label
                                        for="representative_phone"
                                        class="form-label"
                                    >
                                        Teléfono local
                                    </label>
                                    <input
                                        id="representative_phone"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="modalQuery.representative_phone"
                                    />
                                </div>
                                <div class="col-12 col-lg-4">
                                    <label
                                        for="legal_certifi_file"
                                        class="form-label"
                                    >
                                        Certificado de representante legal
                                    </label>
                                    <button
                                        v-if="modalQuery.url_legal_certifi_file"
                                        class="btn btn-outline-primary-dark"
                                        title="Descarga el archivo previamente subido"
                                        @click="
                                            downloadFile(
                                                modalQuery.url_legal_certifi_file,
                                                'Certificado de representante legal'
                                            )
                                        "
                                    >
                                        <i class="fa fa-download"></i>
                                        Descargar
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- Final de datos del representante legal -->

                        <!-- Datos del médico especialista en trasplante -->
                        <div v-if="modalQuery.healthcare_center">
                            <h3 class="h5 text-secondary text-uppercase">
                                Datos del médico especialista en trasplante
                            </h3>
                            <hr />
                            <div class="row pb-4">
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="doctor_transplant_names"
                                        class="form-label"
                                    >
                                        Nombres
                                    </label>
                                    <input
                                        id="doctor_transplant_names"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            modalQuery.doctor_transplant_names
                                        "
                                    />
                                </div>
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="doctor_transplant_lastname"
                                        class="form-label"
                                    >
                                        Apellidos
                                    </label>
                                    <input
                                        id="doctor_transplant_lastname"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            modalQuery.doctor_transplant_lastname
                                        "
                                    />
                                </div>
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="doctor_transplant_mail"
                                        class="form-label"
                                    >
                                        Correo electrónico
                                    </label>
                                    <input
                                        id="doctor_transplant_mail"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            modalQuery.doctor_transplant_mail
                                        "
                                    />
                                </div>
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="doctor_transplant_cellphone"
                                        class="form-label"
                                    >
                                        Teléfono móvil
                                    </label>
                                    <input
                                        id="doctor_transplant_cellphone"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            modalQuery.doctor_transplant_cellphone
                                        "
                                    />
                                </div>
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="doctor_transplant_phone"
                                        class="form-label"
                                    >
                                        Teléfono local
                                    </label>
                                    <input
                                        id="doctor_transplant_phone"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            modalQuery.doctor_transplant_phone
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                        <!-- Final de datos del médico especialista en trasplante -->

                        <!-- Datos del médico cirujano de trasplante -->
                        <div v-if="modalQuery.healthcare_center">
                            <h3 class="h5 text-secondary text-uppercase">
                                Datos del médico cirujano de trasplante
                            </h3>
                            <hr />
                            <div class="row pb-4">
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="doctor_transplant_surgeon_names"
                                        class="form-label"
                                    >
                                        Nombres
                                    </label>
                                    <input
                                        id="doctor_transplant_surgeon_names"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            modalQuery.doctor_transplant_surgeon_names
                                        "
                                    />
                                </div>
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="doctor_transplant_surgeon_lastname"
                                        class="form-label"
                                    >
                                        Apellidos
                                    </label>
                                    <input
                                        id="doctor_transplant_surgeon_lastname"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            modalQuery.doctor_transplant_surgeon_lastname
                                        "
                                    />
                                </div>
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="doctor_transplant_surgeon_mail"
                                        class="form-label"
                                    >
                                        Correo electrónico
                                    </label>
                                    <input
                                        id="doctor_transplant_surgeon_mail"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            modalQuery.doctor_transplant_surgeon_mail
                                        "
                                    />
                                </div>
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="doctor_transplant_surgeon_cellphone"
                                        class="form-label"
                                    >
                                        Teléfono móvil
                                    </label>
                                    <input
                                        id="doctor_transplant_surgeon_cellphone"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            modalQuery.doctor_transplant_surgeon_cellphone
                                        "
                                    />
                                </div>
                                <div class="col-12 col-lg-4 mb-3">
                                    <label
                                        for="doctor_transplant_surgeon_phone"
                                        class="form-label"
                                    >
                                        Teléfono local
                                    </label>
                                    <input
                                        id="doctor_transplant_surgeon_phone"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            modalQuery.doctor_transplant_surgeon_phone
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                        <!-- Final de datos del médico cirujano de trasplante -->

                        <!-- Datos del médico tratante -->
                        <div
                            class="row pb-4"
                            v-if="parseInt(modalQuery.transplant_id) != 28"
                        >
                            <h3 class="h5 text-secondary text-uppercase">
                                Datos del médico tratante
                            </h3>
                            <hr />
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="doctor_names" class="form-label">
                                    Nombres
                                </label>
                                <input
                                    id="doctor_names"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="modalQuery.doctor_names"
                                />
                            </div>
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="doctor_lastname" class="form-label">
                                    Apellidos
                                </label>
                                <input
                                    id="doctor_lastname"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="modalQuery.doctor_lastname"
                                />
                            </div>
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="doctor_mail" class="form-label">
                                    Correo electrónico
                                </label>
                                <input
                                    id="doctor_mail"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="modalQuery.doctor_mail"
                                />
                            </div>
                            <div class="col-12 col-lg-4 mb-3">
                                <label
                                    for="doctor_cellphone"
                                    class="form-label"
                                >
                                    Teléfono móvil
                                </label>
                                <input
                                    id="doctor_cellphone"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="modalQuery.doctor_cellphone"
                                />
                            </div>
                            <div class="col-12 col-lg-4 mb-3">
                                <label for="doctor_phone" class="form-label">
                                    Teléfono local
                                </label>
                                <input
                                    id="doctor_phone"
                                    class="form-control"
                                    type="text"
                                    disabled
                                    :value="modalQuery.doctor_phone"
                                />
                            </div>
                        </div>
                        <!-- Fin de datos del médico tratante -->

                        <!-- Cita medica asignada -->
                        <div
                            v-if="
                                modalQuery &&
                                    modalQuery.citation_information &&
                                    modalQuery.citation_information
                                        .medical_citation_date
                            "
                        >
                            <h3 class="h5 text-secondary text-uppercase">
                                Cita médica asignada
                            </h3>
                            <hr />
                            <div class="row pb-4">
                                <!-- Fecha de la cita -->
                                <div class="col-12 col-lg-2">
                                    <label for="date" class="form-label">
                                        Fecha de la cita
                                    </label>
                                    <input
                                        id="date"
                                        class="form-control"
                                        type="date"
                                        name="date"
                                        readonly
                                        disabled
                                        :value="
                                            modalQuery.citation_information
                                                .medical_citation_date
                                        "
                                    />
                                </div>
                                <!-- Fin de fecha de la cita -->
                                <!-- Hora de la cita -->
                                <div class="col-12 col-lg-2">
                                    <label
                                        for="citation_time"
                                        class="form-label"
                                    >
                                        Hora de la cita
                                    </label>
                                    <input
                                        id="citation_time"
                                        class="form-control"
                                        name="citation_time"
                                        type="time"
                                        readonly
                                        disabled
                                        tabindex="13"
                                        :value="
                                            modalQuery.citation_information
                                                .medical_citation_time
                                        "
                                    />
                                </div>
                                <!-- Fin de hora de la cita -->
                                <div class="col-12 col-lg-4">
                                    <label
                                        for="healthcareCenter"
                                        class="form-label"
                                    >
                                        Centro de trasplante
                                    </label>
                                    <input
                                        id="healthcare_center"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        :value="
                                            getHealthcareCenter(
                                                modalQuery.citation_information
                                                    .medical_citation_healthcare_center
                                            )
                                        "
                                    />
                                </div>
                                <!--  Asistencia -->
                                <div class="col-12 col-lg-2">
                                    <label for="donor" class="form-label">
                                        ¿Asistió a la cita?
                                    </label>
                                    <input
                                        id="healthcareCenter"
                                        class="form-control"
                                        type="text"
                                        readonly
                                        disabled
                                        :value="
                                            modalQuery.citation_information
                                                .medical_citation_assistance
                                        "
                                    />
                                </div>
                                <!-- Fin Asistencia -->
                            </div>
                            <!-- Observación -->
                            <div class="row pb-2">
                                <div class="col-12 col-lg-12 mb-3">
                                    <label for="Observación" class="form-label">
                                        Observación del solicitante
                                    </label>
                                    <textarea
                                        id="Observación"
                                        class="form-control"
                                        name="Observación"
                                        cols="20"
                                        rows="5"
                                        readonly
                                        disabled
                                        :value="
                                            modalQuery.citation_information
                                                .medical_citation_observation
                                        "
                                    ></textarea>
                                </div>
                            </div>
                            <!-- fin de Observación -->
                        </div>
                        <!-- Fin de cita medica asignada  -->

                        <!-- Observaciones de la solicitud -->
                        <div
                            v-if="modalQuery.observation_request"
                            class="col-12 col-lg-12 mb-3"
                        >
                            <label class="form-label">
                                Observaciones de la solicitud
                            </label>
                            <textarea
                                id="observation_request"
                                class="form-control"
                                type="text"
                                disabled
                                rows="4"
                            >{{ modalQuery.observation_request }}
                            </textarea>
                        </div>
                        <!-- Final de observaciones de la solicitud -->
                    </div>
                    <!-- Final de modal-body -->

                    <!-- modal-footer -->
                    <div class="modal-footer justify-content-center">
                        <button
                            type="button"
                            class="btn btn-outline-primary-dark"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            Cerrar
                        </button>
                        <button
                            type="button"
                            class="btn btn-secondary-dark"
                            @click="exportPDF()"
                        >
                            <i class="fa fa-address-card"></i>
                            Generar planilla
                        </button>
                    </div>
                    <!-- Fin de modal-footer -->
                </div>
                <!-- Fin del modal-content -->
            </div>
            <!-- Final de modal-dialog -->
        </div>
        <!-- Final del modal -->
    </div>
    <!-- Final de transplant-request-form-component -->
</template>

<script>
import base64images from "../../base64images";
export default {
    data() {
        return {
            // Asignación médica
            citation_information: {
                medical_citation_date: "",
                medical_citation_time: "",
                medical_citation_healthcare_center: "",
                medical_citation_assistance: "",
                medical_citation_observation: ""
            },
            today: "",
            id_file: null,
            updated_photo_file: null,
            blood_type_file: null,
            born_file: null,
            hla_results_file: null,
            legal_certifi_file: null,
            report_file: null,
            donor_id_file: [],
            donor_blood_type_file: [],
            donor_born_file: [],
            donor_dni_dad_file: [],
            donor_dni_grandfather_file: [],
            donor_birth_certificate_grandfather_file: [],
            donor_dni_grandmother_file: [],
            donor_birth_certificate_grandmother_file: [],
            donor_birth_certificate_dad_file: [],
            donor_birth_certificate_mother_file: [],
            donor_dni_mother_file: [],
            patient_file: null,
            concubinage_file: [],
            marriage_file: [],
            adoption_file: [],
            exposition_healthcare_center_change_file: null,
            letter_healthcare_center_change_file: null,
            mon_id_file: null,
            dad_id_file: null,
            mom_born_file: null,
            dad_born_file: null,
            representative_dni_file: null,
            requirement_file: {},
            medical_extra_requirements_file: [],
            modalQuery: {},
            errors: [],
            records: [],
            blood_types: [],
            estates: [],
            basico: [],
            municipalities: [],
            parishes: [],
            transplants: [],
            relationships: [],
            healthcare_centers: [],
            healthcare_centers_dialisis: [],
            requirements: [],
            url_medic_report_file: "",
            url_blood_type_file: "",
            url_born_file: "",
            url_dni: "",
            url_updated_photo: "",
            url_mon_dni_file: "",
            url_dad_dni_file: "",
            url_mom_born_file: "",
            url_dad_born_file: "",
            url_representative_dni_file: "",
            url_legal_certifi_file: "",
            url_hla_results_file: "",
            aplicants: [],
            occupations_url: "global-data-type/occupation",
            occupations: [],
            selectedOptions: {
                nationality: "",
                gender: "",
                ocupation: "",
                marital_status: "",
                academic_level: "",
                estate_id: "",
                municipality_id: "",
                parish_id: ""
            },
            academic_levels: [],
            nationalityOptions: [
                { id: "V", text: "Venezolano(a)" },
                { id: "E", text: "Extranjero(a)" }
            ],
            genderOptions: [
                { id: "F", text: "Femenino" },
                { id: "M", text: "Masculino" }
            ],
            maritalStatusOptions: [
                { id: "S", text: "Soltero(a)" },
                { id: "C", text: "Casado(a)" },
                { id: "D", text: "Divorciado(a)" },
                { id: "V", text: "Viudo(a)" }
            ],
            healthcare_center_assignment: false,
            update_request: false,
            nationalityOptions: [
                { id: "V", text: "Venezolano(a)" },
                { id: "E", text: "Extranjero(a)" }
            ],
            genderOptions: [
                { id: "F", text: "Femenino" },
                { id: "M", text: "Masculino" }
            ],
            maritalStatusOptions: [
                { id: "S", text: "Soltero(a)" },
                { id: "C", text: "Casado(a)" },
                { id: "D", text: "Divorciado(a)" },
                { id: "V", text: "Viudo(a)" }
            ],
            occupations_url: "global-data-type/occupation",
            occupations: [],
            academic_levels: [],
            location: {
                parish: "",
                municipality: "",
                estate: ""
            },
            record: {
                // Asignación médica
                citation_information: {
                    medical_citation_date: "",
                    medical_citation_time: "",
                    medical_citation_healthcare_center: "",
                    medical_citation_assistance: "",
                    medical_citation_observation: ""
                },
                dads: false,
                names: "",
                number: "",
                status: "",
                surnames: "",
                birthdate: "",
                place_of_birth: "",
                dni: "",
                adress: "",
                email: "",
                patient_blood_type: "",
                movil: "",
                age: "0",
                phone: "",
                time_dialisis: "",
                transplant_id: "",
                dialisis_id: "",
                healthcare_center_diálisis: "",
                healthcare_center: "",
                have_healthcare_center: false,
                healthcare_center_for_change: "",
                diagnosis: "",
                donor: false,
                donor_arrays: [],
                donor_names: "",
                donor_lastname: "",
                donor_birthday: "",
                donor_dni: "",
                donor_nationality: "",
                donor_gender: "",
                donor_marital_status: "",
                donor_ocupation: "",
                donor_academic_level: "",
                donor_age: "",
                donor_cellphone: "",
                donor_rh_factor: "",
                donor_phone: "",
                donor_email: "",
                donor_relationship: "",
                donor_place_of_birth: "",
                rh_factor: "",
                donor_blood: "",
                time: "",
                dad_names: "",
                dad_lastname: "",
                dad_dni_number: "",
                dad_cellphone: "",
                dad_phone: "",
                mon_names: "",
                mon_lastname: "",
                mon_dni_number: "",
                mon_cellphone: "",
                mon_phone: "",
                representative_names: "",
                representative_lastname: "",
                representative_dni_number: "",
                representative_cellphone: "",
                representative_phone: "",
                process_description: "",
                observation_request: "",
                doctor_dialisis_names: "",
                doctor_dialisis_lastname: "",
                doctor_dialisis_dni_number: "",
                doctor_dialisis_cellphone: "",
                doctor_dialisis_phone: "",
                doctor_transplant_names: "",
                doctor_transplant_lastname: "",
                doctor_transplant_dni_number: "",
                doctor_transplant_sacs_register_number: "",
                doctor_sacs_register_number: "",
                doctor_dialisis_sacs_register_number: "",
                doctor_transplant_cellphone: "",
                doctor_transplant_phone: "",
                doctor_names: "",
                doctor_lastname: "",
                doctor_dni_number: "",
                doctor_cellphone: "",
                doctor_phone: "",
                doctor_mail: "",
                // Datos del médico cirujano de trasplante
                doctor_transplant_surgeon_names: "",
                doctor_transplant_surgeon_lastname: "",
                doctor_transplant_surgeon_mail: "",
                doctor_transplant_surgeon_cellphone: "",
                doctor_transplant_surgeon_phone: "",
                user_id: "",
                profile_id: "",
                estate_id: "",
                municipality_id: "",
                parish_id: "",
                relationship: ""
            },
            keys: 1,
            profile: "",
            user: "",
            blood_groups: [],
            rhFactorOptions: [
                { id: "P", text: "Positivo" },
                { id: "N", text: "Negativo" }
            ]
        };
    },
    watch: {
        /**
         * Método que evita que el año de la fecha de inicio de diálisis sea superior al año actual
         *
         * @param {*} newValue
         */
        "record.time_dialisis": function(newValue) {
            const vm = this;
            let date = newValue ? newValue.split("-") : "";
            let current_year = new Date().getFullYear();
            vm.record.time_dialisis = date
                ? parseInt(date[0]) > current_year
                    ? `${current_year}-${date[1]}-${date[2]}`
                    : newValue
                : "";
        },

        /**
         * Método que reinicia el campo fecha de nacimiento cuando el año supera los 4 digitos
         *
         * @param {*} newValue
         */
        "record.birthdate": function(newValue) {
            const vm = this;
            let date = newValue.split("-");
            let current_year = new Date().getFullYear();
            vm.record.birthdate = date
                ? parseInt(date[0]) > current_year
                    ? `${current_year}-${date[1]}-${date[2]}`
                    : newValue
                : "";
        },

        /**
         * Método que reinicia los datos del representante legal
         * @author José Briceño <josejorgebriceno9@gmail.com>
         * @param {*} newValue
         */
        "record.age": function(newValue) {
            const vm = this;
            newValue > 17 && (vm.record.representative_names = "");
            newValue > 17 && (vm.record.representative_lastdadsname = "");
            newValue > 17 && (vm.record.representative_dni_number = "");
            newValue > 17 && (vm.record.representative_cellphone = "");
            newValue > 17 && (vm.record.representative_phone = "");
            newValue > 17 &&
                (vm.url_representative_dni_file = "") &&
                vm.$refs.representative_dni_file &&
                (vm.$refs.representative_dni_file.value = null);
            newValue > 17 &&
                (vm.url_legal_certifi_file = "") &&
                vm.$refs.legal_certifi_file &&
                (vm.$refs.legal_certifi_file.value = null);
        },
        /**
         * Método que reinicia los datos del representante legal y Padres
         * dependiendo del estado del selector
         * @author Francisco Escala
         * @param {*} newValue
         */
        "record.dads": function(newVal, oldVal) {
            if (newVal == false) {
                this.record.dad_names = "";
                this.record.dad_lastname = "";
                this.record.dad_dni_number = "";
                this.record.dad_cellphone = "";
                this.record.dad_phone = "";
                this.record.mon_names = "";
                this.record.mon_lastname = "";
                this.record.mon_dni_number = "";
                this.record.mon_cellphone = "";
                this.record.mon_phone = "";
                (this.url_dad_dni_file = "") &&
                    this.$refs.dad_id_file &&
                    (this.$refs.dad_id_file.value = null);
                (this.url_dad_born_file = "") &&
                    this.$refs.dad_born_file &&
                    (this.$refs.dad_born_file.value = null);
                (this.url_mon_dni_file = "") &&
                    this.$refs.mon_id_file &&
                    (this.$refs.mon_id_file.value = null);
                (this.url_mom_born_file = "") &&
                    this.$refs.mom_born_file &&
                    (this.$refs.mom_born_file.value = null);
            } else {
                this.record.representative_names = "";
                this.record.representative_lastname = "";
                this.record.representative_dni_number = "";
                this.record.representative_cellphone = "";
                this.record.representative_phone = "";
                (this.url_representative_dni_file = "") &&
                    this.$refs.representative_dni_file &&
                    (this.$refs.representative_dni_file.value = null);
                (this.url_legal_certifi_file = "") &&
                    this.$refs.legal_certifi_file &&
                    (this.$refs.legal_certifi_file.value = null);
            }
        },

        /**
         * Método que reinicia los datos del centro de trasplante seleccionado
         * @author José Briceño <josejorgebriceno9@gmail.com>
         * @param {*} newValue
         */
        "record.have_healthcare_center": function(newValue) {
            const vm = this;
            newValue == false && (vm.record.healthcare_center = "");
            /*
            newValue == false &&
            (vm.record.citation_information = {
                medical_citation_date: "",
                medical_citation_time: "",
                medical_citation_healthcare_center: "",
                medical_citation_assistance: "",
                medical_citation_observation: ""
            });
            */
        },

        /**
         * Método que reinicia los datos relacionados a los tipos de trasplantes
         * @author José Briceño <josejorgebriceno9@gmail.com>
         * @param {*} newValue
         */
        "record.transplant_id": function(newValue) {
            const vm = this;
            // Datos adicionales
            newValue != 28 && (vm.record.dialisis_id = "");
            newValue != 28 && (vm.record.healthcare_center_dialisis = "");
            newValue != 28 && (vm.record.time_dialisis = "");
            // Datos del médico de la unidad de diálisis
            newValue != 28 && (vm.record.doctor_dialisis_names = "");
            newValue != 28 && (vm.record.doctor_dialisis_lastname = "");
            newValue != 28 && (vm.record.doctor_dialisis_mail = "");
            newValue != 28 && (vm.record.doctor_dialisis_cellphone = "");
            newValue != 28 && (vm.record.doctor_dialisis_phone = "");
            // Datos del médico tratante
            newValue == 28 && (vm.record.doctor_names = "");
            newValue == 28 && (vm.record.doctor_lastname = "");
            newValue == 28 && (vm.record.doctor_mail = "");
            newValue == 28 && (vm.record.doctor_cellphone = "");
            newValue == 28 && (vm.record.doctor_phone = "");

            newValue == 30 &&
                (vm.record.donor = false) &&
                clearLivingDonorData();

            // Reinicia el documento de la prueba HLA
            newValue != 31 &&
                (vm.url_hla_results_file = "") &&
                vm.$refs.hla_results_file &&
                (vm.$refs.hla_results_file.value = null);

            [30, 32, 33, 34, 35].includes(newValue) &&
                (vm.record.donor = false) &&
                (vm.record.donor_arrays = []);
        }
    },
    methods: {
        /**
         * Método que evita que el año de la fecha de nacimiento de un donante vivo tenga mas de 4 digitos
         *
         * @param {*} index
         *
         * @author José Briceño <josejorgebriceno9@gmail.com>
         */
        setBirthdate(index) {
            const vm = this;
            let date = vm.record.donor_arrays[index].donor_birthday
                ? vm.record.donor_arrays[index].donor_birthday.split("-")
                : "";
            let current_year = new Date().getFullYear();
            vm.record.donor_arrays[index].donor_birthday = date
                ? parseInt(date[0]) > current_year
                    ? `${current_year}-${date[1]}-${date[2]}`
                    : vm.record.donor_arrays[index].donor_birthday
                : "";
        },

        /**
         * Método que obtiene los datos del agente que actualiza una solicitud de trasplante
         *
         * @author José Briceño <josejorgebriceno9@gmail.com>
         * @author Francisco Escala <fjescala@gmail.com>
         */
        clearLivingDonorData() {
            const vm = this;
            vm.record.donor_arrays = [];
            vm.addDonor();
            vm.$refs.donor_id_file && (vm.$refs.donor_id_file.value = null);
            vm.$refs.donor_blood_type_file &&
                (vm.$refs.donor_blood_type_file.value = null);
            vm.$refs.donor_born_file && (vm.$refs.donor_born_file.value = null);
            vm.$refs.donor_concubine_file &&
                (vm.$refs.donor_concubine_file.value = null);
            vm.$refs.donor_marriage_file &&
                (vm.$refs.donor_marriage_file.value = null);
            vm.$refs.donor_adoption_file &&
                (vm.$refs.donor_adoption_file.value = null);
        },
        /**
         * Método que oculta un donante vivo.
         *
         *
         * @author
         */
        removeRow: function(index, el) {
            $(".tooltip:last").remove();
            el.splice(index, 1);
        },
        /**
         * Método que agrega un donante vivo al formulario.
         *
         * addDonor
         *
         * @author Francisco Escala <fjescala@gmail.com>
         */
        addDonor() {
            this.record.donor_arrays.push({
                donor_names: "",
                donor_lastname: "",
                donor_birthday: "",
                donor_id: new Date().getTime(),
                donor_dni: "",
                donor_nationality: "",
                donor_gender: "",
                donor_marital_status: "",
                donor_ocupation: "",
                donor_academic_level: "",
                donor_age: "",
                donor_cellphone: "",
                donor_rh_factor: "",
                donor_phone: "",
                donor_email: "",
                donor_relationship: 1,
                relationship_name: "family",
                donor_place_of_birth: "",
                rh_factor: "",
                donor_blood: "",
                isExpand: true
            });
        },
        /**
         * Método que obtiene el archivo subido al campo respectivo.
         *
         * onFileUpload
         *
         * @author Francisco Escala <fjescala@gmail.com>
         */
        onFileUpload(e) {
            const vm = this;
            const max_allowed_size = vm.maxWeightAllow();
            if (e.target.files[0].size) {
                if (e.target.files[0].size < max_allowed_size) {
                    switch (e.target.name) {
                        case "id_file":
                            this.id_file = e.target.files[0];
                            break;
                        case "updated_photo_file":
                            this.updated_photo_file = e.target.files[0];
                            break;
                        case "blood_type":
                            this.blood_type_file = e.target.files[0];
                            break;
                        case "born_file":
                            this.born_file = e.target.files[0];
                            break;
                        case "hla_results_file":
                            this.hla_results_file = e.target.files[0];
                            break;
                        case "legal_certifi_file":
                            this.legal_certifi_file = e.target.files[0];
                            break;
                        case "report_file":
                            this.report_file = e.target.files[0];
                            break;
                        case "patient_file":
                            this.patient_file = e.target.files[0];
                            break;
                        case "marriage_file":
                            this.marriage_file = e.target.files[0];
                            break;
                        case "mon_id_file":
                            this.mon_id_file = e.target.files[0];
                            break;
                        case "mom_born_file":
                            this.mom_born_file = e.target.files[0];
                            break;
                        case "dad_id_file":
                            this.dad_id_file = e.target.files[0];
                            break;
                        case "dad_born_file":
                            this.dad_born_file = e.target.files[0];
                            break;
                        case "adoption_file":
                            this.adoption_file = e.target.files[0];
                            break;
                        case "exposition_healthcare_center_change_file":
                            this.exposition_healthcare_center_change_file =
                                e.target.files[0];
                            break;
                        case "letter_healthcare_center_change_file":
                            this.letter_healthcare_center_change_file =
                                e.target.files[0];
                            break;
                        case "representative_dni_file":
                            this.representative_dni_file = e.target.files[0];
                            break;
                        default:
                            this.requirement_file[e.target.name] =
                                e.target.files[0];
                    }
                } else {
                    e.target.value = "";
                    vm.maxWeightExceededMessage();
                }
            }
        },

        /**
         * Método que obtiene los archivos del donante subidos  a los campos
         * respectivos.
         *
         * onFileUpload
         *
         * @author Francisco Escala <fjescala@gmail.com>
         */
        onFileUploadDonor(e, index, id, name) {
            const vm = this;
            const max_allowed_size = vm.maxWeightAllow();
            if (e.target.files[0].size) {
                if (e.target.files[0].size < max_allowed_size) {
                    switch (name) {
                        case "donor_id_file":
                            this.donor_id_file[index] = [id, e.target.files[0]];
                            break;
                        case "donor_file":
                            //acta de nacimiento del donante vivo
                            this.donor_born_file[index] = [
                                id,
                                e.target.files[0]
                            ];
                            break;
                        case "donor_dni_dad_file":
                            //cedula del padre del donante vivo
                            this.donor_dni_dad_file[index] = [
                                id,
                                e.target.files[0]
                            ];
                            break;
                        case "donor_birth_certificate_dad_file":
                            //cedula del padre del donante vivo
                            this.donor_birth_certificate_dad_file[index] = [
                                id,
                                e.target.files[0]
                            ];
                            break;
                        case "donor_dni_mother_file":
                            //cedula del padre del donante vivo
                            this.donor_dni_mother_file[index] = [
                                id,
                                e.target.files[0]
                            ];
                            break;
                        case "donor_birth_certificate_mother_file":
                            this.donor_birth_certificate_mother_file[index] = [
                                id,
                                e.target.files[0]
                            ];
                            break;
                        case "donor_dni_grandmother_file":
                            //cedula del padre del donante vivo
                            this.donor_dni_grandmother_file[index] = [
                                id,
                                e.target.files[0]
                            ];
                            break;
                        case "donor_birth_certificate_grandmother_file":
                            this.donor_birth_certificate_grandmother_file[
                                index
                            ] = [id, e.target.files[0]];
                            break;
                        case "donor_dni_grandfather_file":
                            //cedula del padre del donante vivo
                            this.donor_dni_grandfather_file[index] = [
                                id,
                                e.target.files[0]
                            ];
                            break;
                        case "donor_birth_certificate_grandfather_file":
                            this.donor_birth_certificate_grandfather_file[
                                index
                            ] = [id, e.target.files[0]];
                            break;
                        case "donor_birth_certificate_mother_file":
                            this.donor_birth_certificate_mother_file[index] = [
                                id,
                                e.target.files[0]
                            ];
                            break;
                        case "donor_blood_type_file":
                            this.donor_blood_type_file[index] = [
                                id,
                                e.target.files[0]
                            ];
                            break;
                        case "concubinage_file":
                            this.concubinage_file[index] = [
                                id,
                                e.target.files[0]
                            ];
                            break;
                        case "marriage_file":
                            this.marriage_file[index] = [id, e.target.files[0]];
                            break;
                        case "adoption_file":
                            this.adoption_file[index] = [id, e.target.files[0]];
                            break;
                        default:
                    }
                } else {
                    e.target.value = "";
                    vm.maxWeightExceededMessage();
                }
            }
        },

        /**
         * Método para reestablecer valores iniciales del formulario
         *
         * @method reset
         *
         * @author Francisco Escala <fjescala@gmail.com>
         * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <kingangelo_123@gmail.com>
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        reset() {
            const vm = this;
            vm.update_request = false;
            vm.errors = [];
            (vm.selectedOptions = {
                nationality: "",
                gender: "",
                ocupation: "",
                marital_status: "",
                academic_level: "",
                estate_id: "",
                municipality_id: "",
                parish_id: ""
            }),
                (vm.record.dads = false);
            vm.record.surnames = "";
            vm.record.birthdate = "";
            vm.record.place_of_birth = "";
            vm.record.dni = "";
            vm.record.adress = "";
            vm.record.email = "";
            vm.record.patient_blood_type = "";
            vm.record.movil = "";
            vm.record.age = "0";
            vm.record.phone = "";
            vm.record.time_dialisis = "";
            vm.record.transplant_id = "";
            vm.record.dialisis_id = "";
            vm.record.healthcare_center_diálisis = "";
            vm.record.healthcare_center = "";
            vm.record.have_healthcare_center = false;
            vm.record.healthcare_center_for_change = "";
            vm.record.diagnosis = "";
            vm.record.donor = false;
            vm.record.donor_arrays = [];
            vm.record.donor_names = "";
            vm.record.donor_lastname = "";
            vm.record.donor_birthday = "";
            vm.record.donor_dni = "";
            vm.record.donor_nationality = "";
            vm.record.donor_gender = "";
            vm.record.donor_marital_status = "";
            vm.record.donor_ocupation = "";
            vm.record.donor_academic_level = "";
            vm.record.donor_age = "";
            vm.record.donor_cellphone = "";
            vm.record.donor_rh_factor = "";
            vm.record.donor_phone = "";
            vm.record.donor_email = "";
            vm.record.donor_relationship = "";
            vm.record.donor_place_of_birth = "";
            vm.record.rh_factor = "";
            vm.record.donor_blood = "";
            vm.record.time = "";
            vm.record.dad_names = "";
            vm.record.dad_lastname = "";
            vm.record.dad_dni_number = "";
            vm.record.dad_cellphone = "";
            vm.record.dad_phone = "";
            vm.record.mon_names = "";
            vm.record.mon_lastname = "";
            vm.record.mon_dni_number = "";
            vm.record.mon_cellphone = "";
            vm.record.mon_phone = "";
            vm.record.representative_names = "";
            vm.record.representative_lastname = "";
            vm.record.representative_dni_number = "";
            vm.record.representative_cellphone = "";
            vm.record.representative_phone = "";
            vm.record.process_description = "";
            vm.record.observation_request = "";
            vm.record.doctor_dialisis_names = "";
            vm.record.doctor_dialisis_lastname = "";
            vm.record.doctor_dialisis_dni_number = "";
            vm.record.doctor_dialisis_cellphone = "";
            vm.record.doctor_dialisis_phone = "";
            vm.record.doctor_transplant_names = "";
            vm.record.doctor_transplant_lastname = "";
            vm.record.doctor_transplant_dni_number = "";
            vm.record.doctor_transplant_sacs_register_number = "";
            vm.record.doctor_sacs_register_number = "";
            vm.record.doctor_dialisis_sacs_register_number = "";
            vm.record.doctor_transplant_cellphone = "";
            vm.record.doctor_transplant_phone = "";
            vm.record.doctor_names = "";
            vm.record.doctor_lastname = "";
            vm.record.doctor_dni_number = "";
            vm.record.doctor_cellphone = "";
            vm.record.doctor_phone = "";
            vm.record.doctor_mail = "";
            // Datos del médico cirujano de trasplante
            vm.record.doctor_transplant_surgeon_names = "";
            vm.record.doctor_transplant_surgeon_lastname = "";
            vm.record.doctor_transplant_surgeon_mail = "";
            vm.record.doctor_transplant_surgeon_cellphone = "";
            vm.record.doctor_transplant_surgeon_phone = "";
            vm.record.estate_id = "";
            vm.record.municipality_id = "";
            vm.record.parish_id = "";
            vm.record.relationship = "";
            vm.record.names = "";
            this.$refs.id_file && (this.$refs.id_file.value = null);
            this.$refs.updated_photo_file &&
                (this.$refs.updated_photo_file.value = null);
            this.$refs.born_file && (this.$refs.born_file.value = null);
            this.$refs.hla_results_file &&
                (this.$refs.hla_results_file.value = null);
            this.$refs.report_file && (this.$refs.report_file.value = null);
            this.$refs.blood_type && (this.$refs.blood_type.value = null);
            this.$refs.legal_certifi_file &&
                (this.$refs.legal_certifi_file.value = null);
            this.$refs.donor_id_file && (this.$refs.donor_id_file.value = null);
            this.$refs.donor_blood_type_file &&
                (this.$refs.donor_blood_type_file.value = null);
            this.$refs.id_file && (this.$refs.id_file.value = null);
            this.$refs.representative_dni_file &&
                (this.$refs.representative_dni_file.value = null);
            this.$refs.dad_id_file && (this.$refs.dad_id_file.value = null);
            this.$refs.mon_id_file && (this.$refs.mon_id_file.value = null);
            this.$refs.dad_born_file && (this.$refs.dad_born_file.value = null);
            this.$refs.mom_born_file && (this.$refs.mom_born_file.value = null);
            // Asignación de cita médica
            vm.record.citation_information.medical_citation_date &&
                (vm.record.citation_information.medical_citation_date = "");
            vm.record.citation_information.medical_citation_time &&
                (vm.record.citation_information.medical_citation_time = "");
            vm.record.citation_information.medical_citation_healthcare_center &&
                (vm.record.citation_information.medical_citation_healthcare_center =
                    "");
            vm.record.citation_information.medical_citation_assistance &&
                (vm.record.citation_information.medical_citation_assistance =
                    "");
            vm.record.citation_information.medical_citation_observation &&
                (vm.record.citation_information.medical_citation_observation =
                    "");
            this.addDonor();
        },

        /**
         * Método para Descargar Archivos
         *
         * @method downloadFile
         *
         */
        async downloadFile(file, name) {
            const vm = this;
            const url = file;
            const formData = new FormData();
            formData.append("url", url);

            try {
                let response = await axios({
                    url: `${window.url}` + "/download-aplicant-file",
                    method: "post",
                    data: formData,
                    responseType: "arraybuffer"
                });
                let fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                let fileLink = document.createElement("a");
                fileLink.href = fileURL;
                let ext =
                    file.substring(file.lastIndexOf(".") + 1, file.length) ||
                    file;
                fileLink.setAttribute("download", name + "." + ext);
                document.body.appendChild(fileLink);
                fileLink.click();
                URL.revokeObjectURL(fileLink.href);
            } catch (error) {
                let { errors } = JSON.parse(
                    String.fromCharCode.apply(
                        null,
                        new Uint8Array(error.response.data)
                    )
                );
                vm.errors = [];

                for (var index in errors) {
                    if (errors[index]) {
                        vm.errors.push(errors[index][0]);
                    }
                }
            }
        },

        /**
         * Ejecuta la acción para guardar o actualizar datos
         *
         * @method setRecord
         * @author Francisco Escala <fjescala@gmail.com>
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         *
         * @param  {String}  url URL de la petición a realizar
         */
        async setRecord(url, type_request = "", field = "") {
            const vm = this;
            vm.errors = [];
            vm.loadingState(true); // Inicio de spinner de carga.
            const recordId =
                typeof vm.record.id !== "undefined" && vm.record.id
                    ? vm.record.id
                    : "";
            const formData = new FormData();
            if (vm.adoption_file) {
                vm.adoption_file.forEach(function(element) {
                    formData.append(`adoption_file[${element[0]}]`, element[1]);
                });
            }
            if (vm.marriage_file) {
                vm.marriage_file.forEach(function(element) {
                    formData.append(`marriage_file[${element[0]}]`, element[1]);
                });
            }
            if (vm.concubinage_file) {
                vm.concubinage_file.forEach(function(element) {
                    formData.append(
                        `concubinage_file[${element[0]}]`,
                        element[1]
                    );
                });
            }
            if (vm.donor_blood_type_file) {
                vm.donor_blood_type_file.forEach(function(element) {
                    formData.append(
                        `donor_blood_type_file[${element[0]}]`,
                        element[1]
                    );
                });
            }
            if (vm.donor_born_file) {
                vm.donor_born_file.forEach(function(element) {
                    formData.append(
                        `donor_born_file[${element[0]}]`,
                        element[1]
                    );
                });
            }
            if (vm.donor_dni_dad_file) {
                vm.donor_dni_dad_file.forEach(function(element) {
                    formData.append(
                        `donor_dni_dad_file[${element[0]}]`,
                        element[1]
                    );
                });
            }
            if (vm.donor_birth_certificate_mother_file) {
                vm.donor_birth_certificate_mother_file.forEach(function(
                    element
                ) {
                    formData.append(
                        `donor_birth_certificate_mother_file[${element[0]}]`,
                        element[1]
                    );
                });
            }
            if (vm.donor_dni_grandmother_file) {
                vm.donor_dni_grandmother_file.forEach(function(element) {
                    formData.append(
                        `donor_dni_grandmother_file[${element[0]}]`,
                        element[1]
                    );
                });
            }
            if (vm.donor_birth_certificate_grandmother_file) {
                vm.donor_birth_certificate_grandmother_file.forEach(function(
                    element
                ) {
                    formData.append(
                        `donor_birth_certificate_grandmother_file[${element[0]}]`,
                        element[1]
                    );
                });
            }
            if (vm.donor_dni_grandfather_file) {
                vm.donor_dni_grandfather_file.forEach(function(element) {
                    formData.append(
                        `donor_dni_grandfather_file[${element[0]}]`,
                        element[1]
                    );
                });
            }
            if (vm.donor_birth_certificate_grandfather_file) {
                vm.donor_birth_certificate_grandfather_file.forEach(function(
                    element
                ) {
                    formData.append(
                        `donor_birth_certificate_grandfather_file[${element[0]}]`,
                        element[1]
                    );
                });
            }
            if (vm.donor_birth_certificate_dad_file) {
                vm.donor_birth_certificate_dad_file.forEach(function(element) {
                    formData.append(
                        `donor_birth_certificate_dad_file[${element[0]}]`,
                        element[1]
                    );
                });
            }
            if (vm.donor_dni_mother_file) {
                vm.donor_dni_mother_file.forEach(function(element) {
                    formData.append(
                        `donor_dni_mother_file[${element[0]}]`,
                        element[1]
                    );
                });
            }
            if (vm.donor_id_file) {
                vm.donor_id_file.forEach(function(element) {
                    formData.append(`donor_id_file[${element[0]}]`, element[1]);
                });
            }
            if (vm.id_file) {
                formData.append("id_file", vm.id_file);
            }
            if (vm.updated_photo_file) {
                formData.append(
                    "updated_photo_file",
                    vm.updated_photo_file,
                    vm.updated_photo_file.name
                );
            }
            if (vm.blood_type_file) {
                formData.append(
                    "blood_type_file",
                    vm.blood_type_file,
                    vm.blood_type_file.name
                );
            }
            if (vm.born_file) {
                formData.append("born_file", vm.born_file, vm.born_file.name);
            }
            if (vm.hla_results_file) {
                formData.append(
                    "hla_results_file",
                    vm.hla_results_file,
                    vm.hla_results_file.name
                );
            }
            if (vm.legal_certifi_file) {
                formData.append(
                    "legal_certifi_file",
                    vm.legal_certifi_file,
                    vm.legal_certifi_file.name
                );
            }
            if (vm.report_file) {
                formData.append(
                    "report_file",
                    vm.report_file,
                    vm.report_file.name
                );
            }
            if (vm.patient_file) {
                formData.append(
                    "patient_file",
                    vm.patient_file,
                    vm.patient_file.name
                );
            }
            if (vm.mon_id_file) {
                formData.append(
                    "mon_id_file",
                    vm.mon_id_file,
                    vm.mon_id_file.name
                );
            }
            if (vm.dad_id_file) {
                formData.append(
                    "dad_id_file",
                    vm.dad_id_file,
                    vm.dad_id_file.name
                );
            }
            if (vm.mom_born_file) {
                formData.append("mom_born_file", vm.mom_born_file, vm.mom_born_file.name);
            }
            if (vm.dad_born_file) {
                formData.append("dad_born_file", vm.dad_born_file, vm.dad_born_file.name);
            }
            if (vm.representative_dni_file) {
                formData.append(
                    "representative_dni_file",
                    vm.representative_dni_file,
                    vm.representative_dni_file.name
                );
            }
            if (vm.requirement_file) {
                for (const name_file in vm.requirement_file) {
                    formData.append(
                        `requirement_file[${name_file}]`,
                        vm.requirement_file[name_file]
                    );
                }
            }
            vm.record.nationality = vm.selectedOptions.nationality;
            vm.record.gender = vm.selectedOptions.gender;
            vm.record.ocupation = vm.selectedOptions.ocupation;
            vm.record.marital_status = vm.selectedOptions.marital_status;
            vm.record.academic_level = vm.selectedOptions.academic_level;
            Object.keys(vm.record).forEach(key => {
                if (vm.record[key] == null) {
                    vm.record[key] = "";
                }
                if (key == "donor_arrays" || key == "citation_information") {
                    if (vm.record[key].length < 1) {
                    } else {
                        formData.append(key, JSON.stringify(vm.record[key]));
                    }
                } else {
                    formData.append(key, vm.record[key]);
                }
            });
            await axios({
                method: recordId ? "put" : "post",
                url: `${window.url}/${url}`,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" }
            })
                .then(response => {
                    vm.loadingState(); // Finaliza spinner de carga.
                    vm.showMessage("updated");
                    vm.getRecordApplicant();
                })
                .catch(error => {
                    vm.loadingState(); // Finaliza spinner de carga.
                    vm.setErrors(error);
                });
        },

        /**
         * Método para mostrar la informacion de un registro
         * dado su ID en un modal
         *
         * @method showInfo
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {int}      id    ID del registro
         */
        async showInfo(id) {
            const vm = this;
            let recordEdit = JSON.parse(
                JSON.stringify(
                    vm.aplicants.filter(rec => {
                        return rec.id === id;
                    })[0]
                )
            );
            vm.modalQuery = recordEdit;
            vm.profile = recordEdit.profile;
            vm.getUser("users", vm.profile.user_id);
            vm.getLocation("parish", vm.profile.parish_id);
            if (typeof vm.modalQuery.living_donor == "string") {
                vm.modalQuery.living_donor = JSON.parse(
                    vm.modalQuery.living_donor
                );
            }
            if (typeof vm.modalQuery.medical_citation == "string") {
                vm.modalQuery.citation_information = JSON.parse(
                    vm.modalQuery.medical_citation
                );
            } else {
                vm.modalQuery.citation_information = {};
            }
            // Establecimiento de valores del campo ¿Asistió a la cita? en el modal.
            if (
                vm.modalQuery &&
                vm.modalQuery.citation_information &&
                vm.modalQuery.citation_information
                    .medical_citation_assistance == "true"
            ) {
                vm.modalQuery.citation_information.medical_citation_assistance =
                    "Sí";
            } else if (
                vm.modalQuery &&
                vm.modalQuery.citation_information &&
                vm.modalQuery.citation_information
                    .medical_citation_assistance == "false"
            ) {
                vm.modalQuery.citation_information.medical_citation_assistance =
                    "No";
            } else if (vm.modalQuery && vm.modalQuery.citation_information) {
                vm.modalQuery.citation_information.medical_citation_assistance =
                    "";
            }
            $("#show_transplant_request").modal("show");
        },

        /**
         * Método para obtener los datos del registro de un usuario
         *
         *
         * @method getUser
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {int}      id    ID del usuario
         */
        async getUser(url, id) {
            const vm = this;
            const query = `${window.url}/${url}/${id}`;
            axios
                .get(query)
                .then(response => {
                    vm.user = response.data.result;
                })
                .catch(error => {
                    console.error(error);
                });
        },

        /**
         * Método para obtener la representación textual de
         * la dirección (estado, municipio, parroquia)
         * dado el id de una parroquia.
         *
         * @method getLocation
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {int}      id    ID de la parroquia
         */
        async getLocation(url, id) {
            const vm = this;
            const query = `${window.url}/${url}/${id}`;
            axios
                .get(query)
                .then(response => {
                    vm.location.parish = response.data.parish.name;
                    vm.location.municipality =
                        response.data.parish.municipality.name;
                    vm.location.estate =
                        response.data.parish.municipality.estate.name;
                })
                .catch(error => {
                    console.error(error);
                });
        },

        /**
         * Obtiene el nombre del tipo de trasplante dado un id.
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @method getTransplantType
         * @param     {int}      transplant_id      ID del tipo de trasplante
         */
        getTransplantType(transplant_id) {
            const vm = this;
            let name = "";
            vm.transplants.forEach(element => {
                if (element.id == transplant_id) {
                    name = element.name;
                }
            });

            return name;
        },

        /**
         * Obtiene el nombre del tipo de sangre dato un id.
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @method getBloodType
         * @param     {int}      blood_type_id      ID del tipo de sangre
         */
        getBloodType(blood_type_id) {
            const vm = this;
            let name = "";
            vm.blood_groups.forEach(element => {
                if (element.id == blood_type_id) {
                    name = element.name;
                }
            });

            return name;
        },

        /**
         * Método para mostrar el género.
         *
         * @method showGender
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         */
        showGender(gender) {
            let genderType = "";
            if (gender === "F") {
                genderType = "Femenino";
            } else if (gender === "M") {
                genderType = "Masculino";
            }
            return genderType;
        },

        /**
         * Método para mostrar el estado civil.
         *
         * @method showMaritalStatus
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         */
        showMaritalStatus(maritalStatus) {
            let maritalStatusType = "";
            if (maritalStatus === "S") {
                maritalStatusType = "Soltero(a)";
            } else if (maritalStatus === "C") {
                maritalStatusType = "Casado(a)";
            } else if (maritalStatus === "D") {
                maritalStatusType = "Divorciado(a)";
            } else if (maritalStatus === "V") {
                maritalStatusType = "Viudo(a)";
            }
            return maritalStatusType;
        },

        /**
         * Obtiene el nombre del centro de trasplante dado un id.
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @method getHealthcareCenter
         * @param     {int}      healthcare_center_id      ID del centro de trasplante
         */
        getHealthcareCenter(healthcare_center_id) {
            const vm = this;
            let name = "";
            vm.healthcare_centers.forEach(element => {
                if (element.id == healthcare_center_id) {
                    name = element.name;
                }
            });
            return name;
        },

        /**
         * Obtiene el nombre del centro de dialisis dato un id.
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @method getTransplantType
         * @param     {int}      healthcare_centers_dialisis_id      ID del centro de dialisis
         */
        getHealthcareCentersDialisis(healthcare_centers_dialisis_id) {
            const vm = this;
            let name = "";
            vm.healthcare_centers_dialisis.forEach(element => {
                if (element.id == healthcare_centers_dialisis_id) {
                    name = element.name;
                }
            });
            return name;
        },

        /**
         * Método que obtiene los datos del solicitante luego guardar o actulizar.
         *
         * getRecordApplicant
         *
         * @author Francisco Escala <fjescala@gmail.com>
         */
        getRecordApplicant() {
            const vm = this;
            vm.getRecords(
                "get-aplicant/" + vm.record.profile_id,
                "aplicants"
            ).then(() => {
                if (vm.aplicants[vm.aplicants.length - 1]) {
                    var element = vm.aplicants[vm.aplicants.length - 1];
                    vm.record.rh_factor = element.rh_factor;
                    vm.url_blood_type_file = element.url_blood_type_file
                        ? element.url_blood_type_file
                        : "";
                    vm.url_medic_report_file = element.url_medic_report_file
                        ? element.url_medic_report_file
                        : "";
                    vm.url_born_file = element.url_born_file
                        ? element.url_born_file
                        : "";
                    vm.url_hla_results_file = element.url_hla_results_file
                        ? element.url_hla_results_file
                        : "";
                    vm.url_representative_dni_file = element.url_representative_dni_file
                        ? element.url_representative_dni_file
                        : "";
                    vm.url_dad_dni_file = element.url_dad_dni_file
                        ? element.url_dad_dni_file
                        : "";
                    vm.url_dad_born_file = element.url_dad_born_file
                            ? element.url_dad_born_file
                            : "";
                    vm.url_mon_dni_file = element.url_mon_dni_file
                        ? element.url_mon_dni_file
                        : "";
                    vm.url_mom_born_file = element.url_mom_born_file
                            ? element.url_mom_born_file
                            : "";    
                    vm.url_legal_certifi_file = element.url_legal_certifi_file
                        ? element.url_legal_certifi_file
                        : "";
                    vm.url_dni = element.url_dni ? element.url_dni : "";
                    vm.url_updated_photo = element.updated_photo
                        ? element.updated_photo
                        : "";
                    let living_donor = JSON.parse(element.living_donor);
                    if (element.living_donor != null) {
                        this.record.donor_arrays = [];
                        vm.record.donor = true;
                        vm.url_dni = element.url_dni ? element.url_dni : "";
                        living_donor.forEach(e => {
                            this.record.donor_arrays.push({
                                donor_names: e.donor_names,
                                donor_lastname: e.donor_lastname,
                                donor_birthday: e.donor_birthday,
                                donor_id: Math.round(
                                    Math.random() * (500000 - 2) +
                                        2 +
                                        e.donor_dni +
                                        new Date().getTime()
                                ),
                                donor_dni: e.donor_dni,
                                donor_nationality: e.donor_nationality,
                                donor_gender: e.donor_gender,
                                donor_marital_status: e.donor_marital_status,
                                donor_ocupation: e.donor_ocupation,
                                donor_academic_level: e.donor_academic_level,
                                donor_age: e.donor_age,
                                donor_cellphone: e.donor_cellphone,
                                donor_rh_factor: e.donor_rh_factor,
                                donor_phone: e.donor_phone,
                                donor_email: e.donor_email,
                                donor_relationship: parseInt(
                                    e.donor_relationship
                                ),
                                relationship_name: this.getRelationshipByID(
                                    parseInt(e.donor_relationship)
                                ),
                                donor_place_of_birth: e.donor_place_of_birth,
                                url_donor_dni_file: e.url_donor_dni_file
                                    ? e.url_donor_dni_file
                                    : "",
                                url_donor_dni_dad_file: e.url_donor_dni_dad_file
                                    ? e.url_donor_dni_dad_file
                                    : "",
                                url_donor_birth_certificate_dad_file: e.url_donor_birth_certificate_dad_file
                                    ? e.url_donor_birth_certificate_dad_file
                                    : "",
                                url_donor_birth_certificate_mother_file: e.url_donor_birth_certificate_mother_file
                                    ? e.url_donor_birth_certificate_mother_file
                                    : "",
                                url_donor_birth_certificate_grandfather_file: e.url_donor_birth_certificate_grandfather_file
                                    ? e.url_donor_birth_certificate_grandfather_file
                                    : "",
                                url_donor_birth_certificate_grandmother_file: e.url_donor_birth_certificate_grandmother_file
                                    ? e.url_donor_birth_certificate_grandmother_file
                                    : "",
                                url_donor_dni_grandmother_file: e.url_donor_dni_grandmother_file
                                    ? e.url_donor_dni_grandmother_file
                                    : "",
                                url_donor_dni_grandfather_file: e.url_donor_dni_grandfather_file
                                    ? e.url_donor_dni_grandfather_file
                                    : "",

                                url_donor_dni_mother_file: e.url_donor_dni_mother_file
                                    ? e.url_donor_dni_mother_file
                                    : "",
                                url_donor_blood_type_file: e.url_donor_blood_type_file
                                    ? e.url_donor_blood_type_file
                                    : "",
                                url_concubinage_file: e.url_concubinage_file
                                    ? e.url_concubinage_file
                                    : "",
                                url_marriage_file: e.url_marriage_file
                                    ? e.url_marriage_file
                                    : "",
                                url_adoption_file: e.url_adoption_file
                                    ? e.url_adoption_file
                                    : "",
                                url_donor_born_file: e.url_donor_born_file
                                    ? e.url_donor_born_file
                                    : "",
                                rh_factor: e.rh_factor,
                                isExpand: true,
                                donor_blood: e.donor_blood
                            });
                        });
                    }
                    vm.record.adress = element.adress;
                    vm.record.patient_blood_type = parseInt(
                        element.patient_blood_type
                    );
                    vm.record.time_dialisis = element.time_dialisis;
                    vm.record.dialisis_id = element.dialisis_id;
                    vm.record.healthcare_center_dialisis = parseInt(
                        element.healthcare_center_dialisis
                    );
                    vm.record.diagnosis = element.diagnosis;
                    vm.record.time = element.time_union;
                    vm.record.dad_names = element.dad_names
                        ? element.dad_names
                        : "";
                    vm.record.dad_lastname = element.dad_lastname
                        ? element.dad_lastname
                        : "";
                    vm.record.dad_dni_number = element.dad_dni_number
                        ? element.dad_dni_number
                        : "";
                    vm.record.dad_cellphone = element.dad_cellphone
                        ? element.dad_cellphone
                        : "";
                    vm.record.dad_phone = element.dad_phone
                        ? element.dad_phone
                        : "";
                    vm.record.mon_names = element.mon_names
                        ? element.mon_names
                        : "";
                    vm.record.mon_lastname = element.mon_lastname
                        ? element.mon_lastname
                        : "";
                    vm.record.mon_dni_number = element.mon_dni_number
                        ? element.mon_dni_number
                        : "";
                    vm.record.mon_cellphone = element.mon_cellphone
                        ? element.mon_cellphone
                        : "";
                    vm.record.mon_phone = element.mon_phone
                        ? element.mon_phone
                        : "";
                    vm.record.number = element.number;
                    vm.record.representative_names = element.representative_names
                        ? element.representative_names
                        : "";
                    vm.record.representative_lastname = element.representative_lastname
                        ? element.representative_lastname
                        : "";
                    vm.record.representative_dni_number = element.representative_dni_number
                        ? element.representative_dni_number
                        : "";
                    vm.record.representative_cellphone = element.representative_cellphone
                        ? element.representative_cellphone
                        : "";
                    vm.record.representative_phone = element.representative_phone
                        ? element.representative_phone
                        : "";
                    vm.record.doctor_dialisis_names =
                        element.doctor_dialisis_names;
                    vm.record.doctor_dialisis_lastname =
                        element.doctor_dialisis_lastname;
                    vm.record.doctor_dialisis_mail =
                        element.doctor_dialisis_mail;
                    vm.record.doctor_dialisis_dni_number =
                        element.doctor_dialisis_dni_number;
                    vm.record.doctor_dialisis_cellphone =
                        element.doctor_dialisis_cellphone;
                    vm.record.doctor_dialisis_phone =
                        element.doctor_dialisis_phone;
                    vm.record.doctor_transplant_names =
                        element.doctor_transplant_names;
                    vm.record.doctor_transplant_lastname =
                        element.doctor_transplant_lastname;
                    vm.record.doctor_transplant_mail =
                        element.doctor_transplant_mail;
                    vm.record.doctor_transplant_dni_number =
                        element.doctor_transplant_dni_number;
                    vm.record.doctor_transplant_cellphone =
                        element.doctor_transplant_cellphone;
                    vm.record.doctor_transplant_phone =
                        element.doctor_transplant_phone;
                    vm.record.doctor_names = element.doctor_names;
                    vm.record.doctor_lastname = element.doctor_lastname;
                    vm.record.doctor_dni_number = element.doctor_dni_number;
                    vm.record.doctor_cellphone = element.doctor_cellphone;
                    vm.record.doctor_phone = element.doctor_phone;
                    vm.record.doctor_mail = element.doctor_mail;
                    // Datos del médico cirujano de trasplante
                    vm.record.doctor_transplant_surgeon_names = element.doctor_transplant_surgeon_names;
                    vm.record.doctor_transplant_surgeon_lastname = element.doctor_transplant_surgeon_lastname;
                    vm.record.doctor_transplant_surgeon_mail = element.doctor_transplant_surgeon_mail;
                    vm.record.doctor_transplant_surgeon_cellphone = element.doctor_transplant_surgeon_cellphone;
                    vm.record.doctor_transplant_surgeon_phone = element.doctor_transplant_surgeon_phone;
                    vm.record.status = element.status;
                    vm.record.process_description = element.process_description;
                    vm.record.observation_request = element.observation_request;
                    if (vm.record.mon_names || vm.record.dad_names) {
                        vm.record.dads = true;
                    } else {
                        vm.record.dads = false;
                    }
                    vm.record.transplant_id = parseInt(element.transplant_id);
                    if (element.relationship != null) {
                        vm.record.relationship = element.relationship;
                    } else {
                        vm.record.relationship = "";
                    }
                    // Asignación de cita médica
                    let medical_citation_information = null;

                    medical_citation_information =
                        element.medical_citation &&
                        JSON.parse(element.medical_citation);
                    if (medical_citation_information) {
                        vm.record.citation_information.medical_citation_date =
                            medical_citation_information.medical_citation_date;
                        vm.record.citation_information.medical_citation_time =
                            medical_citation_information.medical_citation_time;
                        vm.record.citation_information.medical_citation_healthcare_center =
                            medical_citation_information.medical_citation_healthcare_center;
                        vm.record.citation_information.medical_citation_assistance =
                            medical_citation_information.medical_citation_assistance;
                        // vm.record.citation_information.medical_citation_assistance = vm.convertDate(medical_citation_information.medical_citation_assistance);
                        vm.record.citation_information.medical_citation_observation =
                            medical_citation_information.medical_citation_observation;
                    }

                    vm.record.healthcare_center = element.healthcare_center
                        ? parseInt(element.healthcare_center)
                        : "";
                    vm.record.have_healthcare_center = element.healthcare_center
                        ? true
                        : false;
                }
            });
        },

        /**
         * Método que consume el api de una url particular enviada.
         */
        getRecord(url, id) {
            const vm = this;
            const query = `${window.url}/${url}/${id}`;
            axios
                .get(query)
                .then(response => {
                    vm.record = response.data.result;
                    vm.getMunicipalities();
                    vm.getParishes();
                })
                .catch(error => {
                    console.error(error);
                });
        },

        /**
         * Método que convierte la fecha de registro del perfil.
         *
         * @author Argenis Osorio <aosorio@cenditel.gob.ve>
         */
        convertDate(date) {
            const vm = this;
            var date = new Date(date);
            return date.toLocaleDateString("en-GB");
        },

        /**
         * Método que obtiene los registros de parentesco
         *
         * @method getKinship
         *
         * @author  Luis Ramírez <lgramirez@cenditel.gob.ve>
         *
         * @param  {String}  url URL de la petición a realizar
         *
         */
        getKinship(url) {
            const vm = this;
            const query = `${window.url}/${url}`;
            axios
                .get(query)
                .then(response => {
                    vm.relationships = response.data.result;
                    vm.relationships.unshift({
                        id: "",
                        name: "Seleccione..."
                    });
                })
                .catch(error => {
                    console.error(error);
                });
        },

        /**
         * Método que obtiene los registros de parentesco según el tipo de trasplante
         *
         * @method getKinshipForTansplantType
         *
         * @author  Francisco J. P. Ruiz <fjpenya@cenditel.gob.ve | javierrupe19@gmail.com>
         *
         */
        getKinshipForTansplantType(transplant_id) {
            const vm = this;
            let relationships = [];
            relationships =
                transplant_id == 31 //Médula ósea
                    ? vm.relationships.filter(relationship =>
                        [1, 2, 3, 4].includes(relationship.id)
                    )
                    : vm.record.age >= 18
                    ? deleteKinship(vm.relationships, 435)
                    : vm.relationships;
            function deleteKinship(relationship, id) {
                const objWithIdIndex = relationship.findIndex(
                    obj => obj.id === id
                );
                if (objWithIdIndex > -1) {
                    relationship.splice(objWithIdIndex, 1);
                }
                return relationship;
            }
            return relationships;
        },

        /**
         * Método que obtiene los registros de tipo de trasplante
         *
         * @method getTransplants
         *
         * @author  Francisco J. P. Ruiz <fjpenya@cenditel.gob.ve | javierrupe19@gmail.com>
         *
         * @param  {String}  url URL de la petición a realizar
         *
         */
        getTransplants(url) {
            const vm = this;
            const query = `${window.url}/${url}`;
            axios
                .get(query)
                .then(response => {
                    /*
                    vm.transplants = response.data.result.filter(
                        transplant => transplant.id != 32
                    );
                    */
                    vm.transplants = response.data.result;
                })
                .catch(error => {
                    console.error(error);
                });
        },

        /**
         * Método que rellena el campo edad según le fecha de nacimiento
         * seleccionada.
         *
         * @method setAgeDonor
         *
         * @author Francisco Escala
         *
         * @author José Briceño <josejorgebriceno9@gmail.com>
         */
        setAgeDonor(index) {
            const vm = this;
            let age = moment().diff(
                vm.record.donor_arrays[index].donor_birthday,
                "years",
                false
            );
            vm.record.donor_arrays[index].donor_age = age > -1 ? age : "";
        },

        /**
         * Método que rellena el campo edad según le fecha de nacimiento
         * seleccionada.
         *
         * @method setAge
         *
         * @author José Briceño <josejorgebriceno9@gmail.com>
         */
        setAge() {
            const vm = this;
            let age = moment().diff(vm.record.birthdate, "years", false);
            vm.record.age = age > -1 ? age : "";
        },

        /**
         * Método que obtiene la fecha del registro y la agrega sobre el campo correspondiente
         *
         * @method  getDate
         *
         * @author  Luis Ramírez <lgramirez@cenditel.gob.ve>
         *
         */
        getDate() {
            const vm = this;
            const newDate = new Date();
            vm.record.date = newDate.toLocaleDateString("en-GB");
            vm.record.created_at = newDate.toLocaleDateString("en-GB");
        },

        /**
         * Método que mueve el puntero del formulario una vez actualizado.
         */
        updateRequest() {
            const vm = this;
            vm.update_request = true;
            window.scrollTo(0, 200);
        },

        /**
         * Define los documentos requeridos de un donante vivo para los
         * parentescos: concubinato, matrimonio y adopción.
         *
         * @method  getRelationship
         *
         * @author  Luis Ramírez <lgramirez@cenditel.gob.ve>
         * @author  Francisco Escala <fjescala@gmail.com>
         */
        getRelationship(index) {
            String.prototype.allReplace = function(obj) {
                var retStr = this;
                for (var x in obj) {
                    retStr = retStr.replace(new RegExp(x, "g"), obj[x]);
                }
                return retStr;
            };

            var relati = this.relationships.filter(
                e => e.id == this.record.donor_arrays[index].donor_relationship
            );
            var relationship = relati[0].name;
            let expresion = /concubi/i;
            let found = relationship.match(expresion);
            if (found !== null) {
                this.record.donor_arrays[index].relationship_name =
                    "concubinage";
                return "concubinage";
            }
            expresion = /espos/i;
            found = relationship.match(expresion);
            if (found !== null) {
                this.record.donor_arrays[index].relationship_name = "marriage";
                return "marriage";
            }
            expresion = /adop/i;
            found = relationship.match(expresion);
            if (found !== null) {
                this.record.donor_arrays[index].relationship_name = "adoption";
                return "adoption";
            } else {
                this.record.donor_arrays[index].relationship_name = "family";
                return "family";
            }
        },

        /**
         * Define la relacion de un donante vivo para los
         * parentescos: concubinato, matrimonio y adopción. a partir de su id
         *
         * @method  getRelationship
         *
         * @author  Luis Ramírez <lgramirez@cenditel.gob.ve>
         * @author  Francisco Escala <fjescala@gmail.com>
         */
        getRelationshipByID(id) {
            String.prototype.allReplace = function(obj) {
                var retStr = this;
                for (var x in obj) {
                    retStr = retStr.replace(new RegExp(x, "g"), obj[x]);
                }
                return retStr;
            };
            var relati = this.relationships.filter(e => e.id == id);
            var relationship = relati[0].name;
            let expresion = /concubi/i;
            let found = relationship.match(expresion);
            if (found !== null) {
                return "concubinage";
            }
            expresion = /espos/i;
            found = relationship.match(expresion);
            if (found !== null) {
                return "marriage";
            }
            expresion = /adop/i;
            found = relationship.match(expresion);
            if (found !== null) {
                return "adoption";
            } else {
                return "family";
            }
        },

        /**
         * Mostrar datos del Donante vivo.
         *
         * @method  mostrarDonante
         *
         * @author  Luis Hernández <lhernandez@mincyt.gob.ve>
         */
        mostrarDonante(index, donor_arrays) {
            this.record.donor_arrays[index].isExpand = true;
            this.keys++;
        },

        /**
         * Ocultar datos del Donante vivo.
         *
         * @method  ocultarDonante
         *
         * @author  Luis Hernández <lhernandez@mincyt.gob.ve>
         */
        ocultarDonante(index, donor_arrays) {
            this.record.donor_arrays[index].isExpand = false;
            this.keys++;
        },

        /**
         * Establece si el docmento de acta de nacimiento es obligatrio
         * dependiendo del tipo de trasplante y la edad del solicitante.
         *
         * @method  isRequiredBornFile
         *
         * @author  Francisco J. P. Ruiz <fjpenya@cenditel.gob.ve | javierrupe19@gmail.com>
         */
        isRequiredBornFile(transplant_id) {
            const vm = this;
            let is_required = true;
            switch (transplant_id) {
                // Trasplante de córnea
                case 30:
                    is_required = vm.record.age < 18 ? true : false;
                    break;
                // Trasplante de médula ósea
                case 31:
                    is_required =
                        vm.record.age < 18
                            ? true
                            : vm.record.donor
                            ? true
                            : false;
                    break;
                // Default
                default:
                    is_required = true;
                    break;
            }
            return is_required;
        },

        /**
         * Método que permite exportar la planilla de la solicitud de trasplante.
         *
         * @method exportPDF
         *
         * @author Israel Carrasquel <icarrasquel@mppct.gob.ve>
         * @author Daniel Contreras <exodiadaniel@gmail.com>
         */
        exportPDF() {
            var docDefinition = {
                info: {
                    title:
                        "Solicitud de trasplante N° " + this.modalQuery.number
                },
                pageOrientation: "portrait",
                pageMargins: [40, 60, 40, 150],
                //Cintillo
                header: {
                    image: base64images.cintilloFundavene(),
                    width: 530,
                    style: "text_center"
                },
                content: [
                    // Cabecera del reporte
                    {
                        text:
                            "\n\nInformación de la Solicitud de Trasplante \n\n",
                        style: "header"
                    },
                    {
                        columns: [
                            {
                                // Fecha de registro
                                style: "subheader",
                                text: "Fecha de registro"
                            },
                            {
                                // Serial N°
                                style: "subheader",
                                text: "Serial N°"
                            },
                            {
                                // Estatus
                                style: "subheader",
                                text: "Estatus"
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                // Fecha de registro
                                text:
                                    new Date(
                                        this.modalQuery.created_at
                                    ).toLocaleDateString("en-GB") + "\n\n",
                                style: "text_query"
                            },
                            {
                                // Serial N°
                                text: this.modalQuery.number + "\n\n",
                                style: "text_query"
                            },
                            {
                                // Estatus
                                text:
                                    this.modalQuery.status == 1
                                        ? "Enviada"
                                        : this.modalQuery.status == 2
                                        ? "Procesada"
                                        : this.modalQuery.status == 3
                                        ? "En espera de suero"
                                        : this.modalQuery.status == 4
                                        ? "Lista de espera"
                                        : this.modalQuery.status == 5
                                        ? "Lista de espera"
                                        : this.modalQuery.status == 6
                                        ? "Rechazada"
                                        : this.modalQuery.status == 7
                                        ? "Trasplante realizado"
                                        : "Sin estatus",
                                style: "text_query"
                            }
                        ]
                    },
                    // Datos personales
                    { text: "Datos personales\n\n", style: "header" },
                    {
                        columns: [
                            {
                                // Nombres
                                style: "subheader",
                                text: "Nombres"
                            },
                            {
                                // Apellidos
                                style: "subheader",
                                text: "Apellidos"
                            },
                            {
                                // Número de cédula de identidad
                                style: "subheader",
                                text: "Número de cédula de identidad"
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                text: this.modalQuery.profile.names + "\n\n",
                                style: "text_query"
                            },
                            {
                                text: this.modalQuery.profile.surnames + "\n\n",
                                style: "text_query"
                            },
                            {
                                text: this.modalQuery.profile.dni + "\n\n",
                                style: "text_query"
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                // Fecha de nacimiento
                                style: "subheader",
                                text: "Fecha de nacimiento"
                            },
                            {
                                // Edad
                                style: "subheader",
                                text: "Edad"
                            },
                            {
                                // Lugar de nacimiento
                                style: "subheader",
                                text: "Lugar de nacimiento"
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                text:
                                    new Date(
                                        this.modalQuery.profile.birthdate
                                    ).toLocaleDateString("en-GB", {
                                        timeZone: "UTC"
                                    }) + "\n\n",
                                style: "text_query"
                            },
                            {
                                text: this.modalQuery.profile.age + "\n\n",
                                style: "text_query"
                            },
                            {
                                text:
                                    this.modalQuery.profile.birthplace + "\n\n",
                                style: "text_query"
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                // Nacionalidad
                                style: "subheader",
                                text: "Nacionalidad"
                            },
                            {
                                // Género
                                style: "subheader",
                                text: "Género"
                            },
                            {
                                // Ocupación
                                style: "subheader",
                                text: "Ocupación"
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                text:
                                    this.modalQuery.profile.nationality &&
                                    this.nationalityOptions.filter(x => {
                                        return (
                                            x.id ==
                                            this.modalQuery.profile.nationality
                                        );
                                    })[0]
                                        ? this.nationalityOptions.filter(x => {
                                        return (
                                        x.id ==
                                        this.modalQuery.profile.nationality
                                        );
                                        })[0].text
                                    : "",
                                style: "text_query"
                            },
                            {
                                text:
                                    this.modalQuery.profile.gender &&
                                    this.genderOptions.filter(x => {
                                        return (
                                            x.id ==
                                            this.modalQuery.profile.gender
                                        );
                                    })[0]
                                    ? this.genderOptions.filter(x => {
                                    return (
                                    x.id ==
                                    this.modalQuery.profile.gender
                                    );
                                    })[0].text
                                    : "",
                                style: "text_query"
                            },
                            {
                                text:
                                    this.modalQuery.profile.ocupation &&
                                    this.occupations.filter(x => {
                                        return (
                                            x.id ==
                                            parseInt(
                                                this.modalQuery.profile
                                                    .ocupation
                                            )
                                        );
                                    })[0]
                                    ? this.occupations.filter(x => {
                                    return (
                                    x.id ==
                                    parseInt(this.modalQuery.profile.ocupation));
                                    })[0].name
                                    : "",
                                style: "text_query"
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                // Estado civil
                                style: "subheader",
                                text: "\nEstado civil"
                            },
                            {
                                // Nivel académico
                                style: "subheader",
                                text: "\nNivel académico "
                            },
                            {
                                // vacío
                                style: "subheader",
                                text: "\n"
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                text:
                                    this.modalQuery.profile.marital_status &&
                                    this.maritalStatusOptions.filter(x => {
                                        return (
                                            x.id ==
                                            this.modalQuery.profile
                                                .marital_status
                                        );
                                    })[0]
                                    ? this.maritalStatusOptions.filter(
                                    x => {
                                        return (
                                        x.id ==
                                        this.modalQuery.profile.marital_status);
                                    }
                                    )[0].text
                                    : "",
                                style: "text_query"
                            },
                            {
                                text:
                                    this.modalQuery.profile.academic_level +
                                    "\n\n",
                                style: "text_query"
                            },
                            {
                                // vacío
                                style: "subheader",
                                text: ""
                            }
                        ]
                    },
                    // Dirección
                    { text: "Dirección \n\n", style: "header" },
                    {
                        columns: [
                            {
                                // Estado
                                style: "subheader",
                                text: "Estado"
                            },
                            {
                                // Municipio
                                style: "subheader",
                                text: "Municipio"
                            },
                            {
                                // Parroquia
                                style: "subheader",
                                text: "Parroquia"
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                text: this.location.estate + "\n\n",
                                style: "text_query"
                            },
                            {
                                text: this.location.municipality + "\n\n",
                                style: "text_query"
                            },
                            {
                                text: this.location.parish + "\n\n",
                                style: "text_query"
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                // Dirección
                                style: "subheader",
                                text: "Dirección"
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                // Dirección
                                style: "text_query",
                                text: this.modalQuery.adress + "\n\n"
                            }
                        ]
                    },
                    // Contacto
                    { text: "Contacto\n\n", style: "header" },
                    {
                        columns: [
                            {
                                // Teléfono local
                                style: "subheader",
                                text: "Teléfono local"
                            },
                            {
                                // Teléfono móvil
                                style: "subheader",
                                text: "Teléfono móvil"
                            },
                            {
                                // Correo electrónico
                                style: "subheader",
                                text: "Correo electrónico"
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                text: this.profile.phone + "\n\n",
                                style: "text_query"
                            },
                            {
                                text: this.profile.movil + "\n\n",
                                style: "text_query"
                            },
                            {
                                text: this.user.email + "\n\n",
                                style: "text_query"
                            }
                        ]
                    },
                    // Datos del Trasplante
                    { text: "Datos del Trasplante\n\n", style: "header" },
                    {
                        columns: [
                            {
                                // Tipo de trasplante
                                style: "subheader",
                                text: "Tipo de trasplante"
                            },
                            {
                                // Grupo sanguíneo
                                style: "subheader",
                                text: "Grupo sanguíneo"
                            },
                            {
                                // Factor RH
                                style: "subheader",
                                text: "Factor RH"
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                text:
                                    this.getTransplantType(
                                        this.modalQuery.transplant_id
                                    ) + "\n\n",
                                style: "text_query"
                            },
                            {
                                text:
                                    this.getBloodType(
                                        parseInt(
                                            this.modalQuery.patient_blood_type
                                        )
                                    ) + "\n\n",
                                style: "text_query"
                            },
                            {
                                text: this.rhFactorOptions.filter(
                                    x => x.id == this.modalQuery.rh_factor
                                )[0]
                                ? this.rhFactorOptions.filter(
                                    x => x.id == this.modalQuery.rh_factor
                                )[0].text
                                    : "",
                                style: "text_query"
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                // Tipo de trasplante
                                style: "subheader",
                                text: "¿Posee Centro de trasplante?"
                            },
                            {
                                style: "subheader",
                                text: this.modalQuery.healthcare_center
                                    ? "Centro de transplante"
                                    : ""
                            },
                            {
                                style: "subheader",
                                text: ""
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                // Tipo de trasplante
                                style: "text_query",
                                text:
                                    (this.modalQuery.healthcare_center
                                        ? "Si"
                                        : "No") + "\n\n"
                            },
                            {
                                style: "text_query",
                                text: this.modalQuery.healthcare_center
                                ? this.getHealthcareCenter(
                                    this.modalQuery.healthcare_center
                                ) + "\n\n"
                                : ""
                            },
                            {
                                style: "text_query",
                                text: "\n\n"
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                // Diagnóstico
                                style: "subheader",
                                text: "Diagnóstico"
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                // Diagnóstico
                                style: "text_query",
                                text: this.modalQuery.diagnosis + "\n\n"
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                // ¿Posee posible donante vivo?
                                style: "subheader",
                                text:
                                    this.modalQuery.transplant_id != 30 &&
                                    this.modalQuery.transplant_id != 33 &&
                                    this.modalQuery.transplant_id != 34 &&
                                    this.modalQuery.transplant_id != 35
                                        ? "¿Posee posible donante vivo?"
                                        : "¿Posee Padres?"
                            },
                            {
                                // ¿Posee Padres?
                                style: "subheader",
                                text:
                                    this.modalQuery.transplant_id != 30 &&
                                    this.modalQuery.transplant_id != 33 &&
                                    this.modalQuery.transplant_id != 34 &&
                                    this.modalQuery.transplant_id != 35
                                        ? "¿Posee Padres?"
                                        : ""
                            },
                            {
                                style: "subheader",
                                text: ""
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                // ¿Posee posible donante vivo?
                                style: "text_query",
                                text:
                                    this.modalQuery.transplant_id != 30 &&
                                    this.modalQuery.transplant_id != 32 &&
                                    this.modalQuery.transplant_id != 33 &&
                                    this.modalQuery.transplant_id != 34 &&
                                    this.modalQuery.transplant_id != 35
                                    ? (this.modalQuery.living_donor &&
                                    this.modalQuery.living_donor != []
                                    ? "Si"
                                    : "No") + "\n\n"
                                    : (this.modalQuery.mon_names ||
                                    this.modalQuery.dad_names
                                    ? "Si"
                                    : "No") + "\n\n"
                            },
                            {
                                // ¿Posee Padres?
                                style: "text_query",
                                text:
                                    this.modalQuery.transplant_id != 30 &&
                                    this.modalQuery.transplant_id != 32 &&
                                    this.modalQuery.transplant_id != 33 &&
                                    this.modalQuery.transplant_id != 34 &&
                                    this.modalQuery.transplant_id != 35
                                    ? (this.modalQuery.mon_names ||
                                    this.modalQuery.dad_names
                                    ? "Si"
                                    : "No") + "\n\n"
                                    : ""
                            },
                            {
                                style: "text_query",
                                text: ""
                            }
                        ]
                    }
                ],
                styles: {
                    header: {
                        fontSize: 13,
                        bold: true,
                        alignment: "justify"
                    },
                    subheader: {
                        fontSize: 10,
                        bold: true,
                        alignment: "justify"
                    },
                    text_query: {
                        fontSize: 8,
                        alignment: "justify"
                    },
                    text_bold: {
                        fontSize: 8,
                        alignment: "center",
                        bold: true
                    },
                    text_center: {
                        fontSize: 8,
                        alignment: "center"
                    }
                }
            };
            // Datos del donante vivo
            if (
                this.modalQuery.living_donor &&
                this.modalQuery.living_donor != []
            ) {
                for (const [
                    index,
                    donor_array
                ] of this.modalQuery.living_donor.entries()) {
                    docDefinition.content.push({
                        text: "Datos del donante vivo " + (index + 1) + "\n\n",
                        style: "header"
                    });
                    docDefinition.content.push({
                        columns: [
                            {
                                // Nombres
                                style: "subheader",
                                text: "Nombres"
                            },
                            {
                                // Apellidos
                                style: "subheader",
                                text: "Apellidos"
                            },
                            {
                                // Fecha de nacimiento
                                style: "subheader",
                                text: "Fecha de nacimiento"
                            }
                        ]
                    });
                    docDefinition.content.push({
                        columns: [
                            {
                                text: donor_array.donor_names + "\n\n",
                                style: "text_query"
                            },
                            {
                                text: donor_array.donor_lastname + "\n\n",
                                style: "text_query"
                            },
                            {
                                text:
                                    new Date(
                                        donor_array.donor_birthday
                                    ).toLocaleDateString("en-GB", {
                                        timeZone: "UTC"
                                    }) + "\n\n",
                                style: "text_query"
                            }
                        ]
                    });
                    docDefinition.content.push({
                        columns: [
                            {
                                // Edad
                                style: "subheader",
                                text: "Edad"
                            },
                            {
                                // Lugar de nacimiento
                                style: "subheader",
                                text: "Lugar de nacimiento"
                            },
                            {
                                // Nacionalidad
                                style: "subheader",
                                text: "Nacionalidad"
                            }
                        ]
                    });
                    docDefinition.content.push({
                        columns: [
                            {
                                text: donor_array.donor_age + "\n\n",
                                style: "text_query"
                            },
                            {
                                text: donor_array.donor_place_of_birth + "\n\n",
                                style: "text_query"
                            },
                            {
                                text:
                                    donor_array.donor_nationality == "V"
                                        ? "Venezolano(a)"
                                        : "Extranjero(a)" + "\n\n",
                                style: "text_query"
                            }
                        ]
                    });
                    docDefinition.content.push({
                        columns: [
                            {
                                // Género
                                style: "subheader",
                                text: "Género"
                            },
                            {
                                // Estado civil
                                style: "subheader",
                                text: "Estado civil"
                            },
                            {
                                // Ocupación
                                style: "subheader",
                                text: "Ocupación"
                            }
                        ]
                    });
                    docDefinition.content.push({
                        columns: [
                            {
                                text:
                                    this.showGender(donor_array.donor_gender) +
                                    "\n\n",
                                style: "text_query"
                            },
                            {
                                text:
                                    this.showMaritalStatus(
                                        donor_array.donor_marital_status
                                    ) + "\n\n",
                                style: "text_query"
                            },
                            {
                                text:
                                    this.occupations.filter(
                                        x => x.id == donor_array.donor_ocupation
                                    )[0].name + "\n\n",
                                style: "text_query"
                            }
                        ]
                    });
                    docDefinition.content.push({
                        columns: [
                            {
                                // Nivel académico
                                style: "subheader",
                                text: "Nivel académico"
                            },
                            {
                                // Grupo sanguíneo
                                style: "subheader",
                                text: "Grupo sanguíneo"
                            },
                            {
                                // Factor RH
                                style: "subheader",
                                text: "Factor RH"
                            }
                        ]
                    });
                    docDefinition.content.push({
                        columns: [
                            {
                                text: donor_array.donor_academic_level + "\n\n",
                                style: "text_query"
                            },
                            {
                                text:
                                    this.blood_types.filter(
                                        x => x.id == donor_array.donor_blood
                                    )[0].name + "\n\n",
                                style: "text_query"
                            },
                            {
                                text:
                                    this.rhFactorOptions.filter(
                                        x => x.id == donor_array.donor_rh_factor
                                    )[0].text + "\n\n",
                                style: "text_query"
                            }
                        ]
                    });
                    docDefinition.content.push({
                        columns: [
                            {
                                // Número de cédula de identidad
                                style: "subheader",
                                text: "Número de cédula de identidad"
                            },
                            {
                                // Teléfono móvil
                                style: "subheader",
                                text: "Teléfono móvil"
                            },
                            {
                                // Teléfono local
                                style: "subheader",
                                text: "Teléfono local"
                            }
                        ]
                    });
                    docDefinition.content.push({
                        columns: [
                            {
                                text: donor_array.donor_dni + "\n\n",
                                style: "text_query"
                            },
                            {
                                text: donor_array.donor_cellphone + "\n\n",
                                style: "text_query"
                            },
                            {
                                text: donor_array.donor_phone + "\n\n",
                                style: "text_query"
                            }
                        ]
                    });
                    docDefinition.content.push({
                        columns: [
                            {
                                // Correo electrónico
                                style: "subheader",
                                text: "Correo electrónico"
                            },
                            {
                                // Parentesco
                                style: "subheader",
                                text: "Parentesco"
                            },
                            {
                                // Vacío
                                style: "subheader",
                                text: ""
                            }
                        ]
                    });
                    docDefinition.content.push({
                        columns: [
                            {
                                text: donor_array.donor_email + "\n\n",
                                style: "text_query"
                            },
                            {
                                text: this.relationships.filter(
                                    x => x.id == donor_array.donor_relationship
                                )[0]
                                    ? this.relationships.filter(
                                    x =>
                                    x.id ==
                                    donor_array.donor_relationship
                                    )[0].name + "\n\n"
                                    : "\n\n",
                                style: "text_query"
                            },
                            {
                                text:
                                    this.getRelationship(index) ===
                                    "concubinage"
                                        ? donor_array.time + "\n\n"
                                        : "\n\n",
                                style: "text_query"
                            }
                        ]
                    });
                }
            }
            // Datos del representante legal
            if (this.modalQuery.representative_names) {
                docDefinition.content.push({
                    text: "Datos del representante legal\n\n",
                    style: "header"
                });
                docDefinition.content.push({
                    columns: [
                        {
                            // Nombres
                            style: "subheader",
                            text: "Nombres"
                        },
                        {
                            // Apellidos
                            style: "subheader",
                            text: "Apellidos"
                        },
                        {
                            // Número de cédula de identidad
                            style: "subheader",
                            text: "Número de cédula de identidad"
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            text:
                                this.modalQuery.representative_names ??
                                "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text:
                                this.modalQuery.representative_lastname ??
                                "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text:
                                this.modalQuery.representative_dni_number ??
                                "" + "\n\n",
                            style: "text_query"
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            // Teléfono móvil
                            style: "subheader",
                            text: "\nTeléfono móvil"
                        },
                        {
                            // Teléfono local
                            style: "subheader",
                            text: "\nTeléfono local"
                        },
                        {
                            // Vacío
                            style: "subheader",
                            text: ""
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            text:
                                this.modalQuery.representative_cellphone ??
                                "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text:
                                this.modalQuery.representative_phone ??
                                "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text: "\n\n",
                            style: "text_query"
                        }
                    ]
                });
            }
            // Datos del Padre
            if (this.modalQuery.dad_names) {
                docDefinition.content.push({
                    text: "Datos del Padre\n\n",
                    style: "header"
                });
                docDefinition.content.push({
                    columns: [
                        {
                            // Nombres
                            style: "subheader",
                            text: "Nombres"
                        },
                        {
                            // Apellidos
                            style: "subheader",
                            text: "Apellidos"
                        },
                        {
                            // Número de cédula de identidad
                            style: "subheader",
                            text: "Número de cédula de identidad"
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.dad_names ?? "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text: this.modalQuery.dad_lastname ?? "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text: this.modalQuery.dad_dni_number ?? "" + "\n\n",
                            style: "text_query"
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            // Teléfono móvil
                            style: "subheader",
                            text: "\nTeléfono móvil"
                        },
                        {
                            // Teléfono local
                            style: "subheader",
                            text: "\nTeléfono local"
                        },
                        {
                            // Vacío
                            style: "subheader",
                            text: ""
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.dad_cellphone ?? "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text: this.modalQuery.dad_phone ?? "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text: "\n\n",
                            style: "text_query"
                        }
                    ]
                });
            }
            // Datos de la Madre
            if (this.modalQuery.mon_names) {
                docDefinition.content.push({
                    text: "Datos de la Madre\n\n",
                    style: "header"
                });
                docDefinition.content.push({
                    columns: [
                        {
                            //Nombres
                            style: "subheader",
                            text: "Nombres"
                        },
                        {
                            //Apellidos
                            style: "subheader",
                            text: "Apellidos"
                        },
                        {
                            //Número de cédula de identidad
                            style: "subheader",
                            text: "Número de cédula de identidad"
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.mon_names ?? "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text: this.modalQuery.mon_lastname ?? "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text: this.modalQuery.mon_dni_number ?? "" + "\n\n",
                            style: "text_query"
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            // Teléfono móvil
                            style: "subheader",
                            text: "\nTeléfono móvil"
                        },
                        {
                            // Teléfono local
                            style: "subheader",
                            text: "\nTeléfono local"
                        },
                        {
                            // Vacío
                            style: "subheader",
                            text: ""
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.mon_cellphone ?? "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text: this.modalQuery.mon_phone ?? "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text: "\n\n",
                            style: "text_query"
                        }
                    ]
                });
            }
            // Datos adicionales
            if (this.modalQuery.transplant_id == 28) {
                docDefinition.content.push({
                    text: "Datos adicionales\n\n",
                    style: "header"
                });
                docDefinition.content.push({
                    columns: [
                        {
                            // Tipo de diálisis
                            style: "subheader",
                            text: "Tipo de diálisis"
                        },
                        {
                            // Centro de diálisis
                            style: "subheader",
                            text: "Centro de diálisis"
                        },
                        {
                            // Fecha de inicio de diálisis
                            style: "subheader",
                            text: "Fecha de inicio de diálisis"
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            text:
                                (this.modalQuery.dialisis_id == 1
                                    ? "Peritoneal"
                                    : "Hemodiálisis") + "\n\n",
                            style: "text_query"
                        },
                        {
                            text: this.modalQuery.healthcare_center_dialisis
                            ? this.getHealthcareCentersDialisis(
                            this.modalQuery.healthcare_center_dialisis
                            ) + "\n\n"
                            : "\n\n",
                            style: "text_query"
                        },
                        {
                            text: this.modalQuery.time_dialisis
                            ? new Date(
                            this.modalQuery.time_dialisis
                            ).toLocaleDateString("en-GB", {
                            timeZone: "UTC"
                            }) + "\n\n"
                            : "\n\n",
                            style: "text_query"
                        }
                    ]
                });
                // Datos del médico de la unidad de diálisis
                docDefinition.content.push({
                    text: "Datos del médico de la unidad de diálisis\n\n",
                    style: "header"
                });
                docDefinition.content.push({
                    columns: [
                        {
                            // Nombres
                            style: "subheader",
                            text: "Nombres"
                        },
                        {
                            // Apellidos
                            style: "subheader",
                            text: "Apellidos"
                        },
                        {
                            // Correo electrónico
                            style: "subheader",
                            text: "Correo electrónico"
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            text:
                                this.modalQuery.doctor_dialisis_names ??
                                "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text:
                                this.modalQuery.doctor_dialisis_lastname ??
                                "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text:
                                this.modalQuery.doctor_dialisis_mail ??
                                "" + "\n\n",
                            style: "text_query"
                        }
                    ]
                });
                if (this.modalQuery.doctor_dialisis_phone) {
                    docDefinition.content.push({
                        columns: [
                            {
                                // Teléfono móvil
                                style: "subheader",
                                text: "\nTeléfono móvil"
                            },
                            {
                                style: "subheader",
                                text: ""
                            },
                            {
                                style: "subheader",
                                text: ""
                            }
                        ]
                    });
                    docDefinition.content.push({
                        columns: [
                            {
                                text:
                                    this.modalQuery.doctor_dialisis_cellphone ??
                                    "" + "\n\n",
                                style: "text_query"
                            },
                            {
                                text: "\n\n",
                                style: "text_query"
                            },
                            {
                                text: "\n\n",
                                style: "text_query"
                            }
                        ]
                    });
                } else {
                    docDefinition.content.push({
                        columns: [
                            {
                                // Teléfono móvil
                                style: "subheader",
                                text: "Teléfono móvil"
                            },
                            {
                                style: "subheader",
                                text: "Teléfono de la unidad de diálisis"
                            },
                            {
                                style: "subheader",
                                text: ""
                            }
                        ]
                    });
                    docDefinition.content.push({
                        columns: [
                            {
                                text:
                                    this.modalQuery.doctor_dialisis_cellphone ??
                                    "" + "\n\n",
                                style: "text_query"
                            },
                            {
                                text:
                                    this.modalQuery.doctor_dialisis_phone ??
                                    "" + "\n\n",
                                style: "text_query"
                            },
                            {
                                text: "\n\n",
                                style: "text_query"
                            }
                        ]
                    });
                }
            }
            // Datos del médico especialista en trasplante
            if (this.modalQuery.healthcare_center) {
                docDefinition.content.push({
                    text: "Datos del médico especialista en trasplante\n\n",
                    style: "header"
                });
                docDefinition.content.push({
                    columns: [
                        {
                            // Nombres
                            style: "subheader",
                            text: "Nombres"
                        },
                        {
                            // Apellidos
                            style: "subheader",
                            text: "Apellidos"
                        },
                        {
                            // Correo electrónico
                            style: "subheader",
                            text: "Correo electrónico"
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            text:
                                this.modalQuery.doctor_transplant_names ??
                                "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text:
                                this.modalQuery.doctor_transplant_lastname ??
                                "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text:
                                this.modalQuery.doctor_transplant_mail ??
                                "" + "\n\n",
                            style: "text_query"
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            // Teléfono móvil
                            style: "subheader",
                            text: "\nTeléfono móvil"
                        },
                        {
                            //  Teléfono de la unidad de diálisis
                            style: "subheader",
                            text: "\nTeléfono local"
                        },
                        {
                            style: "subheader",
                            text: ""
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            text:
                                this.modalQuery.doctor_transplant_cellphone ??
                                "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text:
                                this.modalQuery.doctor_transplant_phone ??
                                "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text: "\n\n",
                            style: "text_query"
                        }
                    ]
                });
            }
            // Datos del médico cirujano de trasplante
            if (this.modalQuery.healthcare_center) {
                docDefinition.content.push({
                    text: "Datos del médico cirujano de trasplante\n\n",
                    style: "header"
                });
                docDefinition.content.push({
                    columns: [
                        {
                            // Nombres
                            style: "subheader",
                            text: "Nombres"
                        },
                        {
                            // Apellidos
                            style: "subheader",
                            text: "Apellidos"
                        },
                        {
                            // Correo electrónico
                            style: "subheader",
                            text: "Correo electrónico"
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            text:
                                this.modalQuery.doctor_transplant_surgeon_names ??
                                "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text:
                                this.modalQuery.doctor_transplant_surgeon_lastname ??
                                "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text:
                                this.modalQuery.doctor_transplant_surgeon_mail ??
                                "" + "\n\n",
                            style: "text_query"
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            // Teléfono móvil
                            style: "subheader",
                            text: "\nTeléfono móvil"
                        },
                        {
                            //  Teléfono local
                            style: "subheader",
                            text: "\nTeléfono local"
                        },
                        {
                            style: "subheader",
                            text: ""
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            text:
                                this.modalQuery.doctor_transplant_surgeon_cellphone ??
                                "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text:
                                this.modalQuery.doctor_transplant_surgeon_phone ??
                                "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text: "\n\n",
                            style: "text_query"
                        }
                    ]
                });
            }
            // Datos del médico tratante
            if (this.modalQuery.transplant_id != 28) {
                docDefinition.content.push({
                    text: "Datos del médico tratante\n\n",
                    style: "header"
                });
                docDefinition.content.push({
                    columns: [
                        {
                            // Nombres
                            style: "subheader",
                            text: "Nombres"
                        },
                        {
                            // Apellidos
                            style: "subheader",
                            text: "Apellidos"
                        },
                        {
                            // Correo electrónico
                            style: "subheader",
                            text: "Correo electrónico"
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.doctor_names + "\n\n",
                            style: "text_query"
                        },
                        {
                            text: this.modalQuery.doctor_lastname + "\n\n",
                            style: "text_query"
                        },
                        {
                            text: this.modalQuery.doctor_mail ?? "" + "\n\n",
                            style: "text_query"
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            // Télefono móvil
                            style: "subheader",
                            text: "Télefono móvil"
                        },
                        {
                            // Teléfono local
                            style: "subheader",
                            text: "Teléfono local"
                        },
                        {
                            // Vacío
                            style: "subheader",
                            text: ""
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            text:
                                this.modalQuery.doctor_cellphone ?? "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text: this.modalQuery.doctor_phone ?? "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text: "\n\n",
                            style: "text_query"
                        }
                    ]
                });
            }
            // Cita médica asignada
            if (
                this.modalQuery.citation_information &&
                this.modalQuery.citation_information.medical_citation_date
            ) {
                docDefinition.content.push({
                    text: "Cita médica asignada \n\n",
                    style: "header"
                });
                docDefinition.content.push({
                    columns: [
                        {
                            // Fecha de la cita
                            style: "subheader",
                            text: "Fecha de la cita"
                        },
                        {
                            // Hora de la cita
                            style: "subheader",
                            text: "Hora de la cita"
                        },
                        {
                            // Centro de trasplante
                            style: "subheader",
                            text: "Centro de trasplante"
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.citation_information
                            .medical_citation_date
                            ? new Date(
                                this.modalQuery.citation_information.medical_citation_date
                                ).toLocaleDateString("en-GB", {
                                timeZone: "UTC"
                            }) + "\n\n"
                            : "\n\n",
                            style: "text_query"
                        },
                        {
                            text:
                                this.modalQuery.citation_information
                                    .medical_citation_time ?? "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text:
                                this.getHealthcareCenter(
                                    this.modalQuery.citation_information
                                        .medical_citation_healthcare_center
                                ) ?? "" + "\n\n",
                            style: "text_query"
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            // ¿Asistió a la cita?
                            style: "subheader",
                            text: "¿Asistió a la cita?"
                        },
                        {
                            // Vacío
                            style: "subheader",
                            text: ""
                        },
                        {
                            // Vacío
                            style: "subheader",
                            text: ""
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            text:
                                this.modalQuery.citation_information
                                    .medical_citation_assistance ?? "" + "\n\n",
                            style: "text_query"
                        },
                        {
                            text: "\n\n",
                            style: "text_query"
                        },
                        {
                            text: "\n\n",
                            style: "text_query"
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            // Observación del solicitante
                            style: "subheader",
                            text: "\nObservación del solicitante"
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            text:
                                this.modalQuery.citation_information
                                    .medical_citation_observation ??
                                "" + "\n\n",
                            style: "text_query"
                        }
                    ]
                });
            }
            if (this.modalQuery.observation_request) {
                docDefinition.content.push({
                    columns: [
                        {
                            // Observaciones de la solicitud
                            text: "\nObservaciones de la solicitud",
                            style: "subheader"
                        }
                    ]
                });
                docDefinition.content.push({
                    columns: [
                        {
                            text:
                                this.modalQuery.observation_request ??
                                "" + "\n\n",
                            style: "text_query"
                        }
                    ]
                });
            }
            // Footer del reporte
            docDefinition.content.push({
                image: base64images.footerPlanillasPDF(),
                width: 450,
                style: "text_center",
                absolutePosition: { x: 12, y: 730 }
            });
            pdfMake
                .createPdf(docDefinition)
                .download(
                    "Solicitud de trasplante-Serial N°" + this.modalQuery.number
                );
        }
    },

    async mounted() {
        const vm = this;
        vm.loadingState(true); // Inicio de spinner de carga.
        const ids =
            "#phone,\
            #patientCellPhone,\
            #representativePhone,\
            #representativeCellPhone,\
            #doctorTransplantPhone,\
            #doctorTransplantCellPhone,\
            #doctorPhone,\
            #doctorCellPhone";
        $(ids).on("input", event => {
            if (event.target.id == "phone") {
                vm.record.phone = event.target.value.replace(/[^0-9]/g, "");
            }
            if (event.target.id == "patientCellPhone") {
                vm.record.movil = event.target.value.replace(/[^0-9]/g, "");
            }
            if (event.target.id == "representativePhone") {
                vm.record.representative_phone = event.target.value.replace(
                    /[^0-9]/g,
                    ""
                );
            }
            if (event.target.id == "representativeCellPhone") {
                vm.record.representative_cellphone = event.target.value.replace(
                    /[^0-9]/g,
                    ""
                );
            }
            if (event.target.id == "doctorTransplantPhone") {
                vm.record.doctor_transplant_phone = event.target.value.replace(
                    /[^0-9]/g,
                    ""
                );
            }
            if (event.target.id == "doctorTransplantCellPhone") {
                vm.record.doctor_transplant_cellphone = event.target.value.replace(
                    /[^0-9]/g,
                    ""
                );
            }
            if (event.target.id == "doctorPhone") {
                vm.record.doctor_phone = event.target.value.replace(
                    /[^0-9]/g,
                    ""
                );
            }
            if (event.target.id == "doctorCellPhone") {
                vm.record.doctor_cellphone = event.target.value.replace(
                    /[^0-9]/g,
                    ""
                );
            }
        });

        vm.today = vm.getCurrentDate();
        // Obtener los registros de las ocupaciones.
        await vm.getRecords(vm.occupations_url, "occupations", true);
        // Obtener los registros de los niveles académicos.
        await vm.getRecords(
            "global-data-type/academic_level",
            "academic_levels",
            true
        );
        await vm.getKinship("global-data-type/kinship");
        await vm.getRecords("global-data-type/blood_group", "blood_groups");
        await vm.getTransplants("global-data-type/transplantation-type");
        await vm.getRecords(
            "health-center-type/healthcare",
            "healthcare_centers_dialisis"
        );
        // Obtener el listado de los grupos sanguíneos.
        await vm.getRecords("global-data-type/blood_group", "blood_types");
        await vm.getRecords(
            "health-center-type/transplant",
            "healthcare_centers"
        );
        vm.getDate();
        vm.record.email = vm.$parent.user.email;
        vm.record.user_id = vm.$parent.user.id;
        vm.record.profile_id = vm.$parent.user.profile.id;
        vm.addDonor();
        await vm
            .getRecords("get-aplicant/" + vm.record.profile_id, "aplicants")
            .then(() => {
                if (vm.aplicants[vm.aplicants.length - 1]) {
                    var element = vm.aplicants[vm.aplicants.length - 1];
                    vm.record.rh_factor = element.rh_factor;
                    vm.url_blood_type_file = element.url_blood_type_file
                        ? element.url_blood_type_file
                        : "";
                    vm.url_medic_report_file = element.url_medic_report_file
                        ? element.url_medic_report_file
                        : "";
                    vm.url_born_file = element.url_born_file
                        ? element.url_born_file
                        : "";
                    vm.url_hla_results_file = element.url_hla_results_file
                        ? element.url_hla_results_file
                        : "";
                    vm.url_dni = element.url_dni ? element.url_dni : "";
                    vm.url_updated_photo = element.updated_photo
                        ? element.updated_photo
                        : "";
                    vm.url_representative_dni_file = element.url_representative_dni_file
                        ? element.url_representative_dni_file
                        : "";
                    vm.url_dad_dni_file = element.url_dad_dni_file
                        ? element.url_dad_dni_file
                        : "";
                    vm.url_dad_born_file = element.url_dad_born_file
                        ? element.url_dad_born_file
                        : "";
                    vm.url_mon_dni_file = element.url_mon_dni_file
                        ? element.url_mon_dni_file
                        : "";
                    vm.url_mom_born_file = element.url_mom_born_file
                        ? element.url_mom_born_file
                        : "";
                    vm.url_legal_certifi_file = element.url_legal_certifi_file
                        ? element.url_legal_certifi_file
                        : "";
                    let living_donor = JSON.parse(element.living_donor);
                    if (element.living_donor != null) {
                        this.record.donor_arrays = [];
                        vm.record.donor = true;
                        vm.url_dni = element.url_dni ? element.url_dni : "";
                        living_donor.forEach(e => {
                            this.record.donor_arrays.push({
                                donor_names: e.donor_names,
                                donor_lastname: e.donor_lastname,
                                donor_birthday: e.donor_birthday,
                                donor_id: Math.round(
                                    Math.random() * (500000 - 2) +
                                        2 +
                                        e.donor_dni +
                                        new Date().getTime()
                                ),
                                donor_dni: e.donor_dni,
                                donor_nationality: e.donor_nationality,
                                donor_gender: e.donor_gender,
                                donor_marital_status: e.donor_marital_status,
                                donor_ocupation: e.donor_ocupation,
                                donor_academic_level: e.donor_academic_level,
                                donor_age: e.donor_age,
                                donor_cellphone: e.donor_cellphone,
                                donor_rh_factor: e.donor_rh_factor,
                                donor_phone: e.donor_phone,
                                donor_email: e.donor_email,
                                donor_relationship: parseInt(
                                    e.donor_relationship
                                ),
                                relationship_name: this.getRelationshipByID(
                                    parseInt(e.donor_relationship)
                                ),
                                donor_place_of_birth: e.donor_place_of_birth,
                                url_donor_dni_file: e.url_donor_dni_file
                                    ? e.url_donor_dni_file
                                    : "",
                                url_donor_dni_dad_file: e.url_donor_dni_dad_file
                                    ? e.url_donor_dni_dad_file
                                    : "",
                                url_donor_birth_certificate_dad_file: e.url_donor_birth_certificate_dad_file
                                    ? e.url_donor_birth_certificate_dad_file
                                    : "",
                                url_donor_birth_certificate_mother_file: e.url_donor_birth_certificate_mother_file
                                    ? e.url_donor_birth_certificate_mother_file
                                    : "",
                                    url_donor_birth_certificate_grandfather_file: e.url_donor_birth_certificate_grandfather_file
                                    ? e.url_donor_birth_certificate_grandfather_file
                                    : "",
                                url_donor_birth_certificate_grandmother_file: e.url_donor_birth_certificate_grandmother_file
                                    ? e.url_donor_birth_certificate_grandmother_file
                                    : "",
                                url_donor_dni_grandmother_file: e.url_donor_dni_grandmother_file
                                    ? e.url_donor_dni_grandmother_file
                                    : "",
                                url_donor_dni_grandfather_file: e.url_donor_dni_grandfather_file
                                    ? e.url_donor_dni_grandfather_file
                                    : "",
                                url_donor_dni_mother_file: e.url_donor_dni_mother_file
                                    ? e.url_donor_dni_mother_file
                                    : "",
                                url_donor_blood_type_file: e.url_donor_blood_type_file
                                    ? e.url_donor_blood_type_file
                                    : "",
                                url_concubinage_file: e.url_concubinage_file
                                    ? e.url_concubinage_file
                                    : "",
                                url_marriage_file: e.url_marriage_file
                                    ? e.url_marriage_file
                                    : "",
                                url_adoption_file: e.url_adoption_file
                                    ? e.url_adoption_file
                                    : "",
                                url_donor_born_file: e.url_donor_born_file
                                    ? e.url_donor_born_file
                                    : "",
                                rh_factor: e.rh_factor,
                                isExpand: true,
                                donor_blood: e.donor_blood
                            });
                        });
                    }
                    var date = new Date(element.created_at);
                    vm.record.created_at = date.toLocaleDateString("en-GB");
                    vm.record.date = date.toLocaleDateString("en-GB");
                    vm.record.adress = element.adress;
                    vm.record.patient_blood_type = parseInt(
                        element.patient_blood_type
                    );
                    vm.record.time_dialisis = element.time_dialisis;
                    vm.record.dialisis_id = element.dialisis_id;
                    vm.record.healthcare_center_dialisis = parseInt(
                        element.healthcare_center_dialisis
                    );
                    vm.record.diagnosis = element.diagnosis;
                    vm.record.time = element.time_union;
                    vm.record.dad_names = element.dad_names
                        ? element.dad_names
                        : "";
                    vm.record.dad_lastname = element.dad_lastname
                        ? element.dad_lastname
                        : "";
                    vm.record.dad_dni_number = element.dad_dni_number
                        ? element.dad_dni_number
                        : "";
                    vm.record.dad_cellphone = element.dad_cellphone
                        ? element.dad_cellphone
                        : "";
                    vm.record.dad_phone = element.dad_phone
                        ? element.dad_phone
                        : "";
                    vm.record.mon_names = element.mon_names
                        ? element.mon_names
                        : "";
                    vm.record.mon_lastname = element.mon_lastname
                        ? element.mon_lastname
                        : "";
                    vm.record.mon_dni_number = element.mon_dni_number
                        ? element.mon_dni_number
                        : "";
                    vm.record.mon_cellphone = element.mon_cellphone
                        ? element.mon_cellphone
                        : "";
                    vm.record.mon_phone = element.mon_phone
                        ? element.mon_phone
                        : "";
                    vm.record.number = element.number;
                    vm.record.representative_names = element.representative_names
                        ? element.representative_names
                        : "";
                    vm.record.representative_lastname = element.representative_lastname
                        ? element.representative_lastname
                        : "";
                    vm.record.representative_dni_number = element.representative_dni_number
                        ? element.representative_dni_number
                        : "";
                    vm.record.representative_cellphone = element.representative_cellphone
                        ? element.representative_cellphone
                        : "";
                    vm.record.representative_phone = element.representative_phone
                        ? element.representative_phone
                        : "";
                    vm.record.doctor_dialisis_names =
                        element.doctor_dialisis_names;
                    vm.record.doctor_dialisis_lastname =
                        element.doctor_dialisis_lastname;
                    vm.record.doctor_dialisis_dni_number =
                        element.doctor_dialisis_dni_number;
                    vm.record.doctor_dialisis_cellphone =
                        element.doctor_dialisis_cellphone;
                    vm.record.doctor_dialisis_phone =
                        element.doctor_dialisis_phone;
                    vm.record.doctor_dialisis_mail =
                        element.doctor_dialisis_mail;
                    vm.record.doctor_transplant_names =
                        element.doctor_transplant_names;
                    vm.record.doctor_transplant_lastname =
                        element.doctor_transplant_lastname;
                    vm.record.doctor_transplant_mail =
                        element.doctor_transplant_mail;
                    vm.record.doctor_transplant_dni_number =
                        element.doctor_transplant_dni_number;
                    vm.record.doctor_transplant_cellphone =
                        element.doctor_transplant_cellphone;
                    vm.record.doctor_transplant_phone =
                        element.doctor_transplant_phone;
                    vm.record.doctor_names = element.doctor_names;
                    vm.record.doctor_lastname = element.doctor_lastname;
                    vm.record.doctor_dni_number = element.doctor_dni_number;
                    vm.record.doctor_cellphone = element.doctor_cellphone;
                    vm.record.doctor_phone = element.doctor_phone;
                    vm.record.doctor_mail = element.doctor_mail;
                    vm.record.doctor_transplant_surgeon_names = element.doctor_transplant_surgeon_names;
                    vm.record.doctor_transplant_surgeon_lastname = element.doctor_transplant_surgeon_lastname;
                    vm.record.doctor_transplant_surgeon_mail = element.doctor_transplant_surgeon_mail;
                    vm.record.doctor_transplant_surgeon_cellphone = element.doctor_transplant_surgeon_cellphone;
                    vm.record.doctor_transplant_surgeon_phone = element.doctor_transplant_surgeon_phone;
                    vm.record.status = element.status;
                    setTimeout(() => {
                        if (vm.record.mon_names || vm.record.dad_names) {
                            vm.record.dads = true;
                        } else {
                            vm.record.dads = false;
                        }
                    }, 1000);
                    vm.record.process_description = element.process_description;
                    vm.record.observation_request = element.observation_request;
                    vm.record.transplant_id = parseInt(element.transplant_id);
                    // Asignación de cita médica
                    let medical_citation_information = null;
                    medical_citation_information =
                        element.medical_citation &&
                        JSON.parse(element.medical_citation);

                    if (medical_citation_information) {
                        vm.record.citation_information.medical_citation_date =
                            medical_citation_information.medical_citation_date;
                        vm.record.citation_information.medical_citation_time =
                            medical_citation_information.medical_citation_time;
                        vm.record.citation_information.medical_citation_healthcare_center =
                            medical_citation_information.medical_citation_healthcare_center;
                        vm.record.citation_information.medical_citation_assistance =
                            medical_citation_information.medical_citation_assistance;
                        // vm.record.citation_information.medical_citation_assistance = vm.convertDate(medical_citation_information.medical_citation_assistance);
                        vm.record.citation_information.medical_citation_observation =
                            medical_citation_information.medical_citation_observation;
                    }
                    if (element.relationship != null) {
                        vm.record.relationship = element.relationship;
                    } else {
                        vm.record.relationship = "";
                    }
                    vm.record.healthcare_center = element.healthcare_center
                        ? parseInt(element.healthcare_center)
                        : "";
                    vm.record.have_healthcare_center = element.healthcare_center
                        ? true
                        : false;
                }
            });

        axios.get(`${window.url}` + "/profiles/" + vm.$parent.user.id).then(response => {
            let profile = response.data.profile;
            vm.record.names = profile.names;
            vm.record.surnames = profile.surnames;
            vm.record.dni = profile.dni;
            vm.selectedOptions.nationality = profile.nationality;
            vm.selectedOptions.gender = profile.gender;
            vm.selectedOptions.ocupation = profile.ocupation
                ? parseInt(profile.ocupation)
                : "";
            vm.selectedOptions.academic_level = profile.academic_level;
            vm.selectedOptions.marital_status = profile.marital_status;
            vm.record.marital_status = profile.marital_status;
            vm.record.birthdate = profile.birthdate;
            vm.record.place_of_birth = profile.birthplace;
            vm.record.age = profile.age;
            vm.record.estate_id = profile.estate_id;
            vm.record.municipality_id = profile.municipality_id;
            vm.record.parish_id = profile.parish_id;
            vm.record.phone = profile.phone;
            vm.record.movil = profile.movil;
            vm.getEstates();
            vm.getMunicipalities();
            vm.getParishes();
        })
        .catch(error => {
            console.log(error);
        });
        vm.loadingState(); // Finaliza spinner de carga.
    },

    async created() {
        const vm = this;
        await vm.getEstates();
    }
};
</script>
