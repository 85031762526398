<template>
    <div id="transplant-request-rejected-list-component">
        <div class="card mb-4 shadow-sm">
            <h2 class="h3 text-center text-primary text-uppercase py-3
                border-bottom border-primary"
            >
                <i class="fa fa-book"></i> Solicitudes rechazadas
            </h2>
            <div class="card-body">
                <!-- Filtros de la tabla -->
                <div class="row">
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label">
                            Cédula de identidad
                        </label>
                        <input
                            id=""
                            class="form-control"
                            type="text"
                            minlength="7"
                            maxlength="9"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Cédula de identidad"
                            v-model="filterBy.dni"
                        />
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label">Género</label>
                        <v-select
                            v-model="filterSelectedOptions.genderOption"
                            :options="genderOptions"
                            :reduce="genderOption => genderOption.id"
                            label="text"
                            id="genderOption"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label">
                            Edad (Mayor que)
                        </label>
                        <input
                            id=""
                            class="form-control"
                            type="text"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Edad (Mayor que)"
                            v-model="filterBy.fromAge"
                        />
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label">
                            Edad (Menor que)
                            </label>
                        <input
                            id=""
                            class="form-control"
                            type="text"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Edad (Menor que)"
                            v-model="filterBy.toAge"
                        />
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label">
                            Grupo sanguíneo
                        </label>
                        <v-select
                            v-model="filterSelectedOptions.blodGroupOption"
                            :options="blood_groups"
                            :reduce="blodGroupOption => blodGroupOption.id"
                            label="name"
                            id="blodGroupOption"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label">
                            Factor RH
                        </label>
                        <v-select
                            v-model="filterSelectedOptions.rhFactorOption"
                            :options="rhFactorOptions"
                            :reduce="rhFactorOption => rhFactorOption.id"
                            label="text"
                            id="rhFactorOption"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="estate_id" class="form-label">
                            Estado
                        </label>
                        <v-select
                            v-model="filterSelectedOptions.estate_id"
                            :options="estates"
                            :selectable="option => option.id !== ''"
                            :reduce="estate => estate.id"
                            label="text"
                            id="estate_id"
                            :tabindex="13"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div
                        class="col-12 col-lg-3 mb-3"
                        v-if="user_role == 'ADM' || user_role == 'SUP' || user_role == 'OPS'"
                    >
                        <label for="transplante_id" class="form-label">
                            Tipo de trasplante
                        </label>
                        <v-select
                            v-model="filterSelectedOptions.transplante_id"
                            :options="transplants"
                            :reduce="transplant => transplant.id"
                            label="name"
                            id="transplantId"
                            :tabindex="6"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="row pb-4">
                        <div class="col-12 col-lg-3">
                            <button
                                class="btn btn-outline-primary-dark"
                                type="reset"
                                @click="resetFilters()"
                            >
                                Limpiar
                            </button>
                            <button
                                type="button"
                                class="btn btn-outline-primary-dark"
                                aria-label="Search"
                                @click="filterTransplanteRequest"
                            >
                                Buscar
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Final de filtros de la tabla -->

                <!-- Tabla -->
                <v-client-table
                    :columns="columns"
                    :data="records"
                    :options="table_options"
                >
                    <div slot="number" slot-scope="props">
                        {{ props.row.number }}
                    </div>
                    <div slot="created_at" slot-scope="props">
                        {{ convertDate(props.row.created_at) }}
                    </div>
                    <div slot="names" slot-scope="props">
                        {{ props.row.profile.names }}
                        {{ props.row.profile.surnames }}
                    </div>
                    <div
                        slot="id_number"
                        slot-scope="props"
                    >
                        {{ props.row.profile.dni }}
                    </div>
                    <div slot="transplant_type" slot-scope="props">
                        {{ getTransplantType(props.row.transplant_id) }}
                    </div>
                    <div slot="id" slot-scope="props">
                        <button
                            class="btn btn-outline-primary-dark"
                            type="button"
                            title="Ver registro"
                            @click="showInfo(props.row.id)"
                        >
                            <i class="fa fa-eye"></i>
                        </button>
                    </div>
                </v-client-table>
                <!-- Final de tabla -->

                <!-- Modal -->
                <div id="show_transplant_request_rejected" class="modal fade" tabindex="-1" role="dialog">
                    <div
                        class="modal-dialog modal-dialog-centered modal-xl" role="document"
                    >
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4>
                                    Información detallada de la Solicitud de
                                    trasplante rechazada
                                </h4>
                                <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <!-- Inicio modal body -->
                            <div class="modal-body">
                                <h3 class="h5 text-secondary text-uppercase">
                                    Datos del usuario que rechaza la solicitud
                                </h3>
                                <hr />
                                <div class="row pb-4">
                                    <div class="col-12 col-md-4">
                                        <div class="mb-3">
                                            <label for="agent_name" class="form-label">
                                                Nombres
                                            </label>
                                            <input id="agent_name" type="text"
                                                class="form-control" disabled
                                                v-model="modalQuery.names">
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="mb-3">
                                            <label for="agent_surnames" class="form-label">
                                                Apellidos
                                            </label>
                                            <input id="agent_surnames" type="text"
                                                class="form-control" disabled
                                                v-model="modalQuery.surnames">
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="mb-3">
                                            <label for="agent_mail" class="form-label">
                                                Correo electrónico
                                            </label>
                                            <input id="agent_mail" type="text"
                                                class="form-control" disabled
                                                v-model="agentUser.email">
                                        </div>
                                    </div>
                                </div>
                                <!-- Final de datos del usuario que realiza la gestión de rechazar la solicitud -->

                                <!-- Datos del solicitante -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Datos personales del solicitante
                                </h3>
                                <hr />
                                <div class="row pb-4">
                                    <!-- Fecha de registro -->
                                    <div class="col-12 col-lg-2 mb-3">
                                        <label for="created_at" class="form-label">
                                            Fecha de registro
                                        </label>
                                        <input
                                            id="created_at"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="
                                                new Date(
                                                    record.created_at
                                                ).toLocaleDateString('en-GB')
                                            "
                                        />
                                    </div>

                                    <!-- Serial N° -->
                                    <div class="col-12 col-lg-2 mb-3">
                                        <label for="number" class="form-label">
                                            Serial N°
                                        </label>
                                        <input
                                            id="number"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="record.number"
                                        />
                                    </div>
                                </div>

                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="names" class="form-label">
                                            Nombres</label>
                                        <input
                                            id="names"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="record.profile.names"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="surnames" class="form-label">
                                            Apellidos
                                        </label>
                                        <input
                                            id="surnames"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="record.profile.surnames"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="dni" class="form-label">
                                            Cédula de identidad
                                        </label>
                                        <input
                                            id="dni"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="record.profile.dni"
                                        />
                                    </div>
                                    <!-- Nacionalidad -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="nationality" class="form-label">
                                            Nacionalidad
                                        </label>
                                        <input
                                            id="nationality"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="record.profile.nationality && nationalityOptions.filter(x=> { return x.id==record.profile.nationality})[0] ? nationalityOptions.filter(x=> { return x.id==record.profile.nationality})[0].text:'' "
                                        />
                                    </div>
                                    <!-- Género -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="gender" class="form-label">
                                            Género
                                        </label>
                                        <input
                                            id="gender"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="record.profile.gender && genderOptions.filter(x=> { return x.id==record.profile.gender})[0] ? genderOptions.filter(x=> { return x.id==record.profile.gender})[0].text:'' "
                                        />
                                    </div>
                                    <!-- Ocupación -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="ocupation" class="form-label">
                                            Ocupación
                                        </label>
                                        <input
                                            id="gender"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="record.profile.ocupation && occupations.filter(x=> { return x.id==parseInt(record.profile.ocupation)})[0] ? occupations.filter(x=> { return x.id==parseInt(record.profile.ocupation)})[0].name:'' "
                                        />
                                    </div>
                                    <!-- Estado civil -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="marital_status" class="form-label">
                                            Estado civil
                                        </label>
                                        <input
                                            id="gender"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="record.profile.marital_status && maritalStatusOptions.filter(x=> { return x.id==record.profile.marital_status})[0] ? maritalStatusOptions.filter(x=> { return x.id==record.profile.marital_status})[0].text:'' "
                                        />
                                    </div>
                                    <!-- Nivel académico -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="academic_levels" class="form-label">
                                            Nivel académico
                                        </label>
                                        <input
                                            id="gender"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="record.profile.academic_level"
                                        />
                                    </div>
                                </div>

                                <!-- Edad -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Edad
                                </h3>
                                <hr />
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="birthdate"
                                            class="form-label"
                                        >
                                            Fecha de nacimiento
                                        </label>
                                        <input
                                            id="birthdate"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="new Date(record.profile.birthdate).toLocaleDateString('en-GB', { timeZone: 'UTC' })"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="age" class="form-label">
                                            Edad
                                        </label>
                                        <input
                                            id="age"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="record.profile.age"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="birthplace"
                                            class="form-label"
                                        >
                                            Lugar de nacimiento
                                        </label>
                                        <input
                                            id="birthplace"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="record.profile.birthplace"
                                        />
                                    </div>
                                </div>
                                <!-- Final de edad -->

                                <!-- Dirección -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Dirección
                                </h3>
                                <hr />
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="estate" class="form-label">
                                            Estado
                                        </label>
                                        <input
                                            id="estate"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="location.estate"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="municipality"
                                            class="form-label"
                                        >
                                            Municipio
                                        </label>
                                        <input
                                            id="municipality"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="location.municipality"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="parish" class="form-label">
                                            Parroquia
                                        </label>
                                        <input
                                            id="parish"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="location.parish"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="adress" class="form-label">
                                            Dirección
                                        </label>
                                        <textarea
                                            id="adress"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            rows="4"
                                        >{{record.adress}}</textarea>

                                    </div>
                                </div>
                                <!-- Final de dirección -->

                                <!-- Contacto -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Contacto
                                </h3>
                                <hr />
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="movil" class="form-label">
                                            Teléfono móvil
                                        </label>
                                        <input
                                            id="movil"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="profile.movil"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="phone" class="form-label">
                                            Teléfono local
                                        </label>
                                        <input
                                            id="phone"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="profile.phone"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="email" class="form-label">
                                            Correo electrónico
                                        </label>
                                        <input
                                            id="email"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="user.email"
                                        />
                                    </div>
                                </div>
                                <!-- Final de Contacto -->

                                <!-- Datos del Trasplante -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Datos del Trasplante
                                </h3>
                                <hr />
                                <div class="row pb-4">
                                    <!-- Tipo de trasplante -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="transplant_id" class="form-label" >
                                            Tipo de trasplante
                                        </label>
                                        <input
                                            id="transplant_id"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="getTransplantType(record.transplant_id)"
                                        />
                                    </div>
                                    <!-- Grupo sanguíneo -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="patient_blood_type" class="form-label">
                                            Grupo sanguíneo
                                        </label>
                                        <input
                                            id="patient_blood_type"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="getBloodType(parseInt(record.patient_blood_type))"
                                        />
                                    </div>
                                    <!-- Factor RH -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="rhFactor" class="form-label">
                                            Factor RH
                                        </label>
                                        <input
                                            id="rhFactor"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="rhFactorOptions.filter(x =>x.id == record.rh_factor )[0]?rhFactorOptions.filter(x =>x.id == record.rh_factor )[0].text:''"
                                        />
                                    </div>
                                </div>    

                                <!-- Final de datos del solicitante -->

                                <!-- Razones por las que se rechaza la solicitud -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Razones por las que se rechaza la solicitud
                                </h3>
                                <hr />
                                <div class="row">
                                    <div class="col-12 col-lg-12">
                                        <textarea rows="5" class="form-control" disabled
                                            v-model="record.rejection_reason"
                                        >
                                        </textarea>
                                    </div>
                                </div>
                                <!-- Final de Razones por las que se rechaza la solicitud -->
                            </div>
                            <!-- Fin modal body -->
                            <div class="modal-footer justify-content-center">
                                <button
                                    class="btn btn-outline-primary-dark"
                                    type="button"
                                    aria-label="Close"
                                    data-bs-dismiss="modal"
                                >
                                    Cerrar
                                </button>
                                <button class="btn btn-secondary-dark" type="button" @click="exportPDF()">
                                    <i class="fa fa-address-card"></i>
                                    Generar planilla
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Final del modal -->
            </div>
        </div>
    </div>
</template>

<script>
import base64images from '../../base64images'
export default {
    data() {
        return {
            records: [],
            record: {
                profile:''
            },
            transplant_type: [],
            healthcare_center: [],
            blood_type: [],
            healthcare_centers_dialisis: [],
            user_role: "",
            columns: [
                "number",
                "created_at",
                "names",
                "id_number",
                "transplant_type",
                "id"
            ],
            url: "transplant-request-rejected",
            url_transplantation_type: "global-data-type/transplantation-type",
            url_healthcare_center: "health-center-type/transplant",
            url_blood_type: "global-data-type/blood_type",
            url_healthcare_centers_dialisis: "health-center-type/healthcare",
            modalQuery: {},
            profile: {},
            user: {},
            agentUser: {},
            location: {
                parish: "",
                municipality: "",
                estate: ""
            },
            filterBy: {
                dni: "",
                fromAge: "",
                toAge: ""
            },
            filterSelectedOptions: {
                donationOption: "",
                genderOption: "",
                blodGroupOption: "",
                rhFactorOption: "",
                estate_id: "",
                transplante_id: "",
                estatus_id: ""
            },
            rhFactorOptions: [
                { id: "P", text: "Positivo" },
                { id: "N", text: "Negativo" }
            ],
            donationOptions: [
                { id: "T", text: "Total" },
                { id: "P", text: "Parcial" }
            ],
            genderOptions: [
                { id: "M", text: "Masculino" },
                { id: "F", text: "Femenino" }
            ],
            estates: [],
            blood_groups: [],
            transplants: [],
            estatusOptions: [
                { id: 1, text: "Recibida" },
                { id: 2, text: "Procesada" },
                { id: 99, text: "Negada" }
            ],
            tmpRecords: [],
            nationalityOptions: [
                { id: "V", text: "Venezolano(a)" },
                { id: "E", text: "Extranjero(a)" }
            ],
            maritalStatusOptions: [
                { id: "S", text: "Soltero(a)" },
                { id: "C", text: "Casado(a)" },
                { id: "D", text: "Divorciado(a)" },
                { id: "V", text: "Viudo(a)" }
            ],
            occupations_url: "global-data-type/occupation",
            occupations: [],
            relationships: []
        };
    },
    methods: {

        /**
         * Método para mostrar la informacion de un registro
         * dado su ID en un modal
         *
         * @method showInfo
         *
         * @author Israel Carrasquel <icarrasquel@mppct.gob.ve>
         * @param     {int}      id    ID del registro
         */
        async showInfo(id) {
            const vm = this;
            let recordShow = JSON.parse(JSON.stringify(vm.records.filter((rec) => {
                return rec.id === id;
            })[0]));
            vm.record = recordShow;

            await vm.getProfileComplet(vm.record.agent_user_id);
            await vm.getUser("users", vm.record.profile.user_id);
            await vm.getAgentUser("users", vm.record.agent_user_id);
            vm.profile = recordShow.profile;
            vm.getLocation("parish", vm.profile.parish_id);
            $("#show_transplant_request_rejected").modal("show");
        },

        /**
         * Método que permite exportar la planilla de la Solicitud de trasplante rechazada.
         *
         * @method exportPDF
         *
         * @author Israel Carrasquel <icarrasquel@mppct.gob.ve>
        */
        exportPDF() {
            var docDefinition = {
                info: {
                    title: 'Solicitud de trasplante rechazada N° '+this.record.number,
                },
                pageOrientation: 'portrait',
                pageMargins: [40, 60, 40, 60],
                //Cintillo
                header: {
                    image: base64images.cintilloFundavene(),
                    width: 530,
                    style:'text_center'
                },
                content: [
                    // Cabecera del reporte
                    { text: 'Información de la Solicitud de trasplante rechazada \n\n',
                        style: 'header',
                    },
                    // Usuario que rechazo la solicitud.
                    { text: 'Datos del usuario que rechazo la solicitud\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Nombres
                                style: 'subheader',
                                text: 'Nombres',
                            },
                            {
                                // Apellidos
                                style: 'subheader',
                                text: 'Apellidos',
                            },
                            {
                                // Correo electrónico
                                style: 'subheader',
                                text: 'Correo electrónico',
                            }
                        ],
                    },
                    {
                        columns: [
                            {

                                text: this.modalQuery.names,
                                style: 'text_query',
                            },
                            {
                                text: this.modalQuery.surnames,
                                style: 'text_query',
                            },
                            {
                                text: this.agentUser.email,
                                style: 'text_query',
                            }
                        ],
                    },
                    // Datos del solicitante 
                    { text: '\nDatos personales del solicitante \n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Fecha de registro
                                style: 'subheader',
                                text: 'Fecha de registro',
                            },
                            {
                                // Serial N°
                                style: 'subheader',
                                text: 'Serial N°',
                            },
                            {
                                // Vacío
                                text: '',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Fecha de registro
                                text: new Date(this.record.created_at).toLocaleDateString('en-GB')+'\n\n',
                                style: 'text_query',
                            },
                            {
                                // Serial N°
                                text: this.record.number+'\n\n', style: 'text_query',
                            },
                            {
                                // Vacío
                                text: '',
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                // Nombres
                                style: 'subheader',
                                text: 'Nombres',
                            },
                            {
                                // Apellidos
                                style: 'subheader',
                                text: 'Apellidos',
                            },
                            {
                                // Cédula de identidad
                                style: 'subheader',
                                text: 'Cédula de identidad',
                            },
                        ],
                    },
                    {
                        columns: [
                            {

                                text: this.record.profile.names+'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.record.profile.surnames+'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.record.profile.dni+'\n\n',
                                style: 'text_query',
                            }
                        ],
                    },
                    {
                        columns: [
                            {
                                // Nacionalidad
                                style: 'subheader',
                                text: 'Nacionalidad',
                            },
                            {
                                // Género
                                style: 'subheader',
                                text: 'Género',
                            },
                            {
                                // Ocupación
                                style: 'subheader',
                                text: 'Ocupación',
                            },
                        ],
                    },
                    {
                        columns: [
                            {

                                text: this.record.profile.nationality && this.nationalityOptions.filter(x=> { return x.id==this.record.profile.nationality})[0] ? this.nationalityOptions.filter(x=> { return x.id==this.record.profile.nationality})[0].text:'',
                                style: 'text_query',
                            },
                            {
                                text: this.record.profile.gender && this.genderOptions.filter(x=> { return x.id==this.record.profile.gender})[0] ? this.genderOptions.filter(x=> { return x.id==this.record.profile.gender})[0].text:'',
                                style: 'text_query',
                            },
                            {
                                text: this.record.profile.ocupation && this.occupations.filter(x=> { return x.id==parseInt(this.record.profile.ocupation)})[0] ? this.occupations.filter(x=> { return x.id==parseInt(this.record.profile.ocupation)})[0].name:'',
                                style: 'text_query',
                            }
                        ],
                    },
                    {
                        columns: [
                            {
                                // Estado civil
                                style: 'subheader',
                                text: '\nEstado civil',
                            },
                            {
                                // Nivel académico 
                                style: 'subheader',
                                text: '\nNivel académico ',
                            },
                            {
                                // vacio
                                style: 'subheader',
                                text: '\n',
                            },
                        ],
                    },
                    {
                        columns: [
                            {

                                text: this.record.profile.marital_status && this.maritalStatusOptions.filter(x=> { return x.id==this.record.profile.marital_status})[0] ? this.maritalStatusOptions.filter(x=> { return x.id==this.record.profile.marital_status})[0].text:'',
                                style: 'text_query',
                            },
                            {
                                text: this.record.profile.academic_level+'\n\n',
                                style: 'text_query',
                            },
                            {
                                // vacio
                                style: 'subheader',
                                text: '',
                            },
                        ],
                    },
                    //Edad
                    { text: 'Edad \n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Fecha de nacimiento
                                style: 'subheader',
                                text: 'Fecha de nacimiento',
                            },
                            {
                                // Edad
                                style: 'subheader',
                                text: 'Edad',
                            },
                            {
                                // Lugar de nacimiento
                                style: 'subheader',
                                text: 'Lugar de nacimiento',
                            },
                        ],
                    },
                    {
                        columns: [
                            {

                                text: new Date(this.record.profile.birthdate).toLocaleDateString('en-GB', { timeZone: 'UTC' }) +'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.record.profile.age+'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.record.profile.birthplace+'\n\n',
                                style: 'text_query',
                            }
                        ],
                    },
                    //Dirección
                    { text: 'Dirección \n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Estado
                                style: 'subheader',
                                text: 'Estado',
                            },
                            {
                                // Municipio
                                style: 'subheader',
                                text: 'Municipio',
                            },
                            {
                                // Parroquia
                                style: 'subheader',
                                text: 'Parroquia',
                            },
                        ],
                    },
                    {
                        columns: [
                            {

                                text: this.location.estate +'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.location.municipality+'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.location.parish+'\n\n',
                                style: 'text_query',
                            }
                        ],
                    },
                    {
                        columns: [
                            {
                                // Dirección
                                style: 'subheader',
                                text: 'Dirección',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Dirección
                                style: 'text_query',
                                text: this.record.adress+'\n\n',
                            },
                        ],
                    },
                    //Contacto
                    { text: 'Contacto \n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Teléfono móvil
                                style: 'subheader',
                                text: 'Teléfono móvil',
                            },
                            {
                                // Teléfono local
                                style: 'subheader',
                                text: 'Teléfono local',
                            },
                            {
                                // Correo electrónico
                                style: 'subheader',
                                text: 'Correo electrónico',
                            },
                        ],
                    },
                    {
                        columns: [
                            {

                                text: this.profile.movil +'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.profile.phone+'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.user.email+'\n\n',
                                style: 'text_query',
                            }
                        ],
                    },
                    //Datos del Trasplante
                    { text: 'Datos del Trasplante \n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Tipo de trasplante
                                style: 'subheader',
                                text: 'Tipo de trasplante',
                            },
                            {
                                // Grupo sanguíneo
                                style: 'subheader',
                                text: 'Grupo sanguíneo',
                            },
                            {
                                // Factor RH
                                style: 'subheader',
                                text: 'Factor RH',
                            },
                        ],
                    },
                    {
                        columns: [
                            {

                                text: this.getTransplantType(this.record.transplant_id) +'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.getBloodType(parseInt(this.record.patient_blood_type))+'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.rhFactorOptions.filter(x =>x.id == this.record.rh_factor )[0]?this.rhFactorOptions.filter(x =>x.id == this.record.rh_factor )[0].text:'',
                                style: 'text_query',
                            }
                        ],
                    },
                    { text: 'Razones por las que se rechazo la solicitud \n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Razones por las que se rechaza la solicitud
                                style: 'text_query',
                                text: this.record.rejection_reason,
                            },
                        ],
                    },
                    // Footer del reporte
                    {
                        image: base64images.footerPlanillasPDF(),
                        width: 450,
                        style: 'text_center',
                        absolutePosition: {x: 12, y: 730}
                    },
                ],
                styles: {
                    header: {
                        fontSize: 13,
                        bold: true,
                        alignment: 'justify'
                    },
                    subheader: {
                        fontSize: 10,
                        bold: true,
                        alignment: 'justify'
                    },
                    text_query: {
                        fontSize: 8,
                        alignment: 'justify'
                    },
                    text_bold: {
                        fontSize: 8,
                        alignment: 'center',
                        bold: true
                    },
                    text_center: {
                        fontSize: 8,
                        alignment: 'center',
                    },
                }
            }
            pdfMake.createPdf(docDefinition).download('Solicitud de trasplante rechazada -Serial N°'+this.record.number+'-CI-'+this.record.profile.dni);
        },
        /**
         * Obtiene todos los datos del perfil según el id del usuario.
         *
         * @method getProfileComplet
         *
         */
        async getProfileComplet(id) {
            const vm = this;
            await axios
                .get(`${window.url}` + "/profiles/" + id)
                .then(response => {
                    vm.modalQuery = response.data.profile;
                    if (vm.modalQuery.parish_id) {
                        this.getLocation("parish", vm.modalQuery.parish_id);
                    } else {
                        vm.location.parish = "";
                        vm.location.municipality = "";
                        vm.location.estate = "";
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        /**
         * Obtiene el nombre del tipo de trasplante dato un id.
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @method getTransplantType
         * @param     {int}      transplant_id      ID del tipo de trasplante
         */
        getTransplantType(transplant_id) {
            const vm = this;
            let name = "";
            vm.transplant_type.forEach(element => {
                if (element.id == transplant_id) {
                    name = element.name;
                }
            });
            return name;
        },

        /**
         * Obtiene el nombre del centro de trasplante dato un id.
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @method getTransplantType
         * @param     {int}      healthcare_center_id      ID del centro de trasplante
         */
        getHealthcareCenter(healthcare_center_id) {
            const vm = this;
            let name = "";
            vm.healthcare_center.forEach(element => {
                if (element.id == healthcare_center_id) {
                    name = element.name;
                }
            });
            return name;
        },

        /**
         * Obtiene el nombre del centro de dialisis dato un id.
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @method getTransplantType
         * @param     {int}      healthcare_centers_dialisis_id      ID del centro de dialisis
         */
        getHealthcareCentersDialisis(healthcare_centers_dialisis_id) {
            const vm = this;
            let name = "";
            vm.healthcare_centers_dialisis.forEach(element => {
                if (element.id == healthcare_centers_dialisis_id) {
                    name = element.name;
                }
            });
            return name;
        },

        /**
         * Obtiene el nombre del tipo de sangre dato un id.
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @method getTransplantType
         * @param     {int}      blood_type_id      ID del tipo de sangre
         */
        getBloodType(blood_type_id) {
            const vm = this;
            let name = "";
            vm.blood_groups.forEach(element => {
                if (element.id == blood_type_id) {
                    name = element.name;
                }
            });
            return name;
        },

        /**
         * Método que cambia el formato de visualización de la fecha en la
         * tabla de registros.
         *
         * @method convertDate
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {date}      date     fecha ha ser fornateada
         */
        convertDate(date) {
            return new Date(date).toLocaleDateString("en-GB");
        },

        /**
         * Método para obtener los datos del registro del usuario
         * que realiza la gestión de la solicitud rechazada
         *
         * @method getAgentUser
         *
         * @author Israel Carrasquel <icarrasquel@mppct.gob.ve>
         * @param     {int}      id    ID del usuario
         */
         async getAgentUser(url, id) {
            const vm = this;
            const query = `${window.url}/${url}/${id}`;
            axios.get(query).then(response => {
                vm.agentUser = response.data.result;
            })
            .catch(error => {
                console.error(error);
            });
        },

        /**
         * Método para obtener los datos del registro de un usuario
         *
         *
         * @method getLocation
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {int}      id    ID del usuario
         */
        async getUser(url, id) {
            const vm = this;
            const query = `${window.url}/${url}/${id}`;
            axios.get(query).then(response => {
                vm.user = response.data.result;
            })
            .catch(error => {
                console.error(error);
            });
        },

        /**
         * Método para obtener la representación textual de
         * la dirección (estado, municipio, parroquia)
         * dado el id de una parroquia.
         *
         * @method getLocation
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {int}      id    ID de la parroquia
         */
        async getLocation(url, id) {
            const vm = this;
            const query = `${window.url}/${url}/${id}`;
            axios.get(query)
            .then(response => {
                vm.location.parish = response.data.parish.name;
                vm.location.municipality =
                    response.data.parish.municipality.name;
                vm.location.estate =
                    response.data.parish.municipality.estate.name;
            })
            .catch(error => {
                console.error(error);
            });
        },

        /**
         * Método que permite filtrar los datos de la tabla.
         *
         * @method filterTransplanteRequest
         *
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        filterTransplanteRequest() {
            const vm = this;
            vm.records = vm.tmpRecords
                .filter(rec => {
                    return vm.filterBy.dni
                        ? rec.profile.dni === vm.filterBy.dni
                        : true;
                })
                .filter(rec => {
                    return vm.filterBy.fromAge
                        ? rec.profile.age >= vm.filterBy.fromAge
                        : true;
                })
                .filter(rec => {
                    return vm.filterBy.toAge
                        ? rec.profile.age <= vm.filterBy.toAge
                        : true;
                })
                .filter(rec => {
                    return vm.filterSelectedOptions.genderOption
                    ? rec.profile.gender ===
                    vm.filterSelectedOptions.genderOption
                    : true;
                })
                .filter(rec => {
                    return vm.filterSelectedOptions.blodGroupOption
                    ? parseInt(rec.patient_blood_type) ===
                    parseInt(vm.filterSelectedOptions.blodGroupOption)
                    : true;
                })
                .filter(rec => {
                    return vm.filterSelectedOptions.rhFactorOption
                    ? rec.rh_factor ===
                    vm.filterSelectedOptions.rhFactorOption
                    : true;
                })
                .filter(rec => {
                    return vm.filterSelectedOptions.estate_id &&
                    vm.filterSelectedOptions.estate_id != ""
                    ? parseInt(rec.profile.estate_id) ===
                    parseInt(vm.filterSelectedOptions.estate_id)
                    : true;
                })
                .filter(rec => {
                    return vm.filterSelectedOptions.transplante_id &&
                    vm.filterSelectedOptions.transplante_id != ""
                    ? parseInt(rec.transplant_id) ===
                    parseInt(vm.filterSelectedOptions.transplante_id)
                    : true;
                });
        },

        /**
         * Método para reestablecer valores iniciales del formulario de filtros.
         *
         * @method resetFilters
         *
         * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <kingangelo_123@gmail.com>
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        resetFilters() {
            const vm = this;
            vm.filterBy = {
                dni: "",
                fromAge: "",
                toAge: ""
            };
            vm.filterSelectedOptions.genderOption = "";
            vm.filterSelectedOptions.blodGroupOption = "";
            vm.filterSelectedOptions.rhFactorOption = "";
            vm.filterSelectedOptions.estate_id = "";
            vm.filterSelectedOptions.transplante_id = "";
            vm.filterSelectedOptions.estatus_id = "";
            vm.records = vm.tmpRecords;
        },

        /**
         * Método que obtiene los registros de parentesco
         *
         * @method getKinship
         *
         * @author  Luis Ramírez <lgramirez@cenditel.gob.ve>
         *
         * @param  {String}  url URL de la petición a realizar
         *
         */
        getKinship(url) {
            const vm = this;
            const query = `${window.url}/${url}`;
            axios.get(query)
            .then(response => {
                vm.relationships = response.data.result;
                vm.relationships.unshift({
                    id: "",
                    name: "Seleccione..."
                });
            })
            .catch(error => {
                console.error(error);
            });
        },

        /**
         * Método para mostrar el género
         *
         * @method showGender
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         */
        showGender(gender) {
            let genderType = "";
            if (gender === "F") {
                genderType = "Femenino";
            } else if (gender === "M") {
                genderType = "Masculino";
            }
            return genderType;
        },

        /**
         * Método para mostrar el estado civil.
         *
         * @method showMaritalStatus
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         */
        showMaritalStatus(maritalStatus) {
            let maritalStatusType = "";
            if (maritalStatus === "S") {
                maritalStatusType = "Soltero(a)";
            } else if (maritalStatus === "C") {
                maritalStatusType = "Casado(a)";
            } else if (maritalStatus === "D") {
                maritalStatusType = "Divorciado(a)";
            } else if (maritalStatus === "V") {
                maritalStatusType = "Viudo(a)";
            }
            return maritalStatusType;
        }
    },
    async created() {
        this.table_options.headings = {
            number: 'Serial N°',
            created_at: 'Fecha de registro',
            names: "Nombres y Apellidos",
            id_number: "Cédula",
            transplant_type: "Tipo de trasplante",
            id: "Acción"
        };
        this.table_options.sortable = [
            "number",
            "created_at",
            "names",
            "id_number",
            "transplant_type",
        ];
        this.table_options.filterable = [
            "number",
            "created_at",
            "names",
            "id_number",
            "transplant_type",
        ];
        this.table_options.columnsClasses = {
            id: "text-center"
        };
        this.table_options.orderBy = { column: "number" };
    },
    async mounted() {
        const vm = this;
        /**
         * Si el usuario autenticado intenta acceder al componente sin tener el
         * rol requerido este no carta los diferentes datos a consultar y
         * redirecciona a la pantalla inicial.
        */
        // Obtener el rol del usuario autenticado
        vm.user_role = vm.$parent.user.role;
        if (
                (vm.user_role == 'ADM') ||
                (vm.user_role == 'SUP') ||
                (vm.user_role == 'OPS') ||
                (vm.user_role == 'OPSR') ||
                (vm.user_role == 'OPSH') ||
                (vm.user_role == 'OPSC') ||
                (vm.user_role == 'OPSM') ||
                (vm.user_role == 'OPSV') ||
                (vm.user_role == 'OPSCOR') ||
                (vm.user_role == 'OPSP') ||
                (vm.user_role == 'OPSPU')
            ) {
            vm.loadingState(true); // Inicio de spinner de carga.
            await vm.getRecords(vm.url, "records");
            await vm.getRecords(vm.url_transplantation_type, "transplant_type");
            await vm.getRecords(vm.url_healthcare_center, "healthcare_center");
            await vm.getRecords(vm.url_blood_type, "blood_type");
            // Obtener los estados
            await vm.getEstates();
            await vm.getRecords(
                vm.url_healthcare_centers_dialisis,
                "healthcare_centers_dialisis"
            );
            await vm.getRecords("global-data-type/blood_group", "blood_groups");
            await vm.getRecords(
                "global-data-type/transplantation-type",
                "transplants"
            );
            await vm.getRecords(vm.occupations_url, "occupations", true);
            await vm.getRecords(
                "global-data-type/academic_level",
                "academic_levels",
                true
            );
            await vm.getRecords("global-data-type/blood_group", "blood_types");
            await vm.getKinship("global-data-type/kinship");
            vm.tmpRecords = vm.records;
            vm.loadingState(); // Finaliza spinner de carga.
        }
        else {
            location.href = '/';
        }
    }
};
</script>
