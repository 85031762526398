<template>
    <div id="non-donor-by-family-decision-component">
        <go-back title="Regresar"/>
        <div class="card mb-4 shadow-sm">
            <h2 class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary">
                <i class="fa fa-th-list"></i> Gestión para no donante por negativa familiar
            </h2>
            <div class="card-body">
                <form-errors :errors="errors"></form-errors>
                <div class="row pb-4">
                    <div class="col-12 col-lg-2 mb-3">
                        <label for="date" class="form-label">
                            Fecha de registro
                        </label>
                        <input id="date" class="form-control" name="date"
                            type="text" readonly v-model="record.date"/>
                    </div>
                    <div class="col-12 col-lg-2 mb-3" v-if="record.number">
                        <label for="number" class="form-label">Serial N°</label>
                        <input id="number" class="form-control" name="number"
                        type="text" readonly tabindex="-1" v-model="record.number" />
                    </div>
                </div>
                <!-- Datos del usuario que realiza el registro -->
                <h3 class="h5 text-secondary text-uppercase">
                    Usuario que realiza la gestión del no donante por negativa familiar
                </h3>
                <hr>
                <div class="pb-4">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="mb-3">
                                <label for="agent_name" class="form-label">
                                    Nombres
                                </label>
                                <input id="agent_name" type="text"
                                    class="form-control" disabled
                                    v-model="record.agent_names">
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="mb-3">
                                <label for="agent_surnames" class="form-label">
                                    Apellidos
                                </label>
                                <input id="agent_surnames" type="text"
                                    class="form-control" disabled
                                    v-model="record.agent_surnames">
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="mb-3">
                                <label for="agent_mail" class="form-label">
                                    Correo electrónico
                                </label>
                                <input id="agent_mail" type="text"
                                    class="form-control" disabled
                                    v-model="record.agent_mail">
                            </div>
                        </div>
                    </div>
                </div>
                <!--/ Datos del usuario que realiza el registro -->
                <!-- Datos del donante -->
                <h3 class="h5 text-secondary text-uppercase">
                    Datos del no donante
                </h3>
                <hr>
                <div class="row pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="deceased_names" class="form-label">
                            Nombres del difunto
                        </label>
                        <input id="deceased_names" class="form-control"
                            name="deceased_names" type="text"
                            tabindex="1" disabled
                            v-model="record.names"/>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="deceased_surnames" class="form-label">
                            Apellidos del difunto
                        </label>
                        <input id="deceased_surnames" class="form-control"
                            name="deceased_surnames" type="text" tabindex="2"
                            disabled v-model="record.surnames"/>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="dni" class="form-label">
                            Cédula de identidad
                        </label>
                        <input id="dni" class="form-control" name="dni"
                            type="text" tabindex="5" disabled
                            v-model="record.dni"/>
                    </div>
                </div>
                <h3 class="h5 text-secondary text-uppercase">
                    Datos del registro
                </h3>
                <hr>
                <div class="row">
                    <div class="col-12 col-lg-4 mb-3">
                        <label class="form-label">
                            Acta de negativa familiar <span class="text-danger">*</span>
                        </label>
                        <input
                            id="refusal_file"
                            class="form-control"
                            type="file"
                            accept=".pdf, .docx, .doc, .odt"
                            ref="refusal_file"
                            @change="uploadFile"
                        />
                        <div id="fileHelp" class="form-text">
                            Formato permitido:
                            <label class="fw-bold" for="refusal_file">.pdf .docx .doc .odt</label>
                            Tamaño máximo:
                            <label class="fw-bold" for="refusal_file">5MB</label>
                        </div>
                    </div>
                    <div class="col-12 my-3">
                        <label for="family_refusal_reason" class="form-label">
                            Razón de la negativa familiar <span class="text-danger">*</span>
                        </label>
                        <textarea rows="3"  class="form-control"
                            tabindex="1" v-model="record.family_refusal_reason">
                        </textarea>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="refusal_family_member_full_name" class="form-label">
                            Nombre completo del familiar responsable <span class="text-danger">*</span>
                        </label>
                        <input id="refusal_family_member_full_name" class="form-control"
                            name="refusal_family_member_full_name" type="text"
                            tabindex="2" v-model="record.refusal_family_member_full_name"/>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="refusal_family_member_phone" class="form-label">
                            Teléfono del familiar responsable <span class="text-danger">*</span>
                        </label>
                        <input
                            id="refusal_family_member_phone"
                            class="form-control"
                            name="refusal_family_member_phone"
                            type="text" tabindex="3"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            v-model="record.refusal_family_member_phone"
                            maxlength="11"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="healthcare_center_where_died" class="form-label">
                            Centro generador de órganos y tejidos <span class="text-danger">*</span>
                        </label>
                        <select id="healthcare_center_where_died"
                            class="form-select"
                            tabindex="6"
                            v-model="record.healthcare_center_where_died">
                            <option value=""> -- Seleccione -- </option>
                            <option
                                v-for="healthcare_center in organs_and_tissues"
                                :key="healthcare_center.id"
                                :value="healthcare_center.id"
                                :selected="healthcare_center.id == record.healthcare_center_where_died">
                                {{ healthcare_center.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="card-footer text-center">
                <button type="reset" class="btn btn-outline-primary-dark" @click="reset">
                    Limpiar
                </button>
                <button type="button" class="btn btn-primary" @click="setRecord('family-refusal-donors')">
                    <i class="fa fa-save"></i>
                    Guardar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            errors: [],
            organs_and_tissues:[],
            record: {
                number: '',
                agent_user_id: '',
                names: '',
                surnames: '',
                dni: '',
                family_refusal_reason: '',
                refusal_family_member_full_name: '',
                refusal_family_member_phone: '',
                healthcare_center_where_died: '',
                refusal_file: '',
                donor_type: 'NDF',
            }
        }
    },
    methods:{
        /**
         * Método que obtiene el archivo subido al campo respectivo.
         * 
         * @method uploadFile
         * 
         * @author José Briceño <josejorgebriceno9@gmail.com>
         *
        */
        uploadFile(event){
            const vm = this;
            const max_allowed_size = vm.maxWeightAllow();
            if (event.target.files[0].size) {
                if (event.target.files[0].size < max_allowed_size) {
                    vm.record.refusal_file = event.target.files[0];
                } else {
                    event.target.value = '';
                    vm.maxWeightExceededMessage();
                }
            }
        },

        /**
         * Ejecuta la acción para guardar o actualizar datos
         *
         * @method setRecord
         *
         * @author José Briceño <josejorgebriceno9@gmail.com>
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         *
         * @param  {String}  url URL de la petición a realizar
         */
        async setRecord(url) {
            const vm = this;
            vm.loadingState(true); // Inicio de spinner de carga.

            let formData = new FormData();
            vm.record.donor_id = vm.$route.params.id;
            let data = Object.entries(vm.record);

            data.forEach(element => {
                formData.append(`${element[0]}`, `${element[1]}`);
            });

            formData.append('refusal_file', vm.record.refusal_file);
            await axios({
                headers: {'Content-Type': 'multipart/form-data'},
                method: "post",
                url: `${window.url}/${url}`,
                data: formData,
            }).then((response) => {
                vm.errors = [];
                vm.loadingState(); // Finaliza spinner de carga.
                vm.showMessage("success");
                setTimeout(() => location.href = '/voluntary-donors-list', 3000);
            }).catch(error => {
                    vm.loadingState(); // Finaliza spinner de carga.
                    vm.setErrors(error)
                }
            );
        },

        /**
         * Método para reestablecer valores iniciales del formulario.
         *
         * @method reset
         *
         * @author Argenis Osorio <aosorio@cenditel.gob.ve>
         */
        reset() {
            const vm = this;
            vm.record.family_refusal_reason = '';
            vm.record.refusal_family_member_full_name = '';
            vm.record.refusal_family_member_phone = '';
            vm.record.healthcare_center_where_died = '';
            vm.record.refusal_file = '';
            vm.$refs.refusal_file.value = null;
        },

        /**
         * Método que obtiene la fecha del registro y la agrega sobre el campo correspondiente
         *
         * @author  Luis Ramírez <lgramirez@cenditel.gob.ve>
         *
         */
        getDate() {
            const vm = this;
            const newDate = new Date();
            vm.record.date = newDate.toLocaleDateString('en-GB');
        },
    },

    async mounted() {
        const vm = this;
        /**
         * Si el usuario autenticado intenta acceder al componente sin tener el
         * rol requerido este no carta los diferentes datos a consultar y
         * redirecciona a la pantalla inicial.
        */
        vm.user_role = vm.$parent.user.role;
        if ((vm.user_role == 'ADM') || (vm.user_role == 'CHO') || (vm.user_role == 'SUP')) {
            vm.loadingState(true); // Inicio de spinner de carga.
            vm.getDate();
            // Obtener el listado de los centros generadores de órganos y tejidos.
            await vm.getRecords('health-center-type/organs-and-tissues', 'organs_and_tissues');
            /** Datos del usuario gestor de fundavene */
            vm.record.agent_user_id = vm.$parent.user.profile.user_id;
            vm.record.agent_names = vm.$parent.user.profile.names;
            vm.record.agent_surnames = vm.$parent.user.profile.surnames;
            vm.record.agent_mail = vm.$parent.user.email;
            axios.get(`${window.url}` + '/profiles/' + vm.$parent.user.id)
            .then((response) => {
                let profile = response.data.profile;
                // vm.record.agent_dni = profile.dni;
                // vm.record.agent_phone = profile.phone;
                // vm.record.agent_movil = profile.movil;
            }).catch(error => {
                console.log(error.response)
            });
            /** Datos del donante gestionado */
            if (vm.$route.params.id) {
                axios.get(`${window.url}` + '/manage-will-donor/' + vm.$route.params.id)
                .then((response) => {
                    let profile = response.data.result.profile;
                    vm.record.family_refusal_reason = response.data.result.family_refusal_reason ?? '';
                    vm.record.refusal_family_member_full_name = response.data.result.refusal_family_member_full_name ?? '';
                    vm.record.refusal_family_member_phone = response.data.result.refusal_family_member_phone ?? '';
                    vm.record.healthcare_center_where_died = response.data.result.healthcare_center_where_died ?? '';
                    vm.record.names = profile.names;
                    vm.record.surnames = profile.surnames;
                    vm.record.dni = profile.dni;
                    vm.record.number = response.data.result.number;
                }).catch(error => {
                    console.log(error.response)
                });
            }
            vm.loadingState(); // Finaliza spinner de carga.
        }
        else {
            setTimeout(() => vm.$router.go(-1), 800);
        }
    }
}
</script>

<style>
</style>
