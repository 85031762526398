<template>
    <div id="kinship-component">
        <div class="card mb-4 shadow-sm">
            <h2 ref="header-form" class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary">
                <i class="fa fa-pencil-alt"></i> Registro de parentesco
            </h2>
            <div class="card-body">
                <form-errors :errors="errors"></form-errors>
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-6">
                        <div class="mb-3">
                            <label for="name" class="form-label">
                                Nombre <span class="text-danger">*</span>
                            </label>
                            <input id="name" class="form-control" type="text" placeholder="Nombre" tabindex="1"
                                v-model="record.name" />
                        </div>
                        <div class="mb-3">
                            <label for="prefix" class="form-label">
                                Prefijo <span class="text-danger">*</span>
                            </label>
                            <input id="prefix" class="form-control" type="text" placeholder="Prefijo"
                                tabindex="2" v-model="record.prefix" />
                        </div>
                        <div class="mb-3">
                            <label for="description" class="form-label"> Descripción </label>
                            <input id="description" class="form-control" type="text" placeholder="Descripción"
                                tabindex="3" aria-describedby="descriptionHelp" v-model="record.description" />
                            <div id="descriptionHelp" class="form-text"> Opcional </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer text-center">
                <button class="btn btn-outline-primary-dark" type="reset" @click="reset()">
                    Limpiar
                </button>
                <button class="btn btn-primary" type="button" @click="setRecord('global-data')">
                    <i class="fa fa-save"></i>
                    Guardar
                </button>
            </div>
        </div>
        <div class="card mb-4 shadow-sm">
            <h2 class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary">
                <i class="fa fa-book"></i> Lista de parentescos
            </h2>
            <div class="card-body">
                <v-client-table :columns="columns" :data="records" :options="table_options">
                <div slot="id" slot-scope="props" class="main-flex-buttons">
                    <div v-if="isNotEditable(props.row.id)">
                        <button class="btn btn-outline-primary" type="button" title="Editar registro"
                            @click="getRecord('global-data', props.row.id); scrollMeTo('header-form');">
                            <i class="fa fa-edit"></i>
                        </button>
                    </div>
                    <!--div v-if="isNotEditable(props.row.id)">
                        <button class="btn btn-outline-danger" type="button" title="Eliminar registro"
                            @click="deleteRecordFiltered('global-data', url, props.row.id)">
                            <i class="fas fa-trash"></i>
                        </button>
                    </div-->
                </div>
                </v-client-table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                errors: [],
                records: [],
                record: {
                    prefix: '',
                    name: '',
                    description: '',
                    type: 'kinship',
                },
                url: 'global-data-type/kinship',
                columns: ['name', 'prefix', 'description', 'id'],
            }
        },
        methods: {
            /**
             * Método para determinar si un tipo de parentesco es editable y/o se puede eliminar
             * @param {int} id Identificador del tipo de parentesco
             */
            isNotEditable(id) {
                const vm = this;
                let not_editable = [...Array(15-1).keys()].map(id => id + 1);
                not_editable.push(435);
                return !not_editable.includes(id);
            },

            /**
             * Método para reestablecer valores iniciales del formulario
             *
             * @method reset
             *
             * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <kingangelo_123@gmail.com>
             * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
             */
            reset() {
                const vm = this;
                vm.errors = [];
                vm.record = {
                    prefix: '',
                    name: '',
                    type: 'kinship',
                    description: '',
                }
            },
        },

        created() {
            this.table_options.headings = {
                'prefix': 'Prefijo',
                'name': 'Nombre',
                'type': 'Tipo',
                'description': 'Descripción',
                'id': 'Acción'
            };
            this.table_options.sortable = ['name', 'prefix'];
            this.table_options.filterable = ['name', 'prefix'];
            this.table_options.columnsClasses = {
                'id': 'col-2 text-center'
            };
        },

        async mounted() {
            const vm = this;
            /**
             * Si el usuario autenticado intenta acceder al componente sin tener el
             * rol requerido este no carta los diferentes datos a consultar y
             * redirecciona a la pantalla inicial.
            */
            vm.user_role = vm.$parent.user.role;
            if ((vm.user_role == 'ADM')) {
                vm.loadingState(true); // Inicio de spinner de carga.
                await vm.getRecords(vm.url, 'records');
                vm.loadingState(); // Finaliza spinner de carga.
            }
            else {
                location.href = '/';
            }
        }
    };
</script>
