<template>
    <div class="donor-record-create-component">
        <go-back title="Regresar"/>
        <div class="card mb-4 shadow-sm">
            <h2 class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary">
                <i class="fa fa-book"></i> Gestión para donante efectivo
            </h2>
            <div class="card-body">
                <form-errors :errors="errors"></form-errors>
                <div class="row pb-4">
                    <div class="col-12 col-lg-2 mb-3">
                        <label class="form-label" for="registered_at">
                            Fecha de registro
                        </label>
                        <input id="registered_at" class="form-control" type="text"
                            name="registered_at" readonly
                            v-model="record.registered_at" disabled
                        />
                    </div>
                    <div v-if="record.number" class="col-12 col-lg-2 mb-3">
                        <label class="form-label" for="number">
                            Serial N°
                        </label>
                        <input id="number" class="form-control" type="text"
                            name="number" readonly placeholder="Serial de planilla"
                            tabindex="-1" v-model="record.number"
                            disabled
                        />
                    </div>
                </div>
                <!-- Datos del usuario que realiza el registro -->
                <h3 class="h5 text-secondary text-uppercase">
                    Usuario que realiza la gestión del donante efectivo
                </h3>
                <hr>
                <div class="row pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <label class="form-label" for="agent_name">Nombres</label>
                        <input id="agent_name" class="form-control" type="text"
                            disabled v-model="record.agent_names" />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="agent_surnames" class="form-label">Apellidos</label>
                        <input id="agent_surnames" class="form-control"
                            type="text" disabled v-model="record.agent_surnames" />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label class="form-label" for="agent_mail">
                            Correo electrónico
                        </label>
                        <input id="agent_mail" class="form-control" type="text"
                            disabled v-model="record.agent_mail" />
                    </div>
                </div>
                <!--/ Datos del usuario que realiza el registro -->

                <!-- Datos del donante -->
                <h3 class="h5 text-secondary text-uppercase">
                    Datos del donante efectivo
                </h3>
                <hr>
                <div class="row pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="names" class="form-label">
                            Nombres del difunto
                        </label>
                        <input id="names" class="form-control"
                            name="names" type="text" tabindex="1"
                            :disabled="getDisabled" v-model="record.names"/>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="surnames" class="form-label">
                            Apellidos del difunto
                        </label>
                        <input id="surnames" class="form-control" name="surnames"
                            type="text" tabindex="2" :disabled="getDisabled"
                            v-model="record.surnames"/>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="dni" class="form-label">
                            Cédula de identidad
                        </label>
                        <input id="dni" class="form-control" name="dni"
                            type="text" tabindex="5" :disabled="getDisabled"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            v-model="record.dni"/>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label class="form-label" for="nationality">
                            Nacionalidad
                        </label>
                        <v-select
                            id="nationality"
                            class="v-selectDisabled"
                            label="text"
                            placeholder="-- Seleccione --"
                            :disabled="getDisabled"
                            :options="nationalityOptions"
                            :reduce="nationality => nationality.id"
                            :selectable="option => option.id !== ''"
                            v-model="selectedOptions.nationality"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label class="form-label" for="gender">
                            Género
                        </label>
                        <v-select
                            id="gender"
                            class="v-selectDisabled"
                            label="text"
                            placeholder="-- Seleccione --"
                            :disabled="getDisabled"
                            :options="genderOptions"
                            :reduce="gender => gender.id"
                            :selectable="option => option.id !== ''"
                            v-model="selectedOptions.gender"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label class="form-label" for="marital_status">
                            Estado civil
                        </label>
                        <v-select
                            id="marital_status"
                            class="v-selectDisabled"
                            label="text"
                            placeholder="-- Seleccione --"
                            :disabled="getDisabled"
                            :options="maritalStatusOptions"
                            :reduce="ms => ms.id"
                            :selectable="option => option.id !== ''"
                            v-model="selectedOptions.marital_status"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label class="form-label" for="ocupation">
                            Ocupación
                        </label>
                        <v-select
                            id="ocupation"
                            class="v-selectDisabled"
                            label="name"
                            placeholder="-- Seleccione --"
                            :disabled="getDisabled"
                            :options="occupations"
                            :reduce="ocupation => ocupation.id"
                            :selectable="option => option.id !== ''"
                            v-model="selectedOptions.ocupation"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label class="form-label" for="academic_levels">
                            Nivel académico
                        </label>
                        <v-select
                            id="academic_level"
                            class="v-selectDisabled"
                            label="name"
                            placeholder="-- Seleccione --"
                            :disabled="getDisabled"
                            :options="academic_levels"
                            :reduce="ms => ms.name"
                            :selectable="option => option.id !== ''"
                            v-model="selectedOptions.academic_level"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                </div>

                <!-- Edad -->
                <h3 class="h5 text-secondary text-uppercase">
                    Edad
                </h3>
                <hr />
                <div class="row pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="birthdate" class="form-label">
                            Fecha de nacimiento
                        </label>
                        <input v-if="!$route.params.id" id="birthdate"
                            class="form-control" name="birthdate" type="date"
                            tabindex="-1" @change="setAge" :max="today"
                            v-model="record.birthdate"/>
                        <input v-else id="birthdate" class="form-control"
                            name="birthdate" type="text" tabindex="3" disabled
                            @change="setAge"
                            v-model="record.birthdate" />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="age" class="form-label">Edad</label>
                        <input id="age" class="form-control" name="age"
                            type="number" aria-describedby="ageHelp" min="1" max="3"
                            placeholder="0" :disabled="getDisabled" tabindex="-1"
                            v-model="record.age" />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="birthplace" class="form-label">
                            Lugar de nacimiento
                        </label>
                        <input id="birthplace" class="form-control"
                            name="birthplace" type="text" tabindex="4"
                            :disabled="getDisabled" v-model="record.birthplace"/>
                    </div>
                </div>

                <!-- Dirección -->
                <h3 class="h5 text-secondary text-uppercase">
                    Dirección
                </h3>
                <hr />
                <div class="row pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="estate_id" class="form-label">
                            Estado
                        </label>
                        <v-select
                            v-model="selectedOptions.estate_id"
                            :options="estates"
                            :selectable="option => option.id !== ''"
                            :reduce="estate => estate.id"
                            class="v-selectDisabled"
                            label="text"
                            id="estate_id"
                            :disabled="getDisabled"
                            @input="
                                selectedOptions.municipality_id='';
                                selectedOptions.parish_id='';
                                getMunicipalities(selectedOptions.estate_id)
                            "
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="municipality_id" class="form-label">
                            Municipio
                        </label>
                        <v-select
                            v-model="selectedOptions.municipality_id"
                            :options="municipalities"
                            :selectable="option => option.id !== ''"
                            :reduce="mun => mun.id"
                            class="v-selectDisabled"
                            label="text"
                            id="municipality_id"
                            :disabled="getDisabled"
                            @input="
                                selectedOptions.parish_id='';
                                getParishes(selectedOptions.municipality_id)
                            "
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="parish_id" class="form-label">
                            Parroquia
                        </label>
                        <v-select
                            v-model="selectedOptions.parish_id"
                            :options="parishes"
                            :selectable="option => option.id !== ''"
                            :reduce="par => par.id"
                            label="text"
                            class="v-selectDisabled"
                            id="parish_id"
                            :disabled="getDisabled"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                </div>

                <!-- Contacto -->
                <h3 class="h5 text-secondary text-uppercase">
                    Contacto
                </h3>
                <hr />
                <div class="row pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="phone" class="form-label">
                            Teléfono local
                        </label>
                        <input
                            id="phone"
                            class="form-control"
                            name="phone"
                            type="text"
                            placeholder="Teléfono local"
                            maxlength="11"
                            v-model="record.phone"
                            :disabled="getDisabled"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="movil" class="form-label">
                            Teléfono móvil
                        </label>
                        <input
                            id="movil"
                            class="form-control"
                            name="movil"
                            type="text"
                            placeholder="Teléfono móvil"
                            v-model="record.movil"
                            :disabled="getDisabled"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="dni" class="form-label">
                            Correo electrónico
                        </label>
                        <input
                            id="email"
                            class="form-control"
                            name="email"
                            type="email"
                            placeholder="Correo electrónico"
                            :disabled="getDisabled"
                            v-model="record.email"
                        />
                    </div>
                </div>

                <h3 class="h5 text-secondary text-uppercase">
                    Datos de la donación
                </h3>
                <hr>
                <div class="row pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="donorSchema" class="form-label">
                            Donación
                        </label>
                        <input id="donorSchema" class="form-control"
                            type="text"
                            :disabled="getDisabled"
                            :value="(record.donorSchema === 'T')? 'Total' : 'Parcial'"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-4" v-if="partial_organs">
                        <div v-if="getPartial(partial_organs,'therapeutic').length > 0">
                            <h6>Terapeútico (trasplante):</h6>
                            <ul>
                                <li v-for="organ in getPartial(partial_organs,'therapeutic')">
                                    <span class="small">{{ getNameOrgan(organ) + '.' }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 mb-3" v-if="partial_organs">
                        <div v-if="getPartial(partial_organs,'reasearch').length > 0">
                            <h6>Investigación o Docencia:</h6>
                            <ul>
                                <li v-for="organ in getPartial(partial_organs,'reasearch')">
                                    <span class="small">{{ getNameOrgan(organ) + '.'}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="healthcare_center_where_died" class="form-label">
                            Centro generador de órganos y tejidos <span class="text-danger">*</span>
                        </label>
                        <v-select
                            v-model="selectedOptions.healthcare_center_where_died"
                            :options="health_center_organs_and_tissues"
                            :selectable="option => option.id !== ''"
                            :reduce="healthcare_center_where_died => healthcare_center_where_died.id"
                            label="name"
                            id="healthcare_center_where_died"
                            :tabindex="6"
                            placeholder="-- Seleccione --"
                            :class="[user_role == 'PIN' ? 'v-selectDisabled' : '']"
                            :disabled="getDisabledByRolePIN"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="blood_group" class="form-label">
                            Grupo sangíneo
                        </label>
                        <v-select
                            v-model="selectedOptions.blood_group"
                            :options="blood_groups"
                            :selectable="option => option.id !== ''"
                            :reduce="blood_group => blood_group.id"
                            label="name"
                            id="blood_group"
                            :tabindex="8"
                            placeholder="-- Seleccione --"
                            class="v-selectDisabled"
                            :disabled="getDisabled"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="rhFactor" class="form-label">
                            Factor RH
                        </label>
                        <v-select
                            v-model="selectedOptions.rh_factor"
                            :options="rh_factorOptions"
                            :reduce="rh_factor => rh_factor.id"
                            label="text"
                            id="rh_factor"
                            :tabindex="9"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                            class="v-selectDisabled"
                            :disabled="getDisabled"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="size" class="form-label">
                            Talla
                        </label>
                        <input id="size" class="form-control"
                            name="size" type="text" tabindex="10"
                            v-model="record.size"
                            :disabled="getDisabledByRolePIN"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="weight" class="form-label">
                            Peso (Kg)
                        </label>
                        <input id="weight" class="form-control"
                            name="weight" type="text" tabindex="11"
                            v-model="record.weight"
                            :disabled="getDisabledByRolePIN"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="death_date" class="form-label">
                            Fecha de muerte <span class="text-danger">*</span>
                        </label>
                        <input id="death_date" class="form-control"
                            type="date" name="death_date" tabindex="12"
                            :max="today" v-model="record.death_date"
                            :disabled="getDisabledByRolePIN"
                            :class="[user_role == 'PIN' ? 'v-selectDisabled' : '']"
                        />
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="death_time" class="form-label">
                            Hora de muerte <span class="text-danger">*</span>
                        </label>
                        <input id="death_time" class="form-control"
                            name="death_time" type="time" tabindex="13"
                            v-model="record.death_time"
                            :disabled="getDisabledByRolePIN"
                            :class="[user_role == 'PIN' ? 'v-selectDisabled' : '']"
                        />
                        <div id="ageHelp" class="form-text">
                            (00:00 a.m./p.m)
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="diagnosis" class="form-label">
                            Diagnóstico <span class="text-danger">*</span>
                        </label>
                        <textarea rows="3"  class="form-control" tabindex="14"
                            v-model="record.diagnosis"
                            :disabled="getDisabledByRolePIN"
                            :class="[user_role == 'PIN' ? 'v-selectDisabled' : '']"
                        >
                        </textarea>
                    </div>
                </div>
                <!-- Datos de órganos y tejidos que han sido extraídos -->
                <h4 class="h6 text-secondary" v-if="user_role != 'PIN'">
                    Seleccione los órganos, células y tejidos que han sido
                    extraídos <span class="text-danger">*</span>
                </h4>
                <h4 class="h6 text-secondary" v-else>
                    Órganos, células y tejidos que han sido
                    extraídos
                </h4>
                <div class="my-3">
                    <div class="card p-3" v-if="user_role != 'PIN'">
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-3 my-2"
                                v-for="organ in organs" :key="organ.id">
                                <button type="button" title="Añadir a la tabla"
                                    class="btn btn-outline-primary btn-sm"
                                    @click="addOrgan(organ)">
                                    <i class="fas fa-plus-circle ml-3"
                                        style="cursor:pointer"></i>
                                </button>
                                <span class="text-sm">{{organ.name}}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="typeof(organs_selected)!=='undefined' && organs_selected.length" class="card mt-3 p-3">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr class="text-center">
                                        <th scope="col">Cantidad</th>
                                        <th scope="col">Órgano</th>
                                        <th scope="col">Observación</th>
                                        <th scope="col">Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-center" v-for="(item, index) in organs_selected"
                                        :key="index">
                                        <td>{{item.number}}</td>
                                        <td>{{item.organ}}</td>
                                        <td>
                                            <textarea name="observation"
                                                class="form-control"
                                                v-model="organs_selected[index].observation"
                                                cols="15" rows="2"
                                                :disabled="getDisabledByRolePIN"
                                            >
                                            </textarea>
                                        </td>
                                        <td>
                                            <button class="btn btn-outline-danger"
                                                type="button" title="Eliminar registro"
                                                @click="removeOrgan(item.id_organ)"
                                                v-if="user_role != 'PIN'"
                                            >
                                                <i class="fas fa-trash">
                                            </i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- Final datos de órganos y tejidos que han sido extraídos -->

                <!-- Exámenes Médicos -->
                <h3 class="h5 text-secondary text-uppercase">
                    Exámenes Médicos
                </h3>
                <hr>
                <!-- Resultados de serología -->
                <h4 class="h5 text-secondary">
                    Resultados de serología
                </h4>
                <div class="row pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <label class="form-label">
                            Examen de serología
                        </label>
                        <div class="row">
                            <div :class="[data_serology_test_file ? 'col-12 col-lg-7 mb-3 mb-lg-0' : 'col-12']">
                                <input
                                    id="serology_test_file"
                                    class="form-control"
                                    type="file"
                                    name="serology_test_file"
                                    accept=".pdf, .docx, .doc, .odt"
                                    ref="serology_test_file"
                                    tabindex="15"
                                    :disabled="getDisabledByRolePIN"
                                    @change="uploadFile"
                                />
                            </div>
                            <div v-if="data_serology_test_file" class="col-12 col-lg-5">
                                <button class="btn btn-outline-primary-dark"
                                    title="Descargar el examen de serología cargado anteriormente"
                                    @click="downloadFile(data_serology_test_file,'Examen de serología')">
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>
                        </div>
                        <div class="form-text mb-3">
                            Formato permitido:
                            <span class="fw-bold">
                                .pdf .docx .doc .odt
                            </span>
                            Tamaño máximo:
                            <span class="fw-bold">
                                5MB
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 mb-3">
                        <label class="fw-bold">
                            Observación
                        </label>
                        <textarea rows="3"  class="form-control" tabindex="16"
                            v-model="record.serology_test_observation"
                            :disabled="getDisabledByRolePIN"
                        >
                        </textarea>
                    </div>
                </div>
                <!-- Final Resultados de serología -->

                <!-- Recepción de muestras -->
                <h4 class="h5 text-secondary">
                    Recepción de muestras
                </h4>
                <div class="card p-3">
                    <!-- Ganglios -->
                    <div class="row pt-2 pb-4">
                        <div class="col-12 col-lg-2 d-flex justify-content-center align-items-center">
                            <h6>Ganglios</h6>
                        </div>
                        <div class="col-12 col-lg-4 col-xl-3 pb-2">
                            <label class="fw-bold">
                                Cantidad
                            </label>
                            <input
                                id="ganglios_quantity"
                                class="form-control"
                                type="text"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                tabindex="17"
                                v-model="record.ganglios_quantity"
                                :disabled="getDisabledByRoleCHO"
                            />
                        </div>
                        <div class="col-12 col-lg-6 col-xl-4">
                            <label class="fw-bold">Observación</label>
                            <textarea
                                class="form-control"
                                rows="3"
                                tabindex="13"
                                v-model="record.ganglios_observation"
                                :disabled="getDisabledByRoleCHO"
                            ></textarea>
                        </div>
                    </div>
                    <!-- Final Ganglios -->


                    <!-- Tubo con EDTA -->
                    <div class="row pb-4">
                        <div class="col-12 col-lg-2 d-flex justify-content-center align-items-center">
                            <h6>Tubo con EDTA</h6>
                        </div>
                        <div class="col-12 col-lg-4 col-xl-3 pb-2">
                            <label class="fw-bold">Cantidad</label>
                            <input
                                id="tube_edta_quantity"
                                class="form-control"
                                type="text"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                tabindex="14"
                                v-model="record.tube_edta_quantity"
                                :disabled="getDisabledByRoleCHO"
                            />
                        </div>
                        <div class="col-12 col-lg-6 col-xl-4">
                            <label class="fw-bold">Observación</label>
                            <textarea
                                class="form-control"
                                rows="3"
                                tabindex="15"
                                v-model="record.tube_edta_observation"
                                :disabled="getDisabledByRoleCHO"
                            ></textarea>
                        </div>
                    </div>
                    <!-- Final Tubo con EDTA -->


                    <!-- Tubo sin aditivos -->
                    <div class="row pb-4">
                        <div class="col-12 col-lg-2 d-flex justify-content-center align-items-center">
                            <h6>Tubo sin aditivos</h6>
                        </div>
                        <div class="col-12 col-lg-4 col-xl-3 pb-2">
                            <label class="fw-bold">Cantidad</label>
                            <input
                                id="tube_w_additives_quantity"
                                class="form-control"
                                type="text"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                tabindex="16"
                                v-model="record.tube_w_additives_quantity"
                                :disabled="getDisabledByRoleCHO"
                            />
                        </div>
                        <div class="col-12 col-lg-6 col-xl-4">
                            <label class="fw-bold">Observación</label>
                            <textarea
                                class="form-control"
                                rows="3"
                                tabindex="17"
                                v-model="record.tube_w_additives_observation"
                                :disabled="getDisabledByRoleCHO"
                            ></textarea>
                        </div>
                    </div>
                    <!-- Final Tubo sin aditivos -->
                </div>
                <!-- Final Recepción de muestras -->

                <!-- Resultados de inmunología -->
                <h4 class="h5 text-secondary mt-4">
                    Resultados de inmunología
                </h4>

                <div class="card p-3">
                    <div class="row pb-4">
                        <!-- Títulos -->
                        <div class="col-12 col-lg-3 offset-lg-1 order-0">
                            <h5 class="h6 text-center mb-0 mt-3">Clase 1</h5>
                            <hr>
                        </div>
                        <div class="col-12 col-lg-8 order-2 order-lg-1">
                            <h5 class="h6 text-center mb-0 mt-3">Clase 2</h5>
                            <hr>
                        </div>

                        <!-- Alelos 1 y 2 - A, B, C -->
                        <div class="col-12 col-lg-4 order-1 order-lg-2">
                            <!-- HLA, A, B, C -->
                            <div class="row mb-3">
                                <div class="col-3 text-center">
                                    <span class="form-label">HLA</span>
                                </div>
                                <div class="col-3 text-center">
                                    <span class="form-label">A</span>
                                </div>
                                <div class="col-3 text-center">
                                    <span class="form-label">B</span>
                                </div>
                                <div class="col-3 text-center">
                                    <span class="form-label">C</span>
                                </div>
                            </div>

                            <!-- Alelo 1, 1A, 1B, 1C -->
                            <div class="row mb-3 align-items-center">
                                <div class="col-3 text-center">
                                    <span class="form-label mb-1">Alelo 1</span>
                                </div>
                                <div class="col-3 text-center">
                                    <input
                                        id="allele_1_a"
                                        class="form-control"
                                        type="text"
                                        maxlength="4"
                                        minlength="2"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        tabindex="18"
                                        v-model="allele_1_a"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                                <div class="col-3 text-center">
                                    <input
                                        id="allele_1_b"
                                        class="form-control"
                                        type="text"
                                        maxlength="4"
                                        minlength="2"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        tabindex="19"
                                        v-model="allele_1_b"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                                <div class="col-3 text-center">
                                    <input
                                        id="allele_1_c"
                                        class="form-control"
                                        type="text"
                                        maxlength="4"
                                        minlength="2"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        tabindex="20"
                                        v-model="allele_1_c"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                            </div>

                            <!-- Alelo 2, 2A, 2B, 2C -->
                            <div class="row align-items-center">
                                <div class="col-3 text-center">
                                    <span class="form-label mb-1">Alelo 2</span>
                                </div>
                                <div class="col-3 text-center">
                                    <input
                                        id="allele_2_a"
                                        type="text"
                                        class="form-control"
                                        v-model="allele_2_a"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        minlength="2"
                                        maxlength="4"
                                        tabindex="23"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                                <div class="col-3 text-center">
                                    <input
                                        id="allele_2_b"
                                        type="text"
                                        class="form-control"
                                        v-model="allele_2_b"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        minlength="2"
                                        maxlength="4"
                                        tabindex="24"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                                <div class="col-3 text-center">
                                    <input
                                        id="allele_2_c"
                                        type="text"
                                        class="form-control"
                                        v-model="allele_2_c"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        minlength="2"
                                        maxlength="4"
                                        tabindex="25"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- Alelos 1 y 2 - DRB1, DBQ1, DPB1 -->
                        <div class="col-12 col-lg-3 order-3">
                            <!-- HLA, DRB1, DBQ1, DPB1 -->
                            <div class="row mb-3">
                                <div class="col-3 d-lg-none text-center">
                                    <span class="form-label">HLA</span>
                                </div>
                                <div class="col-3 col-lg-4 text-center">
                                    <span class="form-label">DRB1</span>
                                </div>
                                <div class="col-3 col-lg-4 text-center">
                                    <span class="form-label">DBQ1</span>
                                </div>
                                <div class="col-3 col-lg-4 text-center">
                                    <span class="form-label">DPB1</span>
                                </div>
                            </div>

                            <!-- Alelo 1, DRB1, DBQ1, DPB1 -->
                            <div class="row mb-3 align-items-center">
                                <div class="col-3 d-lg-none text-center">
                                    <span class="form-label mb-1">Alelo 1</span>
                                </div>
                                <div class="col-3 col-lg-4 text-center">
                                    <input
                                        id="allele_1_DRB1"
                                        type="text"
                                        class="form-control"
                                        v-model="allele_1_DRB1"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        minlength="2"
                                        maxlength="4"
                                        tabindex="21"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                                <div class="col-3 col-lg-4 text-center">
                                    <input
                                        id="allele_1_DBQ1"
                                        type="text"
                                        class="form-control"
                                        v-model="allele_1_DBQ1"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        minlength="2"
                                        maxlength="4"
                                        tabindex="22"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                                <div class="col-3 col-lg-4 text-center">
                                    <input
                                        id="allele_1_DPB1"
                                        type="text"
                                        class="form-control"
                                        v-model="allele_1_DPB1"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        minlength="2"
                                        maxlength="4"
                                        tabindex="23"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                            </div>

                            <!-- Alelo 2, DRB1, DBQ1, DPB1 -->
                            <div class="row mb-5 mb-lg-0 align-items-center">
                                <div class="col-3 d-lg-none text-center">
                                    <span class="form-label mb-1">Alelo 2</span>
                                </div>
                                <div class="col-3 col-lg-4 text-center">
                                    <input
                                        id="allele_2_DRB1"
                                        type="text"
                                        class="form-control"
                                        v-model="allele_2_DRB1"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        minlength="2"
                                        maxlength="4"
                                        tabindex="26"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                                <div class="col-3 col-lg-4 text-center">
                                    <input
                                        id="allele_2_DBQ1"
                                        type="text"
                                        class="form-control"
                                        v-model="allele_2_DBQ1"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        minlength="2"
                                        maxlength="4"
                                        tabindex="27"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                                <div class="col-3 col-lg-4 text-center">
                                    <input
                                        class="form-control"
                                        id="allele_2_DPB1"
                                        type="text"
                                        v-model="allele_2_DPB1"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        minlength="2"
                                        maxlength="4"
                                        tabindex="27"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- Alelos 1 y 2 - DQA1, DPA1, DRB3 -->
                        <div class="col-12 col-lg-3 order-4">
                            <!-- HLA, DQA1, DPA1, DRB3 -->
                            <div class="row mb-3">
                                <div class="col-3 d-lg-none text-center">
                                    <span class="form-label">HLA</span>
                                </div>
                                <div class="col-3 col-lg-4 text-center">
                                    <span class="form-label">DQA1</span>
                                </div>
                                <div class="col-3 col-lg-4 text-center">
                                    <span class="form-label">DPA1</span>
                                </div>
                                <div class="col-3 col-lg-4 text-center">
                                    <span class="form-label">DRB3</span>
                                </div>
                            </div>
                            <!-- Alelo 1, DQA1, DPA1, DRB3 -->
                            <div class="row mb-3 align-items-center">
                                <div class="col-3 d-lg-none text-center">
                                    <span class="form-label mb-1">Alelo 1</span>
                                </div>
                                <div class="col-3 col-lg-4 text-center">
                                    <input
                                        id="allele_1_DQA1"
                                        type="text"
                                        class="form-control"
                                        v-model="allele_1_DQA1"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        minlength="2"
                                        maxlength="4"
                                        tabindex="22"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                                <div class="col-3 col-lg-4 text-center">
                                    <input
                                        id="allele_1_DPA1"
                                        type="text"
                                        class="form-control"
                                        v-model="allele_1_DPA1"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        minlength="2"
                                        maxlength="4"
                                        tabindex="22"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                                <div class="col-3 col-lg-4 text-center">
                                    <input
                                        id="allele_1_DRB3"
                                        type="text"
                                        class="form-control"
                                        v-model="allele_1_DRB3"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        minlength="2"
                                        maxlength="4"
                                        tabindex="22"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                            </div>

                            <!-- Alelo 2, DQA1, DPA1, DRB3 -->
                            <div class="row mb-5 mb-lg-0 align-items-center">
                                <div class="col-3 d-lg-none text-center">
                                    <span class="form-label mb-1">Alelo 2</span>
                                </div>
                                <div class="col-3 col-lg-4 text-center">
                                    <input
                                        id="allele_2_DQA1"
                                        type="text"
                                        class="form-control"
                                        v-model="allele_2_DQA1"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        minlength="2"
                                        maxlength="4"
                                        tabindex="27"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                                <div class="col-3 col-lg-4 text-center">
                                    <input
                                        id="allele_2_DPA1"
                                        type="text"
                                        class="form-control"
                                        v-model="allele_2_DPA1"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        minlength="2"
                                        maxlength="4"
                                        tabindex="27"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                                <div class="col-3 col-lg-4 text-center">
                                    <input
                                        id="allele_2_DRB3"
                                        type="text"
                                        class="form-control"
                                        v-model="allele_2_DRB3"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        minlength="2"
                                        maxlength="4"
                                        tabindex="27"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- Alelos 1 y 2 - DRB4, DRB5 -->
                        <div class="col-12 col-lg-2 order-5">

                            <!-- HLA, DRB4, DRB5 -->
                            <div class="row mb-3">
                                <div class="col-3 d-lg-none text-center">
                                    <span class="form-label">HLA</span>
                                </div>
                                <div class="col-3 col-lg-6 text-center">
                                    <span class="form-label">DRB4</span>
                                </div>
                                <div class="col-3 col-lg-6 text-center">
                                    <span class="form-label">DRB5</span>
                                </div>
                            </div>
                            <!-- Alelo 1, DRB4, DRB5 -->
                            <div class="row mb-3 align-items-center">
                                <div class="col-3 d-lg-none text-center">
                                    <span class="form-label mb-1">Alelo 1</span>
                                </div>
                                <div class="col-3 col-lg-6 text-center">
                                    <input
                                        id="allele_1_DRB4"
                                        type="text"
                                        class="form-control"
                                        v-model="allele_1_DRB4"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        minlength="2"
                                        maxlength="4"
                                        tabindex="22"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                                <div class="col-3 col-lg-6 text-center">
                                    <input
                                        id="allele_1_DRB5"
                                        type="text"
                                        class="form-control"
                                        v-model="allele_1_DRB5"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        minlength="2"
                                        maxlength="4"
                                        tabindex="22"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                            </div>

                            <!-- Alelo 2, DRB4, DRB5 -->
                            <div class="row align-items-center">
                                <div class="col-3 d-lg-none text-center">
                                    <span class="form-label mb-1">Alelo 2</span>
                                </div>
                                <div class="col-3 col-lg-6 text-center">
                                    <input
                                        id="allele_2_DRB4"
                                        type="text"
                                        class="form-control"
                                        v-model="allele_2_DRB4"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        minlength="2"
                                        maxlength="4"
                                        tabindex="27"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                                <div class="col-3 col-lg-6 text-center">
                                    <input
                                        id="allele_2_DRB5"
                                        type="text"
                                        class="form-control"
                                        v-model="allele_2_DRB5"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        minlength="2"
                                        maxlength="4"
                                        tabindex="27"
                                        :disabled="getDisabledByRoleCHO"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--/ Resultados de inmunología -->

                <!-- Documentos requeridos -->
                <h3 class="h5 text-secondary text-uppercase mt-4">
                    Documentos requeridos
                </h3>
                <hr>
                <div class="row">
                    <div class="col-12 col-lg-4 my-3">
                        <label class="form-label">
                            Planilla de registro de muerte según criterios
                            neurológicos <span class="text-danger">*</span>
                        </label>
                        <div class="row">
                            <div :class="[data_document_neurological_death ? 'col-12 col-lg-7 mb-3 mb-lg-0' : 'col-12']">
                                <input
                                    id="document_neurological_death"
                                    class="form-control"
                                    type="file"
                                    name="document_neurological_death"
                                    accept=".pdf, .docx, .doc, .odt"
                                    ref="document_neurological_death"
                                    tabindex="28"
                                    :disabled="getDisabledByRolePIN"
                                    @change="uploadFile"
                                />
                            </div>
                            <div v-if="data_document_neurological_death" class="col-12 col-lg-5">
                                <button class="btn btn-outline-primary-dark"
                                    title="Descargar planilla de registro de muerte según criterios neurológicos cargada anteriormente"
                                    @click="downloadFile(
                                        data_document_neurological_death,
                                        'Planilla de registro de muerte según criterios neurológicos'
                                    )"
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>
                        </div>
                        <div class="form-text mb-3">
                            Formato permitido:
                            <span class="fw-bold">
                                .pdf .docx .doc .odt
                            </span>
                            Tamaño máximo:
                            <span class="fw-bold">
                                5MB
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 my-3">
                        <label class="form-label">
                            Planilla de registro posterior a la <br>
                            información familiar
                        </label>
                        <div class="row">
                            <div :class="[data_family_acceptance_document ? 'col-12 col-lg-7 mb-3 mb-lg-0' : 'col-12']">
                                <input
                                    id="family_acceptance_document"
                                    class="form-control"
                                    type="file"
                                    name="family_acceptance_document"
                                    accept=".pdf, .docx, .doc, .odt"
                                    ref="family_acceptance_document"
                                    tabindex="28"
                                    :disabled="getDisabledByRolePIN"
                                    @change="uploadFile"
                                />
                            </div>
                            <div v-if="data_family_acceptance_document" class="col-12 col-lg-5">
                                <button class="btn btn-outline-primary-dark"
                                    title="Descargar planilla de registro posterior a la información familiar cargada anteriormente"
                                    @click="downloadFile(
                                        data_family_acceptance_document,
                                        'Planilla de registro posterior a la información familiar'
                                    )"
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>
                        </div>
                        <div class="form-text mb-3">
                            Formato permitido:
                            <span class="fw-bold">
                                .pdf .docx .doc .odt
                            </span>
                            Tamaño máximo:
                            <span class="fw-bold">
                                5MB
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 my-3">
                        <label class="form-label">
                            Acta de protocolo en caso de muerte violenta, servicio médico forense
                        </label>
                        <div class="row">
                            <div :class="[data_document_protocol_violent_death ? 'col-12 col-lg-7 mb-3 mb-lg-0' : 'col-12']">
                                <input
                                    id="document_protocol_violent_death"
                                    class="form-control"
                                    type="file"
                                    name="document_protocol_violent_death"
                                    accept=".pdf, .docx, .doc, .odt"
                                    ref="document_protocol_violent_death"
                                    tabindex="28"
                                    :disabled="getDisabledByRolePIN"
                                    @change="uploadFile"
                                />
                            </div>
                            <div v-if="data_document_protocol_violent_death" class="col-12 col-lg-5">
                                <button class="btn btn-outline-primary-dark"
                                    title="Descargar acta de protocolo en caso de muerte violenta, servicio médico forense cargada anteriormente"
                                    @click="downloadFile(
                                        data_document_protocol_violent_death,
                                        'Acta de protocolo en caso de muerte violenta, servicio médico forense'
                                    )"
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>
                        </div>
                        <div class="form-text mb-3">
                            Formato permitido:
                            <span class="fw-bold">
                                .pdf .docx .doc .odt
                            </span>
                            Tamaño máximo:
                            <span class="fw-bold">
                                5MB
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 my-3">
                        <label class="form-label">
                            Planilla de registro hepatectomía programa de coordinación hospitalaria de trasplante
                        </label>



                        <div class="row">
                            <div :class="[data_document_hepatectomy_form ? 'col-12 col-lg-7 mb-3 mb-lg-0' : 'col-12']">
                                <input
                                    id="document_hepatectomy_form"
                                    class="form-control"
                                    type="file"
                                    name="document_hepatectomy_form"
                                    accept=".pdf, .docx, .doc, .odt"
                                    ref="document_hepatectomy_form"
                                    tabindex="28"
                                    :disabled="getDisabledByRolePIN"
                                    @change="uploadFile"
                                />
                            </div>
                            <div v-if="data_document_hepatectomy_form" class="col-12 col-lg-5">
                                <button class="btn btn-outline-primary-dark"
                                    title="Descargar planilla de registro hepatectomía programa de coordinación hospitalaria de trasplante cargada anteriormente"
                                    @click="downloadFile(
                                        data_document_hepatectomy_form,
                                        'Planilla de registro hepatectomía programa de coordinación hospitalaria de trasplante'
                                    )"
                                >
                                    <i class="fa fa-download"></i>
                                    Descargar
                                </button>
                            </div>
                        </div>
                        <div class="form-text mb-3">
                            Formato permitido:
                            <span class="fw-bold">
                                .pdf .docx .doc .odt
                            </span>
                            Tamaño máximo:
                            <span class="fw-bold">
                                5MB
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="(user_role == 'ADM') || (user_role == 'OPD') || (user_role == 'CHO')">
                    <h4 class="h6 text-secondary">
                        Seleccione un personal de inmunología asociado para gestionar el Donante efectivo
                    </h4>
                    <div class="row my-3">
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="pin_id" class="form-label">
                                Personal de inmunología <br> asociado <span class="text-danger">*</span>
                            </label>
                            <select id="pin_id" class="form-select"
                                v-model="record.pin_id"
                            >
                                <option
                                    v-for="x in users_pin"
                                    :key="x.id"
                                    :value="x.id"
                                    :selected="x.id == record.doctor">
                                    {{ x.text }}
                                </option>
                            </select>
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                            <label for="email_uin" class="form-label">
                                Correo electrónico de la unidad <br> de inmunología
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                id="email_uin"
                                class="form-control"
                                name="email_uin"
                                type="email"
                                placeholder="Correo electrónico de la unidad de inmunología"
                                v-model="record.email_uin"
                            />
                        </div>
                    </div>
                </div>           
            </div>
            <div class="card-footer text-center">
                <button type="reset" class="btn btn-outline-primary-dark" @click="reset()">
                    Limpiar
                </button>
                <button type="button" class="btn btn-primary" @click="setRecord('effective-donors')">
                    <i class="fa fa-save"></i>
                    Guardar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            errors: [],
            blood_groups: [],
            partial_organs: [],
            estates: [],
            municipalities: [],
            parishes: [],
            health_center_organs_and_tissues:[],
            organs: [],
            url_organ: 'global-data-type/organ',
            occupations_url: "global-data-type/occupation",
            occupations: [],
            academic_levels: [],
            users_pin: [],
            record: {
                number: '',
                names : '',
                surnames: '',
                birthdate: '',
                age: '',
                birthplace: '',
                dni: '',
                gender: '',
                agent_user_id: '',
                healthcare_center_where_died: '',
                blood_group: '',
                rh_factor: '',
                serology_test_file: '',
                serology_test_observation: '',
                ganglios_quantity: '',
                ganglios_observation: '',
                tube_edta_quantity: '',
                tube_edta_observation: '',
                tube_w_additives_quantity: '',
                tube_w_additives_observation: '',
                hla_test_results: [],
                document_neurological_death: '',
                family_acceptance_document: '',
                document_protocol_violent_death: '',
                document_hepatectomy_form: '',
                status: '',
                organs_selected: [],
                size: '',
                weight: '',
                death_date: '',
                death_time: '',
                diagnosis: '',
                user_role: '',
                email_uin: '',
            },
            data_serology_test_file: '',
            data_family_acceptance_document: '',
            data_document_neurological_death: '',
            data_document_protocol_violent_death: '',
            data_document_hepatectomy_form: '',
            organs_selected: [],
            nationalityOptions: [
                { id: "V", text: "Venezolano(a)" },
                { id: "E", text: "Extranjero(a)" }
            ],
            maritalStatusOptions: [
                { id: "S", text: "Soltero(a)" },
                { id: "C", text: "Casado(a)" },
                { id: "D", text: "Divorciado(a)" },
                { id: "V", text: "Viudo(a)" }
            ],
            genderOptions: [
                { id: "F", text: "Femenino" },
                { id: "M", text: "Masculino" }
            ],
            selectedOptions: {
                academic_level: '',
                ocupation: '',
                nationality: '',
                marital_status: '',
                gender: '',
                blood_group: '',
                healthcare_center_where_died: '',
                rh_factor: '',
                estate_id: '',
                municipality_id: '',
                parish_id: ''
            },
            rh_factorOptions: [
                { id: "P", text: "Positivo" },
                { id: "N", text: "Negativo" }
            ],
            organs_list:{
                list: [
                    {
                        name: 'Riñon',
                        therapeutic: 'therapeutic_kidney',
                        research: 'reasearch_kidney'
                    },
                    {
                        name: 'Páncreas',
                        therapeutic: 'therapeutic_pancreas',
                        research: 'reasearch_pancreas'
                    },
                    {
                        name: 'Hígado',
                        therapeutic: 'therapeutic_liver',
                        research: 'reasearch_liver'
                    },
                    {
                        name: 'Corazón',
                        therapeutic: 'therapeutic_heart',
                        research: 'reasearch_heart'
                    },
                    {
                        name: 'Intestino',
                        therapeutic: 'therapeutic_intestine',
                        research: 'reasearch_intestine'
                    },
                    {
                        name: 'Pulmón',
                        therapeutic: 'therapeutic_lung',
                        research: 'reasearch_lung'
                    },{
                        name: 'Sangre',
                        therapeutic: 'therapeutic_blood',
                        research: 'reasearch_blood'
                    },
                    {
                        name: 'Tejido Ocular',
                        therapeutic: 'therapeutic_eye_tissue',
                        research: 'reasearch_eye_tissue'
                    },
                    {
                        name: 'Tejido Osteotendinoso',
                        therapeutic: 'therapeutic_osteotendinous_tissue',
                        research: 'reasearch_osteotendinous_tissue'
                    },
                    {
                        name: 'Tejido Cardiovascular',
                        therapeutic: 'therapeutic_cardiovascular_tissue',
                        research: 'reasearch_cardiovascular_tissue'
                    },
                    {
                        name: 'Hueso',
                        therapeutic: 'therapeutic_bone',
                        research: 'reasearch_bone'
                    },
                    {
                        name: 'Válvulas Cardíacas',
                        therapeutic: 'therapeutic_heart_valves',
                        research: 'reasearch_heart_valves'
                    },
                    {
                        name: 'Piel',
                        therapeutic: 'therapeutic_skin',
                        research: 'reasearch_skin'
                    },
                    {
                        name: 'Cartílago',
                        therapeutic: 'therapeutic_cartilage',
                        research: 'reasearch_cartilage'
                    },
                    {
                        name: 'Vasos',
                        therapeutic: 'therapeutic_vessels',
                        research: 'reasearch_vessels'
                    },
                    {
                        name: 'Células Progenítoras Hematopoyéticas',
                        therapeutic: 'therapeutic_hematopoietic_stem_cells',
                        research: 'reasearch_hematopoietic_stem_cells'
                    },
                    {
                        name: 'Cordón umbilical',
                        therapeutic: 'therapeutic_umbilical_cord',
                        research: 'reasearch_umbilical_cord'
                    },
                ]
            },
            today: '',
            user_role: '',
            hla_test_results: [],
            allele_1_a: '',
            allele_1_b: '',
            allele_1_c: '',
            allele_1_DRB1: '',
            allele_1_DBQ1: '',
            allele_1_DPB1: '',
            allele_1_DQA1: '',
            allele_1_DPA1: '',
            allele_1_DRB3: '',
            allele_1_DRB4: '',
            allele_1_DRB5: '',
            allele_2_a: '',
            allele_2_b: '',
            allele_2_c: '',
            allele_2_DRB1: '',
            allele_2_DBQ1: '',
            allele_2_DPB1: '',
            allele_2_DQA1: '',
            allele_2_DPA1: '',
            allele_2_DRB3: '',
            allele_2_DRB4: '',
            allele_2_DRB5: '',
        }
    },
    methods:{
        reset() {
            const vm = this;
            vm.selectedOptions.healthcare_center_where_died = '';
            vm.record.blood_group = '';
            vm.record.rh_factor = '';
            vm.record.size = '';
            vm.record.weight = '';
            vm.record.death_date = '';
            vm.record.death_time = '';
            vm.record.diagnosis = '';
            vm.record.serology_test_file = '';
            vm.record.serology_test_observation = '';
            vm.$refs.serology_test_file.value = null;
            vm.record.document_neurological_death = '';
            vm.$refs.document_neurological_death.value = null;
            vm.record.family_acceptance_document = '';
            vm.$refs.family_acceptance_document.value = null;
            vm.record.document_protocol_violent_death = '';
            vm.$refs.document_protocol_violent_death.value = null;
            vm.record.document_hepatectomy_form = '';
            vm.$refs.document_hepatectomy_form.value = null;
            vm.record.ganglios_quantity = '';
            vm.record.ganglios_observation = '';
            vm.record.tube_edta_quantity = '';
            vm.record.tube_edta_observation = '';
            vm.record.tube_w_additives_quantity = '';
            vm.record.tube_w_additives_observation = '';
            vm.organs_selected = [];
        },

        getNameOrgan(organ){
            const vm= this;
            // let id = organ.split('_')[1];
            let text = '';

            vm.organs_list.list.forEach(element => {
                if (element.therapeutic  == organ || element.research == organ ) {
                    text = element.name;
                }
            });
            return text;
        },

        getPartial(organs, type){
            let result = organs.filter(organ => organ.split('_')[0] == type );
            return result
        },

        /**
         * Método que devuelve la lista de órganos de tipo terapeuticos
         * para ser mostrados en el reporte pdf.
         *
         * @method getArrayTherapeuticOrgans
         *
         * @author Ing. Argenis Osorio <aosorio@cenditel.gob.ve>
        */
        getArrayTherapeuticOrgans() {
            const vm= this;
            if (vm.partial_organs) {
                const a = vm.getPartial(vm.partial_organs,'therapeutic');
                let arrayTherapeuticOrgans = [];
                for (let organ of a) {
                    vm.getNameOrgan(organ)
                    arrayTherapeuticOrgans.push(
                        vm.getNameOrgan(organ)
                    );
                }
                return arrayTherapeuticOrgans;
            }
            else {
                return [];
            }
        },

        /**
         * Método que devuelve la lista de órganos para investigación a ser
         * mostrados en el reporte pdf.
         *
         * @method getArrayReasearchOrgans
         *
         * @author Ing. Argenis Osorio <aosorio@cenditel.gob.ve>
        */
        getArrayReasearchOrgans() {
            const vm= this;
            if (vm.partial_organs) {
                const a = vm.getPartial(vm.partial_organs,'reasearch');
                let arrayRasearchOrgans = [];
                for (let organ of a) {
                    vm.getNameOrgan(organ)
                    arrayRasearchOrgans.push(
                        vm.getNameOrgan(organ)
                    );
                }
                return arrayRasearchOrgans;
            }
            else {
                return [];
            }
        },

        /**
         * Método que elimina el órgano seleccionado de la tabla de registros
         *
         * @author  Luis Ramírez <lgramirez@cenditel.gob.ve>
         *
         * @param  {Object}  organ objeto que contiene los datos del órgano seleccionado
         *
         */
        removeOrgan(organ){
            const vm = this;
            vm.organs_selected = vm.organs_selected.filter(e => e.id_organ !== organ)
        },

        addHla_test_results() {
            const vm = this;
            vm.record.hla_test_results = JSON.stringify({
                allele_1_a: vm.allele_1_a ? vm.allele_1_a : '',
                allele_1_b: vm.allele_1_b ? vm.allele_1_b : '',
                allele_1_c: vm.allele_1_c ? vm.allele_1_c : '',
                allele_1_DRB1: vm.allele_1_DRB1 ? vm.allele_1_DRB1 : '',
                allele_1_DBQ1: vm.allele_1_DBQ1 ? vm.allele_1_DBQ1 : '',
                allele_1_DPB1: vm.allele_1_DPB1 ? vm.allele_1_DPB1 : '',
                allele_1_DQA1: vm.allele_1_DQA1 ? vm.allele_1_DQA1 : '',
                allele_1_DPA1: vm.allele_1_DPA1 ? vm.allele_1_DPA1 : '',
                allele_1_DRB3: vm.allele_1_DRB3 ? vm.allele_1_DRB3 : '',
                allele_1_DRB4: vm.allele_1_DRB4 ? vm.allele_1_DRB4 : '',
                allele_1_DRB5: vm.allele_1_DRB5 ? vm.allele_1_DRB5 : '',
                allele_2_a: vm.allele_2_a ? vm.allele_2_a : '',
                allele_2_b: vm.allele_2_b ? vm.allele_2_b : '',
                allele_2_c: vm.allele_2_c ? vm.allele_2_c : '',
                allele_2_DRB1: vm.allele_2_DRB1 ? vm.allele_2_DRB1 : '',
                allele_2_DBQ1: vm.allele_2_DBQ1 ? vm.allele_2_DBQ1 : '',
                allele_2_DPB1: vm.allele_2_DPB1 ? vm.allele_2_DPB1 : '',
                allele_2_DQA1: vm.allele_2_DQA1 ? vm.allele_2_DQA1 : '',
                allele_2_DPA1: vm.allele_2_DPA1 ? vm.allele_2_DPA1 : '',
                allele_2_DRB3: vm.allele_2_DRB3 ? vm.allele_2_DRB3 : '',
                allele_2_DRB4: vm.allele_2_DRB4 ? vm.allele_2_DRB4 : '',
                allele_2_DRB5: vm.allele_2_DRB5 ? vm.allele_2_DRB5 : '',
            })
        },

        /**
         * Método que rellena el campo edad según le fecha de nacimiento
         * seleccionada.
         *
         * @method setAge
         *
         * @author José Briceño <josejorgebriceno9@gmail.com>
         */
        setAge() {
            const vm = this;
            let age = moment().diff(vm.record.birthdate, "years", false);
            vm.record.age = age > -1 ? age : "";
        },

        /**
         * Método que obtiene el archivo subido al campo respectivo.
         *
        */
        uploadFile(event){
            const vm = this;
            const max_allowed_size = vm.maxWeightAllow();
            if (event.target.files[0].size) {
                if (event.target.files[0].size < max_allowed_size) {
                    switch (event.target.name) {
                        case "serology_test_file":
                            vm.record.serology_test_file = event.target.files[0];
                            break;

                        case "document_neurological_death":
                            vm.record.document_neurological_death = event.target.files[0];
                            break;

                        case "family_acceptance_document":
                            vm.record.family_acceptance_document = event.target.files[0];
                            break;

                        case "document_protocol_violent_death":
                            vm.record.document_protocol_violent_death = event.target.files[0];
                            break;

                        case "document_hepatectomy_form":
                            vm.record.document_hepatectomy_form = event.target.files[0];
                            break;
                    }
                } else {
                    event.target.value = '';
                    vm.maxWeightExceededMessage();
                }
            }
        },

        /**
         * Método que agrega órganos extraídos sobre la tabla de registros
         *
         * @author  Luis Ramírez <lgramirez@cenditel.gob.ve>
         *
         * @param  {Object}  organ objeto a añadir en los registro de órganos seleccionados
         *
         */
        addOrgan(organ){
            const vm = this;
            let index = vm.organs_selected.length;
            vm.organs_selected.unshift({
                id_organ: vm.record.dni+'_'+organ.id+'_'+(index+1),
                number: 1,
                organ: organ.name,
                observation: ''
            })
        },

        /**
         * Método que elimina el órgano seleccionado de la tabla de registros
         *
         * @author  Luis Ramírez <lgramirez@cenditel.gob.ve>
         *
         * @param  {Object}  organ objeto que contiene los datos del órgano seleccionado
         *
         */
        removeOrgan(organ){
            const vm = this;
            vm.organs_selected = vm.organs_selected.filter(e => e.id_organ !== organ)
        },

        /**
         * Ejecuta la acción para guardar o actualizar datos
         *
         * @method setRecord
         *
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         *
         * @param  {String}  url URL de la petición a realizar
         */
        async setRecord(url) {

            const vm = this;
            this.errors = [];
            vm.loadingState(true); // Inicio de spinner de carga.

            vm.record.organs_selected = JSON.stringify(vm.organs_selected);

            if (vm.record.not_donor_reason == '' && vm.record.donorType == 'ND') {
                this.errors.push('Especifique el motivo por el cual no desea ser donante');
            }

            if(vm.record.donorSchema == '') {
                this.errors.push('Complete la sección datos del donante');
            }

            this.addHla_test_results();

            let formData = new FormData();
            vm.record.donor_id = vm.$route.params.id;

            let data = Object.entries(vm.record);

            data.forEach(element => {
                formData.append(`${element[0]}`, `${element[1]}`);
            });

            formData.append('serology_test_file', vm.record.serology_test_file);
            formData.append('healthcare_center_where_died', vm.selectedOptions.healthcare_center_where_died);
            formData.append('document_neurological_death', vm.record.document_neurological_death);
            formData.append('family_acceptance_document', vm.record.family_acceptance_document);
            formData.append('document_protocol_violent_death', vm.record.document_protocol_violent_death);
            formData.append('document_hepatectomy_form', vm.record.document_hepatectomy_form);
            formData.append('hla_test_results', vm.record.hla_test_results);

            if (this.errors.length === 0) {
                const recordId = (typeof(vm.record.id)!=="undefined" && vm.record.id)
                ? vm.record.id : '';

                await axios({
                    method: (recordId) ? 'put' : 'post',
                    url: `${window.url}/${url}/${recordId}`,
                    data: formData,
                }).then(response => {
                    vm.showMessage('updated');
                    vm.loadingState(); // Finaliza spinner de carga.
                    // Redireccionar al componente anterior luego del guardado.
                    setTimeout(() => vm.$router.go(-1), 3000);
                }).catch(error => {
                    vm.loadingState(); // Finaliza spinner de carga.
                    vm.setErrors(error);
                });
            }
        },

        /**
         * Método para Descargar Archivos
         *
         * @method downloadFile
         *
         */
        downloadFile(file,nombre) {
            const vm = this;
            const url = file;
            const formData = new FormData();
            formData.append("url", url);
            axios({
                url: `${window.url}` + "/download",
                method: "post",
                data: formData,
                responseType: "blob"
            }).then(response => {
                let fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                let fileLink = document.createElement("a");
                let ext =file.substring(file.lastIndexOf('.')+1, file.length) || file                
                fileLink.href = fileURL;
                fileLink.setAttribute("download",nombre+"."+ext);
                document.body.appendChild(fileLink);
                fileLink.click();
                URL.revokeObjectURL(fileLink.href);
            });
        },
    },

    async mounted() {
        const vm = this;
        /**
         * Si el usuario autenticado intenta acceder al componente sin tener el
         * rol requerido este no carta los diferentes datos a consultar y
         * redirecciona a la pantalla inicial.
        */
        vm.user_role = vm.$parent.user.role;
        if ((vm.user_role == 'ADM') || (vm.user_role == 'CHO') || (vm.user_role == 'PIN')) {
            vm.loadingState(true); // Inicio de spinner de carga.
            // Obtener listado de órganos.
            await vm.getRecords(vm.url_organ, 'organs');
            // Obtener el listado de los centros generadores de órganos y tejidos.
            await vm.getRecords('health-center-type/organs-and-tissues', 'health_center_organs_and_tissues');
            // Obtener el listado de los grupos sanguíneos.
            await vm.getRecords('global-data-type/blood_group', 'blood_groups');
            // Obtener el listado de los órganos.
            await vm.getRecords('global-data-type/organ', 'organs')
            // Obtener el listado del personal de inmunología.
            await vm.getRecords('users-by-role/PIN', 'users_pin')
            // Obtener los registros de los niveles académicos.
            await vm.getRecords("global-data-type/academic_level", "academic_levels");
            // Obtener el listado de ocupaciones.
            await vm.getRecords(vm.occupations_url, "occupations", true);
            /** Consulta de datos del usuario gestor de Fundavene */
            vm.user_role = vm.$parent.user.role;
            vm.record.user_role = vm.$parent.user.role;
            vm.record.agent_user_id = vm.$parent.user.profile.user_id;
            vm.record.agent_names = vm.$parent.user.profile.names;
            vm.record.agent_surnames = vm.$parent.user.profile.surnames;
            vm.record.agent_mail = vm.$parent.user.email;
            vm.today = vm.getCurrentDate();
            await axios.get(`${window.url}` + '/profiles/' + vm.$parent.user.id)
            .then((response) => {
                let profile = response.data.profile;
                vm.record.agent_dni = profile.dni;
                vm.record.agent_phone = profile.phone;
                vm.record.agent_movil = profile.movil;
            }).catch(error => {
                console.log(error.response)
            });
            // Consulta de datos del donante gestionado.
            if (vm.$route.params.id) {
                axios.get(`${window.url}` + '/manage-will-donor/' + vm.$route.params.id)
                .then((response) => {
                    let profile = response.data.result.profile;
                    vm.record.names = profile.names;
                    vm.record.surnames = profile.surnames;
                    vm.record.gender = profile.gender;
                    vm.record.birthdate = new Date(profile.birthdate).toLocaleDateString('en-GB', {timeZone: 'UTC'});
                    vm.record.age = profile.age;
                    vm.record.birthplace = profile.birthplace;
                    vm.record.dni = profile.dni;
                    vm.record.phone = profile.phone;
                    vm.record.movil = profile.movil;
                    // Consultar los datos del usuario asociado al perfil y donante.
                    axios.get(`${window.url}` + '/users-list/' + profile.user_id)
                    .then((response) => {
                        vm.record.email = response.data.result.email;
                    }).catch(error => {
                        console.log(error.response);
                    });
                    vm.record.user_id = profile.user_id;
                    vm.record.created_at = response.data.result.created_at;
                    vm.record.registered_at = new Date(response.data.result.registered_at).toLocaleDateString('en-GB', {timeZone: 'UTC'});
                    vm.record.number = response.data.result.number;
                    vm.record.pin_id = (response.data.result.pin_id) ? response.data.result.pin_id : '';
                    vm.record.email_uin = (response.data.result.email_uin) ? response.data.result.email_uin : '';
                    vm.record.serology_test_observation = (response.data.result.serology_test_observation) ? response.data.result.serology_test_observation : '';
                    vm.organs_selected = (response.data.result.organs_selected) ? JSON.parse(response.data.result.organs_selected) : [];
                    vm.record.ganglios_quantity = (response.data.result.ganglios_quantity) ? response.data.result.ganglios_quantity : '';
                    vm.record.ganglios_observation = (response.data.result.ganglios_observation) ? response.data.result.ganglios_observation : '';
                    vm.record.tube_edta_quantity = (response.data.result.tube_edta_quantity) ? response.data.result.tube_edta_quantity : '';
                    vm.record.tube_edta_observation = (response.data.result.tube_edta_observation) ? response.data.result.tube_edta_observation : '';
                    vm.record.tube_w_additives_quantity = (response.data.result.tube_w_additives_quantity) ? response.data.result.tube_w_additives_quantity : '';
                    vm.record.tube_w_additives_observation = (response.data.result.tube_w_additives_observation) ? response.data.result.tube_w_additives_observation : '';
                    vm.data_serology_test_file = (response.data.result.serology_test_file) ? response.data.result.serology_test_file : '';
                    vm.data_document_neurological_death = (response.data.result.document_neurological_death) ? response.data.result.document_neurological_death : '';
                    vm.data_family_acceptance_document = (response.data.result.family_acceptance_document) ? response.data.result.family_acceptance_document : '';
                    vm.data_document_protocol_violent_death = (response.data.result.document_protocol_violent_death) ? response.data.result.document_protocol_violent_death : '';
                    vm.data_document_hepatectomy_form = (response.data.result.document_hepatectomy_form) ? response.data.result.document_hepatectomy_form : '';
                    vm.record.size = (response.data.result.size) ? response.data.result.size : '';
                    vm.record.weight = (response.data.result.weight) ? response.data.result.weight : '';
                    vm.record.death_date = (response.data.result.death_date) ? response.data.result.death_date : '';
                    vm.record.death_time = (response.data.result.death_time) ? response.data.result.death_time : '';
                    vm.record.diagnosis = (response.data.result.diagnosis) ? response.data.result.diagnosis : '';
                    if (response.data.result.hla_test_results == undefined) {
                        vm.hla_test_results = [];
                    }
                    else {
                        vm.hla_test_results = response.data.result.hla_test_results;
                        // Obteniendo los resultados de inmunología
                        var oJson = JSON.parse(vm.hla_test_results);
                        vm.allele_1_a = oJson.allele_1_a;
                        vm.allele_1_b = oJson.allele_1_b;
                        vm.allele_1_c = oJson.allele_1_c;
                        vm.allele_1_DRB1 = oJson.allele_1_DRB1;
                        vm.allele_1_DBQ1 = oJson.allele_1_DBQ1;
                        vm.allele_1_DPB1 = oJson.allele_1_DPB1;
                        vm.allele_1_DQA1 = oJson.allele_1_DQA1;
                        vm.allele_1_DPA1 = oJson.allele_1_DPA1;
                        vm.allele_1_DRB3 = oJson.allele_1_DRB3;
                        vm.allele_1_DRB4 = oJson.allele_1_DRB4;
                        vm.allele_1_DRB5 = oJson.allele_1_DRB5;
                        vm.allele_2_a = oJson.allele_2_a;
                        vm.allele_2_b = oJson.allele_2_b;
                        vm.allele_2_c = oJson.allele_2_c;
                        vm.allele_2_DRB1 = oJson.allele_2_DRB1;
                        vm.allele_2_DBQ1 = oJson.allele_2_DBQ1;
                        vm.allele_2_DPB1 = oJson.allele_2_DPB1;
                        vm.allele_2_DQA1 = oJson.allele_2_DQA1;
                        vm.allele_2_DPA1 = oJson.allele_2_DPA1;
                        vm.allele_2_DRB3 = oJson.allele_2_DRB3;
                        vm.allele_2_DRB4 = oJson.allele_2_DRB4;
                        vm.allele_2_DRB5 = oJson.allele_2_DRB5;
                    }
                    vm.selectedOptions.rh_factor = (response.data.result.rh_factor) ? response.data.result.rh_factor : '';
                    vm.selectedOptions.blood_group = (response.data.result.blood_group)?parseInt(response.data.result.blood_group):'';
                    vm.selectedOptions.healthcare_center_where_died = (response.data.result.healthcare_center_where_died)?parseInt(response.data.result.healthcare_center_where_died):'';
                    vm.selectedOptions.nationality = profile.nationality;
                    vm.selectedOptions.marital_status = profile.marital_status;
                    vm.selectedOptions.gender = profile.gender;
                    vm.selectedOptions.ocupation = (profile.ocupation)?parseInt(profile.ocupation):'';
                    vm.selectedOptions.academic_level = profile.academic_level;
                    vm.selectedOptions.estate_id = (profile.estate_id)?parseInt(profile.estate_id):'';
                    vm.record.donorSchema = response.data.result.donorSchema;
                    vm.partial_organs = response.data.result.partial_organs;
                    vm.getEstates();
                    vm.getMunicipalities();
                    vm.getParishes();
                    vm.getMunicipalities(parseInt(profile.estate_id));
                    vm.selectedOptions.municipality_id = (profile.municipality_id)?parseInt(profile.municipality_id):'';
                    vm.getParishes(parseInt(profile.municipality_id));
                    vm.selectedOptions.parish_id = (profile.parish_id)?parseInt(profile.parish_id):'';
                    // Estatus 3 = Donante efectivo.
                    vm.record.status = 3;
                }).catch(error => {
                    console.log(error.response);
                });
            }
            vm.loadingState(); // Finaliza spinner de carga.
        }
        else {
            location.href = '/';
        }
    },

    computed:{
        /**
         * Método que devuelve disabled si viene un id en los parámetros.
         *
         * @method getDisabled
         *
         * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <kingangelo_123@gmail.com>
         */
        getDisabled: function(){
            return this.$route.params.id ? 'disabled' : null;
        },

        /**
         * Método que devuelve disabled si el rol del usuario autenticado es
         * de Personal de inmunología (PIN).
         *
         * @method getDisabledByRolePIN
         *
         * @author Ing. Argenis Osorio <aosorio@cenditel.gob.ve>
         */
        getDisabledByRolePIN: function() {
            const vm = this;
            if (vm.user_role == 'PIN') {
                return 'disabled';
            }
            else {
                return null;
            }
        },

        /**
         * Método que devuelve disabled si el rol del usuario autenticado es
         * Coordinador hospitalario (CHO).
         *
         * @method getDisabledByRoleCHO
         *
         * @author Ing. Argenis Osorio <aosorio@cenditel.gob.ve>
         */
        getDisabledByRoleCHO: function() {
            const vm = this;
            if (vm.user_role == 'CHO') {
                return 'disabled';
            }
            else {
                return null;
            }
        },
    },

    watch: {
        /**
         * Método que reinicia el campo fecha de nacimiento cuando el año supera los 4 digitos
         *
         * @param {*} newValue
         */
        "record.birthdate": function (newValue) {
            const vm = this;
            let date = newValue ? newValue.split("-") : "";
            let current_year = new Date().getFullYear();
            vm.record.birthdate = date ? (parseInt(date[0]) > current_year ? `${current_year}-${date[1]}-${date[2]}` : newValue) : "";
        },

        /**
         * Método que reinicia el campo fecha de muerte cuando el año supera los 4 digitos
         *
         * @param {*} newValue
         */
         "record.death_date": function (newValue) {
            const vm = this;
            let date = newValue ? newValue.split("-") : "";
            let current_year = new Date().getFullYear();
            vm.record.death_date = date ? (parseInt(date[0]) > current_year ? `${current_year}-${date[1]}-${date[2]}` : newValue) : "";
        },
    }
}
</script>

<style scope>
</style>
