<template>
    <div id="transplantation-center-component">
        <div class="card mb-4 shadow-sm">
            <h2 ref="header-form" class="h3 text-center text-primary
                text-uppercase py-3 border-bottom border-primary"
            >
                <i class="fa fa-pencil-alt"></i> Registro de centro de trasplante
            </h2>
            <div class="card-body">
                <form-errors :errors="errors"></form-errors>
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-6">
                        <div class="mb-3">
                            <label for="name" class="form-label">
                                Nombre <span class="text-danger">*</span>
                            </label>
                            <input id="name" class="form-control" type="text"
                                maxlength="200" placeholder="Nombre" tabindex="1"
                                v-model="record.name" />
                        </div>
                        <div class="mb-3">
                            <label for="description" class="form-label">
                                Descripción
                            </label>
                            <input id="description" class="form-control"
                                type="text" maxlength="200" placeholder="Descripción"
                                tabindex="2" aria-describedby="descriptionHelp"
                                v-model="record.description" />
                            <div id="descriptionHelp" class="form-text">Opcional</div>
                        </div>
                        <div class="mb-3">
                            <label for="estate_id" class="form-label">
                                Estado <span class="text-danger">*</span>
                            </label>
                            <select id="estate_id" class="form-select"
                                tabindex="3" v-model="record.estate_id"
                                @change="getMunicipalities()"
                            >
                                <option value=""> -- Seleccione -- </option>
                                <option :value="estate.id" v-for="estate in estates"
                                    :selected="estate.id == record.estate_id">
                                    {{ estate.text }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="municipality_id" class="form-label">
                                Municipio <span class="text-danger">*</span>
                            </label>
                            <select id="municipality_id" class="form-select"
                                tabindex="4" v-model="record.municipality_id"
                                @change="getParishes()"
                            >
                                <option value=""> -- Seleccione -- </option>
                                <option :value="municipality.id"
                                    v-for="municipality in municipalities"
                                    :selected="municipality.id == record.municipality_id">
                                    {{ municipality.text }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="parish_id" class="form-label">
                                Parroquia <span class="text-danger">*</span>
                            </label>
                            <select id="parish_id" class="form-select"
                                tabindex="5" v-model="record.parish_id"
                            >
                                <option value=""> -- Seleccione -- </option>
                                <option :value="parish.id"
                                    v-for="parish in parishes"
                                    :selected="parish.id == record.parish_id">
                                    {{ parish.text }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="address" class="form-label">
                                Dirección <span class="text-danger">*</span>
                            </label>
                            <input id="address" class="form-control" type="text"
                                maxlength="200" placeholder="Dirección"
                                tabindex="6" v-model="record.address" />
                        </div>
                        <div class="mb-3">
                            <label for="director_name" class="form-label">
                                Nombre del director(a) 
                                <span class="text-danger">*</span>
                            </label>
                            <input id="director_name" class="form-control"
                                type="text" maxlength="200"
                                placeholder="Nombre del director" tabindex="7"
                                v-model="record.director_name" />
                        </div>
                        <div class="mb-3">
                            <label for="phone" class="form-label">
                                Teléfono <span class="text-danger">*</span>
                            </label>
                            <input id="phone" class="form-control" type="text"
                                maxlength="11"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                placeholder="Teléfono" tabindex="8"
                                v-model="record.phone" />
                        </div>
                        <div class="mb-3">
                            <label for="director_trasplant_unit" class="form-label">
                                Nombre del director(a) de la unidad de trasplante
                            </label>
                            <input id="director_trasplant_unit" class="form-control"
                                type="text" maxlength="200"
                                placeholder="Nombre del director de la unidad de trasplante"
                                tabindex="9"
                                v-model="record.director_trasplant_unit " />
                        </div>
                        <div class="mb-3">
                            <label for="transplant_unit_phone" class="form-label">
                                Teléfono de la unidad de trasplante
                            </label>
                            <input id="transplant_unit_phone" class="form-control"
                                type="text" maxlength="11"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                placeholder="Teléfono de la unidad de trasplante"
                                tabindex="10" v-model="record.transplant_unit_phone" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer text-center">
                <button class="btn btn-outline-primary-dark" type="reset"
                    @click="reset()">
                    Limpiar
                </button>
                <button class="btn btn-primary" type="button"
                    @click="setRecord('health-center')">
                    <i class="fa fa-save"></i>
                    Guardar
                </button>
            </div>
        </div>
        <div class="card mb-4 shadow-sm">
            <h2 class="h3 text-center text-primary text-uppercase py-3
                border-bottom border-primary">
                <i class="fa fa-book"></i> Lista de centros de trasplante
            </h2>
            <div class="card-body">
                <v-client-table :columns="columns" :data="records"
                    :options="table_options"
                >
                <div slot="id" slot-scope="props" class="main-flex-buttons">
                    <button
                        class="btn btn-outline-primary-dark"
                        type="button"
                        title="Ver registro"
                        @click="show_info('health-center', props.row.id)" >
                        <i class="fa fa-eye"></i>
                    </button>
                    <button class="btn btn-outline-primary" type="button"
                        title="Editar registro"
                        @click="getRecord('health-center', props.row.id);
                        scrollMeTo('header-form');"
                    >
                        <i class="fa fa-edit"></i>
                    </button>
                    <!--button class="btn btn-outline-danger" type="button"
                        title="Eliminar registro"
                        @click="deleteRecordFiltered('health-center', url, props.row.id)"
                    >
                        <i class="fas fa-trash"></i>
                    </button-->
                </div>
                </v-client-table>
                <!-- Modal -->
                <div
                    id="show_transplantcenter"
                    class="modal fade"
                    tabindex="-1"
                    role="dialog">
                    <div
                        class="modal-dialog modal-dialog-centered modal-xl"
                        role="document"
                    >
                        <div class="modal-content" :key="key">
                            <div class="modal-header">
                                <h6>Información detallada del centro de transplante</h6>
                                <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div class="modal-body">
                                <!-- Datos Personales -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Datos
                                </h3>
                                <hr />
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="names" class="form-label"
                                            >Nombre</label
                                        >
                                        <input
                                            id="name"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.name"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="lastName" class="form-label"
                                            >Descripción</label
                                        >
                                        <input
                                            id="description"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.description"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="estate" class="form-label"
                                            >Estado</label
                                        >
                                        <input
                                            id="estate"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.estate"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="municipality"
                                            class="form-label"
                                            >Municipio</label
                                        >
                                        <input
                                            id="municipality"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.municipality"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="parish" class="form-label">Parroquia</label>
                                        <input
                                            id="parish"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.parish"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="address"
                                            class="form-label"
                                            >Dirección</label
                                        >
                                        <textarea
                                            id="address"
                                            class="form-control"
                                            disabled
                                            rows="4" cols="50"
                                        >{{modalQuery.address}}</textarea>
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="director_name"
                                            class="form-label"
                                            >Nombre del director(a)</label
                                        >
                                        <input
                                            id="director_name"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.director_name"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="phone"
                                            class="form-label"
                                        >
                                            Teléfono</label
                                        >
                                        <input
                                            id="phone"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.phone"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="director_trasplant_unit"
                                            class="form-label"
                                            >Nombre del director(a) de la unidad de trasplante</label
                                        >
                                        <input
                                            id="director_trasplant_unit"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.director_trasplant_unit"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="transplant_unit_phone"
                                            class="form-label"
                                        >
                                            Teléfono de la unidad de trasplante</label
                                        >
                                        <input
                                            id="transplant_unit_phone"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.transplant_unit_phone"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="modal-footer justify-content-center"
                            >
                                <button
                                    type="button"
                                    class="btn btn-outline-primary-dark"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    Cerrar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                errors: [],
				estates: [],
				municipalities: [],
				parishes: [],
                records: [],
                record: {
                    name: '',
                    description: '',
                    estate_id: '',
                    municipality_id: '',
                    parish_id: '',
                    address: '',
                    director_name: '',
                    phone: '',
                    director_trasplant_unit: '',
                    transplant_unit_phone: '',
                    type: 'transplant',
                },
                key:0,
                modalQuery: {
                    name: '',
                    description: '',
                    estate_id: '',
                    municipality_id: '',
                    parish_id: '',
                    estate: '',
                    municipality: '',
                    parish: '',
                    address: '',
                    director_name: '',
                    phone: '',
                    director_trasplant_unit: '',
                    transplant_unit_phone: '',
                    type: 'transplant',
                },
                url: 'health-center-type/transplant',
                columns: ['name', 'description', 'address', 'director_name', 'phone', 'id'],
            }
        },
        methods: {
            /**
             * Método para reestablecer valores iniciales del formulario
             *
             * @method reset
             *
             * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <kingangelo_123@gmail.com>
             * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
             */
            reset() {
                const vm = this;
                vm.errors = [];
                vm.record = {
                    name: '',
                    description: '',
                    estate_id: '',
                    municipality_id: '',
                    parish_id: '',
                    address: '',
                    director_name: '',
                    phone: '',
                    director_trasplant_unit: '',
                    transplant_unit_phone: '',
                    type: 'transplant',
                },
                vm.key=0,
                vm.modalQuery = {
                    name: '',
                    description: '',
                    estate_id: '',
                    municipality_id: '',
                    parish_id: '',
                    estate: '',
                    municipality: '',
                    parish: '',
                    address: '',
                    director_name: '',
                    phone: '',
                    director_trasplant_unit: '',
                    transplant_unit_phone: '',
                    type: 'transplant',
                }
            },
            getRecord(url, id) {
                const vm = this;
                const query = `${window.url}/${url}/${id}`;
                axios.get(query).then(response => {
                    vm.record = response.data.result;
                    vm.getMunicipalities();
                    vm.getParishes();
                }).catch(error => {
                    console.error(error);
                });
            },
            /**
            * Método para mostrar la informacion de un transplant center en un modal
            *
            * @method show_info
            *
            * @author José Briceño <josejorgebriceno9@gmail.com>
            * @author Angelo Osorio <danielking.321@gmail.com>
            */
            async show_info(url, id) {
                const vm = this;
                const user = `${window.url}/${url}/${id}`;
                axios.get(user).then(response => {
                    vm.modalQuery = response.data.result
                    vm.getLocation("parish", vm.modalQuery.parish_id)
                    
                    $("#show_transplantcenter").modal("show")
                });
            },
            /**
            * Método para obtener la representación textual de
            * la dirección (estado, municipio, parroquia)
            * dado el id de una parroquia.
            *
            * @method getLocation
            *
            * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
            * @param     {int}      id    ID de la parroquia
            */
            async getLocation(url, id) {
                const vm = this
                const query = `${window.url}/${url}/${id}`
                axios.get(query).then((response) => {
                    vm.modalQuery.parish = response.data.parish.name
                    vm.modalQuery.municipality = response.data.parish.municipality.name
                    vm.modalQuery.estate = response.data.parish.municipality.estate.name
                    vm.key++
                }).catch((error) => {
                    console.error(error);
                });
            },
        },

        created() {
            this.table_options.headings = {
                'name': 'Nombre',
                'description': 'Descripción',
                'address': 'Dirección',
                'director_name': 'Nombre del director',
                'phone': 'Teléfono',
                'id': 'Acción'
            };
            this.table_options.sortable = ['name', 'description'];
            this.table_options.filterable = ['name', 'description'];
            this.table_options.columnsClasses = {
                'id': 'col-2 text-center'
            };
        },

        async mounted() {
            const vm = this;
            /**
             * Si el usuario autenticado intenta acceder al componente sin tener el
             * rol requerido este no carta los diferentes datos a consultar y
             * redirecciona a la pantalla inicial.
            */
            vm.user_role = vm.$parent.user.role;
            if ((vm.user_role == 'ADM')) {
                vm.loadingState(true); // Inicio de spinner de carga.
                await vm.getRecords(vm.url, 'records');
                vm.getEstates();
                vm.loadingState(); // Finaliza spinner de carga.
            }
            else {
                location.href = '/';
            }
        }
    };
</script>
