<template>
    <div id="transplant-request-list-component">
        <div class="card mb-4 shadow-sm">
            <h2
                class="h3 text-center text-primary text-uppercase py-3 border-bottom
        border-primary"
            >
                <i class="fa fa-book"></i> Personas que necesitan un trasplante
                en este momento (Solicitudes de trasplante)
            </h2>
            <div class="card-body">
                <!-- Filtros de la tabla -->
                <div class="row">
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label"
                            >Cédula de identidad</label
                        >
                        <input
                            id=""
                            class="form-control"
                            type="text"
                            minlength="7"
                            maxlength="9"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Cédula de identidad"
                            v-model="filterBy.dni"
                        />
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label">Género</label>
                        <v-select
                            v-model="filterSelectedOptions.genderOption"
                            :options="genderOptions"
                            :reduce="genderOption => genderOption.id"
                            label="text"
                            id="genderOption"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label"
                            >Edad (Mayor que)</label
                        >
                        <input
                            id=""
                            class="form-control"
                            type="text"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Edad (Mayor que)"
                            v-model="filterBy.fromAge"
                        />
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label"
                            >Edad (Menor que)</label
                        >
                        <input
                            id=""
                            class="form-control"
                            type="text"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            placeholder="Edad (Menor que)"
                            v-model="filterBy.toAge"
                        />
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label">Grupo sanguíneo</label>
                        <v-select
                            v-model="filterSelectedOptions.blodGroupOption"
                            :options="blood_groups"
                            :reduce="blodGroupOption => blodGroupOption.id"
                            label="name"
                            id="blodGroupOption"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="" class="form-label">Factor RH</label>
                        <v-select
                            v-model="filterSelectedOptions.rhFactorOption"
                            :options="rhFactorOptions"
                            :reduce="rhFactorOption => rhFactorOption.id"
                            label="text"
                            id="rhFactorOption"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="estate_id" class="form-label">
                            Estado
                        </label>
                        <v-select
                            v-model="filterSelectedOptions.estate_id"
                            :options="estates"
                            :selectable="option => option.id !== ''"
                            :reduce="estate => estate.id"
                            label="text"
                            id="estate_id"
                            :tabindex="13"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="col-12 col-lg-3 mb-3">
                        <label for="transplante_id" class="form-label">
                            Tipo de trasplante
                        </label>
                        <v-select
                            v-model="filterSelectedOptions.transplante_id"
                            :options="transplants"
                            :reduce="transplant => transplant.id"
                            label="name"
                            id="transplantId"
                            :tabindex="6"
                            :selectable="option => option.id !== ''"
                            placeholder="-- Seleccione --"
                        >
                            <slot name="no-options">Sin registros.</slot>
                        </v-select>
                    </div>
                    <div class="row pb-4">
                        <div class="col-12 col-lg-3">
                            <button
                                class="btn btn-outline-primary-dark"
                                type="reset"
                                @click="resetFilters()"
                            >
                                Limpiar
                            </button>
                            <button
                                type="button"
                                class="btn btn-outline-primary-dark"
                                aria-label="Search"
                                @click="filterTransplanteRequest"
                            >
                                Buscar
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Final de filtros de la tabla -->

                <b>Registros encontrados: {{ records.length }}</b>

                <!-- Tabla -->
                <v-client-table
                    class="mt-3"
                    :columns="columns"
                    :data="records"
                    :options="table_options"
                >
                    <div slot="number" slot-scope="props">
                        {{ props.row.number }}
                    </div>
                    <div slot="created_at" slot-scope="props">
                        {{ convertDate(props.row.created_at) }}
                    </div>
                    <div slot="names" slot-scope="props">
                        {{ props.row.profile.names }}
                        {{ props.row.profile.surnames }}
                    </div>
                    <div
                        slot="id_number"
                        slot-scope="props"
                    >
                        {{ props.row.profile.dni }}
                    </div>
                    <div slot="transplant_type" slot-scope="props">
                        {{ getTransplantType(props.row.transplant_id) }}
                    </div>
                    <div slot="id" slot-scope="props">
                        <button
                            class="btn btn-outline-primary-dark"
                            type="button"
                            title="Ver registro"
                            @click="showInfo(props.row.id)"
                        >
                            <i class="fa fa-eye"></i>
                        </button>
                    </div>
                </v-client-table>
                <!-- Final de tabla -->
                <!-- Modal -->
                <div
                    id="show_transplant_request"
                    class="modal fade"
                    tabindex="-1"
                    role="dialog"
                >
                    <!-- modal-dialog -->
                    <div
                        class="modal-dialog modal-dialog-centered modal-xl"
                        role="document"
                    >
                        <!-- modal-content -->
                        <div class="modal-content">
                            <!-- modal-header -->
                            <div class="modal-header">
                                <h4>
                                    Información detallada de la Solicitud de
                                    Trasplante
                                </h4>
                                <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <!-- Final de modal-header -->

                            <!-- modal-body -->
                            <div class="modal-body">
                                <div class="row pb-4">
                                    <!-- Fecha de registro -->
                                    <div class="col-12 col-lg-2 mb-3">
                                        <label for="created_at" class="form-label">
                                            Fecha de registro
                                        </label>
                                        <input
                                            id="created_at"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="new Date(modalQuery.created_at).toLocaleDateString('en-GB')"
                                        />
                                    </div>

                                    <!-- Serial N° -->
                                    <div class="col-12 col-lg-2 mb-3">
                                        <label for="number" class="form-label">
                                            Serial N°
                                        </label>
                                        <input
                                            id="number"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.number"
                                        />
                                    </div>
                                </div>

                                <!-- Datos personales -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Datos personales
                                </h3>
                                <hr />
                                <div class="row pb-4" v-if="modalQuery && modalQuery.profile">
                                    <!-- Nombres -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="names" class="form-label">
                                            Nombres
                                        </label>
                                        <input
                                            id="names"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.profile.names"
                                        />
                                    </div>
                                    <!-- Apellidos -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="surnames" class="form-label">
                                            Apellidos
                                        </label>
                                        <input
                                            id="surnames"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.profile.surnames"
                                        />
                                    </div>
                                    <!-- Número de cédula de identidad -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="dni" class="form-label">
                                            Número de cédula de identidad
                                        </label>
                                        <input
                                            id="dni"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.profile.dni"
                                        />
                                    </div>
                                    <!-- Documento de la cédula de identidad -->
                                    <div
                                        class="col-12 col-lg-4 mb-3"
                                        v-if="modalQuery.url_dni"
                                    >
                                        <label for="idFile" class="form-label">
                                            Documento de la cédula de identidad
                                        </label>
                                        <button
                                            v-if="modalQuery.url_dni"
                                            class="btn btn-outline-primary-dark"
                                            title="Descarga el archivo previamente subido"
                                            @click="downloadFile(
                                                modalQuery.url_dni,
                                                'Documento de la cédula de identidad'
                                            )"
                                        >
                                            <i class="fa fa-download"></i>
                                            Descargar
                                        </button>
                                    </div>
                                    <!-- Documento de la foto actualizada -->
                                    <div class="col-12 col-lg-4 mb-3" v-if="modalQuery.updated_photo">
                                        <label for="updated_photo_file" class="form-label">
                                            Documento de la foto actualizada
                                        </label>
                                        <button
                                            class="btn btn-outline-primary-dark"
                                            title="Descarga el archivo previamente subido"
                                            @click="downloadFile(
                                                modalQuery.updated_photo,
                                                'Documento de la foto actualizada'
                                            )"
                                        >
                                            <i class="fa fa-download"></i>
                                            Descargar
                                        </button>
                                    </div>
                                    <!-- Nacionalidad -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="nationality" class="form-label">
                                            Nacionalidad
                                        </label>
                                        <input
                                            id="nationality"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="
                                                modalQuery.profile.nationality &&
                                                nationalityOptions.filter(x=> { return x.id ==
                                                modalQuery.profile.nationality})[0] ?
                                                nationalityOptions.filter(x=> { return x.id ==
                                                modalQuery.profile.nationality})[0].text:''
                                            "
                                        />
                                    </div>
                                    <!-- Fecha de nacimiento -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="birthdate"
                                            class="form-label"
                                        >
                                            Fecha de nacimiento
                                        </label>
                                        <input
                                            id="birthdate"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="
                                                new Date(modalQuery.profile.birthdate).
                                                toLocaleDateString('en-GB', { timeZone: 'UTC' })
                                            "
                                        />
                                    </div>
                                    <!-- Edad -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="age" class="form-label">
                                            Edad
                                        </label>
                                        <input
                                            id="age"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="profile.age"
                                        />
                                    </div>
                                    <!-- Lugar de nacimiento -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="birthplace"
                                            class="form-label"
                                        >
                                            Lugar de nacimiento
                                        </label>
                                        <input
                                            id="birthplace"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.profile.birthplace"
                                        />
                                    </div>
                                    <!-- Género -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="gender" class="form-label">
                                            Género
                                        </label>
                                        <input
                                            id="gender"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="
                                                modalQuery.profile.gender &&
                                                genderOptions.filter(x=> {
                                                return x.id==modalQuery.profile.gender})[0] ?
                                                genderOptions.filter(x=> {
                                                return x.id==modalQuery.profile.gender})[0].text:''
                                            "
                                        />
                                    </div>
                                    <!-- Ocupación -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="ocupation" class="form-label">
                                            Ocupación
                                        </label>
                                        <input
                                            id="gender"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="
                                                modalQuery.profile.ocupation &&
                                                occupations.filter(x=> {
                                                return x.id==parseInt(modalQuery.profile.ocupation)})[0] ?
                                                occupations.filter(x=> {
                                                return x.id==parseInt(modalQuery.profile.ocupation)})[0].name:''
                                            "
                                        />
                                    </div>
                                    <!-- Estado civil -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="marital_status" class="form-label">
                                            Estado civil
                                        </label>
                                        <input
                                            id="gender"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="
                                                modalQuery.profile.marital_status &&
                                                maritalStatusOptions.filter(x=> {
                                                return x.id==modalQuery.profile.marital_status})[0] ?
                                                maritalStatusOptions.filter(x=> {
                                                return x.id==modalQuery.profile.marital_status})[0].text:''
                                            "
                                        />
                                    </div>
                                    <!-- Nivel académico -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="academic_levels" class="form-label">
                                            Nivel académico
                                        </label>
                                        <input
                                            id="gender"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.profile.academic_level"
                                        />
                                    </div>
                                </div>
                                <!-- Final de datos personales -->

                                <!-- Dirección -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Dirección
                                </h3>
                                <hr />
                                <div class="row pb-4">
                                    <!-- Estado -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="estate" class="form-label">
                                            Estado
                                        </label>
                                        <input
                                            id="estate"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="location.estate"
                                        />
                                    </div>
                                    <!-- Municipio -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="municipality"
                                            class="form-label"
                                        >
                                            Municipio
                                        </label>
                                        <input
                                            id="municipality"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="location.municipality"
                                        />
                                    </div>
                                    <!-- Parroquia -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="parish" class="form-label">
                                            Parroquia
                                        </label>
                                        <input
                                            id="parish"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="location.parish"
                                        />
                                    </div>
                                    <!-- Dirección -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="adress" class="form-label">
                                            Dirección
                                        </label>
                                        <textarea
                                            id="adress"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            rows="4"
                                        >{{modalQuery.adress}}</textarea>
                                    </div>
                                </div>
                                <!-- Final de dirección -->

                                <!-- Contacto -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Contacto
                                </h3>
                                <hr />
                                <div class="row pb-4">
                                    <!-- Teléfono local -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="phone" class="form-label">
                                            Teléfono local
                                        </label>
                                        <input
                                            id="phone"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="profile.phone"
                                        />
                                    </div>
                                    <!-- Teléfono móvil -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="movil" class="form-label">
                                            Teléfono móvil
                                        </label>
                                        <input
                                            id="movil"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="profile.movil"
                                        />
                                    </div>
                                    <!-- Correo electrónico -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="email" class="form-label">
                                            Correo electrónico
                                        </label>
                                        <input
                                            id="email"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="user.email"
                                        />
                                    </div>
                                </div>
                                <!-- Final de Contacto -->

                                <!-- Datos del Trasplante -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Datos del Trasplante
                                </h3>
                                <hr />
                                <div class="row pb-4">
                                    <!-- Tipo de trasplante -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="transplant_id" class="form-label" >
                                            Tipo de trasplante
                                        </label>
                                        <input
                                            id="transplant_id"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="getTransplantType(modalQuery.transplant_id)"
                                        />
                                    </div>
                                    <!-- Grupo sanguíneo -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="patient_blood_type" class="form-label">
                                            Grupo sanguíneo
                                        </label>
                                        <input
                                            id="patient_blood_type"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="getBloodType(parseInt(modalQuery.patient_blood_type))"
                                        />
                                    </div>
                                    <!-- Factor RH -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="rhFactor" class="form-label">
                                            Factor RH
                                        </label>
                                        <input
                                            id="rhFactor"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="
                                                rhFactorOptions.filter(x =>x.id ==
                                                modalQuery.rh_factor )[0]?
                                                rhFactorOptions.filter(x =>x.id ==
                                                modalQuery.rh_factor )[0].text:''
                                            "
                                        />
                                    </div>
                                    <!-- ¿Posee Centro de trasplante? -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="donor" class="form-label">
                                            ¿Posee Centro de trasplante?
                                        </label>
                                        <input
                                            id="transplant_id"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.healthcare_center?'Si':'No'"
                                        />
                                    </div>
                                    <!-- Centro de trasplante -->
                                    <div
                                        class="col-12 col-lg-4 mb-3"
                                        v-if="modalQuery.healthcare_center"
                                    >
                                        <label
                                            for="healthcare_center"
                                            class="form-label"
                                        >
                                            Centro de trasplante
                                        </label>
                                        <input
                                            id="healthcare_center"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="
                                                getHealthcareCenter(
                                                    modalQuery.healthcare_center
                                                )
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <!-- Acta de nacimiento -->
                                    <div
                                        class="col-12 col-lg-4 mb-3"
                                        v-if="modalQuery.url_born_file"
                                    >
                                        <label for="born_file" class="form-label">
                                            Acta de nacimiento
                                        </label>
                                        <br>
                                        <button
                                            v-if="modalQuery.url_born_file"
                                            class="btn btn-outline-primary-dark"
                                            title="Descarga el archivo previamente subido"
                                            @click="
                                                downloadFile(
                                                    modalQuery.url_born_file,
                                                    'Acta de nacimiento'
                                                )
                                            "
                                        >
                                            <i class="fa fa-download"></i>
                                            Descargar
                                        </button>
                                    </div>

                                    <!-- Informe médico actualizado -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="report_file" class="form-label">
                                            Informe médico actualizado
                                        </label>
                                        <br>
                                        <button
                                            v-if="modalQuery.url_medic_report_file"
                                            class="btn btn-outline-primary-dark"
                                            title="Descarga el archivo previamente subido"
                                            @click="downloadFile(
                                                modalQuery.url_medic_report_file,
                                                'Informe médico actualizado'
                                            )"
                                        >
                                            <i class="fa fa-download"></i>
                                            Descargar
                                        </button>
                                    </div>

                                    <!-- Documento del tipo de sangre -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="bloodType" class="form-label">
                                            Documento del tipo de sangre
                                        </label>
                                        <br>
                                        <button
                                            v-if="modalQuery.url_blood_type_file"
                                            class="btn btn-outline-primary-dark"
                                            title="Descarga el archivo previamente subido"
                                            @click="downloadFile(
                                                modalQuery.url_blood_type_file,
                                                'Documento del tipo de sangre'
                                            )"
                                        >
                                            <i class="fa fa-download"></i>
                                            Descargar
                                        </button>
                                    </div>

                                    <!-- Resultado de prueba HLA -->
                                    <div class="col-12 col-lg-4 mb-3" v-if="modalQuery.transplant_id == 31 && modalQuery.url_hla_results_file">
                                        <label for="hlaResultsFile" class="form-label">
                                            Resultado de prueba HLA
                                        </label>
                                        <br>
                                        <button
                                            v-if="modalQuery.url_hla_results_file"
                                            class="btn btn-outline-primary-dark"
                                            title="Descarga el archivo previamente subido"
                                            @click="downloadFile(
                                                modalQuery.url_hla_results_file,
                                                'Resultado de prueba HLA'
                                            )"
                                        >
                                            <i class="fa fa-download"></i>
                                            Descargar
                                        </button>
                                    </div>
                                </div>
                                <div class="row pb-4">
                                    <!-- Diagnóstico -->
                                    <div class="row pb-2">
                                        <div class="col-12 col-lg-12 mb-3">
                                            <label for="diagnosis" class="form-label">
                                                Diagnóstico
                                            </label>
                                            <textarea
                                                id="diagnosis"
                                                class="form-control"
                                                name="diagnosis"
                                                cols="20"
                                                rows="5"
                                                disabled
                                                v-model="modalQuery.diagnosis"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <!-- ¿Posee posible donante vivo? -->
                                    <div
                                        class="col-12 col-lg-4 mb-3"
                                        v-if="modalQuery.transplant_id != 30 && modalQuery.transplant_id != 32 &&
                                              modalQuery.transplant_id != 33 && modalQuery.transplant_id != 34 &&
                                              modalQuery.transplant_id != 35"
                                    >
                                        <label for="donor" class="form-label">
                                            ¿Posee posible donante vivo?
                                        </label>
                                        <input
                                            id="healthcare_center"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="
                                                (modalQuery.living_donor &&
                                                    modalQuery.living_donor != []
                                                )?'Si':'No'
                                            "
                                        />
                                    </div>
                                    <!-- ¿Posee Padres? -->
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="dads" class="form-label">
                                            ¿Posee Padres?
                                        </label>
                                        <input
                                            id="healthcare_center"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="(modalQuery.mon_names || modalQuery.dad_names)?'Si':'No'"
                                        />
                                    </div>
                                </div>
                                <!-- Final de datos del trasplante -->

                                <!-- Datos del donante vivo -->
                                <div v-if="modalQuery.living_donor && modalQuery.living_donor != []">
                                    <h3
                                        class="h5 text-secondary text-uppercase"
                                    >
                                        Datos del donante vivo
                                    </h3>
                                    <hr />
                                </div>
                                <div
                                    v-if="modalQuery.living_donor != []"
                                    class="accordion mb-3"
                                    id="accordionPanelsStayOpenExample"
                                >
                                    <div
                                        class="accordion-item"
                                        v-for="(donor_array,
                                        index) in modalQuery.living_donor"
                                    >
                                        <h2 class="accordion-header" :id="'panelsStayOpen-heading' + index" >
                                            <button
                                                class="accordion-button h5 text-secondary text-uppercase"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                :data-bs-target="'#panelsStayOpen-collapse' + index"
                                                aria-expanded="true"
                                                :aria-controls="'panelsStayOpen-collapse' + index"
                                            >
                                                Datos del donante vivo {{ index + 1 }}
                                            </button>
                                        </h2>
                                        <div
                                            :id="'panelsStayOpen-collapse' + index "
                                            class="accordion-collapse collapse show"
                                            :aria-labelledby="'panelsStayOpen-heading' + index"
                                        >
                                            <div
                                                class="accordion-body row pb-4"
                                            >
                                                <!-- Nombres del donante vivo -->
                                                <div class="col-12 col-lg-4 mb-3">
                                                    <label for="donarNames" class="form-label" >
                                                        Nombres
                                                    </label>
                                                    <input
                                                        id="donorNames"
                                                        class="form-control"
                                                        name="donor_names"
                                                        disabled
                                                        v-model="donor_array.donor_names "
                                                        type="text"
                                                        placeholder="Nombres"
                                                    />
                                                </div>
                                                <!-- Apellidos del donante vivo -->
                                                <div class="col-12 col-lg-4 mb-3">
                                                    <label for="donorLastname" class="form-label" >
                                                        Apellidos
                                                    </label>
                                                    <input
                                                        id="donorLastname"
                                                        class="form-control"
                                                        name="donor_lastname"
                                                        disabled
                                                        v-model="donor_array.donor_lastname"
                                                        type="text"
                                                        placeholder="Apellidos"
                                                    />
                                                </div>
                                                <!-- Fecha de nacimiento del donante vivo -->
                                                <div
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="donorBirthday"
                                                        class="form-label"
                                                    >
                                                        Fecha de nacimiento
                                                    </label>
                                                    <input
                                                        tabindex="-1"
                                                        :id="
                                                            'donorBirthday_' +
                                                                index
                                                        "
                                                        class="form-control"
                                                        name="donor_birthday"
                                                        disabled
                                                        v-model="
                                                            donor_array.donor_birthday
                                                        "
                                                        type="date"
                                                    />
                                                </div>
                                                <!-- Edad del donante vivo -->
                                                <div
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="age"
                                                        class="form-label"
                                                    >
                                                        Edad
                                                    </label>
                                                    <input
                                                        :id="
                                                            'donor_age_' + index
                                                        "
                                                        class="form-control"
                                                        type="text"
                                                        name="donor_age"
                                                        min="1"
                                                        max="3"
                                                        placeholder="0"
                                                        disabled
                                                        tabindex="-1"
                                                        v-model="donor_array.donor_age"
                                                    />
                                                </div>
                                                <!-- Lugar de nacimiento del donante vivo -->
                                                <div
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="placeOfBirth"
                                                        class="form-label"
                                                    >
                                                        Lugar de nacimiento
                                                    </label>
                                                    <input
                                                        id="placeOfBirth"
                                                        class="form-control"
                                                        type="text"
                                                        name="place_of_birth"
                                                        disabled
                                                        placeholder="Lugar de nacimiento"
                                                        v-model="
                                                            donor_array.donor_place_of_birth
                                                        "
                                                    />
                                                </div>
                                                <!-- Nacionalidad del donante vivo -->
                                                <div
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="nationality"
                                                        class="form-label"
                                                    >
                                                        Nacionalidad
                                                    </label>
                                                    <input
                                                        id="nationality"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="
                                                            donor_array.donor_nationality ==
                                                            'V'
                                                                ? 'Venezolano(a)'
                                                                : 'Extranjero(a)'
                                                        "
                                                    />
                                                </div>
                                                <!-- Género del donante vivo -->
                                                <div
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="gender"
                                                        class="form-label"
                                                    >
                                                        Género
                                                    </label>
                                                    <input
                                                        id="gender"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="
                                                            showGender(
                                                                donor_array.donor_gender
                                                            )
                                                        "
                                                    />
                                                </div>
                                                <!-- Estado civil del donante vivo -->
                                                <div
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="marital_status"
                                                        class="form-label"
                                                    >
                                                        Estado civil
                                                    </label>
                                                    <input
                                                        id="marital_status"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="
                                                            showMaritalStatus(
                                                                donor_array.donor_marital_status
                                                            )
                                                        "
                                                    />
                                                </div>
                                                <!-- Ocupación del donante vivo -->
                                                <div
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="ocupation"
                                                        class="form-label"
                                                    >
                                                        Ocupación
                                                    </label>
                                                    <input
                                                        id="ocupation"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="
                                                            occupations.filter(
                                                                x =>
                                                                    x.id ==
                                                                    donor_array.donor_ocupation
                                                            )[0].name
                                                        "
                                                    />
                                                </div>
                                                <!-- Nivel académico del donante vivo -->
                                                <div
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="academic_levels"
                                                        class="form-label"
                                                    >
                                                        Nivel académico
                                                    </label>
                                                    <input
                                                        id="academic_level"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="donor_array.donor_academic_level"
                                                    />
                                                </div>
                                                <!-- Grupo sanguíneo -->
                                                <div
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="donor_blood"
                                                        class="form-label"
                                                    >
                                                        Grupo sanguíneo
                                                    </label>
                                                    <input
                                                        id="donor_blood"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="
                                                            blood_groups.filter(
                                                                x =>
                                                                    x.id ==
                                                                    donor_array.donor_blood
                                                            )[0].name
                                                        "
                                                    />
                                                </div>
                                                <!-- Factor RH del donante vivo -->
                                                <div
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="rhFactor"
                                                        class="form-label"
                                                    >
                                                        Factor RH
                                                    </label>
                                                    <input
                                                        id="donor_rh_factor"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="rhFactorOptions.filter(x =>x.id == donor_array.donor_rh_factor )[0].text"
                                                    />
                                                </div>
                                                <!-- Número de cédula de identidad del donante vivo -->
                                                <div
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="idNumber"
                                                        class="form-label"
                                                    >
                                                        Número cédula de identidad
                                                    </label>
                                                    <input
                                                        id="donor_idNumber"
                                                        class="form-control"
                                                        type="text"
                                                        name="donor_id_number"
                                                        minlength="7"
                                                        maxlength="9"
                                                        disabled
                                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                        placeholder="Número de cédula de identidad"
                                                        v-model="
                                                            donor_array.donor_dni
                                                        "
                                                    />
                                                </div>
                                                <!-- Documento de la cédula de identidad del donante -->
                                                <div
                                                    v-if="
                                                        donor_array.url_donor_dni_file
                                                    "
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="donorIdFile"
                                                        class="form-label"
                                                    >
                                                        Documento de la cédula de identidad
                                                    </label>
                                                    <button
                                                        class="btn btn-outline-primary-dark"
                                                        title="Descarga el archivo previamente subido"
                                                        @click="
                                                            downloadFile(
                                                                donor_array.url_donor_dni_file,
                                                                'Documento de la cédula de identidad del donante'
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="fa fa-download"
                                                        ></i>
                                                        Descargar
                                                    </button>
                                                </div>
                                                <!-- Documento del tipo de sangre del donante vivo -->
                                                <div
                                                    v-if="
                                                        donor_array.url_donor_blood_type_file
                                                    "
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="donorBloodType"
                                                        class="form-label"
                                                    >
                                                        Documento del tipo de sangre
                                                    </label>
                                                    <br>
                                                    <button
                                                        class="btn btn-outline-primary-dark"
                                                        title="Descarga el archivo previamente subido"
                                                        @click="
                                                            downloadFile(
                                                                donor_array.url_donor_blood_type_file,
                                                                'Documento del tipo de sangre del donante vivo'
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="fa fa-download"
                                                        ></i>
                                                        Descargar
                                                    </button>
                                                </div>
                                                <!-- Teléfono móvil del donante vivo -->
                                                <div
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="donor_cellphone"
                                                        class="form-label"
                                                    >
                                                        Teléfono móvil
                                                    </label>
                                                    <input
                                                        id="donor_cellphone"
                                                        class="form-control"
                                                        name="doctor_cellphone"
                                                        type="text"
                                                        v-model="donor_array.donor_cellphone"
                                                        placeholder="Teléfono móvil"
                                                        maxlength="11"
                                                        disabled
                                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                    />
                                                </div>
                                                <!-- Teléfono local del donante vivo -->
                                                <div
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="donor_phone"
                                                        class="form-label"
                                                    >
                                                        Teléfono local
                                                    </label>
                                                    <input
                                                        id="donor_phone"
                                                        class="form-control"
                                                        name="doctor_phone"
                                                        type="text"
                                                        v-model="donor_array.donor_phone"
                                                        placeholder="Teléfono local"
                                                        maxlength="11"
                                                        disabled
                                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                                    />
                                                </div>
                                                <!-- Correo electrónico del donante vivo -->
                                                <div
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="donor_email"
                                                        class="form-label"
                                                    >
                                                        Correo electrónico
                                                    </label>
                                                    <input
                                                        id="donor_email"
                                                        class="form-control"
                                                        type="email"
                                                        placeholder="Correo electrónico"
                                                        tabindex="5"
                                                        disabled
                                                        v-model="
                                                            donor_array.donor_email
                                                        "
                                                    />
                                                </div>
                                                <!-- Parentesco del donante vivo -->
                                                <div
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="relationship"
                                                        class="form-label"
                                                    >
                                                        Parentesco
                                                    </label>
                                                    <input
                                                        id="donor_blood"
                                                        class="form-control"
                                                        type="text"
                                                        disabled
                                                        :value="
                                                            relationships.filter(x=> x.id ==
                                                            donor_array.donor_relationship)[0] ?
                                                            relationships.filter(x=> x.id ==
                                                            donor_array.donor_relationship)[0].name:''
                                                        "
                                                    />
                                                </div>
                                                <!-- Acta de nacimiento del donante vivo -->
                                                <div
                                                    v-if="
                                                        donor_array.donor_relationship != 1 &&
                                                        donor_array.donor_relationship != 2 &&
                                                        donor_array.donor_relationship != 5 &&
                                                        donor_array.url_donor_born_file
                                                    "
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="donorFile"
                                                        class="form-label"
                                                    >
                                                        Acta de nacimiento
                                                    </label>
                                                    <br />
                                                    <button
                                                        class="btn btn-outline-primary-dark"
                                                        title="Descarga el archivo previamente subido"
                                                        @click="
                                                            downloadFile(
                                                                donor_array.url_donor_born_file,
                                                                'Acta de nacimiento del donante vivo'
                                                            )
                                                        "
                                                    >
                                                        <i class="fa fa-download"></i>
                                                        Descargar
                                                    </button>
                                                </div>
                                                <!-- Documento de la cédula de identidad del padre -->
                                                <div
                                                    v-if="
                                                        (donor_array.donor_relationship == 6 ||
                                                        donor_array.donor_relationship == 8 ||
                                                        donor_array.donor_relationship == 10 ||
                                                        donor_array.donor_relationship == 11) &&
                                                        donor_array.url_donor_dni_dad_file
                                                    "
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="donorFile"
                                                        class="form-label"
                                                    >
                                                        Documento de la cédula de identidad del padre
                                                    </label>
                                                    <br />
                                                    <button
                                                        class="btn btn-outline-primary-dark"
                                                        title="Descarga el archivo previamente subido"
                                                        @click="
                                                            downloadFile(
                                                                donor_array.url_donor_dni_dad_file,
                                                                'Documento de la cédula de identidad del padre'
                                                            )
                                                        "
                                                    >
                                                        <i class="fa fa-download"></i>
                                                        Descargar
                                                    </button>
                                                </div>
                                                <!-- Acta de nacimiento del padre -->
                                                <div
                                                    v-if="
                                                        (donor_array.donor_relationship == 6 ||
                                                        donor_array.donor_relationship == 8 ||
                                                        donor_array.donor_relationship == 10 ||
                                                        donor_array.donor_relationship == 11) &&
                                                        donor_array.url_donor_birth_certificate_dad_file
                                                    "
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="donorFile"
                                                        class="form-label"
                                                    >
                                                        Acta de nacimiento del padre
                                                    </label>
                                                    <br />
                                                    <button
                                                        class="btn btn-outline-primary-dark"
                                                        title="Descarga el archivo previamente subido"
                                                        @click="
                                                            downloadFile(
                                                                donor_array.url_donor_birth_certificate_dad_file,
                                                                'Acta de nacimiento del padre'
                                                            )
                                                        "
                                                    >
                                                        <i class="fa fa-download"></i>
                                                        Descargar
                                                    </button>
                                                </div>
                                                <!-- Documento de la cédula de identidad de la madre -->
                                                <div
                                                    v-if="
                                                        (donor_array.donor_relationship == 6 ||
                                                        donor_array.donor_relationship == 8 ||
                                                        donor_array.donor_relationship == 10 ||
                                                        donor_array.donor_relationship == 11) &&
                                                        donor_array.url_donor_dni_mother_file
                                                    "
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="donorFile"
                                                        class="form-label"
                                                    >
                                                        Documento de la cédula de identidad de la madre
                                                    </label>
                                                    <br />
                                                    <button
                                                        class="btn btn-outline-primary-dark"
                                                        title="Descarga el archivo previamente subido"
                                                        @click="
                                                            downloadFile(
                                                                donor_array.url_donor_dni_mother_file,
                                                                'Documento de la cédula de identidad de la madre'
                                                            )
                                                        "
                                                    >
                                                        <i class="fa fa-download"></i>
                                                        Descargar
                                                    </button>
                                                </div>
                                                <!-- Acta de nacimiento de la madre -->
                                                <div
                                                    v-if="
                                                        (donor_array.donor_relationship == 6 ||
                                                        donor_array.donor_relationship == 8 ||
                                                        donor_array.donor_relationship == 9 ||
                                                        donor_array.donor_relationship == 10 ||
                                                        donor_array.donor_relationship == 11) &&
                                                        donor_array.url_donor_birth_certificate_mother_file
                                                    "
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="donorFile"
                                                        class="form-label"
                                                    >
                                                        Acta de nacimiento de la madre
                                                    </label>
                                                    <br />
                                                    <button
                                                        class="btn btn-outline-primary-dark"
                                                        title="Descarga el archivo previamente subido"
                                                        @click="
                                                            downloadFile(
                                                                donor_array.url_donor_birth_certificate_mother_file,
                                                                'Acta de nacimiento de la madre'
                                                            )
                                                        "
                                                    >
                                                        <i class="fa fa-download"></i>
                                                        Descargar
                                                    </button>
                                                </div>
                                                <!-- Documento de la cédula de identidad del abuelo en común -->
                                                <div
                                                    v-if="
                                                        (donor_array.donor_relationship == 8 ||
                                                        donor_array.donor_relationship == 9 ||
                                                        donor_array.donor_relationship == 10 ||
                                                        donor_array.donor_relationship == 11) &&
                                                        donor_array.url_donor_dni_grandfather_file
                                                    "
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="donorFile"
                                                        class="form-label"
                                                    >
                                                        Documento de la cédula de identidad del abuelo en común
                                                    </label>
                                                    <br />
                                                    <button
                                                        class="btn btn-outline-primary-dark"
                                                        title="Descarga el archivo previamente subido"
                                                        @click="
                                                            downloadFile(
                                                                donor_array.url_donor_dni_grandfather_file,
                                                                'Documento de la cédula de identidad del abuelo en común'
                                                            )
                                                        "
                                                    >
                                                        <i class="fa fa-download"></i>
                                                        Descargar
                                                    </button>
                                                </div>
                                                <!-- Acta de nacimiento del abuelo en común -->
                                                <div
                                                    v-if="
                                                        (donor_array.donor_relationship == 9 ||
                                                        donor_array.donor_relationship == 10) &&
                                                        donor_array.url_donor_birth_certificate_grandfather_file
                                                    "
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="donorFile"
                                                        class="form-label"
                                                    >
                                                    Acta de nacimiento del abuelo en común
                                                    </label>
                                                    <br />
                                                    <button
                                                        class="btn btn-outline-primary-dark"
                                                        title="Descarga el archivo previamente subido"
                                                        @click="
                                                            downloadFile(
                                                                donor_array.url_donor_birth_certificate_grandfather_file,
                                                                'Acta de nacimiento del abuelo en común'
                                                            )
                                                        "
                                                    >
                                                        <i class="fa fa-download"></i>
                                                        Descargar
                                                    </button>
                                                </div>
                                                <!-- Documento de la cédula de identidad de la abuela en común -->
                                                <div
                                                    v-if="
                                                        (donor_array.donor_relationship == 8 ||
                                                        donor_array.donor_relationship == 9 ||
                                                        donor_array.donor_relationship == 10 ||
                                                        donor_array.donor_relationship == 11) &&
                                                        donor_array.url_donor_dni_grandmother_file
                                                    "
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="donorFile"
                                                        class="form-label"
                                                    >
                                                        Documento de la cédula de identidad de la abuela en común
                                                    </label>
                                                    <br />
                                                    <button
                                                        class="btn btn-outline-primary-dark"
                                                        title="Descarga el archivo previamente subido"
                                                        @click="
                                                            downloadFile(
                                                                donor_array.url_donor_dni_grandmother_file,
                                                                'Documento de la cédula de identidad de la abuela en común'
                                                            )
                                                        "
                                                    >
                                                        <i class="fa fa-download"></i>
                                                        Descargar
                                                    </button>
                                                </div>
                                                <!-- Acta de nacimiento de la abuela en común -->
                                                <div
                                                    v-if="
                                                        (donor_array.donor_relationship == 9 ||
                                                        donor_array.donor_relationship == 10) &&
                                                        donor_array.url_donor_birth_certificate_grandmother_file
                                                    "
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label
                                                        for="donorFile"
                                                        class="form-label"
                                                    >
                                                    Acta de nacimiento de la abuela en común
                                                    </label>
                                                    <br />
                                                    <button
                                                        class="btn btn-outline-primary-dark"
                                                        title="Descarga el archivo previamente subido"
                                                        @click="
                                                            downloadFile(
                                                                donor_array.url_donor_birth_certificate_grandmother_file,
                                                                'Acta de nacimiento de la abuela en común'
                                                            )
                                                        "
                                                    >
                                                        <i class="fa fa-download"></i>
                                                        Descargar
                                                    </button>
                                                </div>
                                                <!-- Acta de concubinato del donante vivo -->
                                                <div
                                                    v-if="
                                                        getRelationship(index) === 'concubinage' &&
                                                        donor_array.url_concubinage_file
                                                    "
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label for="donorFile" class="form-label">
                                                        Acta de concubinato
                                                    </label>
                                                    <br>
                                                    <button
                                                        class="btn btn-outline-primary-dark"
                                                        title="Descarga el archivo previamente subido"
                                                        @click="
                                                            downloadFile(
                                                                donor_array.url_concubinage_file,
                                                                'Acta de concubinato del donante vivo'
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="fa fa-download"
                                                        ></i>
                                                        Descargar
                                                    </button>
                                                </div>
                                                <!-- Acta de matrimonio del donante vivo -->
                                                <div
                                                    v-if="
                                                        getRelationship(index) === 'marriage' &&
                                                        donor_array.url_marriage_file
                                                    "
                                                    class="col-12 col-lg-4 mb-3"
                                                >
                                                    <label for="donorFile" class="form-label">
                                                        Acta de matrimonio
                                                    </label>
                                                    <br>
                                                    <button
                                                        class="btn btn-outline-primary-dark"
                                                        title="Descarga el archivo previamente subido"
                                                        @click="
                                                            downloadFile(
                                                                donor_array.url_marriage_file,
                                                                'Acta de matrimonio del donante vivo'
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="fa fa-download"
                                                        ></i>
                                                        Descargar
                                                    </button>
                                                </div>
                                                <!-- Certificado de adopción del donante vivo -->
                                                <div
                                                    v-if="
                                                        getRelationship(index) === 'adoption' &&
                                                        donor_array.url_adoption_file
                                                    "
                                                    class="col-12 col-sm-2 mb-3 mt-lg-4 pt-lg-2"
                                                >
                                                    <label
                                                        for="adoptionFile"
                                                        class="form-label"
                                                    >
                                                        Certificado de adopción
                                                    </label>
                                                    <br>
                                                    <button
                                                        class="btn btn-outline-primary-dark"
                                                        title="Descarga el archivo previamente subido"
                                                        @click="
                                                            downloadFile(
                                                                donor_array.url_adoption_file,
                                                                'Certificado de adopción del donante vivo'
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="fa fa-download"
                                                        ></i>
                                                        Descargar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Final de datos del donante vivo -->

                                <!-- Datos de los padres -->
                                <!-- Datos del Padre  -->
                                <div v-if="modalQuery.dad_names || modalQuery.dad_lastname">
                                    <h3 class="h5 text-secondary text-uppercase" >
                                        Datos del Padre
                                    </h3>
                                    <hr />
                                    <div class="row pb-4">
                                        <!-- Nombres del Padre -->
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                            v-if="modalQuery.dad_names"
                                        >
                                            <label
                                                for="dad_names"
                                                class="form-label"
                                            >
                                                Nombres
                                            </label>
                                            <input
                                                id="dad_names"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="modalQuery.dad_names"
                                            />
                                        </div>
                                        <!-- Apellidos del Padre -->
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                            v-if="modalQuery.dad_lastname"
                                        >
                                            <label
                                                for="dad_lastname"
                                                class="form-label"
                                            >
                                                Apellidos
                                            </label>
                                            <input
                                                id="dad_lastname"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="modalQuery.dad_lastname"
                                            />
                                        </div>
                                        <!-- Número de cédula de identidad del Padre -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="dad_dni_number"
                                                class="form-label"
                                            >
                                                Número de cédula de identidad
                                            </label>
                                            <input
                                                id="dad_dni_number"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    modalQuery.dad_dni_number
                                                "
                                            />
                                        </div>
                                        <!-- Documento de la cédula de identidad del padre -->
                                        <div
                                            class="col-12 col-lg-4"
                                            v-if="modalQuery.url_dad_dni_file"
                                        >
                                            <label
                                                for="url_dad_dni_file"
                                                class="form-label"
                                            >
                                                Documento de la cédula de identidad
                                            </label>
                                            <br>
                                            <button
                                                v-if="modalQuery.url_dad_dni_file"
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="downloadFile(
                                                    modalQuery.url_dad_dni_file,
                                                    'Documento de la cédula de identidad del'
                                                )"
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!-- Acta de nacimiento del padre-->
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                            v-if="modalQuery.url_dad_born_file"
                                        >
                                            <label for="born_file" class="form-label">
                                                Acta de nacimiento
                                            </label>
                                            <br>
                                            <button
                                                v-if="modalQuery.url_dad_born_file"
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        modalQuery.url_dad_born_file,
                                                        'Acta de nacimiento del padre'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!-- Teléfono móvil del Padre -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="dad_cellphone"
                                                class="form-label"
                                            >
                                                Teléfono móvil
                                            </label>
                                            <input
                                                id="dad_cellphone"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    modalQuery.dad_cellphone
                                                "
                                            />
                                        </div>
                                        <!-- Teléfono local del Padre -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="dad_phone"
                                                class="form-label"
                                            >
                                                Teléfono local
                                            </label>
                                            <input
                                                id="dad_phone"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="modalQuery.dad_phone"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <!-- Final de datos del Padre -->

                                <!-- Datos de la Madre -->
                                <div
                                    v-if="
                                        modalQuery.mon_names ||
                                            modalQuery.mon_lastname
                                    "
                                >
                                    <h3 class="h5 text-secondary text-uppercase">
                                        Datos de la Madre
                                    </h3>
                                    <hr />
                                    <div class="row pb-4">
                                        <!-- Nombres de la madre -->
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                            v-if="modalQuery.mon_names"
                                        >
                                            <label
                                                for="mon_names"
                                                class="form-label"
                                            >
                                                Nombres
                                            </label>
                                            <input
                                                id="mon_names"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="modalQuery.mon_names"
                                            />
                                        </div>
                                        <!-- Apellidos de la madre -->
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                            v-if="modalQuery.mon_lastname"
                                        >
                                            <label
                                                for="mon_lastname"
                                                class="form-label"
                                            >
                                                Apellidos
                                            </label>
                                            <input
                                                id="mon_lastname"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="modalQuery.mon_lastname"
                                            />
                                        </div>
                                        <!-- Número de cédula de identidad de la madre -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="mon_dni_number"
                                                class="form-label"
                                            >
                                                Número de cédula de identidad
                                            </label>
                                            <input
                                                id="mon_dni_number"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    modalQuery.mon_dni_number
                                                "
                                            />
                                        </div>
                                        <!-- Documento de la cédula de identidad de la madre -->
                                        <div
                                            class="col-12 col-lg-4"
                                            v-if="modalQuery.url_mon_dni_file"
                                        >
                                            <label
                                                for="url_mon_dni_file"
                                                class="form-label"
                                            >
                                                Documento de la cédula de identidad
                                            </label>
                                            <br>
                                            <button
                                                v-if="modalQuery.url_mon_dni_file"
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="downloadFile(
                                                    modalQuery.url_mon_dni_file,
                                                    'Documento de la cédula de identidad de la madre'
                                                )"
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!-- Acta de nacimiento de la madre-->
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                            v-if="modalQuery.url_mom_born_file"
                                        >
                                            <label for="born_file" class="form-label">
                                                Acta de nacimiento
                                            </label>
                                            <br>
                                            <button
                                                v-if="modalQuery.url_mom_born_file"
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="
                                                    downloadFile(
                                                        modalQuery.url_mom_born_file,
                                                        'Acta de nacimiento de la madre'
                                                    )
                                                "
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <!-- Teléfono móvil de la madre -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="mon_cellphone"
                                                class="form-label"
                                            >
                                                Teléfono móvil
                                            </label>
                                            <input
                                                id="mon_cellphone"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    modalQuery.mon_cellphone
                                                "
                                            />
                                        </div>
                                        <!-- Teléfono local de la madre -->
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="mon_phone"
                                                class="form-label"
                                            >
                                                Teléfono local
                                            </label>
                                            <input
                                                id="mon_phone"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="modalQuery.mon_phone"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <!-- Final de datos de la Madre -->
                                <!-- Datos de los padres -->

                                <!-- Datos adicionales -->
                                <div
                                    v-if="modalQuery.transplant_id == 28"
                                    class="mt-3"
                                >
                                    <h3
                                        class="h5 text-secondary text-uppercase"
                                    >
                                        Datos adicionales
                                    </h3>
                                    <hr />
                                    <div class="row pb-4">
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="dialisis_id"
                                                class="form-label"
                                            >
                                                Tipo de diálisis
                                            </label>
                                            <input
                                                id="dialisis_id"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    modalQuery.dialisis_id == 1
                                                    ? 'Peritoneal'
                                                    : 'Hemodiálisis'
                                                "
                                            />
                                        </div>
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="healthcare_center_diálisis"
                                                class="form-label"
                                            >
                                                Centro de diálisis
                                            </label>
                                            <input
                                                id="healthcare_center_diálisis"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    getHealthcareCentersDialisis(
                                                        modalQuery.healthcare_center_dialisis
                                                    )
                                                "
                                            />
                                        </div>
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="time_dialisis"
                                                class="form-label"
                                            >
                                                Fecha de inicio de diálisis
                                            </label>
                                            <input
                                                id="time_dialisis"
                                                class="form-control"
                                                type="date"
                                                disabled
                                                :value="modalQuery.time_dialisis"
                                            />
                                        </div>
                                    </div>

                                    <!-- Datos del médico de la unidad de diálisis -->
                                    <h3
                                        class="h5 text-secondary
                                        text-uppercase"
                                    > Datos del médico de la unidad de diálisis </h3>
                                    <hr />
                                    <div class="row pb-4">
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="doctor_dialisis_names"
                                                class="form-label"
                                            >
                                                Nombres
                                            </label>
                                            <input
                                                id="doctor_dialisis_names"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    modalQuery.doctor_dialisis_names
                                                "
                                            />
                                        </div>
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="doctor_dialisis_lastname"
                                                class="form-label"
                                            >
                                                Apellidos
                                            </label>
                                            <input
                                                id="doctor_dialisis_lastname"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    modalQuery.doctor_dialisis_lastname
                                                "
                                            />
                                        </div>
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="doctor_dialisis_dni_number"
                                                class="form-label"
                                            >
                                                Correo electrónico
                                            </label>
                                            <input
                                                id="doctor_dialisis_dni_number"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="modalQuery.doctor_dialisis_mail"
                                            />
                                        </div>
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="doctor_dialisis_cellphone"
                                                class="form-label"
                                            >
                                                Teléfono móvil
                                            </label>
                                            <input
                                                id="doctor_dialisis_cellphone"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    modalQuery.doctor_dialisis_cellphone
                                                "
                                            />
                                        </div>
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                            v-if="
                                                modalQuery.doctor_dialisis_phone
                                            "
                                        >
                                            <label
                                                for="doctor_dialisis_phone"
                                                class="form-label"
                                            >
                                                Teléfono de la unidad de diálisis
                                            </label>
                                            <input
                                                id="doctor_dialisis_phone"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    modalQuery.doctor_dialisis_phone
                                                "
                                            />
                                        </div>
                                    </div>
                                    <!-- Final de datos del médico de la unidad de diálisis -->
                                </div>
                                <!-- Final de datos adicionales -->

                                <!-- Datos del representante legal -->
                                <div
                                    v-if="
                                        modalQuery &&
                                        modalQuery.profile &&
                                        modalQuery.profile.age < 18 &&
                                        modalQuery.representative_names
                                    "
                                >
                                    <h3 class="h5 text-secondary text-uppercase">
                                        Datos del representante legal
                                    </h3>
                                    <hr />
                                    <div class="row pb-4">
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                            v-if="modalQuery.representative_names"
                                        >
                                            <label
                                                for="representative_names"
                                                class="form-label"
                                            >
                                                Nombres
                                            </label>
                                            <input
                                                id="representative_names"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="modalQuery.representative_names"
                                            />
                                        </div>
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                            v-if="modalQuery.representative_lastname"
                                        >
                                            <label
                                                for="representative_lastname"
                                                class="form-label"
                                            >
                                                Apellidos
                                            </label>
                                            <input
                                                id="representative_lastname"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="modalQuery.representative_lastname"
                                            />
                                        </div>
                                        <div class="col-12 col-lg-4 mb-3" v-if="modalQuery.representative_dni_number">
                                            <label
                                                for="representative_dni_number"
                                                class="form-label"
                                            >
                                                Número de cédula de identidad
                                            </label>
                                            <input
                                                id="representative_dni_number"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="modalQuery.representative_dni_number"
                                            />
                                        </div>
                                        <div class="col-12 col-lg-4 mb-3">
                                            <label
                                                for="representativeIdFile"
                                                class="form-label"
                                            >
                                                Documento de la cédula de identidad
                                            </label>
                                            <button
                                                v-if="modalQuery.url_representative_dni_file"
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="downloadFile(
                                                    modalQuery.url_representative_dni_file,
                                                    'Documento de la cédula de identidad representante legal'
                                                )"
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                            v-if="modalQuery.representative_cellphone"
                                        >
                                            <label
                                                for="representative_cellphone"
                                                class="form-label"
                                            >
                                                Teléfono móvil
                                            </label>
                                            <input
                                                id="representative_cellphone"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="modalQuery.representative_cellphone"
                                            />
                                        </div>
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                            v-if="modalQuery.representative_phone"
                                        >
                                            <label
                                                for="representative_phone"
                                                class="form-label"
                                            >
                                                Teléfono local
                                            </label>
                                            <input
                                                id="representative_phone"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="modalQuery.representative_phone"
                                            />
                                        </div>
                                        <div class="col-12 col-lg-4">
                                            <label
                                                for="legal_certifi_file"
                                                class="form-label"
                                            >
                                                Certificado de representante legal
                                            </label>
                                            <button
                                                v-if="modalQuery.url_legal_certifi_file"
                                                class="btn btn-outline-primary-dark"
                                                title="Descarga el archivo previamente subido"
                                                @click="downloadFile(
                                                    modalQuery.url_legal_certifi_file,
                                                    'Certificado de representante legal'
                                                )"
                                            >
                                                <i class="fa fa-download"></i>
                                                Descargar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <!-- Final de datos del representante legal -->

                                <!-- Datos del médico tratante -->
                                <div class="row pb-4" v-if="parseInt(modalQuery.transplant_id) != 28">
                                    <h3 class="h5 text-secondary text-uppercase">
                                        Datos del médico tratante
                                    </h3>
                                    <hr />
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="doctor_names"
                                            class="form-label"
                                        >
                                            Nombres
                                        </label>
                                        <input
                                            id="doctor_names"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.doctor_names"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="doctor_lastname"
                                            class="form-label"
                                        >
                                            Apellidos
                                        </label>
                                        <input
                                            id="doctor_lastname"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.doctor_lastname"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="doctor_mail"
                                            class="form-label"
                                        >
                                            Correo electrónico
                                        </label>
                                        <input
                                            id="doctor_mail"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.doctor_mail"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label
                                            for="doctor_cellphone"
                                            class="form-label"
                                        >
                                            Teléfono móvil
                                        </label>
                                        <input
                                            id="doctor_cellphone"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.doctor_cellphone"
                                        />
                                    </div>
                                    <div
                                        class="col-12 col-lg-4 mb-3"
                                    >
                                        <label
                                            for="doctor_phone"
                                            class="form-label"
                                        >
                                            Teléfono local
                                        </label>
                                        <input
                                            id="doctor_phone"
                                            class="form-control"
                                            type="text"
                                            disabled
                                            :value="modalQuery.doctor_phone"
                                        />
                                    </div>
                                </div>
                                <!-- Fin de datos del médico tratante -->

                                <!-- Datos del médico especialista en trasplante -->
                                <div v-if="modalQuery.healthcare_center">
                                    <h3 class="h5 text-secondary text-uppercase">
                                        Datos del médico especialista en
                                        trasplante
                                    </h3>
                                    <hr />
                                    <div class="row pb-4">
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="doctor_transplant_names"
                                                class="form-label"
                                            >
                                                Nombres
                                            </label>
                                            <input
                                                id="doctor_transplant_names"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    modalQuery.doctor_transplant_names
                                                "
                                            />
                                        </div>
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="doctor_transplant_lastname"
                                                class="form-label"
                                            >
                                                Apellidos
                                            </label>
                                            <input
                                                id="doctor_transplant_lastname"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    modalQuery.doctor_transplant_lastname
                                                "
                                            />
                                        </div>
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="doctor_transplant_mail"
                                                class="form-label"
                                            >
                                                Correo electrónico
                                            </label>
                                            <input
                                                id="doctor_transplant_mail"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    modalQuery.doctor_transplant_mail
                                                "
                                            />
                                        </div>
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="doctor_transplant_cellphone"
                                                class="form-label"
                                            >
                                                Teléfono móvil
                                            </label>
                                            <input
                                                id="doctor_transplant_cellphone"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    modalQuery.doctor_transplant_cellphone
                                                "
                                            />
                                        </div>
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="doctor_transplant_phone"
                                                class="form-label"
                                            >
                                                Teléfono local
                                            </label>
                                            <input
                                                id="doctor_transplant_phone"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    modalQuery.doctor_transplant_phone
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <!-- Final de datos del médico especialista en trasplante -->

                                <!-- Datos del médico cirujano de trasplante -->
                                <div v-if="modalQuery.healthcare_center">
                                    <h3 class="h5 text-secondary text-uppercase">
                                        Datos del médico cirujano de trasplante
                                    </h3>
                                    <hr />
                                    <div class="row pb-4">
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="doctor_transplant_surgeon_names"
                                                class="form-label"
                                            >
                                                Nombres
                                            </label>
                                            <input
                                                id="doctor_transplant_surgeon_names"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    modalQuery.doctor_transplant_surgeon_names
                                                "
                                            />
                                        </div>
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="doctor_transplant_surgeon_lastname"
                                                class="form-label"
                                            >
                                                Apellidos
                                            </label>
                                            <input
                                                id="doctor_transplant_surgeon_lastname"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    modalQuery.doctor_transplant_surgeon_lastname
                                                "
                                            />
                                        </div>
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="doctor_transplant_mail"
                                                class="form-label"
                                            >
                                                Correo electrónico
                                            </label>
                                            <input
                                                id="doctor_transplant_surgeon_mail"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    modalQuery.doctor_transplant_surgeon_mail
                                                "
                                            />
                                        </div>
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="doctor_transplant_surgeon_cellphone"
                                                class="form-label"
                                            >
                                                Teléfono móvil
                                            </label>
                                            <input
                                                id="doctor_transplant_surgeon_cellphone"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    modalQuery.doctor_transplant_surgeon_cellphone
                                                "
                                            />
                                        </div>
                                        <div
                                            class="col-12 col-lg-4 mb-3"
                                        >
                                            <label
                                                for="doctor_transplant_surgeon_phone"
                                                class="form-label"
                                            >
                                                Teléfono local
                                            </label>
                                            <input
                                                id="doctor_transplant_surgeon_phone"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    modalQuery.doctor_transplant_surgeon_phone
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <!-- Final de datos del médico cirujano de trasplante -->

                                <!-- Cita medica asignada -->
                                <div v-if="modalQuery && modalQuery.medical_citation && modalQuery.medical_citation.medical_citation_date">
                                    <h3 class="h5 text-secondary text-uppercase">
                                        Cita médica asignada
                                    </h3>
                                    <hr />
                                    <div class="row pb-4">
                                        <!-- Fecha de la cita -->
                                        <div class="col-12 col-lg-2">
                                            <label for="date" class="form-label">
                                                Fecha de la cita
                                            </label>
                                            <input
                                                id="date"
                                                class="form-control"
                                                type="date"
                                                name="date"
                                                readonly
                                                disabled
                                                :value="
                                                    modalQuery.medical_citation.
                                                    medical_citation_date
                                                "
                                            />
                                        </div>
                                        <!-- Fin de fecha de la cita -->
                                        <!-- Hora de la cita -->
                                        <div class="col-12 col-lg-2">
                                            <label for="citation_time" class="form-label">
                                                Hora de la cita
                                            </label>
                                            <input
                                                id="citation_time"
                                                class="form-control"
                                                name="citation_time"
                                                type="time"
                                                readonly
                                                disabled
                                                tabindex="13"
                                                :value="
                                                    modalQuery.medical_citation.
                                                    medical_citation_time
                                                "
                                            />
                                        </div>
                                        <!-- Fin de hora de la cita -->
                                        <div
                                            class="col-12 col-lg-4"
                                        >
                                            <label for="healthcareCenter" class="form-label">
                                                Centro de trasplante
                                            </label>
                                            <input
                                                id="healthcare_center"
                                                class="form-control"
                                                type="text"
                                                disabled
                                                :value="
                                                    getHealthcareCenter(
                                                        modalQuery.medical_citation.
                                                        medical_citation_healthcare_center
                                                    )
                                                "
                                            />
                                        </div>
                                        <!--  Asistencia -->
                                        <div class="col-12 col-lg-2">
                                            <label for="donor" class="form-label">
                                                ¿Asistió a la cita?
                                            </label>
                                            <input
                                                id="healthcareCenter"
                                                class="form-control"
                                                type="text"
                                                readonly
                                                disabled
                                                :value="
                                                    modalQuery.medical_citation.
                                                    medical_citation_assistance
                                                "
                                            />
                                        </div>
                                        <!-- Fin Asistencia -->
                                    </div>
                                    <!-- Observación -->
                                    <div class="row pb-2">
                                        <div class="col-12 col-lg-12 mb-3">
                                            <label for="Observación" class="form-label">
                                                Observación del solicitante
                                            </label>
                                            <textarea
                                                id="Observación"
                                                class="form-control"
                                                name="Observación"
                                                cols="20"
                                                rows="5"
                                                readonly
                                                disabled
                                                :value="
                                                    modalQuery.medical_citation.
                                                    medical_citation_observation
                                                "
                                            ></textarea>
                                        </div>
                                    </div>
                                    <!-- fin de Observación -->
                                </div>
                                <!-- Fin de cita medica asignada  -->

                                <!-- Observaciones de la solicitud -->
                                <div
                                    v-if="modalQuery.observation_request"
                                    class="col-12 col-lg-12 mb-3"
                                >
                                    <label
                                        class="form-label"
                                    >
                                        Observaciones de la solicitud
                                    </label>
                                    <textarea
                                        id="observation_request"
                                        class="form-control"
                                        type="text"
                                        disabled
                                        rows="4"
                                    >{{ modalQuery.observation_request }}
                                    </textarea>
                                </div>
                                <!-- Final de observaciones de la solicitud -->
                            </div>
                            <!-- Final de modal-body -->

                            <!-- modal-footer -->
                            <div class="modal-footer justify-content-center">
                                <button
                                    type="button"
                                    class="btn btn-outline-primary-dark"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    Cerrar
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-secondary-dark"
                                    @click="exportPDF()"
                                >
                                    <i class="fa fa-address-card"></i>
                                    Generar planilla
                                </button>
                            </div>
                            <!-- Fin de modal-footer -->
                        </div>
                        <!-- Fin del modal-content -->
                    </div>
                    <!-- Final de modal-dialog -->
                </div>
                <!-- Fin del modal -->
            </div>
        </div>
    </div>
</template>

<script>
import base64images from '../../base64images'
export default {
    data() {
        return {
            records: [],
            transplant_type: [],
            healthcare_center: [],
            blood_type: [],
            healthcare_centers_dialisis: [],
            columns: [
                "number",
                "created_at",
                "names",
                "id_number",
                "transplant_type",
                "id"
            ],
            url: "manage-aplicant",
            url_transplantation_type: "global-data-type/transplantation-type",
            url_healthcare_center: "health-center-type/transplant",
            url_blood_type: "global-data-type/blood_type",
            url_healthcare_centers_dialisis: "health-center-type/healthcare",
            modalQuery: {},
            profile: {},
            user: {},
            location: {
                parish: "",
                municipality: "",
                estate: ""
            },
            filterBy: {
                dni: "",
                fromAge: "",
                toAge: ""
            },
            filterSelectedOptions: {
                donationOption: "",
                genderOption: "",
                blodGroupOption: "",
                rhFactorOption: "",
                estate_id: "",
                transplante_id: "",
                estatus_id: ""
            },
            rhFactorOptions: [
                { id: "P", text: "Positivo" },
                { id: "N", text: "Negativo" }
            ],
            donationOptions: [
                { id: "T", text: "Total" },
                { id: "P", text: "Parcial" }
            ],
            genderOptions: [
                { id: "M", text: "Masculino" },
                { id: "F", text: "Femenino" }
            ],
            estates: [],
            blood_groups: [],
            transplants: [],
            estatusOptions: [
                { id: 1, text: "Enviada" },
                { id: 2, text: "Procesada" },
                { id: 99, text: "Negada" }
            ],
            tmpRecords: [],
            nationalityOptions: [
                { id: "V", text: "Venezolano(a)" },
                { id: "E", text: "Extranjero(a)" }
            ],
            genderOptions: [
                { id: "F", text: "Femenino" },
                { id: "M", text: "Masculino" }
            ],
            maritalStatusOptions: [
                { id: "S", text: "Soltero(a)" },
                { id: "C", text: "Casado(a)" },
                { id: "D", text: "Divorciado(a)" },
                { id: "V", text: "Viudo(a)" }
            ],
            occupations_url: "global-data-type/occupation",
            occupations: [],
            relationships: [],
        };
    },
    methods: {
        /**
         * Método para Descargar Archivos
         *
         * @method downloadFile
         *
         */
        async downloadFile(file, name) {
            const vm = this;
            const url = file;
            const formData = new FormData();
            formData.append("url", url);

            try {
                let response = await axios({
                    url: `${window.url}` + "/download-aplicant-file",
                    method: "post",
                    data: formData,
                    responseType: "arraybuffer"
                });
                let fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                let fileLink = document.createElement("a");
                fileLink.href = fileURL;
                let ext =
                    file.substring(file.lastIndexOf(".") + 1, file.length) ||
                    file;
                fileLink.setAttribute("download", name + "." + ext);
                document.body.appendChild(fileLink);
                fileLink.click();
                URL.revokeObjectURL(fileLink.href);
            } catch (error) {
                let { errors } = JSON.parse(
                    String.fromCharCode.apply(
                        null,
                        new Uint8Array(error.response.data)
                    )
                );
                vm.errors = [];

                for (var index in errors) {
                    if (errors[index]) {
                        vm.errors.push(errors[index][0]);
                    }
                }
            }
        },

        /**
         * Obtiene el nombre del tipo de trasplante dato un id.
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @method getTransplantType
         * @param     {int}      transplant_id      ID del tipo de trasplante
         */
        getTransplantType(transplant_id) {
            const vm = this;
            let name = "";

            vm.transplant_type.forEach(element => {
                if (element.id == transplant_id) {
                    name = element.name;
                }
            });

            return name;
        },

        /**
         * Obtiene el nombre del centro de trasplante dato un id.
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @method getTransplantType
         * @param     {int}      healthcare_center_id      ID del centro de trasplante
         */
        getHealthcareCenter(healthcare_center_id) {
            const vm = this;
            let name = "";

            vm.healthcare_center.forEach(element => {
                if (element.id == healthcare_center_id) {
                    name = element.name;
                }
            });

            return name;
        },

        /**
         * Obtiene el nombre del centro de dialisis dato un id.
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @method getTransplantType
         * @param     {int}      healthcare_centers_dialisis_id      ID del centro de dialisis
         */
        getHealthcareCentersDialisis(healthcare_centers_dialisis_id) {
            const vm = this;
            let name = "";

            vm.healthcare_centers_dialisis.forEach(element => {
                if (element.id == healthcare_centers_dialisis_id) {
                    name = element.name;
                }
            });

            return name;
        },

        /**
         * Obtiene el nombre del tipo de sangre dato un id.
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @method getTransplantType
         * @param     {int}      blood_type_id      ID del tipo de sangre
         */
        getBloodType(blood_type_id) {
            const vm = this;
            let name = "";

            vm.blood_groups.forEach(element => {
                if (element.id == blood_type_id) {
                    name = element.name;
                }
            });

            return name;
        },

        /**
         * Método que cambia el formato de visualización de la fecha en la
         * tabla de registros.
         *
         * @method convertDate
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {date}      date     fecha ha ser fornateada
         */
        convertDate(date) {
            return new Date(date).toLocaleDateString("en-GB");
        },

        /**
         * Método para mostrar el género.
         *
         * @method showGender
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         */
        showGender(gender) {
            let genderType = "";
            if (gender === "F") {
                genderType = "Femenino";
            } else if (gender === "M") {
                genderType = "Masculino";
            }
            return genderType;
        },

        /**
         * Método para mostrar el estado civil.
         *
         * @method showMaritalStatus
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         */
        showMaritalStatus(maritalStatus) {
            let maritalStatusType = "";
            if (maritalStatus === "S") {
                maritalStatusType = "Soltero(a)";
            } else if (maritalStatus === "C") {
                maritalStatusType = "Casado(a)";
            } else if (maritalStatus === "D") {
                maritalStatusType = "Divorciado(a)";
            } else if (maritalStatus === "V") {
                maritalStatusType = "Viudo(a)";
            }
            return maritalStatusType;
        },

        /**
         * Método que obtiene los registros de parentesco
         *
         * @method getKinship
         *
         * @author  Luis Ramírez <lgramirez@cenditel.gob.ve>
         *
         * @param  {String}  url URL de la petición a realizar
         *
         */
         getKinship(url) {
            const vm = this;
            const query = `${window.url}/${url}`;
            axios.get(query).then(response => {
                vm.relationships = response.data.result;
                vm.relationships.unshift({
                    id: "",
                    name: "Seleccione..."
                });
            })
            .catch(error => {
                console.error(error);
            });
        },
        /**
         * Define los documentos requeridos de un donante vivo para los
         * parentescos: concubinato, matrimonio y adopción.
         *
         * @method  getRelationship
         *
         * @author  Luis Ramírez <lgramirez@cenditel.gob.ve>
         * @author  Francisco Escala <fjescala@gmail.com>
         */
        getRelationship(index) {
            String.prototype.allReplace = function(obj) {
                var retStr = this;
                for (var x in obj) {
                    retStr = retStr.replace(new RegExp(x, "g"), obj[x]);
                }
                return retStr;
            };

            var relati = this.relationships.filter(
                e =>
                e.id ==
                this.modalQuery.living_donor[index].donor_relationship
            );
            var relationship = relati[0].name;
            let expresion = /concubi/i;
            let found = relationship.match(expresion);
            if (found !== null) {
                this.modalQuery.living_donor[index].relationship_name =
                "concubinage";
                return "concubinage";
            }
            expresion = /espos/i;
            found = relationship.match(expresion);
            if (found !== null) {
                this.modalQuery.living_donor[index].relationship_name =
                "marriage";
                return "marriage";
            }
            expresion = /adop/i;
            found = relationship.match(expresion);
            if (found !== null) {
                this.modalQuery.living_donor[index].relationship_name =
                "adoption";
                return "adoption";
            } else {
                this.modalQuery.living_donor[index].relationship_name =
                "family";
                return "family";
            }
        },

        /**
         * Método para mostrar la informacion de un registro
         * dado su ID en un modal
         *
         * @method showInfo
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {int}      id    ID del registro
         */
        async showInfo(id) {
            const vm = this;
            let recordEdit = JSON.parse(
                JSON.stringify(
                    vm.records.filter(rec => {
                        return rec.id === id;
                    })[0]
                )
            );
            vm.modalQuery = recordEdit;
            vm.profile = recordEdit.profile;
            vm.getUser("users", vm.profile.user_id);
            vm.getLocation("parish", vm.profile.parish_id);
            if (typeof vm.modalQuery.living_donor == 'string'){
                vm.modalQuery.living_donor = JSON.parse(vm.modalQuery.living_donor)
            }
            if (typeof vm.modalQuery.medical_citation == 'string') {
                vm.modalQuery.medical_citation = JSON.parse(vm.modalQuery.medical_citation)
            }
            else {
                vm.modalQuery.medical_citation = {};
            }
            // Establecimiento de valores del campo ¿Asistió a la cita? en el modal.
            if (vm.modalQuery.medical_citation.medical_citation_assistance == 'true') {
                vm.modalQuery.medical_citation.medical_citation_assistance = 'Sí'
            }
            else if (vm.modalQuery.medical_citation.medical_citation_assistance == 'false') {
                vm.modalQuery.medical_citation.medical_citation_assistance = 'No'
            }
            else {
                vm.modalQuery.medical_citation.medical_citation_assistance = ''
            }
            $("#show_transplant_request").modal("show");
        },

        /**
         * Método para obtener los datos del registro de un usuario
         *
         *
         * @method getLocation
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {int}      id    ID del usuario
         */
        async getUser(url, id) {
            const vm = this;
            const query = `${window.url}/${url}/${id}`;
            axios
            .get(query)
            .then(response => {
                vm.user = response.data.result;
            })
            .catch(error => {
                console.error(error);
            });
        },

        /**
         * Método para obtener la representación textual de
         * la dirección (estado, municipio, parroquia)
         * dado el id de una parroquia.
         *
         * @method getLocation
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {int}      id    ID de la parroquia
         */
        async getLocation(url, id) {
            const vm = this;
            const query = `${window.url}/${url}/${id}`;
            axios
            .get(query)
            .then(response => {
                vm.location.parish = response.data.parish.name;
                vm.location.municipality = response.data.parish.municipality.name;
                vm.location.estate = response.data.parish.municipality.estate.name;
            })
            .catch(error => {
                console.error(error);
            });
        },

        /**
         * Método que permite filtrar los datos de la tabla Donantes voluntarios.
         *
         * @method filterDonors
         *
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        filterTransplanteRequest() {
            const vm = this;
            vm.records = vm.tmpRecords
            .filter(rec => {
                return vm.filterBy.dni
                    ? rec.profile.dni === vm.filterBy.dni
                    : true;
            })
            .filter(rec => {
                return vm.filterBy.fromAge
                    ? rec.profile.age >= vm.filterBy.fromAge
                    : true;
            })
            .filter(rec => {
                return vm.filterBy.toAge
                    ? rec.profile.age <= vm.filterBy.toAge
                    : true;
            })
            .filter(rec => {
                return vm.filterSelectedOptions.genderOption
                ? rec.profile.gender ===
                vm.filterSelectedOptions.genderOption
                : true;
            })
            .filter(rec => {
                return vm.filterSelectedOptions.blodGroupOption
                ? parseInt(rec.patient_blood_type) ===
                parseInt(vm.filterSelectedOptions.blodGroupOption)
                : true;
            })
            .filter(rec => {
                return vm.filterSelectedOptions.rhFactorOption
                ? rec.rh_factor ===
                vm.filterSelectedOptions.rhFactorOption
                : true;
            })
            .filter(rec => {
                return vm.filterSelectedOptions.estate_id &&
                vm.filterSelectedOptions.estate_id != ""
                ? parseInt(rec.profile.estate_id) ===
                parseInt(vm.filterSelectedOptions.estate_id)
                : true;
            })
            .filter(rec => {
                return vm.filterSelectedOptions.transplante_id &&
                vm.filterSelectedOptions.transplante_id != ""
                ? parseInt(rec.transplant_id) ===
                parseInt(vm.filterSelectedOptions.transplante_id)
                : true;
            });
        },

        /**
         * Método para reestablecer valores iniciales del formulario de filtros.
         *
         * @method resetFilters
         *
         * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <kingangelo_123@gmail.com>
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        resetFilters() {
            const vm = this;
            vm.filterBy = {
                dni: "",
                fromAge: "",
                toAge: ""
            };
            vm.filterSelectedOptions.genderOption = "";
            vm.filterSelectedOptions.blodGroupOption = "";
            vm.filterSelectedOptions.rhFactorOption = "";
            vm.filterSelectedOptions.estate_id = "";
            vm.filterSelectedOptions.transplante_id = "";
            vm.filterSelectedOptions.estatus_id = "";
            vm.records = vm.tmpRecords;
        },

        /**
         * Método que permite exportar la planilla de la solicitud de trasplante.
         *
         * @method exportPDF
         *
         * @author Israel Carrasquel <icarrasquel@mppct.gob.ve>
        */
        exportPDF() {
            var docDefinition = {
                info: {
                    title: 'Solicitud de trasplante N° '+this.modalQuery.number,
                },
                pageOrientation: 'portrait',
                pageMargins: [40, 60, 40, 150],
                // Cintillo
                header: {
                    image: base64images.cintilloFundavene(),
                    width: 530,
                    style:'text_center'
                },
                content: [
                    // Cabecera del reporte
                    { text: '\n\nInformación de la Solicitud de Trasplante \n\n', style: 'header' },
                    {
                        columns: [
                            {
                                // Fecha de registro
                                style: 'subheader',
                                text: 'Fecha de registro',
                            },
                            {
                                // Serial N°
                                style: 'subheader',
                                text: 'Serial N°',
                            },
                            {
                                // Vacío
                                text: '',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Fecha de registro
                                text: new Date(this.modalQuery.created_at).toLocaleDateString('en-GB')+'\n\n',
                                style: 'text_query',
                            },
                            {
                                // Serial N°
                                text: this.modalQuery.number+'\n\n', style: 'text_query',
                            },
                            {
                                // Vacío
                                text: '',
                                style: 'text_query',
                            },
                        ]
                    },
                    // Datos personales
                    { text: 'Datos personales\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Nombres
                                style: 'subheader',
                                text: 'Nombres',
                            },
                            {
                                // Apellidos
                                style: 'subheader',
                                text: 'Apellidos',
                            },
                            {
                                // Número de cédula de identidad
                                style: 'subheader',
                                text: 'Número de cédula de identidad',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                text: this.modalQuery.profile.names+'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.modalQuery.profile.surnames+'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.modalQuery.profile.dni+'\n\n',
                                style: 'text_query',
                            }
                        ],
                    },
                    {
                        columns: [
                            {
                                // Fecha de nacimiento
                                style: 'subheader',
                                text: 'Fecha de nacimiento',
                            },
                            {
                                // Edad
                                style: 'subheader',
                                text: 'Edad',
                            },
                            {
                                // Lugar de nacimiento
                                style: 'subheader',
                                text: 'Lugar de nacimiento',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                text: new Date(this.modalQuery.profile.birthdate).toLocaleDateString('en-GB', { timeZone: 'UTC' }) +'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.modalQuery.profile.age+'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.modalQuery.profile.birthplace+'\n\n',
                                style: 'text_query',
                            }
                        ],
                    },
                    {
                        columns: [
                            {
                                // Nacionalidad
                                style: 'subheader',
                                text: 'Nacionalidad',
                            },
                            {
                                // Género
                                style: 'subheader',
                                text: 'Género',
                            },
                            {
                                // Ocupación
                                style: 'subheader',
                                text: 'Ocupación',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                text: this.modalQuery.profile.nationality && this.nationalityOptions.filter(x=> { return x.id==this.modalQuery.profile.nationality})[0] ? this.nationalityOptions.filter(x=> { return x.id==this.modalQuery.profile.nationality})[0].text:'',
                                style: 'text_query',
                            },
                            {
                                text: this.modalQuery.profile.gender && this.genderOptions.filter(x=> { return x.id==this.modalQuery.profile.gender})[0] ? this.genderOptions.filter(x=> { return x.id==this.modalQuery.profile.gender})[0].text:'',
                                style: 'text_query',
                            },
                            {
                                text: this.modalQuery.profile.ocupation && this.occupations.filter(x=> { return x.id==parseInt(this.modalQuery.profile.ocupation)})[0] ? this.occupations.filter(x=> { return x.id==parseInt(this.modalQuery.profile.ocupation)})[0].name:'',
                                style: 'text_query',
                            }
                        ],
                    },
                    {
                        columns: [
                            {
                                // Estado civil
                                style: 'subheader',
                                text: '\nEstado civil',
                            },
                            {
                                // Nivel académico
                                style: 'subheader',
                                text: '\nNivel académico ',
                            },
                            {
                                // vacío
                                style: 'subheader',
                                text: '\n',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                text: this.modalQuery.profile.marital_status && this.maritalStatusOptions.filter(x=> { return x.id==this.modalQuery.profile.marital_status})[0] ? this.maritalStatusOptions.filter(x=> { return x.id==this.modalQuery.profile.marital_status})[0].text:'',
                                style: 'text_query',
                            },
                            {
                                text: this.modalQuery.profile.academic_level+'\n\n',
                                style: 'text_query',
                            },
                            {
                                // vacío
                                style: 'subheader',
                                text: '',
                            },
                        ],
                    },
                    // Dirección
                    { text: 'Dirección \n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Estado
                                style: 'subheader',
                                text: 'Estado',
                            },
                            {
                                // Municipio
                                style: 'subheader',
                                text: 'Municipio',
                            },
                            {
                                // Parroquia
                                style: 'subheader',
                                text: 'Parroquia',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                text: this.location.estate +'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.location.municipality+'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.location.parish+'\n\n',
                                style: 'text_query',
                            }
                        ],
                    },
                    {
                        columns: [
                            {
                                // Dirección
                                style: 'subheader',
                                text: 'Dirección',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Dirección
                                style: 'text_query',
                                text: this.modalQuery.adress+'\n\n',
                            },
                        ],
                    },
                    // Contacto
                    { text: 'Contacto\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Teléfono local
                                style: 'subheader',
                                text: 'Teléfono local',
                            },
                            {
                                // Teléfono móvil
                                style: 'subheader',
                                text: 'Teléfono móvil',
                            },
                            {
                                // Correo electrónico
                                style: 'subheader',
                                text: 'Correo electrónico',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                text: this.profile.phone+'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.profile.movil +'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.user.email+'\n\n',
                                style: 'text_query',
                            }
                        ],
                    },
                    // Datos del Trasplante
                    { text: 'Datos del Trasplante\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Tipo de trasplante
                                style: 'subheader',
                                text: 'Tipo de trasplante',
                            },
                            {
                                // Grupo sanguíneo
                                style: 'subheader',
                                text: 'Grupo sanguíneo',
                            },
                            {
                                // Factor RH
                                style: 'subheader',
                                text: 'Factor RH',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                text: this.getTransplantType(this.modalQuery.transplant_id) +'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.getBloodType(parseInt(this.modalQuery.patient_blood_type))+'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.rhFactorOptions.filter(x =>x.id == this.modalQuery.rh_factor )[0]?this.rhFactorOptions.filter(x =>x.id == this.modalQuery.rh_factor )[0].text:'',
                                style: 'text_query',
                            }
                        ],
                    },
                    {
                        columns: [
                            {
                                // Tipo de trasplante
                                style: 'subheader',
                                text: '¿Posee Centro de trasplante?',
                            },
                            {
                                style: 'subheader',
                                text: this.modalQuery.healthcare_center ? 'Centro de transplante' : '',
                            },
                            {
                                style: 'subheader',
                                text: '',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Tipo de trasplante
                                style: 'text_query',
                                text: (this.modalQuery.healthcare_center?'Si':'No')+'\n\n',
                            },
                            {
                                style: 'text_query',
                                text: this.modalQuery.healthcare_center ?
                                    this.getHealthcareCenter(this.modalQuery.healthcare_center)+'\n\n' :
                                    '',
                            },
                            {
                                style: 'text_query',
                                text: '\n\n',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Diagnóstico
                                style: 'subheader',
                                text: 'Diagnóstico',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Diagnóstico
                                style: 'text_query',
                                text: this.modalQuery.diagnosis+'\n\n',
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                // ¿Posee posible donante vivo?
                                style: "subheader",
                                text:
                                    this.modalQuery.transplant_id != 30 &&
                                    this.modalQuery.transplant_id != 32 &&
                                    this.modalQuery.transplant_id != 33 &&
                                    this.modalQuery.transplant_id != 34 &&
                                    this.modalQuery.transplant_id != 35
                                        ? "¿Posee posible donante vivo?"
                                        : "¿Posee Padres?"
                            },
                            {
                                // ¿Posee Padres?
                                style: "subheader",
                                text:
                                    this.modalQuery.transplant_id != 30 &&
                                    this.modalQuery.transplant_id != 32 &&
                                    this.modalQuery.transplant_id != 33 &&
                                    this.modalQuery.transplant_id != 34 &&
                                    this.modalQuery.transplant_id != 35
                                        ? "¿Posee Padres?"
                                        : ""
                            },
                            {
                                style: 'subheader',
                                text: '',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // ¿Posee posible donante vivo?
                                style: "text_query",
                                text:
                                    this.modalQuery.transplant_id != 30 &&
                                    this.modalQuery.transplant_id != 32 &&
                                    this.modalQuery.transplant_id != 33 &&
                                    this.modalQuery.transplant_id != 34 &&
                                    this.modalQuery.transplant_id != 35
                                    ? (this.modalQuery.living_donor &&
                                    this.modalQuery.living_donor != []
                                        ? "Si"
                                        : "No") + "\n\n"
                                    : (this.modalQuery.mon_names ||
                                    this.modalQuery.dad_names
                                        ? "Si"
                                        : "No") + "\n\n"
                            },
                            {
                                // ¿Posee Padres?
                                style: "text_query",
                                text:
                                    this.modalQuery.transplant_id != 30 &&
                                    this.modalQuery.transplant_id != 32 &&
                                    this.modalQuery.transplant_id != 33 &&
                                    this.modalQuery.transplant_id != 34 &&
                                    this.modalQuery.transplant_id != 35
                                    ? (this.modalQuery.mon_names ||
                                    this.modalQuery.dad_names
                                        ? "Si"
                                        : "No") + "\n\n"
                                    : ""
                            },
                            {
                                style: 'text_query',
                                text: '',
                            },
                        ],
                    }
                ],
                styles: {
                    header: {
                        fontSize: 13,
                        bold: true,
                        alignment: 'justify'
                    },
                    subheader: {
                        fontSize: 10,
                        bold: true,
                        alignment: 'justify'
                    },
                    text_query: {
                        fontSize: 8,
                        alignment: 'justify'
                    },
                    text_bold: {
                        fontSize: 8,
                        alignment: 'center',
                        bold: true
                    },
                    text_center: {
                        fontSize: 8,
                        alignment: 'center',
                    },
                },
            }
            // Datos del donante vivo
            if (this.modalQuery.living_donor && this.modalQuery.living_donor != []) {
                for (const [index, donor_array] of this.modalQuery.living_donor.entries()) {
                    docDefinition.content.push({ text: 'Datos del donante vivo ' + (index + 1) + '\n\n', style: 'header'})
                    docDefinition.content.push({
                        columns: [
                            {
                                // Nombres
                                style: 'subheader',
                                text: 'Nombres',
                            },
                            {
                                // Apellidos
                                style: 'subheader',
                                text: 'Apellidos',
                            },
                            {
                                // Fecha de nacimiento
                                style: 'subheader',
                                text: 'Fecha de nacimiento',
                            },
                        ],
                    })
                    docDefinition.content.push({
                        columns: [
                            {
                                text: donor_array.donor_names +'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: donor_array.donor_lastname + '\n\n',
                                style: 'text_query',
                            },
                            {
                                text: new Date(donor_array.donor_birthday).toLocaleDateString('en-GB', { timeZone: 'UTC' }) +'\n\n',
                                style: 'text_query',
                            }
                        ],
                    })
                    docDefinition.content.push({
                        columns: [
                            {
                                // Edad
                                style: 'subheader',
                                text: 'Edad',
                            },
                            {
                                // Lugar de nacimiento
                                style: 'subheader',
                                text: 'Lugar de nacimiento',
                            },
                            {
                                // Nacionalidad
                                style: 'subheader',
                                text: 'Nacionalidad',
                            },
                        ],
                    })
                    docDefinition.content.push({
                        columns: [
                            {
                                text: donor_array.donor_age +'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: donor_array.donor_place_of_birth + '\n\n',
                                style: 'text_query',
                            },
                            {
                                text: donor_array.donor_nationality == 'V' ? 'Venezolano(a)' : 'Extranjero(a)' + '\n\n',
                                style: 'text_query',
                            }
                        ],
                    })
                    docDefinition.content.push({
                        columns: [
                            {
                                // Género
                                style: 'subheader',
                                text: 'Género',
                            },
                            {
                                // Estado civil
                                style: 'subheader',
                                text: 'Estado civil',
                            },
                            {
                                // Ocupación
                                style: 'subheader',
                                text: 'Ocupación',
                            },
                        ],
                    })
                    docDefinition.content.push({
                        columns: [
                            {
                                text: this.showGender(donor_array.donor_gender) +'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.showMaritalStatus(donor_array.donor_marital_status) + '\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.occupations.filter(x => x.id == donor_array.donor_ocupation)[0].name + '\n\n',
                                style: 'text_query',
                            }
                        ],
                    })
                    docDefinition.content.push({
                        columns: [
                            {
                                // Nivel académico
                                style: 'subheader',
                                text: 'Nivel académico',
                            },
                            {
                                // Grupo sanguíneo
                                style: 'subheader',
                                text: 'Grupo sanguíneo',
                            },
                            {
                                // Factor RH
                                style: 'subheader',
                                text: 'Factor RH',
                            },
                        ],
                    })
                    docDefinition.content.push({
                        columns: [
                            {
                                text: donor_array.donor_academic_level +'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.blood_groups.filter(x => x.id == donor_array.donor_blood)[0].name + '\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.rhFactorOptions.filter(x =>x.id == donor_array.donor_rh_factor )[0].text + '\n\n',
                                style: 'text_query',
                            }
                        ],
                    })
                    docDefinition.content.push({
                        columns: [
                            {
                                // Número de cédula de identidad
                                style: 'subheader',
                                text: 'Número de cédula de identidad',
                            },
                            {
                                // Teléfono móvil
                                style: 'subheader',
                                text: 'Teléfono móvil',
                            },
                            {
                                // Teléfono local
                                style: 'subheader',
                                text: 'Teléfono local',
                            },
                        ],
                    })
                    docDefinition.content.push({
                        columns: [
                            {
                                text: donor_array.donor_dni +'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: donor_array.donor_cellphone + '\n\n',
                                style: 'text_query',
                            },
                            {
                                text: donor_array.donor_phone + '\n\n',
                                style: 'text_query',
                            }
                        ],
                    })
                    docDefinition.content.push({
                        columns: [
                            {
                                // Correo electrónico
                                style: 'subheader',
                                text: 'Correo electrónico',
                            },
                            {
                                // Parentesco
                                style: 'subheader',
                                text: 'Parentesco',
                            },
                            {
                                // Vacío
                                style: 'subheader',
                                text: '',
                            }
                        ],
                    })
                    docDefinition.content.push({
                        columns: [
                            {
                                text: donor_array.donor_email +'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.relationships.filter(x=> x.id == donor_array.donor_relationship)[0] ?
                                    this.relationships.filter(x=> x.id == donor_array.donor_relationship)[0].name + '\n\n' :
                                    '\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.getRelationship(index) === 'concubinage' ? donor_array.time + '\n\n' : '\n\n',
                                style: 'text_query',
                            }
                        ],
                    })
                }
            }
            // Datos del representante legal
            if (this.modalQuery.representative_names){
                docDefinition.content.push({ text: 'Datos del representante legal\n\n', style: 'header'})
                docDefinition.content.push({
                    columns: [
                        {
                            // Nombres
                            style: 'subheader',
                            text: 'Nombres',
                        },
                        {
                            // Apellidos
                            style: 'subheader',
                            text: 'Apellidos',
                        },
                        {
                            // Número de cédula de identidad
                            style: 'subheader',
                            text: 'Número de cédula de identidad',
                        },
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.representative_names ?? '' + '\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.representative_lastname ?? '' + '\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.representative_dni_number ?? '' + '\n\n',
                            style: 'text_query',
                        }
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            // Teléfono móvil
                            style: 'subheader',
                            text: '\nTeléfono móvil',
                        },
                        {
                            // Teléfono local
                            style: 'subheader',
                            text: '\nTeléfono local',
                        },
                        {
                            // Vacío
                            style: 'subheader',
                            text: '',
                        },
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.representative_cellphone ?? '' +'\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.representative_phone ?? '' +'\n\n',
                            style: 'text_query',
                        },
                        {
                            text: '\n\n',
                            style: 'text_query',
                        }
                    ],
                })
            }
            // Datos del Padre
            if (this.modalQuery.dad_names){
                docDefinition.content.push({ text: 'Datos del Padre\n\n', style: 'header'})
                docDefinition.content.push({
                    columns: [
                        {
                            // Nombres
                            style: 'subheader',
                            text: 'Nombres',
                        },
                        {
                            // Apellidos
                            style: 'subheader',
                            text: 'Apellidos',
                        },
                        {
                            // Número de cédula de identidad
                            style: 'subheader',
                            text: 'Número de cédula de identidad',
                        },
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.dad_names ?? '' + '\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.dad_lastname ?? '' + '\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.dad_dni_number ?? '' + '\n\n',
                            style: 'text_query',
                        }
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            // Teléfono móvil
                            style: 'subheader',
                            text: '\nTeléfono móvil',
                        },
                        {
                            // Teléfono local
                            style: 'subheader',
                            text: '\nTeléfono local',
                        },
                        {
                            // Vacío
                            style: 'subheader',
                            text: '',
                        },
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.dad_cellphone ?? '' +'\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.dad_phone ?? '' +'\n\n',
                            style: 'text_query',
                        },
                        {
                            text: '\n\n',
                            style: 'text_query',
                        }
                    ],
                })
            }
            // Datos de la Madre
            if (this.modalQuery.mon_names){
                docDefinition.content.push({ text: 'Datos de la Madre\n\n', style: 'header'})
                docDefinition.content.push({
                    columns: [
                        {
                            // Nombres
                            style: 'subheader',
                            text: 'Nombres',
                        },
                        {
                            // Apellidos
                            style: 'subheader',
                            text: 'Apellidos',
                        },
                        {
                            // Número de cédula de identidad
                            style: 'subheader',
                            text: 'Número de cédula de identidad',
                        },
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.mon_names ?? '' + '\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.mon_lastname ?? '' + '\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.mon_dni_number ?? '' + '\n\n',
                            style: 'text_query',
                        }
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            // Teléfono móvil
                            style: 'subheader',
                            text: '\nTeléfono móvil',
                        },
                        {
                            // Teléfono local
                            style: 'subheader',
                            text: '\nTeléfono local',
                        },
                        {
                            // Vacío
                            style: 'subheader',
                            text: '',
                        },
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.mon_cellphone ?? '' +'\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.mon_phone ?? '' +'\n\n',
                            style: 'text_query',
                        },
                        {
                            text: '\n\n',
                            style: 'text_query',
                        }
                    ],
                })
            }
            // Datos adicionales
            if (this.modalQuery.transplant_id == 28){
                docDefinition.content.push({ text: 'Datos adicionales\n\n', style: 'header'})
                docDefinition.content.push({
                    columns: [
                        {
                            // Tipo de diálisis
                            style: 'subheader',
                            text: 'Tipo de diálisis',
                        },
                        {
                            // Centro de diálisis
                            style: 'subheader',
                            text: 'Centro de diálisis',
                        },
                        {
                            // Fecha de inicio de diálisis
                            style: 'subheader',
                            text: 'Fecha de inicio de diálisis',
                        },
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            text: (this.modalQuery.dialisis_id == 1 ? 'Peritoneal' : 'Hemodiálisis') +'\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.healthcare_center_dialisis ?
                                this.getHealthcareCentersDialisis(this.modalQuery.healthcare_center_dialisis)+'\n\n' :
                                '\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.time_dialisis ?
                                new Date(this.modalQuery.time_dialisis).toLocaleDateString('en-GB', { timeZone: 'UTC' })+'\n\n' :
                                '\n\n',
                            style: 'text_query',
                        }
                    ],
                })
                // Datos del médico de la unidad de diálisis
                docDefinition.content.push({ text: 'Datos del médico de la unidad de diálisis\n\n', style: 'header'})
                docDefinition.content.push({
                    columns: [
                        {
                            // Nombres
                            style: 'subheader',
                            text: 'Nombres',
                        },
                        {
                            // Apellidos
                            style: 'subheader',
                            text: 'Apellidos',
                        },
                        {
                            // Correo electrónico
                            style: 'subheader',
                            text: 'Correo electrónico',
                        },
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.doctor_dialisis_names ?? '' +'\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.doctor_dialisis_lastname ?? '' +'\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.doctor_dialisis_mail ?? '' +'\n\n',
                            style: 'text_query',
                        }
                    ],
                })
                if (this.modalQuery.doctor_dialisis_phone) {
                    docDefinition.content.push({
                        columns: [
                            {
                                // Teléfono móvil
                                style: 'subheader',
                                text: "\nTeléfono móvil"
                            },
                            {
                                style: 'subheader',
                                text: '',
                            },
                            {
                                style: 'subheader',
                                text: '',
                            },
                        ],
                    })
                    docDefinition.content.push({
                        columns: [
                            {
                                text: this.modalQuery.doctor_dialisis_cellphone ?? '' +'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: '\n\n',
                                style: 'text_query',
                            },
                            {
                                text: '\n\n',
                                style: 'text_query',
                            }
                        ],
                    })
                }
                else {
                    docDefinition.content.push({
                        columns: [
                            {
                                // Teléfono móvil
                                style: 'subheader',
                                text: 'Teléfono móvil',
                            },
                            {
                                style: 'subheader',
                                text: 'Teléfono de la unidad de diálisis',
                            },
                            {
                                style: 'subheader',
                                text: '',
                            },
                        ],
                    })
                    docDefinition.content.push({
                        columns: [
                            {
                                text: this.modalQuery.doctor_dialisis_cellphone ?? '' +'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: this.modalQuery.doctor_dialisis_phone ?? '' +'\n\n',
                                style: 'text_query',
                            },
                            {
                                text: '\n\n',
                                style: 'text_query',
                            }
                        ],
                    })
                }
            }
            // Datos del médico tratante
            if (this.modalQuery.transplant_id != 28){
                docDefinition.content.push({ text: 'Datos del médico tratante\n\n', style: 'header'})
                docDefinition.content.push({
                    columns: [
                        {
                            // Nombres
                            style: 'subheader',
                            text: 'Nombres',
                        },
                        {
                            // Apellidos
                            style: 'subheader',
                            text: 'Apellidos',
                        },
                        {
                            // Correo electrónico
                            style: 'subheader',
                            text: 'Correo electrónico',
                        },
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.doctor_names+'\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.doctor_lastname+'\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.doctor_mail ?? '' + '\n\n',
                            style: 'text_query',
                        }
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            // Télefono móvil
                            style: 'subheader',
                            text: 'Télefono móvil',
                        },
                        {
                            // Teléfono local
                            style: 'subheader',
                            text: 'Teléfono local',
                        },
                        {
                            // Vacío
                            style: 'subheader',
                            text: '',
                        },
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.doctor_cellphone ?? '' + '\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.doctor_phone ?? '' + '\n\n',
                            style: 'text_query',
                        },
                        {
                            text: '\n\n',
                            style: 'text_query',
                        }
                    ],
                })
            }
            // Datos del médico especialista en trasplante
            if (this.modalQuery.healthcare_center) {
                docDefinition.content.push({ text: 'Datos del médico especialista en trasplante\n\n', style: 'header'})
                docDefinition.content.push({
                    columns: [
                        {
                            // Nombres
                            style: 'subheader',
                            text: 'Nombres',
                        },
                        {
                            // Apellidos
                            style: 'subheader',
                            text: 'Apellidos',
                        },
                        {
                            // Correo electrónico
                            style: 'subheader',
                            text: 'Correo electrónico',
                        },
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.doctor_transplant_names ?? '' +'\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.doctor_transplant_lastname ?? ''+'\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.doctor_transplant_mail ?? ''+'\n\n',
                            style: 'text_query',
                        }
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            // Teléfono móvil
                            style: 'subheader',
                            text: '\nTeléfono móvil',
                        },
                        {
                            // Teléfono local
                            style: 'subheader',
                            text: '\nTeléfono local',
                        },
                        {
                            style: 'subheader',
                            text: '',
                        },
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.doctor_transplant_cellphone ?? '' +'\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.doctor_transplant_phone ?? '' +'\n\n',
                            style: 'text_query',
                        },
                        {
                            text: '\n\n',
                            style: 'text_query',
                        }
                    ],
                })
            }
            // Datos del médico cirujano de trasplante
            if (this.modalQuery.healthcare_center) {
                docDefinition.content.push({ text: 'Datos del médico cirujano de trasplante\n\n', style: 'header'})
                docDefinition.content.push({
                    columns: [
                        {
                            // Nombres
                            style: 'subheader',
                            text: 'Nombres',
                        },
                        {
                            // Apellidos
                            style: 'subheader',
                            text: 'Apellidos',
                        },
                        {
                            // Correo electrónico
                            style: 'subheader',
                            text: 'Correo electrónico',
                        },
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.doctor_transplant_surgeon_names ?? '' +'\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.doctor_transplant_surgeon_lastname ?? ''+'\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.doctor_transplant_surgeon_mail ?? ''+'\n\n',
                            style: 'text_query',
                        }
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            // Teléfono móvil
                            style: 'subheader',
                            text: '\nTeléfono móvil',
                        },
                        {
                            // Teléfono local
                            style: 'subheader',
                            text: '\nTeléfono local',
                        },
                        {
                            style: 'subheader',
                            text: '',
                        },
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.doctor_transplant_surgeon_cellphone ?? '' +'\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.doctor_transplant_surgeon_phone ?? '' +'\n\n',
                            style: 'text_query',
                        },
                        {
                            text: '\n\n',
                            style: 'text_query',
                        }
                    ],
                })
            }
            // Cita médica asignada
            if (this.modalQuery.medical_citation && this.modalQuery.medical_citation.medical_citation_date){
                docDefinition.content.push({ text: 'Cita médica asignada \n\n', style: 'header'})
                docDefinition.content.push({
                    columns: [
                        {
                            // Fecha de la cita
                            style: 'subheader',
                            text: 'Fecha de la cita',
                        },
                        {
                            // Hora de la cita
                            style: 'subheader',
                            text: 'Hora de la cita',
                        },
                        {
                            // Centro de trasplante
                            style: 'subheader',
                            text: 'Centro de trasplante',
                        }
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.medical_citation.medical_citation_date ?
                                new Date(this.modalQuery.medical_citation.medical_citation_date).toLocaleDateString('en-GB', { timeZone: 'UTC' })+'\n\n' :
                                '\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.modalQuery.medical_citation.medical_citation_time ?? '' + '\n\n',
                            style: 'text_query',
                        },
                        {
                            text: this.getHealthcareCenter(this.modalQuery.medical_citation.medical_citation_healthcare_center) ?? '' + '\n\n',
                            style: 'text_query',
                        }
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            // ¿Asistió a la cita?
                            style: 'subheader',
                            text: '¿Asistió a la cita?',
                        },
                        {
                            // Vacío
                            style: 'subheader',
                            text: '',
                        },
                        {
                            // Vacío
                            style: 'subheader',
                            text: '',
                        }
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.medical_citation.medical_citation_assistance ?? '' + '\n\n',
                            style: 'text_query',
                        },
                        {
                            text: '\n\n',
                            style: 'text_query',
                        },
                        {
                            text: '\n\n',
                            style: 'text_query',
                        }
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            // Observación del solicitante
                            style: 'subheader',
                            text: '\nObservación del solicitante',
                        },
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.medical_citation.medical_citation_observation ?? '' + '\n\n',
                            style: 'text_query',
                        }
                    ],
                })
            }
            if (this.modalQuery.observation_request) {
                docDefinition.content.push({
                    columns: [
                        {
                            // Observaciones de la solicitud
                            text: '\nObservaciones de la solicitud',
                            style: 'subheader',
                        },
                    ],
                })
                docDefinition.content.push({
                    columns: [
                        {
                            text: this.modalQuery.observation_request ?? '' + '\n\n',
                            style: 'text_query',
                        }
                    ],
                })
            }
            // Footer del reporte
            docDefinition.content.push({
                image: base64images.footerPlanillasPDF(),
                width: 450,
                style: 'text_center',
                absolutePosition: {x: 12, y: 730}
            })
            pdfMake.createPdf(docDefinition).download('Solicitud de trasplante-Serial N°'+this.modalQuery.number);
        },
    },
    async created() {
        this.table_options.headings = {
            number: 'Serial N°',
            created_at: 'Fecha de registro',
            names: "Nombres y Apellidos",
            id_number: "Cédula",
            transplant_type: "Tipo de trasplante",
            id: "Acción"
        };
        this.table_options.sortable = [
            "number",
            "created_at",
            "names",
            "id_number",
            "transplant_type",
        ];
        this.table_options.filterable = [
            "number",
            "created_at",
            "names",
            "id_number",
            "transplant_type",
        ];
        this.table_options.columnsClasses = {
            id: "col-3 text-center"
        };
        this.table_options.orderBy = { column: "number" };
    },
    async mounted() {
        const vm = this;
        /**
         * Si el usuario autenticado intenta acceder al componente sin tener el
         * rol requerido este no carta los diferentes datos a consultar y
         * redirecciona a la pantalla inicial.
        */
        vm.user_role = vm.$parent.user.role;
        if ((vm.user_role == 'ADM') || (vm.user_role == 'SUP')) {
            vm.loadingState(true); // Inicio de spinner de carga.
            // Obtener los estados.
            await vm.getEstates();
            await vm.getKinship("global-data-type/kinship");
            await vm.getRecords(vm.url, "records");
            await vm.getRecords(vm.occupations_url, "occupations", true);
            await vm.getRecords(vm.url_transplantation_type, "transplant_type");
            await vm.getRecords(vm.url_healthcare_center, "healthcare_center");
            await vm.getRecords(vm.url_blood_type, "blood_type");
            await vm.getRecords(
                vm.url_healthcare_centers_dialisis,
                "healthcare_centers_dialisis"
            );
            await vm.getRecords("global-data-type/blood_group", "blood_groups");
            await vm.getRecords(
                "global-data-type/transplantation-type",
                "transplants"
            );
            vm.tmpRecords = vm.records;
            vm.loadingState(); // Finaliza spinner de carga.
        }
        else {
            location.href = '/';
        }
    }
};
</script>
