<template>
    <div id="non-donor-by-family-decision-list-component">
        <div class="card mb-4 shadow-sm">
            <h2 class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary">
                <i class="fa fa-th-list"></i> No Donantes Por Negativa Familiar
            </h2>
            <div class="card-body">
            <!-- Filtros de la tabla -->
            <div class="row">
                <div class="col-12 col-lg-3 mb-3">
                    <label for="" class="form-label">Cédula de identidad</label>
                    <input
                        id=""
                        class="form-control"
                        type="text"
                        minlength="7"
                        maxlength="9"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        placeholder="Cédula de identidad"
                        v-model="filterBy.dni"
                    />
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <label for="" class="form-label">Donación</label>
                    <v-select
                        v-model="filterSelectedOptions.donationOption"
                        :options="donationOptions"
                        :reduce="donationOption => donationOption.id"
                        label="text"
                        id="donationOption"
                        :selectable="option => option.id !== ''"
                        placeholder="-- Seleccione --"
                    >
                        <slot name="no-options">Sin registros.</slot>
                    </v-select>
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <label for="" class="form-label">Género</label>
                    <v-select
                        v-model="filterSelectedOptions.genderOption"
                        :options="genderOptions"
                        :reduce="genderOption => genderOption.id"
                        label="text"
                        id="genderOption"
                        :selectable="option => option.id !== ''"
                        placeholder="-- Seleccione --"
                    >
                        <slot name="no-options">Sin registros.</slot>
                    </v-select>
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <label for="" class="form-label">Edad (Mayor que)</label>
                    <input
                        id=""
                        class="form-control"
                        type="text"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        placeholder="Edad (Mayor que)"
                        v-model="filterBy.fromAge"
                    />
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <label for="" class="form-label">Edad (Menor que)</label>
                    <input id=""
                        class="form-control"
                        type="text"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        placeholder="Edad (Menor que)"
                        v-model="filterBy.toAge"
                    />
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <label for="" class="form-label">Grupo sanguíneo</label>
                    <v-select
                        v-model="filterSelectedOptions.blodGroupOption"
                        :options="blood_groups"
                        :reduce="blodGroupOption => blodGroupOption.id"
                        label="name"
                        id="blodGroupOption"
                        :selectable="option => option.id !== ''"
                        placeholder="-- Seleccione --"
                    >
                        <slot name="no-options">Sin registros.</slot>
                    </v-select>
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <label for="" class="form-label">Factor RH</label>
                    <v-select
                        v-model="filterSelectedOptions.rhFactorOption"
                        :options="rhFactorOptions"
                        :reduce="rhFactorOption => rhFactorOption.id"
                        label="text"
                        id="rhFactorOption"
                        :selectable="option => option.id !== ''"
                        placeholder="-- Seleccione --"
                    >
                        <slot name="no-options">Sin registros.</slot>
                    </v-select>
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <label for="estate_id" class="form-label">
                        Estado
                    </label>
                    <v-select
                        v-model="filterSelectedOptions.estate_id"
                        :options="estates"
                        :selectable="option => option.id !== ''"
                        :reduce="estate => estate.id"
                        label="text"
                        id="estate_id"
                        :tabindex="13"
                        placeholder="-- Seleccione --"
                    >
                        <slot name="no-options">Sin registros.</slot>
                    </v-select>
                </div>
                <div class="row pb-4">
                    <div class="col-12 col-lg-3">
                        <button class="btn btn-outline-primary-dark"
                            type="reset" @click="resetFilters()"
                        >
                            Limpiar
                        </button>
                        <button type="button" class="btn btn-outline-primary-dark"
                            aria-label="Search" @click="filterDonors"
                        >
                            Buscar
                        </button>
                    </div>
                </div>
            </div>
            <!-- Final de filtros de la tabla -->
                <v-client-table :columns="columns" :data="records" :options="table_options">
                    <div slot="profile" slot-scope="props">
                        {{ props.row.profile.names }} {{ props.row.profile.surnames }}
                    </div>
                    <div slot="id" slot-scope="props" class="main-flex-buttons">
                        <button class="btn btn-outline-primary-dark" type="button" title="Ver registro"
                            @click="showInfo(props.row.id)">
                            <i class="fa fa-eye"></i>
                        </button>
                    </div>
                </v-client-table>

                <!-- Modal -->
                <div id="show_non_donor_by_f_d" class="modal fade" tabindex="-1" role="dialog">
                    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4>Información detallada del No Donante por Negativa familiar</h4>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-2 mb-3">
                                        <label for="created_at" class="form-label">Fecha de registro</label>
                                        <input id="created_at" class="form-control" type="text" disabled
                                            :value="new Date(modalQuery.created_at).toLocaleDateString('en-GB')" />
                                    </div>
                                    <div class="col-12 col-lg-2 mb-3">
                                        <label for="number" class="form-label">
                                            Serial N°
                                        </label>
                                        <input id="number" class="form-control" type="text" disabled
                                            :value="modalQuery.number" />
                                    </div>
                                </div>

                                <!-- Datos del usuario que realiza el registro -->
                                <h3 class="h5 text-secondary text-uppercase">
                                    Datos del usuario que realizo la gestión del No Donante por negativa familiar
                                </h3>
                                <hr>
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="agent_names" class="form-label">Nombres</label>
                                        <input id="agent_names" class="form-control" type="text" disabled
                                            :value="modalQuery.agent_names" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="agent_surnames" class="form-label">Apellidos</label>
                                        <input id="agent_surnames" class="form-control" type="text" disabled
                                            :value="modalQuery.agent_surnames" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="agent_mail" class="form-label">Correo electrónico</label>
                                        <input id="agent_mail" class="form-control" type="text" disabled
                                            :value="modalQuery.agent_email" />
                                    </div>
                                </div>

                                <!-- Datos del No Donante  -->
                                <h3 class="h5 text-secondary text-uppercase">Datos del No Donante</h3>
                                <hr>
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="names" class="form-label">Nombres del difunto</label>
                                        <input id="names" class="form-control" type="text" disabled
                                            :value="profile.names" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="surnames" class="form-label">Apellidos del difunto</label>
                                        <input id="surnames" class="form-control" type="text" disabled
                                            :value="profile.surnames" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="dni" class="form-label">Cédula de identidad</label>
                                        <input id="dni" class="form-control" type="text" disabled
                                            :value="profile.dni" />
                                    </div>
                                </div>

                                <!-- Datos del registro  -->
                                <h3 class="h5 text-secondary text-uppercase">Datos del registro </h3>
                                <hr>
                                <div class="row pb-4">
                                    <div class="col-12 mb-3">
                                        <div class="row pb-2">
                                            <div class="col-12 col-lg-10">
                                                <label for="refusal_file" class="form-label">
                                                    Acta de negativa familiar
                                                </label>
                                            </div>
                                            <div
                                                class="
                                                    col-12 col-lg-2 d-flex
                                                    justify-content-center
                                                    justify-content-lg-end
                                                "
                                            >
                                                <button
                                                    class="btn btn-outline-primary-dark"
                                                    @click="
                                                        downloadFile(
                                                            modalQuery.url_family_refusal_file,
                                                            'Acta de negativa familiar'
                                                        )
                                                    "
                                                    :disabled="
                                                        !modalQuery.url_family_refusal_file ||
                                                        modalQuery.url_family_refusal_file ===
                                                        ''
                                                    "
                                                >
                                                    <i class="fa fa-download"></i>
                                                    Descargar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <label for="family_refusal_reason" class="form-label">
                                            Razón de la negativa familiar
                                        </label>
                                        <textarea rows="3"  class="form-control" disabled
                                            tabindex="1" :value="modalQuery.family_refusal_reason">
                                        </textarea>
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="refusal_family_member_full_name" class="form-label">Nombres y apellidos del familiar responsable</label>
                                        <input id="refusal_family_member_full_name" class="form-control" type="text" disabled
                                            :value="modalQuery.refusal_family_member_full_name" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="refusal_family_member_phone" class="form-label">Número de contacto del familiar responsable</label>
                                        <input id="refusal_family_member_phone" class="form-control" type="text" disabled
                                            :value="modalQuery.refusal_family_member_phone" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3" v-if="modalQuery.healthcare_center_where_died">
                                        <label for="healthcare_center_where_died" class="form-label">
                                            Centro generador de órganos y tejidos
                                        </label>
                                        <input id="healthcare_center_where_died" class="form-control" type="text" disabled
                                            :value="modalQuery.health_center.name" />
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer justify-content-center">
                                <button type="button" class="btn btn-outline-primary-dark"
                                    data-bs-dismiss="modal" aria-label="Close">
                                    Cerrar
                                </button>
                                <button type="button" class="btn btn-secondary-dark" @click="exportPDF()">
                                    <i class="fa fa-address-card"></i>
                                    Generar planilla
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal -->
            </div>
        </div>
    </div>
</template>

<script>
import base64images from '../../../base64images'
export default {
    name : 'NonDonorByFamilyDecisionList',
    data() {
        return {
            records: [],
            columns: ['number', 'profile', 'profile.dni', 'id' ],
            modalQuery: {},
            profile : {},
            filterBy: {
                dni: '',
                fromAge: '',
                toAge: ''
            },
            filterSelectedOptions: {
                donationOption: "",
                genderOption: "",
                blodGroupOption: "",
                rhFactorOption: "",
                estate_id:"",
            },
            rhFactorOptions: [
                { id: "P", text: "Positivo" },
                { id: "N", text: "Negativo" }
            ],
            donationOptions: [
                { id: "T", text: "Total" },
                { id: "P", text: "Parcial" }
            ],
            genderOptions: [
                { id: "M", text: "Masculino" },
                { id: "F", text: "Femenino" }
            ],
            estates:[],
            blood_groups: [],
            tmpRecords: [],
        }
    },
    async created() {
        this.table_options.headings = {
            'number':             'Serial N°',
            'profile':            'Nombres y apellidos',
            'profile.dni':        'Cédula',
            'id':                 'Acción'
        };
        this.table_options.sortable = ['number', 'profile', 'profile.dni'];
        this.table_options.filterable = ['number', 'profile', 'profile.dni'];
        this.table_options.columnsClasses = {
            'id': 'text-center'
        };
    },
    methods:{
        /**
         * Método para mostrar la informacion de un registro
         * dado su ID en un modal
         *
         * @method showInfo
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {int}      id    ID del registro
         */
        async showInfo(id) {
            const vm = this;
            
            let recordEdit = JSON.parse(JSON.stringify(vm.records.filter((rec) => {
                return rec.id === id;
            })[0]));

            vm.modalQuery = recordEdit;
            const { names, surnames, email } = vm.modalQuery.agent_info;
            vm.modalQuery.agent_names = names;
            vm.modalQuery.agent_surnames = surnames;
            vm.modalQuery.agent_email = email;
            vm.profile = recordEdit.profile;

            $('#show_non_donor_by_f_d').modal('show');
        },

        /**
         * Método que permite exportar la planilla del No Donante en PDF.
         *
         * @method exportPDF
         *
         * @author Ing. Argenis Osorio <aosorio@cenditel.gob.ve>
         * @author Daniel Contreras <dcontreras@cenditel.gob.ve>
        */
        exportPDF() {
            var docDefinition = {
                info: {
                    title: 'Planilla N° '+this.modalQuery.number,
                },
                pageOrientation: 'portrait',
                //Cintillo
                header: {
                    image: base64images.cintilloFundavene(),
                    width: 530,
                    style:'text_center'
                },
                content: [
                    // Cabecera del reporte
                    { text: '\n\nInformación del No Donante por negativa familiar: ' + this.modalQuery.profile.names + ' ' + this.modalQuery.profile.surnames + '\n\n',
                        style: 'header'
                    },
                    {
                        columns: [
                            {
                                // Fecha de registro
                                style: 'subheader',
                                text: 'Fecha de registro',
                            },
                            {
                                // Serial N°
                                style: 'subheader',
                                text: 'Serial N°',
                            },
                            {
                                // Vacío
                                text: '',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Fecha de registro
                                text: new Date(this.modalQuery.created_at).toLocaleDateString('en-GB')+'\n\n', style: 'text_query',
                            },
                            {
                                // Serial N°
                                text: this.modalQuery.number+'\n\n', style: 'text_query',
                            },
                            {
                                // Vacío
                                text: '',
                            },
                        ]
                    },
                    // Usuario que realizó la gestión del No Donante por negativa familiar
                    { text: 'Usuario que realizó la gestión del No Donante por negativa familiar\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Nombres
                                style: 'subheader',
                                text: 'Nombres',
                            },
                            {
                                // Apellidos
                                style: 'subheader',
                                text: 'Apellidos',
                            },
                            {
                                // Correo electrónico
                                style: 'subheader',
                                text: 'Correo electrónico',
                            }
                        ],
                    },
                    {
                        columns: [
                            {
                                // Nombres
                                text: this.$parent.user.profile.names+'\n\n', style: 'text_query',
                            },
                            {
                                // Apellidos
                                text: this.$parent.user.profile.surnames+'\n\n', style: 'text_query',
                            },
                            {
                                // Correo electrónico
                                text: this.$parent.user.email+'\n\n', style: 'text_query',
                            }
                        ]
                    },
                    // Datos personales
                    { text: 'Datos personales\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Nombres del difunto
                                style: 'subheader',
                                text: 'Nombres del difunto',
                            },
                            {
                                // Apellidos del difunto
                                style: 'subheader',
                                text: 'Apellidos del difunto',
                            },
                            {
                                // Cédula de identidad
                                style: 'subheader',
                                text: 'Cédula de identidad',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Nombres del difunto
                                text: this.profile.names+'\n\n', style: 'text_query',
                            },
                            {
                                // Apellidos del difunto
                                text: this.profile.surnames+'\n\n', style: 'text_query',
                            },
                            {
                                // Cédula de identidad
                                text: this.profile.dni+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    //  Datos del registro
                    { text: 'Datos del registro\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Razón de la negativa familiar
                                width:'100%',
                                style: 'subheader',
                                text: 'Razón de la negativa familiar',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Razón de la negativa familiar
                                width:'100%',
                                text: this.modalQuery.family_refusal_reason+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                // Nombres y apellidos del familiar responsable
                                style: 'subheader',
                                text: 'Nombres y apellidos del\n familiar responsable',
                            },
                            {
                                // Número de contacto del familiar responsable
                                style: 'subheader',
                                text: 'Número de contacto del\n familiar responsable',
                            },
                            {
                                // Centro generador de órganos y téjidos
                                style: 'subheader',
                                text: 'Centro generador de órganos y téjidos',
                            }
                        ],
                    },
                    {
                        columns: [
                            {
                                // Nombres y apellidos del familiar responsable
                                text: this.modalQuery.refusal_family_member_full_name+'\n\n', style: 'text_query',
                            },
                            {
                                // Número de contacto del familiar responsable
                                text: this.modalQuery.refusal_family_member_phone+'\n\n', style: 'text_query',
                            },
                            {
                                // Centro generador de órganos y téjidos
                                text: this.modalQuery.health_center.name+'\n\n',
                                style: 'text_query',
                            }
                        ]
                    },
                    // Footer del reporte
                    {
                        image: base64images.footerPlanillasPDF(),
                        width: 450,
                        style: 'text_center',
                        absolutePosition: {x: 12, y: 730}
                    },
                ],
                styles: {
                    header: {
                        fontSize: 13,
                        bold: true,
                        alignment: 'justify'
                    },
                    subheader: {
                        fontSize: 10,
                        bold: true,
                        alignment: 'justify'
                    },
                    text_query: {
                        fontSize: 8,
                        alignment: 'justify'
                    },
                    text_bold: {
                        fontSize: 8,
                        alignment: 'center',
                        bold: true
                    },
                    text_center: {
                        fontSize: 8,
                        alignment: 'center',
                    },
                }
            }
            pdfMake.createPdf(docDefinition).download('Planilla No Donante Negativa Familiar-Serial N°'+this.modalQuery.number+'-CI-'+this.profile.dni);
        },

        /**
         * Método para Descargar Archivos
         *
         * @method downloadFile
         *
         */
        async downloadFile(file, name) {
            const vm = this;
            const url = file;
            vm.errors = [];
            const formData = new FormData();
            formData.append("url", url);
            try {
                let response = await axios({
                    url: `${window.url}` + "/family-refusal-donors-download",
                    method: "post",
                    data: formData,
                    responseType: "arraybuffer"
                });
                let fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                let fileLink = document.createElement("a");
                fileLink.href = fileURL;
                let ext =
                    file.substring(file.lastIndexOf(".") + 1, file.length) ||
                    file;
                fileLink.setAttribute("download", name + "." + ext);
                document.body.appendChild(fileLink);
                fileLink.click();
                URL.revokeObjectURL(fileLink.href);
            } catch (error) {
                let { errors } = JSON.parse(
                    String.fromCharCode.apply(
                        null,
                        new Uint8Array(error.response.data)
                    )
                );
                vm.errors = [];
                for (var index in errors) {
                    if (errors[index]) {
                        vm.errors.push(errors[index][0]);
                    }
                }
            }
        },

        /**
         * Método para reestablecer valores iniciales del formulario de filtros.
         *
         * @method resetFilters
         *
         * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <kingangelo_123@gmail.com>
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        resetFilters() {
            const vm = this;
            vm.filterBy = {
                dni: '',
                fromAge: '',
                toAge: ''
            };
            vm.filterSelectedOptions.donationOption = '';
            vm.filterSelectedOptions.genderOption = '';
            vm.filterSelectedOptions.blodGroupOption = '';
            vm.filterSelectedOptions.rhFactorOption = '';
            vm.filterSelectedOptions.estate_id = '';
            vm.records = vm.tmpRecords;
        },
        /**
         * Método que permite filtrar los datos de la tabla Donantes voluntarios.
         *
         * @method filterDonors
         *
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        filterDonors() {
            const vm = this
            vm.records = vm.tmpRecords.filter((rec) => {
                return (vm.filterBy.dni) ? (rec.profile.dni === vm.filterBy.dni) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.donationOption) ? (rec.donorSchema === vm.filterSelectedOptions.donationOption) : true;
            }).filter((rec) => {
                return (vm.filterBy.fromAge) ? (rec.profile.age >= vm.filterBy.fromAge) : true;
            }).filter((rec) => {
                return (vm.filterBy.toAge) ? (rec.profile.age <= vm.filterBy.toAge) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.genderOption) ? (rec.profile.gender === vm.filterSelectedOptions.genderOption) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.blodGroupOption) ? (parseInt(rec.blood_group) === parseInt(vm.filterSelectedOptions.blodGroupOption)) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.rhFactorOption) ? (rec.rh_factor === vm.filterSelectedOptions.rhFactorOption) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.estate_id && vm.filterSelectedOptions.estate_id!="") ? (parseInt(rec.profile.estate_id) === parseInt(vm.filterSelectedOptions.estate_id)) : true;
            })
        },
    },
    async mounted() {
        const vm = this;
        /**
         * Si el usuario autenticado intenta acceder al componente sin tener el
         * rol requerido este no carta los diferentes datos a consultar y
         * redirecciona a la pantalla inicial.
        */
        vm.user_role = vm.$parent.user.role;
        if ((vm.user_role == 'ADM') || (vm.user_role == 'SUP') || (vm.user_role == 'CHO')) {
            vm.loadingState(true); // Inicio de spinner de carga.
            // Obtener los estados.
            await vm.getEstates()
            await vm.getRecords('family-refusal-donors', 'records');
            vm.tmpRecords = vm.records;
            // Obtener el listado de los grupos sanguíneos.
            await vm.getRecords('global-data-type/blood_group', 'blood_groups')
            vm.loadingState(); // Finaliza spinner de carga.
        }
        else {
            location.href = '/';
        }
    }
}
</script>

<style>

</style>
