<template>
    <div id="not-apt-donor-component">
        <go-back title="Regresar"/>
        <div class="card mb-4 shadow-sm">
            <h2 class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary">
                <i class="fa fa-th-list"></i> Gestión para donante no apto
            </h2>
            <div class="card-body">
                <form-errors :errors="errors"></form-errors>
                <div class="row pb-4">
                    <div class="col-12 col-lg-2 mb-3">
                        <label for="date" class="form-label">
                            Fecha de registro
                        </label>
                        <input id="date" class="form-control" name="date"
                            type="text" readonly v-model="record.date"/>
                    </div>
                    <div class="col-12 col-lg-2 mb-3" v-if="record.number">
                        <label for="number" class="form-label">Serial de planilla</label>
                        <input id="number" class="form-control" name="number"
                            type="text" placeholder=" Serial de planilla"
                            readonly v-model="record.number"
                        />
                    </div>
                </div>
                <!-- Datos del usuario que realiza el registro -->
                <h3 class="h5 text-secondary text-uppercase">
                    Usuario que realiza la gestión del donante no apto
                </h3>
                <hr>
                <div class="pb-4">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="mb-3">
                                <label for="agent_name" class="form-label">
                                    Nombres
                                </label>
                                <input id="agent_name" type="text"
                                    class="form-control" disabled
                                    v-model="record.agent_names">
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="mb-3">
                                <label for="agent_surnames" class="form-label">
                                    Apellidos
                                </label>
                                <input id="agent_surnames" type="text"
                                    class="form-control" disabled
                                    v-model="record.agent_surnames">
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="mb-3">
                                <label for="agent_mail" class="form-label">
                                    Correo electrónico
                                </label>
                                <input id="agent_mail" type="text"
                                    class="form-control" disabled
                                    v-model="record.agent_mail">
                            </div>
                        </div>
                    </div>
                </div>
                <!--/ Datos del usuario que realiza el registro -->
                <!-- Datos del donante -->
                <h3 class="h5 text-secondary text-uppercase">
                    Datos del Donante no apto
                </h3>
                <hr>
                <div class="row pb-4">
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="deceased_names" class="form-label">
                            Nombres
                        </label>
                        <input id="deceased_names" class="form-control"
                            name="deceased_names" type="text"
                            tabindex="1" disabled
                            v-model="record.names"/>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="deceased_surnames" class="form-label">
                            Apellidos
                        </label>
                        <input id="deceased_surnames" class="form-control"
                            name="deceased_surnames" type="text" tabindex="2"
                            disabled v-model="record.surnames"/>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <label for="dni" class="form-label">
                            Cédula de identidad
                        </label>
                        <input id="dni" class="form-control" name="dni"
                            type="text" tabindex="5" disabled
                            v-model="record.dni"/>
                    </div>
                </div>
                <h3 class="h5 text-secondary text-uppercase">
                    Datos del registro
                </h3>
                <hr>
                <div class="pb-4">
                    <div class="col-12 my-3">
                        <label for="not_apt_donor_reason" class="form-label">
                            Razón por la cual el Donante no es apto <span class="text-danger">*</span>
                        </label>
                        <textarea rows="3"  class="form-control"
                            tabindex="1" v-model="record.not_apt_donor_reason">
                        </textarea>
                    </div>
                </div>
            </div>
            <div class="card-footer text-center">
                <button type="reset" class="btn btn-outline-primary-dark" @click="reset">
                    Limpiar
                </button>
                <button type="button" class="btn btn-primary" @click="setRecord('not-apt-donors')">
                    <i class="fa fa-save"></i>
                    Guardar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            errors: [],
            organs_and_tissues:[],
            record: {
                names: '',
                surnames: '',
                dni: '',
                agent_user_id: '',
                not_apt_donor_reason: '',
                status: '',
            }
        }
    },
    methods:{
        /**
         * Ejecuta la acción para guardar o actualizar datos
         *
         * @method setRecord
         *
         * @author José Briceño <josejorgebriceno9@gmail.com>
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         *
         * @param  {String}  url URL de la petición a realizar
         */
        async setRecord(url) {
            const vm = this;
            vm.loadingState(true); // Inicio de spinner de carga.

            let formData = new FormData();
            vm.record.donor_id = vm.$route.params.id;
            let data = Object.entries(vm.record);

            data.forEach(element => {
                formData.append(`${element[0]}`, `${element[1]}`);
            });

            await axios({
                headers: {'Content-Type': 'multipart/form-data'},
                method: "post",
                url: `${window.url}/${url}`,
                data: formData,
            }).then((response) => {
                vm.errors = [];
                vm.loadingState(); // Finaliza spinner de carga.
                vm.showMessage("success");
                // Redireccionar al componente anterior luego del guardado.
                setTimeout(() => vm.$router.go(-1), 3000);
            }).catch(error => {
                    vm.loadingState(); // Finaliza spinner de carga.
                    vm.setErrors(error)
                }
            );
        },

        /**
         * Método para reestablecer valores iniciales del formulario.
         *
         * @method reset
         *
         * @author Argenis Osorio <aosorio@cenditel.gob.ve>
         */
        reset() {
            const vm = this;
            vm.record.not_apt_donor_reason = '';
        },

        /**
         * Método que obtiene la fecha del registro y la agrega sobre el campo correspondiente
         *
         * @author  Luis Ramírez <lgramirez@cenditel.gob.ve>
         *
         */
        getDate() {
            const vm = this;
            const newDate = new Date();
            vm.record.date = newDate.toLocaleDateString('en-GB');
        },
    },
    async mounted() {
        const vm = this;
        /**
         * Si el usuario autenticado intenta acceder al componente sin tener el
         * rol requerido este no carta los diferentes datos a consultar y
         * redirecciona a la pantalla inicial.
        */
        vm.user_role = vm.$parent.user.role;
        if ((vm.user_role == 'ADM') || (vm.user_role == 'CHO')) {
            vm.loadingState(true); // Inicio de spinner de carga.
            vm.getDate();
            /** Datos del usuario gestor de fundavene */
            vm.record.agent_user_id = vm.$parent.user.profile.user_id;
            vm.record.agent_names = vm.$parent.user.profile.names;
            vm.record.agent_surnames = vm.$parent.user.profile.surnames;
            vm.record.agent_mail = vm.$parent.user.email;
            axios.get(`${window.url}` + '/profiles/' + vm.$parent.user.id)
            .then((response) => {
                let profile = response.data.profile;
            }).catch(error => {
                console.log(error.response)
            });
            /** Datos del donante gestionado */
            if (vm.$route.params.id) {
                axios.get(`${window.url}` + '/manage-will-donor/' + vm.$route.params.id)
                .then((response) => {
                    let profile = response.data.result.profile;
                    vm.record.names = profile.names;
                    vm.record.surnames = profile.surnames;
                    vm.record.dni = profile.dni;
                    vm.record.number = response.data.result.number;
                    vm.record.not_apt_donor_reason = (response.data.result.not_apt_donor_reason) ? response.data.result.not_apt_donor_reason : '';
                    // Estatus 4 = Donante no apto.
                    vm.record.status = 4;
                }).catch(error => {
                    console.log(error.response)
                });
            }
            vm.loadingState(); // Finaliza spinner de carga.
        }
        else {
            location.href = '/';
        }
    }
}
</script>

<style>
</style>
