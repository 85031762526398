<template>
    <div id="non-donor-list-component">
        <div class="card mb-4 shadow-sm">
            <h2 class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary">
                <i class="fa fa-th-list"></i> No Donantes
            </h2>
            <div class="card-body">
            <!-- Filtros de la tabla -->
            <div class="row">
                <div class="col-12 col-lg-3 mb-3">
                    <label for="" class="form-label">Cédula de identidad</label>
                    <input
                        id=""
                        class="form-control"
                        type="text"
                        minlength="7"
                        maxlength="9"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        placeholder="Cédula de identidad"
                        v-model="filterBy.dni"
                    />
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <label for="" class="form-label">Donación</label>
                    <v-select
                        v-model="filterSelectedOptions.donationOption"
                        :options="donationOptions"
                        :reduce="donationOption => donationOption.id"
                        label="text"
                        id="donationOption"
                        :selectable="option => option.id !== ''"
                        placeholder="-- Seleccione --"
                    >
                        <slot name="no-options">Sin registros.</slot>
                    </v-select>
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <label for="" class="form-label">Género</label>
                    <v-select
                        v-model="filterSelectedOptions.genderOption"
                        :options="genderOptions"
                        :reduce="genderOption => genderOption.id"
                        label="text"
                        id="genderOption"
                        :selectable="option => option.id !== ''"
                        placeholder="-- Seleccione --"
                    >
                        <slot name="no-options">Sin registros.</slot>
                    </v-select>
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <label for="" class="form-label">Edad (Mayor que)</label>
                    <input
                        id=""
                        class="form-control"
                        type="text"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        placeholder="Edad (Mayor que)"
                        v-model="filterBy.fromAge"
                    />
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <label for="" class="form-label">Edad (Menor que)</label>
                    <input id=""
                        class="form-control"
                        type="text"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        placeholder="Edad (Menor que)"
                        v-model="filterBy.toAge"
                    />
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <label for="" class="form-label">Grupo sanguíneo</label>
                    <v-select
                        v-model="filterSelectedOptions.blodGroupOption"
                        :options="blood_groups"
                        :reduce="blodGroupOption => blodGroupOption.id"
                        label="name"
                        id="blodGroupOption"
                        :selectable="option => option.id !== ''"
                        placeholder="-- Seleccione --"
                    >
                        <slot name="no-options">Sin registros.</slot>
                    </v-select>
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <label for="" class="form-label">Factor RH</label>
                    <v-select
                        v-model="filterSelectedOptions.rhFactorOption"
                        :options="rhFactorOptions"
                        :reduce="rhFactorOption => rhFactorOption.id"
                        label="text"
                        id="rhFactorOption"
                        :selectable="option => option.id !== ''"
                        placeholder="-- Seleccione --"
                    >
                        <slot name="no-options">Sin registros.</slot>
                    </v-select>
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <label for="estate_id" class="form-label">
                        Estado
                    </label>
                    <v-select
                        v-model="filterSelectedOptions.estate_id"
                        :options="estates"
                        :selectable="option => option.id !== ''"
                        :reduce="estate => estate.id"
                        label="text"
                        id="estate_id"
                        :tabindex="13"
                        placeholder="-- Seleccione --"
                    >
                        <slot name="no-options">Sin registros.</slot>
                    </v-select>
                </div>
                <div class="row pb-4">
                    <div class="col-12 col-lg-3">
                        <button class="btn btn-outline-primary-dark"
                            type="reset" @click="resetFilters()"
                        >
                            Limpiar
                        </button>
                        <button type="button" class="btn btn-outline-primary-dark"
                            aria-label="Search" @click="filterDonors"
                        >
                            Buscar
                        </button>
                    </div>
                </div>
            </div>
            <!-- Final de filtros de la tabla -->
                <v-client-table :columns="columns" :data="records" :options="table_options">
                    <div slot="profile" slot-scope="props">
                        {{props.row.profile.names}} {{props.row.profile.surnames}}
                    </div>
                    <div slot="id" slot-scope="props" class="main-flex-buttons">
                        <button class="btn btn-outline-primary-dark" type="button" title="Ver registro"
                            @click="showInfo(props.row.id)">
                            <i class="fa fa-eye"></i>
                        </button>
                    </div>
                </v-client-table>

                <!-- Modal -->
                <div id="show_no_donor" class="modal fade" tabindex="-1" role="dialog">
                    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4>Información detallada del No Donante</h4>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-2 mb-3">
                                        <label for="created_at" class="form-label">Fecha de registro</label>
                                        <input id="created_at" class="form-control" type="text" disabled
                                            :value="new Date(modalQuery.created_at).toLocaleDateString('en-GB')" />
                                    </div>
                                    <div class="col-12 col-lg-2 mb-3">
                                        <label for="number" class="form-label">
                                            Serial N°
                                        </label>
                                        <input id="number" class="form-control" type="text" disabled
                                            :value="modalQuery.number" />
                                    </div>
                                </div>

                                <!-- Datos Personales -->
                                <h3 class="h5 text-secondary text-uppercase">Datos personales</h3>
                                <hr>
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="names" class="form-label">Nombres</label>
                                        <input id="names" class="form-control" type="text" disabled
                                            :value="profile.names" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="surnames" class="form-label">Apellidos</label>
                                        <input id="surnames" class="form-control" type="text" disabled
                                            :value="profile.surnames" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="dni" class="form-label">Cédula de identidad</label>
                                        <input id="dni" class="form-control" type="text" disabled
                                            :value="profile.dni" />
                                    </div>
                                    <div class="col-12 col-lg-2 mb-3">
                                        <label for="nationality" class="form-label">Nacionalidad</label>
                                        <input id="nationality" class="form-control" type="text" disabled
                                            :value="showNationality(profile.nationality)" />
                                    </div>
                                    <div class="col-12 col-lg-2 mb-3">
                                        <label for="gender" class="form-label">Género</label>
                                        <input id="gender" class="form-control" type="text" disabled
                                            :value="showGender(profile.gender)" />
                                    </div>
                                    <div class="col-12 col-lg-3 mb-3">
                                        <label for="ocupation" class="form-label">Ocupación</label>
                                        <input id="ocupation" class="form-control" type="text" disabled
                                            :value="profile.ocupation && profile.ocupation.name?profile.ocupation.name:''" />
                                    </div>
                                    <div class="col-12 col-lg-2 mb-3">
                                        <label for="marital_status" class="form-label">Estado civil</label>
                                        <input id="marital_status" class="form-control" type="text" disabled
                                            :value="showMaritalStatus(profile.marital_status)" />
                                    </div>
                                    <div class="col-12 col-lg-3 mb-3">
                                        <label for="academic_level" class="form-label"> Nivel académico</label>
                                        <input id="academic_level" class="form-control" type="text" disabled
                                            :value="profile.academic_level" />
                                    </div>
                                </div>

                                <!-- Edad -->
                                <h3 class="h5 text-secondary text-uppercase">Edad</h3>
                                <hr>
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="birthdate" class="form-label">Fecha de nacimiento</label>
                                        <input id="birthdate" class="form-control" type="text" disabled
                                            :value="new Date(profile.birthdate).toLocaleDateString('en-GB', {timeZone: 'UTC'})" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="birthplace" class="form-label">Lugar de nacimiento</label>
                                        <input id="birthplace" class="form-control" type="text" disabled
                                            :value="profile.birthplace" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="age" class="form-label">Edad</label>
                                        <input id="age" class="form-control" type="text" disabled
                                            :value="profile.age" />
                                    </div>
                                </div>

                                <!-- Dirección -->
                                <h3 class="h5 text-secondary text-uppercase">Dirección</h3>
                                <hr>
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="estate" class="form-label">Estado</label>
                                        <input id="estate" class="form-control" type="text" disabled
                                            :value="location.estate" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="municipality" class="form-label">Municipio</label>
                                        <input id="municipality" class="form-control" type="text" disabled
                                            :value="location.municipality" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="parish" class="form-label">Parroquia</label>
                                        <input id="parish" class="form-control" type="text" disabled
                                            :value="location.parish" />
                                    </div>
                                </div>

                                <!-- Contacto -->
                                <h3 class="h5 text-secondary text-uppercase">Contacto</h3>
                                <hr>
                                <div class="row pb-4">
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="phone" class="form-label">Teléfono</label>
                                        <input id="phone" class="form-control" type="text" disabled
                                            :value="profile.phone" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="movil" class="form-label">Celular</label>
                                        <input id="movil" class="form-control" type="text" disabled
                                            :value="profile.movil" />
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                        <label for="email" class="form-label">Correo electrónico</label>
                                        <input id="email" class="form-control" type="text" disabled
                                            :value="user.email" />
                                    </div>
                                </div>
                                <!-- Razones por las cuales no quiere ser donante -->
                                <h3 class="h5 text-secondary text-uppercase">Razones por las cuales no quiere ser donante</h3>
                                <hr>
                                <div class="row pb-4">
                                    <div class="col-12">
                                        <div class=" mb-3">
                                            <label class="form-label">Especificación de las razones</label>
                                            <textarea rows="4" class="form-control" disabled
                                                :value="modalQuery.not_donor_reason">
                                            </textarea>
                                        </div>
                                    </div>
                                </div>

                                <h3 v-if="families.length" class="h5 text-secondary text-uppercase">
                                    Familiares con quién se comparte la decisión
                                </h3>
                                <div class="row pb-4" v-for="family in families" v-if="families != null">
                                    <div class="col-12 col-lg-6 mb-3">
                                        <label for="family_names" class="form-label">Nombres</label>
                                        <input id="family_names" class="form-control" type="text" disabled
                                            :value="family.names" />
                                    </div>
                                    <div class="col-12 col-lg-6 mb-3">
                                        <label for="family_surnames" class="form-label">Apellidos</label>
                                        <input id="family_surnames" class="form-control" type="text" disabled
                                            :value="family.surnames" />
                                    </div>
                                    <div class="col-12 col-lg-6 mb-3">
                                        <label for="family_phone" class="form-label">Teléfono</label>
                                        <input id="pfamily_hone" class="form-control" type="text" disabled
                                            :value="family.phone" />
                                    </div>
                                    <div class="col-12 col-lg-6 mb-3">
                                        <label for="family_relationship" class="form-label">Parentesco</label>
                                        <input id="family_relationship" class="form-control" type="text" disabled
                                            :value="getKinship(family.relationship)" />
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer justify-content-center">
                                <button type="button" class="btn btn-outline-primary-dark"
                                    data-bs-dismiss="modal" aria-label="Close">
                                    Cerrar
                                </button>
                                <button type="button" class="btn btn-secondary-dark" @click="exportPDF()">
                                    <i class="fa fa-address-card"></i>
                                    Generar planilla
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal -->
            </div>
        </div>
    </div>
</template>

<script>
import base64images from '../../../base64images'
export default {
    name : 'NonDonorList',
    data() {
        return {
            records: [],
            columns: ['number', 'profile', 'profile.dni', 'id' ],
            url: 'manage-will-donor-type/ND',
            modalQuery: {},
            profile : {},
            user: {},
            families: [],
            kinship: [],
            location:{
                parish:'',
                municipality:'',
                estate:'',
            },
            filterBy: {
                dni: '',
                fromAge: '',
                toAge: ''
            },
            filterSelectedOptions: {
                donationOption: "",
                genderOption: "",
                blodGroupOption: "",
                rhFactorOption: "",
                estate_id:"",
            },
            rhFactorOptions: [
                { id: "P", text: "Positivo" },
                { id: "N", text: "Negativo" }
            ],
            donationOptions: [
                { id: "T", text: "Total" },
                { id: "P", text: "Parcial" }
            ],
            genderOptions: [
                { id: "M", text: "Masculino" },
                { id: "F", text: "Femenino" }
            ],
            estates:[],
            blood_groups: [],
            tmpRecords: [],
        }
    },
    async created() {
        this.table_options.headings = {
            'number':             'Serial N°',
            'profile':            'Nombres y apellidos',
            'profile.dni':        'Cédula',
            'id':                 'Acción'
        };
        this.table_options.sortable = ['number', 'profile', 'profile.dni'];
        this.table_options.filterable = ['number', 'profile', 'profile.dni'];
        this.table_options.columnsClasses = {
            'id': 'text-center'
        };
    },

    methods:{
        /**
         * Método para mostrar la informacion de un registro
         * dado su ID en un modal
         *
         * @method showInfo
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {int}      id    ID del registro
         */
        async showInfo(id) {
            const vm = this;
            let recordEdit = JSON.parse(JSON.stringify(vm.records.filter((rec) => {
                return rec.id === id;
            })[0]));
            vm.modalQuery = recordEdit;
            vm.profile = recordEdit.profile;
            vm.families = JSON.parse(recordEdit.families);
            vm.getUser('users', vm.profile.user_id);
            vm.getLocation('parish', vm.profile.parish_id);
            $('#show_no_donor').modal('show');
        },

        /**
         * Obtener a información de un usuario dado su Id
         *
         * @method getUser
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {int}      id    ID del registro usuario
         */
        async getUser(url, id){
            const vm = this;
            const query = `${window.url}/${url}/${id}`;
            axios.get(query).then(response => {
                vm.user = response.data.result;
            }).catch(error => {
                console.error(error)
            });
        },

        /**
        * Método para obtener la representación textual de
        * la dirección (estado, municipio, parroquia)
        * dado el id de una parroquia.
        *
        * @method getLocation
        *
        * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
        * @param     {int}      id    ID del de la parroquia
        */
        async getLocation(url, id){
            const vm = this;
            const query = `${window.url}/${url}/${id}`;

            axios.get(query).then(response => {
                vm.location.parish = response.data.parish.name;
                vm.location.municipality = response.data.parish.municipality.name;
                vm.location.estate = response.data.parish.municipality.estate.name;
            }).catch(error => {
                console.error(error)
            });
        },

        /**
         * Obtener el nombre de la relación dado un Id
         *
         * @method getKinship
         *
         * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
         * @param     {int}      id    ID de la relación
         */
        getKinship(id){
            const vm= this;
            let text = '';

            vm.kinship.forEach(element => {
                if (element.id  == id) {
                    text = element.name;
                }
            });
            return text;
        },

        /**
        * Método para mostrar la nacionalidad
        *
        * @method showNationality
        *
        * @author Francisco J. P. Ruiz  <javierrupe19@gmail.com>
        */
        showNationality(nationality){
            let nationalityType= ''
            if (nationality === 'V') {
                nationalityType = 'Venezolano(a)'
            } else if (nationality === 'E') {
                nationalityType = 'Extranjero(a)'
            }
            return nationalityType
        },

        /**
        * Método para mostrar el género
        *
        * @method showGender
        *
        * @author Francisco J. P. Ruiz <javierrupe19@gmail.com>
        */
        showGender(gender){
            let genderType= ''
            if (gender === 'F') {
                genderType = 'Femenino'
            } else if (gender === 'M') {
                genderType = 'Masculino'
            }
            return genderType
        },

        /**
        * Método para mostrar el estado civil
        *
        * @method showGender
        *
        * @author Francisco J. P. Ruiz  <javierrupe19@gmail.com>
        */
        showMaritalStatus(maritalStatus){
            let maritalStatusType= ''
            if (maritalStatus === 'S') {
                maritalStatusType = 'Soltero(a)'
            } else if (maritalStatus === 'C') {
                maritalStatusType = 'Casado(a)'
            } else if (maritalStatus === 'D') {
                maritalStatusType = 'Divorciado(a)'
            } else if (maritalStatus === 'V') {
                maritalStatusType = 'Viudo(a)'
            }
            return maritalStatusType
        },
        /**
         * Método para reestablecer valores iniciales del formulario de filtros.
         *
         * @method resetFilters
         *
         * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <kingangelo_123@gmail.com>
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        resetFilters() {
            const vm = this;
            vm.filterBy = {
                dni: '',
                fromAge: '',
                toAge: ''
            };
            vm.filterSelectedOptions.donationOption = '';
            vm.filterSelectedOptions.genderOption = '';
            vm.filterSelectedOptions.blodGroupOption = '';
            vm.filterSelectedOptions.rhFactorOption = '';
            vm.filterSelectedOptions.estate_id = '';
            vm.records = vm.tmpRecords;
        },
        /**
         * Método que permite filtrar los datos de la tabla Donantes voluntarios.
         *
         * @method filterDonors
         *
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        filterDonors() {
            const vm = this
            vm.records = vm.tmpRecords.filter((rec) => {
                return (vm.filterBy.dni) ? (rec.profile.dni === vm.filterBy.dni) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.donationOption) ? (rec.donorSchema === vm.filterSelectedOptions.donationOption) : true;
            }).filter((rec) => {
                return (vm.filterBy.fromAge) ? (rec.profile.age >= vm.filterBy.fromAge) : true;
            }).filter((rec) => {
                return (vm.filterBy.toAge) ? (rec.profile.age <= vm.filterBy.toAge) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.genderOption) ? (rec.profile.gender === vm.filterSelectedOptions.genderOption) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.blodGroupOption) ? (parseInt(rec.blood_group) === parseInt(vm.filterSelectedOptions.blodGroupOption)) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.rhFactorOption) ? (rec.rh_factor === vm.filterSelectedOptions.rhFactorOption) : true;
            }).filter((rec) => {
                return (vm.filterSelectedOptions.estate_id && vm.filterSelectedOptions.estate_id!="") ? (parseInt(rec.profile.estate_id) === parseInt(vm.filterSelectedOptions.estate_id)) : true;
            })
        },
        /**
         * Método que permite exportar la planilla del donante en PDF.
         *
         * @method exportPDF
         *
         * @author Ing. Argenis Osorio <aosorio@cenditel.gob.ve>
        */
        exportPDF() {
            var docDefinition = {
                info: {
                    title: 'Planilla N° '+this.modalQuery.number,
                },
                pageOrientation: 'portrait',
                //Cintillo
                header: {
                    image: base64images.cintilloFundavene(),
                    width: 530,
                    style:'text_center'
                },
                pageMargins: [ 40, 60, 40, 60 ],
                content: [
                    // Cabecera del reporte
                    { text: '\n\nInformación del No Donante: ' + this.modalQuery.profile.names + ' ' + this.modalQuery.profile.surnames + '\n\n',
                        style: 'header'
                    },
                    {
                        columns: [
                            {
                                // Fecha de registro
                                style: 'subheader',
                                text: 'Fecha de registro',
                            },
                            {
                                // Serial N°
                                style: 'subheader',
                                text: 'Serial N°',
                            },
                            {
                                // Vacío
                                text: '',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Fecha de registro
                                text: new Date(this.modalQuery.created_at).toLocaleDateString('en-GB')+'\n\n', style: 'text_query',
                            },
                            {
                                // Serial N°
                                text: this.modalQuery.number+'\n\n', style: 'text_query',
                            },
                            {
                                // Vacío
                                text: '',
                            },
                        ]
                    },
                    // Datos personales
                    { text: 'Datos personales\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Nombres
                                style: 'subheader',
                                text: 'Nombres',
                            },
                            {
                                // Apellidos
                                style: 'subheader',
                                text: 'Apellidos',
                            },
                            {
                                // Cédula de identidad
                                style: 'subheader',
                                text: 'Cédula de identidad',
                            }
                        ],
                    },
                    {
                        columns: [
                            {
                                // Nombres
                                text: this.profile.names+'\n\n', style: 'text_query',
                            },
                            {
                                // Apellidos
                                text: this.profile.surnames+'\n\n', style: 'text_query',
                            },
                            {
                                // Cédula de identidad
                                text: this.profile.dni+'\n\n', style: 'text_query',
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                // Nacionalidad
                                style: 'subheader',
                                text: 'Nacionalidad',
                            },
                            {
                                // Género
                                style: 'subheader',
                                text: 'Género',
                            },
                            {
                                // Ocupación
                                style: 'subheader',
                                text: 'Ocupación',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Nacionalidad
                                text: this.showNationality(this.profile.nationality)+'\n\n', style: 'text_query',
                            },
                            {
                                // Género
                                text: this.showGender(this.profile.gender)+'\n\n', style: 'text_query',
                            },
                            {
                                // Ocupación
                                text: this.profile.ocupation.name+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                // Estado civil
                                style: 'subheader',
                                text: 'Estado civil',
                            },
                            {
                                // Nivel académico
                                style: 'subheader',
                                text: 'Nivel académico',
                            },
                            {
                                // Grupo sanguíneo
                                style: 'subheader',
                                text: 'Grupo sanguíneo',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Estado civil
                                text: this.showMaritalStatus(this.profile.marital_status)+'\n\n', style: 'text_query',
                            },
                            {
                                // Nivel académico
                                text: this.profile.academic_level+'\n\n', style: 'text_query',
                            },
                            {
                                // Grupo sanguíneo
                                text: this.modalQuery.blood_group?this.modalQuery.blood_group:""+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                // RH factor
                                style: 'subheader',
                                text: 'Factor RH',
                            },
                            {
                                // Vacío
                                text: '',
                            },
                            {
                                // Vacío
                                text: '',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Factor RH
                                text: this.modalQuery.rh_factor?this.modalQuery.rh_factor:""+'\n\n', style: 'text_query',
                            },
                            {
                                // Vacío
                                text: '',
                            },
                            {
                                // Vacío
                                text: '',
                            },
                        ]
                    },
                    // Edad
                    { text: '\nEdad\n\n', style: 'header' },
                    {
                        columns: [
                            {
                                // Fecha de nacimiento
                                style: 'subheader',
                                text: 'Fecha de nacimiento',
                            },
                            {
                                // Lugar de nacimiento
                                style: 'subheader',
                                text: 'Lugar de nacimiento',
                            },
                            {
                                // Edad
                                style: 'subheader',
                                text: 'Edad',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Fecha de nacimiento
                                text: new Date(this.profile.birthdate).toLocaleDateString('en-GB', {timeZone: 'UTC'})+'\n\n', style: 'text_query',
                            },
                            {
                                // Lugar de nacimiento
                                text: this.profile.birthplace+'\n\n', style: 'text_query',
                            },
                            {
                                // Edad
                                text: this.profile.age+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    // Dirección
                    { text: 'Dirección\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Estado
                                style: 'subheader',
                                text: 'Estado',
                            },
                            {
                                // Municipio
                                style: 'subheader',
                                text: 'Municipio',
                            },
                            {
                                // Parroquia
                                style: 'subheader',
                                text: 'Parroquia',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Estado
                                text: this.location.estate+'\n\n', style: 'text_query',
                            },
                            {
                                // Municipio
                                text: this.location.municipality+'\n\n', style: 'text_query',
                            },
                            {
                                // Parroquia
                                text: this.location.parish+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    // Contacto
                    { text: 'Contacto\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // Teléfono
                                style: 'subheader',
                                text: 'Teléfono local',
                            },
                            {
                                // Teléfono móvil
                                style: 'subheader',
                                text: 'Teléfono móvil',
                            },
                            {
                                // Correo electrónico
                                style: 'subheader',
                                text: 'Correo electrónico',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                // Teléfono
                                text: this.profile.phone+'\n\n', style: 'text_query',
                            },
                            {
                                // Teléfono móvil
                                text: this.profile.movil+'\n\n', style: 'text_query',
                            },
                            {
                                // Correo electrónico
                                text: this.user.email+'\n\n', style: 'text_query',
                            },
                        ]
                    },
                    // Familiares con quienes se compartió la decisión
                    {
                        text: this.families.length > 0 ? 'Familiares con quienes se compartió la decisión\n\n' : '',
                        style: 'header'},
                    {
                        columns: [
                            {
                                // Familiares
                                style: 'subheader',
                                text: this.families.length > 0 ? 'Familiares' : '',
                            }
                        ],
                    },
                    {
                        columns: [
                            {
                                // Familiares
                                text: this.getFamilyNames()+'\n\n', style: 'text_query'
                            }
                        ]
                    },
                    // Razones por las cuales no quiere ser donante
                    { text: 'Razones por las cuales no quiere ser donante\n\n', style: 'header'},
                    {
                        columns: [
                            {
                                // razon
                                text: this.modalQuery.not_donor_reason+'\n\n', style: 'text_query'
                            }
                        ]
                    },
                    // Footer del reporte
                    {
                        image: base64images.footerPlanillasPDF(),
                        width: 450,
                        style: 'text_center',
                        absolutePosition: {x: 12, y: 730}
                    },
                ],
                styles: {
                    header: {
                        fontSize: 13,
                        bold: true,
                        alignment: 'justify'
                    },
                    subheader: {
                        fontSize: 10,
                        bold: true,
                        alignment: 'justify'
                    },
                    text_query: {
                        fontSize: 8,
                        alignment: 'justify'
                    },
                    text_bold: {
                        fontSize: 8,
                        alignment: 'center',
                        bold: true
                    },
                    text_center: {
                        fontSize: 8,
                        alignment: 'center',
                    },
                }
            }
            pdfMake.createPdf(docDefinition).download(
                `Planilla Donante voluntario-Serial N°${this.modalQuery.number}-CI-${this.profile.dni}`
            );
        },
        /**
         * Método que devuelve la lista de familiares con quien se comparte
         * la decisión para ser mostrados en el reporte pdf.
         *
         * @method getFamilyNames
         *
         * @author Daniel Contreras <dcontreras@cenditel.gob.ve>
        */
        getFamilyNames() {
            const vm = this;
            let familyInfo = '';

            for (let [i, family] of vm.families.entries()) {
                if (i == vm.families.length - 1) {
                    familyInfo = familyInfo + 'Nombre: ' + family.names + ' ' + family.surnames + ' | Teléfono: ' + family.phone + ' | Parentesco: ' + vm.getKinship(family.relationship) + '.';
                } else {
                    familyInfo = familyInfo + 'Nombre: ' + family.names + ' ' + family.surnames + ' | Teléfono: ' + family.phone + ' | Parentesco: ' + vm.getKinship(family.relationship) + ',\n';
                }
            }
            return familyInfo;
        },
    },
    async mounted() {
        const vm = this;
        /**
         * Si el usuario autenticado intenta acceder al componente sin tener el
         * rol requerido este no carta los diferentes datos a consultar y
         * redirecciona a la pantalla inicial.
        */
        vm.user_role = vm.$parent.user.role;
        if ((vm.user_role == 'ADM') || (vm.user_role == 'SUP') || (vm.user_role == 'OPD') || (vm.user_role == 'CHO')) {
            vm.loadingState(true); // Inicio de spinner de carga.
            // Obtener los estados.
            await vm.getEstates()
            await vm.getRecords(vm.url, 'records');
            await vm.getRecords('global-data-type/kinship', 'kinship');
            vm.tmpRecords = vm.records;
            // Obtener el listado de los grupos sanguíneos.
            await vm.getRecords('global-data-type/blood_group', 'blood_groups')
            vm.loadingState(); // Finaliza spinner de carga.
        }
        else {
            location.href = '/';
        }
    }
}
</script>
