<template>
    <div id="password-edit-component">
        <div class="card mb-4 shadow-sm">
            <h2 class="h3 text-center text-primary text-uppercase py-3 border-bottom border-primary">
                <i class="fa fa-key"></i> Cambiar contraseña
            </h2>
            <div class="card-body">
                <form-errors :errors="errors"></form-errors>
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-6">
                        <div class="mb-3">
                            <label for="prefix" class="form-label">
                                Contraseña actual <span class="text-danger">*</span>
                            </label>
                            <input id="old_password" class="form-control"
                                type="password" name="password"
                                placeholder="Contraseña actual"
                                v-model="record.old_password"
                                required>
                        </div>
                        <div class="mb-3">
                            <label for="prefix" class="form-label">
                                Nueva contraseña <span class="text-danger">*</span>
                            </label>
                            <input id="new_password" class="form-control"
                                type="password" name="password"
                                placeholder="Nueva contraseña"
                                v-model="record.new_password"
                                required>
                        </div>
                        <div class="mb-3">
                            <label for="prefix" class="form-label">
                                Confirmar contraseña <span class="text-danger">*</span>
                            </label>
                            <input id="password_confirmation"
                                class="form-control" type="password"
                                name="password_confirmation"
                                placeholder="Confirmar contraseña"
                                v-model="record.password_confirmation"
                                required>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer text-center">
                <button type="submit" class="btn btn-primary text-light"
                    @click="setRecord('user-password-edit'); reset();">
                    <i class="fa fa-save"></i>
                    Guardar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                errors: [],
                record: {
                    old_password: '',
                    new_password: '',
                    password_confirmation: '',
                }
            }
        },
        watch: {
        },
        methods: {
            /**
             * Método para reestablecer valores iniciales del formulario
             *
             * @method reset
             *
             * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <kingangelo_123@gmail.com>
             * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
             */
            reset() {
                const vm = this;
                vm.errors = [];
                vm.record = {
                    password: '',
                    new_password: '',
                    password_confirmation: '',
                }
            },
        },
        mounted() {
        }
    };
</script>
