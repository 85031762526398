/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue';
import VueRouter from 'vue-router';
import {
    ServerTable,
    ClientTable,
    Event
} from 'vue-tables-2';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import 'sweetalert2/src/sweetalert2.scss';
window.Swal = require('sweetalert2');
Vue.use(VueRouter);
Vue.use(ClientTable);
Vue.use(ServerTable);

Vue.component('v-select', vSelect);

/**
 * Componente genérico para el uso de listas desplegables con select2 y selects dependientes
 *
 * @author  Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
 */
Vue.component('select2', () => import('./components/shared/SelectComponent.vue'));

Vue.mixin({
    data() {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            table_options: {
                highlightMatches: true,
                perPage: 10,
                perPageValues: [10, 20, 50],
                sortable: true,
                filterable: true,
                orderBy: false,
                columnsDropdown: false,
                dateFormat: "DD/MM/YYYY",
                pagination: {
                    show: true,
                    dropdown: false,
                    chunk: 10,
                    edge: true,
                    align: "right",
                    nav: "fixed"
                },
                texts: {
                    filter: "Buscar:",
                    filterBy: 'Buscar por {column}',
                    //count:'Página {page}',
                    count: ' ',
                    first: 'PRIMERO',
                    last: 'ÚLTIMO',
                    limit: 'Registros',
                    //page: 'Página:',
                    loadingError: 'Oops! No se pudo cargar la información',
                    noResults: 'No existen registros',
                    loading: "Cargando...",
                    filterPlaceholder: "Buscar...",
                },
                sortIcon: {
                    is: 'fa-sort cursor-pointer',
                    base: 'fa',
                    up: 'fa-sort-up cursor-pointer',
                    down: 'fa-sort-down cursor-pointer'
                },
            },
        }
    },
    watch: {
        /**
         * [errors mueve el scroll al top de la pagina para visualizar los errores generados]
         * @author Angelo Osorio <adosorio@cenditel.gob.ve | danielking.321@gmail.com>
         */
        errors: function (res) {
            if (res.length > 0) {
                window.scrollTo(0, 200);
            };
        },
    },
    methods: {
        /**
         * Método para reestablecer valores iniciales del formulario
         *
         * @method reset
         *
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        reset() {
            //
        },

        /**
         * Función para cambiar el estado del loader.
         *
         * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <danielking.321@gmail.com>
         */
        loadingState(state = false){
            const loader = document.getElementById('loader');
            if (state) {
                loader.classList.remove('d-none');
            } else {
                loader.classList.add('d-none');
            }
        },

        /**
         * Habilita / Deshabilita campos para su edición
         *
         * @author    Ing. Roldan Vargas <roldandvg@gmail.com>
         */
        setEditable(editable) {
            this.editable = (/true/i).test(editable);
        },

        /**
         * Obtiene los registos solicitado
         *
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         *
         * @param  {String}   url   Ruta en la cual buscar la información
         * @param  {String}   field Nombre del campo en el cual se va a asignar el resultado obtenido
         */
        async getRecords(url, field = 'records') {
            const vm = this;
            const query = `${window.url}/${url}`;
            await axios.get(query)
            .then(response => {
                vm[field] = response.data.result;
            })
            .catch(error => {
                console.error(error);
            });
        },

        /**
         * Obtiene el registo solicitado
         *
         * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <danielking.321@gmail.com>
         *
         * @param     {String}      url     URL para el registro a editar
         * @param     {String}      id      ID del registro a editar
         */
        async getRecord(url, id) {
            const vm = this;
            const query = `${window.url}/${url}/${id}`;
            await axios.get(query)
            .then(response => {
                vm.record = response.data.result;
            })
            .catch(error => {
                console.error(error);
            });
        },

        /**
         * Método que desplaza la pantalla a la cabecera del formulario.
         *
         * @author Argenis Osorio <aosorio@cenditel.gob.ve>
         */
        scrollMeTo(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop;
            window.scrollTo(0, top);
        },

        /**
         * Ejecuta la acción para guardar o actualizar datos
         *
         * @method setRecord
         *
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         *
         * @param  {String}  url URL de la petición a realizar
         */
        async setRecord(url) {
            const vm = this;
            const recordId = (typeof (vm.record.id) !== "undefined" && vm.record.id) ? vm.record.id : '';
            vm.loadingState(true);
            await axios({
                method: (recordId) ? 'put' : 'post',
                url: `${window.url}/${url}/${recordId}`,
                data: vm.record
            })
            .then(response => {
                vm.loadingState();
                vm.reset();
                vm.getRecords(vm.url);
                vm.showMessage('updated');
            })
            .catch(error => {
                vm.loadingState();
                vm.setErrors(error);
            });
        },

        /**
         * Establece los datos del registro a modificar
         *
         * @author    Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         *
         * @param     {String}      url     URL para el registro a editar
         * @param     {String}      id      ID del registro a editar
         */
        editRecord(url, id) {
            this.$router.push(`${url}/${id}`);
        },

        /**
         * Elimina el registro solicitado
         *
         * @author    Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         *
         * @param     {String}        url     URL para la eliminación del registro
         * @param     {Integer}       id      Identificador del registro a eliminar
         */
        deleteRecord(url, id) {
            const vm = this;
            Swal.fire({
                title: '¿Estás seguro que quieres eliminar este registro?',
                text: 'Esta acción es definitiva y el registro no podrá recuperarse!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-cancel-to-swal',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`${window.url}/${url}/${id}`).then(response => {
                        if (response.data.code === 200) {
                            vm.getRecords(url);
                            vm.showMessage('destroy');
                        }
                    }).catch(error => {
                        console.error(error);
                        vm.setErrors(error);
                    });
                }
            });
        },

        /**
         * Elimina el registro solicitado
         *
         * @author    Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         *
         * @param     {String}        url     URL para la eliminación del registro
         * @param     {Integer}       id      Identificador del registro a eliminar
         */
        deleteRecordFiltered(url, urlfilter, id) {
            const vm = this;
            Swal.fire({
                title: '¿Estás seguro que quieres eliminar este registro?',
                text: 'Esta acción es definitiva y el registro no podrá recuperarse!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-cancel-to-swal',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`${window.url}/${url}/${id}`).then(response => {
                        if (response.data.code === 200) {
                            vm.getRecords(urlfilter);
                            vm.showMessage('destroy');
                        }
                    }).catch(error => {
                        console.error(error);
                        vm.setErrors(error);
                    });
                }
            });
        },

        /**
         * Establece información de errores generados en el sistema
         *
         * @author    Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         *
         * @param     {Object}     error    Objeto con infrmación de los errores
         */
        setErrors(error) {
            let errors = [];
            if (typeof (error.response) != "undefined") {
                if (typeof (error.response.data) !== "undefined" && typeof (error.response.data.errors) !== "undefined") {
                    for (var index in error.response.data.errors) {
                        if (error.response.data.errors[index]) {
                            errors.push(error.response.data.errors[index][0]);
                        }
                    }
                } else {
                    errors.push((window.debug) ? error.response.data.message : 'Error procesando la petición');
                }
                this.errors = errors;
            }
        },

        /**
         * Determina si el usuario autenticado tiene acceso al módulo o registro a evaluar
         *
         * @author    Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         *
         * @param     {Object}     user            Objeto con información del usuario autenticado
         * @param     {Array}      userType        Tipo de usuario
         *
         * @return    {Boolean}    Devuelve verdadero si el usuario tiene acceso, de lo contrario devuelve falso
         */
        checkRole(user, userType) {
            return userType.includes(user.role);
        },

        /**
         * Muestra un mensaje al usuario de acuerdo a la acción ejecutada
         *
         * @author    Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         *
         * @param     {String}       type     Tipo de mensaje a mostrar
         *
         * @return    {[type]}       [description]
         */
        showMessage(type) {
            let options = {
                closeButton: true,
                progressBar: true,
                timeOut: 5000,
                extendedTimeOut: 1000,
                preventDuplicates: true
            };
            if (type === 'success') {
                toastr.success("Registro creado", "", options);
            } else if (type === 'updated') {
                toastr.success("Registro guardado", "", options);
            } else if (type === 'destroy') {
                toastr.success("Registro eliminado", "", options);
            }
        },

        /**
         * Mensaje a mostrar para cuando no se dispone de acceso a una funcionalidad
         *
         * @author     Ing. Roldan Vargas <roldandvg@gmail.com>
         */
        showAccessMessage(perm) {
            if (!perm) {
                toastr.warning('No tienes permiso para ejectuar esta acción', "", {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 5000,
                    extendedTimeOut: 1000,
                    preventDuplicates: true
                });
            }
        },

        /**
         * Mensaje a mostrar para cuando un archivo excede el tamaño permitido
         *
         * @author     Angelo Osorio <adosorio@cenditel.gob.ve> | <danielking.321@gmail.com>
         */
        maxWeightExceededMessage() {
            toastr.warning('El archivo excede el tamaño permitido', "", {
                closeButton: true,
                progressBar: true,
                timeOut: 5000,
                extendedTimeOut: 1000,
                preventDuplicates: true
            });
        },

        /**
         * Devuelve el peso máximo en bytes permitido para la validación de los input
         * de tipo file
         *
         * @author     Angelo Osorio <adosorio@cenditel.gob.ve> | <danielking.321@gmail.com>
         */
        maxWeightAllow() {
            return 5 * 1024 * 1024; // Peso máximo del archivo: 5MB
        },

        /**
         * Listado de Estados
         *
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        async getEstates() {
            const vm = this;
            axios.get(`${window.url}/estates`).then(response => {
                vm.estates = response.data.result;
            }).catch(error => {
                console.error(error);
            })
        },

        /**
         * Listado de Municipios
         *
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        async getMunicipalities(estateId = null) {
            const vm = this;
            let estate_id = (!estateId)?vm.record.estate_id:estateId;
            if (!estate_id) {
                return false;
            }
            await axios.get(`${window.url}/municipalities/${estate_id}`).then(response => {
                vm.municipalities = response.data.result;
            }).catch(error => {
                console.error(error);
            })
        },

        /**
         * Listado de Parroquias
         *
         * @author Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
         */
        async getParishes(municipalityId = null) {
            const vm = this;
            let municipality_id = (!municipalityId)?vm.record.municipality_id:municipalityId;
            if (!municipality_id) {
                return false;
            }
            await axios.get(`${window.url}/parishes/${municipality_id}`).then(response => {
                vm.parishes = response.data.result;
            }).catch(error => {
                console.error(error);
            })
        },

        /**
         * Devuelve la fecha actual. Se usa en formularios con fecha
         * seleccionable para no ingresar una fecha posterior al día
         * en curso.
         *
         * @author Angelo Osorio <adosorio@cenditel.gob.ve> | <danielking.321@gmail.com>
         */
        getCurrentDate() {
            let today = new Date().toISOString().slice(0, 10);
            return today;
        }
    },
});

/**
 * Componente genérico para el uso de listas desplegables y selects dependientes
 *
 * @author  Ing. Roldan Vargas <rvargas@cenditel.gob.ve> | <roldandvg@gmail.com>
 */
Vue.component('select-component', () => import(
    /* webpackChunkName: "selects" */
    './components/shared/SelectComponent.vue'
));
Vue.component('form-errors', () => import(
    /* webpackChunkName: "form-errors" */
    './components/shared/FormErrorsComponent.vue'
));
Vue.component('go-back', () => import(
    /* webpackChunkName: "form-errors" */
    './components/shared/GoBack.vue'
));

import App from './views/App';

// Inicio
import Landing from './views/common/LandingComponent';
// import Load from './views/common/LoadComponent';

// Componentes de la gestión de usuarios
import Users from './views/user/UserListComponent';

// Componente para la gestión del perfil del usuario autenticado.
import Profile from './views/user/ProfileComponent';

// Componente para la actualización de la contraseña del usuario autenticado.
import PasswordEditComponent from './views/user/PasswordEditComponent';

// Componentes de la carga inicial (comunes) - Datos globales
import AcademicLevel from './views/common/AcademicLevelListComponent';
import BloodGroup from './views/common/BloodGroupListComponent.vue';
import Kinship from './views/common/KinshipListComponent.vue';
import Occupation from './views/common/OccupationListComponent.vue';
import Organ from './views/common/OrganListComponent.vue';
import TransplantationType from './views/common/TransplantationTypeListComponent.vue';
import OrgansAndTissues from './views/common/OrgansAndTissuesListComponent.vue';

// Componentes de la carga inicial (comunes) - Infraestructuras
import HealthcareCenter from './views/common/HealthcareCenterListComponent.vue';
import TransplantCenter from './views/common/TransplantCenterListComponent.vue';

// Componentes para el registro de voluntad de ser donante
import WillDonor from './views/donor/WillDonor';

// ***** Componentes para la gestión de Solicitudes de trasplante *****

// Registro de solicitud de trasplante
import TransplantRequestForm from './views/applicant/TransplantRequestFormComponent.vue';

// Lista de solicitudes de trasplante
import TransplantRequestList from './views/management/TransplantRequestListComponent.vue';

// Lista de solicitudes de trasplante procesadas
import TransplantRequestProcessedList from './views/management/TransplantRequestProcessedListComponent.vue';

// Lista de solicitudes de trasplante en espera de suero
import TransplantRequestAwaitingSerumList from './views/management/TransplantRequestAwaitingSerumListComponent.vue';

// Lista de solicitudes rechazadas
import TransplantRequestRejectedList from './views/management/TransplantRequestRejectedListComponent.vue';

// Lista de espera (activos)
import WaitingListActiveList from './views/management/WaitingListActiveListComponent.vue';

// Lista de espera (inactivos)
import WaitingListInactiveList from './views/management/WaitingListInactiveListComponent.vue';

// Lista de Trasplantes realizados
import TransplantsPerformedList from './views/management/TransplantsPerformedListComponent.vue';

// Componente para la gestión de rechazo de solicitud de trasplante
import TransplantRequestDecline from './views/management/TransplantRequestDeclineComponent.vue'

// Componente que lista los registro de pacientes con donante vivo y solicitud aprobada
import LivingDonorPatientList from './views/management/LivingDonorPatientListComponent.vue';

// Lista de pacientes aptos y no aptos con donante vivo
import LivingDonorSuitablePatientList from './views/management/LivingDonorSuitablePatientListComponent.vue';
import LivingDonorNotSuitablePatientList from './views/management/LivingDonorNotSuitablePatientListComponent.vue';

// ***** Componentes del Panel estadístico *****

// Personas que expresaron su voluntad de donar - Lista de Donantes voluntarios
import PanelVoluntaryDonorsList from './views/management/donor_management/PanelVoluntaryDonorsListComponent.vue';

// Personas que necesitan un trasplante en este momento - Lista de solicitudes de trasplante
import PanelTransplantRequestList from './views/management/PanelTransplantRequestListComponent.vue';

// Personas que donaron sus órganos durante este año (2022) - Lista de Donantes efectivos
import PanelEffectiveDonorList from './views/management/donor_management/PanelEffectiveDonorListComponent.vue';

// Lista de Trasplantes realizados en el año en curso.
import PanelTransplantsPerformedList from './views/management/PanelTransplantsPerformedListComponent.vue';

// Lista de médicos especialistas en trasplante.
import MedicalSpecialist from './views/common/MedicalSpecialistComponent.vue';

// Formulario para actualizar los datos de una solicitud de trasplante.
import TransplantRequestUpdateComponent from './views/management/TransplantRequestUpdateComponent.vue';

// Formulario para actualizar los datos de una solicitud de trasplante procesada.
import TransplantReqProcessedUpdateComponent from './views/management/TransplantReqProcessedUpdateComponent';

// Formulario para actualizar el estatus de una solicitud de trasplante.
import TransplantRequestUpdateStatusComponent from './views/management/TransplantRequestUpdateStatusComponent.vue';

// ***** Componentes para la gestión de Donantes *****

// Lista de Donantes voluntarios
import VoluntaryDonorsList from './views/management/donor_management/VoluntaryDonorsListComponent.vue';

// Gestión para donante potencial
import PotentialDonorCreateComponent from './views/management/donor_management/PotentialDonorCreateComponent.vue';

// Gestión para donante efectivo
import EffectiveDonorCreate from './views/management/donor_management/EffectiveDonorCreateComponent.vue';

// Registro de nuevo donante efectivo que no estaba registrado como Donante.
import NotRegisteredToEffectiveDonorCreateComponent from './views/management/donor_management/NotRegisteredToEffectiveDonorCreateComponent.vue';

// Lista de Donantes potenciales
import PotentialDonorsList from './views/management/donor_management/PotentialDonorsListComponent.vue';

// Lista de Donantes efectivos
import EffectiveDonorList from './views/management/donor_management/EffectiveDonorListComponent.vue';

// Lista de No Donantes
import NonDonorList from './views/management/donor_management/NonDonorListComponent.vue';

// Gestión de donante por negativa familiar
import NonDonorByFamilyDecision from './views/management/donor_management/NonDonorByFamilyDecisionComponent.vue';

// Lista de No Donantes por Negativa Familiar
import NonDonorByFamilyDecisionList from './views/management/donor_management/NonDonorByFamilyDecisionListComponent.vue';

// Lista de Donantes no aptos
import NotAptDonorsList from './views/management/donor_management/NotAptDonorsListComponent.vue';

// Gestión para donante no apto
import NotAptDonor from './views/management/donor_management/NotAptDonorComponent.vue';

const router = new VueRouter({
    mode: 'history',
    routes: [{
            path: '',
            name: 'landing',
            component: Landing
        },
        {
            path: '/users',
            name: 'users',
            component: Users
        },
        {
            path: '/common/academic-level',
            name: 'academic-level',
            component: AcademicLevel
        },
        {
            path: '/common/blood-group',
            name: 'blood-group',
            component: BloodGroup
        },
        {
            path: '/common/kinship',
            name: 'kinship',
            component: Kinship
        },
        {
            path: '/common/occupation',
            name: 'occupation',
            component: Occupation
        },
        {
            path: '/common/organ',
            name: 'organ',
            component: Organ
        },
        {
            path: '/common/organs-and-tissues',
            name: 'organs-and-tissues',
            component: OrgansAndTissues
        },
        {
            path: '/common/transplantation-type',
            name: 'transplantation-type',
            component: TransplantationType
        },
        {
            path: '/common/healthcare-center',
            name: 'healthcare-center',
            component: HealthcareCenter
        },
        {
            path: '/common/transplant-center',
            name: 'transplant-center',
            component: TransplantCenter
        },
        {
            path: '/common/medical-specialist',
            name: 'medical-specialist',
            component: MedicalSpecialist
        },
        {
            path: '/profile',
            name: 'profile',
            component: Profile
        },
        {
            path: '/will-donor',
            name: 'will-donor',
            component: WillDonor
        },
        {
            path: '/effective-donor-list',
            name: 'effective-donor-list',
            component: EffectiveDonorList
        },
        {
            path: '/panel-effective-donor-list',
            name: 'panel-effective-donor-list',
            component: PanelEffectiveDonorList
        },
        {
            path: '/potential-donors-list',
            name: 'potential-donors-list',
            component: PotentialDonorsList
        },
        {
            path: '/not-apt-donors-list',
            name: 'not-apt-donors-list',
            component: NotAptDonorsList
        },
        {
            path: '/not-apt-donor-registry-management/:id',
            name: 'not-apt-donor-registry-management',
            component: NotAptDonor
        },
        {
            path: '/voluntary-donors-list',
            name: 'voluntary-donors-list',
            component: VoluntaryDonorsList
        },
        {
            path: '/panel-voluntary-donors-list',
            name: 'panel-voluntary-donors-list',
            component: PanelVoluntaryDonorsList
        },
        {
            path: '/non-donor-list',
            name: 'non-donor-list',
            component: NonDonorList
        },
        {
            path: '/non-donor-by-family-decision-list',
            name: 'non-donor-by-family-decision-list',
            component: NonDonorByFamilyDecisionList
        },
        {
            path: '/non-donor-by-family-decision/:id',
            name: 'non-donor-by-family-decision',
            component: NonDonorByFamilyDecision
        },
        {
            path: '/transplant-request-form',
            name: 'transplant-request-form',
            component: TransplantRequestForm
        },
        {
            path: '/transplant-request-decline/:id',
            name: 'transplant-request-decline',
            component: TransplantRequestDecline
        },
        {
            path: '/transplant-request-list',
            name: 'transplant-request-list',
            component: TransplantRequestList
        },
        {
            path: '/panel-transplant-request-list',
            name: 'panel-transplant-request-list',
            component: PanelTransplantRequestList
        },
        {
            path: '/transplant-request-processed-list',
            name: 'transplant-request-processed-list',
            component: TransplantRequestProcessedList
        },
        {
            path: '/transplant-request-awaiting-serum-list',
            name: 'transplant-request-awaiting-serum-list',
            component: TransplantRequestAwaitingSerumList
        },
        {
            path: '/transplant-request-rejected-list',
            name: 'transplant-request-rejected-list',
            component: TransplantRequestRejectedList
        },
        {
            path: '/waiting-list-active-list',
            name: 'waiting-list-active-list',
            component: WaitingListActiveList
        },
        {
            path: '/waiting-list-inactive-list',
            name: 'waiting-list-inactive-list',
            component: WaitingListInactiveList
        },
        {
            path: '/transplants-performed-list',
            name: 'transplants-performed-list',
            component: TransplantsPerformedList
        },
        {
            path: '/living-donor-patient-list',
            name: 'living-donor-patient-list',
            component: LivingDonorPatientList,
        },
        {
            path: '/living-donor-suitable-patient-list',
            name: 'living-donor-suitable-patient-list',
            component: LivingDonorSuitablePatientList
        },
        {
            path: '/living-donor-not-suitable-patient-list',
            name: 'living-donor-not-suitable-patient-list',
            component: LivingDonorNotSuitablePatientList
        },
        {
            path: '/potential-donor-registry-management/:id',
            name: 'potential-donor-registry-management',
            component: PotentialDonorCreateComponent
        },
        {
            path: '/effective-donor-create',
            name: 'effective-donor-create',
            component: NotRegisteredToEffectiveDonorCreateComponent
        },
        {
            path: '/effective-donor-edit/:id',
            name: 'effective-donor-edit/',
            component: NotRegisteredToEffectiveDonorCreateComponent
        },
        {
            path: '/effective-donor-registry-management/:id',
            name: 'effective-donor-registry-management',
            component: EffectiveDonorCreate
        },
        {
            path: '/password-edit',
            name: 'password-edit',
            component: PasswordEditComponent
        },
        {
            path: '/update/transplant-request/:id',
            name: 'transplant-request-update',
            component: TransplantRequestUpdateComponent
        },
        {
            path: '/update/transplant-request-processed/:id',
            name: 'transplant-request-processed-update',
            component: TransplantReqProcessedUpdateComponent
        },
        {
            path: '/transplant-request-update-status/:id',
            name: 'transplant-request-update-status',
            component: TransplantRequestUpdateStatusComponent
        },
        {
            path: '/panel-transplants-performed-list',
            name: 'panel-transplants-performed-list',
            component: PanelTransplantsPerformedList
        },
    ]
});
router.beforeEach(async (to, from,next ) => {
    //Se determinan las rutas a las que se pueden acceder sin estar logueado
    const regex = new RegExp('/register|/login|/password/reset+');

    if (!regex.test(to.path)){
            axios.get('/').then(response => {
            next();
    }).catch(function (error) {
        if (error.response) {
            if (error.response.status == 401) {
                location.href = `${window.url}/login`;
            }
            if (error.response.status == 419) {
                location.href = `${window.url}/login`;
            }
        }
    });
    }
});
const app = new Vue({
    el: '#app',
    components: {
        App
    },
    router,
});

